
import { ApiUrl, DateFormat } from 'util/Constant';
import { createMultiPartFormBody } from 'util/Utility';
import DataAccessObject from './DataAccessObject';
import _ from 'lodash/object';
import moment from "moment";

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class OfficerSearchDao extends DataAccessObject {

    async getOfficerSearchByOfficerId(officerId) {
        return this.get(ApiUrl._API_GET_OFFICER_SEARCH_BY_OFFICER_ID.replace(':officerId', officerId));
    }

    async getOfficerSearchBySearchId(searchId) {
        return this.get(ApiUrl._API_GET_OFFICER_SEARCH_BY_SEARCH_ID.replace(':searchId', searchId));
    }

    async createOfficerSearch(data) {
        return this.post(ApiUrl._API_CREATE_OFFICER_SEARCH, data);
    }
    async createOfficerSearchByCompanyId(companyId, data) {
        return this.post(ApiUrl._API_CREATE_OFFICER_SEARCH_BY_COMPANY_ID.replace(':companyId', companyId), data);
    }
    async resolveOfficerSearchDetail(officerSearchDetailId) {
        return this.post(ApiUrl._API_RESOLVE_OFFICER_SEARCH_DETAIL.replace(":officerSearchDetailId", officerSearchDetailId));
    }
    async searchAgainOfficerSearch(officerId, data) {
        return this.post(ApiUrl._API_RE_SEARCH_OFFICER_SEARCH.replace(':officerId', officerId), data);
    }
}

export default OfficerSearchDao;