import { fetch } from 'whatwg-fetch';
import getCountryList from 'react-select-country-list';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Payment from 'payment';
import _ from 'lodash/object';
import { startCase, isEqual } from 'lodash';
import { UrgencyDays, UrgencyId, PaymentMethod, NotificationPriority, AccountantStatus, SessionKey, Language, CompanyStatus, CompanyStatusName } from './Constant';
import validator from "validator";

/// <summary>
/// Author : -
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    if (typeof (data) == "boolean") {
        return data;
    }
    return data == null || data == '';
}


/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : -
/// Convert array into form that use to post data
/// Updated by : Lewis - to support nested object layer
/// </summary>
export function createMultiPartFormBody(obj) {
    var formBody = new FormData();

    for (let key in obj) {
        if (typeof (obj[key]) === 'object') {
            for (let subKey in obj[key]) {
                formBody.append(`${key}[${subKey}]`, obj[key][subKey] ?? null);
            }
        } else {
            formBody.append(key, obj[key] ?? null);
        }
    }

    return formBody;
}

/// <summary>
/// Author : -
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : -
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author: Chris
/// </summary>
export const useGetCountries = () => {
    const [groupedCountries, setGroupCountries] = useState();

    useEffect(() => {
        let listOfCountries = getCountryList().getData();

        let mainCountries = listOfCountries.filter((country) => {
            return ['Hong Kong', 'China', 'Singapore'].includes(country.label);
        });

        mainCountries.forEach((country) => {
            listOfCountries.splice(listOfCountries.indexOf(country), 1);
        })

        setGroupCountries([
            { label: '', options: mainCountries },
            { label: '', options: listOfCountries }
        ]);
    }, []);

    return groupedCountries;
}

/// <summary>
/// Author: Chris
/// </summary>
export const getListOfYears = (offset = 100) => {
    let years = [];
    for (var i = 0; i <= offset; i++) {
        years.push(moment().year() - i);
    }

    return years;
}

/// <summary>
/// Author: Chris
/// </summary>
export const generateRandString = (length = 6) => {
    return Math.random().toString(20).substr(2, length);
}

/// <summary>
/// Author: Lewis
/// Editted: Wind
/// </summary> 
export const getCartItemPrices = (cartItems) => {
    let total = 0;
    if (cartItems.discountAmount) {
        let afterDiscount = cartItems.price - cartItems.discountAmount;
        total = afterDiscount < 0 ? 0 : afterDiscount;
    }
    else if (cartItems.discountRate) {
        let afterDiscount = cartItems.price - (cartItems.price * (cartItems.discountRate / 100));
        total = afterDiscount > cartItems.discountRateCapped ? cartItems.discountRateCapped : afterDiscount
    }
    else {
        total = cartItems.price;
    }
    return total;
}

/// <summary>
/// Author: Lewis
/// </summary>
function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case "amex":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case "dinersclub":
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

/// <summary>
/// Author: Lewis
/// </summary>
export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

/// <summary>
/// Author: Lewis
/// sort country list by popularity first
/// </summary>
export function sortByPopularCountry(countryList) {
    const countryPopularity = { CN: 1, HK: 2, SG: 3, UK: 4, US: 5, CA: 6 }

    countryList.map((item, index) => {
        if (countryPopularity[item.value] !== undefined) {
            item.popularity = countryPopularity[item.value];
        } else {
            item.popularity = index + Object.keys(countryPopularity).length + 1;
        }
    })

    countryList.sort((a, b) => (a.popularity > b.popularity ? 1 : -1))

    return countryList
}

/// <summary>
/// Author: Andrew
/// </summary>
export function getFullUrl(url, toReplace) {
    let fullUrl = url;
    toReplace.map((data, value) => {
        fullUrl = url.replace(`:${Object.keys(data)[0]}`, Object.values(data)[0]);
    });
    return fullUrl;
}

/// <summary>
/// Author: Chris
/// </summary>
export function propertyIsUndefined(data) {
    return data === undefined;
}

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
export const doubleClickNavigate = (history, weburl) => {
    return {
        onDoubleClick: () => history.push(weburl),
    }
}

/// <summary>
/// Author: Lewis
/// </summary>
export const convertObjCamelCaseToStartCase = (obj) => {
    return _.mapKeys(obj, (v, k) => startCase(k))
}

/// <summary>
/// Author: Lewis
/// </summary>
export const objParseDatetimeFormat = (obj, arrayFields) => {
    Object.entries(obj).map(([k, v]) => {
        if (arrayFields.includes(k)) {
            obj[k] = moment(obj[k]).format('YYYY/MM/DD');
        }
    })
    return obj;
}

/// <summary>
/// Author: Andrew
/// </summary>
export const formatNumber = (value) => {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

/// <summary>
/// Author: Lewis
/// </summary>
export const renameObjectKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] }
        }),
        {}
    );

/// <summary>
/// Author: Lewis
/// </summary>
export const queryStringUrlToObj = (queryParamsUrl) => {
    var queryParamsObject = queryParamsUrl.substring(queryParamsUrl.indexOf("?") + 1)
        .split("&")
        .reduce((memo, param) => ({ ...memo, [param.split("=")[0]]: param.split("=")[1] }), {});
    return queryParamsObject;
}

/// <summary>
/// Author: Robin
/// </summary>
export const getOrDefault = (data, defaultValue = null) => {
    if (data === undefined) {
        return defaultValue;
    }

    return data;
}

// <summary>
// Author: CJ(Jiann)
//</summary>
export const checkHashTagColour = (colorCode) => {
    if (colorCode.includes('#')) {
        return colorCode;
    } else {
        var hashTagString = "#" + colorCode;
        return hashTagString;
    }
}

// <summary>
// Author: Lewis
//</summary>
export const toggleAccordionByComponentKey = (componentKey, accordionSetter) => {
    accordionSetter((prevState) => {
        let newState = { ...prevState };
        Object.entries(prevState).map(([k, v]) =>
            (k == componentKey) ? newState[k] = { ...prevState[k], isOpen: true } : newState[k] = { ...prevState[k], isOpen: false }
        );
        return newState;
    });
}

/// <summary>
/// Author: Lewis
/// </summary>
// export const formSubmitOnEnter = (e, formRef) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//         submitForm(formRef);
//     }
// }

/// <summary>
/// Author: Lewis
/// </summary>
export const formatCountryList = (countryList) => {
    var newCountryList = new Array();
    countryList.map(x => {
        newCountryList.push({ id: x.id, value: x.id, shortCode: x.value, label: x.label });
    });
    return newCountryList;
}

/// <summary>
/// Author: Lewis
/// </summary>
export const formatJurisdictionList = (jurisdictionList) => {
    var newJurisdictionList = new Array();
    jurisdictionList.map(x => {
        newJurisdictionList.push({ id: x.id, value: x.id, shortCode: x.shortName, label: x.name });
    })
    return newJurisdictionList;
}

/// <summary>
/// Author: Lewis
/// by default accept multiple images upload
/// </summary>
export const filterFileObjectToSubmit = (fileArrayInput, single = false) => {
    var listOfFileObject = []
    if (typeof (fileArrayInput) == "object" && fileArrayInput.length > 0) {
        fileArrayInput.map(item => { listOfFileObject.push(item.file) });
    }
    return single ? listOfFileObject?.[0] : listOfFileObject;
}

/// <summary>
/// Author: Lewis
/// </summary>
export const populateAttachmentFileMeta = (fileMeta) => {
    if (fileMeta != undefined && fileMeta.length > 0) {
        var fileArrayToPopulate = [];
        fileMeta.map(fileItem => {
            fileArrayToPopulate.push({ filename: fileItem.filename, url: fileItem.requestPath });
        });
        return fileArrayToPopulate;
    }
    return []
}
//<summary>
//Author: CJ(Jiann)
//</summary>
export const CalculateUrgencyDays = (id) => {
    var days;
    switch (id) {
        case UrgencyId._THREE_DAYS:
            days = 3;
            break;
        case UrgencyId._ONE_WEEK:
            days = 7;
            break;
        case UrgencyId._TWO_WEEKS:
            days = 14;
            break;
        case UrgencyId._ONE_MONTH:
            days = 30;
            break;
        case UrgencyId._SIX_MONTHS:
            days = 180;
            break;
        default:
            break;
    }
    return days;
}

//<summary>
//Author: Lewis
//</summary>
export const compareObjectDiffAttributesAndGetProps = (oldObject, newObject) => {
    let changesArray = new Array();
    Object.entries(newObject).map(([k, v], index) => {

        // check if old object key not existed, then add new key and compare
        if (!(k in oldObject)) {
            oldObject[k] = null;
        }

        if (oldObject[k] != newObject[k]) {
            changesArray.push({ label: startCase(k), propertyName: k, oldValue: oldObject[k], newValue: newObject[k] });
        }
    });
    return changesArray;
}

export const getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

export const flattenObject = (item) => {
    var newObj = {};
    Object.keys(item).forEach(key => {
        if (typeof item[key] === 'object' && !(item[key] instanceof Date)) {
            Object.keys(item[key]).forEach(innerKey => {
                newObj[`${key}.${innerKey}`] = item[key][innerKey];
            });
        } else {
            newObj[key] = item[key];
        }
    });

    return newObj;
}

export const cleanObject = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] == "") {
            delete obj[propName];
        }
    }
    return obj
}

export const removeCommaParseInt = (string) => {
    return parseInt(string.replace(/,/g, ''));
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const checkStringContainInArray = (obj, arr) => {
    if (arr.length > 0) {
        return arr.includes(obj);
    }
    return false;
}

export const parseInt = (stringValue) => {
    const parsed = Number.parseInt(stringValue);
    return Number.isNaN(parsed) ? 0 : parsed;
}

//<summary>
//Author: YJ
//</summary>
export const constructStripeTransactionType = (paymentMethod) => {
    switch (paymentMethod) {
        case PaymentMethod._ALIPAY:
            return "alipay";
            break;
        case PaymentMethod._CREDITCARD:
            return "card";
            break;
        case PaymentMethod._WECHATPAY:
            return "wechat";
            break;
        default:
            return "";
            break;
    }
}

//<summary>
//Author: YJ
//</summary>
export const useQuery = (location) => {
    return new URLSearchParams(location.search);
}

//<summary>
//Author: YJ
//</summary>
export function isObjectEmpty(data) {
    return Object.keys(data).length === 0;
}

//<summary>
//Author: Lewis
//</summary>
export function isNullOrUndefined(param) {
    return (param === undefined || param === null) ? true : false;
}

//<summary>
//Author: Lewis
//</summary>
export function getCountryFlagIconByJurisdictionName(code) {
    try {
        return require(`../assets/img/country-flag/${code}.png`);
    }
    catch {
        return null;
    }
}


//<summary>
//Authro: CJ(Jiann)
//</summary>
export function setNotificationColor(notificationPriority) {
    switch (notificationPriority) {
        case NotificationPriority._URGENT:
            return "danger";
        case NotificationPriority._HIGH:
            return "warning";
        case NotificationPriority._NORMAL:
            return "general";
        case NotificationPriority._LOW:
            return "success";
        default:
            return "gray";

    }
}

//<summary>
//Authro: CJ(Jiann)
//</summary>
export function setNotificationBadge(notificationPriority) {
    switch (notificationPriority) {
        case NotificationPriority._URGENT:
            return "badge badge-danger";
        case NotificationPriority._HIGH:
            return "badge badge-warning";
        case NotificationPriority._NORMAL:
            return "badge badge-primary";
        case NotificationPriority._LOW:
            return "badge badge-success";
        default:
            return "badge badge-secondary";

    }
}

//<summary>
//Author: Sze Hua
//</summary>
export function getIconByName(name) {
    try {
        return require(`../assets/img/icon/${name}.svg`);
    }
    catch {
        return null;
    }
}

// <summary>
// Author : CJ(Jiann)
// </summary>
export function setAccountantStatusName(id) {
    switch (id) {
        case AccountantStatus._PENDING_APPROVAL:
            return "PENDING_APPROVAL";
        case AccountantStatus._APPROVED:
            return "APPROVED";
        case AccountantStatus._REJECTED:
            return "REJECTED";
        default:
            break;
    }
}

export function urlEncode(value) {
    return encodeURIComponent(value);
}

export function isGuid(guid) {

    if (guid == "00000000-0000-0000-0000-000000000000") {
        return false;
    }

    return true;
    // const pattern = '/^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}‌​\}?$/';

    // return  validator.matches(guid, pattern);
}

/// <summary>
/// Author: CJ(Jiann)
/// </summary>
export function InitLanguage(lang){
    switch (lang) {
        case "en":
            return Language._ENGLISH_GB;
        case "hk":
            return Language._CHINESE_HK;
        case "cn":
            return Language._CHINESE_CN;
        default:
            break;
    }
}

export function clearLocalStorage(exclude) {
    Object.keys(localStorage).map(key => {
        let tempTableId = key.split(",")[1];
        if (!(tempTableId && exclude.some(item => tempTableId.includes(item)))) {
            localStorage.removeItem(key)
        }
    });
}

/// <summary>
/// Author: CJ(Jiann)
/// </summary>
export function CompanyStatusToTranslateKey(status) {
    switch (status) {
        case CompanyStatus._ALL:
            return CompanyStatusName._ALL;
        case CompanyStatus._REJECTED:
            return CompanyStatusName._REJECTED;
        case CompanyStatus._REGISTERING:
            return CompanyStatusName._REGISTERING;
        case CompanyStatus._LIVE:
            return CompanyStatusName._LIVE;
        case CompanyStatus._DEREGISTERING:
            return CompanyStatusName._DEREGISTERING;
        case CompanyStatus._DEREGISTERED:
            return CompanyStatusName._DEREGISTERED
        default:
            break;
    }
}