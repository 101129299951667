import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactTable from '../../components/react-table/ReactTable';
import { ApiUrl, DateFormat, InputTypes, ApiKey, BtnTypes, LoadingStateText } from '../../util/Constant';
import moment from 'moment';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { GoIssueOpened } from 'react-icons/go';
import InputHoc from '../../components/form/InputHoc';
import Notiflix, { Confirm, Notify, Block } from 'notiflix';
import FormBuilder from '../../components/form/FormBuilder';
import { FactSheetDao } from '../../data';
import { Card, setRef } from '@material-ui/core';
import ButtonRound from 'components/buttons/ButtonRound';
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';

const FactSheetGroupManagement = (props) => {
    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    let { refreshTable, setRefreshTable, setSelectedGroup } = props;

    const { ref: contentWrapperRef } = useResizeDetector();
    const { t } = useTranslation();
    const softDeleteGroupItem = (Id) => {
        Confirm.Show(
            t('notiflix:DELETE_CONFIRMATION'),
            `${t('notiflix:ARE_YOU_SURE_YOU_WANT_TO_DELETE')}?`,
            t('notiflix:YES'),
            t('notiflix:NO'),
            function () {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                let dao = new FactSheetDao();
                dao.softDeleteGroup(Id).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        setSelectedGroup(null);
                        setRefreshTable(prevState => !prevState);
                        Notify.Success(t('notiflix:GROUP_DELETED'));
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            }
        )
    }

    const _CATEGORIES_COLUMN = useMemo(() => [
        {
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                return (row.original.name);
            }
        },
        {
            Header: "CREATED_DATE",
            accessor: "createDate",
            Cell: ({ row }) => {
                return (moment(row.original.createDate).format(DateFormat._DATE_ONLY));
            }
        },
        {
            Header: "ICON",
            accessor: "icon",
            Cell: ({ row }) => {
                return (<>
                    <i className={row.original.iconClass?.iconClassname}></i>
                </>)
            }
        },
        {
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="btn-list-wrapper d-flex">
                        <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={() => { setSelectedGroup(row.original); }} />
                        <ButtonRound medium type={BtnTypes.DELETE} onClick={() => { softDeleteGroupItem(row.original.id); }} />
                    </div>
                </>)
            },
            disableSortBy: true
        }
    ], []);

    const fixedContentHeight = useMemo(() => {
        if (contentWrapperRef.current != null) {
            return contentWrapperRef.current.clientHeight;
        }
    }, [contentWrapperRef?.current]);

    return (<div className="d-flex flex-column h-100">
        <div className="flex-grow-1" ref={contentWrapperRef}>
            <div style={{ height: fixedContentHeight - 1 }}>
                <ReactTable
                    tblFooterClass={"rt-tbl-footer-white"}
                    columns={_CATEGORIES_COLUMN}
                    url={ApiUrl._API_GET_FACT_SHEET_GROUP_LIST}
                    refreshTable={refreshTable}
                    setPagination
                />
            </div>
        </div>
    </div>);
};

export default FactSheetGroupManagement;