import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, ApiKey, LoadingStateText, SweetAlert, ApiUrl } from 'util/Constant';
import { useForm } from 'react-hook-form';
import { CommonDao, CompanyDao, DocumentDao } from 'data';
import InputHoc from "components/form/InputHoc";
import Notiflix, { Block } from 'notiflix';
import { isEmpty } from 'lodash';
import BrandModal from 'components/modals/BrandModal';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { formatJurisdictionList } from 'util/Utility';

//<summary>
//Author: CJ(Jiann)
//</summary>
export const DocumentModal = props => {

    const { modal, setModal, setRefreshTable, documentTypeOpts, document, setDocument } = props;

    const _ref = useRef();
    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    const toggle = () => { setModal(!modal); setDocument({}) }

    const createDocument = async (data) => {

        Block.Circle("div#addDocumentModalContent", LoadingStateText._PLEASE_WAIT);

        if (document.id != null) data.id = document.id;

        let dao = new DocumentDao();

        await dao.createDocument(data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t('notiflix:SUCCESS_CHANGE_DOCUMENT'));
                setRefreshTable(prevState => !prevState);
                toggle();
            }
            else {
                Notiflix.Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t(SweetAlert._ERROR_HAS_OCCUR),
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove("div#addDocumentModalContent"));
    };

    useEffect(() => {
        if (document.id != null) {
            reset(document);
        }
        else
            reset({});
    }, [document])

    return (<>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={isEmpty(document) ? t("ADD_DOCUMENT") : t("EDIT_DOCUMENT")}
            customBody
        >
            <ModalBody id="addDocumentModalContent">
                <form onSubmit={handleSubmit(createDocument)} ref={_ref} autoComplete="off">
                    <Row>
                        <Col xl={12}>
                            <InputHoc name="name" label="NAME" inputType={InputTypes.INPUT} control={control} rules={{ required: 'Document Name is required' }} errors={errors?.name?.message} />
                            <InputHoc name="documentCode" label="systemConfiguration:CODE" inputType={InputTypes.INPUT} control={control} rules={{ required: 'Document Code is required' }} errors={errors?.documentCode?.message} />
                            <InputHoc name="jurisdictionId" label="JURISDICTION" inputType={InputTypes.SELECT}
                                options={jurisdictionOptions} control={control} rules={{ required: 'Please select Jurisdiction' }} errors={errors?.jurisdictionId?.message} />
                            <InputHoc name="documentTypeId" label="systemConfiguration:TYPE" inputType={InputTypes.SELECT}
                                options={documentTypeOpts} control={control} rules={{ required: 'Please select Document Type' }} errors={errors?.documentTypeId?.message} />
                            <InputHoc name="description" label="DESCRIPTION" inputType={InputTypes.TEXTAREA} ref={register} />
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(createDocument)()}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>);
};

export const DocumentTypeModal = props => {

    const { modal, setModal, documentType, setRefreshTable } = props;

    const _ref = useRef();
    const { t } = useTranslation();
    const { register, control, handleSubmit, reset } = useForm();

    useEffect(() => {
        if (documentType.id != null) {
            reset(documentType);
        }
    }, [documentType])

    const toggle = () => { setModal(!modal); reset({}); }

    const createDocumentType = async (data) => {

        Block.Circle("div#documentTypeModalContent", LoadingStateText._PLEASE_WAIT);

        if (documentType.id != null) {
            data.id = documentType.id;
        }

        let dao = new DocumentDao();

        await dao.createDocumentType(data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t('notiflix:SUCCESS_CHANGE_DOCUMENT_TYPE'));
                setRefreshTable(prevState => !prevState);
                toggle();
            }
            else {
                Notiflix.Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t(SweetAlert._ERROR_HAS_OCCUR),
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove("div#documentTypeModalContent"))
    };

    return (<>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={isEmpty(documentType) ? t("systemConfiguration:ADD_DOCUMENT_TYPE") : t("systemConfiguration:EDIT_DOCUMENT_TYPE")}
            customBody
        >
            <ModalBody id="documentTypeModalContent">
                <form onSubmit={handleSubmit(createDocumentType)} ref={_ref} autoComplete="off">
                    <Row>
                        <Col xl={12}>
                            <InputHoc name="name" label="NAME" inputType={InputTypes.INPUT} control={control} rules={{ required: true }} />
                            <InputHoc name="description" label="DESCRIPTION" inputType={InputTypes.TEXTAREA} ref={register} />
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(createDocumentType)()}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>);
}

export const DocumentTemplateModal = (props) => {

    const { templateModal, setTemplateModal, document, setRefreshTable } = props;

    const { t } = useTranslation();
    const _templateFormRef = useRef();
    const { register, control, handleSubmit } = useForm();

    const toggle = () => { setTemplateModal(!templateModal); }

    const createOrUpdateTemplate = async (data) => {

        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);

        let dao = new DocumentDao();

        let tempData = { id: document?.id, templateFileId: document.templateFileId ? document.templateFileId : null, ...data }

        await dao.createOrUpdateDocumentTemplate(tempData).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                setRefreshTable(prevState => !prevState);
                toggle();
            }
            else {
                Notiflix.Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t(SweetAlert._ERROR_HAS_OCCUR),
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove(".modal-content"));

    };

    return <>
        <BrandModal
            isOpen={templateModal}
            toggler={toggle}
            title={t("UPLOAD_TEMPLATE")}
            customBody
            modalSize="md"
        >
            <ModalBody id="uploadDocumentModalContent">
                <form onSubmit={handleSubmit(createOrUpdateTemplate)} ref={_templateFormRef} autoComplete="off">
                    <InputHoc name="templateAttachment" label="DOCUMENT_TEMPLATE" register={register} inputType={InputTypes.FILEUPLOAD} control={control} />
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(createOrUpdateTemplate)()}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>
}
