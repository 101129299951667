import React, { useState, useEffect, useRef, useMemo } from 'react';
import { WebUrl, ApiKey, AccountantStatus, DateFormat, LoadingStateText, InputTypes, BtnTypes, FileType } from 'util/Constant';
import { Card, CardBody, Row, Col, CardHeader, Collapse, ModalBody, ModalFooter } from "reactstrap";
import { AccountantDao } from '../../data';
import Moment from 'moment';
import Notiflix, { Block, Notify, Report } from "notiflix";
import _, { isEmpty } from 'lodash';
import InputHoc from 'components/form/InputHoc';
import { useForm } from 'react-hook-form';
import BrandModal from 'components/modals/BrandModal';

const RejectAttachmentModal = props => {
    const { modal, toggler, userId, rejectId, setRejectId, getAccountantProfile } = props;
    const { control, reset, handleSubmit, errors } = useForm();

    const accountantDao = useMemo(() => new AccountantDao());

    const rejectAttachment = async (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let postData = { ...rejectId, ...data };
        await accountantDao.adminRejectAttachment(userId, postData).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                getAccountantProfile(userId);
            }
            else {
                let errors = jsonResponse[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        }).finally(() => { setRejectId({}); Block.Remove(".modal-content"); toggler(); });
    }

    return <BrandModal
        isOpen={modal}
        toggler={toggler}
        title={"Reject Attachments?"}
        customBody
    >
        <form onSubmit={handleSubmit(rejectAttachment)} autoComplete="off">
            <ModalBody className="p-40 p-b-10 p-t-10">
                <h4>{"Fill in your remark below"}</h4>
                <InputHoc name="remark" inputType={InputTypes.TEXTAREA} error={errors?.remark} control={control} rules={{ required: true }} minRows={5} maxLength={450} />
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={() => toggler()}>Cancal</button>
                <button type="submit" className="btn btn-sm btn-themed btn-min-width">Yes</button>
            </ModalFooter>
        </form>
    </BrandModal>
}

export default RejectAttachmentModal;