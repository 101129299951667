import React, { useState, useMemo, useEffect, useCallback, useRef, useImperativeHandle } from 'react';

/// bootstrap & component
import Tree from 'react-d3-tree';
import * as d3 from 'd3';

import { Confirm, Block, Notify, Report } from 'notiflix';
import { ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, ModalBody, ModalFooter } from 'reactstrap';

/// recoils & dao
import { CompanyDao, CurrencyDao, OfficerDao } from 'data';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { companyOfficersState, IncorporationInfo, ubChildrenNodeListState } from 'recoil/Incorporation';

/// utils
import classnames from "classnames";
import ButtonRound from 'components/buttons/ButtonRound';
import _, { isEmpty, maxBy } from 'lodash';
import { formatJurisdictionList, generateRandString, stringIsNullOrEmpty } from 'util/Utility';
import { ShareholderType, BtnTypes, ApiKey, OfficerTypeID, IncorpComponentKeys, CreateOfficerStepFormRefKey, LoadingStateText, ApiUrl } from "util/Constant";
import SearchOrCreateCompanyOfficerWizard from './SearchOrCreateCompanyOfficerWizard';
import BrandModal from 'components/modals/BrandModal';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';


/// <summary>
/// Author: Lewis
/// </summary>
const UbTreeHierarchyComponent = (props) => {
    let {
        index,
        toggleStepComplete,
        setBackdropUbTreeShow,
        isBackdropUbTreeShow,
        companyId,
        isEdit,
    } = props;

    const stepAddBeneficiaryFormRef = useRef(null);
    const companyOfficersList = useRecoilValue(companyOfficersState);
    const incorporationInfo = useRecoilValue(IncorporationInfo);
    const setCompanyOfficerList = useSetRecoilState(companyOfficersState);
    const setChildrenNodes = useSetRecoilState(ubChildrenNodeListState);

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [shareOptions, setShareOptions] = useState([]);
    const [selectedTreeNode, setSelectedTreeNode] = useState({});
    const [treeData, setTreeData] = useState({});
    const [zoom, setZoom] = useState(0.5);
    const [maxDepthLevel, setMaxDepthLevel] = useState(0);
    const [toggleList, setToggleList] = useState([]);
    const [isNodeEdit, setNodeEdit] = useState(false);
    const [isWizardOfficerModalOpen, setWizardOfficerModalOpen] = useState(false);
    const [isAddBeneficiaryModalOpen, setAddBeneficiaryModalOpen] = useState(false);
    const [officerTypeId, setOfficerTypeId] = useState(OfficerTypeID._INDIVIDUAL);

    const { t } = useTranslation();
    /// <summary>
    /// Author: Lewis
    /// </summary>
    const { data: compOfficersJson, mutate: compOfficerMutate } = useSWR(companyId ? [
        ApiUrl._API_GET_COMPANY_OFFICER_LIST.replace(":companyId", companyId),
        ApiKey._API_GET
    ] : null);

    useEffect(() => {

        if (compOfficersJson?.[ApiKey._API_SUCCESS_KEY]) {
            setCompanyOfficerList(compOfficersJson[ApiKey._API_DATA_KEY]);
        }

    }, [compOfficersJson]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchCurrencies = async () => {

        let currencyDao = new CurrencyDao();

        await currencyDao.getCurrencyList().then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var currencyArr = [];
                response[ApiKey._API_DATA_KEY].map(item => {
                    currencyArr.push({ label: item.code, value: item.id });
                });
                setCurrencyOptions(currencyArr);
            }
            else {
                Report.Warning(t("notiflix:WARNING")+"!", response[ApiKey._API_MESSAGE_KEY], t("notiflix:BACK"));
            }
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchClassOfShare = async () => {

        let companyDao = new CompanyDao();

        await companyDao.getClassOfShareList().then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var classOfShareArr = [];
                response[ApiKey._API_DATA_KEY].map(item => {
                    classOfShareArr.push({ label: item.name, value: item.id });
                });
                setShareOptions(classOfShareArr);
            }
            else {
                Report.Warning(t("notiflix:WARNING")+"!", response[ApiKey._API_MESSAGE_KEY], t("BACK"));
            }
        });
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const formatTreeData = (details, depthLevel = 0) => {
        return {
            name: details.name,
            beneficiaryShares: details.companyBeneficiaryShares,
            beneficiaryDirectors: details.companyBeneficiaryDirectors,
            attributes: {
                type: details.nodeType,
                identifier: `${details.nodeType}-${generateRandString()}`,
                depth: depthLevel,
                id: details.id,
                officerId: details?.officer?.id ? details.officer.id : null,
                childrenId: details?.children !== undefined ? details.children.map((child) => child.id) : [],
                ubo: details.ubo,
                nationality: details.nodeType == "Individual" ? parseInt(details.officer.nationality) : null,
                refNumber: details?.officer?.pNumber ? details.officer.pNumber : null,
                englishName: details?.officer?.englishName ? details.officer.englishName : null,
                chineseName: details?.officer?.chineseName ? details.officer.chineseName : null,
                jurisdictionId: details?.officer?.jurisdictionId ? details.officer.jurisdictionId : null,
                ciNumber: details?.officer?.ciNumber ? details.officer.ciNumber : null,
                canApplyUbo: (depthLevel !== 0 && details?.children?.length == 0 && details.nodeType === "Individual") ? true : false,
            },
            children: details?.children !== undefined ? details.children.map((child) => formatTreeData(child, depthLevel + 1)) : [],
        };
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const appendNewNode = (details) => {

        let data = {
            name: details.name,
            beneficiaryShares: details.companyBeneficiaryShares,
            beneficiaryDirectors: details.companyBeneficiaryDirectors,
            children: [],
            attributes: {
                type: details.nodeType,
                identifier: `${details.nodeType}-${generateRandString()}`,
                ubo: details.ubo,
                id: details.id,
                officerId: details?.officer?.id ? details.officer.id : null,
                // depth: depthLevel,
                childrenId: details?.children !== undefined ? details.children.map((child) => child.id) : [],
                nationality: details.nodeType == "Individual" ? parseInt(details.officer.nationality) : null,
                englishName: details?.officer?.englishName ? details.officer.englishName : null,
                chineseName: details?.officer?.chineseName ? details.officer.chineseName : null,
                refNumber: details?.officer?.pNumber ? details.officer.pNumber : null,
                jurisdictionId: details?.officer?.jurisdictionId ? details.officer.jurisdictionId : null,
                ciNumber: details?.officer?.ciNumber ? details.officer.ciNumber : null,
            }
        };
        let tempData = treeData;

        const findNode = (nodeIdentifier, data, node) => {
            if (node.attributes.identifier === nodeIdentifier) {
                node.children.push(data);
            }
            else {
                for (var i = 0; i < node.children.length; i++) {
                    findNode(nodeIdentifier, data, node.children[i]);
                }
            }
        }

        findNode(selectedTreeNode.attributes.identifier, data, tempData);
        setTreeData({ ...tempData });
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    const deleteNode = (id, name) => {
        Confirm.Show(
            `${t("notiflix:DELETE")} ${name}?`,
            `${t('notiflix:OFFICER_UNDER')} <b>${name}</b> ${t("notiflix:WILL_BE_REMOVED")}`,
            t('notiflix:YES'),
            t('notiflix:NO'),
            async () => {

                let companyDao = new CompanyDao();

                await companyDao.removeBeneficiary(companyId, id).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(t("notiflix:REMOVE_BENEFICIARY_SUCCESS"));
                        retrieveBeneficiaryTree(companyId);
                        compOfficerMutate();
                        setChildrenNodes(prevState => {
                            var newState = [...prevState];
                            var index = newState.findIndex(x => x.id === id);

                            if (index > -1) {
                                newState.splice(index, 1);
                            }

                            return newState;
                        });
                    }
                    else {
                        Notify.Failure(`${responseJson[ApiKey._API_MESSAGE_KEY]}`);
                    }
                });
            }
        );
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    const retrieveBeneficiaryTree = async (companyId) => {

        let companyDao = new CompanyDao();

        let loadingTimeout = setTimeout(() => {
            Block.Circle('#treeWrapper');
        }, 250);

        (async () => {
            await companyDao.getBeneficiaryTree(companyId).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let treeData = formatTreeData(responseJson[ApiKey._API_DATA_KEY]);
                    setTreeData(treeData);
                }
            });
        })();

        clearTimeout(loadingTimeout);
        Block.Remove();
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    useMemo(() => {
        if (!stringIsNullOrEmpty(companyId)) {
            retrieveBeneficiaryTree(companyId);
        }
    }, [companyId, companyOfficersList]);


    /// <summary>
    /// Author: Lewis
    /// </summary>
    const flattenTreeData = (tree) => {
        return tree ? tree.reduce((result, item) => [
            ...result,
            {
                name: item.name,
                attributes: item.attributes,
                beneficiaryShares: item.beneficiaryShares,
                beneficiaryDirectors: item.beneficiaryDirectors,
            },
            ...flattenTreeData(item.children)
        ], []) : [];
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(companyId)) {
            fetchCurrencies();
            fetchClassOfShare();
        }
    }, [companyId]);

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    const applyAsUbo = (id, name) => {

        let companyDao = new CompanyDao();

        Confirm.Show(
            `UBO`,
            `<b>${name}</b> ${t('notiflix:WILL_BE_SELECT')} UBO.`,
            t('notiflix:YES'),
            t('notiflix:NO'),
            async () => {
                await companyDao.updateBeneficiaryUbo(companyId, id).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(t('notiflix:SELECT_SUCCESS'));
                        retrieveBeneficiaryTree(companyId);
                        compOfficerMutate();
                    }
                    else {
                        Notify.Failure(`${responseJson[ApiKey._API_MESSAGE_KEY]}`);
                    }
                });
            }
        );
    };

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const toggleNodeDropdown = (type, id) => {
        let tempToggleList = toggleList;
        let currentId = tempToggleList[type].id;

        tempToggleList[type].id = currentId == id ? null : id;

        setToggleList(JSON.parse(JSON.stringify(tempToggleList)));
    }

    /// <summary>
    /// Author: Robin
    /// </summary>
    const [refreshTree, setRefreshTree] = useState(false);
    const expandCollapseNode = useCallback((nodeData) => {
        if (nodeData && nodeData._collapsed) {
            Tree.expandNode(nodeData);
        } else {
            Tree.collapseNode(nodeData);
        }

        setRefreshTree((prevState) => !prevState);
    }, []);

    /// <summary>
    /// Author: Robin
    /// </summary>
    const [treeTranslation, setTreeTranslation] = useState(null);
    const _treeWrapperRef = useCallback((node) => {
        if (node !== null) {
            let x = node.clientWidth;
            let y = node.clientHeight;

            let initialX = (x / 2);
            let initialY = (y / 2);

            // Initial Translation
            setTreeTranslation({
                x: initialX,
                y: initialY
            });
        }
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const wizardFormToggler = () => {
        setWizardOfficerModalOpen(prevState => {
            if (prevState) {
                /// always reset state once the modal is closed
                setSelectedTreeNode({});
                setNodeEdit(false);
            }
            return !prevState;
        })
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const beneficiaryModalToggler = () => {
        setAddBeneficiaryModalOpen(prevState => !prevState);
    };

    const _TREE_OPTIONS = useMemo(() => {
        /// by default, the CustomTreeNodeLabel is injected with nodeData
        let nodeHandler = {
            deleteNode,
            applyAsUbo,
            setSelectedTreeNode,
            toggleNodeDropdown,
            expandCollapseNode,
            setNodeEdit,
        };

        const customNodeProps = {
            nodeHandler,
            currencyOptions,
            shareOptions,
            maxDepthLevel,
            toggleList,
            wizardFormToggler,
            beneficiaryModalToggler
        };

        return {
            data: treeData,
            orientation: 'vertical',
            translate: treeTranslation,
            transitionDuration: 0,
            pathFunc: 'step',
            allowForeignObjects: true,
            nodeLabelComponent: {
                render: <CustomTreeNodeLabel {...customNodeProps} isEdit={isEdit} />,
                foreignObjectWrapper: {
                    width: 300,
                    height: 100,
                    x: -150,
                    y: -100
                }
            },
            scaleExtent: {
                min: 0.25,
                max: 1
            },
            nodeSvgShape: { shape: 'none' },
            depthFactor: -250,
            separation: {
                siblings: 2,
                nonSiblings: 3
            },
            collapsible: false,
            zoomable: true,
            zoom: zoom,
            nodeSize: {
                x: 180,
                y: 150
            },
        };
    }, [
        refreshTree,
        zoom,
        treeData,
        setSelectedTreeNode,
        isEdit,
        currencyOptions,
        deleteNode,
        toggleNodeDropdown,
        expandCollapseNode,
        wizardFormToggler,
        beneficiaryModalToggler,
        applyAsUbo,
        maxDepthLevel,
        toggleList,
        shareOptions,
        setNodeEdit,
    ]);

    /// <summary>
    /// Author: Lewis
    /// Condition to be valid
    /// 1. All last level node must be individual
    /// 2. Ubo must be assigned
    /// 3. Each hierarchy level must assigned 100% shares
    /// </summary>
    const isTreeValid = useMemo(() => {

        var allLastNodes = [];
        var isHierarchyShareValid = true;
        var isUboValid = false;
        var isLastHierarchyAllIndOfficer = true;

        const recurseTreeData = (collection) => {

            _.each(collection, (x) => {

                if (x.children.length > 0) {

                    var totalPercentage = 0;
                    x.children.map(({ beneficiaryShares, attributes }) => {

                        if (attributes.ubo) isUboValid = true;
                        totalPercentage += _.sumBy(beneficiaryShares, "sharePercentage");

                    });

                    // round off to check whether is 100% assigned
                    if (Math.round(totalPercentage) < 100) isHierarchyShareValid = false;
                    recurseTreeData(x.children);
                }
                else {
                    /// last node is append to list for checking all last node is individual
                    allLastNodes.push(x);
                }

            });

        };

        if (!isEmpty(treeData)) {
            recurseTreeData([treeData]);
            isLastHierarchyAllIndOfficer = allLastNodes.every(y => y.attributes.type === "Individual");

            // get max level depth 
            var flattenTreeDataNode = flattenTreeData([treeData]);
            setMaxDepthLevel(maxBy(flattenTreeDataNode, (o) => o.attributes.depth).attributes.depth);
            setToggleList({ menu: {}, director: {} });
        }

        return (isHierarchyShareValid && isUboValid && isLastHierarchyAllIndOfficer) ? true : false;

    }, [treeData]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onCreateNewOfficer = async (officerFormValue) => {

        let officerDao = new OfficerDao();

        let postData = officerFormValue;

        Block.Circle("div.create-officer-address-attachment-2", LoadingStateText._PLEASE_WAIT);

        if (officerTypeId == OfficerTypeID._CORPORATE) {

            await officerDao.createCorporateOfficer(postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    stepAddBeneficiaryFormRef.current.onSuccessCreateOfficer(json[ApiKey._API_DATA_KEY]);
                }
                else {
                    Report.Warning(t('ADD_OFFICER'), t('notiflix:ADD_INDIVIDUAL_OFFICER_SUCCESS'), t("notiflix:BACK"));
                }
                Block.Remove("div.create-officer-address-attachment-2");
            });
        }
        else {

            await officerDao.createIndividualOfficer(postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    stepAddBeneficiaryFormRef.current.onSuccessCreateOfficer(json[ApiKey._API_DATA_KEY]);
                } else {
                    Report.Warning(t('ADD_OFFICER'), t('notiflix:ADD_CORPORATE_OFFICER_SUCCESS'), t("notiflix:BACK"));
                }
                Block.Remove("div.create-officer-address-attachment-2");
            });
        }
    }

    useEffect(() => {

        if (typeof (toggleStepComplete) == "function") {
            if (isTreeValid) {
                toggleStepComplete(IncorpComponentKeys._UB, true);
            }
            else {
                toggleStepComplete(IncorpComponentKeys._UB, false);
            }
        }

    }, [isTreeValid]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onAddBeneficiaryOfficer = (responseValue) => {
        // appendNewNode(responseValue);
        return;
    }

    return <>
        <div className="treeButtonPanel" style={
            {
                position: "absolute",
                display: "grid",
                bottom: "2rem",
                left: "2rem",
                gridGap: "0.2rem",
            }}>
            <ButtonRound medium type={BtnTypes.IMAGE} className="btn-themed"
                render={
                    <div><img alt="" src={require("../../assets/img/icon/ubo-zoom-in.svg")} /></div>
                }
                title={"Zoom In"} onClick={() => {
                    setZoom((prevState) => Math.min(1, prevState + 0.25));
                }} />
            <ButtonRound medium type={BtnTypes.IMAGE} className="btn-themed"
                render={
                    <div><img alt="" src={require("../../assets/img/icon/ubo-zoom-out.svg")} /></div>
                }
                title={"Zoom Out"} onClick={() => {
                    setZoom((prevState) => Math.max(0.25, prevState - 0.25));
                }} />
            {
                (typeof (setBackdropUbTreeShow) == "function" && !isBackdropUbTreeShow) &&
                <ButtonRound
                    medium
                    type={BtnTypes.IMAGE}
                    onClick={() => setBackdropUbTreeShow(true)}
                    title="Fullscreen view"
                    render={
                        <div><img alt="" src={require("../../assets/img/icon/fullscreen.svg")} /></div>
                    }
                />
            }
        </div>
        <div id="treeWrapper" ref={_treeWrapperRef}>
            {Object.values(treeData).length > 0 && <Tree {..._TREE_OPTIONS} />}
        </div>

        <BrandModal
            isOpen={isAddBeneficiaryModalOpen}
            toggler={beneficiaryModalToggler}
            customBody={true}
            title={t("ADD_BENEFICIARY_OFFICER")}
            className="add-beneficiary-officer-modal"
            modalSize="md"
        >
            <ModalBody className="p-0 text-center">
                <p>{t("DO_YOU_WANT_TO_ADD_PERSON_OF_INDIVIDUAL_OR_CORPORATE")}?</p>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-themed-default m-r-5"
                    onClick={() => {
                        setOfficerTypeId(OfficerTypeID._INDIVIDUAL);
                        beneficiaryModalToggler();
                        wizardFormToggler();
                    }}>
                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/individual.svg")} />{t("INDIVIDUAL")}
                </button>
                <button type="button" className="btn btn-themed-default m-l-5"
                    onClick={() => {
                        setOfficerTypeId(OfficerTypeID._CORPORATE);
                        beneficiaryModalToggler();
                        wizardFormToggler();
                    }}>
                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/corporate.svg")} />{t("CORPORATE")}
                </button>
            </ModalFooter>
        </BrandModal>

        <SearchOrCreateCompanyOfficerWizard
            ref={stepAddBeneficiaryFormRef}
            isOpen={isWizardOfficerModalOpen}
            toggler={wizardFormToggler}
            officerTypeId={officerTypeId}
            setOfficerTypeId={setOfficerTypeId}
            companyId={incorporationInfo.id}
            isEdit={isNodeEdit}
            onCreateNewOfficer={onCreateNewOfficer}
            onAddBeneficiaryOfficer={onAddBeneficiaryOfficer}
            parentKey={CreateOfficerStepFormRefKey._COMPANY_BENEFICIARY}
            beneficiaryId={selectedTreeNode?.attributes?.id}
            officerId={selectedTreeNode?.attributes?.officerId}
            deleteNode={deleteNode}
            retrieveBeneficiaryTree={retrieveBeneficiaryTree}
        />
    </>
}

export default UbTreeHierarchyComponent;

/// <summary>
/// Author: Lewis
/// </summary>
const CustomTreeNodeLabel = (props) => {

    let { nodeData, isEdit, ...restProps } = props;
    let {
        nodeHandler,
        currencyOptions,
        shareOptions,
        maxDepthLevel,
        toggleList,
        wizardFormToggler,
        beneficiaryModalToggler,
    } = restProps;

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    const { name, depth, beneficiaryShares, beneficiaryDirectors } = nodeData;
    const {
        type,
        nationality,
        id,
        childrenId,
        ubo,
        refNumber,
        englishName,
        chineseName,
        canApplyUbo,
        ...restNodeAttr
    } = nodeData.attributes;

    let isRoot = type !== ShareholderType.INDIVIDUAL && type !== ShareholderType.CORPORATE;
    let isShareholder = beneficiaryShares.length > 0;
    let svg = "";

    switch (type) {
        case ShareholderType.INDIVIDUAL:
            svg = <img
                className="entity-icon"
                src={ubo ? require('../../assets/img/icon/individual-ubo.svg') : require('../../assets/img/icon/individual.svg')}
            />
            break;
        case ShareholderType.CORPORATE:
            svg = <img className="entity-icon" src={require('../../assets/img/ui/corporate.svg')} />;
            break;
        default:
            break;
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const renderJurisdictionFlag = (jurisdictionId) => {

        let jurisdiction = jurisdictionOptions.filter(x => x.id == jurisdictionId);

        if (jurisdiction.length > 0) {

            let { label, shortCode } = jurisdiction[0];

            const getImgSrc = (label) => {
                try {
                    return require(`../../assets/img/country-flag/${label}.png`)
                }
                catch (e) {
                    console.log("No jurisdiction image found!")
                    return ""
                }
            };

            return <>
                <img className="node-flag" src={getImgSrc(label)} />
                <span>&nbsp;&nbsp;{shortCode}</span>
            </>
        }
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const addBtnHandler = (e) => {
        e.stopPropagation();
        nodeHandler.setSelectedTreeNode(nodeData);
        beneficiaryModalToggler();
    }

    const editBtnHandler = (e) => {
        e.stopPropagation();
        nodeHandler.setSelectedTreeNode(nodeData);
        nodeHandler.setNodeEdit(true);
        wizardFormToggler();
    }

    return (
        <div className="node-wrapper no-select">
            <div className="ubo-expand">
                {
                    nodeData && nodeData._children?.length > 0 &&
                    <div className="ubo-content">
                        <ButtonRound medium className="btn-themed" type={BtnTypes.IMAGE}
                            render={<img alt="" src={require("../../assets/img/icon/ubo-expand.svg")} />}
                            title={"Expand / Collapse"} onClick={() => {
                                nodeHandler.expandCollapseNode(nodeData)
                            }} />
                    </div>
                }
            </div>
            <div className="ubo-ribbon">
                {
                    ubo &&
                    <div className="ubo-content">
                        <img src={require("../../assets/img/ui/ubo-mark.svg")} />Selected as UBO
                    </div>
                }
            </div>
            <div className={classnames(`node-label-container node-${type.toLowerCase()}`,
                { 'node-ubo': ubo, 'node-not-shareholder': !isShareholder && !isRoot })}
            >
                <div className="node-label-content">
                    {
                        !isRoot ?
                            <div className="node-header">
                                <div className="d-flex align-items-center">
                                    <div className="entity-icon-wrapper">{svg}</div>
                                    <div style={{ display: "grid" }}>
                                        <p className="m-0 text-left"><strong title={englishName}>{englishName} {chineseName && `(${chineseName})`}</strong></p>
                                        {refNumber && <p className="node-pnum-text"><span>({refNumber})</span></p>}
                                        {
                                            type === ShareholderType.CORPORATE &&
                                            <div className="d-flex corp-detail-text">
                                                {renderJurisdictionFlag(restNodeAttr.jurisdictionId)}&nbsp;  <span><strong>CI No.:</strong>{restNodeAttr.ciNumber}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    isEdit &&
                                    <div className="button-wrapper">
                                        <ButtonDropdown
                                            isOpen={toggleList['menu'].id == id}
                                            toggle={() => nodeHandler.toggleNodeDropdown('menu', id)}
                                        >
                                            {
                                                type === ShareholderType.INDIVIDUAL ?
                                                    <DropdownToggle className="btn btn-rounded btn-themed"><i className="fa fa-ellipsis-v"></i></DropdownToggle> :
                                                    <DropdownToggle className="btn btn-rounded btn-themed"><i className="fas fa-plus"></i></DropdownToggle>
                                            }
                                            <DropdownMenu className="ub-node-dropdown">
                                                {
                                                    (type === ShareholderType.INDIVIDUAL) ?
                                                        (canApplyUbo &&
                                                            <DropdownItem onClick={() => nodeHandler.applyAsUbo(id, name)}>
                                                                <div><img src={require("../../assets/img/ui/ubo-mark.svg")} /></div>
                                                            </DropdownItem>
                                                        ) :
                                                        <DropdownItem onClick={addBtnHandler}>
                                                            <div><img src={require("../../assets/img/icon/add-person.svg")} /></div>
                                                        </DropdownItem>
                                                }
                                                {
                                                    /// first level of node only allow edit from company officers part (3rd step)
                                                    (depth > 1 &&
                                                        <DropdownItem onClick={editBtnHandler}>
                                                            <div><img src={require("../../assets/img/icon/edit.svg")} /></div>
                                                        </DropdownItem>
                                                    )
                                                }
                                                <DropdownItem onClick={() => nodeHandler.deleteNode(id, name)}>
                                                    <div><img src={require("../../assets/img/ui/delete.svg")} /></div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </div>
                                }
                            </div> : <strong title={name}>{name}</strong>
                    }
                    {
                        beneficiaryShares.length > 0 &&
                        <div className="share-list mt-1">
                            {
                                beneficiaryShares.map((s) => {
                                    let classOfShareName = shareOptions.length > 0 ? shareOptions.find(x => x.value == s.classOfShareId).label : '';
                                    let currency = currencyOptions.length > 0 ? currencyOptions.find(x => x.value == s.currencyId).label : '';

                                    return <div key={s.id}>
                                        <strong>{`${classOfShareName} : ${currency} (${s.sharePercentage} %)`}</strong>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="node-label-container-footer">
                {
                    beneficiaryDirectors.length > 0 &&
                    <ButtonDropdown className="director-dropdown" isOpen={toggleList['director'].id == id} toggle={() => nodeHandler.toggleNodeDropdown('director', id)}>
                        <DropdownToggle caret>Director</DropdownToggle>
                        <DropdownMenu>
                            {
                                beneficiaryDirectors.map((director) => {
                                    return <DropdownItem key={director.id}>{director.directorName}</DropdownItem>
                                })
                            }
                        </DropdownMenu>
                    </ButtonDropdown>
                }
            </div>
        </div>
    );
}