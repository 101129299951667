import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.jsx';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import SearchForm from './search/form.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';
import ShoppingCart from './dropdown/shoppingcart.jsx';

import { PageSettings } from './../../config/page-settings.js';
import { Can } from './../../config/user-access';
import { WebUrl, SidebarType, PolicyObjectConstant, PolicyActionConstant } from 'util/Constant.js';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
        this.state = { collapseMegaMenu: false };
        // const {
        //     isXsDevices,
        //     isSmDevices,
        // } = useContext(PageSettings);
    }

    toggleMegaMenu() {
        this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({ toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar, toggleActiveSidebar, sideBarButton, activeSidebar, isXsDevices }) => (
                    <div id="header" className="header navbar-default">
                        <div className="navbar-header">
                            {pageTwoSidebar && (
                                <button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            <Link to={WebUrl._GET_STARTED} className="navbar-brand"><span className="navbar-logo"></span> <b>YICOM</b></Link>

                            {//mobile view navbar move to header to have more space to view content
                                isXsDevices && (
                                    <div className="navbar-header-menu">
                                        {/* <SearchForm /> */}
                                        {
                                            <Can do={PolicyActionConstant.view} on={PolicyObjectConstant.backoffice}>
                                                <li onClick={toggleActiveSidebar}>
                                                    <a typeof="button" style={{ cursor: "pointer" }}>
                                                        <img alt="" src={require("../../assets/img/icon/switch-admin-acc.svg")} />
                                                    </a>
                                                </li>
                                            </Can>
                                        }

                                        <li>
                                            <Link to={WebUrl._PAYMENT_HISTORY} style={{ color: "#1f2225" }}><img alt="" src={require("../../assets/img/icon/payment-history.svg")} /></Link>
                                        </li>

                                        <ShoppingCart />

                                        <DropdownNotification />

                                        {pageHeaderLanguageBar && (
                                            <DropdownLanguage />
                                        )}

                                        <DropdownProfile />

                                        {pageTwoSidebar && (
                                            <li className="divider d-none d-md-block"></li>
                                        )}

                                        {pageTwoSidebar && (
                                            <li className="d-none d-md-block">
                                                <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                                                    <i className="fa fa-th"></i>
                                                </Link>
                                            </li>
                                        )}
                                    </div>    
                                )
                            }
                            {pageHeaderMegaMenu && (
                                <button type="button" className="navbar-toggle pt-0 pb-0 mr-0" onClick={this.toggleMegaMenu}>
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                    </span>
                                </button>
                            )}
                            {!pageSidebar && pageTopMenu && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            {pageSidebar && (
                                <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                        </div>

                        {pageHeaderMegaMenu && (
                            <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
                        )}

                        <ul className="navbar-nav navbar-right"> 
                            {
                                !isXsDevices && (<>
                                    {/* <SearchForm /> */}
                                    {
                                        <Can do={PolicyActionConstant.view} on={PolicyObjectConstant.backoffice}>
                                            <li onClick={toggleActiveSidebar}>
                                                <a typeof="button" style={{ cursor: "pointer" }}>
                                                    <img alt="" src={require("../../assets/img/icon/switch-admin-acc.svg")} />
                                                </a>
                                            </li>
                                        </Can>
                                    }

                                    <li>
                                        <Link to={WebUrl._PAYMENT_HISTORY} style={{ color: "#1f2225" }}><img alt="" src={require("../../assets/img/icon/payment-history.svg")} /></Link>
                                    </li>

                                    <ShoppingCart />

                                    <DropdownNotification />

                                    {pageHeaderLanguageBar && (
                                        <DropdownLanguage />
                                    )}

                                    <DropdownProfile />

                                    {pageTwoSidebar && (
                                        <li className="divider d-none d-md-block"></li>
                                    )}

                                    {pageTwoSidebar && (
                                        <li className="d-none d-md-block">
                                            <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                                                <i className="fa fa-th"></i>
                                            </Link>
                                        </li>
                                    )}
                                </>)
                            }
                        </ul>
                    </div>
                )}
            </PageSettings.Consumer>
        )
    }
}

export default Header;
