import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { InputTypes, ApiKey, OfficerTypeString, OfficerTypeID, DateFormat, WebUrl, ApiUrl } from "util/Constant";
import { OfficerDao } from "data";
import ModalImage from "react-modal-image";
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactTable from 'components/react-table/ReactTable';
import { doubleClickNavigate, formatCountryList, stringIsNullOrEmpty } from '../../util/Utility';
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import InputHoc from "components/form/InputHoc";
import { useForm } from "react-hook-form";

const _ATTACHMENT_KEY = {
    _IDENTITY: "Identity",
    _ADDRESS: "Address",
    _CERT: "Cert"
}

const OfficerPersonalDetail = (props) => {
    let { fetchOfficerId, companyId } = props;
    const { t } = useTranslation();
    const [openIndex, setOpenIndex] = useState(0);
    const [officerDetail, setOfficerDetail] = useState({});
    const [officerType, setOfficerType] = useState(OfficerTypeID._INDIVIDUAL);
    const [identityAttachmentUrl, setIdentityAttachmentUrl] = useState([]);
    const [addressProofAttachmentUrl, setAddressProofAttachmentUrl] = useState([]);
    const [certAttachmentUrl, setCertAttachmentUrl] = useState([]);
    const [attachmentFile, setAttachmentFile] = useState({});

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    /// <summary>
    /// Author: CJ(Jiann)
    /// </summary>
    const _DETAIL_KEY = {
        _PERSONAL_DETAIL: "Personal Detail",
        _ADDRESS_INFO: "Address Info",
        _ATTACHMENT: "Attachment",
        _RELATED_COMPANIES: "Related Companies",
    }

    const _CARD_HEADER = {
        [_DETAIL_KEY._PERSONAL_DETAIL]: { id: 0, title: t("PERSONAL_DETAIL"), component: PersonalDetail, icon: require("../../assets/img/icon/edit.svg") },
        [_DETAIL_KEY._ADDRESS_INFO]: { id: 1, title: t("ADDRESS_INFO"), component: AddressDetail, icon: require("../../assets/img/icon/address-info.svg") },
        [_DETAIL_KEY._ATTACHMENT]: { id: 2, title: t("ATTACHMENT"), component: AttachemntDetail, icon: require("../../assets/img/icon/attachment.svg") },
        [_DETAIL_KEY._RELATED_COMPANIES]: { id: 3, title: t("RELATED_COMPANIES"), component: OfficerRelatedCompaniesComponent, icon: require("../../assets/img/icon/corporate.svg") },
    };

    useEffect(() => {
        if (!stringIsNullOrEmpty(fetchOfficerId)) {
            getOfficerProfile(fetchOfficerId);
        }
    }, [fetchOfficerId]);

    const getOfficerProfile = async (officerId) => {
        let dao = new OfficerDao();
        await dao.getOfficerById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];

                setOfficerType(data.officerTypeId);

                var identityAttachment = data?.idAttachment?.fileMeta;
                var addressProofAttachment = data?.addressAttachment?.fileMeta;
                var certAttachmentFileMeta = data?.certAttachment?.fileMeta;

                if (identityAttachment) {
                    let tempFile = identityAttachment.find(f => f.id === data?.idAttachment?.currentVersionFileMetaId);
                    if (tempFile) {
                        setAttachmentFile(prevState => ({
                            ...prevState,
                            [_ATTACHMENT_KEY._IDENTITY]: [tempFile]
                        }));
                    }
                    else {
                        identityAttachment.map(item => {
                            return (
                                setAttachmentFile(prevState => ({
                                    ...prevState,
                                    [_ATTACHMENT_KEY._IDENTITY]: [item]
                                }))
                            )
                        });
                    }
                    // var paths = {};
                    // setIdentityAttachmentUrl(paths);
                }
                else {
                    setAttachmentFile(prevState => ({
                        ...prevState,
                        [_ATTACHMENT_KEY._IDENTITY]: null
                    }))
                }

                if (addressProofAttachment) {
                    let tempFile = addressProofAttachment.find(f => f.id === data?.addressAttachment?.currentVersionFileMetaId);
                    if (tempFile) {
                        setAttachmentFile(prevState => ({
                            ...prevState,
                            [_ATTACHMENT_KEY._ADDRESS]: [tempFile]
                        }));
                    }
                    else {
                        addressProofAttachment.map(item => {
                            return (
                                setAttachmentFile(prevState => ({
                                    ...prevState,
                                    [_ATTACHMENT_KEY._ADDRESS]: [item]
                                }))
                            )
                        });
                    }
                    // var paths = {}
                    // setAddressProofAttachmentUrl(paths);
                }
                else {
                    setAttachmentFile(prevState => ({
                        ...prevState,
                        [_ATTACHMENT_KEY._ADDRESS]: null
                    }))
                }

                if (certAttachmentFileMeta) {
                    let tempFile = certAttachmentFileMeta.find(f => f.id === data?.certAttachment?.currentVersionFileMetaId);
                    if (tempFile) {
                        setAttachmentFile(prevState => ({
                            ...prevState,
                            [_ATTACHMENT_KEY._CERT]: [tempFile]
                        }));
                    }
                    else {
                        certAttachmentFileMeta.map(item => {
                            return (
                                setAttachmentFile(prevState => ({
                                    ...prevState,
                                    [_ATTACHMENT_KEY._CERT]: [item]
                                }))
                            )
                        });
                    }
                    // var paths = {}
                    // setCertAttachmentUrl(paths);
                }
                else {
                    setAttachmentFile(prevState => ({
                        ...prevState,
                        [_ATTACHMENT_KEY._CERT]: null
                    }))
                }
                setOfficerDetail(data);
            }
        })
    }

    return (<div className="mx-3">
        {
            Object.keys(_CARD_HEADER).map((key, index) => {
                return (<>
                    <Card key={index}>
                        <CardHeader onClick={() => { setOpenIndex(index) }} style={{
                            backgroundColor: "#EDEEF1"
                        }} ><img className="mr-3" src={_CARD_HEADER[key].icon} />{_CARD_HEADER[key].title} <i class="fas fa-caret-down right"></i>
                        </CardHeader>
                        <Collapse isOpen={openIndex == index ? true : false}>
                            <CardBody>
                                {_CARD_HEADER[key].component && React.createElement(_CARD_HEADER[key].component, {
                                    officerDetail: officerDetail,
                                    officerType: officerType,
                                    attachmentFile: attachmentFile,
                                    officerId: fetchOfficerId,
                                    countryOptions: countryOptions,
                                    companyId
                                })}
                            </CardBody>
                        </Collapse>
                    </Card>
                </>)
            })
        }
    </div>)
}

const PersonalDetail = props => {
    let { officerDetail, officerType, countryOptions } = props;
    const { t } = useTranslation();

    return (<>
        {
            officerType == OfficerTypeString._INDIVIDUAL || officerType == OfficerTypeID._INDIVIDUAL
                ?
                <>
                    <table className="company-officer-profile-detail table-responsive x-scrollbar-2">
                        <tbody>
                            <tr>
                                <td className="text-label">{t("ENGLISH_NAME")}</td>
                                <td>{officerDetail.englishName}</td>
                                <td className="text-label">{t("ENGLISH_SURNAME")}</td>
                                <td>{officerDetail?.englishSurname}</td>
                            </tr>
                            {
                                (officerDetail?.chineseName || officerDetail?.chineseSurname) &&
                                <tr>
                                    <td className="text-label">{t("CHINESE_NAME")}</td>
                                    <td>{officerDetail?.chineseName}</td>
                                    <td className="text-label">{t("CHINESE_SURNAME")}</td>
                                    <td>{officerDetail?.chineseSurname}</td>
                                </tr>
                            }
                            <tr>
                                <td className="text-label">{t("IDENTITY_TYPE")}</td>
                                <td>{officerDetail?.identityType}</td>
                                <td className="text-label">{t("IDENTITY_NUM")}</td>
                                <td>{officerDetail?.identityNumber}</td>
                            </tr>
                            <tr>
                                <td className="text-label">{t("NATIONALITY")}</td>
                                <td>{countryOptions.find(c => c.value == officerDetail?.nationality)?.label}</td>
                                <td className="text-label">{t("PHONE_NUMBER")}</td>
                                <td>{officerDetail?.phoneNumber}</td>
                            </tr>
                            <tr>
                                <td className="text-label">{t("EMAIL")}</td>
                                <td>{officerDetail?.email}</td>
                                {
                                    officerDetail?.weChat && <>
                                        <td className="text-label">{t("WECHAT")}</td>
                                        <td>{officerDetail?.weChat}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className="text-label">{t("DATE_OF_BIRTH")}</td>
                                <td>{moment(officerDetail?.dateOfBirth).format(DateFormat._DATE_ONLY)}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
                :
                <>
                    <table className="company-officer-profile-detail table-responsive">
                        <tbody>
                            <tr>
                                <td className="text-label">{t("ENGLISH_NAME")}</td>
                                <td>{officerDetail.englishName}</td>
                                {
                                    officerDetail?.chineseName && <>
                                        <td className="text-label">{t("CHINESE_NAME")}</td>
                                        <td>{officerDetail?.chineseName}</td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td className="text-label">{t("JURISDICTION")}</td>
                                <td>{officerDetail?.jurisdictionName}</td>
                                <td className="text-label">{t("DATE_OF_INCORP")}</td>
                                <td>{moment(officerDetail?.dateOfIncorporation).format(DateFormat._DATE_ONLY)}</td>
                            </tr>
                            <tr>
                                <td className="text-label">{t("CI_NUM")}</td>
                                <td>{officerDetail?.ciNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
        }

    </>)
}

const AddressDetail = props => {
    let { officerDetail } = props;
    const { t } = useTranslation();

    return (<>
        <table className="company-officer-profile-detail table-responsive">
            <tbody>
                <tr>
                    <td className="text-label">{t("ADDRESS")}</td>
                    <td>{officerDetail && officerDetail.address && officerDetail.address.address1}</td>
                </tr>
                <tr>
                    <td className="text-label">{t("COUNTRY")}</td>
                    <td>{officerDetail && officerDetail.address && officerDetail.address.countryName}</td>
                </tr>
            </tbody>
        </table>
    </>);
}


const AttachemntDetail = props => {
    let { officerDetail, officerType, attachmentFile } = props;
    const { t } = useTranslation();
    const { control, reset } = useForm();

    useEffect(() => {
        if (attachmentFile) {
            reset(attachmentFile);
        }
    }, [reset, attachmentFile]);

    return (<>
        <table className="company-officer-profile-detail w-100">
            <tbody>

                {
                    officerType == OfficerTypeString._INDIVIDUAL || officerType == OfficerTypeID._INDIVIDUAL
                        ?
                        <>
                            <tr>
                                <td className="text-label">{t("ID_ATTACHMENT")}</td>
                                <td className="text-label">{t("ADDRESS_PROOF")}</td>
                            </tr>
                            <tr>
                                <td>
                                    <InputHoc
                                        name={_ATTACHMENT_KEY._IDENTITY}
                                        inputType={InputTypes.FILEUPLOAD}
                                        control={control}
                                        disabled={true}
                                        defaultValue={null}
                                    />
                                    {/* <ModalImage
                                        small={attachmentFile[_ATTACHMENT_KEY._IDENTITY]?.path}
                                        medium={attachmentFile[_ATTACHMENT_KEY._IDENTITY]?.path}
                                        large={attachmentFile[_ATTACHMENT_KEY._IDENTITY]?.path}
                                    /> */}
                                </td>
                                <td>
                                    <InputHoc
                                        name={_ATTACHMENT_KEY._ADDRESS}
                                        inputType={InputTypes.FILEUPLOAD}
                                        control={control}
                                        disabled={true}
                                        defaultValue={null}
                                    />
                                    {/* <ModalImage
                                        small={attachmentFile[_ATTACHMENT_KEY._ADDRESS]?.path}
                                        medium={attachmentFile[_ATTACHMENT_KEY._ADDRESS]?.path}
                                        large={attachmentFile[_ATTACHMENT_KEY._ADDRESS]?.path}
                                    /> */}
                                </td>
                            </tr>

                        </>
                        :
                        <>
                            <tr>
                                <td className="text-label">{t("CERT_ATTACHMENT")}</td>
                                <td className="text-label"></td>
                            </tr>
                            <tr>
                                <td>
                                    <InputHoc
                                        name={_ATTACHMENT_KEY._CERT}
                                        inputType={InputTypes.FILEUPLOAD}
                                        control={control}
                                        disabled={true}
                                        defaultValue={null}
                                    />
                                    {/* <ModalImage
                                        small={attachmentFile[_ATTACHMENT_KEY._CERT]?.path}
                                        medium={attachmentFile[_ATTACHMENT_KEY._CERT]?.path}
                                        large={attachmentFile[_ATTACHMENT_KEY._CERT]?.path}
                                    /> */}
                                </td>
                                <td></td>
                            </tr>
                        </>
                }
            </tbody>
        </table>
    </>);
}

const OfficerRelatedCompaniesComponent = props => {
    const { officerId, companyId } = props;
    const { state } = useLocation();
    const _history = useHistory();
    const [officerCompanyList, setOfficerCompanyList] = useState([]);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        getOfficerCompanyList();
    }, [officerId])

    const getOfficerCompanyList = async () => {
        let dao = new OfficerDao();
        await dao.getOfficerCompanyListById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setOfficerCompanyList(data);
            }
        })
    }

    const _RELATED_COMPANIES_COLUMNS = useMemo(() => [
        {
            Header: 'C#',
            Cell: ({ row }) => { return (<>{row.original.company.cNumber}</>) }
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => { return (<>{row.original.company.englishName}</>) }
        },
        {
            Header: 'DATE_OF_INCORP',
            Cell: ({ row }) => {
                return (<>
                    {
                        row.original.company.incorporationDate
                            ?
                            moment(row.original.company.incorporationDate).format(DateFormat._DATE_ONLY)
                            : "N/A"
                    }
                </>)
            }
        },
        {
            Header: 'OFFICER_ROLE',
            Cell: ({ row }) => { return (<>{row.original.roleLabel}</>) }
        }
    ])

    return (<>
        <ReactTable
            columns={_RELATED_COMPANIES_COLUMNS}
            data={officerCompanyList}
            getRowProps={(row, index) => {
                return {
                    ...doubleClickNavigate(_history, WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(':companyId', selectedRow)),
                    onClick: (e) => {
                        setSelectedRow(row.index);
                    },
                    style: {
                        background: row.index == selectedRow ? 'lightgray' : null,
                        color: row.index == selectedRow ? 'white' : 'black',
                    },
                }
            }}
            setPagination />
    </>);
}



export default OfficerPersonalDetail;