import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ApiKey, BtnTypes, KycFormKeys, SweetAlert } from 'util/Constant';
import 'awesome-steps/dist/style.css';
import Notiflix, { Confirm, Notify } from 'notiflix';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash/object';
import { CompanyDao } from 'data';
import { IncorporationInfo, kycQuestionState } from 'recoil/Incorporation';
import ButtonRound from 'components/buttons/ButtonRound';
import {
    PropertyInvestmentForm,
    StockInvestmentForm,
    OtherForm,
    TradingForm,
} from './forms/KycForm';
import BrandModal from 'components/modals/BrandModal';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Chris
/// </summary>
const KycNatureOfBusiness = () => {
    const { t } = useTranslation();
    const incorporationInfo = useRecoilValue(IncorporationInfo);
    const [kycQuestion, setKycQuestion] = useRecoilState(kycQuestionState);

    const { register } = useForm({});

    const initialState = {
        [KycFormKeys.TRADING]: { name: 'TRADING', component: TradingForm, status: false, isModal: true, isOpen: false },
        [KycFormKeys.PROPERTY_INVESTMENT]: { name: 'PROPERTY_INVESTMENT', component: PropertyInvestmentForm, status: false, isModal: true, isOpen: false },
        [KycFormKeys.STOCK_INVESTMENT]: { name: 'INVESTMENT_IN_STOCK_EXC_OR_SEC', component: StockInvestmentForm, status: false, isModal: true, isOpen: false },
        [KycFormKeys.OTHER]: { name: 'OTHER', component: OtherForm, status: false, isModal: false, isOpen: false },
    };

    const [natureOfBusinessState, setNatureOfBusinessState] = useState(initialState);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const toggleOption = async (key) => {
        if (natureOfBusinessState[key].status) {
            Confirm.Show(
                t(SweetAlert._DELETE_CONFIRMATION),
                `${t("REMOVE_OPTION")} <b>${t(natureOfBusinessState[key].name)}</b>?<br/>${t("YOU_WILL_NEED_ENTER_RELEVANT_DETAIL_AGN")}`,
                t("YES"),
                t("NO"),
                async () => {

                    let data = {
                        natureOfBusiness: {
                            [`${key}`]: kycQuestion?.natureOfBusiness?.[`${key}`]
                        }
                    }

                    let dao = new CompanyDao();
                    await dao.deleteKycQuestions(incorporationInfo?.id, data).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {

                            var responseData = response[ApiKey._API_DATA_KEY];
                            setKycQuestion((prevState) => {
                                var newState = { ...prevState };
                                newState.natureOfBusiness = { ...prevState?.natureOfBusiness }
                                delete newState.natureOfBusiness[key];
                                return newState;
                            });

                            Notify.Success("KYC Questions updated Successfully");

                            if (natureOfBusinessState[key].isOpen && !natureOfBusinessState[key].isModal) {
                                toggleDetails(key);
                            }
                        };
                    });
                }
            )
        }
        else {
            toggleOpenByKey(key);
        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggleOpenByKey = (key) => {
        setNatureOfBusinessState(prevState => {
            var newState = { ...prevState };
            newState[key].isOpen = !prevState[key].isOpen;
            return newState;
        });
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const toggleDetails = (key) => {
        toggleOpenByKey(key);
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const cancelHandler = (key) => {
        toggleOpenByKey(key);
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const submitHandler = async (data) => {
        data = {
            natureOfBusiness: {
                ...data
            }
        }

        let dao = new CompanyDao();
        await dao.saveKycQuestions(incorporationInfo?.id, data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var responseData = response[ApiKey._API_DATA_KEY];
                setKycQuestion((prevState) => ({
                    ...prevState,
                    natureOfBusiness: {
                        ...prevState?.natureOfBusiness,
                        ...responseData?.natureOfBusiness
                    }
                }));

                Notify.Success(t(SweetAlert._OPERATION_SUCCESS));

                let keys = Object.keys(responseData.natureOfBusiness)[0];
                if (natureOfBusinessState[keys].isModal) {
                    toggleOpenByKey(keys);
                }
            };
        });
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (kycQuestion?.natureOfBusiness) {
            let natureOfBusiness = kycQuestion.natureOfBusiness;

            if (natureOfBusiness) {
                setNatureOfBusinessState(prevState => {
                    var newState = { ...prevState };

                    newState[KycFormKeys.TRADING].status = _.has(natureOfBusiness, KycFormKeys.TRADING);
                    newState[KycFormKeys.PROPERTY_INVESTMENT].status = _.has(natureOfBusiness, KycFormKeys.PROPERTY_INVESTMENT);
                    newState[KycFormKeys.STOCK_INVESTMENT].status = _.has(natureOfBusiness, KycFormKeys.STOCK_INVESTMENT);
                    newState[KycFormKeys.OTHER].status = _.has(natureOfBusiness, KycFormKeys.OTHER);

                    return newState;
                });
            }
        }
    }, [kycQuestion]);

    return <>
        <tr>
            <td className="p-0">
                <Row>
                    <Col xl={3} style={{ backgroundColor: "#F3F4F6", display: 'flex' }}>
                        <p className="m-auto"><strong>{t("NATURE_OF_BUSINESS")}</strong></p>
                    </Col>
                    <Col xl={9} className="p-t-10 p-b-10">
                        {
                            Object.keys(natureOfBusinessState).map((key, index) => {
                                return (
                                    <React.Fragment key={key}>
                                        <div className="d-flex">
                                            <div class="checkbox-wrapper p-3" style={{ minWidth: "60%" }}>
                                                <div class="exp">
                                                    <div class="checkbox">
                                                        <input
                                                            id={`natureOfBusiness_${key}`}
                                                            type="checkbox"
                                                            onClick={() => toggleOption(key)}
                                                            checked={natureOfBusinessState[key].status}
                                                            name={key}
                                                            ref={register}
                                                        />
                                                        <label for={`natureOfBusiness_${key}`}>
                                                            <span></span>
                                                            {t(natureOfBusinessState[key].name)}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ minWidth: "40%" }}>
                                                {
                                                    !isEmpty(kycQuestion?.natureOfBusiness?.[key]) &&
                                                    <ButtonRound className="m-auto" medium type={BtnTypes.FA_VIEW} onClick={() => toggleDetails(key)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {
                                                (key == KycFormKeys.OTHER && natureOfBusinessState[key].isOpen &&
                                                    React.createElement(natureOfBusinessState[KycFormKeys.OTHER].component, { cancelHandler, setState: submitHandler, defaultValues: kycQuestion?.natureOfBusiness?.[key] }))
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Col>
                </Row>
            </td>
        </tr>
        {
            Object.entries(natureOfBusinessState).map(([k, v], index) => {
                if (v.isModal) {
                    return <React.Fragment key={k}>
                        <BrandModal
                            isOpen={v.isOpen}
                            toggler={() => cancelHandler(k)}
                            title={t(v.name)}
                            customBody={true}
                        >
                            {
                                React.createElement(v.component, { cancelHandler, setState: submitHandler, defaultValues: kycQuestion?.natureOfBusiness?.[k] })
                            }
                        </BrandModal>
                    </React.Fragment>
                }
            })
        }
    </>
}

export default KycNatureOfBusiness;