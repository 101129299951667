import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { stringIsNullOrEmpty } from 'util/Utility';

/// <summary>
/// Author: Chris
/// </summary>
const Input = forwardRef((props, ref) => {
    const {
        error,
        readOnly,
        useNumberFormatter,
        className,
        control,
        onChange: propsOnChange,
        defaultValue: propsDefaultValue,
        autoComplete,
        rules,
        ...rest
    } = props;

    const [hasError, setHasError] = useState();
    const [defaultValue, setDefaultValue] = useState(null);

    const inputClasses = classnames(
        'form-control-md', {
        'has-error': hasError,
        'form-control': !readOnly,
        'form-control-plaintext': readOnly
    },
        className
    );
    /// <summary>
    /// Author: Lewis
    /// Disabled number input field on scroll change value
    /// </summary>
    const onWheel = (e) => {
        if (rest.type == "number") {
            e.target.blur();
        }
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    //useEffect(() => {
    //    if (!stringIsNullOrEmpty(propsDefaultValue)) setDefaultValue(propsDefaultValue);
    //}, [propsDefaultValue]);

    if (control != undefined) {
        return (
            <Controller
                {...rest}
                rules={{
                    ...rules, validate: async (value) => {
                        if (rules?.required == true || typeof rules?.required === 'string') {
                            if (typeof value === 'string') {
                                if (!stringIsNullOrEmpty(value.trim()) && typeof (rules?.validate) === 'function') {
                                    return await rules?.validate(value);
                                }
                                return !stringIsNullOrEmpty(value.trim());
                            }
                        }
                        return true;
                    }
                }}
                defaultValue={propsDefaultValue}
                control={control}
                render={({ onChange, onBlur, value, name, ...otherProps }) => {
                    return (
                        <input
                            ref={ref}
                            name={name}
                            className={inputClasses}
                            disabled={rest?.disabled ? true : false}
                            onWheel={onWheel}
                            onChange={(e) => {
                                onChange(e.target.value);
                                propsOnChange && propsOnChange(e)
                            }}
                            onBlur={onBlur}
                            value={defaultValue !== null ? defaultValue : value ?? ""}
                            //value={!stringIsNullOrEmpty(propsDefaultValue) ? propsDefaultValue : value }
                            autoComplete={stringIsNullOrEmpty(autoComplete) ? "off" : autoComplete}
                            {...rest}
                            {...otherProps}
                        />
                    )
                }}
            />
        )
    }

    return <input ref={ref} {...rest}
        autoComplete={stringIsNullOrEmpty(autoComplete) ? "off" : autoComplete}
        className={inputClasses}
        readOnly={readOnly}
        onWheel={onWheel}
        defaultValue={propsDefaultValue}
        onChange={(e) => {
            propsOnChange && propsOnChange(e)
        }}
    />;
});

Input.defaultProps = {
    placeholder: " ",
    maxLength: 255
}

export default Input;