import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { generateRandString } from 'util/Utility';
import { BiCheckboxChecked } from 'react-icons/bi';

/// <summary>
/// Author: Chris
/// </summary>
const Checkbox = forwardRef((props, ref) => {
    const {
        options,
        name,
        isSwitcher,
        checkboxInline,
        defaultValue,
        ...otherProps
    } = props;

    const checkboxClasses = classnames(
        { 'checkbox checkbox-css': !isSwitcher },
        { 'm-b-10': !checkboxInline },
        { 'switcher': isSwitcher },
    );

    /// <summary>
    /// Author: Chris
    /// </summary>
    const shouldCheck = (checkValue) => {
        if (typeof defaultValue === 'array' || typeof defaultValue === 'object') {
            return defaultValue.includes(checkValue);
        }

        return defaultValue === checkValue;
    }

    let checkboxes = options.map((item, key) => {
        let id = `${name}-${generateRandString()}`;
        return (
            <div key={key} className={checkboxClasses}>
                <input ref={ref} id={id} name={name} value={item.value} {...otherProps} defaultChecked={defaultValue && shouldCheck(item.value)} />
                <label htmlFor={id}>{item.label}</label>
            </div>
        )
    });

    if (checkboxInline) {
        return (
            <div className='d-flex justify-content-between flex-wrap'>
                {checkboxes}
            </div>
        );
    }

    return <>
        {checkboxes}
    </>;
});

Checkbox.defaultProps = {
    type: 'checkbox',
    checkboxInline: false,
    isSwitcher: false,
}

export default Checkbox;