import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Input, Select, Radio } from "components/form";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";
import { InputTypes } from "util/Constant";
import InputHoc from "components/form/InputHoc";
import classname from "classnames";
import { uniqueId } from 'lodash';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Chris
/// - Dynamic form builder based on an array of inputs.
/// - REQUIRED to pass in an onSubmit function, formRef, and fields.
/// - Fields should be an array in this format:-
/*
	[
		- each curly brace represents a form <Row></Row>.
		{ 
			- any kind of row options available (reactstrap) can be passed here.
			rowOptions: { options },

			- Columns should be an array in this format:-
			columns: [
				- each curly brace represents a <Col></Col>.
				- all required fields for typical input should be passed here as well as any extra options.
				- input options is required to determine what kind of input to render, but defaults to <Input /> if not defined.
				- if you need to show/hide inputs based on certain conditions, you may pass in a toDisplay flag
				{ label, name, input, defaultValue, toDisplay, ...options }
			],

			- this is optional if you wish to render a <hr/> after each row.
			seperator: true 
		}
	]
*/
/// </summary>
const FormBuilder = (props) => {
	const {
		fields,
		watchFields,
		onSubmit,
		formRef,
		readOnly,
		defaultValues,
		formClasses,
		setHookFormMethods,
		reset: triggerReset,
		resetTriggerReset,
		autoComplete,
		resetValues,
	} = props;
	const _FORM_METHODS = useForm();
	let {
		register,
		control,
		handleSubmit,
		errors,
		watch,
		setValue,
		reset,
		setError,
		...restHookFormProps
	} = _FORM_METHODS;

	const { t } = useTranslation();
	const _FORM_ID = useMemo(() => uniqueId('form-id-') + '-', []);
	const watchedFields = watch(watchFields);

	/// <summary>
	/// Author: Lewis
	/// </summary>
	useEffect(() => {

		if (typeof (setHookFormMethods) == "function") {
			setHookFormMethods({ ..._FORM_METHODS });
		}
	}, [setHookFormMethods]);

	/// <summary>
	/// Author: Chris
	/// </summary>
	const registerWithRules = (rules) => {
		return rules ? register(rules) : register;
	};

	/// <summary>
	/// Author: Chris
	/// - Determines the react hook form control for input
	/// </summary>
	const getFormType = (type, rules) => {
		switch (type) {
			case InputTypes.NUMBER_INPUT:
			case InputTypes.SELECT:
			case InputTypes.PHONE:
			case InputTypes.ATTACHMENT:
			case InputTypes.DATEPICKER:
			case InputTypes.FILEUPLOAD:
			case InputTypes.IMAGE_UPLOADER:
				return { control };
			default:
				return { ref: registerWithRules(rules) };
		}
	};

	/// <summary>
	/// Author: Chris
	/// </summary>
	const evaluateWatchValue = (type, field, value) => {
		if (
			type === InputTypes.CHECKBOX &&
			typeof field === "object" &&
			field !== undefined
		) {
			return field.includes(value);
		}
		return field === value;
	};

	/// <summary>
	/// Author: Chris
	/// </summary>
	useEffect(() => {
		if (triggerReset) {
			reset(resetValues ?? {});
			resetTriggerReset(false);
		}
	}, [triggerReset]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			ref={formRef}
			className={classname({ [formClasses]: formClasses })}
			autoComplete={autoComplete}
		>
			{fields && fields.length > 0 && fields.map((row, rowKey) => {
				return (
					<Fragment key={rowKey}>
						<Row
							className={classname(
								row.rowClasses ? row.rowClasses : ""
							)}
							{...row.rowOptions}
							style={row.style}
						>
							{row.rowTitle}
							{row.columns?.filter((column) => column !== null && column !== false).map((column, columnKey) => {
								const {
									columnOptions,
									input,
									name,
									toDisplay,
									watchInput,
									defaultValue,
									autoComplete,
									hidden,
									...rest
								} = column;
								let inputType = input ?? InputTypes.INPUT;
								let hasDynamicInput = false;
								let styles = { display: hidden ? "none" : "block" };

								if (readOnly) {
									inputType = InputTypes.INPUT;
								}

								if (
									typeof toDisplay === "boolean" &&
									toDisplay === false
								) {
									return null;
								}

								if (watchInput) {
									hasDynamicInput = evaluateWatchValue(
										inputType,
										watchedFields[name],
										watchInput.equalsTo
									);
								}

								return (
									<Fragment key={columnKey}>
										<Col {...column.columnOptions} style={{ ...styles }}>
											<div id={name && _FORM_ID + name.replace(".", "_")}>
												<InputHoc
													inputType={inputType}
													name={name}
													error={errors?.[name]?.message}
													{...getFormType(
														inputType,
														column?.rules
													)}
													readOnly={readOnly}
													defaultValue={
														defaultValues?.[name] ??
														column.defaultValue ??
														null
													}
													autoComplete={autoComplete}
													{...rest}
												/>
												{hasDynamicInput &&
													typeof watchInput?.inputType ===
													"string" && (
														<InputHoc
															inputType={
																watchInput.inputType
															}
															name={watchInput.name}
															error={
																errors?.[
																	watchInput.name
																]?.message
															}
															{...getFormType(
																watchInput.inputType,
																column?.rules
															)}
															autoComplete={autoComplete}
															{...watchInput}
														/>
													)}
												{
													rest.label && name && <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="bottom" target={_FORM_ID + name.replace(".", "_")}>{t(rest.label)}</UncontrolledTooltip>
												}
											</div>
										</Col>
										{hasDynamicInput &&
											typeof watchInput?.inputType !==
											"string" &&
											React.createElement(
												watchInput.customComponent,
												{
													register,
													control,
													handleSubmit,
													errors,
													watch,
													setValue,
												}
											)}
									</Fragment>
								);
							})}
						</Row>
						{row.seperator && <hr />}
					</Fragment>
				);
			})}
			<button
				id="resetForm"
				type="button"
				style={{ display: "none" }}
				onClick={reset}
			></button>
		</form>
	);
};

export const submitForm = (ref) => {
	ref.current.dispatchEvent(new Event("submit", { cancelable: true }));
};

FormBuilder.propTypes = {
	fields: PropTypes.array.isRequired,
	onSubmit: PropTypes.func.isRequired,
	formRef: PropTypes.any.isRequired,
	setHookFormMethods: PropTypes.func,
};

export default FormBuilder;
