import { ApiUrl, DateFormat } from 'util/Constant';
import DataAccessObject from './DataAccessObject';
import moment from 'moment';

/// <summary>
/// Author: Robin
/// </summary>
class ChatDao extends DataAccessObject {

    async retrievePendingRequestList(data = null) {
        let url = ApiUrl._API_RETRIEVE_PENDING_CHAT_REQUEST_LIST;
        if (data) {
            url += `?${new URLSearchParams(data)}`;
        }
        return this.get(url);
    }

    async retrieveMessages(chatRoomId) {
        return this.get(ApiUrl._API_RETRIEVE_MESSAGE_LIST.replace(':chatRoomId', chatRoomId));
    }
}

export default ChatDao;
