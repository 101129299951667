import React, { useState, useEffect, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import Avatar from 'react-avatar-edit'

/// <summary>
/// Author: Lewis
/// </summary>
const AvatarEditModal = props => {
    const _FILE_SIZE = 71680
    const [avatarState, setAvatarState] = useState({
        preview: "",
        src: "",
    })
    const onClose = () => {
        setAvatarState({ preview: null })
    }

    const onCrop = (preview) => {
        setAvatarState({ preview })
    }

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > _FILE_SIZE) {
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    return (
        <Modal className="AvatarEditModal" isOpen={props.isModalOpen}>
            <ModalHeader><h4 className="m-0">Edit your avatar</h4></ModalHeader>
            <ModalBody>
                <div className="col-lg-3 avatar-editor">
                    <Avatar
                        width={390}
                        height={295}
                        onCrop={onCrop}
                        onClose={onClose}
                        onBeforeFileLoad={onBeforeFileLoad}
                        src={avatarState.src}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-prmary" onClick={() => {
                    props.setModalOpen(false);
                }}>Save</button>
                <button className="btn btn-danger" onClick={() => props.setModalOpen(false)}>Close</button>
            </ModalFooter>
        </Modal>
    )
}

export default AvatarEditModal;