import { ApiUrl, matterType } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class ProductDao extends DataAccessObject {

    async getProducts() {
        return this.get(ApiUrl._API_GET_PRODUCTS);
    }

    async getJurisdictionProductMatterProducts(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_JURISDICTION_PRODUCT_MATTER_PRODUCTS.replace(":jurisdictionId", jurisdictionId).replace(":productMatterId", productMatterId));
    }

    async getProductByCode(productCode) {
        return this.get(ApiUrl._API_GET_PRODUCT_BY_CODE.replace(":productCode", productCode));
    }

    async getReferenceProductList() {
        return this.get(ApiUrl._API_GET_REFERENCE_PRODUCT_LIST);
    }

    async getJurisdictionProductList() {
        return this.get(ApiUrl._API_GET_PRODUCT_LIST);
    }

    async getProductTypeList() {
        return this.get(ApiUrl._API_GET_PRODUCT_TYPE_LIST);
    }

    async deleteProduct(id) {
        return this.delete(ApiUrl._API_DELETE_PRODUCT.replace(':id', id));
    }

    //new services
    async getIncorporationProductListByJurisdiction(jurisdictionId) {
        return this.get(ApiUrl._API_GET_INCORPORATION_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId));
    }
    async createOrUpdateIncorporationProductListByJurisdiction(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_INCORPORATION_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }
    async getAnnualPackageProductListByJurisdiction(jurisdictionId) {
        return this.get(ApiUrl._API_GET_ANNUAL_PACKAGE_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId));
    }
    async createOrUpdateAnnualPackageProductListByJurisdiction(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_ANNUAL_PACKAGE_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }
    async getServicesProductListByJurisdiction(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_SERVICES_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId));
    }
    async createOrUpdateServicesProductListByJurisdiction(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_SERVICES_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }
    async getMiscellaneousProductListByJurisdiction(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_MISCELLANEOUS_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId));
    }
    async createOrUpdateMiscellaneousProductListByJurisdiction(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_MISCELLANEOUS_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }
    async getProductListByJurisdiction(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId));
    }
    async createOrUpdateProductListByJurisdiction(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_PRODUCT_LIST_BY_JURISDICTION.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }
    async createOrUpdateIncomeProduct(postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_INCOME_PRODUCT, postData);
    }
    async createOrUpdateExpenseProduct(postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_EXPENSE_PRODUCT, postData);
    }
    async getProductMatterServiceListByMatterType(jurisdictionId, matterTypeId) {
        return this.get(ApiUrl._API_GET_PRODUCT_MATTER_SERVICE_LIST_BY_JURISDICTION_MATTER_TYPE.replace(':matterTypeId', matterTypeId).replace(':jurisdictionId', jurisdictionId));
    }
    async createProductMatter(postData) {
        let { matterTypeId, address1, countryId } = postData;
        let url = ApiUrl._API_CREATE_PRODUCT_MATTER;
        switch (matterTypeId) {
            case matterType._ANNUAL_PACKAGE: {
                postData['address'] = { address1: address1, countryId: countryId };
                url = url.replace('create', 'annualPackage/create');
                break;
            }
            default: {
                break;
            }
        }
        return this.post(url, postData);
    }
    async updateProductMatter(postData) {
        let { matterTypeId, address1, countryId } = postData;
        let url = ApiUrl._API_UPDATE_PRODUCT_MATTER;
        switch (matterTypeId) {
            case matterType._ANNUAL_PACKAGE: {
                postData['address'] = { address1: address1, countryId: countryId };
                url = url.replace('update', 'annualPackage/update');
                break;
            }
            default: {
                break;
            }
        }
        return this.post(url, postData);
    }
}

export default ProductDao;
