import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { ApiKey, ApiUrl, BtnTypes, FileType, FinanceType, LoadingStateText, InputTypes, DateFormat, DefaultCurrency, DefaultCurrencyId, Icon, SweetAlert, PolicyObjectConstant, PolicyActionConstant, TableId } from "util/Constant";
import moment from 'moment';
import { useRecoilValue } from "recoil";
import { authCredentialState, userRolesSelector } from "../../recoil/Atoms";
import NumberFormat from 'react-number-format';
import ButtonRound from "components/buttons/ButtonRound";
import { Block, Notify, Confirm, Report } from "notiflix";
import { useForm } from "react-hook-form";
import InputHoc from 'components/form/InputHoc';
import { useHistory, useLocation } from "react-router";
import { UncontrolledTooltip, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import DocumentViewer from "components/document-viewer/DocumentViewer";
import BrandModal from "components/modals/BrandModal";
import { useResizeDetector } from "react-resize-detector";
import { Link } from "react-router-dom";
import { CompanyDao } from "data";
import { PageSettings } from 'config/page-settings';
import classNames from "classnames";
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from 'components/panel/TabPanel';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { formatJurisdictionList, getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty, isGuid } from "util/Utility";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Can } from "config/user-access";
import Error403View from 'components/error-graphic/Error403NoAccess';
import validator from "validator";

///<summary>
///Author: Sze Hua
///</summary>
const FinancesList = props => {
    const { t } = useTranslation();
    const _location = useLocation();
    const _history = useHistory();
    const { isXlDevices } = useContext(PageSettings);

    const _TABS = {
        IN: { id: 0, title: t("IN") },
        OUT: { id: 1, title: t("OUT") }
    };
    const [currentTab, setCurrentTab] = useState(_TABS.IN.id);

    useEffect(() => {
        let search = _location.search;

        if (!stringIsNullOrEmpty(search)) {
            let params = new URLSearchParams(search);
            if (params) {
                let tab = parseInt(params.get("tab"));
                let listOfTabIds = Object.values(_TABS).map(x => x.id);
                listOfTabIds.includes(tab) && setCurrentTab(tab);
            }
        }
    }, []);

    return <>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot">{t("FINANCE_LIST")}</h1>
                <Tabs
                    value={currentTab}
                    onChange={(e, newTab) => {
                        const path = _location.pathname;
                        let params = new URLSearchParams({
                            tab: newTab
                        });

                        _history.push({
                            pathname: path,
                            search: `?${params}`
                        })

                        setCurrentTab(newTab)
                    }}
                    variant="scrollable"
                >
                    <Tab icon={<img alt="" src={require('../../assets/img/icon/accountant.svg')} className="mr-2" />} label={(_TABS.IN.title)} classes={{ wrapper: "d-inline text-theme-1" }} />
                    <Tab icon={<img alt="" src={require('../../assets/img/icon/admin.svg')} className="mr-2" />} label={(_TABS.OUT.title)} classes={{ wrapper: "d-inline text-theme-1" }} />
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames('panel-body-inner-content with-br-radius', { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}>
                    <div className="d-flex flex-column h-100">
                        <TabPanel value={currentTab} index={_TABS.IN.id} className="flex-grow-1">
                            <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_finance_list} passThrough>
                                {
                                    allowed => allowed ?
                                        <FinancesTable
                                            currentTab={currentTab}
                                        />
                                        :
                                        <Error403View />
                                }
                            </Can>
                        </TabPanel>
                        <TabPanel value={currentTab} index={_TABS.OUT.id} className="flex-grow-1">
                            <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_finance_list} passThrough>
                                {
                                    allowed => allowed ?
                                        <FinancesTable
                                            currentTab={currentTab}
                                        />
                                        :
                                        <Error403View />
                                }
                            </Can>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const FinancesTable = props => {

    const { currentTab } = props;

    const _tableRef = useRef();
    const [filtersForm, setFiltersForm] = useState();
    const { control, handleSubmit, errors, reset, watch } = useForm();
    const _filterFormRef = useRef();
    const { t } = useTranslation();
    const [resetFilters, setResetFilters] = useState(false);
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [isViewing, setIsViewing] = useState(false);
    const [filePath, setFilePath] = useState({});
    const [cartItem, setCartItem] = useState({
        id: 0,
        attachmentType: "Default",
    });
    const ELLIPSIS_STYLE = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '200px',
        lineHeight: '2.05rem'
    }
    const userRole = useRecoilValue(userRolesSelector);
    const { currencyPreference, id:userId } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    const { ref: wrapperRef } = useResizeDetector();
    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight - 62;
        }
    }, [wrapperRef?.current]);

    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);
    const { jurisdictionOptions, jurisdictionList } = useMemo(() => {
        if (jurisdictionJson?.[ApiKey._API_SUCCESS_KEY]) {
            return {
                jurisdictionOptions: formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]),
                jurisdictionList: jurisdictionJson[ApiKey._API_DATA_KEY]
            }
        }
        return {
            jurisdictionOptions: [],
            jurisdictionList: []
        }
    }, [jurisdictionJson]);

    let companyDao = new CompanyDao();

    const _SEARCH_FORMS = useMemo(() => {
        const _FORM_FIELDS = [
            {
                columns: [
                    { prefix: { icon: Icon._SEARCH_COMPANY }, label: `${t('SEARCH_COMPANY_NAME')}`, placeholder: `${t('SEARCH_COMPANY_NAME')}`, name: 'companyName', input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                    { prefix: { icon: Icon._SEARCH_JURISDICTION }, label: `${t('SEARCH_JURISDICTION')}`, placeholder: `${t('SEARCH_JURISDICTION')}`, name: 'jurisdictionId', input: InputTypes.SELECT, options: jurisdictionOptions, columnOptions: { xl: 3, md: 6 } },
                ]
            }
        ];
        return _FORM_FIELDS;
    }, [jurisdictionJson]);

    const _COMMON_COLUMN = useMemo(() => [
        {
            Header: 'COMPANY_NAME',
            accessor: el => el.company?.chineseName ? [el.company?.englishName, el.company.chineseName] : [el.company?.englishName],
            Cell: ({ value }) => <>
                <div><span className="badge-lang-en">EN</span> {value[0]}</div>
                {
                    value?.[1] &&
                    <div><span className="badge-lang-zh">CN</span>{value[1]}</div>
                }
            </>,
            isRequiredColumn: true
        },
        {
            Header: 'JURISDICTION',
            accessor: el => jurisdictionList.find(j => j.id === el.company.jurisdiction.id)?.name ?? "",
        },
        {
            Header: "DATE",
            accessor: el => el.date ? moment(el.date).format(DateFormat._DATE_ONLY) : "",
        },
        {
            Header: "MATTER",
            accessor: "productMatterName",
            Cell: ({ row }) => <>
                {
                    row.original.orderCartItems.map((value, index) => {
                        return (<div style={ELLIPSIS_STYLE} className="m-b-10">
                            <span>{value.productName}</span><br />
                        </div>
                        )
                    })
                }
            </>,
            isRequiredColumn: true
        },
    ], [jurisdictionList]);

    const _COLUMN = useMemo(() => {
        let column = [
            ..._COMMON_COLUMN,
            {
                id: "UnitPrice",
                Header: 'UNIT_PRICE',
                accessor: "price",
                Cell: ({ row, value }) => <>
                    {
                        row.original.orderCartItems.map((value, index) => {
                            return (<div className="m-b-10" style={{ lineHeight: '2.05rem' }}>
                                <div>
                                    <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={value.price} displayType={'text'} thousandSeparator={true} />
                                    {
                                        value.price && row.original?.quoteCurrency?.id != 2 && row.original?.quoteCurrency?.exchangeRate?.rate &&
                                        <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${row.original?.quoteCurrency?.code} `} value={row.original?.quoteCurrency?.quotePrice} displayType={'text'} thousandSeparator={true} />
                                    }
                                </div>
                            </div>)
                        })
                    }

                </>,
                isRequiredColumn: true
            },
            {
                id: "Invoice",
                Header: "INVOICE",
                Cell: ({ row }) => <>
                    <div>
                        {
                            row.original?.orderCartItems.map((item, index) => {
                                return (<div className="m-b-10">
                                    {
                                        item?.invoiceAttachment?.id &&
                                        <>
                                            <ButtonRound small className="mr-1" type={BtnTypes.VIEW} onClick={() => {
                                                if (item?.invoiceAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                                    Notify.Warning(`.doc & .docx ${t("notiflix:ARE_NOT_VIEWABLE")},${t("notiflix:DOWNLOAD_TO_VIEW")}`);
                                                } else {
                                                    setIsViewing(true);
                                                    setCartItem({ id: row.original.id, attachmentType: "Invoice" });
                                                    setFilePath(item?.invoiceAttachment?.fileMeta[0]?.requestPath);
                                                }
                                            }} />
                                            <Link className="brand-anchor mr-1"
                                                to={item?.invoiceAttachment?.fileMeta[0]?.requestPath}
                                                target={"_blank"} download>
                                                <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} />
                                            </Link>
                                        </>
                                    }
                                    {
                                        !row.original.confirmBy &&
                                        <ButtonRound small className="btn-themed" type={BtnTypes.FILE_UPLOAD} onClick={() => {
                                            setCartItem({ id: item.id, attachmentType: "Invoice" });
                                            setToggle();
                                            setCompanyId(row.original.company?.id);
                                        }} />
                                    }
                                </div>);
                            })
                        }
                    </div>
                </>,
                disableSortBy: true,
                disableFilters: true,
                isRequiredColumn: true
            },
            {
                id: 'Receipt',
                Header: "RECEIPT",
                Cell: ({ row }) => <>
                    {
                        row.original?.orderCartItems.map((item, index) => {
                            return (<div className="m-b-10">
                                {
                                    item?.receiptAttachment?.id &&
                                    <>
                                        <ButtonRound type={BtnTypes.VIEW} className="mr-1 p-b-10" small onClick={() => {
                                            if (item?.receiptAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                                Notify.Warning(`.doc & .docx ${t("notiflix:ARE_NOT_VIEWABLE")},${t("notiflix:DOWNLOAD_TO_VIEW")}`);
                                            } else {
                                                setIsViewing(true);
                                                setCartItem({ id: row.original.id, attachmentType: "Receipt" });
                                                setFilePath(item?.receiptAttachment?.fileMeta[0]?.requestPath);
                                            }
                                        }} />
                                        <Link className="brand-anchor mr-1 p-b-10"
                                            to={item?.receiptAttachment?.fileMeta[0]?.requestPath}
                                            target={"_blank"} download>
                                            <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} />
                                        </Link>
                                    </>
                                }
                                {
                                    !row.original.confirmBy &&
                                    <ButtonRound type={BtnTypes.FILE_UPLOAD} className="p-b-10" small onClick={() => {
                                        setCartItem({ id: item.id, attachmentType: "Receipt" });
                                        setToggle();
                                        setCompanyId(row.original.company?.id);
                                    }} />
                                }
                            </div>);
                        })
                    }
                </>,
                disableSortBy: true,
                disableFilters: true,
                isRequiredColumn: true
            },
            {
                id: 'Status',
                Header: "STATUS",
                accessor: el => el.confirmBy ? t("COMPLETED") : t("INCOMPLETE"),
                Cell: ({ row, value }) => <>
                    {
                        row.original?.orderCartItems.map((item, index) => {
                            return (
                                <div className="m-b-10" style={{ lineHeight: '2.05rem' }}>
                                    <span className={classNames("__status-text badge", {
                                        "badge-success": isGuid(item.confirmBy),
                                        "badge-warning": !isGuid(item.confirmBy)
                                    })}>{isGuid(item.confirmBy) ? t("COMPLETED") : t("INCOMPLETE")}</span>
                                </div>
                            );
                        })
                    }
                </>,
            }
        ];
        if (userRole?.isAdmin || userRole?.isSuperAdmin) {
            column.push(
                {
                    id: "Confirmation",
                    Header: 'CONFIRMATION',
                    Cell: ({ row }) => <>
                        {
                            row.original?.orderCartItems.map((item, index) => {
                                return (<div className="m-b-10" style={{ lineHeight: '2.05rem' }}>
                                    {
                                        !isGuid(item.confirmBy) && 
                                        <button type="button" className="btn btn-xs btn-themed" style={{ width: "80%", padding: "1px" }}
                                            onClick={() => {
                                                if (item.invoiceAttachment != null || item.receiptAttachment != null) {
                                                    confirmationSubmitAttachment(row.original.orderCartItems[index].id, row.original.orderCartItems[index].productName);
                                                }
                                                else {
                                                    Report.Warning(t("notiflix:CONFIRMATION_WARNING"), t("notiflix:PLEASE_UPLOAD_INVOICE_OR_RECEIPT"), t("notiflix:OK"));
                                                }
                                            }}
                                        >{t("CONFIRM")}</button>
                                    }
                                </div>);
                            })

                        }
                    </>,
                    disableSortBy: true,
                    disableFilters: true,
                    isRequiredColumn: true
                }
            );
        }
        return column;
    }, [currencyPreference, userRole, _COMMON_COLUMN]);

    const filterOnSubmit = (data) => {
        setFiltersForm(data);
    }

    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }

        setResetFilters(prev => !prev);
        setFiltersForm({});
    };

    const confirmationSubmitAttachment = (id, name) => {
        Confirm.Show(t('CONFIRMATION'), t('notiflix:DO_YOU_CONFIRM') + ' <b>' + name + '</b> ' + t('notiflix:DOCUMENT_ARE_VALID') + '?', t('YES'), t('NO'),
            async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                await companyDao.confirmPaymentReceiptOrInvoice(id).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(t('COMPLETE'));
                        _tableRef.current.reFetch();
                    } else {
                        Notify.Failure(SweetAlert._OPERATION_FAIL);
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            }
        );
    }

    const setToggle = async () => {
        setInvoiceModal(prevState => !prevState);
    }

    const uploadFinanceInvoice = async (data) => {
        let tempData = { id: cartItem.id, ...data };
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        await companyDao.postCompanyFinanceInvoiceDocument(companyId, tempData).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setToggle();
                _tableRef.current.reFetch();
            }
        }).finally(() => Block.Remove(".modal-content"));
    }

    const fileModal = () => {
        let title = t("UPLOAD_FILE");
        let inputLabel;
        let inputName;
        if (cartItem.attachmentType == "Invoice") {
            inputLabel = t("INVOICE_FILE");
            inputName = "invoiceAttachment";
        }
        else {
            inputLabel = t("RECEIPT_FILE");
            inputName = "receiptAttachment";
        }
        return <BrandModal
            isOpen={invoiceModal}
            toggler={setToggle}
            title={title}
            customBody
            onClosed={() => setCompanyId()}
        >
            <form onSubmit={handleSubmit(uploadFinanceInvoice)} autoComplete="off">
                <ModalBody>
                    <InputHoc label={inputLabel}
                        name={inputName}
                        inputType={InputTypes.FILEUPLOAD} control={control} rules={{ required: true }}
                        error={errors?.[inputName]}
                    />
                </ModalBody>
                <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                    <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={setToggle}>{t("CANCEL")}</button>
                    <button type="submit" className="btn btn-sm btn-themed btn-min-width" >{t("SAVE")}</button>
                </ModalFooter>
            </form>
        </BrandModal>
    }

    return <>
        <div className="d-flex flex-column h-100" ref={wrapperRef}>
            <div className="filter-panel-container border-radius-none">
                <Row className="filter-panel-body p-t-15">
                    <Col xl={10}>
                        <FormBuilder fields={_SEARCH_FORMS} formRef={_filterFormRef} onSubmit={filterOnSubmit}
                            reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                    </Col>
                    <Col xl={2} className="btn-list d-flex">
                        <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                    </Col>
                </Row>
            </div>
            <div className="flex-grow-1">
                <ReactTable
                    ref={_tableRef}
                    filterFormfield={filtersForm}
                    columns={_COLUMN}
                    url={ApiUrl._API_GET_FINANCES_LIST}
                    initQueryProps={{ productTypeId: currentTab + 1 }}
                    tableMinHeight={fixedContentHeight}
                    tableColumnPreference={[userId, `${TableId._FINANCE_LIST}-${currentTab}`]}
                />
            </div>
        </div>
        {fileModal()}
        <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={filePath} />
    </>
}

export default FinancesList;
