import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Card, CardBody, Col, UncontrolledTooltip, UncontrolledCollapse, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { ReactTablev2 as ReactTable } from 'components/react-table';
import InputHoc from 'components/form/InputHoc';
import { WebUrl, InputTypes, ApiUrl, DateFormat, Icon, BtnTypes, ApiKey, TableId } from 'util/Constant';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { userIdSelector, userRolesSelector } from "recoil/Atoms";
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import ButtonRound from 'components/buttons/ButtonRound';
import { CompanyDao } from 'data';
import { Notify, Report } from 'notiflix';
import { getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty } from 'util/Utility';
import { isEmpty, uniqueId } from 'lodash';
import BrandModal from 'components/modals/BrandModal';
import { Can } from 'config/user-access';
import { PolicyObjectConstant, PolicyActionConstant } from 'util/Constant';
import { PageSettings } from 'config/page-settings';

const RenewalType = {
    _COMPLIANCE: "Compliance",
    _PACKAGE: "Package"
};

///<summary>
///Author: Ong Sze Hua
///</summary>
const RenewalList = props => {

    const { tabId, retrivedCallback, tableMinHeight } = props;
    const { t } = useTranslation();
    const _tableRef = useRef(null);
    const { ref: wrapperRef } = useResizeDetector();
    const _history = useHistory();
    const userId = useRecoilValue(userIdSelector);

    const [showModal, setShowModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState();
    const [filtersForm, setFiltersForm] = useState();
    const { register, control, handleSubmit, errors, reset, watch, setValue } = useForm();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const {
        isXsDevices,
        isSmDEvices
    } = useContext(PageSettings);
    const calendarYearOpts = [
        { label: t('CALENDER_DEFAULT'), value: "" },
        { label: t('CALENDER_Q1'), value: "q1" },
        { label: t('CALENDER_Q2'), value: "q2" },
        { label: t('CALENDER_Q3'), value: "q3" },
        { label: t('CALENDER_Q4'), value: "q4" },
        { label: t('CALENDER_FIRST_SEMI'), value: "firstSemiannual" },
        { label: t('CALENDER_SECOND_SEMI'), value: "secondSemiannual" },
        { label: t('CALENDER_ANNUAL'), value: "annual" },
        { label: t('CALENDER_CUSTOM'), value: "custom" },
    ];
    const today = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

    const _COLUMNS = useMemo(() => [
        {
            Header: "ACCOUNTANT",
            accessor: "requestorName"
        },
        {
            Header: 'COMPANY_NAME',
            accessor: el => el.englishCompanyName + (el.chineseCompanyName ?? ""),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="m-b-5 nowrap text-overflow-ellipsis">
                            <span className="badge badge-purple">EN</span>
                            <span id={`comp-name-ctrl-tooltip-${tabId}-${row.original.companyId}`} style={{ paddingLeft: '3%' }}>{row.original.englishCompanyName}</span>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`comp-name-ctrl-tooltip-${tabId}-${row.original.companyId}`}>{row.original.englishCompanyName}</UncontrolledTooltip>
                        {
                            !stringIsNullOrEmpty(row.original?.chineseCompanyName) && <>
                                <div className="nowrap">
                                    <span className="badge badge-light-green">CN</span>
                                    <span style={{ paddingLeft: '3%' }}>{row.original.chineseCompanyName}</span>
                                </div>
                            </>
                        }
                    </div>
                )
            },
            isRequiredColumn: true
        },
        {
            Header: 'JURISDICTION',
            accessor: "jurisdictionName",
            Cell: ({ row, value }) => {
                return (
                    <>
                        <div className="d-flex">
                            <div id={`jurisdiction_${tabId}_${row.original.companyId}`}>
                                <img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(value)} />
                                <span>{value}</span>
                            </div>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`jurisdiction_${tabId}_${row.original.companyId}`}>{value}</UncontrolledTooltip>
                    </>
                )
            },
        },
        {
            Header: 'MATTER',
            accessor: el => {
                let tempResult = [];
                if (el.renewalProductMatter?.annualPackage?.expiryDate) {
                    tempResult.push({ name: el.renewalProductMatter.annualPackage.packageName, type: RenewalType._PACKAGE });
                }
                if (el.renewalProductMatter?.annualCompliances) {
                    el.renewalProductMatter.annualCompliances.map(d => {
                        if (d.productMatterName) {
                            tempResult.push({ name: d.productMatterName, type: RenewalType._COMPLIANCE });
                        }
                    });
                }
                return tempResult;
            },
            Cell: ({ value }) => {
                let packageList = [];
                let complianceList = [];
                value.map(d => {
                    if (d.type === RenewalType._PACKAGE) {
                        packageList.push(d);
                    }
                    else if (d.type === RenewalType._COMPLIANCE) {
                        complianceList.push(d);
                    }
                });
                return (
                    <>
                        {!isEmpty(packageList) && (
                            <div>
                                <b className="text-underline">{RenewalType._PACKAGE}</b>
                                <Row key={uniqueId('matter-')} className="m-0">
                                    {packageList.map((d) => <Col xl={12} md={12} sm={12} className="p-0 nowrap">{d.name}</Col>)}
                                </Row>
                            </div>
                        )}

                        {!isEmpty(complianceList) && (
                            <div>
                                <b className="text-underline">{RenewalType._COMPLIANCE}</b>
                                <Row key={uniqueId('matter-')} className="m-0">
                                    {complianceList.map((d) => <Col xl={12} md={12} sm={12} className="p-0 nowrap">{d.name}</Col>)}
                                </Row>
                            </div>
                        )}
                    </>
                )
            },
            sortType: (a, b, id, desc) => {
                a = a.values[id]?.[0];
                b = b.values[id]?.[0];
                if (a > b)
                    return 1;
                if (a < b)
                    return -1;
                return 0;
            },
            isRequiredColumn: true
        },
        {
            Header: 'SYSTEM_DEADLINE',
            accessor: el => {
                let tempResult = [];
                if (el.renewalProductMatter?.annualPackage?.expiryDate) {
                    tempResult.push({ expiryDate: moment(el.renewalProductMatter.annualPackage.expiryDate).format(DateFormat._DATE_ONLY), type: RenewalType._PACKAGE });
                }
                if (el.renewalProductMatter?.annualCompliances) {
                    el.renewalProductMatter.annualCompliances.map(d => {
                        if (d.expiryDate) {
                            tempResult.push({ expiryDate: moment(d.expiryDate).format(DateFormat._DATE_ONLY), type: RenewalType._COMPLIANCE });
                        }
                    });
                }
                return tempResult;
            },
            Cell: ({ value }) => {
                let packageList = [];
                let complianceList = [];
                value.map(d => {
                    if (d.type === RenewalType._PACKAGE) {
                        packageList.push(d);
                    }
                    else if (d.type === RenewalType._COMPLIANCE) {
                        complianceList.push(d);
                    }
                });
                return (
                    <>
                        {!isEmpty(packageList) && (
                            <div>
                                <b className="text-underline">{RenewalType._PACKAGE}</b>
                                <Row key={uniqueId('deadline-')} className="m-0">
                                    {packageList.map((d) => (
                                        <Col xl={12} md={12} sm={12} className="p-0">
                                            {renderDeadline(d.expiryDate, uniqueId())}
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )}

                        {!isEmpty(complianceList) && (
                            <div>
                                <b className="text-underline">{RenewalType._COMPLIANCE}</b>
                                <Row key={uniqueId('deadline-')} className="m-0">
                                    {complianceList.map((d) => (
                                        <Col xl={12} md={12} sm={12} className="p-0">
                                            {renderDeadline(d.expiryDate, uniqueId())}
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )}
                    </>
                )
            },
            sortType: (a, b, id, desc) => {
                a = a.values[id]?.[0];
                b = b.values[id]?.[0];
                if (a > b)
                    return 1;
                if (a < b)
                    return -1;
                return 0;
            },
            isRequiredColumn: true
        },
        {
            Header: 'ACTION',
            Cell: ({ row }) => <>
                {
                    // (row.original.renewalProductMatter?.annualCompliances.some(a => moment(today).isSameOrBefore(moment(a.expiryDate))) ||
                    //     (row.original.renewalProductMatter?.annualPackage?.expiryDate && moment(today).isSameOrBefore(moment(row.original.renewalProductMatter.annualPackage.expiryDate)))) && (
                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_annualCompliance}>
                        <ButtonRound
                            medium
                            type={BtnTypes.REMIND}
                            className="m-r-5"
                            onClick={() => {
                                // sendReminder(row.original.companyId);
                                setSelectedCompany(row.original);
                                toggleShowModal();
                            }
                            }
                        />
                    </Can>
                }
                <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={() => _history.push(
                    {
                        pathname: WebUrl._COMPANY_MANAGEMENT_ANN_COMPLIANCES.replace(":companyId", row.original.companyId),
                        state: {
                            // company: row.original,
                            // role: userRole,
                            companyId: row.original.companyId
                        }
                    })} />
            </>,
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], []);

    const toggleShowModal = () => setShowModal(prev => !prev);

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    const onSubmitFilter = (filtersValue) => {
        if (filtersValue.calendarYear != 'custom') {
            let firstMonth = null;
            let lastMonth = null;
            switch (filtersValue.calendarYear) {
                case 'q1':
                    firstMonth = 0;
                    lastMonth = 3;
                    break;
                case 'q2':
                    firstMonth = 3;
                    lastMonth = 6;
                    break;
                case 'q3':
                    firstMonth = 6;
                    lastMonth = 9;
                    break;
                case 'q4':
                    firstMonth = 9;
                    lastMonth = 12;
                    break;
                case 'firstSemiannual':
                    firstMonth = 0;
                    lastMonth = 6;
                    break;
                case 'secondSemiannual':
                    firstMonth = 6;
                    lastMonth = 12;
                    break;
                case 'annual':
                    firstMonth = 0;
                    lastMonth = 12;
                    break;
                default:
                    break;
            }
            if (firstMonth != null && lastMonth != null) {
                let firstDayMonth = new Date(today).setMonth(firstMonth, 1);
                let lastDayMonth = new Date(today).setMonth(lastMonth, 0);
                filtersValue["startDate"] = moment(firstDayMonth).format(DateFormat._DATE_ONLY);
                filtersValue["endDate"] = moment(lastDayMonth).format(DateFormat._DATE_ONLY);
            }
        }
        else {
            filtersValue["startDate"] = moment(filtersValue["startDate"]).format(DateFormat._DATE_ONLY);
            filtersValue["endDate"] = moment(filtersValue["endDate"]).format(DateFormat._DATE_ONLY);
        }
        setIsFilterOpen(prevState => !prevState);
        setFiltersForm(filtersValue);
    };

    const sendReminder = async (companyId) => {
        let companyDao = new CompanyDao();
        await companyDao.sendAnnualComplianceDeadline(companyId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                Report.Success(t('notiflix:SUCCESS'), t('notiflix:REMIND_USER_SUCCESS'), t('notiflix:OKAY'));
            }
            else {
                let errors = jsonResponse[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        })
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }

        reset({ calendarYear: "" });
        setFiltersForm({});

    };

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    function renderDeadline(deadline, id) {
        let badgeColor;
        let tooltipTxt = "More than 7 days";
        let diff = (new Date(deadline) - today) / (1000 * 60 * 60 * 24);
        if (diff < 0) {
            badgeColor = 'danger';
            tooltipTxt = "Expired";
        }
        else if (diff <= 7) {
            badgeColor = 'warning';
            tooltipTxt = "Within 7 days";
        }
        else
            badgeColor = 'info'
        return <>
            <span id={"calendar" + id}>
                <span className={"badge badge-" + badgeColor + " p-1 mr-1"}><i className="las la-calendar" style={{ fontSize: 15 }}></i></span>
                {moment(deadline).format(DateFormat._DATE_ONLY)}
            </span>
            <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target={"calendar" + id}>{tooltipTxt}</UncontrolledTooltip>
        </>
    }

    return (<>
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container border-radius-none">
                <form onSubmit={handleSubmit(onSubmitFilter)} autoComplete='off'>
                    {
                        (isXsDevices || isSmDEvices) ?
                        <>
                            <Row className="filter-panel-header">
                                <div className="filter-panel-header-content">
                                    <div className="btn btn-light btn-filter" onClick={() => setIsFilterOpen(prevState => !prevState)}>
                                        <img alt="" className="mr-2" src={require("../../assets/img/icon/filter.svg")} />{t("FILTER")}
                                    </div>
                                    {
                                        isFilterOpen &&
                                        <div className="d-flex btn-list">
                                            <button className="btn btn-themed btn-brand-round m-l-auto"><i className="las la-search" /></button>
                                            <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                        </div>
                                    }
                                </div>
                            </Row>
                            <Collapse isOpen={isFilterOpen}>
                                <Row className="filter-panel-body p-5">
                                    <Col xl={4}><InputHoc prefix={{ icon: Icon._CALENDER }} label={`${t('SEARCH_BY_DATE')}`} placeholder={`${t('SEARCH_BY_DATE')}`} name='calendarYear' inputType={InputTypes.SELECT} options={calendarYearOpts} control={control} defaultValue={""} /></Col>
                                        {
                                            watch("calendarYear") === 'custom' && <>
                                                <Col xl={3}><InputHoc prefix={{ icon: Icon._CALENDER }} label='Start Date' name='startDate' inputType={InputTypes.DATEPICKER} control={control}
                                                    onChange={(value) => { moment(value).isAfter(watch('endDate')) && setValue('endDate', value) }} /></Col>
                                                <Col xl={1} className="d-flex"><p className="m-auto">{t('TO')}</p></Col>
                                                <Col xl={3}><InputHoc prefix={{ icon: Icon._CALENDER }} label='End Date' name='endDate' inputType={InputTypes.DATEPICKER} control={control} minDate={watch("startDate") ?? today} /></Col>
                                            </>
                                        }
                                </Row>
                            </Collapse>
                        </>
                        :
                        <Row className="filter-panel-body justify-between">
                             <Col xl={4}><InputHoc prefix={{ icon: Icon._CALENDER }} label='Search by date' placeholder="Search by date" name='calendarYear' inputType={InputTypes.SELECT} options={calendarYearOpts} control={control} defaultValue={""} /></Col>
                            {
                                watch("calendarYear") === 'custom' && <>
                                    <Col xl={3}><InputHoc prefix={{ icon: Icon._CALENDER }} label='Start Date' name='startDate' inputType={InputTypes.DATEPICKER} control={control}
                                        onChange={(value) => { moment(value).isAfter(watch('endDate')) && setValue('endDate', value) }} /></Col>
                                    <Col xl={1} className="d-flex"><p className="m-auto">{t('TO')}</p></Col>
                                    <Col xl={3}><InputHoc prefix={{ icon: Icon._CALENDER }} label='End Date' name='endDate' inputType={InputTypes.DATEPICKER} control={control} minDate={watch("startDate") ?? today} /></Col>
                                </>
                            }
                            <div className="d-flex btn-list">
                                <button className="btn btn-themed btn-brand-round m-l-auto"><i className="las la-search" /></button>
                                <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                            </div>
                        </Row>
                    }
                </form>    
            </div>
            <div className="flex-grow-1" ref={wrapperRef}>
                <div className="d-flex flex-column h-100">
                    <ReactTable
                        ref={_tableRef}
                        columns={_COLUMNS}
                        filterFormfield={filtersForm}
                        url={ApiUrl._API_GET_RENEWALS_LIST}
                        tableMinHeight={tableMinHeight}
                        tableMinWidth={1000}
                        fetchOnSuccess={(json, count) => retrivedCallback(json, count)}
                        tableColumnPreference={[userId, TableId._RENEWAL_LIST]}
                    />
                </div>
            </div>
        </div>
        <ReminderModal showModal={showModal} toggleShowModal={toggleShowModal} company={selectedCompany} />
    </>)
}

const ReminderModal = props => {
    const { showModal, toggleShowModal, company } = props;
    const { t } = useTranslation();

    const [selectedIds, setSelectedIds] = useState([]);
    const { control, errors, watch, handleSubmit } = useForm();

    const matterList = useMemo(() => {
        let tempResult = [];

        if (!isEmpty(company)) {
            let renewalMatters = company.renewalProductMatter;
            if (!isEmpty(renewalMatters)) {
                let today = new Date().setHours(0, 0, 0, 0);
                if (renewalMatters?.annualPackage) {
                    tempResult.push()
                }
                if (//renewalMatters?.annualPackage?.expiryDate && !renewalMatters?.annualPackage?.isExpired
                    renewalMatters?.annualPackage) {
                    tempResult.push({
                        id: renewalMatters.annualPackage.productMatterId,
                        matterName: renewalMatters.annualPackage.packageName,
                        expiryDate: moment(renewalMatters.annualPackage.expiryDate).format(DateFormat._DATE_ONLY),
                        label: renewalMatters.annualPackage.packageName,
                        value: renewalMatters.annualPackage.productMatterId,
                        type: RenewalType._PACKAGE
                    });
                }
                if (renewalMatters?.annualCompliances) {
                    renewalMatters.annualCompliances.map(matter => {
                        // if (!matter?.isExpired && moment(today).isSameOrBefore(moment(matter.expiryDate))) {
                        tempResult.push({
                            id: matter.id,
                            matterName: matter.productMatterName,
                            expiryDate: moment(matter.expiryDate).format(DateFormat._DATE_ONLY),
                            label: matter.productMatterName,
                            value: matter.id,
                            type: RenewalType._COMPLIANCE
                        })
                        // }
                    });
                }
            }
        }
        return tempResult;
    }, [company]);

    const _COLUMNS = useMemo(() => [
        {
            Header: 'MATTER',
            accessor: "matterName"
        },
        {
            Header: 'SYSTEM_DEADLINE',
            accessor: "expiryDate"
        }
    ], []);

    const onClickSend = async () => {
        if (isEmpty(selectedIds)) {
            Notify.Warning("At least select one matter to send.")
            return;
        }
        console.log(selectedIds);
    }

    const onSubmit = async (data) => {
        let { matterId } = data;
        let companyDao = new CompanyDao();
        let renewalType = matterList.find(m => m.id === matterId)?.type;
        if (renewalType === RenewalType._PACKAGE) {
            await companyDao.manualSendRemindPackage(company.companyId, matterId).then((res) => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(res[ApiKey._API_MESSAGE_KEY]);
                    toggleShowModal();
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            });
        }
        else if (renewalType === RenewalType._COMPLIANCE) {
            await companyDao.manualSendRemindCompliance(company.companyId, matterId).then((res) => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(res[ApiKey._API_MESSAGE_KEY]);
                    toggleShowModal();
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            });
        }
    }

    return (
        <>
            <BrandModal
                modalSize={"md"}
                customBody
                title={t('REMIND')}
                isOpen={showModal}
                toggler={toggleShowModal}
                centered
                backdrop='static'
            >
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <ModalBody
                    //className="p-0"
                    >
                        <div className="text-pill-brand m-auto m-b-15" style={{ maxWidth: '70%', width: 'fit-content' }}>
                            <strong>
                                {company?.englishCompanyName}
                                {company?.chineseCompanyName && <><br />(${company?.chineseCompanyName})</>}
                            </strong>
                        </div>
                        <Row>
                            <Col xl={8}>
                                <InputHoc name="matterId" inputType={InputTypes.SELECT} options={matterList} control={control} error={errors?.matterId} label={t('MATTER')}
                                    rules={({ required: 'Matter is required.' })} />
                            </Col>
                            <Col xl={4}>
                                <InputHoc inputType={InputTypes.INPUT} label={t('DEADLINE')} disabled value={matterList.find(m => m.id === watch("matterId"))?.expiryDate} />
                            </Col>
                        </Row>

                        {/* <ReactTable
                        enableSearch
                        setPagination
                        columns={_COLUMNS}
                        data={matterList}
                        enableCheckbox
                        setCheckboxIds={setSelectedIds}
                        initialPageSize={5}
                        pageFooterClass={"page-footer-white"}
                    /> */}
                    </ModalBody>
                    <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                        <button type="button" className="btn btn-min-width btn-themed grayscale-100" onClick={() => toggleShowModal()}>{t("CANCEL")}</button>
                        {/* <button type="button" className="btn btn-min-width btn-themed" onClick={() => onClickSend()}>Send</button> */}
                        <button type="submit" className="btn btn-min-width btn-themed" >{t('SEND')}</button>
                    </ModalFooter>
                </form>
            </BrandModal>
        </>
    );
}

export default RenewalList;
