
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import AvatarEditModal from './AvatarEditModal';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { InputTypes, ApiKey, RoleType, SweetAlert, SessionKey } from "util/Constant";
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import classnames from 'classnames';
import ChangePassword from "./ChangePassword";
import ImageUploader from 'react-images-upload';
import { AccountantDao, CommonDao } from 'data';
import Notiflix from "notiflix";
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { languagesState, currenciesState, authCredentialState } from "recoil/Atoms";
import { isEmpty } from "lodash";
import AccountantAttachment from "./AccountantAttachment";

/// <summary>
/// Author: Lewis
/// </summary>
const ProfileEdit = props => {
    const { t, i18n } = useTranslation();
    const formRef = useRef();

    const [user, setUser] = useState();
    const [certificateImg, setCertificateImg] = useState([]);
    const [isAvatarEditModalOpen, setAvatarEditModalOpen] = useState(false);
    const [isEditableState, setEditableState] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [expertiseOptions, setExpertiseOptions] = useState([]);
    const languageOptions = useRecoilValue(languagesState);
    const currenciesOptions = useRecoilValue(currenciesState);
    const setAuthCredentialState = useSetRecoilState(authCredentialState);
    const [reset, setReset] = useState(false);

    const GENDER_OPTIONS = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }];

    const _FIELDS_COLUMN = useMemo(() => [
        {
            disabled: true, label: 'EMAIL', name: 'email', input: InputTypes.INPUT, columnOptions: { xl: 6 },
            rules: {
                required: 'Email is required.',
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                }
            }
        },
        { disabled: !isEditableState, label: 'NAME', name: 'name', input: InputTypes.INPUT, rules: { required: 'Name is required', maxLength: 50 }, columnOptions: { xl: 6 }, maxLength: 50 },
        { disabled: !isEditableState, label: "PREFERRED_LANGUAGE", name: 'languageId', input: InputTypes.SELECT, options: languageOptions, columnOptions: { xl: 6 }, rules: { required: 'Language is required' } },
        { disabled: !isEditableState, label: "PREFERRED_CURRENCY", name: 'currencyId', input: InputTypes.SELECT, options: currenciesOptions, columnOptions: { xl: 6 }, rules: { required: 'Currency is required' } },
        { disabled: !isEditableState, label: 'PHONE_NUMBER', name: 'phoneNumber', input: InputTypes.PHONE, rules: { required: 'Contact is required' }, columnOptions: { xl: 6 } },
        { disabled: !isEditableState, label: 'GENDER', name: 'gender', placeholder: t("SELECT_GENDER"), input: InputTypes.SELECT, options: GENDER_OPTIONS, selectClasses: 'w-100', columnOptions: { xl: 6 } },
        { disabled: !isEditableState, label: 'BRIEF_BIO', name: 'biography', input: InputTypes.TEXTAREA, columnOptions: { xl: 12 } },
    ], [isEditableState, languageOptions, currenciesOptions]);

    const _fields = useMemo(() => [
        {
            rowOptions: { xl: 1 },
            columns: (user?.role?.id == RoleType._ACCOUNTANT || user?.role?.id == RoleType._GUEST) ?
                [
                    ..._FIELDS_COLUMN,
                    { disabled: true, label: 'ACCOUNT_STATUS', name: 'accountantStatusName', input: InputTypes.INPUT, columnOptions: { xl: 6 } },
                    { disabled: !isEditableState, label: 'PRACTITIONER_NAME', name: 'practitionerName', input: InputTypes.INPUT, rules: { required: 'Practitioner Name is required' }, columnOptions: { xl: 6 } },
                    { disabled: !isEditableState, label: 'SERVICES', name: 'services', isMulti: true, input: InputTypes.SELECT, options: serviceOptions, selectClasses: 'w-100 h-auto', rules: { required: 'Services is required' }, columnOptions: { xl: 6 } },
                    { disabled: !isEditableState, label: 'EXPERTISE', name: 'expertise', isMulti: true, input: InputTypes.SELECT, options: expertiseOptions, selectClasses: 'w-100 h-auto', rules: { required: 'Expertise is required' }, columnOptions: { xl: 6 } },
                ]
                : [..._FIELDS_COLUMN]
        },
    ], [user, serviceOptions, expertiseOptions, isEditableState, _FIELDS_COLUMN]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    function onChangeUploadImage(picture) {
        setCertificateImg(picture);
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = (data) => {
        let accountantDao = new AccountantDao();

        let { practitionerName, services, expertise, ...restData } = data;

        let params = { id: user.id, ...restData, email: user.email };

        if (practitionerName) {
            params.firm = {
                id: user.firm?.id,
                practitionerName: practitionerName,
                services: services?.map(s => { return { id: s.value } }),
                expertise: expertise?.map(e => { return { id: e.value } })
            };
            (async () => {
                await accountantDao.updateAccountantProfile(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Notiflix.Notify.Success(responseJson[ApiKey._API_MESSAGE_KEY]);

                        let { languageId, currencyPreference, ...restUpdateProps } = responseJson[ApiKey._API_DATA_KEY];

                        setAuthCredentialState(responseJson[ApiKey._API_DATA_KEY]);
                        setUser(formatUserData(responseJson[ApiKey._API_DATA_KEY]));

                        let selectedLanguage = languageOptions.filter(x => x.id == languageId)[0].code;
                        i18n.changeLanguage(selectedLanguage);
                        localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
                    }
                    else {
                        Notiflix.Notify.Warning(responseJson[ApiKey._API_FIRST_ERROR_KEY]);
                    }
                })
            })();
        }
        else {
            (async () => {
                await accountantDao.updateAdminProfile(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Notiflix.Notify.Success(responseJson[ApiKey._API_MESSAGE_KEY]);

                        let { languageId, currencyPreference, ...restUpdateProps } = responseJson[ApiKey._API_DATA_KEY];

                        setAuthCredentialState(responseJson[ApiKey._API_DATA_KEY]);
                        setUser(formatUserData(responseJson[ApiKey._API_DATA_KEY]));

                        let selectedLanguage = languageOptions.filter(x => x.id == languageId)[0].code;
                        i18n.changeLanguage(selectedLanguage);
                        localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
                    }
                    else {
                        Notiflix.Notify.Warning(responseJson[ApiKey._API_FIRST_ERROR_KEY]);
                    }
                })
            })();
        }

        setEditableState(!isEditableState);
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        if (!isEmpty(user)) {
            setReset(true);
        }
    }, [user]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const formatOptions = (label, value) => {
        return { label, value };
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const formatUserData = (userResponseData) => {
        let tempData = userResponseData;
        tempData = {
            ...tempData,
            practitionerName: tempData.firm?.practitionerName,
            expertise: tempData.firm?.expertise.map(item => {
                return formatOptions(item.name, item.id)
            }),
            services: tempData.firm?.services.map(item => {
                return formatOptions(item.name, item.id)
            }),
        };
        return tempData;
    };

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    const getUser = async () => {
        let accountantDao = new AccountantDao();
        await accountantDao.getUser().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setUser(formatUserData(responseJson[ApiKey._API_DATA_KEY]));
            }
        })
    }

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    useEffect(() => {
        let commonDao = new CommonDao();
        getUser();
        (async () => {
            await commonDao.getServicesList().then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempData = responseJson[ApiKey._API_DATA_KEY];
                    setServiceOptions(tempData.map(({ id, name }) => { return formatOptions(name, id) }));
                }
            })
            await commonDao.getExpertisesList().then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempData = responseJson[ApiKey._API_DATA_KEY];
                    setExpertiseOptions(tempData.map(({ id, name }) => { return formatOptions(name, id) }));
                }
            })
        })()
    }, [])

    return (
        <Row style={{ height: '101%' }}>
            <Col xl={7} className="p-r-40 px-4">
                <div className="d-flex m-t-30 mb-3" style={{ alignItems: "end" }}>
                    <img className="mr-2" src={require("../../assets/img/icon/edit.svg")} alt="" /><h4 className="font-weight-bold">{t("PROFILE_DETAILS")}</h4>
                </div>
                <FormBuilder
                    fields={_fields}
                    formRef={formRef}
                    onSubmit={onSubmit}
                    reset={reset}
                    resetTriggerReset={setReset}
                    resetValues={user} />
                <button
                    className={classnames("mr-2 btn btn-themed btn-mid-long", { "d-none": !isEditableState })}
                    disabled={isEditableState ? false : true}
                    onClick={() => { submitForm(formRef); }}> {t("UPDATE")}
                </button>
                <button type="button" onClick={() => {
                    setEditableState(!isEditableState);
                    setReset(true);
                }} className={classnames("btn btn-mid-long", { "btn-themed": !isEditableState, "btn-themed grayscale-100": isEditableState })}>{isEditableState ? t('CANCEL') : t('EDIT')}</button>
            </Col>
            <Col xl={5} className="p-l-30 px-4">
                {
                    (user?.role?.id == RoleType._ACCOUNTANT || user?.role?.id == RoleType._GUEST) &&
                    <AccountantAttachment
                        certificateAttachment={user?.certificateAttachment}
                        identificationAttachment={user?.identificationAttachment}
                        pendingAttachment={user?.pendingAttachment}
                        userId={user?.id}
                        getUser={getUser}
                    />
                }
                <ChangePassword user={user} />
            </Col>
            <AvatarEditModal isModalOpen={isAvatarEditModalOpen} setModalOpen={setAvatarEditModalOpen} />
        </Row >
    )
}

export default ProfileEdit;
