import { WebUrl } from "util/Constant";

const Menu = [
    // { path: WebUrl._DASHBOARD, img: require("../../assets/img/side-menu/dashboard.svg"), title: 'Dashboard' },
    { path: WebUrl._GET_STARTED, img: require("../../assets/img/side-menu/dashboard.svg"), title: 'GET_STARTED' },
    // { path: WebUrl._ADD_NEW_COMPANY, img: require("../../assets/img/side-menu/add-new-company.svg"), title: 'ADD_NEW_COMPANY' },
    { path: WebUrl._COMPANY_MANAGEMENT, img: require("../../assets/img/side-menu/company-management.svg"), title: 'COMPANY_MANAGEMENT' },
    { path: WebUrl._INDIVIDUAL_MANAGEMENT, img: require("../../assets/img/side-menu/individual-management.svg"), title: 'INDIVIDUAL_MANAGEMENT' },
    // { path: WebUrl._BUSINESS_INTELLIGENCE, img: require("../../assets/img/side-menu/business-intelligence.svg"), title: 'Business Intelligence' },
    { path: WebUrl._ACCOUNTANT_CONTACT_US_VIEW, img: require("../../assets/img/side-menu/contact-administrator.svg"), title: 'CONTACT_ADMINISTRATOR' },
];

export const AdminMenu = [
    { path: WebUrl._USERS_MANAGEMENT, img: require("../../assets/img/side-menu/individual-management.svg"), title: 'USER_MANAGEMENT', },
    { path: WebUrl._PRODUCTION_LIST, img: require("../../assets/img/side-menu/product-list.svg"), title: 'PRODUCTION_LIST' },
    // { path: WebUrl._RENEWAL_LIST, img: require("../../assets/img/side-menu/renewal-list.svg"), title: 'RENEWAL_LIST' },
    { path: WebUrl._ADMIN_COMPANY_MANAGEMENT, img: require("../../assets/img/side-menu/company-management.svg"), title: 'COMPANY_MANAGEMENT' },
    { path: WebUrl._FINANCES_LIST, img: require("../../assets/img/side-menu/renewal-list.svg"), title: 'FINANCE_LIST' },
    {
        path: '/configuration', img: require("../../assets/img/side-menu/configuration.svg"), title: 'SYSTEM_CONFIGURATION',
        children: [
            { path: WebUrl._JURISDICTION_MANAGEMENT, title: 'JURISDICTION_MANAGEMENT' },
            { path: WebUrl._CURRENCY_MANAGEMENT, title: 'CURRENCY_MANAGEMENT' },
            { path: WebUrl._DOCUMENT_MANAGEMENT, title: 'DOCUMENT_MANAGEMENT' },
            { path: WebUrl._SERVICE_FEES_MANAGEMENT, title: 'SERVICE_FEE_MANAGEMENT' },
            { path: WebUrl._ROLE_MANAGEMENT, title: 'ROLE_MANAGEMENT' },
        ]
    },
    //{ path: WebUrl._REFERRAL_PACKAGE, icon: 'las la-award', title: 'Referral Package' },
    { path: WebUrl._ADMIN_CHAT_VIEW, img: require("../../assets/img/side-menu/contact-administrator.svg"), title: 'CLIENT_CHAT_ROOM' },
    { path: WebUrl._KYC_INDEX, img: require("../../assets/img/side-menu/search.svg"), title: 'KYC' },
];

export default Menu;
