import NavigationButton from 'components/buttons/NavigationButton';
import moment from 'moment';
import { OfficerPersonalDetail } from 'pages/individual-management';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Row, Col, Collapse } from 'reactstrap';
import { ApiKey, DateFormat, WebUrl } from 'util/Constant';
import ReactTable from '../../components/react-table/ReactTable';
import OfficerDao from '../../data/OfficerDao';
import { doubleClickNavigate } from '../../util/Utility';

/// <summary>
/// Author: Lewis
/// </summary>
const CompanyOfficerProfile = ({ props }) => {
    const { state } = useLocation();
    const _history = useHistory();
    const { t } = useTranslation();
    var officerId = state.data.officerId;
    var companyId = state.data.companyId;
    var companyProfile = state.data.companyProfile;
    const [openIndex, setOpenIndex] = useState(0);
    const [officerDetail, setOfficerDetail] = useState({});
    const [certAttachmentUrl, setCertAttachmentUrl] = useState([]);
    const [identityAttachmentUrl, setIdentityAttachmentUrl] = useState([]);
    const [officerCompanyShareList, setOfficerCompanyShareList] = useState([]);
    const [officerCompanyList, setOfficerCompanyList] = useState([]);
    const [officerCompanyDirectorList, setOfficerCompanyDirectorList] = useState([]);
    const [addressProofAttachmentUrl, setAddressProofAttachmentUrl] = useState([]);

    const _CARD_KEYS = {
        _SHAREHOLDINGS: t("CURRENT_SHAREHOLDINGS"),
        _DIRECTORSHIP: t("CURRENT_DIRECTORSHIP"),
    }
    const _CARD_HEADERS = {
        [_CARD_KEYS._SHAREHOLDINGS]: { id: 0, title: _CARD_KEYS._SHAREHOLDINGS, component: OfficerShareHoldingsComponent, icon: require("../../assets/img/icon/icon-currentshareholdings.svg") },
        [_CARD_KEYS._DIRECTORSHIP]: { id: 1, title: _CARD_KEYS._DIRECTORSHIP, component: OfficerDirectorshipComponent, icon: require("../../assets/img/icon/current-directorship.svg") },
    }

    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    const getOfficerProfile = async () => {
        let dao = new OfficerDao();
        await dao.getOfficerById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var identityAttachment = data?.idAttachment?.fileMeta;
                var addressProofAttachment = data?.addressAttachment?.fileMeta;
                var certAttachmentFileMeta = data?.certAttachment?.fileMeta;

                if (identityAttachment) {
                    var paths = []
                    identityAttachment.map(item => { paths.push(item.requestPath) });
                    setIdentityAttachmentUrl(paths);
                }

                if (addressProofAttachment) {
                    var paths = []
                    addressProofAttachment.map(item => { paths.push(item.requestPath) });
                    setAddressProofAttachmentUrl(paths);
                }

                if (certAttachmentFileMeta) {
                    var paths = []
                    certAttachmentFileMeta.map(item => paths.push(item.requestPath));
                    setCertAttachmentUrl(paths);
                }

                setOfficerDetail(data);
            }
        })
    }

    useEffect(() => {
        getOfficerProfile();
    }, [])

    //const IndividualProfileComponent = () => {
    //    const { control } = useForm();
    //    return (
    //        <Card>
    //            <CardHeader><span className="badge badge-info"><i class="fa fa-user" /></span>&nbsp;&nbsp;Profile Details</CardHeader>
    //            {
    //                officerDetail
    //                    ?
    //                    <CardBody>
    //                        <table className="company-officer-profile-detail w-100">
    //                            <tbody>
    //                                <tr><td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i class="fas fa-id-card" /> &nbsp;&nbsp;English</span></td>
    //                                    <td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i class="fas fa-id-card" />&nbsp;&nbsp; Chinese</span></td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Name</td>
    //                                    <td>{officerDetail.englishName}</td>
    //                                    <td className="text-label">Chinese Name</td>
    //                                    <td>{officerDetail.chineseName}</td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Identity Type</td><td>{officerDetail.identityType}</td>
    //                                    <td className="text-label">Identification Number</td><td>{officerDetail.identityNumber}</td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Surname</td><td>{officerDetail.englishSurname}</td>
    //                                    <td className="text-label">Surname</td><td>{officerDetail.chineseSurname}</td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Alias</td><td>{officerDetail.englishAliasName}</td>
    //                                    <td className="text-label">Alias</td><td>{officerDetail.chineseAliasName}</td>
    //                                </tr>
    //                            </tbody>
    //                        </table>
    //                        <hr />
    //                        <table className="company-officer-profile-detail w-100">
    //                            <tbody>
    //                                <tr>
    //                                    <td className="p-b-10"><span className="badge badge-warning"><i class="fas fa-info-circle" />&nbsp;&nbsp;Info</span></td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Address</td><td className="address-field">{officerDetail && officerDetail.address && officerDetail.address.address1}</td>
    //                                    <td className="text-label">Country</td><td>{officerDetail && officerDetail.address && officerDetail.address.countryName}</td>
    //                                </tr>
    //                                <tr>
    //                                    {/*<td className="text-label">Phone</td><td><InputHoc formGroupClass={'m-0'} defaultValue={officerDetail.phoneNumber} control={control} disabled /></td>*/}
    //                                    <td className="text-label">Phone</td><td>{officerDetail.phoneNumber}</td>
    //                                    <td className="text-label">Wechat</td><td>{officerDetail.weChat}</td>
    //                                </tr>
    //                            </tbody>
    //                        </table>
    //                        <hr />
    //                        <table className="company-officer-profile-detail w-100">
    //                            <tbody>
    //                                <tr><td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i className="fas fa-birthday-cake" /> &nbsp;&nbsp;Birth</span></td></tr>
    //                                <tr>
    //                                    <td className="text-label">Nationality</td><td>{officerDetail.nationality}</td>
    //                                    <td className="text-label">Place of birth</td><td>{officerDetail.placeOfBirth}</td>
    //                                </tr>
    //                                <tr>
    //                                    <td className="text-label">Date Of Birth</td><td>{moment(officerDetail.dateOfBirth).format(DateFormat._DATE_ONLY)}</td>
    //                                </tr>
    //                            </tbody>
    //                        </table>
    //                        <hr />
    //                        <table className="company-officer-profile-detail w-100">
    //                            <tbody>
    //                                <tr>
    //                                    <td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i className="fas fa-file-alt" /> &nbsp;&nbsp;Attachment</span></td>
    //                                </tr>
    //                                <tr><td colSpan="2" className="text-label">ID Attachment</td><td className="text-label">Address Proof</td></tr>
    //                                {
    //                                    identityAttachmentUrl.length > 0 && addressProofAttachmentUrl.length > 0
    //                                        ?
    //                                        <tr>
    //                                            <td><ModalImage small={identityAttachmentUrl} medium={identityAttachmentUrl} large={identityAttachmentUrl} /></td>
    //                                            <td></td>
    //                                            <td><ModalImage small={addressProofAttachmentUrl} medium={addressProofAttachmentUrl} large={addressProofAttachmentUrl} /></td>
    //                                            <td></td>
    //                                        </tr>
    //                                        :
    //                                        <tr>
    //                                            <td> N/A </td>
    //                                            <td></td>
    //                                            <td> N/A </td>
    //                                            <td></td>
    //                                        </tr>
    //                                }
    //                            </tbody>
    //                        </table>
    //                    </CardBody>
    //                    :
    //                    <></>
    //            }

    //        </Card>
    //    )
    //}

    //const CorporateProfileComponent = () => {
    //    return (
    //        <Card>
    //            <CardHeader><span className="badge badge-info"><i class="fa fa-industry" /></span>&nbsp;&nbsp;Corporate Details</CardHeader>
    //            {   officerDetail
    //                ?
    //                <CardBody>
    //                    <table className="company-officer-profile-detail w-100">
    //                        <tbody>
    //                            <tr><td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i class="fas fa-id-card" /> &nbsp;&nbsp;English</span></td><td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i class="fas fa-id-card" />&nbsp;&nbsp; Chinese</span></td></tr>
    //                            <tr>
    //                                <td className="text-label">Name</td>
    //                                <td>{officerDetail.englishName}</td>
    //                                <td className="text-label">Chinese Name</td>
    //                                <td>{officerDetail.chineseName}</td>
    //                            </tr>
    //                            <tr>
    //                                <td className="text-label">Former Name</td>
    //                                <td>{officerDetail.englishFormerName}</td>
    //                                <td className="text-label">Former Name</td>
    //                                <td>{officerDetail.chineseFormerName}</td>
    //                            </tr>
    //                        </tbody>
    //                    </table>
    //                    <hr />
    //                    <table className="company-officer-profile-detail w-100">
    //                        <tbody>
    //                            <tr>
    //                                <td className="p-b-10"><span className="badge badge-warning"><i class="fas fa-info-circle" />&nbsp;&nbsp;Info</span></td>
    //                            </tr>
    //                            <tr>
    //                                <td className="text-label">CI Number</td><td>{officerDetail.ciNumber}</td>
    //                                <td className="text-label">Date Of Incorporation</td><td>{officerDetail && officerDetail.incorporationDate ? moment(officerDetail && officerDetail.incorporationDate).format(DateFormat._DATE_ONLY) : "N/A"}</td>
    //                            </tr>
    //                        </tbody>
    //                    </table>
    //                    <hr />
    //                    <table className="company-officer-profile-detail w-100">
    //                        <tbody>
    //                            <tr><td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i class="fas fa-map-marker-alt" />&nbsp;&nbsp;Location</span></td></tr>
    //                            <tr>
    //                                <td className="text-label">Address</td><td className="address-field">{officerDetail && officerDetail.address && officerDetail.address.address1}</td>
    //                            </tr>
    //                            <tr>
    //                                <td className="text-label">Country</td><td>{officerDetail && officerDetail.address && officerDetail.address.country && officerDetail.address.countryName}</td>
    //                                <td className="text-label">Jurisdiction Of Incorp.</td><td>{officerDetail && officerDetail.address && officerDetail.address.countryName}</td>
    //                            </tr>
    //                        </tbody>
    //                    </table>
    //                    <table className="company-officer-profile-detail w-100">
    //                        <tbody>
    //                            <tr>
    //                                <td colSpan="2" className="p-b-10"><span className="badge badge-warning"><i className="fas fa-file-alt" /> &nbsp;&nbsp;Attachment</span></td>
    //                            </tr>
    //                            <tr><td colSpan="2" className="text-label">Certificate Attachment</td></tr>
    //                            {
    //                                certAttachmentUrl.length > 0
    //                                    ?
    //                                    <tr>
    //                                        <td><ModalImage small={certAttachmentUrl} medium={certAttachmentUrl} large={certAttachmentUrl} /></td>
    //                                        <td></td>
    //                                    </tr>
    //                                    :
    //                                    <tr>
    //                                        <td> N/A </td>
    //                                        <td></td>
    //                                    </tr>
    //                            }
    //                        </tbody>
    //                    </table>
    //                </CardBody >
    //                :
    //                <></>
    //            }
    //        </Card >
    //    )
    //}


    return (
        <>
            <div className="panel panel-brand panel-flex" id="OfficerProfile" style={{ backdropFilter: "none" }}>
                <div className="panel-body y-scrollbar-2">
                    <Row>
                        <Col className="d-inline-flex">
                            <NavigationButton /> <h1 className="page-header title title-with-dot">{t("COMPANY_OFFICER")}</h1>
                        </Col>
                    </Row>
                    <div className="table-brand-wrapper">
                        <div className="d-flex-center pill-header justify-center">
                            <div className="company-name-pill">
                                <h4 className="m-0">{companyProfile && companyProfile.englishName}{companyProfile?.chineseName ? "(" + companyProfile.chineseName + ")" : ""}</h4>
                                <p>CI {t("NUMBER_SHORT")}: {companyProfile && companyProfile.cNumber}</p>
                            </div>
                        </div>
                        <Row>
                            <Col xl={6} sm={12} >
                                <OfficerPersonalDetail fetchOfficerId={officerId} officerType={officerDetail && officerDetail.officerTypeId} />
                            </Col>
                            <Col xl={6} sm={12}>
                                {
                                    Object.keys(_CARD_HEADERS).map((key, index) => {
                                        return (<>
                                            <Card key={index}>
                                                <CardHeader onClick={() => { setOpenIndex(index) }} style={{ backgroundColor: "#EDEEF1" }}>
                                                    <img className="mr-3" src={_CARD_HEADERS[key].icon} />{_CARD_HEADERS[key].title} <i class="fas fa-caret-down right"></i>
                                                </CardHeader>
                                                <Collapse isOpen={openIndex == index ? true : false}>
                                                    <CardBody>
                                                        {
                                                            React.createElement(_CARD_HEADERS[key].component, { officerId: officerId })
                                                        }
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        </>)
                                    })
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </>
    )
}

export const OfficerShareHoldingsComponent = props => {
    const { officerId, companyId } = props;
    const { state } = useLocation();
    const _history = useHistory();
    const [officerCompanyShareList, setOfficerCompanyShareList] = useState([]);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        getOfficerCompanyShareList();
    }, [officerId])

    const getOfficerCompanyShareList = async () => {
        let dao = new OfficerDao();
        await dao.getOfficerCompanyShareListById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setOfficerCompanyShareList(data);
            }
        })
    }

    const _SHARE_HOLDINGS_COLUMN = useMemo(() => [
        {
            Header: 'C#',
            Cell: ({ row }) => { return (<>{row.original.company.cNumber}</>) }
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => { return (<>{row.original.company.englishName}</>) }
        },
        {
            Header: 'INCORPORATION_DATE',
            Cell: ({ row }) => {
                return (<>
                    {
                        row.original.company.incorporationDate
                            ?
                            moment(row.original.company.incorporationDate).format(DateFormat._DATE_ONLY)
                            : "N/A"
                    }
                </>)
            }
        },
        {
            Header: 'CURRENCY',
            Cell: ({ row }) => { return (<>{row.original.code}/{row.original.symbol}</>) }
        },
        {
            Header: 'SHARES',
            Cell: ({ row }) => {
                return (<>
                    {row.original.shareAllocated} - {Math.round((parseFloat(row.original.shareAllocated) / row.original.issuedShares + Number.EPSILON) * 100)}%
                </>)
            }
        }
    ])
    return (<>
        <ReactTable
            columns={_SHARE_HOLDINGS_COLUMN}
            data={officerCompanyShareList}
            setPagination
            getRowProps={(row) => {
                return {
                    ...doubleClickNavigate(_history, WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(':companyId', selectedRow)),
                    onClick: (e) => {
                        setSelectedRow(row.index);
                    },
                    style: {
                        background: row.index == selectedRow ? 'lightgray' : null,
                        color: row.index == selectedRow ? 'white' : 'black',
                    },
                }
            }}
        />
    </>);
}

export const OfficerDirectorshipComponent = props => {
    const { officerId, companyId } = props;
    const { state } = useLocation();
    const _history = useHistory();
    const [officerCompanyDirectorList, setOfficerCompanyDirectorList] = useState([]);
    const [selectedRow, setSelectedRow] = useState();


    useEffect(() => {
        getOfficerCompanyDirectorList();
    }, [officerId])

    const getOfficerCompanyDirectorList = async () => {
        let dao = new OfficerDao();
        await dao.getOfficerCompanyDirectorListById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setOfficerCompanyDirectorList(data);
            }
        })
    }

    const _DIRECTOR_COLUMNS = useMemo(() => [
        {
            Header: 'C#',
            Cell: ({ row }) => { return (<>{row.original.company.cNumber}</>) }
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => { return (<>{row.original.company.englishName}</>) }
        },
        {
            Header: 'INCORPORATION_DATE_SHORT',
            Cell: ({ row }) => {
                return (<>
                    {
                        row.original.company.incorporationDate
                            ?
                            moment(row.original.company.incorporationDate).format(DateFormat._DATE_ONLY)
                            : "N/A"
                    }
                </>)
            }
        }
    ])

    return (<>
        <ReactTable
            columns={_DIRECTOR_COLUMNS}
            data={officerCompanyDirectorList}
            setPagination
            getRowProps={(row) => {
                return {
                    ...doubleClickNavigate(_history, WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(':companyId', selectedRow)),
                    onClick: (e) => {
                        setSelectedRow(row.index);
                    },
                    style: {
                        background: row.index == selectedRow ? 'lightgray' : null,
                        color: row.index == selectedRow ? 'white' : 'black',
                    },
                }
            }}
        />
    </>);
}

export const OfficerRelatedCompaniesComponent = props => {
    const { officerId, companyId } = props;
    const { state } = useLocation();
    const _history = useHistory();
    const [officerCompanyList, setOfficerCompanyList] = useState([]);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        getOfficerCompanyList();
    }, [officerId])

    const getOfficerCompanyList = async () => {
        let dao = new OfficerDao();
        await dao.getOfficerCompanyListById(officerId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setOfficerCompanyList(data);
            }
        })
    }

    const _RELATED_COMPANIES_COLUMNS = useMemo(() => [
        {
            Header: 'C#',
            Cell: ({ row }) => { return (<>{row.original.company.cNumber}</>) }
        },
        {
            Header: 'Company Name',
            Cell: ({ row }) => { return (<>{row.original.company.englishName}</>) }
        },
        {
            Header: 'Incrop. Date',
            Cell: ({ row }) => {
                return (<>
                    {
                        row.original.company.incorporationDate
                            ?
                            moment(row.original.company.incorporationDate).format(DateFormat._DATE_ONLY)
                            : "N/A"
                    }
                </>)
            }
        },
        {
            Header: 'Officer Position',
            Cell: ({ row }) => { return (<>{row.original.roleLabel}</>) }
        }
    ])

    return (<>
        <ReactTable
            columns={_RELATED_COMPANIES_COLUMNS}
            data={officerCompanyList}
            getRowProps={(row) => {
                return {
                    ...doubleClickNavigate(_history, WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(':companyId', selectedRow)),
                    onClick: (e) => {
                        setSelectedRow(row.index);
                    },
                    style: {
                        background: row.index == selectedRow ? 'lightgray' : null,
                        color: row.index == selectedRow ? 'white' : 'black',
                    },
                }
            }}
            setPagination />
    </>);
}

export default CompanyOfficerProfile;