import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useResizeDetector } from 'react-resize-detector';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { RoleDao } from 'data';
import { isEmpty } from 'lodash';
import Notiflix, { Block } from 'notiflix';
import { PageSettings } from 'config/page-settings';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import InputHoc from "components/form/InputHoc";
import BrandModal from 'components/modals/BrandModal';
import ButtonRound from 'components/buttons/ButtonRound';
import ReactTable from "components/react-table/ReactTable";
import { InputTypes, BtnTypes, ApiKey, LoadingStateText, SweetAlert, ApiUrl } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

//<summary>
//Author: Robin
//</summary>
export const RolePolicyObjectAssignActionsModal = props => {

    const { modal, setModal, setRefreshTable, role, roleName, roleObject, roleActions, actionsOption } = props;

    const _ref = useRef();
    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();

    const toggle = () => {
        setModal(!modal);
    }

    const _ROLE_OBJECT_ACTIONS_COLUMN = useMemo(() => [
        {
            Header: "systemConfiguration:ROLE",
            accessor: "role",
            Cell: ({ row }) => {
                if (row.original.role) {
                    return (row.original.role);
                } else {
                    return <> - </>
                }
            }
        },
        {
            Header: "systemConfiguration:MODULE",
            accessor: "object",
            Cell: ({ row }) => {
                if (row.original.object) {
                    return (row.original.object);
                } else {
                    return <> - </>
                }
            }
        },
        {
            Header: "systemConfiguration:PERMISSION",
            accessor: "action",
            Cell: ({ row }) => {
                if (row.original.action) {
                    return <InputHoc name={`actions[${row.index}]`}
                        inputType={InputTypes.CHECKBOX}
                        options={[{
                            label: row.original.action,
                            value: row.original.action
                        }]}
                        checkboxInline
                        isSwitcher
                        ref={register}
                        defaultValue={roleActions?.filter(item => item === row.original.action)}
                    />
                } else {
                    return <> - </>
                }
            }
        }
    ], [roleActions]);

    const assignActionsToRoleObject = async (data) => {
        Block.Circle("div#roleObjectActions", LoadingStateText._PLEASE_WAIT);
        if (role.id != null) data.id = role.id;
        data.object = roleObject;
        data.actions = data.actions.filter(item => item !== false);

        let dao = new RoleDao();

        await dao.assignActionsToRoleObject(data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                // setRefreshTable(prevState => !prevState);
                setRefreshTable();
                toggle();
            }
            else {
                Notiflix.Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t(SweetAlert._ERROR_HAS_OCCUR),
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove("div#roleObjectActions"));
    };

    return (<>
        <BrandModal
            modalSize={'md'}
            isOpen={modal}
            toggler={toggle}
            title={t("systemConfiguration:ROLE_POLICY_MODULE_PERMISSION")}
            customBody
        >
            <ModalBody className="p-0" id="roleObjectActions">
                <form onSubmit={handleSubmit(assignActionsToRoleObject)} ref={_ref} autoComplete="off">
                    <ReactTable
                        enableSearch
                        columns={_ROLE_OBJECT_ACTIONS_COLUMN}
                        data={
                            actionsOption &&
                            actionsOption.map((value, index) => {
                                return {
                                    role: roleName,
                                    object: roleObject,
                                    action: value
                                }
                            })
                        }
                        setPagination
                        isInheritHeight
                    />
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-right">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(assignActionsToRoleObject)()}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>);
};

export default RolePolicyObjectAssignActionsModal;