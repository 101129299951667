import { ApiUrl, ApiKey, DateFormat, OfficerTypeID } from 'util/Constant';
import { compareObjectDiffAttributesAndGetProps, createMultiPartFormBody, filterFileObjectToSubmit } from 'util/Utility';
import DataAccessObject from './DataAccessObject';
import moment from "moment";

/**
 * Author: Lewis
 */
class CompanyDao extends DataAccessObject {
    //Companies
    async getCompanies() {
        return this.get(ApiUrl._API_RETRIEVE_COMPANIES);
    }
    //Name Check
    async getNameCheckRemarksList(nameCheckId) {
        return this.get(ApiUrl._API_GET_COMPANY_REMARKS_LIST.replace(':id', nameCheckId));
    }

    async createCompanyNameCheck(data) {
        return this.post(ApiUrl._API_CREATE_COMPANY_NAME_CHECK, data);
    }

    async getCompanyNameCheckList() {
        return this.get(ApiUrl._API_GET_COMPANY_NAME_CHECK_LIST);
    }

    async setApproveAvailability(nameCheckId) {
        return this.post(ApiUrl._API_UPDATE_APPROVE_AVAILABILITY.replace(':id', nameCheckId));
    }

    async setRejectAvailability(nameCheckId) {
        return this.post(ApiUrl._API_UPDATE_REJECT_AVAILABILITY.replace(':id', nameCheckId));
    }

    async confirmApproveCompanyNameCheck(nameCheckId) {
        return this.post(ApiUrl._API_UPDATE_APPROVE_COMPANY_NAME_CHECK.replace(':id', nameCheckId));
    }

    async confirmRejectCompanyNameCheck(nameCheckId) {
        return this.post(ApiUrl._API_UPDATE_REJECT_COMPANY_NAME_CHECK.replace(':id', nameCheckId));
    }

    async updateFollowUpDateAndRemark(nameCheckId, date, remark) {
        return this.post(ApiUrl._API_UPDATE_NAME_CHECK_FOLLOW_UP_DATE_AND_REMARK.replace(':id', nameCheckId), { "FollowUpDateTime": date, "Remark": remark });
    }

    //Company Share
    async getCompanyDetailsById(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId));
    }

    async getStandardShareCapitalListById(jurisdictionId) {
        return this.get(ApiUrl._API_GET_STANDARD_SHARE_CAPITAL_PACKAGE_LIST_BY_ID.replace(':jurisdictionId', jurisdictionId));
    }

    async getClassOfShareList() {
        return this.get(ApiUrl._API_GET_CLASS_OF_SHARE_LIST);
    }

    async getCustomShareCapitalList(id) {
        return this.get(ApiUrl._API_GET_CUSTOM_SHARE_CAPITAL_PACKAGE_LIST.replace(':id', id));
    }

    async updateCompanyStandardCapitalPackage(data) {
        return this.post(ApiUrl._API_USE_COMPANY_STANDARD_CAPITAL_PACKAGE, data);
    }

    async createOrUpdateCompanyCustomCapitalPackage(data) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_COMPANY_CUSTOM_CAPITAL_PACKAGE, data);
    }

    async removeCustomCompanyShareCapital(shareId, companyId) {
        return this.delete(ApiUrl._API_REMOVE_CUSTOM_COMPANY_SHARE_CAPITAL.replace(':shareId', shareId).replace(':companyId', companyId));
    }

    //IncorporationPackage
    async getIncorporationPackage(jurisdictionId) {
        return this.get(ApiUrl._API_GET_INCORPORATION_PACKAGE.replace(':jurisdictionId', jurisdictionId));
    }

    async useIncorporationPackage(companyId, incorpData) {
        return this.post(ApiUrl._API_USE_INCORPORATION_PACKAGE.replace(':companyId', companyId), incorpData);
    }

    async useProvideOwnIncorporationPackage(companyId) {
        return this.post(ApiUrl._API_USE_PROVIDE_OWN_INCOPRORATION_PACKAGE.replace(':companyId', companyId));
    }

    //Officer && Secreatary
    async createCompanyIndividualSecreatary(companyId, secretaryData) {
        let { postcode, state, countryId, address1, address2,
            identityAttachment, addressProofAttachment, ...rest } = secretaryData

        var postData = {
            address: { postcode, state, countryId, address1, address2 },
            ...rest,
        };

        var formBody = createMultiPartFormBody(postData);
        formBody.append('addressProofAttachment', addressProofAttachment?.[0]);
        formBody.append('identityAttachment', identityAttachment?.[0]);
        return this.postForm(ApiUrl._API_CREATE_COMPANY_INDIVIDUAL_SECREATARY.replace(':companyId', companyId), formBody);
    }

    async createCompanyCorporateSecreatary(companyId, secretaryData) {
        let { postcode, state, countryId, address1, address2,
            certificateAttachment, ...rest } = secretaryData

        var postData = {
            address: { postcode, state, countryId, address1, address2 },
            ...rest,
        };

        var formBody = createMultiPartFormBody(postData);
        formBody.append('certificateAttachment', certificateAttachment?.[0]);
        return this.postForm(ApiUrl._API_CREATE_COMPANY_CORPORATE_SECREATARY.replace(':companyId', companyId), formBody);
    }

    async createExistingIndividualCompanySecretary(companyId, secretaryData) {
        return this.post(ApiUrl._API_CREATE_EXISTING_COMPANY_SECRETARY.replace(':companyId', companyId), secretaryData);
    }

    async createExistingCorporateCompanySecretary(companyId, secretaryData) {
        return this.post(ApiUrl._API_CREATE_EXISTING_COMPANY_SECRETARY.replace(':companyId', companyId), secretaryData);
    }

    async createAddress(companyId, addressData) {
        return this.post(ApiUrl._API_CREATE_CUSTOM_COMPANY_ADDRESS.replace(':companyId', companyId), addressData);

    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async updateCompanyOfficer(formData, companyId) {
        let { address1, address2, countryId, state, postcode, officerTypeId,
            identityAttachment, addressProofAttachment, certificateAttachment, dateOfBirth, dateOfIncorporation, ...rest } = formData;
        var postData = {
            address: { address1, address2, countryId, state, postcode },
            ...rest,
        }
        var formBody = createMultiPartFormBody(postData);
        formBody.append('dateOfBirth', moment(dateOfBirth).format(DateFormat._DATE_FORMAT));
        formBody.append('dateOfIncorporation', moment(dateOfIncorporation).format(DateFormat._DATE_FORMAT));
        formBody.append('addressProofAttachment', filterFileObjectToSubmit(addressProofAttachment, true));
        formBody.append('identityAttachment', filterFileObjectToSubmit(identityAttachment, true));
        formBody.append('certificateAttachment', filterFileObjectToSubmit(certificateAttachment, true));

        if (officerTypeId == OfficerTypeID._INDIVIDUAL) {
            return this.postForm(ApiUrl._API_UPDATE_COMPANY_OFFICER_INDIVIDUAL.replace(":companyId", companyId), formBody);
        }
        return this.postForm(ApiUrl._API_UPDATE_COMPANY_OFFICER_CORPORATE.replace(":companyId", companyId), formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async deleteCompanyOfficer(companyId, officerId) {
        return this.delete(ApiUrl._API_REMOVE_COMPANY_OFFICER.replace(":officerId", officerId).replace(":companyId", companyId));
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getCompanyOfficerById(companyId, officerId) {
        return this.get(ApiUrl._API_GET_COMPANY_OFFICER_BY_ID.replace(":companyId", companyId).replace(":officerId", officerId));
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getCompanyOfficerList(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_OFFICER_LIST.replace(":companyId", companyId));
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async createCompanyIndividualOfficer(companyId, formData) {
        let { address1, address2, countryId, state, postcode,
            identityAttachment, addressProofAttachment, dateOfBirth, ...rest } = formData;
        var postData = {
            address: { address1, address2, countryId, state, postcode },
            ...rest,
        }
        var formBody = createMultiPartFormBody(postData);
        formBody.append('dateOfBirth', moment(dateOfBirth).format(DateFormat._DATE_FORMAT));
        formBody.append('addressProofAttachment', filterFileObjectToSubmit(addressProofAttachment, true) ?? addressProofAttachment?.[0]);
        formBody.append('identityAttachment', filterFileObjectToSubmit(identityAttachment, true) ?? identityAttachment?.[0]);

        return this.postForm(ApiUrl._API_CREATE_COMPANY_INDIVIDUAL_OFFICER.replace(':companyId', companyId), formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async createCompanyCorporateOfficer(companyId, formData) {
        let {
            address1,
            countryId,
            certificateAttachment,
            dateOfIncorporation, ...rest } = formData;

        var postData = {
            address: { address1, countryId },
            ...rest,
        }

        var formBody = createMultiPartFormBody(postData);
        formBody.append('dateOfIncorporation', moment(dateOfIncorporation).format(DateFormat._DATE_FORMAT));
        formBody.append('certificateAttachment', filterFileObjectToSubmit(certificateAttachment, true) ?? certificateAttachment?.[0]);
        return this.postForm(ApiUrl._API_CREATE_COMPANY_CORPORATE_OFFICER.replace(':companyId', companyId), formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async bindOfficerToCompany(companyId, officerDto) {
        return this.post(ApiUrl._API_ADD_EXISTING_OFFICER.replace(':companyId', companyId), officerDto);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async checkIsOfficerBindedToCompany(officerId, companyId) {
        return this.get(ApiUrl._API_GET_CHECK_OFFICER_BINDED_TO_COMPANY
            .replace(':companyId', companyId)
            .replace(':officerId', officerId)
        );
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async assignCompanyOfficerRole(companyId, officerId, formData) {
        return this.post(ApiUrl._API_ASSIGN_COMPANY_OFFICER_ROLE
            .replace(':companyId', companyId)
            .replace(':officerId', officerId), formData);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getShareCapitalList(companyId) {
        return this.get(ApiUrl._API_GET_SHARES_CAPITAL_LIST.replace(':companyId', companyId));
    }

    async saveKycQuestions(companyId, data) {
        return this.post(ApiUrl._API_SAVE_KYC_QUESTIONS.replace(':id', companyId), data);
    }

    async deleteKycQuestions(companyId, data) {
        return this.post(ApiUrl._API_DELETE_KYC_QUESTIONS.replace(':id', companyId), data);
    }

    async createSubmitCompanyTransferIn(data) {
        return this.post(ApiUrl._API_CREATE_SUBMIT_COMPANY_TRANSFER_IN, data);
    }

    async submitIncorporationRequest(companyId) {
        return this.post(ApiUrl._API_SUBMIT_COMPANY_INCORPORATION_REQUEST.replace(':id', companyId));
    }

    async submitNewServiceRequest(companyId, productMatterId) {
        return this.post(ApiUrl._API_SUBMIT_NEW_SERVICE_REQUEST.replace(':companyId', companyId).replace(':productMatterId', productMatterId));
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    async updateProposedFyeMonth(companyId, data) {
        return this.post(ApiUrl._API_UPDATE_PROPOSED_FYE.replace(':id', companyId), data);
    }

    async getFinancialYear(financialYearId) {
        return this.get(ApiUrl._API_GET_FINANCIAL_YEAR.replace(':financialYearId', financialYearId));
    }

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    async createOrUpdateFinancialYearQuestionnaire(postData) {
        let { financialStatementDocument, xbrlDocument } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (financialStatementDocument) {
            formBody.append('financialStatementDocument.docProofAttachment', financialStatementDocument?.docProofAttachment?.[0]);
            formBody.append('financialStatementDocument.effectiveDate', moment(financialStatementDocument?.effectiveDate).format(DateFormat._DATE_FORMAT));
        }
        if (xbrlDocument) {
            formBody.append('xbrlDocument.docProofAttachment', xbrlDocument?.docProofAttachment?.[0]);
            formBody.append('xbrlDocument.effectiveDate', moment(xbrlDocument?.effectiveDate).format(DateFormat._DATE_FORMAT));
        }

        return this.postForm(ApiUrl._API_CREATE_OR_UPDATE_FINANCIAL_YEAR_QUESTIONNAIRE, formBody);
    }
    async updateAnnualComplianceDocument(postData) {
        let { companyDocument } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (companyDocument) {
            formBody.append('companyDocument.docProofAttachment', companyDocument?.docProofAttachment?.[0]);
            formBody.append('companyDocument.effectiveDate', moment(companyDocument?.effectiveDate).format(DateFormat._DATE_FORMAT));
        }

        return this.postForm(ApiUrl._API_UPDATE_ANNUAL_COMPLIANCE_DOCUMENT, formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async removeOfficerShareCapital(companyShareCapitalId, companyId, officerId) {
        return this.delete(ApiUrl._API_DELETE_OFFICER_SHARE
            .replace(":companyId", companyId)
            .replace(":companyShareCapitalId", companyShareCapitalId)
            .replace(":officerId", officerId)
        );
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async addOfficerShareCapital(companyId, officerId, postData) {
        return this.post(ApiUrl._API_ADD_OFFICER_SHARE
            .replace(":companyId", companyId)
            .replace(":officerId", officerId), postData);
    }

    async updateAnnualComplianceStatus(id, status) {
        return this.post(ApiUrl._API_UPDATE_ANNUAL_COMPLIANCE_STATUS, { "id": id, "status": status });
    }

    async saveEsaQuestionnaire(id, data) {
        return this.post(ApiUrl._API_SAVE_ESA_QUESTIONNAIRE.replace(':id', id), data);
    }

    async getFinancialYear(financialYearId) {
        return this.get(ApiUrl._API_GET_FINANCIAL_YEAR.replace(':financialYearId', financialYearId));
    }
    async getCompanyFinancialYears(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_FINANCIAL_YEARS.replace(':companyId', companyId));
    }
    async getCloseDeadlineCompanies(queryParams) {
        return this.get(ApiUrl._API_GET_CLOSE_DEADLINE_COMPANY, queryParams);
    }
    async closeFinancialYear(financialYearId) {
        return this.post(ApiUrl._API_CLOSE_AND_TRANSFER_FINANCIAL_YEAR.replace(":financialYearId", financialYearId));
    }

    async getUpcomingAnnualCompliancesDeadline() {
        return this.get(ApiUrl._API_GET_UPCOMING_ANNUAL_COMPLIANCES_DEADLINE);
    }

    async sendAnnualComplianceDeadline(companyId) {
        return this.get(ApiUrl._API_MANUAL_SENT_ANNUAL_COMPLIANCE_DEADLINE_EMAIL.replace(':companyId', companyId));
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getCompanyStatus() {
        return this.get(ApiUrl._API_GET_COMPANY_STATUS_LIST);
    }

    async performCompanyGoLive(companyId, data) {
        return this.post(ApiUrl._API_COMPANY_GO_LIVE.replace(':companyId', companyId), data);
    }

    async rejectIncorporationRequest(companyId, data) {
        return this.post(ApiUrl._API_COMPANY_REJECT_INCORPORATION_REQUEST.replace(':companyId', companyId), data);
    }

    // <summary>
    // Author: CJ(Jiann)
    // </summary>
    async getServiceProductMatterListByCompanyId(companyId) {
        return this.get(ApiUrl._API_GET_SERVICE_PRODUCT_MATTER_LIST_BY_COMPANY_ID.replace(':companyId', companyId));
    }

    async getMiscProductMatterListByCompanyId(companyId) {
        return this.get(ApiUrl._API_GET_MISC_PRODUCT_MATTER_LIST_BY_COMPANY_ID.replace(':companyId', companyId));
    }

    async getTaskDetail(id) {
        return this.get(ApiUrl._API_GET_TASK_DETAIL.replace(':id', id));
    }

    async onChangeWorkflowTaskOwnerAssignee(data) {
        return this.post(ApiUrl._API_ON_CHANGE_WORKFLOW_TASK_OWNER_ASSIGNEE, data);
    }

    async getCompanyOfficerRemainingShares(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_OFFICERS_REMAINING_SHARES.replace(':companyId', companyId));
    }

    async checkShareHeld(companyId, officerId, companyShareCapitalId) {
        return this.get(ApiUrl._API_CHECK_SHARE_HELD.replace(':companyId', companyId).replace(':officerId', officerId).replace(':companyShareCapitalId', companyShareCapitalId));
    }

    async getOnGoingTaskListByMatterId(matterId) {
        return this.get(ApiUrl._API_GET_TASK_LIST_BY_ONGOING_MATTER_ID.replace(":matterId", matterId));
    }

    async addWorkflowTaskFollowUpRemark(postData) {
        return this.post(ApiUrl._API_ADD_WORKFLOW_TASK_FOLLOWUP_REMARK, postData);
    }

    async updateTaskDetail(taskDto) {
        return this.post(ApiUrl._API_UPDATE_TASK_DETAIL, taskDto);
    }

    async getBeneficiaryTree(companyId) {
        return this.get(ApiUrl._API_GET_BENEFICIARY_TREE.replace(':id', companyId));
    }

    async addBeneficiary(companyId, data) {
        return this.post(ApiUrl._API_ADD_BENEFICIARY.replace(':id', companyId), data);
    }

    async removeBeneficiary(companyId, nodeId) {
        return this.delete(ApiUrl._API_REMOVE_BENEFICIARY.replace(':id', companyId).replace(':nodeId', nodeId));
    }

    async updateBeneficiaryUbo(companyId, nodeId) {
        return this.post(ApiUrl._API_UPDATE_BENEFICIARY_UBO.replace(':id', companyId).replace(':nodeId', nodeId));
    }

    async getCompanyDocumentList(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_DOCUMENT_LIST.replace(":companyId", companyId));
    }

    async adoptCompanyDocument(documentId) {
        return this.post(ApiUrl._API_ADOPT_COMPANY_DOCUMENT.replace(":documentId", documentId));
    }

    async rejectCompanyDocument(documentId) {
        return this.post(ApiUrl._API_REJECT_COMPANY_DOCUMENT.replace(":documentId", documentId));
    }

    async getFinanceList(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_FINANCES_LIST.replace(":companyId", companyId));
    }

    async confirmPaymentReceiptOrInvoice(orderCartItemId) {
        return this.post(ApiUrl._API_CONFIRM_PAYMENT_RECEIPT_OR_INVOICE.replace(":orderCartItemId", orderCartItemId));
    }

    async postCompanyFinanceInvoiceDocument(companyId, postData) {
        let { invoiceAttachment, receiptAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (invoiceAttachment) {
            formBody.append("invoiceAttachment", invoiceAttachment[0]);
            formBody.append('invoiceProofAttachment', invoiceAttachment[0]);
        }
        if (receiptAttachment) {
            formBody.append("receiptAttachment", receiptAttachment[0]);
            formBody.append("receiptProofAttachment", receiptAttachment[0]);
        }
        return this.postForm(ApiUrl._API_POST_FINANCE_INVOICE_DOCUMENT.replace(":companyId", companyId), formBody);
    }

    async getBeneficiaryOfficers(companyId) {
        return this.get(ApiUrl._API_GET_PRODUCTION_BENEFICIARY_OFFICERS.replace(":companyId", companyId));
    }

    //Document
    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    async uploadNewCompanyDocument(postData) {
        let { docAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (docAttachment) {
            formBody.append("docAttachment", docAttachment[0]);
            formBody.append("docProofAttachment", docAttachment[0]);
            formBody.append("effectiveDate", moment(docAttachment?.effectiveDate).format(DateFormat._DATE_FORMAT));
        }
        return this.postForm(ApiUrl._API_UPLOAD_NEW_COMPANY_DOCUMENT, formBody);
    }

    async createNewDocumentOrType(postData) {
        return this.post(ApiUrl._API_CREATE_NEW_DOCUMENT_OR_TYPE, postData);
    }

    async getTaskRequiredDocuments(id) {
        return this.get(ApiUrl._API_GET_TASK_REQUIRED_DOCUMENTS.replace(':id', id));
    }

    async getTemplateFieldNames(documentId) {
        return this.get(ApiUrl._API_GET_TEMPLATE_FIELD_NAMES.replace(':documentId', documentId));
    }

    async getCompanyVisibleFieldNames() {
        return this.get(ApiUrl._API_GET_COMPANY_VISIBLE_FIELD_NAMES);
    }

    async generateCompanyDocument(companyId, documentId) {
        return this.post(ApiUrl._API_GENERATE_COMPANY_DOCUMENT.replace(':companyId', companyId).replace(':documentId', documentId));
    }

    async uploadSignedCompanyDocument(companyId, companyDocumentId, postData) {
        let { signedDocumentAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (signedDocumentAttachment) {
            formBody.append("signedDocumentAttachment", signedDocumentAttachment[0]);
        }
        return this.postForm(ApiUrl._API_UPLOAD_SIGNED_COMPANY_DOCUMENT.replace(':id', companyId).replace(':companyDocumentId', companyDocumentId), formBody);
    }

    async requestSignature(companyId, serviceRequestDocumentId, postData) {
        let { requestSignatureAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (requestSignatureAttachment) {
            formBody.append("requestSignatureAttachment", requestSignatureAttachment[0]);
        }
        return this.postForm(ApiUrl._API_REQUEST_SIGNATURE_SERVICE_REQUEST_DOCUMENT.replace(':companyId', companyId).replace(':serviceRequestDocumentId', serviceRequestDocumentId), formBody);
    }

    async getServiceRequestDocumentList(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_SERVICE_REQUEST_DOCUMENT.replace(':id', companyId));
    }

    //<summary>
    //Author: Robin
    //</summary>
    async getUbSiblingRemainingShares(companyId, beneficiarySharesId, classOfShareId, currencyId) {
        let postData = {
            companyId, beneficiarySharesId, classOfShareId, currencyId
        }
        return this.post(ApiUrl._API_POST_UB_TOTAL_SHARE_REMAINING, postData);
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async performUbSharesCalc(companyId, beneficiarySharesId, beneficiarySharesEntries) {
        let postData = {
            companyId, beneficiarySharesId, beneficiarySharesEntries
        }
        return this.post(ApiUrl._APT_POST_CALC_UB_SHARES, postData);
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async performUbSharesCalcSameHierarchyLevel(companyId, beneficiarySharesId, beneficiarySharesEntries) {
        let postData = {
            companyId, beneficiarySharesId, beneficiarySharesEntries
        }
        return this.post(ApiUrl._APT_POST_CALC_SAME_HIERARCHY_UB_SHARES, postData);
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async getBeneficiaryInfo(companyId, beneficiaryId) {
        return this.get(ApiUrl._API_GET_UB_INFO
            .replace(":companyId", companyId).replace(":beneficiaryId", beneficiaryId)
        );
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async ubUpdateBeneficiaryShares(companyId, beneficiaryId, companyBeneficiaryShares) {
        return this.post(ApiUrl._API_UPDATE_UB_SHARES.replace(":companyId", companyId)
            .replace(":beneficiaryId", beneficiaryId), companyBeneficiaryShares);
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async getCompanyOfficerListByRole(companyId, officerRoleId) {
        let url = `${ApiUrl._API_GET_COMPANY_OFFICER_LIST_BY_ROLE}?${new URLSearchParams({ officerRoleId })}`.replace(":companyId", companyId);
        return this.get(url);
    }

    //<summary>
    //Author: Robin
    //</summary>
    async ubUpdateBeneficiaryDirectors(companyId, beneficiaryId, companyBeneficiaryDirectors) {
        return this.post(ApiUrl._API_UPDATE_UB_DIRECTORS.replace(":companyId", companyId)
            .replace(":beneficiaryId", beneficiaryId), companyBeneficiaryDirectors);
    }

    /// <summary>
    /// Author: Robin
    /// </summary>
    async retrieveCompanyCompliances(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_ANNUAL_COMPLIANCES.replace(':companyId', companyId));
    }

    async getCompanyAnnualComplianceDocumentList(companyId) {
        return this.get(ApiUrl._API_GET_COMPANY_ANNUAL_COMPLIANCE_DOCUMENTS.replace(':companyId', companyId));
    }

    async requestAnnualComplianceSignature(companyId, annualComplianceDocumentId, postData) {
        let { requestSignatureAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (requestSignatureAttachment) {
            formBody.append("requestSignatureAttachment", requestSignatureAttachment[0]);
        }
        return this.postForm(ApiUrl._API_REQUEST_SIGNATURE_COMPANY_ANNUAL_COMPLIANCE_DOCUMENT.replace(':companyId', companyId).replace(':annualComplianceDocumentId', annualComplianceDocumentId), formBody);
    }

    async markServiceRequestAsComplete(companyId, serviceRequestId) {
        return this.post(ApiUrl._API_MARK_SERVICE_REQUEST_COMPLETE.replace(':companyId', companyId).replace(':serviceRequestId', serviceRequestId));
    }

    async getCompanyProcessStates() {
        return this.get(ApiUrl._API_GET_COMPANY_PROCESS_STATE_LIST);
    }

    async getUbChildrenNodeList(companyId, beneficiaryId) {
        return this.get(ApiUrl._API_GET_UB_CHILDREN_NODES
            .replace(":companyId", companyId)
            .replace(":beneficiaryId", beneficiaryId)
        )
    }

    async getUbDescendantNodeList(companyId, beneficiaryId) {
        return this.get(ApiUrl._API_GET_UB_DESCENDANT_NODES
            .replace(":companyId", companyId)
            .replace(":beneficiaryId", beneficiaryId)
        )
    }

    async updateUbBeneficiaryNode(companyId, postData) {
        return this.post(ApiUrl._API_UPDATE_UB_NODE
            .replace(":companyId", companyId),
            postData
        )
    }

    async getUbSiblingsNode(companyId, beneficiaryId) {
        return this.get(ApiUrl._API_GET_UB_SIBLING_NODES
            .replace(":companyId", companyId)
            .replace(":beneficiaryId", beneficiaryId)
        )
    }

    async manualSendRemindCompliance(companyId, annualComplianceId) {
        return this.post(ApiUrl._API_MANUAL_SEND_REMIND_COMPLIANCE.replace(":companyId", companyId).replace(":annualComplianceId", annualComplianceId));
    }

    async manualSendRemindPackage(companyId, productMatterId) {
        return this.post(ApiUrl._API_MANUAL_SEND_REMIND_PACKAGE.replace(":companyId", companyId).replace(":productMatterId", productMatterId));
    }

    async uploadCompanyLetter(companyId, postData) {
        const { letterAttachment, ...restPostData } = postData;
        var formBody = new createMultiPartFormBody(restPostData);
        formBody.append('LetterAttachment', letterAttachment);
        return this.postForm(ApiUrl._API_COMPANY_ANNUAL_COMPLIANCE_LETTERS.replace(":companyId", companyId), formBody);
    }
}

export default CompanyDao;
