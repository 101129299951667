import React, { useState, useEffect, useMemo } from 'react';
import { Route, Link, useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { WebUrl, ApiKey, BtnTypes, Language, ApiUrl } from 'util/Constant';
import FactSheetDao from '../../data/FactSheetDao';
import { useTranslation } from 'react-i18next';
import SubmitNameCheckForm from './forms/SubmitNameCheckForm';
import TransferInCompanyForm from './forms/TransferInCompanyForm';
import BrandModal from '../../components/modals/BrandModal';
import ButtonRound from 'components/buttons/ButtonRound';
import NavigationButton from 'components/buttons/NavigationButton';
import InputHoc from "components/form/InputHoc";
import useSWR from 'swr';

const AddNewCompany = () => {

    const { t, i18n } = useTranslation();
    const [selectedFactSheet, setSelectedFactSheet] = useState({});
    const [showNameCheckModal, setShowNameCheckModal] = useState(false);
    const [showTransferInModal, setShowTransferInModal] = useState(false);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const { data: factSheetJson } = useSWR([
        ApiUrl._API_GET_FACT_SHEET_LIST,
        ApiKey._API_GET,
        null,
        "div#addNewCompany"
    ]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const factSheetList = useMemo(() => {

        return factSheetJson?.[ApiKey._API_SUCCESS_KEY] ?
            factSheetJson[ApiKey._API_DATA_KEY] : [];

    }, [factSheetJson]);

    /// <summary>
    /// Author: Robin
    /// </summary>
    const toggleNameCheckModal = () => {
        setShowNameCheckModal(!showNameCheckModal);
    };

    const cancelNameCheckModalHandler = () => {
        toggleNameCheckModal();
    };

    const toggleTransferInModal = () => {
        setShowTransferInModal(!showTransferInModal);
    };

    const cancelTransferInModalHandler = () => {
        toggleTransferInModal();
    };

    /// usage : set input size dynamically with placeholder string size
    useEffect(() => {
        const hinterNote = document.querySelectorAll('#hinter-add-new-company');
        for (var i = 0; i < hinterNote.length; i++) {

            let size = i18n.language === Language._ENGLISH_GB ? hinterNote[i].getAttribute('placeholder').length
                : hinterNote[i].getAttribute('placeholder').length * 2;

            hinterNote[i].setAttribute('size', size);
        }
    }, [i18n.language]);

    return (
        <>
            <div className="panel panel-brand panel-flex" id="addNewCompany">
                <div className="panel-header p-b-0 d-flex justify-content-between border-0" style={{ boxShadow: 'none' }}>
                    <NavigationButton />
                </div>
                <div className="panel-body panel-flex-scrollable y-scrollbar-2 h-100">
                    <div className="d-flex justify-center flex-column align-items-center mb-5">
                        <div className="page-header title title-with-dot">{t("ADD_NEW_COMPANY")}</div>
                        <Row className="w-100" style={{ padding: '0 6.25rem' }}>
                            <Col xl={3}></Col>
                            <Col xl={6}>
                                <InputHoc
                                    formGroupClass="hinter-grp-cls"
                                    id="hinter-add-new-company"
                                    disabled={true}
                                    prefix={<img src={require("../../assets/img/icon/bulb.svg")} />}
                                    placeholder={t("HOVER_VIEW_FACT_SHEET_DETAILS")}
                                />
                            </Col>
                            <Col xl={3}></Col>
                        </Row>
                    </div>
                    <Row>
                        {
                            factSheetList.map((factSheet, index) => {
                                return (
                                    <JurisdictionFactSheet key={index} factSheet={factSheet} setSelectedFactSheet={setSelectedFactSheet} toggleNameCheckModal={toggleNameCheckModal} />
                                );
                            })
                        }
                    </Row >

                    <BrandModal
                        customBody={true}
                        isOpen={selectedFactSheet && showNameCheckModal}
                        toggler={toggleNameCheckModal}
                        title={<>{t('incorporation:INCORPORATION')} {selectedFactSheet.title}</>}>
                        <SubmitNameCheckForm cancelHandler={cancelNameCheckModalHandler} selectedFactSheet={selectedFactSheet}></SubmitNameCheckForm>
                    </BrandModal>

                </div>
            </div>
            <Modal isOpen={selectedFactSheet && showTransferInModal} size="md" centered={true}>
                <ModalHeader toggle={cancelTransferInModalHandler} style={{ textAlign: 'center' }}>{t('incorporation:TRANSFER_IN')} {selectedFactSheet?.title}</ModalHeader>
                {
                    showTransferInModal == true ?
                        <TransferInCompanyForm cancelHandler={cancelTransferInModalHandler} selectedFactSheet={selectedFactSheet}></TransferInCompanyForm>
                        :
                        <></>
                }
            </Modal>
        </>
    );
};

const JurisdictionFactSheet = (props) => {
    const { factSheet, setSelectedFactSheet, toggleNameCheckModal } = props;
    const _CARD_STYLE = {
        display: "flex"
    }

    const _CARD_BODY_STYLE = {
        padding: 0,
        backgroundColor: "white"
    }

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const formatClass = (jurisdiction) => {
        return jurisdiction.replaceAll(" ", "-").toLowerCase();
    }

    return (
        <Col sm={12} md={6} xl={4} className="mb-3" style={_CARD_STYLE}>
            <Card className={`factsheet-card ${formatClass(factSheet.title)}`}>
                <CardHeader className="panel-brand" style={{ borderColor: '#fff' }}>
                    <Row>
                        <Col className="d-flex justify-content-between">
                            <div className="d-flex">
                                <div className={`countries country-flag-${factSheet.jurisdiction.shortName.toLowerCase()}`} style={{ borderRadius: '0.5rem', height: '30px', width: '30px' }}></div>
                                {/* {<img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(factSheet.title)} />} */}
                                <span className="m-auto">{factSheet.title}</span>
                            </div>
                            <ButtonRound title="Incorporate Now" type={BtnTypes.RIGHT} className="align-self-center btn-themed" medium onClick={() => { setSelectedFactSheet(factSheet); toggleNameCheckModal(); }} />
                        </Col>
                    </Row>
                </CardHeader>
                {
                    <CardBody className="card-body-facthsheet panel-brand" style={_CARD_BODY_STYLE}>
                        {
                            factSheet.itemGroups.length !== 0
                                ?
                                <div className="factsheet-details">
                                    <table className={`table table-factsheet `}>
                                        <tbody>
                                            {
                                                factSheet.itemGroups.map((groups, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th>{groups.name}</th>
                                                            <td>
                                                                <ul className="ul-factsheet">
                                                                    {
                                                                        groups.items.map((items, index) => {
                                                                            return (

                                                                                <li key={index}>{items.text ? items.text : "-"}</li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div style={{ textAlign: 'center' }}>No data available</div>
                        }
                    </CardBody>
                    // <CardFooter>
                    //     <div className="factsheet-incorporation-button">
                    //         <button
                    //             type="button"
                    //             className="btn btn-lg btn-themed"
                    //             onClick={() => {
                    //                 setSelectedFactSheet(factSheet);
                    //                 toggleNameCheckModal();
                    //             }}
                    //         >
                    //             Incorporate Now</button>
                    //     </div>
                    // </CardFooter>
                }
            </Card>
        </Col>
    )
}

export default AddNewCompany;