import React, { useState, useMemo, useEffect } from 'react';
import { CardBody, Col, Row, CardHeader, Collapse } from 'reactstrap';
import { CompanyDao, OfficerDao } from 'data';
import classnames from 'classnames';
import { ApiKey, InputTypes, OfficerRoleId, BtnTypes, OfficerTypeID, CreateOfficerStepFormRefKey, LoadingStateText, SweetAlert } from 'util/Constant';
import { sum } from 'lodash';
import { Report, Notify, Confirm, Block } from 'notiflix';
import { OfficerPersonalDetail } from 'pages/individual-management';
import { useFieldArray, useForm } from "react-hook-form";
import InputHoc from 'components/form/InputHoc';
import { stringIsNullOrEmpty } from 'util/Utility';
import { useRecoilState, useRecoilValue } from 'recoil';
import { companyOfficersState } from 'recoil/Incorporation';
import ButtonRound from 'components/buttons/ButtonRound';
import { useTranslation } from 'react-i18next';

///<summary>
///Author: Lewis
///</summary>
const DisplayPersonDetailsForm = React.forwardRef((props, ref) => {
    let {
        index,
        isEdit,
        parentKey,
        companyId,
        jumpToStep,
        modalToggler,
        beneficiaryId,
        officerTypeId,
        fetchOfficerId,
        refreshCompanyOfficers,
        retrieveBeneficiaryTree,
        onAddBeneficiaryOfficer,
    } = props;

    const { t } = useTranslation();
    let companyDao = new CompanyDao();
    let officerDao = new OfficerDao();
    const [indexOpen, setIndexOpen] = useState(0);
    const [isBindToCompany, setIsBindToCompany] = useState(false);
    const [officerDetails, setOfficerDetails] = useState({});

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const _ACCORDIONS = useMemo(() => {
        const initState = [
            { collapsed: true, status: false, name: t('P_HASH_PROFILE_INFOMATION'), component: PersonDetailsAccordion },
            {
                collapsed: true, status: false,
                name: parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS ?
                    t('ROLE_ASSIGNMENT') : t('SHARES_AND_DIRECTOR_ASSIGNMENT'),
                component: parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS ?
                    RoleAssignmentAccordion : DirectorAndSharesAssignment
            },
        ]

        if (indexOpen != null) {

            initState.map((o, index) => {
                if (index == indexOpen) {
                    o.collapsed = false;
                }
                else {
                    o.collapsed = true;
                }
            })

        }
        return initState;

    }, [indexOpen, parentKey, t]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const bindOfficerToCompany = async () => {
        Block.Circle("div.modal-content", LoadingStateText._PLEASE_WAIT);
        await companyDao.bindOfficerToCompany(companyId, { id: fetchOfficerId }).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(json[ApiKey._API_MESSAGE_KEY])
                setIsBindToCompany(true);
                setIndexOpen(1);
                // refreshCompanyOfficers(companyId);
                refreshCompanyOfficers();
            }
            else {
                Report.Warning(
                    json[ApiKey._API_MESSAGE_KEY],
                    json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove("div.modal-content"));
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchOfficerById = async (officerId) => {
        await officerDao.getOfficerById(officerId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setOfficerDetails(json[ApiKey._API_DATA_KEY]);
            }
            else {
                Report.Warning(
                    json[ApiKey._API_MESSAGE_KEY],
                    json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        })
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const checkIsOfficerBindedToCompany = async (officerId, companyId) => {
        await companyDao.checkIsOfficerBindedToCompany(officerId, companyId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setIsBindToCompany(json[ApiKey._API_DATA_KEY]);
            }
            else {
                Report.Warning(
                    json[ApiKey._API_MESSAGE_KEY],
                    json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        })
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(fetchOfficerId) && !stringIsNullOrEmpty(companyId)) {
            fetchOfficerById(fetchOfficerId);

            if (parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS) {
                checkIsOfficerBindedToCompany(fetchOfficerId, companyId);
            }

        }

    }, [fetchOfficerId, companyId, ref]);

    return (
        <div id="companyBeneficiaryAssignmentAccordion" className="accordion">
            {
                _ACCORDIONS.map((o, index) => {
                    let _style = index == 0 ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } :
                        { borderBottomLeftRadius: "2rem", borderBottomRightRadius: "2rem" };

                    return <>
                        <div className="card" key={index} style={_style}>
                            <CardHeader
                                className={
                                    classnames('card-header pointer-cursor',
                                        { "collapsed": o.collapsed },
                                        { "p-0": (index == 0 && !isBindToCompany && parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS) }
                                    )
                                }
                                onClick={() => {
                                    // force do not open role assignment accordion if it is havent assign officer into company
                                    if (parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS) {
                                        if (!isBindToCompany) {
                                            Notify.Warning(t("notiflix:PLEASE_SELECT_BEFORE_ROLE_ASSIGNMENT"));
                                            return;
                                        }
                                    }

                                    setIndexOpen(index);
                                }}>
                                {
                                    (index == 0 && !isBindToCompany && parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS) ?
                                        <Row className="incorporate-exist-officer-head p-10 m-0">
                                            <Col xl={7} className="d-flex">
                                                <p className="text-theme-1 m-t-auto m-b-auto f-s-14">
                                                    {t("DO_YOU_WANT_TO_SELECT")} "{officerDetails.englishName}" {t("AS_THE_NEW")} {officerTypeId == OfficerTypeID._CORPORATE ? t("CORPORATE") : t("PERSON")}?
                                                </p>
                                            </Col>
                                            <Col xl={5} className="d-flex justify-content-end">
                                                <button className="btn btn-themed btn-mid-long grayscale-100" onClick={(e) => {

                                                    e.stopPropagation();

                                                    if (parentKey === CreateOfficerStepFormRefKey._COMPANY_OFFICERS) {
                                                        Confirm.Show(
                                                            t("notiflix:ARE_YOU_SURE_TO_LEAVE") + "?",
                                                            t("notiflix:OFFICER_NOT_SELECTED") + "!",
                                                            t("notiflix:YES"),
                                                             t("notiflix:CANCEL"),
                                                            () => {
                                                                jumpToStep(0);
                                                            });
                                                        return;
                                                    }

                                                }}>{t("BACK")}</button>
                                                <button
                                                    className="btn btn-themed btn-mid-long m-l-5"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        bindOfficerToCompany()
                                                    }}
                                                >{t("SELECT")}</button>
                                            </Col>
                                        </Row> :
                                        <>{o.name}</>
                                }
                            </CardHeader>
                            <Collapse isOpen={!o.collapsed}>
                                <CardBody>
                                    {React.createElement(o.component, {
                                        fetchOfficerId, isEdit, modalToggler,
                                        refreshCompanyOfficers, jumpToStep,
                                        companyId, beneficiaryId, onAddBeneficiaryOfficer,
                                        retrieveBeneficiaryTree
                                    })}
                                </CardBody>
                            </Collapse>
                        </div>
                    </>
                })
            }
        </div>
    )
});

///<summary>
///Author: Lewis
///</summary>
const PersonDetailsAccordion = React.forwardRef((props, ref) => {
    let { companyId, fetchOfficerId } = props;

    return (
        <div className="personFormWrapper p-25" style={{ backgroundColor: "#fff" }}>
            <OfficerPersonalDetail fetchOfficerId={fetchOfficerId} companyId={companyId} />
        </div>
    )
});

///<summary>
///Author: Lewis
/// Usage : company beneficiary director binding
///</summary>
const DirectorAndSharesAssignment = React.forwardRef((props, ref) => {

    let {
        isEdit,
        companyId,
        jumpToStep,
        modalToggler,
        beneficiaryId,
        fetchOfficerId,
        retrieveBeneficiaryTree,
        onAddBeneficiaryOfficer
    } = props;

    const { t } = useTranslation();

    let companyDao = new CompanyDao();
    let officerDao = new OfficerDao();

    const _FIELDS_ARRAY_KEY = "companyBeneficiaryDirectors";
    const [remainingSharePar, setRemainingSharePar] = useState(100);
    const [shareCapitalList, setShareCapitalList] = useState([]);
    const [showDirectorAssignmentForm, setShowDirectorAssignmentForm] = useState(false);
    const [classOfShareOptions, setClassOfShareOptions] = useState([]);
    const { control, handleSubmit, errors, setValue, getValues, watch, reset } = useForm({ defaultValues: { [_FIELDS_ARRAY_KEY]: [{ name: "" }] } });
    const { fields, append, remove } = useFieldArray({ control: control, name: _FIELDS_ARRAY_KEY });
    const percentageInputWatcher = watch("sharePercentage");

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchBeneficiary = async (companyId, beneficiaryId) => {
        await companyDao.getBeneficiaryInfo(companyId, beneficiaryId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY];
                let { companyBeneficiaryShares, companyBeneficiaryDirectors, officer, officerId, companyId } = data;

                let valueToReset = {
                    officerId,
                    companyId,
                    englishName: officer.englishName,
                    chineseName: officer?.chineseName,
                    pNumber: officer?.pNumber,
                    [_FIELDS_ARRAY_KEY]: companyBeneficiaryDirectors
                };

                if (companyBeneficiaryShares.length > 0) {
                    let { id, classOfShareId, companyBeneficiaryId, sharePercentage, ...restShareProps } = companyBeneficiaryShares[0];
                    valueToReset = {
                        ...valueToReset,
                        classOfShareId, companyBeneficiaryId, sharePercentage
                    }
                }

                reset({ ...valueToReset });

            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchCompanyShareCapitals = async (companyId) => {

        await companyDao.getShareCapitalList(companyId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setShareCapitalList(json[ApiKey._API_DATA_KEY]);
            }
        });

    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchClassOfShare = async () => {
        await companyDao.getClassOfShareList().then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setClassOfShareOptions(json[ApiKey._API_DATA_KEY].map(({ name, id }) => {
                    return { label: name, value: id }
                }));
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchOfficerById = async (officerId) => {
        await officerDao.getOfficerById(officerId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {

                let { id, pNumber, englishName, ...restProps } = json[ApiKey._API_DATA_KEY];
                setValue("officerId", id);
                setValue("englishName", englishName);
                setValue("pNumber", pNumber);

                if (restProps?.chineseName) {
                    setValue("chineseName", restProps.chineseName);
                }

                if (restProps.officerTypeId == OfficerTypeID._CORPORATE) {
                    setShowDirectorAssignmentForm(true);
                }
            }
        })
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchSiblingNodes = async (companyId, beneficiaryId) => {
        await companyDao.getUbSiblingsNode(companyId, beneficiaryId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY];
                let totalPar = 0;
                if (data.length > 0) {
                    data.map(o => {
                        let { companyBeneficiaryShares, ...restProps } = o;
                        if (companyBeneficiaryShares.length > 0) {
                            companyBeneficiaryShares.map(j => { totalPar += j.sharePercentage });
                        }
                    })

                    setRemainingSharePar(100 - totalPar);
                }
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchChildrenNodes = async (companyId, beneficiaryId) => {
        await companyDao.getUbChildrenNodeList(companyId, beneficiaryId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY];
                let totalPar = 0;
                if (data.length > 0) {
                    data.map(o => {
                        let { companyBeneficiaryShares, ...restProps } = o;
                        if (companyBeneficiaryShares.length > 0) {
                            companyBeneficiaryShares.map(j => { totalPar += j.sharePercentage });
                        }
                    })

                    setRemainingSharePar(100 - totalPar);
                }
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const onSubmit = async (formValue) => {
        let {
            officerId,
            companyBeneficiaryDirectors,
            sharePercentage,
            companyId,
            currencyId,
            classOfShareId,
            ...restProps
        } = formValue;

        let postData = {
            id: beneficiaryId,
            companyId,
            officerId,
            companyBeneficiaryDirectors,
            companyBeneficiaryShares: [
                { sharePercentage, currencyId, classOfShareId }
            ],
        }

        Block.Circle("div.modal-content", LoadingStateText._PLEASE_WAIT);

        if (!isEdit) {
            /// create new beneficiary
            await companyDao.addBeneficiary(companyId, postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {

                    // on add beneficiary callback value to the parent components
                    if (typeof (onAddBeneficiaryOfficer) == "function") {
                        onAddBeneficiaryOfficer(json[ApiKey._API_DATA_KEY]);
                    }

                    if (typeof (retrieveBeneficiaryTree) == "function") {
                        retrieveBeneficiaryTree(companyId);
                    }

                    Notify.Success(json[ApiKey._API_MESSAGE_KEY]);
                    jumpToStep(5);
                }
                else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => Block.Remove("div.modal-content"));
        }
        else {
            /// edit beneficiary
            await companyDao.updateUbBeneficiaryNode(companyId, postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(json[ApiKey._API_MESSAGE_KEY]);

                    if (typeof (retrieveBeneficiaryTree) == "function") {
                        retrieveBeneficiaryTree(companyId);
                    }
                    modalToggler();
                }
                else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => Block.Remove("div.modal-content"));
        }

    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        fetchClassOfShare();
        if (!stringIsNullOrEmpty(companyId)) {
            fetchCompanyShareCapitals(companyId);
            setValue("companyId", companyId);

            if (!stringIsNullOrEmpty(beneficiaryId)) {

                if (isEdit) {
                    fetchBeneficiary(companyId, beneficiaryId);
                    fetchSiblingNodes(companyId, beneficiaryId);
                }
                else {
                    fetchChildrenNodes(companyId, beneficiaryId);
                }
            }
        }

    }, [companyId, beneficiaryId]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(fetchOfficerId)) {
            fetchOfficerById(fetchOfficerId);
        }

    }, [fetchOfficerId]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (shareCapitalList.length > 0) {
            setValue("currencyId", shareCapitalList[0].currencyId);
            setValue("classOfShareId", shareCapitalList[0].classOfShareId);
        }

    }, [shareCapitalList]);

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xl={12}>
                    <CardHeader className="p-l-0 p-t-0 m-b-5">
                        <span className="text-theme-1">{t("SHARES")}</span> {t("ASSIGNMENT")}
                    </CardHeader>
                </Col>
                <Col xl={6}>
                    <InputHoc label= {t("SHARE_PERCENTAGE") + " (%)"} name="sharePercentage" control={control} rules={{ required: "required" }} error={errors?.sharePercentage} postfix="%"
                        inputType={InputTypes.NUMBER_INPUT}
                        pattern="[0-9]"
                        smallnote={`${t("REMAINING_SHARE_ALLOCATED")}: ${remainingSharePar - (percentageInputWatcher ? percentageInputWatcher : 0)}%`}
                        onChange={(e) => {
                            let value = e;
                            if (value > remainingSharePar) {
                                value = remainingSharePar;
                            }
                            else if (value < 0) {
                                value *= -1;
                            }
                            setValue("sharePercentage", value);
                        }}
                    />
                    {/* hidden field */}
                    <InputHoc name="officerId" control={control} rules={{ required: "required" }} hidden />
                    <InputHoc name="englishName" control={control} rules={{ required: "required" }} hidden />
                    <InputHoc name="chineseName" control={control} rules={{ required: false }} hidden />
                    <InputHoc name="pNumber" control={control} rules={{ required: "required" }} hidden />
                    <InputHoc name="companyId" control={control} rules={{ required: "required" }} hidden />
                    <InputHoc name="currencyId" control={control} rules={{ required: "required" }} hidden />
                    <InputHoc name="classOfShareId" control={control} rules={{ required: "required" }} hidden />
                    {/* {isEdit && <InputHoc name="companyBeneficiaryId" control={control} rules={{ required: "required" }} hidden />} */}
                </Col>
                <Col xl={6}></Col>
                {
                    showDirectorAssignmentForm &&
                    <>
                        <Col xl={12}>
                            <CardHeader className="p-l-0 p-t-0 m-b-5">
                                <span className="text-theme-1">{t("DIRECTOR")}</span> {t("ASSIGNMENT")}
                            </CardHeader>
                        </Col>
                        <Col xl={12} className="row">
                            {
                                fields.map((fieldItem, index) => {
                                    return <React.Fragment key={index}>
                                        <Col xl={6}>
                                            <InputHoc defaultValue={fieldItem.name} label="Director Name" name={`${_FIELDS_ARRAY_KEY}.[${index}].directorName`} control={control} rules={{ required: true }}
                                                error={errors?.[_FIELDS_ARRAY_KEY]?.[index]?.directorName} />
                                        </Col>
                                        <Col xl={6} className="d-flex btn-list-wrapper">
                                            {index + 1 == fields.length && <ButtonRound className="m-t-auto m-b-auto" medium type={BtnTypes.ADD} onClick={() => append({ name: "" })} />}
                                            {fields.length > 1 && <ButtonRound className="m-t-auto m-b-auto" medium type={BtnTypes.DELETE} onClick={() => remove(index)} />}
                                        </Col>
                                    </React.Fragment>
                                })
                            }
                        </Col>
                    </>
                }
            </Row>
        </form>
        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-themed btn-mid-long" onClick={() => {
                handleSubmit(onSubmit)();
            }}>{isEdit ? t("UPDATE") : t("SAVE")}</button>
        </div>
    </>
});

///<summary>
///Author: Lewis
///</summary>
const RoleAssignmentAccordion = React.forwardRef((props, ref) => {

    let {
        companyId,
        fetchOfficerId: officerId,
        isEdit,
        modalToggler,
        refreshCompanyOfficers
    } = props;

    let companyDao = new CompanyDao();
    const { t } = useTranslation();
    const companyOfficerList = useRecoilValue(companyOfficersState);
    const _FIELDS_ARRAY_KEY = "shares";
    const [shareCapitalList, setShareCapitalList] = useState([]);
    const [classOfShareOptions, setClassOfShareOptions] = useState([]);
    const { register, errors, watch, reset, control, handleSubmit, getValues, setValue } = useForm({});
    const { append, fields } = useFieldArray({ name: _FIELDS_ARRAY_KEY, control: control });
    const [tempShareAmt, setTempShareAmt] = useState(0);
    const _ROLE_SELECTOR_VALUE = watch("roleSelection");

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const onSubmit = async (formValue) => {
        formValue.roleSelection = formValue.roleSelection.map(x => parseInt(x));

        let postData = {
            isDirector: formValue.roleSelection.includes(OfficerRoleId._DIRECTOR),
            isShareholder: formValue.roleSelection.includes(OfficerRoleId._SHAREHOLDER),
            shares: formValue.shares,
        }

        await companyDao.assignCompanyOfficerRole(companyId, officerId, postData).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {

                Notify.Success(json[ApiKey._API_MESSAGE_KEY]);

                // refreshCompanyOfficers(companyId);
                refreshCompanyOfficers();
                // retrieveBeneficiaryTree();
                modalToggler();
            }
            else {
                Report.Warning(
                    json[ApiKey._API_MESSAGE_KEY],
                    json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }

        });

    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchCompanyShareCapitals = async (companyId) => {

        await companyDao.getShareCapitalList(companyId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setShareCapitalList(json[ApiKey._API_DATA_KEY]);
            }
        });

    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchClassOfShare = async () => {
        await companyDao.getClassOfShareList().then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setClassOfShareOptions(json[ApiKey._API_DATA_KEY].map(({ name, id }) => {
                    return { label: t(`${name.toUpperCase()}`), value: id }
                }));
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchCompanyOfficerById = async (companyId, officerId) => {
        await companyDao.getCompanyOfficerById(companyId, officerId).then(json => {

            if (json[ApiKey._API_SUCCESS_KEY]) {

                let { roleAssignments, shares } = json[ApiKey._API_DATA_KEY];

                if (roleAssignments.length > 0) {
                    let roleArr = roleAssignments.map(x => x.id.toString());
                    let shareListToSet = shares ? shares.map(({ classOfShareId, shareAllocated, sharePercentage }) => {
                        setTempShareAmt(shareAllocated);
                        return ({ classOfShareId, shareAllocated, sharePercentage: parseFloat(sharePercentage).toFixed(2) })
                    }

                    ) : [];

                    reset({
                        'roleSelection': roleArr,
                        [_FIELDS_ARRAY_KEY]: shareListToSet
                    });
                }
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(companyId)) {
            fetchCompanyShareCapitals(companyId);

            if (isEdit && !stringIsNullOrEmpty(officerId)) {
                fetchCompanyOfficerById(companyId, officerId);
            }
        }

    }, [companyId, officerId]);

    useEffect(() => {
        fetchClassOfShare();
    }, []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (fields.length == 0 /*&& !isEdit*/) {
            append({
                classOfShareId: 1, shareAllocated: 0, sharePercentage: 0
            })
        }

    }, [fields.length]);

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                /* 
                The options value only support string type, not sure what problem in the inputtype checkbox wrapper 
                as i try to reset value with int type and options with int type it does not populate the value
                Commented @ 21/5/2021 by Lewis
                */
            }
            <InputHoc
                inputType={InputTypes.CHECKBOX}
                name="roleSelection"
                options={[
                    { label: t("DIRECTOR"), value: OfficerRoleId._DIRECTOR.toString() },
                    { label: t("SHAREHOLDER"), value: OfficerRoleId._SHAREHOLDER.toString() }
                ]}
                ref={register({ required: true })}
                formGroupClass="justify-start"
                formGroup={false}
                error={errors?.roleSelection}
                inline
            />
            {
                (typeof (_ROLE_SELECTOR_VALUE) == "object" && _ROLE_SELECTOR_VALUE.map(x => parseInt(x)).includes(OfficerRoleId._SHAREHOLDER)) &&
                <Row>
                    <Col xl={12}>
                        <CardHeader className="p-0 p-b-10 m-b-15"><span className="text-theme-1">{t("SHARES")}</span> {t("ALLOTMENT")}</CardHeader>
                    </Col>
                    {
                        fields.map((f, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Col xl={3}>
                                        <InputHoc label="TYPE_OF_SHARES"
                                            name={`${_FIELDS_ARRAY_KEY}[${i}][classOfShareId]`}
                                            options={classOfShareOptions}
                                            inputType={InputTypes.SELECT}
                                            control={control}
                                            ref={register}
                                            disabled
                                            defaultValue={f.classOfShareId} />
                                        {/* hidden */}
                                        <InputHoc
                                            name={`${_FIELDS_ARRAY_KEY}[${i}][companyShareCapitalId]`}
                                            control={control}
                                            defaultValue={shareCapitalList[0]?.id}
                                            hidden={true}
                                        />
                                        <InputHoc
                                            name={`${_FIELDS_ARRAY_KEY}[${i}][currencyId]`}
                                            control={control}
                                            defaultValue={shareCapitalList[0]?.currencyId}
                                            hidden={true}
                                        />
                                    </Col>
                                    <Col xl={3}>
                                        <InputHoc label="SHARES_HELD"
                                            name={`${_FIELDS_ARRAY_KEY}[${i}][shareAllocated]`}
                                            error={errors?.[_FIELDS_ARRAY_KEY]?.[i]?.shareAllocated}
                                            rules={{ required: true, validate: (v) => v !== 0 }}
                                            control={control}
                                            defaultValue={f.sharesHeld}
                                            inputType={InputTypes.NUMBER_INPUT}
                                            onChange={(value) => {
                                                let totalSharesAmount = 0;

                                                if (isEdit) {
                                                    totalSharesAmount -= tempShareAmt;
                                                }

                                                if (companyOfficerList?.length > 0) {
                                                    companyOfficerList.map(({ shares, roleAssignments, isShareholder, ...restOfficerProps }) => {

                                                        if (isShareholder) {
                                                            totalSharesAmount += shares[0].shareAllocated;
                                                        }

                                                    });
                                                }
                                                let percentage = ((value / (totalSharesAmount + value)) * 100).toFixed(2);
                                                if (isNaN(percentage)) percentage = 0;
                                                let tempValues = { ...getValues() };
                                                tempValues[_FIELDS_ARRAY_KEY][i].sharePercentage = percentage
                                                reset({ ...tempValues });
                                            }} />
                                    </Col>
                                    <Col xl={4}>
                                        <InputHoc
                                            label="PERCENTAGE_OF_OWNERSHIP"
                                            control={control}
                                            name={`${_FIELDS_ARRAY_KEY}[${i}][sharePercentage]`}
                                            value={f.sharePercentage}
                                            ref={register}
                                            disabled
                                            postfix="%"
                                        />
                                    </Col>
                                </React.Fragment>
                            )
                        })
                    }
                </Row>
            }
            <hr style={{ marginLeft: -30, marginRight: -30, background: "#f2f2f2" }} />
            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-themed btn-mid-long" >{isEdit ? t("UPDATE") : t("SAVE")}</button>
            </div>
        </form>
    </>
});

export default DisplayPersonDetailsForm;