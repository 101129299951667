
import 'react-chatbox-component/dist/style.css';

import React, { useEffect, useState, useRef, useMemo } from 'react';
import Avatar from 'react-avatar';
import { Row, Col } from 'reactstrap';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Notiflix, { Notify, Info } from "notiflix";
import TabPanel from '../../components/panel/TabPanel';
import { Tab, Tabs } from '@material-ui/core';
import classnames from 'classnames';
import { ChatBox } from 'react-chatbox-component';
import DataAccessObject, { _API_URL } from 'data/DataAccessObject.js';
import { ApiKey, ApiUrl, DateFormat, HubMethodKeys, Icon, InputTypes } from 'util/Constant';
import { stringIsNullOrEmpty } from 'util/Utility';
import { userIdSelector, usernameSelector } from 'recoil/Atoms';
import { ChatDao } from 'data';
import { useTranslation } from 'react-i18next';
import InputHoc from 'components/form/InputHoc';
import { useForm } from 'react-hook-form';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    Avatar as ChatAvatar,
    ConversationHeader,
    MessageList,
    Message,
    MessageInput,
    MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { groupBy, isEmpty } from 'lodash';

const AdminChatView = (props) => {
    const userId = useRecoilValue(userIdSelector);
    const username = useRecoilValue(usernameSelector);
    const user = {
        "uid": userId
    }
    const { t } = useTranslation();
    const [chatHubConnection, setChatHubConnection] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [chatRoomId, setChatRoomId] = useState("");
    const [chatRequests, setChatRequests] = useState([]);
    const [messages, setMessages] = useState([]);
    const messagesDate = useMemo(() => {
        let tempResult = [];
        tempResult = groupBy(messages, (item) => moment.utc(item.messageDate).calendar({
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: DateFormat._DATE_ONLY
        }));
        return tempResult;
    }, [messages]);

    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        CHAT_LIST: { id: 0, title: t("CHAT_LIST") },
    };

    const [owner, setOwner] = useState();
    const msgListRef = useRef();

    const { control, handleSubmit, reset } = useForm();

    const retrievePendingChatList = async (data = null) => {
        let dao = new ChatDao();
        await dao.retrievePendingRequestList(data).then((json) => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY] ?? [];
                setChatRequests((prevState) => ([
                    // ...prevState,
                    ...data
                ]));
            }
        }).finally(() => {

        })
    }

    const retrieveMessages = async (chatRoomId) => {
        let dao = new ChatDao();
        await dao.retrieveMessages(chatRoomId).then((json) => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY] ?? [];
                setMessages(data);

                setChatRequests((prevState) => ([
                    ...prevState.map((item) => {
                        if (item.id == chatRoomId) {
                            item.hasUnreadMessage = false;
                            item.unreadCount = 0;
                        }

                        return item;
                    })
                ]))
            }
        }).finally(() => {

        })
    }

    const addChat = (message) => {
        setMessages((prevState) => ([
            ...prevState,
            message
        ]))
    }

    const addNewChatRequests = (chatRoom) => {
        setChatRequests((prevState) => ([
            ...prevState.map((item) => item.id == chatRoom.id ? chatRoom : item)
        ]))
    }

    const updateChatLobbyLatestMessage = async (chatRoomId, message) => {
        setChatRequests((prevState) => ([
            ...prevState.map((item) => {
                if (item.id == chatRoomId) {
                    // Update here
                    let isSameUser = message.senderId === user.uid;
                    item.hasUnreadMessage = isSameUser ? false : true;
                    if (isSameUser) {
                        item.unreadCount = 0;
                    }
                    else {
                        item.unreadCount ? item.unreadCount += 1 : item.unreadCount = 1;
                    }
                    item.lastMessage = message;
                }

                return item;
            })
        ]))
    }

    //const removeChatRequests = (chatRoomId) => {
    //    setChatRequests((prevState) => chatRequests.filter((currentRequest) => currentRequest.id != chatRoomId));
    //}

    const initConnection = () => {
        const connect = new HubConnectionBuilder()
            .withUrl(`${_API_URL}${ApiUrl._API_CHAT}`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Warning)
            .build();

        connect.on(HubMethodKeys._BROADCAST_MESSAGE, (message) => {
            addChat(message);
        });

        connect.on(HubMethodKeys._CHAT_SESSION_INITIALIZED, (id, participant) => {
            if (id) {
                setChatRoomId(id);
            }
        });

        //connect.on(HubMethodKeys._CHAT_SESSION_ENDED, (id, participant) => {

        //});

        connect.on(HubMethodKeys._NEW_CHAT_REQUEST, (chatRoom) => {
            if (chatRoom) {
                addNewChatRequests(chatRoom);
                Notiflix.Notify.Info(`You have new chat request.`);
            }
        });

        connect.on(HubMethodKeys._CHAT_LOBBY_NEW_MESSAGE, (chatRoomId, message) => {
            updateChatLobbyLatestMessage(chatRoomId, message);
        });

        setChatHubConnection(connect);
    }

    const endConnection = () => {
        if (chatHubConnection != null) {
            chatHubConnection.stop();
            setChatHubConnection(null);
        }
    }

    const joinChatSession = async (request) => {
        try {
            await chatHubConnection.invoke("JoinChatSession", request);
        } catch (err) {
            console.error(err);
        }
    }

    //const endChatSession = async () => {
    //    try {
    //        await chatHubConnection.invoke("EndChatSession", chatRoomId);
    //    } catch (err) {
    //        console.error(err);
    //    }
    //}

    const sendChat = async (message) => {
        try {
            let participant = {
                id: userId,
                name: username
            }
            await chatHubConnection.invoke("SendMessage", chatRoomId, message, participant);
        } catch (err) {
            console.error(err);
        }
        finally {
            msgListRef.current.scrollToBottom();
        }
    }

    useEffect(() => {
        if (userId) {
            initConnection();
        }
        return (() => {
            endConnection();
        })
    }, [userId]);

    useEffect(() => {
        if (chatRoomId) {
            joinChatSession(chatRoomId);
            retrieveMessages(chatRoomId);
            msgListRef.current.scrollToBottom();
        }
    }, [chatRoomId]);

    useEffect(() => {
        if (currentTab === _TABS.CHAT_LIST.id) {
            retrievePendingChatList();
        }
    }, [currentTab])

    /// <summary>
    /// Author: Robin
    /// </summary>
    useEffect(() => {
        if (chatHubConnection) {
            chatHubConnection
                .start()
                .catch((error) => console.log(error));
        }
    }, [chatHubConnection]);

    return <>

        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("LIVE_SUPPORT")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 chat-request">
                <div className="panel panel-brand chat-request-panel">
                    <div className="panel-body y-scrollbar-2">
                        <Row>
                            <Col xl={3} className="d-flex flex-column">
                                <div className="chat-request-list-header">
                                    <form onSubmit={handleSubmit(retrievePendingChatList)} autoComplete='off'>
                                        <InputHoc prefix={{ icon: Icon._SEARCH }} placeholder={t("SEARCH")} name="search" inputType={InputTypes.INPUT} control={control}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && !e.shiftKey) {
                                                    handleSubmit(retrievePendingChatList);
                                                }
                                            }}
                                        />
                                    </form>
                                </div>
                                <div className="chat-request-list y-scrollbar-2">
                                    {
                                        chatRequests &&
                                        chatRequests.length > 0 &&
                                        chatRequests.map((request, index) => {
                                            return (
                                                <>
                                                    <ChatItem key={index} currentChatRoomId={chatRoomId} chatRequest={request} onClick={() => { joinChatSession(request.id); setOwner(request.owner) }} />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            <Col xl={9}>
                                {/* {chatRoomId && owner && (
                                    <div className="chat-content-header">
                                        <Avatar className="avatar" name={owner?.name ?? "ME"} size="40" textSizeRatio={2} round maxInitials={2} />
                                        <span className="ml-3 h3">{owner?.name ?? "ME"}</span>
                                    </div>
                                )} */}
                                <div className="chat-content">
                                    {
                                        chatRoomId ?
                                            (
                                                // <ChatBox
                                                //     messages={messages}
                                                //     user={user}
                                                //     onSubmit={sendChat}
                                                //     isLoading={isLoading}
                                                //     renderMessage={(message) => <Chat message={message} user={user} />}
                                                // />
                                                <MainContainer>
                                                    <ChatContainer key={chatRoomId}>
                                                        <ConversationHeader>
                                                            <ChatAvatar>
                                                                <Avatar className="avatar" name={owner?.name ?? "ME"} size="40" textSizeRatio={2} round maxInitials={2} />
                                                            </ChatAvatar>
                                                            <ConversationHeader.Content userName={owner?.name ?? "ME"} />
                                                        </ConversationHeader>
                                                        <MessageList ref={msgListRef}>
                                                            {!isEmpty(messagesDate) && (
                                                                Object.entries(messagesDate).map(([key, val]) => {
                                                                    return (
                                                                        <>
                                                                            <MessageSeparator content={key} />
                                                                            {val.map(msg => {
                                                                                let isUser = (user.uid === msg.sender.uid);
                                                                                return (
                                                                                    <Message
                                                                                        model={{
                                                                                            direction: isUser ? "outgoing" : "incoming",
                                                                                            type: "custom"
                                                                                        }}
                                                                                    >
                                                                                        {!isUser && (
                                                                                            <ChatAvatar>
                                                                                                <Avatar className="avatar" name={owner?.name ?? "ME"} size="40" textSizeRatio={2} round maxInitials={2} />
                                                                                            </ChatAvatar>
                                                                                        )}
                                                                                        <Message.CustomContent>
                                                                                            <Chat message={msg} user={user} />
                                                                                        </Message.CustomContent>
                                                                                    </Message>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    );
                                                                })
                                                            )}
                                                        </MessageList>
                                                        <MessageInput
                                                            placeholder="Type a message"
                                                            attachButton={false}
                                                            onSend={(innerHtml, textContent, innerText) => { sendChat(innerText); }}
                                                            onPaste={(evt) => {
                                                                // let paste = (evt.clipboardData || window.clipboardData).getData('text');
                                                                // const selection = window.getSelection();
                                                                // if (!selection.rangeCount) return false;

                                                                // function insertAt(src, startOffset, str) {
                                                                //     return src.substr(0, startOffset) + str + src.substr(startOffset);
                                                                // }

                                                                // settest(prev => {
                                                                //     if (prev) {
                                                                //         let tempPrev = prev;
                                                                //         if (selection.getRangeAt(0).collapsed) {
                                                                //             return insertAt(tempPrev, selection.getRangeAt(0).startOffset, paste);
                                                                //         }
                                                                //         else {
                                                                //             console.log(tempPrev.substring(selection.getRangeAt(0).startOffset, selection.rangeCount), selection.rangeCount, selection.getRangeAt(0).startOffset)
                                                                //             return tempPrev.replace(tempPrev.substring(selection.getRangeAt(0).startOffset, selection.getRangeAt(0).endOffset), paste);
                                                                //         }
                                                                //     }
                                                                //     return paste;
                                                                // });
                                                                evt.preventDefault();
                                                            }}
                                                        />
                                                    </ChatContainer>
                                                </MainContainer>
                                            ) :
                                            (
                                                <div className="chat-none d-flex align-items-center justify-content-center" >
                                                    <div className="d-lg-block d-md-none">
                                                        <img className="mr-5" src={require("../../assets/img/ui/search-graphic.svg")} alt="" />
                                                        <h4 className="text-center">{t("SELECT_CHAT_TO_VIEW_MESSAGE")}</h4>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </div>
    </>;
}

export default AdminChatView;

export const Chat = (props) => {
    let { user, message } = props;
    let isUser = (user.uid === message.sender.uid) || false;

    const renderName = () => {
        if (isUser) {
            return <></>;
        } else {
            return <div className='sender-name'>{message.sender.name}</div>;
        }
    }

    return (
        <div
            key={message.id}
            className="msg-chat-bubble"
        >
            <span>
                {message.text}
            </span>
            <span>{moment.utc(message.messageDate).format(DateFormat._DISPLAY_TIME_ONLY)}</span>
        </div>
    );
}

export const ChatItem = (props) => {
    let { currentChatRoomId, chatRequest, onClick } = props;
    const selected = (chatRequest.id === currentChatRoomId);
    const { t } = useTranslation();
    const renderName = () => {
        return <div className='sender-name'>{chatRequest.owner?.name}</div>;
    }

    const renderLastMessage = () => {
        var lastMessage = chatRequest?.lastMessage;
        return (
            <div className='last-message'>
                {
                    lastMessage ?
                        <>
                            {lastMessage?.message}
                        </>
                        :
                        <>{t("NO_NEW_MESSAGE")}</>
                }
            </div>
        );
    }

    const renderLastMsgDate = () => {
        var lastMessage = chatRequest?.lastMessage;
        return (
            <div className="last-message-date">
                {!stringIsNullOrEmpty(lastMessage?.messageDate) && moment.utc(lastMessage?.messageDate).calendar({ sameElse: DateFormat._DATE_ONLY })}
            </div>
        );
    }

    useEffect(() => {
    }, []);

    return (
        <div
            onClick={onClick}
            className={classnames("chat-request-item", { "selected": selected, "read": !chatRequest?.hasUnreadMessage })}>
            <Avatar className="avatar" name={chatRequest.owner?.name} size="40" textSizeRatio={2} round maxInitials={2} />
            <div className="chat-request-item-details">
                <div className="d-flex justify-between">{renderName()}{renderLastMsgDate()}</div>
                <div className="d-flex justify-between">
                    {renderLastMessage()}
                    {(chatRequest?.hasUnreadMessage && chatRequest?.unreadCount > 0) && (
                        <span className="unread-label">
                            {chatRequest.unreadCount}
                        </span>
                    )}
                </div>

            </div>
        </div>
    );
}
