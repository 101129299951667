import React, { useContext } from "react";
import NavigationButton from 'components/buttons/NavigationButton';
import { Link, useParams } from "react-router-dom";
import FinancesListing from "./FinancesListing";
import { PolicyActionConstant, PolicyObjectConstant, RoleType, WebUrl } from "util/Constant";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import { PageSettings } from 'config/page-settings';
import { useRecoilValue } from "recoil";
import { userRolesSelector } from "recoil/Atoms";
import Error403View from 'components/error-graphic/Error403NoAccess';
import { Can, UserAccessContext } from 'config/user-access'

///<summary>
///Author: Ong Sze Hua
///</summary>
const CompanyFinances = (props) => {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const { isXlDevices } = useContext(PageSettings);

    return (<div className="panel panel-brand panel-flex">
        <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_finance_list} passThrough>
            {
                allowed => {
                    if(allowed){
                        return <>
                        <div className="panel-header">
                            <h1 className="page-header mb-0 title title-with-dot"><NavigationButton />{t("FINANCES")}</h1>
                        </div>
                        <div className="panel-body y-scrollbar-2 h-100">
                            <div className={classNames('panel-body-inner-content with-br-radius', {
                                'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices
                            })}>
                                <div className="d-flex flex-column h-100">
                                    <div className="flex-grow-1">
                                        <FinancesListing companyId={companyId} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                    else{
                        return <Error403View />
                    }
                }
            }
        </Can>
    </div>)
}

export default CompanyFinances;