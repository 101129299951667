import React, { useState, useEffect, useRef, useMemo } from "react";
import classnames from "classnames";
import { useRecoilState } from "recoil";
import { UncontrolledTooltip } from "reactstrap";
import { CompanyProcessState, CompanyStatus } from "util/Constant";
import { companyProcessStates } from "recoil/Atoms.js";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Robin
/// </summary>
const CompanyStatusIcon = ({ companyStatus, stateId, className, ...rest }) => {
    const [companyProcesses, setCompanyProcesses] = useRecoilState(companyProcessStates);
    const [statusClass, setStatusClass] = useState();
    const [tooltipId, setTooltipId] = useState(uuidv4());
    const { t } = useTranslation();

    const getClassName = (stateId) => {
        let classStr = "";

        switch (stateId) {
            case CompanyProcessState._IDLE:
                if (companyStatus === CompanyStatus._LIVE) {
                    classStr = "status-green";
                } else if (companyStatus === CompanyStatus._REJECTED) {
                    classStr = "status-darkred";
                } else {
                    classStr = "status-grey";
                }
                break;
            case CompanyProcessState._NAME_CHECK:
                classStr = "status-gold";
                break;
            case CompanyProcessState._PENDING_INFORMATION:
                classStr = "status-light-blue";
                break;
            case CompanyProcessState._PENDING_PAYMENT:
                classStr = "status-blue";
                break;
            case CompanyProcessState._PAYMENT_COMPLETE:
                classStr = "status-purple";
                break;
            case CompanyProcessState._PENDING_SIGNATURE:
                classStr = "status-yellow";
                break;
            case CompanyProcessState._VERIFY_DOCUMENT:
                classStr = "status-orange";
                break;
            case CompanyProcessState._PROCESSING_SERVICE_REQUEST:
                classStr = "status-light-blue";
                break;
            default:
                classStr = "status-grey";
                break;
        }

        return classStr;
    }

    useEffect(() => {
        let classStr = getClassName(stateId);
        setStatusClass(classStr);
        return;
    }, [stateId]);

    return (
        <React.Fragment>
            <div id={`statusIcon_${tooltipId}`} className={classnames(`status-icon`, className, statusClass)}></div>
            <UncontrolledTooltip placement="right" target={`statusIcon_${tooltipId}`}>
                <table className="table-statuslist" style={{ fontSize: "13px", lineHeight: 1 }}>
                    <tbody>
                        {
                            companyProcesses &&
                            companyProcesses.map((item, index) => {
                                var active = item?.id == stateId;
                                return (<tr key={index} className={classnames((active ? "active" : ""))}>
                                    <td><div className={classnames(`status-icon`, getClassName(item?.id))}></div></td>
                                    <td>{item?.name}</td>
                                </tr>)
                            })
                        }
                        <tr>
                            <td colSpan="2"><div className="divider div-transparent" /></td>
                        </tr>
                        <tr>
                            <td><div className={classnames(`status-icon status-green`)}></div></td>
                            <td>{t("LIVE")}</td>
                        </tr>
                        <tr>
                            <td><div className={classnames(`status-icon`, "status-darkred")}></div></td>
                            <td>{t("REJECTED")}</td>
                        </tr>
                    </tbody>
                </table>
            </UncontrolledTooltip>
        </React.Fragment>
    );
}

export default CompanyStatusIcon;