import React, { useState, useEffect, useContext, useRef } from "react";
import { PageSettings } from '../../config/page-settings.js';
import StepZilla from "react-stepzilla";
import { ApiKey, ApiUrl, InputTypes, LoadingStateText, OTPKey, OTPSettings, RoleType, SessionKey, SidebarType, WebUrl } from "util/Constant.js";
import FormBuilder, { submitForm } from "components/form/FormBuilder.js";
// import ForgotPasswordModal from "./ForgotPasswordModal";
import { useHistory } from "react-router";
import { Report, Notify, Loading, Block } from 'notiflix';
import { Card, CardBody, Util } from 'reactstrap';
import classnames from 'classnames';
import { useRecoilState, useRecoilValue } from "recoil";
import { authCredentialState, loginFormState } from '../../recoil/Atoms';
import { useAuthController } from "hooks";
import { isEmpty } from "lodash";
import OtpModal from '../../components/modals/OtpModal';
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import useSWR from "swr";
import DataAccessObject from "data/DataAccessObject.js";
import { useTranslation } from "react-i18next";
import { InitLanguage } from "util/Utility";
//import queryString from 'query-string';




/// <summary>
/// Author: Lewis
/// </summary>
const LoginForm = ({ jumpToStep, setLoginFormValue, loginFormValue }) => {
    const { t, i18n } = useTranslation();
    const formRef = useRef();
    const _history = useHistory();
    const _MAX_ATTEMPTS_NUM = 10;
    const [remainSeconds, setRemainSeconds] = useState();
    const [attemptNum, setAttemptNum] = useState();
    const { validateCredentials, errorMessages, login } = useAuthController({
        validateCredentialsCallback: {
            success: () => {
                jumpToStep(1);
                Block.Remove('div.card-login');

                // //Temporaly use for skip OTP
                // login(loginFormValue);
            },
            fail: (data) => {
                if (!isEmpty(data)) {
                    setAttemptNum(data.attemptCounts);
                    setRemainSeconds(data.remainingCooldown);
                }
                Block.Remove('div.card-login');
            },
        },
        //Temporaly use for skip OTP
        loginCallback: {
            success: () => {
                setLoginFormValue({});
                _history.push(WebUrl._GET_STARTED);
            },
            fail: () => { return },
        },
    });

    const _fields = [
        {
            columns: [
                { label: 'EMAIL', name: 'email', input: InputTypes.INPUT, columnOptions: { xl: 12 }, rules: { required: 'Email is required.' } },
                {
                    label: 'PASSWORD', name: 'password', type: 'password', input: InputTypes.INPUT, columnOptions: { xl: 12 }, rules: { required: 'Password is required.' }, onKeyDown: (e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
                        }
                    }
                },
            ]
        }
    ]

    useEffect(() => {
        if (loginFormValue?.email && loginFormValue?.password) {
            Block.Circle('div.card-login');
            validateCredentials(loginFormValue);
        }
    }, [loginFormValue]);

    useEffect(() => {
        if (attemptNum > _MAX_ATTEMPTS_NUM) {
            const interval = setInterval(() => {
                setRemainSeconds((prevSeconds) => {
                    let tempRemainSeconds = prevSeconds - 1;
                    if (tempRemainSeconds === 0) {
                        clearInterval(interval);
                        setAttemptNum(0);
                    }
                    return tempRemainSeconds;
                });
            }, 1000);
        }
    }, [attemptNum]);

    ///  <summary>
    /// Author: CJ(Jiann)
    /// </summary>
    useEffect(() => {
        if(_history.location.search != ""){
            let language = new URLSearchParams(window.location.search).get('lang');
            var selectedLanguage = InitLanguage(language);
            i18n.changeLanguage(selectedLanguage);
            localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
        }
    }, [_history])

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = (data) => {
        setLoginFormValue(data);
    };

    return (
        <div id="loginForm" className="login-wrapper p-0">
            <div className="login-form">
                <h1 className="title title-with-dot mb-4">{t('LOGIN')}</h1>
                <FormBuilder fields={_fields} formRef={formRef} onSubmit={onSubmit}/>
                <div className="mt-3 mb-3 text-right"><Link to={WebUrl._FORGOT_PASSWORD}>{`${t('FORGOT_PASSWORD')}?`}</Link></div>
                <div className="login-btn-div m-t-10">
                    <div className="d-flex justify-content-center mb-5">
                        <button onClick={() => submitForm(formRef)} className="btn btn-lg btn-block btn-themed btn-min-width">{t('LOGIN')}</button>
                    </div>
                </div>
                {errorMessages && <div className="text-center"><span className="messages__error">{errorMessages}</span></div>}
                {attemptNum > _MAX_ATTEMPTS_NUM && <div className="text-center mt-3"><span className="messages__error">{t('TOO_MANY_ATTEMPS')} <b>{remainSeconds}</b> {t('SECONDS')}</span></div>}
                <div className="text-center mt-4">{`${t('DONT_HAVE_ACC')}?`} <Link to={WebUrl._REGISTER}>{`${t('SIGN_UP_NOW')}!`}</Link></div>
                {/* <ForgotPasswordModal isModalOpen={isForgotPasswordModalOpen} setModalOpen={setForgotPasswordModalOpen} /> */}
            </div>
            <div className="login-aside d-lg-block d-none">
                <img src={require('../../assets/img/ui/login-graphic.svg')} alt="" style={{ width: "100%", height: "100%" }} />
            </div>
        </div>
    )
}

/// <summary>
/// Author: Lewis
/// </summary>
const LoginTacMethod = ({ jumpToStep, setLoginFormValue, loginFormValue }) => {

    const _history = useHistory();
    const otpRef = useRef();
    const [otpMethod, setOtpMethod] = useState({ [OTPKey._EMAIL]: true, [OTPKey._SMS]: false });
    const [tacValue, setTacValue] = useState({ otp: '' });
    const [otpModal, setOtpModal] = useState();
    const [counter, setCounter] = useState(OTPSettings._OTP_TIMER);
    const [attemptNum, setAttemptNum] = useState(OTPSettings._OTP_RESEND_ATTEMPT);
    const [remainSeconds, setRemainSeconds] = useState(0);
    const [messagesObj, setMessagesObj] = useState({ success: false, message: null });
    const { t } = useTranslation();

    const { login, isLoggedIn, getOtp, errorMessages } = useAuthController({
        loginCallback: {
            success: () => {
                setLoginFormValue({});
                _history.push(WebUrl._GET_STARTED);
            },
            fail: (jsonResponse) => {

                let data = jsonResponse?.[ApiKey._API_DATA_KEY];

                if (data?.kickUser) {
                    Report.Warning(t('notiflix:TOO_MANY_ATTEMPS'), jsonResponse[ApiKey._API_MESSAGE_KEY], t('notiflix:BACK'), () => jumpToStep(0));
                }
                else {
                    // wrong otp
                    setTacValue({ otp: '' });
                    // Notify.Warning(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                    setMessagesObj({ success: false, message: jsonResponse[ApiKey._API_MESSAGE_KEY] });
                }
            },
        },
        getOtpCallback: {
            success: (data) => {
                if (!isEmpty(data)) {
                    setRemainSeconds(data.remainingCooldown);
                    setAttemptNum(0);
                    setMessagesObj({ success: true, message: 'notiflix:OTP_REQUEST_SUCCESS'});

                }
                

                setOtpModal(true);
                // jumpToStep(2)
                Block.Remove("div.card-login");
            },
            fail: (data) => {
                if (!isEmpty(data)) {
                    setRemainSeconds(data.remainingCooldown);
                    setAttemptNum(0);
                    if (data.remainingCooldown) {
                        setMessagesObj({ success: false, message: 'notiflix:OTP_REQUEST' });
                    }
                }

                Block.Remove("div.card-login");
            },
        }
    });

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const requestOtpHandler = () => {
        var keys = Object.keys(otpMethod);
        var otpKey = keys.filter(key => { return otpMethod[key] })[0];
        setLoginFormValue(prevState => ({ ...prevState, otpMethod: otpKey }));
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggleOtpMethod = (key) => {
        setOtpMethod(prevState => {
            if (otpMethod[key]) return prevState;
            return ({ ...!prevState, [key]: !prevState[key] });
        })
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const otpFieldOnChangeHandler = (otp) => {
        setTacValue({ otp });
        if (otp.length != OTPSettings._OTP_INPUT_LENGTH) {
            return
        }
        setLoginFormValue(prevState => ({ ...prevState, oneTimePassword: otp }))
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const resendOtpOnClickBtnHandler = () => {
        // if (attemptNum - 1 === 0) {
        //     Report.Warning(
        //         'OTP Reach Maximum Attempts',
        //         'Please wait 15 minutes to reset your password again.',
        //         'Back',
        //         () => (jumpToStep(0))
        //     );
        //     return
        // }
        setTacValue({ otp: '' });
        if (attemptNum > 0) {
            getOtp(loginFormValue, false);
            setCounter(OTPSettings._OTP_TIMER);
        }
        setAttemptNum(attemptNum - 1);

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (loginFormValue?.otpMethod) {
            Block.Circle("div.card-login", LoadingStateText._PLEASE_WAIT);
            getOtp(loginFormValue);
        }
    }, [loginFormValue?.otpMethod]);

    useEffect(() => {
        if (loginFormValue?.oneTimePassword) {
            login(loginFormValue);
        }
    }, [loginFormValue?.oneTimePassword]);

    useEffect(() => {
        requestOtpHandler();
    }, []);

    useEffect(() => {
        if (attemptNum === 0) {
            const interval = setInterval(() => {
                setRemainSeconds((t) => {
                    let tempRemainTime = t - 1;
                    if (tempRemainTime === 0) {
                        clearInterval(interval);
                        setAttemptNum(OTPSettings._OTP_RESEND_ATTEMPT);
                    }
                    return tempRemainTime;
                });
            }, 1000);
        }
    }, [attemptNum]);

    useEffect(() => {
        if (!(loginFormValue?.email && loginFormValue?.password)) {
            jumpToStep(0);
        }
    }, [loginFormValue]);

    return (
        <>
            <div className="LoginTacPanel">
                <div className="login-tac-wrapper">
                    <div className="login-tac-body y-scrollbar-2 x-scrollbar-2">
                        <div className="text-center mb-5">
                            {/* temporary remove login method
                            <h1 className="title title-with-dot">Login Method</h1>
                            <p className="text-center">Please select a method to receive your One-Time Password (OTP)</p> */}
                        </div>
                        <div className="login-method-wrapper">
                            <div className="modal-login-otp">
                                <div className="otp-wrapper">
                                    <div className="d-lg-block">
                                        <img className="mr-5" src={require("../../assets/img/ui/email-selected.png")} alt="" />
                                    </div>
                                    <div>
                                        <h1 className="title title-with-dot mb-4">{t('LOGIN_OTP')}</h1>
                                        <p style={{ maxWidth: '80%' }}>{t('PLEASE_ENTER_OTP')}</p>
                                        {messagesObj?.message &&
                                            <span className={messagesObj.success ? "text-success" : "text-danger"}>
                                                {t(`${messagesObj?.message}`)}
                                            </span>
                                        }
                                        <OtpInput
                                            ref={otpRef}
                                            containerStyle={"otp-input-container"}
                                            value={tacValue.otp}
                                            onChange={otpFieldOnChangeHandler}
                                            numInputs={OTPSettings._OTP_INPUT_LENGTH}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            focusStyle="red"
                                            hasErrored={true}
                                        />
                                        {attemptNum !== 0 ? (
                                            <div>{`${t('DIDNT_RECEIVE_OTP')}?`}<button type="button" onClick={resendOtpOnClickBtnHandler} className="btn btn-link" style={{ padding: '0 0.75rem' }}>{t('RESENT_OTP')}</button></div>
                                        ) : (
                                            <div style={{ padding: '1px 0' }}>{t('PLEASE_WAIT')} <b>{remainSeconds}</b> {t('SECONDS_TO_RESENT_OTP')}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* temporary remove login method
                            <Card onClick={() => toggleOtpMethod(OTPKey._EMAIL)}
                                className={classnames("login-method-selection cursor-pointer", { "active": otpMethod[OTPKey._EMAIL] })}>
                                <CardBody>
                                    <img src={require('../../assets/img/ui/email-selected.png')} alt="" />
                                    <strong>Email</strong>
                                </CardBody>
                            </Card>
                            <Card onClick={() => toggleOtpMethod(OTPKey._SMS)}
                                className={classnames("login-method-selection cursor-pointer", { "active": otpMethod[OTPKey._SMS] })}
                            >
                                <CardBody>
                                    <img src={require('../../assets/img/ui/sms-selected.png')} alt="" />
                                    <strong>SMS</strong>
                                </CardBody>
                            </Card> */}
                        </div>
                    </div>
                    <div className="panel-foot d-flex justify-between">
                        <button onClick={() => { setLoginFormValue({}); }} className="btn btn-themed btn-themed-rest btn-min-width">{t('BACK')}</button>
                        {/* <button onClick={requestOtpHandler} className="btn btn-themed btn-min-width">Next</button> */}
                    </div>
                </div>
            </div>
            {/* <OtpModal
                isOpen={otpModal}
                toggler={(e) => setOtpModal(e)}
                onChange={otpFieldOnChangeHandler}
                inputLength={OTPSettings._OTP_INPUT_LENGTH}
                value={tacValue.otp}
                onResend={resendOtpOnClickBtnHandler}>
                <h2 className="title title-with-dot mb-4">Login OTP</h2>
                <p style={{ maxWidth: '80%' }}>Please enter the OTP (One Time Password)</p>
            </OtpModal> */}
        </>
    )
}

/// <summary>
/// Author: Lewis
/// </summary>
const Login = () => {
    const authCredential = useRecoilValue(authCredentialState);
    const _context = useContext(PageSettings);
    const [loginFormValue, setLoginFormValue] = useRecoilState(loginFormState);
    const _loginWizardArray = [
        { name: '1', component: <LoginForm setLoginFormValue={setLoginFormValue} loginFormValue={loginFormValue} /> },
        { name: '2', component: <LoginTacMethod setLoginFormValue={setLoginFormValue} loginFormValue={loginFormValue} /> }
    ]

    const { t, i18n } = useTranslation();

    const { data: languageList } = useSWR(
        ApiUrl._API_GET_LANGUAGE_LIST,
        async (url) => {
            let dao = new DataAccessObject();
            let tempList = [];
            await dao.get(url).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    tempList = jsonResponse[ApiKey._API_DATA_KEY];
                }
            });
            return tempList;
        }
    );

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);
        _context.setOptions('sideBarButton', false);
        return (() => {
            if (!isEmpty(authCredential)) {
                if (authCredential.role.id == RoleType._SUPERUSER || authCredential.role.id == RoleType._ADMIN) {
                    _context.setOptions('sideBarButton', true);
                    _context.setOptions('activeSidebar', SidebarType._ADMIN);
                }
                else {
                    _context.setOptions('sideBarButton', false);
                    _context.setOptions('activeSidebar', SidebarType._ACCOUTANT);
                }
            }
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
        });
    }, [authCredential]);

    return (
        <>
            <div className="login-bg"></div>
            <div className="container h-100 d-flex flex-column">
                <Card className="card-login panel-brand mt-auto w-100">
                    <StepZilla
                        preventEnterSubmission={true}
                        showNavigation={false}
                        steps={_loginWizardArray}
                        stepsNavigation={false}
                        showSteps={false}
                        nextButtonCls='btn btn-prev pull-right'
                        backButtonCls='btn btn-next pull-left'
                    />
                </Card>
                <div className="d-flex align-items-center p-l-15 p-t-10 mb-auto">
                    <span>{t('LANGUAGE')}</span>
                    <div className="d-flex">
                        {languageList && (
                            languageList.map(data => (
                                <div className={"pointer-cursor countries language-" + data.code} onClick={() => { i18n.changeLanguage(data.code) }} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
