import React from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PageSettings } from './../../config/page-settings.js';
import SidebarNav from './sidebar-nav.jsx';
import { SidebarType } from '../../util/Constant';

class Sidebar extends React.Component {
    static contextType = PageSettings;

    render() {
        return (
            <PageSettings.Consumer>
                {({ toggleSidebarMinify, toggleMobileSidebar, pageSidebarTransparent, activeSidebar }) => (
                    <React.Fragment>
                        {
                            activeSidebar == SidebarType._ACCOUTANT &&
                            <>
                                <div id="sidebar" className={'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '')}>
                                    <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                                        <SidebarNav userType="Accountant" />
                                    </PerfectScrollbar>
                                </div>
                                <div className="sidebar-bg"></div>
                                <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar}></div>
                            </>
                        }
                    </React.Fragment>
                )}
            </PageSettings.Consumer>
        )
    }
}

export default Sidebar;
