import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language, SessionKey } from "../util/Constant";

import EnGB from "./en-GB"
import ZhCN from "./zh-CN"
import ZhHK from "./zh-HK"

/// <summary>
/// Author: -
/// </summary>
const _resources = {};
_resources[Language._ENGLISH_GB] = {...EnGB};
_resources[Language._CHINESE_CN] = {...ZhCN};
_resources[Language._CHINESE_HK] = {...ZhHK};

i18n
    .use(initReactI18next)
    .init({
        defaultNS: "common",
        debug: false,
        resources: _resources,
        lng: localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_US,
        fallbackLng: [Language._ENGLISH_GB, Language._CHINESE_CN, Language._CHINESE_HK],
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;