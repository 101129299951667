import React, { useMemo, useState, Component, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Tooltip, Nav, TabPane } from 'reactstrap';
import { WebUrl, InputTypes, ApiKey, LoadingStateText, BtnTypes, DateFormat, ApiUrl, PolicyActionConstant, PolicyObjectConstant, FileType } from 'util/Constant';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';
import Notiflix, { Block, Notify } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { OfficerSearchDao } from "data";
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "../../components/panel/TabPanel";
import { useTranslation } from "react-i18next";
import { CompanyDao } from "../../data";
import ButtonRound from "components/buttons/ButtonRound";
import classnames from 'classnames';
import moment from 'moment';
import ImageModal from "components/modals/ImageModal";
import { UbTreeHierarchyComponent } from "pages/incorporation";
import useSWR from "swr";
import { isEmpty } from "lodash";
import { createNonNullChain } from "typescript";
import Error403View from 'components/error-graphic/Error403NoAccess';
import { Can } from 'config/user-access';
import DocumentViewer from 'components/document-viewer/DocumentViewer';

const AmlTypes = { _SANCTION: "sanction", _WARNING: "warning", _PEP: "pep", _ADVERSE_MEDIA: "adverse-media" };

///<summary>
///Author: Ong Sze Hua
///</summary>
const KycAmlResult = (props) => {

    const { t } = useTranslation();
    const { companyId } = useParams();
    const _location = useLocation();
    const _history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        if (!companyId) {
            _history.goBack();
        }
    }, [companyId]);

    return (
        <div className="panel panel-brand panel-flex">
            <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_kyc_aml_result} passThrough>
                {
                    allowed => {
                        if(allowed){
                            return <>
                            <div className="panel-header p-b-0">
                                <h1 className="mb-0 page-header title title-with-dot not-to-print"><NavigationButton />{t("KYC_AML_RESULT")}</h1>
                                <Tabs value={currentTab} onChange={(e, newTab) => { setCurrentTab(newTab) }} variant="scrollable" className="not-to-print">
                                    <Tab icon={<img src={require('../../assets/img/icon/accountant.svg')} className="mr-2" />} label={t("KYC_AML_RESULT")} classes={{ wrapper: "d-inline" }} />
                                    <Tab icon={<img src={require('../../assets/img/icon/admin.svg')} className="mr-2" />} label={t("ULTIMATE_BENEFICIARY_DETAILS")} classes={{ wrapper: "d-inline" }} />
                                </Tabs>
                            </div>
                            <div className="panel-body p-0 h-100">
                                <div className="d-flex flex-column h-100">
                                    <TabPanel value={currentTab} index={0} style={{ flex: 1, height: "100%" }}><KycAmlTabView companyId={companyId} /></TabPanel>
                                    <TabPanel value={currentTab} index={1} style={{ flex: 1, height: "100%" }}><UbTreeHierarchyComponent companyId={companyId} isEdit={false} /></TabPanel>
                                </div>
                            </div>
                            </>
                        }
                        else{
                            return <Error403View />
                        }
                    }
                }
            </Can>
            
        </div >
    )
}

///<summary>
///Author: Ong Sze Hua
///</summary>
const KycAmlTabView = ({ companyId }) => {

    const { t } = useTranslation();
    const [officers, setOfficers] = useState();
    const _history = useHistory();
    const [attachmentUrl, setAttachmenUrl] = useState(null);
    const [attachmentHeader, setAttachmentHeader] = useState(null);
    const [modal, setModal] = useState(false);
    const [resolvedOfficerId, setResolvedOfficerId] = useState();
    const [currentTab, setCurrentTab] = useState([]);
    const [company, setCompany] = useState();
    const [showImageModal, setShowImageModal] = useState(false);
    const [isViewing, setIsViewing] = useState(false);
    const [viewFile, setViewFile] = useState();
    const toggleImage = () => {
        setShowImageModal(!showImageModal);
    }

    const setImageModalImage = (url) => {
        let path = url.fileMeta.slice(-1)[0].requestPath;
        setAttachmenUrl(path);
        toggleImage();
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: companyProfileJson } = useSWR(companyId ? [
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#kyc-result-blocker"
    ] : null);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: beneficiaryOfficerJson } = useSWR(companyId ? [
        ApiUrl._API_GET_PRODUCTION_BENEFICIARY_OFFICERS.replace(":companyId", companyId),
        ApiKey._API_GET,
        null,
        "div#company-officer-kyc"
    ] : null);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (companyProfileJson?.[ApiKey._API_SUCCESS_KEY]) {

            let { data } = companyProfileJson;

            if (data.kycQuestion) {
                let temp = {};
                Object.keys(data.kycQuestion).map((key) => { temp[key] = 0; });
                setCurrentTab(temp);
            }

            setCompany(data);
        }

    }, [companyProfileJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (beneficiaryOfficerJson?.[ApiKey._API_SUCCESS_KEY]) {

            let { data } = beneficiaryOfficerJson;
            setOfficers(data);
        }

    }, [beneficiaryOfficerJson]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(!modal); };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const renderRows = (data) => {
        var searchResult;
        var resolved = false;
        if (data.officer.officerSearches) {
            searchResult = data.officer.officerSearches[data.officer.officerSearches.length - 1];
            if (searchResult)
                resolved = searchResult.officerSearchDetails.some(o => o.status);
        }
        return <> <tr>
            <td>{data.officer.englishName}</td>
            <td>
                <ButtonRound type={BtnTypes.FA_VIEW} medium
                    onClick={() => _history.push({
                        pathname: WebUrl._COMPANY_MANAGEMENT_OFFICER_PROFILE.replace(":companyId", companyId), state: {
                            data: {
                                officerId: data.officer.id,
                                officerTypeId: data.officer.officerTypeId,
                                companyId: company?.id,
                                isShareHolder: data.isShareholder,
                                isDirector: data.isDirector
                            }
                        }
                    })}
                />
            </td>
            <td>
                {
                    data.officer.idAttachment ?
                        <div className="flex-align-center">
                            <i className="las la-check" style={{ color: 'limegreen', marginRight: '5%' }} />
                            <ButtonRound type={BtnTypes.FA_VIEW} medium
                                onClick={() => {
                                    let CurrentIdentityFile = data.officer.idAttachment.fileMeta.find(f => f.id === data.officer.idAttachment.currentVersionFileMetaId);

                                    if (CurrentIdentityFile?.extension == FileType._PDF) {
                                        setIsViewing(!isViewing);
                                        setViewFile(CurrentIdentityFile.requestPath)
                                    }
                                    else {
                                        setImageModalImage(data.officer.idAttachment);
                                    }
                                }}
                            />
                        </div> : t("incorporation:NA")
                }
            </td>
            <td>
                {
                    data.officer.addressAttachment ?
                        <div className="flex-align-center">
                            <i className="las la-check" style={{ color: 'limegreen', marginRight: '5%' }} />
                            <ButtonRound type={BtnTypes.FA_VIEW} medium
                                onClick={() => {
                                    let CurrentAddressFile = data.officer.addressAttachment.fileMeta.find(f => f.id === data.officer.addressAttachment.currentVersionFileMetaId);

                                    if (CurrentAddressFile?.extension == FileType._PDF) {
                                        setIsViewing(!isViewing);
                                        setViewFile(CurrentAddressFile.requestPath)
                                    }
                                    else {
                                        setImageModalImage(data.officer.addressAttachment);
                                    }
                                }}
                            />
                        </div> : t("incorporation:NA")
                }
            </td>
            <td>
                {searchResult && searchResult.officerSearchDetails.length > 0 ?
                    <>
                        {
                            resolved &&
                            <ButtonRound type={BtnTypes.FA_VIEW} medium
                                onClick={() => { setResolvedOfficerId(data.officer.id); toggle(); }}
                            />
                        }
                        <Link to={{ pathname: WebUrl._KYC_RESULT_LIST, state: { officerId: data.officer.id, searchTerm: data.officer.englishName } }}>{resolved ? "Other " : searchResult.officerSearchDetails.length} {searchResult.matchStatus}</Link>
                    </>
                    : t("incorporation:NO_EXACT_MATCH")
                }
            </td>
        </tr>
        </>
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function changeAttachmentUrl(attachment, attachmentType, englishName) {
        if (attachment) {
            let path = attachment.fileMeta.slice(-1)[0].requestPath;
            if (attachmentUrl === path) {
                setAttachmenUrl(null);
                setAttachmentHeader(null);
            }
            else {
                setAttachmenUrl(path);
                setAttachmentHeader(englishName + " - " + attachmentType);
            }
        }
    }

    ///<summary>
    ///Author: Andrew
    ///</summary>
    const changeTabHandler = (id, tab) => {
        let temp = currentTab;
        temp[id] = tab;
        setCurrentTab({ ...currentTab, temp });
    };

    ///<summary>
    ///Author: Andrew
    ///</summary>
    const renderKycDetail = (detail) => {
        let parsed = detail;
        switch (typeof detail) {
            case 'object':
                parsed = Object.values(detail).join(", ")
                break;
            case 'array':
                parsed = detail.join(", ");
                break;
        }
        return parsed;
    };

    ///<summary>
    ///Author: Andrew
    ///</summary>
    const renderKycQuestions = (id, kycType) => {
        return <>
            <Tabs value={currentTab[id]} onChange={(e, tab) => { changeTabHandler(id, tab) }}>
                {
                    Object.keys(kycType).map((key, index) => {
                        return <Tab label={t(`incorporation:${key}`)} />
                    })
                }
            </Tabs>
            {
                Object.keys(kycType).map((key, index) => {
                    return (
                        <TabPanel value={currentTab[id]} index={index}>
                            <table>
                                <thead>
                                    {
                                        kycType[key] instanceof Object ?
                                            Object.keys(kycType[key]).map((key2) => {
                                                return <tr>
                                                    <th width="300">{t(`incorporation:${key2}`)}</th>
                                                    <td>{renderKycDetail(kycType[key][key2])}</td>
                                                </tr>
                                            })
                                            : kycType[key]
                                    }
                                </thead>
                            </table>
                        </TabPanel>
                    )
                })
            }
        </>
    }

    return (
        <div className="printable-area panel-body y-scollbar-2">
            <Row>
                <Col xl={4}>
                    <div className="panel panel-brand-inner company-kyc not-to-print" id="kyc-result-blocker">
                        <div>
                            {
                                company?.kycQuestion ?
                                    <>
                                        {
                                            company.kycQuestion.sourceOfFunds &&
                                            <>
                                                <div className="panel-header">{t(`incorporation:SOURCE_OF_FUNDS`)}</div>
                                                <div className="panel-body">
                                                    {
                                                        renderKycQuestions("sourceOfFunds", company.kycQuestion.sourceOfFunds)
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            company.kycQuestion.natureOfBusiness &&
                                            <>
                                                <hr className="mb-0 mt-0" />
                                                <div className="panel-header">{t(`incorporation:NATURE_OF_BUSINESS`)}</div>
                                                <div className="panel-body">
                                                    {
                                                        renderKycQuestions("natureOfBusiness", company.kycQuestion.natureOfBusiness)
                                                    }
                                                </div>
                                            </>
                                        }
                                        {
                                            company.kycQuestion.regionOfBusiness &&
                                            <>
                                                <hr className="mb-0 mt-0" />
                                                <div className="panel-header">{t(`incorporation:REGION_OF_BUSINESS`)}</div>
                                                <div className="panel-body">
                                                    {
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th width="300">{t(`incorporation:REGION_OF_BUSINESS`)}</th>
                                                                    <td>{renderKycDetail(company.kycQuestion.regionOfBusiness)}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </>
                                    : <p className="mt-3 text-center">{t("incorporation:KYC_QUESTION_UNAVAILABLE")}</p>
                            }
                        </div>
                    </div>
                </Col>
                <Col xl={8}>
                    <div id="company-officer-kyc" className="panel-body-inner-content with-br-radius" style={{ backgroundColor: "#fff" }}>
                        <div className="filter-panel-container">
                            <Row className="filter-panel-body p-t-15">
                                <Col xl={12}>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-pill-brand">
                                            <strong className="f-s-16">
                                                {company?.englishName}
                                                {company?.chineseName && <>({company?.chineseName})</>}
                                            </strong>
                                            <div className="ci-number"><strong>CI {t("NUMBER_SHORT")}.:</strong>{company?.corporateIdentityNumber}</div>
                                        </div>
                                        <div>
                                            {/* <button className="btn btn-sm btn-themed KYC-report-btn not-to-print btn-min-width" onClick={() => window.print()}>
                                                KYC Report
                                            </button> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <table className="table table-td-valign-middle table-kyc-report table-responsive">
                            <thead>
                                <tr role="row">
                                    <th width="1%"></th>
                                    <th>{t("NAME")}</th>
                                    <th><b>P#</b></th>
                                    <th>{t('incorporation:IDENTIFICATION_SHORT')}{t('incorporation:ATTACHMENT_SHORT')}</th>
                                    <th>{t('incorporation:ADDRESS_SHORT')}{t('incorporation:ATTACHMENT_SHORT')}</th>
                                    <th>{t('incorporation:AML')}{t('incorporation:SEARCH')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    officers &&
                                    Object.entries(officers).map(([key, value], index) => {
                                        let isArray = Array.isArray(value);
                                        if (isArray && value.length === 0) {
                                            return;
                                        }
                                        return <>
                                            <tr>
                                                <th rowSpan={isArray ? (value.length + 1) : 2}>{key.toUpperCase()}</th>
                                            </tr>
                                            {
                                                isArray ?
                                                    value.map(data => renderRows(data))
                                                    :
                                                    renderRows(value)
                                            }
                                        </>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={viewFile} />

            <ResolvedDetailModal modal={modal} toggle={toggle} resolvedOfficerId={resolvedOfficerId} />
            {
                attachmentUrl &&
                <ImageModal
                    attachmentUrl={attachmentUrl}
                    showImageModal={showImageModal}
                    toggle={toggleImage}
                />
            }
        </div >
    )
}

const ResolvedDetailModal = props => {
    const { modal, toggle, resolvedOfficerId } = props;

    const [resolvedDetail, setResolvedDetail] = useState();

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    useEffect(() => {
        if (modal && resolvedOfficerId)
            getOfficerSearch();
    }, [resolvedOfficerId]);

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    async function getOfficerSearch() {
        let officerSearchDao = new OfficerSearchDao();
        await officerSearchDao.getOfficerSearchByOfficerId(resolvedOfficerId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                let data = jsonResponse[ApiKey._API_DATA_KEY];
                setResolvedDetail(data.officerSearchDetails.find(d => d.status));
            }
        });
    }

    return <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
        <ModalHeader toggle={toggle}>Resolved Detail</ModalHeader>
        <ModalBody className="p-0">
            {
                resolvedDetail ?
                    <RenderDetail officerSearchDetail={resolvedDetail} />
                    :
                    <div style={{ minHeight: '300px' }} className="d-flex justify-content-center align-items-center">Loading...</div>
            }
        </ModalBody>
    </Modal>
}

const RenderDetail = props => {
    const { officerSearchDetail, renderActBtn, searchId } = props;

    const _TABS = {
        LISTING: { id: 0, title: "Listing" },
        ADVERSE_MEDIA: { id: 1, title: "Adverse Media" }

    };
    const [activeTab, setActiveTab] = useState(_TABS.LISTING.id);
    const [fullView, setFullView] = useState(false);
    const [selectAmlTypes, setSelectedAmlTypes] = useState([]);
    const [sourceViews, setSourceViews] = useState([]);
    const [mediaViews, setMediaViews] = useState([]);


    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    useEffect(() => {
        if (officerSearchDetail) {
            if (officerSearchDetail.sources) {
                let tempArr = [];
                Object.values(AmlTypes).map(type => {
                    if (officerSearchDetail.sources.some(d => d.amlTypes.some(v => v.includes(type)))) {
                        tempArr.push(type)
                    }
                })
                setSelectedAmlTypes(tempArr);
                let tempSource = officerSearchDetail.sources.map(() => fullView);
                setSourceViews(tempSource);
            }
            if (officerSearchDetail.media) {
                let tempMedia = officerSearchDetail.media.map(() => fullView);
                setMediaViews(tempMedia);
            }
            setActiveTab(_TABS.LISTING.id);
        }
    }, [officerSearchDetail]);

    const viewDetail = (views, index, isMedia = false) => {
        let temp = [...views];
        temp[index] = !temp[index];
        if (!isMedia)
            setSourceViews(temp);
        else if (isMedia)
            setMediaViews(temp);

    }
    useEffect(() => {
        if (sourceViews.length > 0 || mediaViews.length > 0) {
            let tempSource = sourceViews.map(() => fullView);
            let tempMedia = mediaViews.map(() => fullView);
            setSourceViews(tempSource);
            setMediaViews(tempMedia);
        }
    }, [fullView])

    return <Card className="kyc-listing" style={{ overflowY: 'auto' }}>
        <CardHeader className="d-flex justify-content-between">
            {officerSearchDetail.name}
            {
                !searchId &&
                (!officerSearchDetail.status ?
                    renderActBtn &&
                    renderActBtn()
                    : <span className="badge badge-success">Resolved</span>
                )
            }
        </CardHeader>
        <CardBody>
            <h4>Key Information</h4>
            <Row className={"m-b-5"}>
                <Col xs={2} className={"font-weight-bold"}>
                    Full Name
                </Col>
                <Col style={{ alignSelf: 'center' }}>
                    {officerSearchDetail.name}
                </Col>
            </Row>
            <Row className={"m-b-5"}>
                <Col xs={2} className={"font-weight-bold"}>
                    Entity Type
                </Col>
                <Col style={{ alignSelf: 'center' }}>
                    {officerSearchDetail.entityType}
                </Col>
            </Row>
            <Row className={"m-b-5"}>
                <Col xs={2} className={"font-weight-bold"}>
                    AKA
                </Col>
                <Col>
                    {
                        officerSearchDetail.aka?.reduce((accumulated, aka) => {
                            return [...accumulated, aka.name];
                        }, []).join(", ")
                    }
                </Col>
            </Row>
            <Row className={"m-b-5"}>
                <Col xs={2} className={"font-weight-bold"}>
                    Countries
                </Col>
                <Col>
                    {officerSearchDetail.countries}
                </Col>
            </Row>
            <hr />
            <button className="btn btn-themed pull-right" onClick={() => { setFullView(!fullView) }}>
                View {fullView ? "Summary" : "Full Listing"}
            </button>
            <Tabs value={activeTab} onChange={(e, newTab) => { setActiveTab(newTab) }} variant="scrollable">
                <Tab label={_TABS.LISTING.title} />
                {
                    (officerSearchDetail && officerSearchDetail.media && officerSearchDetail.media.length > 0) &&
                    <Tab label={_TABS.ADVERSE_MEDIA.title} />
                }
            </Tabs>
            <div>
                <TabPanel value={activeTab} index={_TABS.LISTING.id}>
                    {
                        officerSearchDetail && officerSearchDetail.sources && selectAmlTypes &&
                        selectAmlTypes.map(type => {
                            return <Row className="mb-4">
                                <Col xs={2}><span className="badge badge-warning f-s-14" style={{ whiteSpace: ((!searchId && !renderActBtn) && "pre-line") }}>{type}</span></Col>
                                <Col>
                                    {
                                        officerSearchDetail.sources.map((data, index) => {
                                            if (data.amlTypes.some(v => v.includes(type))) {
                                                return <div className={classnames("sanction-detail", { "disabled": !fullView ? !fullView && !sourceViews[index] : !fullView || !sourceViews[index] })} onClick={() => viewDetail(sourceViews, index)}>
                                                    <a href={data.url} target="_blank">{data.name}</a>
                                                    <table className='table-kyc-summary'>
                                                        <tbody>
                                                            {
                                                                data.fields.map(f =>
                                                                    <tr>
                                                                        <th className="p-b-5" style={{ width: "20%" }}>{f.name}</th>
                                                                        <td className="p-b-5" style={{ overflowWrap: 'anywhere' }}>{f.value}</td>
                                                                    </tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        })
                                    }
                                </Col>
                            </Row>
                        })
                    }
                </TabPanel>
                {
                    (officerSearchDetail && officerSearchDetail.media && officerSearchDetail.media.length > 0) &&
                    <TabPanel value={activeTab} index={_TABS.ADVERSE_MEDIA.id}>
                        {
                            officerSearchDetail.media.map((data, index) =>
                                <div className={classnames("sanction-detail", { "disabled": !fullView ? !fullView && !mediaViews[index] : !fullView || !mediaViews[index] })} onClick={() => viewDetail(mediaViews, index, true)}>
                                    <Row className="m-0 p-0">
                                        <Col xs={1}><i className={"las la-newspaper " + ((searchId || renderActBtn) ? "la-4x" : "la-3x")}></i></Col>
                                        <Col>
                                            <a href={data.url} target="_blank" className="f-s-15 p-b-5">{data.title}</a>
                                            <table className='table-kyc-summary'>
                                                <tbody>
                                                    <tr>
                                                        <td className="f-s-11 p-b-5">Published {moment(data.date).format(DateFormat._DATE_ONLY)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{data.snippet}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            )
                        }
                    </TabPanel>
                }

            </div>
        </CardBody>
    </Card>
}
export default KycAmlResult;