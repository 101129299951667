import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { stringIsNullOrEmpty } from 'util/Utility';
import { startsWith } from "lodash";
import parsePhoneNumber from 'libphonenumber-js'

/// <summary>
/// Author: Chris
/// </summary>
const Phone = (props) => {
    const { error, defaultValue, country, disabled, rules, ...rest } = props;

    const [populateValue, setPopulateValue] = useState(null);
    const [isValid, setValid] = useState(false);

    useEffect(() => {
        if (!stringIsNullOrEmpty(defaultValue)) setPopulateValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => { rest.control.trigger(rest.name) }, [isValid])

    return (
        <Controller
            {...rest}
            rules={{
                ...rules, validate: async (value) => {
                    if (rules?.required == true || typeof rules?.required === 'string') {
                        if (isValid && typeof rules?.validate === 'function') {
                            return await rules?.validate(value);
                        }
                        return isValid;
                    }
                    return true;
                }
            }}
            render={
                ({ onChange, value, ...otherProps }) => {
                    return (
                        <PhoneInput inputClass="form-control-md"
                            containerClass="form-control"
                            country={country}
                            onChange={(inputValue, country, countries) => {
                                onChange(inputValue, country, countries);
                            }}
                            {...otherProps}
                            value={value ? value : populateValue}
                            disabled={disabled}
                            isValid={(inputNumber, country, countries) => {

                                if (country && inputNumber) {

                                    let checkingNum = inputNumber.slice(country.dialCode.length, inputNumber.length);
                                    const phoneNumber = parsePhoneNumber(checkingNum, country.iso2.toUpperCase())

                                    if (phoneNumber) {
                                        setValid(phoneNumber.isValid());
                                    }
                                    return;
                                }
                                setValid(false);
                            }}
                        />
                    );
                }
            }
        />
    );
};

Phone.defaultProps = {
    defaultValue: null,
    country: 'hk',
}

export default Phone;