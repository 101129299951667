import { atom, selector, selectorFamily } from 'recoil';

/// <summary>
/// Author: Chris
/// </summary>
export const incorporationDetails = atom({
    key: 'incorporationDetails',
    default: {
        englishCompanyName: 'Test Company',
        chineseCompanyName: '一二三',
        businessName: '-',
        incorporationPackage: 'Package 1: No Company Secretary',
        proposedFye: '03/31',
        esaFinancialPeriod: '06/30',
        br: '3 years',
        commonSeal: 'Yes',
        shareCapitalType: 'Standard',
        maRadio: 'Standard',
        addressLine1: '1 Wang Kwong Road, Kowloon Bay',
        addressLine2: '10/F., Tower A, Billion Centre',
        postcode: '-',
        state: 'Kowloon',
        country: 'HK',
        deliveryOption: '0',
        deliveryAddressLine1: '1 Wang Kwong Road, Kowloon Bay',
        deliveryAddressLine2: '10/F., Tower A, Billion Centre',
        deliveryPostcode: '-',
        deliveryState: 'Kowloon',
        deliveryCountry: 'HK',
        propertyInvestmentCheck: 'yes',
        memberName: 'Test Member',
        memberOccupation: 'director',
        underEmploymentCheck: false,
        propertyInvestmentCheck: true,
        stockExchangeCheck: false,
        bankLoanCheck: true,
        othersCheck: false,
        regionOfBusiness: '0',
        memberName: 'Shareholder 1',
        businessRegion: 'China / Hong Kong / Singapore / Japan / Taiwan',
        companyOfficers: [
            {
                englishName: 'Test',
                chineseName: '一二三',
                position: ['Director'],
                type: 'individual',
                country: 'SG',
                contactNumber: '12345678',
                emailAddress: 'test@email.com',
            },
            {
                englishName: 'Test 1',
                chineseName: '一二三',
                position: ['Director', 'Shareholder'],
                type: 'corporate',
                country: 'SG',
                contactNumber: '12345678',
                emailAddress: 'test@email.com',
                sharePercentage: 30
            },
            {
                englishName: 'Test 2',
                chineseName: '一二三',
                position: ['Shareholder'],
                type: 'corporate',
                country: 'SG',
                contactNumber: '12345678',
                emailAddress: 'test@email.com',
                sharePercentage: 20
            },
            {
                englishName: 'Test 3',
                chineseName: '一二三',
                position: ['Director'],
                type: 'individual',
                country: 'SG',
                contactNumber: '12345678',
                emailAddress: 'test@email.com',
            }
        ],
        kycQuestions: {
            sourceOfFunds: {
                propertyInvestment: {
                    propertyLocation: ['HK', 'SG'],
                    propertyType: ['Residential', 'Commercial']
                },
                shares: {
                    sharesLocation: ['HK', 'SG'],
                    shareType: 'IPO'
                }
            },
            businessNature: {
                trading: {
                    typeOfGoods: 'Food and Drinks',
                    countryOfBusiness: ['HK', 'SG'],
                    businessDetails: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla interdum ligula in ipsum luctus iaculis. Vestibulum ac est eu sem mattis congue scelerisque vitae metus. Sed non orci nunc. Nam commodo velit euismod arcu faucibus imperdiet. Cras mattis augue eu mi faucibus, non mattis metus pharetra. Duis odio metus, tempor non scelerisque vitae, consectetur in lectus. Morbi eu finibus augue, vitae blandit sem. Cras vitae mi eu diam laoreet auctor. Pellentesque tristique enim et pretium iaculis. In eu orci eget odio consectetur laoreet vel a urna.'
                },
                shares: {
                    sharesLocation: ['HK, SG'],
                    shareType: 'Limited Companies'
                }
            }
        }
    }
});

// please remove later
export const testCompanyOfficers = atom({
    key: 'testCompanyOfficers',
    default: {
        directors: [
            {
                englishName: 'Director 1',
                chineseName: ' 测试',
                officerCountry: { label: 'Hong Kong' },
                contactNumber: '+8123456789',
                email: 'director@mail.com'
            }
        ],
        shareholders: [
            {
                englishName: 'Shareholder 1',
                chineseName: ' 测试',
                officerCountry: { label: 'Hong Kong' },
                contactNumber: '+8123456789',
                type: 'Individual',
                percentageOfOwnership: 30
            }
        ]
    }
});

/// <summary>
/// Author: Chris
/// </summary>
export const incorporationList = atom({
    key: 'incorporationList',
    default: []
});

/// <summary>
/// Author: Chris
/// </summary>
export const addIncorpDetails = atom({
    key: 'addIncorpDetails',
    default: {}
});

/// <summary>
/// Author: Lewis
/// </summary>
export const IncorporationInfo = atom({
    key: 'incorporationInfo',
    default: {
        // id: "",
        // englishName: "",
        // chineseName: "",
        // jurisdiction: { id: "", name: "", products: [], shortName: "" },
        // officers: [
        //     {
        //         chineseName: "",
        //         chineseSurname: "",
        //         dateOfBirth: "",
        //         email: "",
        //         englishName: "",
        //         englishSurname: "",
        //         formerName: "",
        //         id: "",
        //         identityType: "",
        //         nationality: "",
        //         officerTypeId: "",
        //         pNumber: "",
        //         phoneNumber: "",
        //         placeOfBirth: "",
        //         weChat: "",
        //     }
        // ],
        // shareCapitals: [],
        // useStandardShareCapitalPackage: "",
        // useIncorporationPackage: "",
        // companySecretary: {
        //     englishSurname: "A",
        //     englishName: "A",
        //     englishAliasName: "A",
        //     chineseSurname: "A",
        //     chineseName: "A",
        //     chineseAliasName: "A",
        //     formerName: "A",
        //     identityType: "A",
        //     identityNumber: "A",
        //     placeOfBirth: "A",
        //     nationality: "A",
        //     dateOfBirth: "0001-01-01T00:00:00",
        //     phoneNumber: "A",
        //     email: "A",
        //     weChat: "A",
        //     identityAttachmentId: 35,
        //     addressProofAttachmentId: 36,
        //     id: 82,
        //     officerTypeId: 1,
        //     address: {
        //         id: 0,
        //         addressType: "a",
        //         address1: "a",
        //         address2: "a",
        //         postcode: "a",
        //         state: "a",
        //         countryId: 1
        //     },
        //     pNumber: "P"
        // },
        // isInCart: false,
        // kycQuestion: {
        //     sourceOfFunds: {},
        //     natureOfBusiness: {},
        //     regionOfBusiness: null
        // },
        // registrationType: 0
    }
});

/// <summary>
/// Author: Lewis
/// </summary>
export const companyOfficersState = atom({
    key: 'companyOfficersList',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const kycQuestionState = atom({
    key: 'kycQuestion',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const companyAddressState = atom({
    key: 'address',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const companyShareCapitalsState = atom({
    key: 'shareCapitals',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const companySecretaryState = atom({
    key: 'companySecretaryState',
    default: {}
});

/// <summary>
/// Author: Lewis
/// </summary>
export const companyOfficerProfileState = atom({
    key: 'companyOfficerProfileState',
    default: {}
});

/// <summary>
/// Author: Lewis
/// </summary>
export const createOfficerFormState = atom({
    key: 'createOfficerFormState',
    default: {}
})

/// <summary>
/// Author: Lewis
/// </summary>
export const ubDescendantNodeListState = atom({
    key: 'ubDescendantNodeListState',
    default: []
})

/// <summary>
/// Author: Lewis
/// </summary>
export const ubChildrenNodeListState = atom({
    key: 'ubChildrenNodeListState',
    default: []
})