import React, { useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormBuilder from "components/form/FormBuilder";
import { InputTypes, SelectField, ApiUrl, ApiKey, WebUrl } from "util/Constant";
import { Report } from "notiflix";
import { CompanyDao } from "data";
import { Notify, Confirm } from "notiflix";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Robin
/// </summary>
const TransferInCompanyForm = ({ cancelHandler, selectedFactSheet }) => {
    const { t } = useTranslation();
    const _history = useHistory();
    const formRef = useRef();
    const [reset, setReset] = useState(false);

    const _FIELDS = [
        {
            rowOptions: { xl: 1 },
            columns: [
                {
                    label: "English Name",
                    name: "englishName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    className: "text-uppercase",
                    formGroupClass: "m",
                    rules: { required: "Company Name is required" },
                },
                {
                    label: "Chinese Name",
                    name: "chineseName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    rules: { required: false },
                },
            ],
        },
    ];

    useEffect(() => {

    }, []);

    const submitCompanyTransferIn = (formData) => {
        let dao = new CompanyDao();
        formData.jurisdictionId = selectedFactSheet?.jurisdiction?.id;
        Confirm.Show('Submit transfer-in request?',
            '',
            'Yes',
            'No',
            async () => {
                await dao.createSubmitCompanyTransferIn(formData).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        var data = response[ApiKey._API_DATA_KEY];
                        Report.Success("Success!", "You've successfully submitted a transfer in!\nYou will be redirected to incorporation page.", "Okay",
                            () => {
                                _history.push({
                                    pathname: WebUrl._INCORPORATION_ADD_PORTFOLIO, state: {
                                        data: {
                                            companyId: data.id, englishCompanyName: data.englishName, chineseCompanyName: data.chineseName
                                        }
                                    }
                                })
                            });
                    } else {
                        var message = response[ApiKey._API_MESSAGE_KEY];
                        Report.Warning('Fail', t(`api:${message}`), "Okay");
                    }
                });
            },
            () => { return });
    };

    //Submit Company Transfer In
    return (
        <>
            <ModalBody>
                <FormBuilder
                    fields={_FIELDS}
                    onSubmit={submitCompanyTransferIn}
                    formRef={formRef}
                    reset={reset}
                    resetTriggerReset={setReset}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-default" onClick={cancelHandler}>Cancel</button>
                <button type="submit" className="btn btn-themed"
                    onClick={() => {
                        formRef.current.dispatchEvent(
                            new Event("submit", { cancelable: true })
                        );
                    }}
                >Submit</button>
            </ModalFooter>
        </>
    );
};

export default TransferInCompanyForm;
