import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ApiKey, InputTypes, WebUrl, LoadingStateText, ApiUrl } from 'util/Constant';
import { Row, Col } from "reactstrap";
import Notiflix, { Block } from "notiflix";
import NavigationButton from 'components/buttons/NavigationButton';
import { useForm } from 'react-hook-form';
import InputHoc from 'components/form/InputHoc';
import AccountRegistrationDao from "data/AccountRegistrationDao.js";
import { isEmailExisted, isUsernameExisted, isPhoneNumExisted } from './RegistrationUtils';
import { stringIsNullOrEmpty, createMultiPartFormBody } from 'util/Utility';
import { CommonDao } from 'data';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

/// <summary>
/// Author: CJ(Jiann)
/// </summary>
const AddAccountantForm = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, control, watch, errors } = useForm();
    const [servicesList, setServicesList] = useState([]);
    const [expertiseList, setExpertiseList] = useState([]);
    const password = useRef({});
    const _history = useHistory();
    password.current = watch("password", "");

    const { data: expertiseJson } = useSWR([ApiUrl._API_GET_EXPERTISES_LIST, ApiKey._API_GET]);
    const { data: servicesJson } = useSWR([ApiUrl._API_GET_SERVICES_LIST, ApiKey._API_GET]);

    useEffect(() => {

        if (expertiseJson?.[ApiKey._API_SUCCESS_KEY]) {
            setExpertiseList(expertiseJson[ApiKey._API_DATA_KEY].map(
                ({ id, name }) => ({ value: id, label: name, id, name })
            ));
        }

        if (servicesJson?.[ApiKey._API_SUCCESS_KEY]) {
            setServicesList(servicesJson[ApiKey._API_DATA_KEY].map(
                ({ id, name }) => ({ value: id, label: name, id, name })
            ));
        }

    }, [expertiseJson, servicesJson]);

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    const isReferralCodeExist = async (referralCode) => {
        if (stringIsNullOrEmpty(referralCode)) {
            return true;
        }

        let dao = new AccountRegistrationDao();

        return await dao.checkReferralCode(referralCode).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                return true
            }
            return false
        })
    }

    const onSubmit = async (data) => {
        Block.Circle("#addNewAccountant", LoadingStateText._PLEASE_WAIT);

        let firmSubmitData = {};
        var servicesIdArr = new Array(), experiseIdArr = new Array();

        var servicesArr = data?.services;
        var experisesArr = data?.expertise;

        if (servicesArr) {
            servicesArr.map(x => {
                servicesIdArr.push({
                    id: x.id,
                    value: x.id,
                    label: servicesList.find((e) => { return e.id == x.id }).name
                })
            });
            firmSubmitData['firm.services'] = servicesIdArr;
            servicesIdArr.map((item, idx) => {
                firmSubmitData[`firm.services[${idx}].Id`] = item.id
            });
        }

        if (experisesArr) {
            experisesArr.map(x => {
                experiseIdArr.push({
                    id: x.id,
                    value: x.id,
                    label: expertiseList.find((e) => { return e.id == x.id }).name
                });
            });
            firmSubmitData['firm.expertise'] = experiseIdArr;
            experiseIdArr.map((item, idx) => {
                firmSubmitData[`firm.expertise[${idx}].Id`] = item.id
            });
        }
        firmSubmitData['firm.name'] = data.firmName;
        firmSubmitData['firm.practitionerName'] = data.practitionerName;
        let postForm = createMultiPartFormBody({ ...data, ...firmSubmitData });
        let dao = new AccountRegistrationDao();
        await dao.registrationThroughAdmin(postForm).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                _history.goBack();
                Notiflix.Notify.Success(t('notiflix:CREATE_ACCOUNTANT_SUCCESS'));
            } else {
                var errors = response[ApiKey._API_ERROR_KEY];
                if (errors) {
                    var firstError = errors[0];
                    if (firstError) {
                        Notiflix.Notify.Warning(errors?.detail ?? errors?.title);
                    }
                } else {
                    Notiflix.Notify.Warning(t('notiflix:CREATE_ACCOUNTANT_FAILED'));
                }
            }
        }).finally(() => Block.Remove("#addNewAccountant"));
    }
    return (<>
        <div className="panel panel-brand panel-flex" id="addNewAccountant">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot"><NavigationButton />{t("ADD_NEW_ACCOUNTANT")}</h1>
            </div>
            <form className="m-40 mb-0" style={{ maxWidth: "75%" }} onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <div className="panel-body y-scrollbar-2 h-100">
                    <div className="row">
                        <div className="col-md-6">
                            <InputHoc label='FIRM_NAME' name='firmName' inputType={InputTypes.INPUT} ref={register({ required: 'Firm Name is required.' })} error={errors?.firmName?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc formGroupClass="brand-dropdown" label='EXPERTISE' isMulti={true} selectClasses='h-auto' name='expertise' inputType={InputTypes.SELECT} control={control} options={expertiseList} rules={{ required: 'Expertise is required.' }} error={errors?.expertise?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc label='PRACTITIONER_NAME' name='practitionerName' inputType={InputTypes.INPUT} ref={register({ required: 'Practitioner Name is required.' })} error={errors?.practitionerName?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc formGroupClass="brand-dropdown" label='SERVICES' isMulti={true} selectClasses='h-auto' name='services' inputType={InputTypes.SELECT} control={control} options={servicesList} rules={{ required: 'Services is required.' }} error={errors?.services?.message} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputHoc type="input" label='NAME' name='name' inputType={InputTypes.INPUT}
                                ref={register({ required: 'Name is required.' })}
                                error={errors?.name?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc type="input" label='EMAIL' name='email' inputType={InputTypes.INPUT}
                                ref={register({
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    },
                                    validate: async value => await isEmailExisted(value) || "This email address already exists"
                                })} error={errors?.email?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc className="form-control" type="input" label='PHONE_NUMBER' name='phoneNumber' inputType={InputTypes.PHONE} control={control}
                                rules={{
                                    required: 'Phone is required.',
                                    validate: async value => await isPhoneNumExisted(value) || "This phone number already exists"
                                }} error={!stringIsNullOrEmpty(errors?.phoneNumber?.message) ? errors?.phoneNumber?.message : errors?.phoneNumber} />

                        </div>
                        {/* <div className="col-md-6">
                            <InputHoc type="input" label='USERNAME' name='username' inputType={InputTypes.INPUT}
                                ref={register({
                                    required: 'Username is required.',
                                    validate: async value => await isUsernameExisted(value) || "This username already exists"
                                })}
                                error={errors?.username?.message} />
                        </div> */}
                        {/* <div className="col-md-6">
                            <InputHoc type="password" label='PASSWORD' name='password' inputType={InputTypes.INPUT}
                                ref={register({ required: 'Password is required.' })}
                                error={errors?.password?.message} />
                        </div>
                        <div className="col-md-6">
                            <InputHoc type="password" label='RETYPE_PASSWORD' name='retypePassword' inputType={InputTypes.INPUT}
                                ref={register({
                                    validate: value => value === password.current || "The passwords do not match"
                                })} error={errors?.retypePassword?.message} />
                        </div> */}
                        {//temporary remove referral code
                            /* <div className="col-md-6">
                                <InputHoc type="input" label='REFERRAL_CODE_OPTIONAL' name='referralCode' inputType={InputTypes.INPUT}
                                    ref={register({
                                        required: false,
                                        validate: value => isReferralCodeExist(value)
                                    })} error={errors?.referralCode} />
                            </div> */
                        }
                    </div>
                </div>
                <div className="panel-footer text-center d-flex justify-content-end" style={{ backgroundColor: "transparent" }}>
                    <button type="submit" className="btn btn-themed btn-mid-long">{t("REGISTER")}</button>
                </div>
            </form>
        </div>
    </>)
}

export default AddAccountantForm;