import React, { useState, useRef, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Confirm } from 'notiflix';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import { flattenObject, formatCountryList, propertyIsUndefined } from 'util/Utility';
import { ApiKey, ApiUrl, InputTypes, KycFormKeys } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

const TRADING_COUNTRY_OPTIONS = [
    { label: 'H.K', value: 'HK' },
    { label: 'P.R.C', value: 'CN' },
    { label: 'TAIWAN', value: 'TW' },
    { label: 'OTHER', value: 'other' },
];
const PROPERTY_TYPE_OPTIONS = [
    { label: 'RESIDENTIAL', value: 'Residential' },
    { label: 'COMMERCIAL', value: 'Commercial' },
];
const SHARES_COUNTRY_OPTIONS = [
    { label: 'H.K', value: 'HK' },
    { label: 'P.R.C', value: 'CN' },
    { label: 'OTHER', value: 'other' },
];
const SHARES_TYPE_OPTIONS = [
    { label: 'LIMITED_COMPANIES', value: 'Limited Companies' },
    { label: 'IPO', value: 'IPO' },
]
const POSITION_OPTIONS = [
    { label: 'DIRECTOR', value: 'Director' },
    { label: 'MANAGER', value: 'Manager' },
    { label: 'OTHER_PLEASE_SPECIFY', value: 'other' },
];
const SERVICE_YEARS_OPTIONS = [
    { label: '1_YEARS', value: '1 year' },
    { label: '2_YEARS', value: '2 years' },
    { label: '3_YEARS', value: '3 years' },
    { label: '4_YEARS', value: '4 years' },
    { label: '5_YEARS', value: '5 years' },
    { label: '6_YEARS', value: '6 years' },
    { label: '7_YEARS', value: '7 years' },
    { label: '8_YEARS', value: '8 years' },
    { label: '9_YEARS', value: '9 years' },
    { label: '10_YEARS', value: '10 years' },
    { label: 'OTHER_DURATION', value: 'other' },
];

/// <summary>
/// Author: Chris
/// </summary>
export const OtherRegionForm = (props) => {
    let {
        defaultValues,
        setState
    } = props;

    const _fields = [
        {
            rowOptions: { md: 1 },
            columns: [
                {
                    label: 'OTHER_REGION',
                    name: 'regionOthers',
                    rules: { required: 'Other Region is required' },
                },
            ]
        }
    ];

    const { t } = useTranslation();
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ ...data });
    }

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <>
        <Row>
            <Col xl={9}>
                <FormBuilder
                    formClasses="m-l-10"
                    fields={_fields}
                    onSubmit={onSubmit}
                    formRef={formRef}
                    resetValues={valueToReset}
                    reset={reset}
                    resetTriggerReset={setReset}
                />
            </Col>
            <Col xl={3}>
                <button type="button" className="btn btn-themed btn-mid-long mt-3" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues?.regionOthers) ? t("SUBMIT") : t("UPDATE")}</button>
            </Col>
        </Row>

    </>;
}

/// <summary>
/// Author: Chris
/// </summary>
export const UnderEmploymentForm = (props) => {
    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    const _fields = [
        {
            rowOptions: { md: 2 },
            columns: [
                {
                    label: 'EMPLOYERS_NAME',
                    name: 'employerName',
                    rules: { required: 'Employer\'s Name is required.' },
                },
                {
                    label: 'EMPLOYED_POSITION',
                    name: 'employedPosition',
                    rules: { required: 'Employed Position is required.' },
                    input: InputTypes.SELECT,
                    options: POSITION_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                    watchInput: {
                        equalsTo: 'other',
                        inputType: InputTypes.INPUT,
                        name: 'employedPositionOther',
                    }
                },
                {
                    label: 'AVG_ANN_INCOME_USD',
                    name: 'averageIncome',
                    input: InputTypes.NUMBER_INPUT,
                    rules: { required: 'Average Annual Income is required.' },
                },
                {
                    label: 'EMPLOYED_SERVICE_DURATION',
                    name: 'employedDuration',
                    rules: { required: 'Employed Service Duration is required.' },
                    input: InputTypes.SELECT,
                    options: SERVICE_YEARS_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                    watchInput: {
                        equalsTo: 'other',
                        inputType: InputTypes.INPUT,
                        name: 'employedDurationOther'
                    }
                },
            ],
            seperator: true,
        },
        {
            rowOptions: { md: 2 },
            columns: [
                { label: 'ADDRESS', name: 'address1', smallnote: t('ADDRESS_SM_NOTE'), columnOptions: { md: 6 }, rules: { required: 'Address is required.' } },
                { label: 'COUNTRY', name: 'countryId', input: InputTypes.SELECT, options: countryOptions, columnOptions: { md: 6 }, rules: { required: 'Country is required.' } },
            ],
            seperator: true,
        },
        {
            rowTitle: <Col xl={3}><span>{t("NATURE_OF_BUSINESS")}</span></Col>,
            columns: [
                { label: `${t("NATURE_OF_BUSINESS")} (${t("OPTIONAL")})`, name: 'businessNatureDetail' },
            ]
        }
    ];

    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            let { address, ...restData } = defaultValues;
            setValueToReset({ ...address, ...restData });
            setReset(!reset);
        }
    }, [defaultValues]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        let { address1, countryId, ...restData } = data;
        let dataToSubmit = { ...restData, address: { address1, countryId } };
        setState({ underEmployment: dataToSubmit });
    }

    return <div className="kyc-modal-wrapper">
        <ModalBody className="kyc-modal-body">
            <FormBuilder
                fields={_fields}
                onSubmit={onSubmit}
                formRef={formRef}
                reset={reset}
                resetTriggerReset={setReset}
                resetValues={valueToReset}
                watchFields={['employedPosition', 'employedDuration']}
            />
        </ModalBody>
        <ModalFooter className="kyc-modal-footer panel-foot panel-foot-buttons">
            <button type="button" className="btn btn-themed grayscale-100 btn-mid-long" onClick={() => cancelHandler(KycFormKeys.UNDER_EMPLOYMENT)}>{t("CANCEL")}</button>
            <button type="button" className="btn btn-themed btn-mid-long" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
        </ModalFooter>
    </div>;
}

/// <summary>
/// Author: Lewis
/// </summary>
export const TradingForm = (props) => {
    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    const _fields = [
        {
            rowOptions: { md: 2 },
            columns: [
                {
                    label: 'PLEASE_SPECIFY_TYPE_OF_GOODS',
                    name: 'typeOfGoods',
                    rules: { required: 'Type of Goods is required.' },
                },
                {
                    label: 'PLEASE_SPECIFY_COUNTRY_OF_BUSINESS',
                    name: 'countryOfBusiness',
                    rules: { required: 'Country(ies) of Business is required.' },
                    input: InputTypes.CHECKBOX,
                    options: TRADING_COUNTRY_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                    watchInput: {
                        equalsTo: 'other',
                        inputType: InputTypes.INPUT,
                        name: 'countryOfBusinessOther'
                    }
                },
                {
                    label: 'PLEASE_PROVIDE_DETAIL_ON_BUSNESS_ACT_AND_OPERATION',
                    name: 'businessDetails',
                    rules: { required: 'Business Details is required.' },
                    input: InputTypes.TEXTAREA,
                    columnOptions: { md: 12 }
                },
            ]
        }
    ];

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ trading: data });
    }


    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <div className="kyc-modal-wrapper">
        <ModalBody className="kyc-modal-body">
            <FormBuilder
                fields={_fields}
                onSubmit={onSubmit}
                formRef={formRef}
                resetValues={valueToReset}
                reset={reset}
                resetTriggerReset={setReset}
                watchFields={['countryOfBusiness']}
            />
        </ModalBody>
        <ModalFooter className="panel-foot panel-foot-buttons kyc-modal-footer">
            <button type="button" className="btn btn-themed grayscale-100 btn-mid-long" onClick={() => cancelHandler(KycFormKeys.TRADING)}>{t("CANCEL")}</button>
            <button type="button" className="btn btn-themed btn-mid-long" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
        </ModalFooter>
    </div>;
}

/// <summary>
/// Author: Lewis
/// </summary>
export const PropertyInvestmentForm = (props) => {
    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();
    const _fields = [
        {
            rowOptions: { md: 2 },
            columns: [
                {
                    label: 'PROPERTIES_LOCATION',
                    name: 'propertyLocation',
                    rules: { required: 'Property(ies) Location is required.' },
                    input: InputTypes.CHECKBOX,
                    options: TRADING_COUNTRY_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                    watchInput: {
                        equalsTo: 'other',
                        inputType: InputTypes.INPUT,
                        name: 'propertyLocationOther'
                    }
                },
                {
                    label: 'TYPE_OF_PROPERTIES',
                    name: 'propertyType',
                    rules: { required: 'Type of Property(ies) is required.' },
                    input: InputTypes.CHECKBOX,
                    options: PROPERTY_TYPE_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                },
            ]
        }
    ];
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ propertyInvestment: data });
    }

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <div className="kyc-modal-wrapper">
        <ModalBody className="kyc-modal-body">
            <FormBuilder
                fields={_fields}
                onSubmit={onSubmit}
                formRef={formRef}
                resetValues={valueToReset}
                reset={reset}
                resetTriggerReset={setReset}
                watchFields={["propertyLocation"]}
            />
        </ModalBody>
        <ModalFooter className="kyc-modal-footer panel-foot panel-foot-buttons">
            <button type="button" className="btn btn-themed grayscale-100 btn-mid-long" onClick={() => cancelHandler(KycFormKeys.PROPERTY_INVESTMENT)}>{t("CANCEL")}</button>
            <button type="button" className="btn btn-themed btn-mid-long" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
        </ModalFooter>
    </div>;
};

export const StockInvestmentForm = (props) => {
    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    const _fields = [
        {
            rowOptions: { md: 2 },
            columns: [
                {
                    label: 'SHARES_OR_SECURITIES_LOCATION',
                    name: 'sharesLocation',
                    rules: { required: 'Shares or Securities Location is required.' },
                    input: InputTypes.CHECKBOX,
                    options: SHARES_COUNTRY_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                    watchInput: {
                        equalsTo: 'other',
                        inputType: InputTypes.INPUT,
                        name: 'sharesLocationOther'
                    }
                },
                {
                    label: 'TYPE_OF_SHARES_SECURITIES',
                    name: 'shareType',
                    rules: { required: 'Type of Shares / Securities is required.' },
                    input: InputTypes.CHECKBOX,
                    options: SHARES_TYPE_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}),
                },
            ]
        }
    ];

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ stockInvestment: data });
    }

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <div className="kyc-modal-wrapper">
        <ModalBody className="kyc-modal-body">
            <FormBuilder
                fields={_fields}
                onSubmit={onSubmit}
                formRef={formRef}
                resetValues={valueToReset}
                reset={reset} resetTriggerReset={setReset} watchFields={["sharesLocation"]}
            />
        </ModalBody>
        <ModalFooter className="panel-foot panel-foot-buttons kyc-modal-footer">
            <button type="button" className="btn btn-themed grayscale-100 btn-mid-long" onClick={() => cancelHandler(KycFormKeys.STOCK_INVESTMENT)}>{t("CANCEL")}</button>
            <button type="button" className="btn btn-themed btn-mid-long" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
        </ModalFooter>
    </div>;
};

export const OtherForm = (props) => {

    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    const _fields = [
        {
            rowOptions: { md: 1 },
            columns: [
                {
                    label: "PLEASE_EXPLAIN_IN_DETAIL",
                    name: 'other',
                    rules: { required: 'Field is required.' },
                    input: InputTypes.TEXTAREA,
                },
            ]
        }
    ];

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ other: data });
    }

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <Row className="w-100">
        <Col xl={9}>
            <FormBuilder
                formClasses="m-l-10"
                fields={_fields}
                onSubmit={onSubmit}
                formRef={formRef}
                resetValues={valueToReset}
                reset={reset}
                resetTriggerReset={setReset}
            />
        </Col>
        <Col xl={3}>
            <button type="button" className="btn btn-themed mt-4 btn-mid-long" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
        </Col>
    </Row>
};

/// <summary>
/// Author: Lewis
/// </summary>
export const BankLoanForm = (props) => {
    let {
        cancelHandler,
        defaultValues,
        setState,
    } = props;

    const { t } = useTranslation();
    const _fields = [
        {
            rowOptions: { md: 1 },
            columns: [
                {
                    label: 'BANK_NAME',
                    name: 'bankName',
                    rules: { required: 'Bank Name is required.' },
                },
            ]
        }
    ];
    const formRef = useRef();
    const [valueToReset, setValueToReset] = useState({});
    const [reset, setReset] = useState(false);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = (data) => {
        setState({ bankLoan: data });
    }

    useEffect(() => {
        if (!isEmpty(defaultValues)) {
            setValueToReset(defaultValues);
            setReset(!reset);
        }
    }, [defaultValues]);

    return <>
        <Row className="w-100">
            <Col xl={9}>
                <FormBuilder
                    formClasses="m-l-10"
                    fields={_fields}
                    onSubmit={onSubmit}
                    resetValues={valueToReset}
                    reset={reset}
                    resetTriggerReset={setReset}
                    formRef={formRef}
                />
            </Col>
            <Col xl={3}>
                <button type="button" className="btn btn-themed btn-mid-long m-t-15" onClick={() => submitForm(formRef)}>{isEmpty(defaultValues) ? t("SUBMIT") : t("UPDATE")}</button>
            </Col>
        </Row>
    </>;
}