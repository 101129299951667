import NavigationButton from "components/buttons/NavigationButton";
import InputHoc from "components/form/InputHoc";
import { PageSettings } from "config/page-settings";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, Route, useLocation, useHistory, useParams } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card } from "reactstrap";
import { InputTypes, SelectField, WebUrl, ApiKey, RoleType, CompanyStatus, LoadingStateText, BtnTypes, ApiUrl, DefaultCurrencyId, DefaultCurrency } from "util/Constant";
import classnames from 'classnames';
import { Report, Loading, Confirm } from 'notiflix';
import { useRecoilState, useRecoilValue } from "recoil";
import { authCredentialState, cartState } from "recoil/Atoms";
import { CompanyDao, AccountantDao, CompanyServiceDao } from "data";
import { stringIsNullOrEmpty } from "util/Utility";
import { isEmpty } from "lodash";
import ButtonRound from "components/buttons/ButtonRound";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "../../components/panel/TabPanel";
import CompanyServiceHistory from "./CompanyServiceHistory";
import useSWR from "swr";
import { useTranslation } from 'react-i18next';
import NumberFormat from "react-number-format";

/// <summary>
/// Author : Lewis
/// </summary>
const CompanyServices = (props) => {
    const { companyId } = useParams();
    const _location = useLocation();
    const _history = useHistory();

    const [cartValue, setCartState] = useRecoilState(cartState);
    const [currentAnnualPackageProductMatter, setCurrentAnnualPackageProductMatter] = useState({});
    const [serviceProductMatterList, setServiceProductMatterList] = useState([]);
    const [miscProductMatterList, setMiscProductMatterList] = useState([]);
    const [refreshCart, setRefreshCart] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({});
    const [servicesRequestCheckList, setServicesRequestCheckList] = useState([]);

    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        SERVICE_LISTS: { id: 0, title: "Service" },
        SERVICE_HISTORY: { id: 1, title: "Service History" },
    }

    let accountDao = new AccountantDao();
    let companyDao = new CompanyDao();
    let companyServiceDao = new CompanyServiceDao();

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const AddToCartHandler = async (companyId, productMatterId) => {
        await companyDao.submitNewServiceRequest(companyId, productMatterId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setRefreshCart((prevState) => !prevState);
                mutateCompanyServiceOngoing();
                Report.Success('Success!', 'Successfully add service to cart.', 'Okay');
            } else {
                var errors = response[ApiKey._API_SUCCESS_KEY];
                if (errors) {
                    var firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError.title, firstError.detail);
                    }
                } else {
                    Report.Warning("Some unknown error occured.", firstError.detail);
                }
            }
        })
    }

    const getCartList = async () => {
        await accountDao.getAccountantCart().then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY]
                setCartState(data);
            }
        })
    };

    useEffect(() => {
        getCartList();
    }, [refreshCart]);

    /// <summary>
    /// Author : Lewis
    /// </summary>
    useEffect(() => {

        if (stringIsNullOrEmpty(companyId)) {
            _history.goBack();
        }

    }, [companyId, cartValue]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: companyProfileJson } = useSWR(companyId ? [
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#product-service-matter-blocker"
    ] : null);

    const { data: currentAnnualPackageProductMatterJson } = useSWR(companyId ? [
        ApiUrl._API_GET_CURRENT_ANNUAL_PACKAGE_PRODUCT_MATTER_BY_COMPANY_ID.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#product-service-matter-blocker"
    ] : null);

    const { data: serviceProductMatterJson } = useSWR(companyId ? [
        ApiUrl._API_GET_SERVICE_PRODUCT_MATTER_LIST_BY_COMPANY_ID.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#product-service-matter-blocker"
    ] : null);

    const { data: miscProductMatterJson } = useSWR(companyId ? [
        ApiUrl._API_GET_MISC_PRODUCT_MATTER_LIST_BY_COMPANY_ID.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#product-service-matter-blocker"
    ] : null);

    const { data: companyServiceOngoingJson, mutate: mutateCompanyServiceOngoing } = useSWR(companyId ? [
        ApiUrl._API_GET_SERVICES_ONGOING_LIST.replace(":companyId", companyId),
        ApiKey._API_GET,
        null,
        "div#product-service-matter-blocker"
    ] : null);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (companyProfileJson?.[ApiKey._API_SUCCESS_KEY])
            setCompanyInfo(companyProfileJson[ApiKey._API_DATA_KEY]);

        if (currentAnnualPackageProductMatterJson?.[ApiKey._API_SUCCESS_KEY])
            setCurrentAnnualPackageProductMatter(currentAnnualPackageProductMatterJson[ApiKey._API_DATA_KEY]);

        if (serviceProductMatterJson?.[ApiKey._API_SUCCESS_KEY])
            setServiceProductMatterList(serviceProductMatterJson[ApiKey._API_DATA_KEY]);

        if (miscProductMatterJson?.[ApiKey._API_SUCCESS_KEY])
            setMiscProductMatterList(miscProductMatterJson[ApiKey._API_DATA_KEY]);

        if (companyServiceOngoingJson?.[ApiKey._API_SUCCESS_KEY])
            setServicesRequestCheckList(companyServiceOngoingJson[ApiKey._API_DATA_KEY]);

    }, [
        companyProfileJson,
        currentAnnualPackageProductMatterJson,
        serviceProductMatterJson,
        miscProductMatterJson,
        companyServiceOngoingJson
    ]);

    return (
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot mb-0"><NavigationButton />{companyInfo.englishName}'s Services</h1>
                <Tabs value={currentTab} onChange={(e, newTab) => { setCurrentTab(newTab) }} variant="scrollable">
                    <Tab label={_TABS.SERVICE_LISTS.title} />
                    <Tab label={_TABS.SERVICE_HISTORY.title} />
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2">
                <div className="table-brand-wrapper">
                    <TabPanel value={currentTab} index={_TABS.SERVICE_LISTS.id}>
                        <ServiceList
                            companyInfo={companyInfo}
                            companyId={companyId}
                            AddToCartHandler={AddToCartHandler}
                            currentAnnualPackageProductMatter={currentAnnualPackageProductMatter}
                            miscProductMatterList={miscProductMatterList}
                            serviceProductMatterList={serviceProductMatterList}
                            servicesRequestCheckList={servicesRequestCheckList}
                        />
                    </TabPanel>
                    <TabPanel value={currentTab} index={_TABS.SERVICE_HISTORY.id}>
                        <CompanyServiceHistory companyId={companyId} companyInfo={companyInfo} />
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}

const ServiceList = props => {
    let { companyInfo, companyId, AddToCartHandler,
        currentAnnualPackageProductMatter, miscProductMatterList, serviceProductMatterList, servicesRequestCheckList, ...rest } = props;

    const { t } = useTranslation();
    const _history = useHistory();
    const _location = useLocation();

    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    return (<>
        <div className="portfolio-services__container container" id="product-service-matter-blocker">
            {
                companyInfo.status === CompanyStatus._LIVE &&
                !isEmpty(currentAnnualPackageProductMatter) &&
                <Row>
                    <Col xl={12}>
                        <div className="portfolio-service">
                            <div className="service-header">
                                <img alt="" src={require("../../assets/img/ui/service-order-existing.png")} />
                                <div>
                                    <strong>Renew Annual Package {`#(${companyInfo.cNumber})`}</strong>
                                </div>
                            </div>
                            <div className="service-body">
                                <div className="service-list">
                                    <div className="service-item">
                                        <strong>
                                            {currentAnnualPackageProductMatter.name}
                                        </strong>
                                        <div className="price-wrapper">
                                            <div className="d-flex flex-column">
                                                <strong className="price">
                                                    <NumberFormat value={currentAnnualPackageProductMatter.price} thousandSeparator displayType={'text'} prefix={`${defaultCurrencyCode} `} decimalScale={2} fixedDecimalScale /> {t("PER_ANNUM")}
                                                </strong>
                                                {
                                                    currentAnnualPackageProductMatter?.quoteCurrency?.id != DefaultCurrencyId && currentAnnualPackageProductMatter?.quoteCurrency?.exchangeRate?.rate &&
                                                    <strong className="quote-currency">
                                                        <NumberFormat value={currentAnnualPackageProductMatter?.quoteCurrency?.quotePrice} thousandSeparator displayType={'text'} prefix={`≈ ${currentAnnualPackageProductMatter?.quoteCurrency?.code} `} decimalScale={2} fixedDecimalScale />
                                                    </strong>
                                                }
                                            </div>
                                            {
                                                <>
                                                    {
                                                        currentAnnualPackageProductMatter.price && servicesRequestCheckList.map(x => x.productMatterId).includes(currentAnnualPackageProductMatter.id) ?
                                                            <ButtonRound
                                                                title="On-going"
                                                                type={BtnTypes.CHECK_CIRCLE}
                                                                medium
                                                                disabled />
                                                            :
                                                            <ButtonRound
                                                                type={BtnTypes.ADD_TO_CART}
                                                                className="btn-themed"
                                                                medium
                                                                onClick={() => AddToCartHandler(companyInfo.id, currentAnnualPackageProductMatter.id)} />
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            
            <Row>
                {/* <Col xl={6}>
                    <div className="portfolio-service">
                        <div className="service-header">
                            <img src={require("../../assets/img/ui/service-update-company.svg")} />
                            <strong>Update Company Information</strong>
                        </div>
                        <div className="service-body">
                            <div className="service-list">
                                {
                                    serviceProductMatterList.map((element, index) => (
                                        <div key={index} className="service-item row">
                                            <Col xl={8}>
                                                <strong>{element.name}</strong>
                                            </Col>
                                            <Col xl={2}>
                                                <div className="m-r-10">
                                                    <strong>
                                                        <NumberFormat className="text-theme-1" value={(element.price).toFixed(2)} thousandSeparator displayType={'text'} prefix="HKD $" />
                                                    </strong>
                                                </div>
                                            </Col>
                                            <Col xl={2} className="btn-list-wrapper d-flex justify-content-end">
                                                {
                                                    companyInfo.status == CompanyStatus._LIVE
                                                    &&
                                                    <>
                                                        {
                                                            servicesRequestCheckList.map(x => x.productMatterId).includes(element.id) ?
                                                                <>
                                                                    <ButtonRound
                                                                        title="On-going"
                                                                        className="btn-info btn-flat-icon m-r-5"
                                                                        type={BtnTypes.CHECK_CIRCLE}
                                                                        small
                                                                        disabled={companyInfo.status != CompanyStatus._LIVE} />

                                                                    {(() => {
                                                                        let requestedServices = servicesRequestCheckList.filter(x => x.productMatterId == element.id);
                                                                        // means service on-going or in review
                                                                        if (requestedServices.length > 0) {

                                                                            let serviceRequestId = requestedServices[0].id;
                                                                            let isRejected = requestedServices[0].isRejected;

                                                                            let buttonProps = {
                                                                                title: isRejected ? "Edit" : "View",
                                                                                type: isRejected ? BtnTypes.EDIT : BtnTypes.FA_VIEW,
                                                                                onClick: () => {

                                                                                    let propsToPass = {
                                                                                        companyId, serviceName: element.name, productMatterId: element.id, isRejected: isRejected,
                                                                                        serviceRequestId: serviceRequestId
                                                                                    };

                                                                                    _history.push({
                                                                                        pathname: isRejected ? WebUrl._EDIT_COMPANY_SERVICES_REQUEST : WebUrl._VIEW_COMPANY_SERVICES_REQUEST,
                                                                                        state: propsToPass
                                                                                    });
                                                                                }
                                                                            }
                                                                            return <ButtonRound {...buttonProps} medium />
                                                                        }
                                                                    })()}
                                                                </>
                                                                :
                                                                <ButtonRound
                                                                    title="Proceed"
                                                                    type={BtnTypes.DOUBLE_RIGHT_PROCEED}
                                                                    medium
                                                                    disabled={companyInfo.status != CompanyStatus._LIVE}
                                                                    onClick={() => {
                                                                        _history.push({
                                                                            pathname: WebUrl._UPDATE_COMPANY_INFO_SERVICES,
                                                                            state: { companyId, serviceName: element.name, productMatterId: element.id }
                                                                        });
                                                                    }} />
                                                        }
                                                    </>
                                                }
                                            </Col>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Col> */}
                <Col xl={12}>
                    <div className="portfolio-service">
                        <div className="service-header">
                            <img alt="" src={require("../../assets/img/ui/service-order-existing.png")} />
                            <div>
                                <strong>Order Existing Business Information</strong>
                                {companyInfo.status == CompanyStatus._LIVE && `#(${companyInfo.cNumber})`}
                            </div>
                        </div>
                        <div className="service-body">
                            <div className="service-list">
                                {
                                    !isEmpty(miscProductMatterList) ?
                                        miscProductMatterList.map((element, index) => (
                                            <div className="service-item" key={index}>
                                                <strong>{element.name}</strong>
                                                <div className="price-wrapper">
                                                    <div className="d-flex flex-column">
                                                        <strong className="price">
                                                            <NumberFormat value={element.price} thousandSeparator displayType={'text'} prefix={`${defaultCurrencyCode} `} decimalScale={2} fixedDecimalScale />
                                                        </strong>
                                                        {
                                                            element?.quoteCurrency?.id != DefaultCurrencyId && element?.quoteCurrency?.exchangeRate?.rate &&
                                                            <strong className="quote-currency">
                                                                <NumberFormat value={element?.quoteCurrency?.quotePrice} thousandSeparator displayType={'text'} prefix={`≈ ${element?.quoteCurrency?.code} `} decimalScale={2} fixedDecimalScale />
                                                            </strong>
                                                        }
                                                    </div>
                                                    {
                                                        <>
                                                            {
                                                                element.price && servicesRequestCheckList.map(x => x.productMatterId).includes(element.id) ?
                                                                    <ButtonRound
                                                                        title="On-going"
                                                                        type={BtnTypes.CHECK_CIRCLE}
                                                                        medium
                                                                        disabled />
                                                                    :
                                                                    <ButtonRound
                                                                        type={BtnTypes.ADD_TO_CART}
                                                                        className="btn-themed"
                                                                        medium
                                                                        onClick={() => AddToCartHandler(companyInfo.id, element.id)} />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="text-center">
                                            <img src={require("../../assets/img/ui/graphic-nodatafound.svg")} />
                                            <p className="no-found-text-pos-absolute-bottom">{t("NO_DATA_AVAILABLE")}</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </>)
}

export default CompanyServices;
