import React, { Fragment, useCallback, useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Card, CardBody, Col, UncontrolledTooltip, Collapse, UncontrolledCollapse } from 'reactstrap';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import { WebUrl, InputTypes, Status, SelectField, ApiUrl, ApiKey, CompanyStatus, CompanyNameCheckStatus, AnnualComplianceRuleType, DateFormat, CompanyRegistrationType, BtnTypes, Icon, TableId } from 'util/Constant';
import { useRecoilValue, useRecoilState } from 'recoil';
import { companyCurrentTab, cartState, userIdSelector } from "recoil/Atoms";
import moment from 'moment';
import Notiflix, { Notify } from 'notiflix';
import ButtonRound from 'components/buttons/ButtonRound';
import { getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty, cleanObject, formatJurisdictionList, CompanyStatusToTranslateKey } from 'util/Utility';
import CompanyStatusIcon from 'components/icon-tooltip/CompanyStatusIcon';
import { useTranslation } from 'react-i18next';
import { PageSettings } from 'config/page-settings';
import classNames from 'classnames';
import { ReactTablev2 as ReactTable, CustomPaginationFooter } from 'components/react-table';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { RenewalList } from 'pages/renewal';
import { useResizeDetector } from 'react-resize-detector';

/// <summary>
/// Author : Nicholas
/// </summary>
const CompanyManagementListing = (props) => {

    const { t } = useTranslation();
    const containerRef = useRef(null);
    const menuTabRef = useRef(null);
    const [registeringCompanyCount, setRegisteringCompanyCount] = useState(0);
    const [liveCompanyCount, setLiveCompanyCount] = useState(0);
    const [companyCount, setCompanyCount] = useState(0);
    const [renewalCount, setRenewalCount] = useState(0);
    const [currentTab, setCurrentTab] = useRecoilState(companyCurrentTab);
    const _TAB_KEYS = {
        REGISTERING: "Registering",
        LIVE: "Live",
        ALL: "All",
        RENEWAL: "Renewal",
    }
    const _TABS = {
        [_TAB_KEYS.ALL]: { id: 0, title: "ALL", count: companyCount },
        [_TAB_KEYS.LIVE]: { id: 1, title: "LIVE", count: liveCompanyCount },
        [_TAB_KEYS.REGISTERING]: { id: 2, title: "REGISTERING", count: registeringCompanyCount },
        [_TAB_KEYS.RENEWAL]: { id: 3, title: "RENEWAL_LIST", count: renewalCount },
    }
    const {
        isXsDevices,
        isMdDevices,
        isLgDevices,
        isXlDevices
    } = useContext(PageSettings);
    const [showMenuTab, setShowMenuTab] = useState(true);

    const toggle = useCallback((index) => { setCurrentTab(index) }, []);
    const retrieveRegisteringCompanyCount = useCallback((tableData, totalCount) => { setRegisteringCompanyCount(totalCount) }, []);
    const retrieveLiveCompanyCount = useCallback((tableData, totalCount) => { setLiveCompanyCount(totalCount) }, []);
    const retrieveCompanyCount = useCallback((tableData, totalCount) => { setCompanyCount(totalCount) }, []);
    const retrieveRenewalCount = useCallback((tableData, totalCount) => { setRenewalCount(totalCount) }, []);

    const { tableMinWidth, tableMinHeight, renewalTableMinHeight } = useMemo(() => {
        //containerHeight - React table's container height(include filter, table body & tale footer)
        //menuTabHeight - Menu tab height(able to hide when toggle) -> use to minus with container height to determine react table's height
        let containerHeight = containerRef.current?.clientHeight;
        let menuTabHeight = menuTabRef.current?.clientHeight;

        let minusTableHeightForLargerView = 0;
        if (isMdDevices ||isLgDevices) {
            minusTableHeightForLargerView = 150;
        }
        else {
            minusTableHeightForLargerView = 66.5;
        }

        //tableHeight - Use to set react table's body height
        //renewalTableHeight - Use to set REnewal List react table's body height
        let tableHeight = isXsDevices? ( (showMenuTab ? (containerHeight - (menuTabHeight === 0 ? 185 : menuTabHeight)) : (containerHeight))) + 25 : containerHeight - minusTableHeightForLargerView;
        let renewalTableHeight = showMenuTab ? (tableHeight + (menuTabHeight === 0 ? menuTabHeight : 20)) : tableHeight + 25;
        return {
            tableMinHeight: tableHeight,
            tableMinWidth: containerRef.current?.clientWidth,
            renewalTableMinHeight: renewalTableHeight
        }
    }, [containerRef.current, menuTabRef.current, showMenuTab]);
    
    return (
        <div className="panel panel-brand panel-flex" id="CompanyManagement">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("COMPANY_MANAGEMENT")}</h1>
                <ol className="breadcrumb float-xl-right">
                    <Link className="btn btn-sm btn-themed mb-2" style={{ padding: '7px 20px' }} to={WebUrl._ADD_NEW_COMPANY}>{t("ADD_NEW_COMPANY")}</Link>
                </ol>
            </div>
            {
                isXsDevices &&
                <div className="d-flex justify-center" onClick={() => {setShowMenuTab(!showMenuTab);}}>
                    {
                        showMenuTab ?
                        <i class="las la-angle-double-up"></i>
                        :
                        <i class="las la-angle-double-down"></i>
                    }
                </div>
            }
            <div className={classNames("panel-body h-100")}>
                <div className={classNames('panel-body-inner-content with-btm-br-radius')}>
                    <div className="d-flex flex-column h-100"
                        style={{ borderTopRightRadius: 16, WebkitBorderTopLeftRadius: 16 }}
                    >
                        
                        <div className="d-block" ref={menuTabRef}>
                            <Collapse isOpen={showMenuTab || !isXsDevices}>
                                <div className="m-0 row" className="management-tabs">
                                    <Row>
                                        {
                                            Object.keys(_TABS).map((key, index) => {
                                                return (<Fragment key={index}>
                                                    <Col className="p-0">
                                                        <div className={`${currentTab == index ? "active" : ""} card-header`} onClick={() => {
                                                            toggle(index);
                                                        }}>
                                                            <h4 style={{ whiteSpace: 'nowrap' }}>{t(_TABS[key].title)} {"(" + _TABS[key].count + ")"}</h4>
                                                        </div>
                                                    </Col>
                                                </Fragment>);
                                            })
                                        }
                                    </Row>
                                </div>
                            </Collapse>
                        </div>
                        
                        <div className="flex-grow-1" ref={containerRef}>
                            <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.ALL].id}>
                                <RenderTable
                                    fetchOnSuccess={retrieveCompanyCount}
                                    companyCount={companyCount}
                                    tableMinHeight={tableMinHeight}
                                    tableMinWidth={tableMinWidth}
                                    companyStatus={CompanyStatus._ALL}
                                    currentTab={currentTab}
                                />
                            </Collapse>
                            <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.LIVE].id}>
                                <RenderTable
                                    companyStatus={CompanyStatus._LIVE}
                                    fetchOnSuccess={retrieveLiveCompanyCount}
                                    companyCount={liveCompanyCount}
                                    tableMinHeight={tableMinHeight}
                                    tableMinWidth={tableMinWidth}
                                    currentTab={currentTab}
                                />
                            </Collapse>
                            <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.REGISTERING].id}>
                                <RenderTable
                                    companyStatus={CompanyStatus._REGISTERING}
                                    fetchOnSuccess={retrieveRegisteringCompanyCount}
                                    companyCount={registeringCompanyCount}
                                    tableMinHeight={tableMinHeight}
                                    tableMinWidth={tableMinWidth}
                                    currentTab={currentTab}
                                />
                            </Collapse>
                            <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.RENEWAL].id} className="h-100">
                                <RenewalList
                                    tabId={_TABS[_TAB_KEYS.RENEWAL].id}
                                    retrivedCallback={retrieveRenewalCount}
                                    tableMinHeight={renewalTableMinHeight}
                                    tableMinWidth={tableMinWidth}
                                />
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

/// <summary>
/// Author : Sze Hua
/// </summary>
const RenderTable = React.forwardRef((props, ref) => {

    let {
        companyStatus,
        fetchOnSuccess,
        tableMinHeight,
        tableMinWidth,
        companyCount,
        currentTab,
    } = props;

    const {
        isXsDevices,
        isSmDevices,
    } = useContext(PageSettings);

    const { t } = useTranslation();
    const cartList = useRecoilValue(cartState);
    const userId = useRecoilValue(userIdSelector);
    const _history = useHistory();
    const _formRef = useRef(null);
    const _tableRef = useRef(null);
    const _FYE_APPLICABLE_JURISDICTION = ["SG"];
    const _AGM_APPLICABLE_JURISDICTION = ["SG"];
    const [filtersForm, setFiltersForm] = useState({});
    const [resetFilters, setResetFilters] = useState(false);
    const { height: filtersHeight, ref: filtersRef } = useResizeDetector();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    const _SERVICE_STATUS = [
        { label: 'Ongoing', value: 'false' },
        { label: 'Completed', value: 'true' }
    ]

    const _FORM_FIELDS = useMemo(() => [
        {
            rowOptions: { xl: 3 },
            columns: [
                { prefix: { icon: Icon._PROFILE }, label: `${t('SEARCH_ACCOUNTANT')}`, placeholder: `${t('SEARCH_ACCOUNTANT')}`, name: 'accountantName', smallnote: `${t("NAME")}, ${t("FIRM_NAME")} ${t("ETC")}`, input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                { prefix: { icon: Icon._SEARCH_COMPANY }, label: `${t('SEARCH_COMPANY_NAME')}`, placeholder: `${t('SEARCH_COMPANY_NAME')}`, name: 'englishName', smallnote: `${t("C_NUMBER")}, ${t("COMPANY_NAME")}, CI ${t("ETC")}`, columnOptions: { xl: 3, md: 6 } },
                { prefix: { icon: Icon._SEARCH_JURISDICTION }, label: `${t('SEARCH_JURISDICTION')}`, placeholder: `${t('SEARCH_JURISDICTION')}`, name: 'jurisdictionId', input: InputTypes.SELECT, options: jurisdictionOptions, columnOptions: { xl: 3, md: 6 } },
                // { prefix: { icon: Icon._SELECT_ACTIVITY }, label: 'Service Status', placeholder: t('SERVICE_STATUS'), name: 'isCompleted', input: InputTypes.SELECT, options: _SERVICE_STATUS, columnOptions: { xl: 3, md: 6 } },
            ]
        }
    ], [jurisdictionOptions]);

    const _COLUMNS = useMemo(() => {

        switch (companyStatus) {
            case CompanyStatus._REGISTERING:
                return [
                    {
                        Header: "ACCOUNTANT", accessor: "requestor.name", Cell: ({ row }) => {
                            return <span>{row.original.requestor.name}</span>
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'COMPANY_NAME',
                        accessor: el => el.englishName + (el.chineseName ?? ""),
                        Cell: ({ row }) => {
                            return (
                                <div>
                                    <div className="m-b-5 nowrap text-overflow-ellipsis">
                                        <span className="badge badge-purple">EN</span>
                                        <span id={`comp-name-ctrl-tooltip-${companyStatus}-${row.original.id}`} style={{ paddingLeft: '3%' }}>{row.original.englishName}</span>
                                    </div>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`comp-name-ctrl-tooltip-${companyStatus}-${row.original.id}`}>{row.original.englishName}</UncontrolledTooltip>
                                    {
                                        !stringIsNullOrEmpty(row.original?.chineseName) && <>
                                            <div className="nowrap">
                                                <span className="badge badge-light-green">CN</span>
                                                <span style={{ paddingLeft: '3%' }}>{row.original.chineseName}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        },
                        canResize: true,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "JURISDICTION", accessor: (el) => el.jurisdiction.name,
                        Cell: ({ row }) => {
                            return (
                                <>
                                    <div className="d-flex">
                                        <div id={`${row.original.jurisdiction.shortName}_${companyStatus}_${row.original.id}`}>
                                            <img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(row.original.jurisdiction.name)} />
                                            <span>{row.original.jurisdiction.name}</span>
                                        </div>
                                    </div>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`${row.original.jurisdiction.shortName}_${companyStatus}_${row.original.id}`}>{row.original.jurisdiction.name}</UncontrolledTooltip>
                                </>
                            )
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "COMPANY_STATUS", accessor: "status",
                        Cell: ({ row }) => {
                            return (
                                <div className="d-flex">
                                    <CompanyStatusIcon companyStatus={row.original?.status} stateId={row.original?.processStateId} className="mr-2" />
                                    {row.original?.statusName}
                                </div>)
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'ACTION',
                        Cell: ({ row }) => {
                            // Namecheck must be completed before able to do the rest
                            if (row.original?.onGoingNameCheck && row.original?.onGoingNameCheck?.status !== CompanyNameCheckStatus._APPROVED) {
                                return (<></>);
                            }

                            return (
                                <div className="btn-list-wrapper d-flex">
                                    {
                                        (row.original.status === CompanyStatus._LIVE || (row.original.status === CompanyStatus._REGISTERING && row.original.incorporationRequest)) &&
                                        <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => { viewPortfolio(row.original); }} />
                                    }
                                    {
                                        (row.original.status === CompanyStatus._REGISTERING && !row.original.incorporationRequest) &&
                                        <ButtonRound type={BtnTypes.DOUBLE_RIGHT} medium
                                            onClick={() => { continueIncorporation(row.original); }} />
                                    }
                                </div>
                            )
                        },
                        disableFilters: true,
                        disableSortBy: true,
                        style: { alignSelf: 'center' }
                    }
                ]
                break;

            default:
                return [
                    {
                        Header: "ACCOUNTANT", accessor: "requestor.name",
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "C#", accessor: "cNumber",
                        Cell: ({ row }) => <span>{row.original.cNumber ? row.original.cNumber : '-'}</span>,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'COMPANY_NAME',
                        accessor: el => el.englishName + (el.chineseName ?? ""),
                        Cell: ({ row }) => {
                            return (
                                <div>
                                    <div className="m-b-5 nowrap text-overflow-ellipsis">
                                        <span className="badge badge-purple">EN</span>
                                        <span id={`comp-name-ctrl-tooltip-${companyStatus}-${row.original.id}`} style={{ paddingLeft: '3%' }}>{row.original.englishName}</span>
                                    </div>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`comp-name-ctrl-tooltip-${companyStatus}-${row.original.id}`}>{row.original.englishName}</UncontrolledTooltip>
                                    {
                                        !stringIsNullOrEmpty(row.original?.chineseName) && <>
                                            <div className="nowrap">
                                                <span className="badge badge-light-green">CN</span>
                                                <span style={{ paddingLeft: '3%' }}>{row.original.chineseName}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            )
                        },
                        style: { width: 200 },
                        isRequiredColumn: true,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "JURISDICTION", accessor: (el) => el.jurisdiction.name,
                        Cell: ({ row }) => {
                            return (
                                <>
                                    <div className="d-flex">
                                        <div id={`${row.original.jurisdiction.shortName}_${companyStatus}_${row.original.id}`}>
                                            <img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(row.original.jurisdiction.name)} />
                                            <span>{row.original.jurisdiction.name}</span>
                                        </div>
                                    </div>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`${row.original.jurisdiction.shortName}_${companyStatus}_${row.original.id}`}>{row.original.jurisdiction.name}</UncontrolledTooltip>
                                </>
                            )
                        },
                        style: { width: 110 },
                        isRequiredColumn: true,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "STATUS", accessor: "status",
                        Cell: ({ row }) => {
                            return (
                                <div className="d-flex">
                                    <CompanyStatusIcon companyStatus={row.original?.status} stateId={row.original?.processStateId} className="mr-2" />
                                    {t(`${CompanyStatusToTranslateKey(row.original?.status)}`)}
                                </div>
                            )
                        },
                        style: { width: 110 },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'INCORPORATION_DATE',
                        accessor: (el) => (el?.status >= CompanyStatus._LIVE) ? el?.incorporationDate : "",
                        Cell: ({ row }) => {
                            return <span className="f-s-12">{((row.original.status >= CompanyStatus._LIVE) ? moment(row.original.incorporationDate).format(DateFormat._DATE_ONLY) : '-')}</span>
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'CI#',
                        accessor: (el) => (el.status >= CompanyStatus._LIVE) ? el?.corporateIdentityNumber : '',
                        Cell: ({ row }) => {
                            return <span className="f-s-12">{((row.original.status >= CompanyStatus._LIVE) ? row.original.corporateIdentityNumber : '-')}</span>
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "FYE",
                        accessor: (el) => {
                            if (el.status == CompanyStatus._REGISTERING) {
                                if (_FYE_APPLICABLE_JURISDICTION.includes(el?.jurisdiction?.shortName)) {
                                    if (el?.proposedFinancialYearEndMonth)
                                        return moment(el.proposedFinancialYearEndMonth, 'MM').format('MMMM');
                                }
                            } else {
                                if (el?.financialYearEndMonth && el?.financialYearEndDay) {
                                    var month = el?.financialYearEndMonth - 1;
                                    var day = el?.financialYearEndDay;
                                    return moment().month(month).date(day).format(DateFormat._MONTH_DAY_ONLY);
                                }
                            }
                            return "-";
                        },
                        Cell: ({ row, value }) => {
                            if (row.original.status == CompanyStatus._REGISTERING) {
                                if (_FYE_APPLICABLE_JURISDICTION.includes(row.original?.jurisdiction?.shortName)) {
                                    if (row.original?.proposedFinancialYearEndMonth) {
                                        return (<span>
                                            {value}
                                            {row.original.status == CompanyStatus._REGISTERING && <span className="badge badge-warning">Proposed</span>}
                                        </span>)
                                    }
                                }
                            }

                            return value;
                        },
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "NEXT_AGM",
                        accessor: (el) => {
                            if (_AGM_APPLICABLE_JURISDICTION.includes(el?.jurisdiction?.shortName) && el?.companyAnnualCompliances) {
                                var agm = el?.companyAnnualCompliances.find(d => d.annualComplianceRule?.annualComplianceRuleTypeId === AnnualComplianceRuleType._AGM);
                                if (agm != undefined) {
                                    return moment(agm.systemDeadline).format(DateFormat._DATE_ONLY);
                                };
                            }

                            return "-";
                        },
                        isDefaultHidden: true,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: "NEXT_AR",
                        accessor: el => {
                            if (el?.companyAnnualCompliances) {
                                var annualReturn = el?.companyAnnualCompliances.find(d => d.annualComplianceRule?.annualComplianceRuleTypeId === AnnualComplianceRuleType._AR);
                                if (annualReturn != undefined) {
                                    return moment(annualReturn.systemDeadline).format(DateFormat._DATE_ONLY);
                                };
                            }

                            return "-";
                        },
                        isDefaultHidden: true,
                        style: { alignSelf: 'center' }
                    },
                    {
                        Header: 'ACTION',
                        Cell: ({ row }) => {
                            // Namecheck must be completed before able to do the rest
                            if (row.original?.onGoingNameCheck && row.original?.onGoingNameCheck?.status !== CompanyNameCheckStatus._APPROVED) {
                                return (<></>);
                            }

                            return (
                                <div className="btn-list-wrapper d-flex">
                                    {
                                        (row.original.status === CompanyStatus._LIVE || (row.original.status === CompanyStatus._REGISTERING && row.original.incorporationRequest)) &&
                                        <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => { viewPortfolio(row.original); }} />
                                    }
                                    {
                                        (row.original.status === CompanyStatus._REGISTERING && !row.original.incorporationRequest) &&
                                        <ButtonRound type={BtnTypes.DOUBLE_RIGHT} medium
                                            onClick={() => { continueIncorporation(row.original); }} />
                                    }
                                </div>
                            )
                        },
                        disableFilters: true,
                        disableSortBy: true,
                        isRequiredColumn: true,
                        style: { alignSelf: 'center' }
                    }
                ]
                break;
        }
    }, [companyStatus, cartList.length]);

    /// <summary>
    /// Author : Robin
    /// Go to portfolio
    /// </summary>
    const goTo = (company) => {
        let { status, incorporationRequest, onGoingNameCheck, ...restCompProps } = company;

        switch (true) {

            /// namecheck priority higher
            case onGoingNameCheck?.status === CompanyNameCheckStatus._PENDING:
                Notiflix.Notify.Warning(t("NAME_CHECK_STILL_PENDING"));
                break;

            case onGoingNameCheck?.status === CompanyNameCheckStatus._REJECTED:
                Notiflix.Notify.Warning(t("NAME_CHECK_HAS_BEEN_REJECTED"));
                break;

            case status === CompanyStatus._REJECTED:
                Notiflix.Notify.Warning(t("COMPANY_HAS_BEEN_REJECTED"));
                break;

            case status === CompanyStatus._REGISTERING && !company.incorporationRequest:
                continueIncorporation(company);
                break;

            default:
                viewPortfolio(company);
                break;

        }
    }

    const viewPortfolio = (company) => {
        let companyId = company?.id;
        _history.push({
            pathname: WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(":companyId", companyId),
            state: {
                pageTitle: "Company Portfolio"
            }
        })
    }

    const continueIncorporation = (company) => {
        let companyId = company?.id;
        let companyProcessStateId = company?.processStateId
        _history.push({
            pathname: WebUrl._COMPANY_MANAGEMENT_EDIT_INCORPORATION, state: {
                companyId,
                companyProcessStateId,
            }
        })
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }
        setResetFilters(!resetFilters);
        setFiltersForm({});

    };

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const filterOnSubmitHandler = (value) => setFiltersForm(value);

    const [isFilterOpen, setFilterOpen] = useState(false);

    return <div className="d-flex flex-column h-100">
        <div className="filter-panel-container border-radius-none" ref={filtersRef}>
            {
                (isXsDevices || isSmDevices) ? (
                    <div className="filter-panel-container border-radius-none">
                        <Row className="filter-panel-header">
                            <div className="filter-panel-header-content">
                                <div className="btn btn-light btn-filter" onClick={() => setFilterOpen(prevState => !prevState)}>
                                    <img alt="" className="mr-2" src={require("../../assets/img/icon/filter.svg")} />{t("FILTER")}
                                </div>
                                {
                                    isFilterOpen &&
                                    <div className="d-flex btn-list">
                                        <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => {submitForm(_formRef); setFilterOpen(prevState => !prevState);}}><i className="las la-search" /></button>
                                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                    </div>
                                }
                            </div>
                        </Row>
                        <Collapse isOpen={isFilterOpen}>
                            <Row className="filter-panel-body p-5">
                                <Col xl={12}>
                                    <FormBuilder
                                        formClasses="table-search-forms"
                                        fields={_FORM_FIELDS}
                                        formRef={_formRef}
                                        onSubmit={filterOnSubmitHandler}
                                        reset={resetFilters}
                                        resetTriggerReset={setResetFilters}
                                        resetValues={{}}
                                    />
                                </Col>
                            </Row>
                        </Collapse>
                    </div>
                ) :
                    <Row className="filter-panel-body">
                        <Col xl={11}>
                            <FormBuilder
                                fields={_FORM_FIELDS}
                                formRef={_formRef}
                                onSubmit={filterOnSubmitHandler}
                                reset={resetFilters}
                                resetTriggerReset={setResetFilters}
                                resetValues={{}}
                            />
                        </Col>
                        <Col xl={1} className="btn-list d-flex">
                            <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => submitForm(_formRef)}><i className="las la-search" /></button>
                            <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                        </Col>
                    </Row>
            }
        </div>
        <div className="flex-grow-1">
            <div className="d-flex flex-column h-100">
                <ReactTable
                    ref={_tableRef}
                    columns={_COLUMNS}
                    filterFormfield={filtersForm}
                    url={ApiUrl._API_RETRIEVE_COMPANIES}
                    tableMinHeight={tableMinHeight}
                    initQueryProps={{ status: companyStatus }}
                    getRowProps={(row) => {
                        let props = row.getRowProps(row);
                        return {
                            ...props,
                            onDoubleClick: () => {
                                goTo(row.original);
                            }
                        }
                    }}
                    customButtons={
                        companyCount ?
                            <Link className="brand-anchor"
                                to={`${ApiUrl._API_COMPANY_MANAGEMENT_EXPORT_EXCEL}?${new URLSearchParams(companyStatus ? { status: companyStatus, ...(cleanObject(filtersForm)), pageSize: companyCount }
                                    : { ...(cleanObject(filtersForm)), pageSize: companyCount })}`}
                                target={"_parent"} download>
                                <ButtonRound medium style={{ lineHeight: '0px' }} type={BtnTypes.FILE_DOWNLOAD} title="Download Excel" />
                            </Link>
                            : ""
                    }
                    fetchOnSuccess={(json, count) => fetchOnSuccess(json, count)}
                    tableColumnPreference={[userId, `${TableId._COMPANY_MANAGEMENT}-${companyStatus}`]}
                >
                </ReactTable>
            </div>
        </div>
    </div>
});

export default CompanyManagementListing;
