import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { OTPKey } from 'util/Constant';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Andrew
/// </summary>
const OtpModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal size="lg" className="modal-brand modal-login-otp" isOpen={props.isOpen} centered>
            <ModalHeader>
                <button className="btn btn-close-modal" onClick={() => props.toggler(false)}><i className="las la-times"></i></button>
            </ModalHeader>
            <ModalBody>
                <div className="otp-wrapper">
                    <div className="d-lg-block">
                        <img className="mr-5" src={props.mode == OTPKey._SMS ? require("../../assets/img/ui/sms-selected.png") : require("../../assets/img/ui/email-selected.png")} alt="" />
                    </div>
                    <div className="otp-info">
                        {props.children}
                        <OtpInput
                            containerStyle={"otp-input-container"}
                            value={props.value}
                            onChange={props.onChange}
                            numInputs={props.inputLength}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            focusStyle="red"
                            hasErrored={true}
                        />
                        {props.attemptNum !== 0 ? (
                            <div>{`${t('DIDNT_RECIEVE_OTP')}?`}<button type="button" onClick={props.onResend} className="btn btn-link" style={{ padding: '0 0.75rem' }}>{t('RESENT_OTP')}</button></div>
                        ) : (
                            <div style={{ padding: '1px 0' }}>{t('PLEASE_WAIT')} <b>{props.remainSeconds}</b> {t('SECONDS_TO_RESENT_OTP')}</div>
                        )}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default OtpModal;