import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class NotificationDao extends DataAccessObject {

    async getNotifications(filters) {
        let url = ApiUrl._API_GET_NOTIFICATIONS + "?" + new URLSearchParams(filters);
        return this.get(url);
    }
    async readNotifications(ids) {
        return this.post(ApiUrl._API_READ_NOTIFICATIONS, ids);
    }
    async totalUnreadNotifications() {
        return this.get(ApiUrl._API_TOTAL_UNREAD_NOTIFICATIONS);
    }
    async deleteNotifications(ids) {
        return this.post(ApiUrl._API_DELETE_NOTIFICATIONS, ids);
    }
    async deleteSingleNotification(id) {
        return this.post(ApiUrl._API_DELETE_NOTIFICATION.replace(":id", id));
    }
}

export default NotificationDao;
