import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Row, Col, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Notiflix from "notiflix";
import { InputTypes, ApiKey, ApiUrl, deleteConfirm, matterType } from "util/Constant";
import { formatJurisdictionList, stringIsNullOrEmpty } from "util/Utility.js";
import { useForm, useFieldArray} from "react-hook-form";
import InputHoc from "components/form/InputHoc";
import { WorkflowDao, CommonDao } from 'data';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useSWR from "swr";


///<summary>
///Author: Ong Sze Hua
///</summary>
const Workflow = props => {
    const { modal, setModal, jurisdictionId, productMatterId, productMatterName } = props;
    const [workflowOpts, setWorkflowOpts] = useState([]);
    const [workflowSequence, setWorkflowSequence] = useState([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState();
    const [currInd, setCurrInd] = useState();
    const [newWorkflow, setNewWorkflow] = useState();
    const [viewWorkflowTaskModal, setViewWorkflowTaskModal] = useState(false);
    const [createEditWorkflowModal, setCreateEditWorkflowModal] = useState(false);
    const _formRef = useRef();

    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const { fields, append, remove, move } = useFieldArray(
        {
            control,
            name: "workflowSequence"
        }
    );

    const _selectedWorkflowList = watch(`workflowSequence`)?.map(d => d.workflowId);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => {
        setModal(!modal);
        remove();
    };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (modal) {
            getWorkflowSequence();
        }
    }, [modal]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (workflowSequence)
            reset({ workflowSequence: workflowSequence });
    }, [workflowSequence]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (!createEditWorkflowModal) {
            getWorkflowsOpts();
        }
    }, [createEditWorkflowModal]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    async function getWorkflowsOpts() {
        let workflowDao = new WorkflowDao();
        await workflowDao.getWorkflowsByJurisdiction(jurisdictionId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                let temp = jsonResponse[ApiKey._API_DATA_KEY];
                temp = temp.map(d => { return { label: d.name, value: d.id, tasks: d.tasks, ...d } });
                setWorkflowOpts(temp);
            }
        });
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    async function getWorkflowSequence() {
        let workflowDao = new WorkflowDao();
        await workflowDao.getWorkflowByJurisdictionProductMatter(jurisdictionId, productMatterId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                let temp = jsonResponse[ApiKey._API_DATA_KEY];
                setWorkflowSequence(temp);
            }
        });
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        let workflowDao = new WorkflowDao();
        (async () => {
            await workflowDao.updateJurisdictionProductMatterMapping(data.workflowSequence, jurisdictionId, productMatterId).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Notify.Success("Workflow Assign Successful");
                    toggle();
                }
            });
        })()
    };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const handleDrag = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    useEffect(() => {
        if (newWorkflow) {
            let temp = [...fields];
            temp[currInd] = { ...newWorkflow, workflowId: newWorkflow.id };
            setWorkflowSequence(temp);
            setNewWorkflow(null);
            setCurrInd(null);
        }
    }, [newWorkflow]);

    return (<>
        <Modal isOpen={modal} toggle={toggle} backdrop='static'>
            <ModalHeader toggle={toggle}>{productMatterName} | <i className="la la-clipboard-list ml-1 mr-1"></i>Workflow Sequence</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} ref={_formRef} autoComplete="off">
                <ModalBody>
                    <button type="button" className="btn btn-themed btn-sm mb-2" onClick={() => append({})}>Assign New Workflow</button>
                    <DragDropContext onDragEnd={handleDrag}>
                        <Droppable droppableId="workflowSequence">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        fields.map((data, i) => {
                                            let selectedWorkflowId = watch(`workflowSequence[${i}].workflowId`);
                                            let selectedWorkflow = workflowOpts.find(d => d.value == selectedWorkflowId);
                                            let sequence = i + 1;
                                            return <Draggable key={`wfSeq[${i}]`} draggableId={`item-${i}`} index={i}>
                                                {(provided) => (
                                                    <div key={data.id} ref={provided.innerRef} {...provided.draggableProps}>
                                                        <Row style={{ boxShadow: "0 0 1.25rem rgb(31 45 61 / 8%)", backgroundColor: 'white' }} className="mb-1">
                                                            <Col xs={2} style={{ alignSelf: 'center' }} className={"d-flex justify-content-between"}>
                                                                <i class="las la-bars" style={{ fontSize: "1.5em" }} {...provided.dragHandleProps}></i><span className={"centered"}>{sequence}.</span>
                                                            </Col>
                                                            <Col xs={7}>
                                                                <InputHoc name={`workflowSequence[${i}].workflowId`} error={errors?.workflowSequence?.[i]?.workflowId?.message} inputType={InputTypes.SELECT} options={workflowOpts.filter(d => !_selectedWorkflowList?.includes(d.value) || selectedWorkflowId == d.value)}
                                                                    control={control} label="Please Select Workflow" rules={{ required: 'Workflow is required' }} defaultValue={data?.workflowId} />
                                                                <InputHoc name={`workflowSequence[${i}].sequence`} error={errors?.workflowSequence?.[i]?.sequence?.message} type="hidden"
                                                                    ref={register()} defaultValue={sequence} />
                                                            </Col>
                                                            <Col xs={3} style={{ alignSelf: 'center' }}>
                                                                <div className="btn-round expand-theme btn-sm mr-2" id={"addWorkflowBtn-" + data.id} onClick={() => { setCreateEditWorkflowModal(!createEditWorkflowModal); setCurrInd(i) }}><i className="la la-plus"></i></div>
                                                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"addWorkflowBtn-" + data.id}>Add New Workflow</UncontrolledTooltip>
                                                                {
                                                                    selectedWorkflow && <>
                                                                        <div className="btn-round expand-theme btn-sm mr-2" id={"viewWorkflowBtn-" + data.id} onClick={() => { setSelectedWorkflow(selectedWorkflow); setViewWorkflowTaskModal(!viewWorkflowTaskModal) }}><i className="la la-eye"></i></div>
                                                                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"viewWorkflowBtn-" + data.id}>View</UncontrolledTooltip>
                                                                    </>
                                                                }
                                                                {
                                                                    i !== 0 &&
                                                                    <>
                                                                        <div className="btn-round expand-red btn-round-warning btn-sm" id={"removeWorkflowSeqBtn-" + data.id} onClick={() => { remove(i); }}><i className="la la-trash"></i></div>
                                                                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"removeWorkflowSeqBtn-" + data.id}>Remove</UncontrolledTooltip>
                                                                    </>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Draggable>
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-red" onClick={toggle}>Cancel</button>
                    <button type="submit" className="btn btn-themed">Save</button>
                </ModalFooter>
            </form>
            <ViewWorkflowTasks viewWorkflowTaskModal={viewWorkflowTaskModal} setViewWorkflowTaskModal={setViewWorkflowTaskModal} selectedWorkflow={selectedWorkflow} setSelectedWorkflow={setSelectedWorkflow} />
            <CreateEditWorkflow modal={createEditWorkflowModal} setModal={setCreateEditWorkflowModal} jurisdictionId={jurisdictionId} setNewWorkflow={setNewWorkflow} />
        </Modal>
    </>)
}

export default Workflow;

///<summary>
///Author: Ong Sze Hua
///</summary>
const WorkflowFormInput = props => {
    const { errors, register, append, fields, control, remove, watch } = props;

    const [deparmentOpts, setDepartmentOpts] = useState([]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        let commonDao = new CommonDao();
        (async () => {
            await commonDao.getDepartmentList().then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    let temp = jsonResponse[ApiKey._API_DATA_KEY];
                    temp = temp.map(d => { return { label: d.name, value: d.id } });
                    setDepartmentOpts(temp);
                }
            });
        })()
    }, []);

    return <>
        <Row>
            <Col>
                <InputHoc name="name" error={errors?.name?.message} label="Workflow Name" ref={register({ required: 'Name is required.' })} />
            </Col>
            <Col>
                <InputHoc name="jurisdictionId" error={errors?.jurisdictionId?.message} label="Jurisdiction" inputType={InputTypes.SELECT} options={jurisdictionOptions}
                    control={control} rules={{ required: 'Jurisdiction is required.' }} />
            </Col>
        </Row>
        <button type="button" className="btn btn-themed btn-sm" onClick={() => append({})}>Add New Task</button>
        {
            fields.map((data, i) => <Row key={data.id} className={i + 1 !== fields.length ? "serviceItems" : ""} style={{ paddingTop: 15 }}>
                <Col xs='6'>
                    <InputHoc name={`tasks[${i}].departmentId`} error={errors?.tasks?.[i]?.departmentId?.message} label="Department" inputType={InputTypes.SELECT} options={deparmentOpts}
                        control={control} rules={{ required: 'Department is required' }} defaultValue={data?.departmentId} />

                    <InputHoc name={`tasks[${i}].name`} error={errors?.tasks?.[i]?.name?.message} label="Task Name"
                        ref={register({ required: 'Task Name is required.' })} defaultValue={data?.name} />

                    <InputHoc name={`tasks[${i}].id`} type="hidden" defaultValue={data.id} ref={register()} />
                </Col>
                <Col xs='5'>
                    <InputHoc name={`tasks[${i}].estimatedDayRequired`} error={errors?.tasks?.[i]?.estimatedDayRequired?.message} label="Estimated Day Required" type="number"
                        ref={register({ required: 'Estimated Day is required.', pattern: { value: /^\d+$/, message: "Only accept whole number" } })} defaultValue={data?.estimatedDayRequired} />

                    <Row>
                        <Col style={{ paddingRight: 0 }} xs='4'>
                            <InputHoc name={`tasks[${i}].color`} error={errors?.tasks?.[i]?.color?.message} label="Color" inputType={InputTypes.INPUT_COLOR}
                                control={control} rules={{ required: 'Color is required' }} defaultValue={data?.color} />
                        </Col>
                        <Col style={{ paddingLeft: 0 }} className={"form-group"} xs='8'>
                            <Row className="m-0 p-0">
                                <Col className="m-0 p-0">
                                    <InputHoc name={`tasks[${i}].requireDocument`} error={errors?.tasks?.[i]?.requireDocument?.message} label="Require Document?" inputType={InputTypes.CHECKBOX}
                                        ref={register()} options={[{ value: true }]} defaultValue={data?.requireDocument} isSwitcher />
                                </Col>
                                <Col className="m-0 p-0">{
                                    watch(`tasks[${i}].requireDocument`) == 'true' &&
                                    <div className="form-group">
                                        <label style={{
                                            color: errors?.tasks?.[i]?.requiredDocuments?.some(r => {
                                                if (r?.documentId || r?.docProofAttachment)
                                                    return true;
                                                return false;
                                            }) && '#f64e60'
                                        }}>Documents</label>
                                        <button type="button" className="btn btn-primary btn-sm" data-toggle="collapse" data-target={"#wfTaskDocs-" + data.id}>
                                            <i className="fas fa-file-alt"></i>
                                        </button>
                                    </div>
                                }</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs='1' className="d-flex justify-content-center align-items-center ">
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => remove(i)}>
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </Col>
                <Col xs='12' style={{ paddingLeft: 50, paddingRight: 50 }}>
                    {
                        watch(`tasks[${i}].requireDocument`) == 'true' &&
                        <div id={"wfTaskDocs-" + data.id} className={"collapse" + watch(`tasks[${i}].requireDocument`) && ' show'}>
                            <WorkflowTaskDocuments nestIndex={i} control={control} register={register} errors={errors} watch={watch} />
                        </div>
                    }
                </Col>
            </Row>)
        }
    </>
}

///<summary>
///Author: Ong Sze Hua
///</summary>
const WorkflowTaskDocuments = props => {
    const { errors, nestIndex, control, register, watch } = props;
    const { fields, remove, append } = useFieldArray({
        control,
        name: `tasks[${nestIndex}].requiredDocuments`
    });
    const _formRef = useRef();
    const [filtersForm, setFiltersForm] = useState({});
    const [resetFilters, setResetFilters] = useState(false);

    const [modal, setModal] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);

    const [refreshDocumentTable, setRefreshDocumentTable] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    ///<summary>
    ///Author : Robin
    ///</summary>
    const _COLUMNS = useMemo(() => [
        {
            Header: "#",
            id: "row",
            Cell: ({ row }) => {
                return <>
                    <Col xs='12' className="p-0">
                        <InputHoc name={`tasks[${nestIndex}].requiredDocuments[${row.index}].id`} type="hidden" value={row.original?.id} ref={register()} />
                        <InputHoc name={`tasks[${nestIndex}].requiredDocuments[${row.index}].workflowTaskId`} type="hidden" value={row.original?.workflowTaskId} ref={register()} />
                        <InputHoc name={`tasks[${nestIndex}].requiredDocuments[${row.index}].documentId`} type="hidden" value={row.original?.document?.id} ref={register()} />
                        {row.index + 1}
                    </Col>
                </>
            },
            disableSortBy: true,
        },
        {
            Header: "Document Name",
            Cell: ({ row }) => {
                return <>
                    <Col xs='12' className="p-0">
                        {row.original?.document?.name}
                    </Col>
                </>
            },
            disableSortBy: true,
        },
        {
            Header: "Document Type",
            Cell: ({ row }) => {
                return <>
                    <Col xs='12' className="p-0">
                        {row.original?.document?.documentType?.name}
                    </Col>
                </>
            },
            disableSortBy: true,
        },
        {
            Header: "Require Signature",
            Cell: ({ row }) => {
                return <>
                    <Col xs='12' className="p-0">
                        <InputHoc
                            name={`tasks[${nestIndex}].requiredDocuments[${row.index}].requireSignature`}
                            inputType={InputTypes.CHECKBOX}
                            isSwitcher={true}
                            checkboxInline={true}
                            options={[{ value: true }]}
                            defaultValue={row.original?.requireSignature}
                            ref={register()}
                        />
                    </Col>
                </>
            },
            disableSortBy: true,
            style: { overflow: "visible" }
        },
        {
            Header: "Action",
            Cell: ({ row }) => {
                return <>
                    <div type="button" className="btn-round expand-theme btn-sm" id={"deleteActionBtn" + (row.original.id)} onClick={(e) => {
                        e.stopPropagation();
                        Notiflix.Confirm.Show('Confirmation', 'Do you confirm?', 'Yes', 'No',
                            () => {
                                setSelectedDocuments((prevList) => {
                                    return prevList.filter((o) => o.id !== row.original?.id);
                                });
                            }, '', deleteConfirm)
                    }}><i className="las la-trash"></i></div>
                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"deleteActionBtn" + (row.original.id)}>Remove Workflow</UncontrolledTooltip>
                </>
            },
            disableSortBy: true,
            style: { overflow: "visible" }
        }
    ], []);

    const _DOCUMENT_COLUMN = useMemo(() => [
        {
            Header: "#",
            id: "row",
            Cell: ({ row }) => {
                return (row.index + 1)
            },
            disableSortBy: true,
        },
        {
            Header: "",
            id: "cbSelectDocument",
            Cell: ({ row }) => {
                // Try to search if selectedDocumentList has the document
                var selectedItems = selectedDocuments.filter((o) => o?.document?.id === row.original?.id);
                var selectedItem = selectedItems && selectedItems[0];

                return <>
                    <InputHoc name={"cbSelectDocument"} inputType={InputTypes.CHECKBOX} onChange={(e) => {
                        var checked = e.target.checked;
                        if (checked) {
                            setSelectedDocuments((prevList) => {
                                var newList = [...prevList];
                                if (row.original) {
                                    var document = {
                                        document: {
                                            ...row.original
                                        }
                                    };

                                    newList.push(document);
                                }
                                return newList;
                            });
                        } else {
                            setSelectedDocuments((prevList) => {
                                return prevList.filter((o) => o?.document?.id !== row?.original?.id);
                            });
                        }
                    }}
                        checkboxInline={true}
                        options={[{ value: row.original?.id }]}
                        defaultValue={selectedItem?.document?.id}
                    />
                </>
            },
            disableSortBy: true,
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => {
                return (row.original?.name);
            }
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: ({ row }) => {
                return (row.original?.documentType?.name);
            }
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ row }) => {
                return (row.original?.description);
            }
        },
        {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="m-b-5">
                        {
                            row.original?.templateFile &&
                            <>
                                <a className="btn-sm btn-round expand-theme m-r-5 m-b-5" target="_blank" id="download_btn"
                                    href={row.original?.templateFile?.fileMeta[0].requestPath}
                                    download={row.original?.templateFile?.fileMeta[0].filename}>
                                    <i class="las la-download"></i>
                                </a>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_btn">Download Template</UncontrolledTooltip>
                            </>
                        }
                    </div>
                </>);
            },
            disableSortBy: true,
        }
    ])

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        getDocumentList();
    }, []);

    ///<summary>
    ///Author: Robin
    ///</summary>
    useEffect(() => {
        if (fields) {
            var requiredDocuments = fields.map((data, i) => {
                return data;
            });

            setSelectedDocuments(requiredDocuments);
        }
    }, []);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    async function getDocumentList() {
        let dao = new CommonDao();
        await dao.getDocumentList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setDocumentList(data);
            }
        })
        await dao.getDocumentTypeList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                let temOpts = data.map(d => { return { label: d.name, value: d.id } });
                setDocumentTypeList(temOpts);
            }
        })
    }

    return <>
        <button type="button" className="btn btn-primary btn-sm" onClick={() => toggle()}>Add New Document</button>
        <Row>
            {
                selectedDocuments.length > 0 &&
                <Col xl={12}>
                    <ReactTable
                        className="selectedDocumentTable"
                        columns={_COLUMNS}
                        data={selectedDocuments}
                    />
                </Col>
            }
        </Row>
        <Modal size='lg' isOpen={modal} toggle={toggle} centered backdrop='static'>
            <ModalHeader toggle={toggle}>Assign Document</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xl={12}>
                        <ReactTable
                            className="assignDocumentTable"
                            columns={_DOCUMENT_COLUMN}
                            data={documentList}
                            setPagination
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-red" onClick={toggle}>Done</button>
            </ModalFooter>
        </Modal>
    </>
}

///<summary>
///Author: Ong Sze Hua
///</summary>
export const CreateEditWorkflow = props => {
    const { modal, setModal, selectedWorkflow, setRefreshTable, jurisdictionId, setNewWorkflow } = props;
    const _formRef = useRef();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const { fields, append, remove } = useFieldArray(
        {
            control,
            name: "tasks"
        }
    );

    useEffect(() => {
        if (selectedWorkflow) {
            reset(selectedWorkflow);
        }
        else {
            reset({ jurisdictionId: jurisdictionId, tasks: [] });
        }
    }, [selectedWorkflow]);

    useEffect(() => {
        if (modal && !selectedWorkflow) {
            append({});
        }
    }, [modal]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        if (!stringIsNullOrEmpty(data.tasks)) {
            data.tasks.map(d => {
                if (isNaN(d["id"])) {
                    d["id"] = null;
                }
                if (!stringIsNullOrEmpty(d.requiredDocuments)) {
                    d.requiredDocuments.map(r => {
                        if (isNaN(r["id"])) {
                            r["id"] = null;
                        }
                    })
                }
            });
        }
        let params = { id: selectedWorkflow?.id, ...data };
        let workflowDao = new WorkflowDao();
        (async () => {
            if (params?.id)
                await workflowDao.updateWorkflow(params).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        if (setRefreshTable)
                            setRefreshTable(prevState => !prevState);
                        Notiflix.Notify.Success("Update Workflow Successful");
                        toggle();
                    }
                });
            else
                await workflowDao.createWorkflow(params).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        if (setRefreshTable)
                            setRefreshTable(prevState => !prevState);
                        if (setNewWorkflow)
                            setNewWorkflow(jsonResponse[ApiKey._API_DATA_KEY])
                        Notiflix.Notify.Success("Workflow Created Successful");
                        toggle();
                    }
                });
        })()
    };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(prevState => !prevState); remove(); };

    return <>
        <Modal isOpen={modal} toggle={toggle} backdrop='static' size='lg'>
            <ModalHeader toggle={toggle}><i className="la la-clipboard-list mr-1"></i>Workflow</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} ref={_formRef} autoComplete="off">
                <ModalBody>
                    <WorkflowFormInput errors={errors} register={register} append={append} fields={fields} control={control} remove={remove} watch={watch} />
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-red btn-sm" onClick={toggle}>Cancel</button>
                    <button type="submit" className="btn btn-themed btn-sm">Save</button>
                </ModalFooter>
            </form>
        </Modal>
    </>
}

export const ViewWorkflowTasks = props => {
    const { viewWorkflowTaskModal, setViewWorkflowTaskModal, setSelectedWorkflow, selectedWorkflow } = props;

    const _WORKFLOW_TASK_COLUMN = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => <>
                <i class="las la-square mr-1" style={{ color: row.original.color }}></i>
                {row.original.name}
            </>,
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        },
        {
            Header: "Department",
            accessor: el => el?.department?.name,
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        },
        {
            Header: "Estimated Day Required",
            accessor: "estimatedDayRequired",
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        },
        {
            Header: "Documents",
            Cell: ({ row }) => <>
                {
                    row.original.requiredDocuments &&
                    row.original.requiredDocuments.filter((d) => d.document.templateFile).map(d => {
                        let latestFileMeta = d.document.templateFile?.fileMeta.slice(-1)[0];
                        return <Row className="mt-1 mr-0 ml-0 mb-0">
                            <Col className="p-0">
                                <a id={"actDownload" + (row.original.id) + "-" + (d.id)}
                                    className="btn-sm btn-round expand-theme mr-1"
                                    target="_blank"
                                    href={latestFileMeta?.requestPath}
                                    download={latestFileMeta?.filename}>
                                    <i className={("las la-file-download")}></i>
                                </a>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"actDownload" + (row.original.id) + "-" + (d.id)}>Download Document</UncontrolledTooltip>
                                {latestFileMeta.filename}
                            </Col>
                        </Row>
                    })

                }
            </>,
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }

    ], [selectedWorkflow]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggleViewWorkflowTask = () => { setSelectedWorkflow(null); setViewWorkflowTaskModal(prevState => !prevState); };
    return <>
        <Modal isOpen={viewWorkflowTaskModal} toggle={toggleViewWorkflowTask} backdrop='static' size='lg'>
            <ModalHeader toggle={toggleViewWorkflowTask}>{selectedWorkflow?.name} - Workflow Tasks</ModalHeader>
            <ModalBody>
                <ReactTable columns={_WORKFLOW_TASK_COLUMN} data={Array.isArray(selectedWorkflow?.tasks) ? selectedWorkflow.tasks : []} />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-red" onClick={toggleViewWorkflowTask}>Back</button>
            </ModalFooter>
        </Modal>
    </>
}
