import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Report, Confirm, Block, Notify } from "notiflix";
import { InputTypes, BtnTypes, ApiKey, ApiUrl, matterType, DefaultCurrencyId, DefaultCurrency, TableId } from "util/Constant";
import { formatCountryList, formatNumber, stringIsNullOrEmpty } from "util/Utility.js";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import Workflow from '../Workflow';
import { ProductDao } from "data";
import ServiceListAssignDocument from "./ServiceListAssignDocument";
import ServiceListAssignRule from "./ServiceListAssignRule";
import ButtonRound from "components/buttons/ButtonRound";
import InputHoc from "components/form/InputHoc";
import { useForm } from "react-hook-form";
import NumberFormat from 'react-number-format';
import DataAccessObject from "../../../data/DataAccessObject";
import { useRecoilValue } from "recoil";
import { authCredentialState, countriesState, userIdSelector } from "recoil/Atoms";
import BrandModal from "components/modals/BrandModal";
import { LoadingStateText } from "../../../util/Constant";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import useSWR from "swr";

///<summary>
///Author: Ong Sze Hua
///</summary>
const ServicesList = props => {
    const { t } = useTranslation();
    const { jurisdictionId, matterTypeId, canAddService } = props;
    const [modal, setModal] = useState(false);
    const [workflowModal, setworkflowModal] = useState(false);
    const [productModal, setProductModal] = useState(false);
    const [assignDocumentModal, setAssignDocumentModal] = useState(false);
    const [assignRuleModal, setAssignRuleModal] = useState(false);
    const [service, setService] = useState();
    const [productMatterId, setproductMatterId] = useState();
    const [productMatterName, setproductMatterName] = useState();
    const formRef = useRef();
    const [reset, setReset] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [innerTab, setInnerTab] = useState(false);
    const userId = useRecoilValue(userIdSelector);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    const _fields = useMemo(() => [
        {
            rowOptions: { xl: 1 },
            columns: [
                {
                    label: t('NAME'), name: "name", input: InputTypes.INPUT, rules: { required: 'Name is required' }
                },
                {
                    label: t('DESCRIPTION'), name: "description", input: InputTypes.TEXTAREA, rules: { required: 'Description is required' }, maxLength: 256, className: classNames({ "text-area-max-height": matterTypeId === matterType._ANNUAL_PACKAGE })
                }

            ]
        },
        matterTypeId === matterType._ANNUAL_PACKAGE &&
        {
            rowOptions: { xl: 1 },
            columns: [
                matterTypeId === matterType._ANNUAL_PACKAGE &&
                {
                    label: t('ADDRESS'), name: "address1", input: InputTypes.TEXTAREA, rules: { required: 'Address is required' }, maxLength: 450, className: "text-area-max-height"
                },
                matterTypeId === matterType._ANNUAL_PACKAGE &&
                {
                    label: t('COUNTRY'), name: "countryId", input: InputTypes.SELECT, options: countryOptions, rules: { required: 'Country is required' }
                }

            ]
        }
    ], [matterTypeId]);

    const _ANNUAL_PACKAGE_COLUMN = matterTypeId === matterType._ANNUAL_PACKAGE ?
        [
            {
                id: "Registered Address",
                Header: "REGISTERED_ADDRESS",
                accessor: el => el?.address && el?.address?.address1 + ', ' + el?.address?.country?.label,
                Cell: ({ row }) => <>
                    <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{row.original?.address && row.original?.address?.address1 + ', ' + row.original?.address?.country?.label}</div>
                </>,
                style: { width: '27.5%' }
            }
        ]
        : [];

    const _COLUMN = useMemo(() => [
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            style: { width: '20%', whiteSpace: 'pre-line', wordBreak: 'break-word' },
            isRequiredColumn: true
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => <>
                <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{row.original.description}</div>
            </>,
            style: { width: '27.5%' }
        },
        ..._ANNUAL_PACKAGE_COLUMN,
        {
            id: "Total Document",
            Header: "systemConfiguration:TOTAL_DOCUMENT",
            accessor: "totalDocument"
        },
        {
            id: "Status",
            Header: "STATUS",
            accessor: el => el?.isAvailable ? 1 : 0,
            Cell: ({ row }) => {
                let badgeClass;
                let msg;
                if (row.original?.isAvailable) {
                    badgeClass = "badge-success";
                    msg = t("AVAILABLE");
                }
                else {
                    badgeClass = "badge-warning";
                    msg = t("UNAVAILABLE");
                }

                return <span className={"badge " + badgeClass}>{msg}</span>
            },
            isRequiredColumn: true
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => <div className="d-flex btn-list-wrapper">

                {
                    matterTypeId === matterType._ANNUAL_COMPLIANCE &&
                    <>
                        <ButtonRound medium title={false} className="btn-themed" type={BtnTypes.RULE} id={"assign_rule-" + row.index} onClick={() => {
                            setproductMatterId(row.original.id);
                            setproductMatterName(row.original.name);
                            setAssignRuleModal((prevState) => !prevState);
                            setInnerTab(true);
                        }} />
                    </>
                }

                <ButtonRound medium title={false} className="btn-themed" type={BtnTypes.DOCUMENT} id={"assign_document-" + row.index} onClick={() => {
                    setproductMatterId(row.original.id);
                    setproductMatterName(row.original.name);
                    setAssignDocumentModal((prevState) => !prevState);
                    setInnerTab(true);
                }} />

                <ButtonRound type={BtnTypes.MONEY} className="btn-themed" medium id={"checkActionBtn" + (row.original.id)} onClick={() => {
                    //setSelectService(true); setService(row.original)
                    setproductMatterId(row.original.id);
                    setproductMatterName(row.original.name);
                    setProductModal((prevState) => !prevState);
                    setInnerTab(true);
                }} />

                <ButtonRound type={BtnTypes.EDIT} className="btn-themed" medium id={"editActionBtn" + (row.original.id)} onClick={() => {
                    toggle();
                    let { address, ...restData } = row.original;
                    setService({ ...address, ...restData });
                }} />

            </div>,
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" },
            isRequiredColumn: true
        }

    ], []);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let params = {
            ...data,
            matterTypeId: matterTypeId,
            jurisdictionId: jurisdictionId
        };
        let msg = "";

        if (!stringIsNullOrEmpty(service)) {
            params["id"] = service.id;
            msg = t("systemConfiguration:SUCCESS_UPDATE_SERVICE");
        }
        else {
            msg = t("systemConfiguration:SUCCESS_CREATE_SERVICE");
        }

        let dao = new ProductDao();
        (async () => {
            if (params["id"]) {
                await dao.updateProductMatter(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setRefreshProdMatterTable();
                        Notify.Success(msg);
                        toggle();
                    }
                }).finally(() => Block.Remove(".modal-content"));
            }
            else {
                await dao.createProductMatter(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setRefreshProdMatterTable();
                        Notify.Success(msg);
                        toggle();
                    }
                }).finally(() => Block.Remove(".modal-content"));
            }
        })();
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(!modal); setService(null) };

    const setRefreshProdMatterTable = () => setRefreshTable(prevState => !prevState);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (service) {
            setReset(true);
        }
    }, [service]);

    return (<>
        {
            assignDocumentModal &&
            <ServiceListAssignDocument modal={assignDocumentModal} setModal={() => { setAssignDocumentModal(false); setInnerTab(false); }} jurisdictionId={jurisdictionId} productMatterId={productMatterId} productMatterName={productMatterName} setRefreshProdMatterTable={setRefreshProdMatterTable} />
        }
        {
            productModal &&
            <ProductModal modal={productModal} setModal={() => { setProductModal(false); setInnerTab(false); }} jurisdictionId={jurisdictionId} productMatterId={productMatterId} setRefreshProdMatterTable={setRefreshProdMatterTable} />
        }
        {
            assignRuleModal &&
            <ServiceListAssignRule modal={assignRuleModal} setModal={() => { setAssignRuleModal(false); setInnerTab(false); }} jurisdictionId={jurisdictionId} productMatterId={productMatterId} productMatterName={productMatterName} setRefreshProdMatterTable={setRefreshProdMatterTable} />
        }
        {
            !innerTab &&
            <div id="servicesList" className="d-flex flex-column h-100">
                <ReactTable
                    tblFooterClass={"rt-tbl-footer-white"}
                    enableSearch
                    columns={_COLUMN}
                    setPagination
                    url={ApiUrl._API_GET_PRODUCT_MATTER_SERVICE_LIST_BY_JURISDICTION_MATTER_TYPE.replace(':matterTypeId', matterTypeId).replace(':jurisdictionId', jurisdictionId)}
                    refreshTable={refreshTable}
                    filterFormfield={filtersForm}
                    customButtons={
                        <>
                            {
                                canAddService && <button type="button" className="btn btn-themed tbl-custom-brand-btn" onClick={toggle}>
                                    {t("systemConfiguration:ADD_SERVICE")}
                                </button>
                            }
                        </>
                    }
                    tableColumnPreference={[userId, `${TableId._SERVICES}-${matterTypeId}`]}
                />
            </div>
        }
        <BrandModal modalSize="md" title={service ? t("systemConfiguration:EDIT_SERVICE") : t("systemConfiguration:ADD_SERVICE")} customBody isOpen={modal} toggler={toggle} centered backdrop='static'>
            <ModalBody>
                <FormBuilder fields={_fields} onSubmit={onSubmit} formRef={formRef} reset={reset} resetTriggerReset={setReset} resetValues={service} />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="submit" className="btn btn-themed btn-min-width" onClick={() => { submitForm(formRef) }}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
        <Workflow modal={workflowModal} setModal={setworkflowModal} jurisdictionId={jurisdictionId} productMatterId={productMatterId} productMatterName={productMatterName} />
    </>)
}

export default ServicesList;

const ProductModal = props => {
    const { t } = useTranslation();
    const { modal, setModal, jurisdictionId, productMatterId, setRefreshProdMatterTable } = props;

    const toggle = () => setModal(!modal);
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const [productList, setProductList] = useState([]);
    const [refreshTable, setRefreshTable] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    let productDao = new ProductDao();
    let assignedMsg = t("systemConfiguration:PRODUCT_BEEN_ASSIGNED");
    const { currencyPreference, id:userId } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    const _SELECTED_COLUMN = useMemo(() => [
        {
            id: "Code",
            Header: "systemConfiguration:CODE",
            Footer: "",
            accessor: "productCode",
        },
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            Footer: ''
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => <>
                <ButtonRound medium title={t("REMOVE")} type={BtnTypes.DELETE} id={"deleteActionBtn" + (row.original.id)} onClick={() => {
                    removeProduct(row.index);
                }} />
            </>,
            disableSortBy: true,
            width: '1%',
            Footer: ({ rows }) => <>
                {
                    rows.length > 0 &&
                    <strong>{t('TOTAL')}</strong>
                }
            </>,
        },
        {
            id: "Price",
            Header: "PRICE",
            Footer: ({ rows, ...rest }) => {
                let total = calcTotalPrice(rows);
                if (rows.length > 0)
                    return <div className="d-flex flex-column">
                        <strong><NumberFormat value={formatNumber(total)} displayType={'text'} thousandSeparator={true} prefix={`${defaultCurrencyCode} `} /></strong>
                        {
                            currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                            <strong><NumberFormat className="quote-currency" value={formatNumber(total * currencyPreference?.exchangeRate?.rate)} displayType={'text'} thousandSeparator={true} prefix={`≈ ${currencyPreference?.code} `} /></strong>
                        }
                    </div>
            },
            accessor: "price",
            Cell: ({ row }) => {
                let price = calcPrice(row);
                return <div className="d-flex flex-column">
                    <NumberFormat value={formatNumber(price)} displayType={'text'} thousandSeparator={true} prefix={`${defaultCurrencyCode} `} />
                    {
                        row.original?.quoteCurrency?.id != DefaultCurrencyId && row.original?.quoteCurrency?.exchangeRate?.rate &&
                        <NumberFormat className="quote-currency" value={formatNumber(price * row.original?.quoteCurrency?.exchangeRate?.rate)} displayType={'text'} thousandSeparator={true} prefix={`≈ ${row.original?.quoteCurrency?.code} `} />
                    }
                </div>
            }
        },
    ], [productList, currencyPreference]);

    const _SEARCH_COLUMN = useMemo(() => [
        {
            id: "Code",
            Header: "systemConfiguration:CODE",
            accessor: "productCode",
        },
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => <>
                <ButtonRound medium title={t("systemConfiguration:ASSIGN")} className="btn-themed" type={BtnTypes.PLUS} id={"assignProduct" + (row.original.id)} />
            </>,
            disableSortBy: true,
            width: '1%'
        }
    ], []);


    const getJurisdictionProductMatterProduct = async () => {
        await productDao.getJurisdictionProductMatterProducts(jurisdictionId, productMatterId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                setProductList(jsonResponse[ApiKey._API_DATA_KEY] ?? []);
            }
        });
    }

    useEffect(() => {
        if (modal && jurisdictionId && productMatterId) {
            getJurisdictionProductMatterProduct();
        }
    }, [modal]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function calcPrice(row) {
        let result;
        let price = row.original.price;
        // temporary remove discount
        // if (row.original.discountRate) {
        //     let tempDiscount = price * row.original.discountRate / 100;
        //     let discountRateCapped = row.original.discountRateCapped;
        //     if (tempDiscount > discountRateCapped) {
        //         result = price - discountRateCapped;
        //     }
        //     else {
        //         result = price - tempDiscount;
        //     }
        // }
        // else if (row.original.discountAmount) {
        //     result = price - row.original.discountAmount;
        // }
        // else {
        //     result = price;
        // }
        return price;
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function calcTotalPrice(rows = null) {
        let total = 0;
        if (rows !== null) {
            rows.map((row) => {
                total += parseFloat(calcPrice(row));
            })
        }
        return total;
    }

    const onSubmit = async (postData) => {
        var newUrl = `${ApiUrl._API_GET_PRODUCTS}?${new URLSearchParams({ page: 1, pageSize: 1, ...postData, jurisdictionId: jurisdictionId })}`;
        let dao = new DataAccessObject();
        await dao.get(newUrl).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                let count = jsonResponse[ApiKey._API_DATA_COUNT_KEY];
                let tempData = jsonResponse[ApiKey._API_DATA_KEY];
                let foundMsg = t("systemConfiguration:PRODUCT_FOUND");
                let notFoundMsg = t("systemConfiguration:PRODUCT_NOT_FOUND");
                if (count === 0) {
                    Notify.Warning(notFoundMsg);
                }
                else if (count === 1) {
                    Notify.Success(foundMsg);
                    let tempArr = [...productList];
                    if (tempArr.some(d => d.id == tempData[0].id)) {
                        Notify.Warning(assignedMsg);
                    }
                    else {
                        tempArr.push(tempData[0]);
                        setProductList(tempArr);
                    }
                }
                else {
                    Notify.Success(count + ' ' + foundMsg);
                }
                setFiltersForm(postData);
            }
        });
    };

    const removeProduct = (index) => {
        //Confirm.Show("Remove Products", "Are you sure to remove?", "Okay", "Back", () => {
        //    let tempArr = [...productList];
        //    tempArr.splice(index, 1);
        //    setProductList(tempArr);
        //}, () => { return });
        let tempArr = [...productList];
        tempArr.splice(index, 1);
        setProductList(tempArr);
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const submitSelectedList = async () => {
        let selectedProductList = productList.map(p => { return p.id });
        await productDao.createOrUpdateProductListByJurisdiction(jurisdictionId, productMatterId, selectedProductList).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                getJurisdictionProductMatterProduct();
                Notify.Success(t("SUBMITTED_SUCCESSFULLY"));
                toggle();
                setRefreshProdMatterTable();
            }
        })
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const assignProduct = (product) => {
        let tempArr = [...productList];
        if (tempArr.some(d => d.id == product.id)) {
            Notify.Warning(assignedMsg);
        }
        else {
            tempArr.push(product);
            setProductList(tempArr);
        }
    }


    return <div className="d-flex flex-column h-100" isOpen={modal} toggle={toggle}>
        <div className="section-split flex-grow-1">
            <div className="section-wrapper flex-grow-1">
                <div className="section-header">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' className="header-search-form">
                        <div className="input-wrapper">
                            <ButtonRound type={BtnTypes.ARROW_LEFT} className="btn-sm m-auto m-r-20" title={t("BACK")} onClick={() => props.setModal(false)}></ButtonRound>
                            <InputHoc name="productCode" placeholder={t("systemConfiguration:PRODUCT_CODE")} ref={register} />
                            <InputHoc name="name" placeholder={t("systemConfiguration:PRODUCT_NAME")} ref={register} />
                        </div>
                        <div className="d-flex">
                            <button type="submit" className="btn btn-themed btn-brand-round mr-2"><i className="las la-search" /></button>
                            <button type="button" className="btn btn-themed btn-brand-round grayscale-100" onClick={() => { reset(); setFiltersForm({}) }}><i className="las la-redo-alt" /></button>
                        </div>
                    </form>
                </div>
                <div className="section-body assigned-product-wrapper">
                    <ReactTable
                        enableSearch
                        columns={_SEARCH_COLUMN}
                        refreshTable={refreshTable}
                        filterFormfield={filtersForm}
                        setPagination
                        url={ApiUrl._API_GET_PRODUCTS}
                        initialPageSize={10}
                        initialSearchParam={{ jurisdictionId: jurisdictionId }}
                        tblFooterClass={"rt-tbl-footer-white"}
                        getRowProps={(row) => {
                            return {
                                onClick: (e) => {
                                    setSelectedRow(row);
                                    assignProduct(row.original);
                                },
                                class: row.id == selectedRow && "table-row-selected"
                            }
                        }}
                        tableColumnPreference={[userId, TableId._SERVICES_FEE_ITEMS]}
                    />
                </div>
            </div>
            <div className="section-wrapper">
                <div className="section-header">
                    <span className="text-pill-brand">{t("systemConfiguration:ASSIGNED_PRODUCT")}</span>
                </div>
                <div className="section-body">
                    <ReactTable
                        enableSearch
                        setPagination
                        columns={_SELECTED_COLUMN}
                        data={productList}
                        tblFooterClass={"rt-tbl-footer-white"}
                        setCustomFooters
                        tableColumnPreference={[userId, TableId._SERVICES_FEE_ITEMS_ASSIGNED]}
                    />
                </div>
                <div className="section-footer">
                    <button type="button" className="btn btn-themed btn-min-width" onClick={submitSelectedList}>{t("SAVE")}</button>
                </div>
            </div>
        </div>
    </div>
}