import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useForm } from "react-hook-form";
import { AiFillEye } from "react-icons/ai";
import { Report } from "notiflix";
import OtpInput from 'react-otp-input';
import { OTPSettings, ApiKey, InputTypes, SweetAlert } from 'util/Constant';
import InputHoc from "components/form/InputHoc";
import { AccountantDao } from "data";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis
/// </summary>
const ChangePassword = props => {
    const { user } = props;
    const { t } = useTranslation();
    const _history = useHistory();

    const [passwordShown, setPasswordShown] = useState({
        currentPassword: false,
        newPassword: false,
        retypeNewPassword: false
    });
    const [counter, setCounter] = useState(null);
    const [isRequestOtp, setRequestOtp] = useState(true);
    const [phoneNum, setPhoneNum] = useState('+01243513524');
    const [attemptNum, setAttemptNum] = useState(OTPSettings._OTP_RESEND_ATTEMPT);
    const [isValidateOtpState, setValidateOtpState] = useState(false);
    const [tacValue, setTacValue] = useState('');
    const [isTacPassed, setTacPassed] = useState(false);

    const { register, handleSubmit, errors, watch, reset } = useForm();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmitHandler = (data) => {
        // TODO : check password validation
        let params = {
            id: user?.id,
            currentPassword: data.currentPassword,
            newPassword: data.newPassword
        }
        let accountantDao = new AccountantDao();
        (async () => {
            await accountantDao.changePassword(params).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                    reset({});
                    return;
                }
                else {
                    var errors = responseJson[ApiKey._API_ERROR_KEY];
                    var errorHtml = "";
                    errors.forEach((error) => {
                        errorHtml += `<li>${error.detail}</li>`;
                    });
                    errorHtml = `<ul>${errorHtml}</ul>`;

                    Notiflix.Report.Warning("Invalid password", errorHtml ?? "");
                }
            })
        })();
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const _otpFieldOnChangeHandler = (otp) => {
        setTacValue(otp)
        if (otp.length != OTPSettings._OTP_INPUT_LENGTH) {
            setTacPassed(false);
            return
        }
        // TODO : verify otp
        setTacPassed(true);
        Report.Success(
            t(SweetAlert._OPERATION_SUCCESS),
            'Successfully changed password',
            t(SweetAlert._OK), () => _history.goBack()
        );
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const resendOtpButtonHandler = () => {
        if (attemptNum !== 0) {
            setAttemptNum(attemptNum - 1)
        }
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const togglePasswordVisiblity = (event) => {
        var dataKey = event.currentTarget.getAttribute('data-key');
        setPasswordShown({ ...passwordShown, [dataKey]: !passwordShown[dataKey] });
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            setRequestOtp(false);
            setCounter(null);
        }
        else if (counter === OTPSettings._OTP_TIMER) {
            setRequestOtp(false);
        }
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <>
            <div className="d-flex m-t-30 mb-3" style={{ alignItems: "end" }}>
                <img className="mr-2" src={require("../../assets/img/icon/change-password.svg")} /><h4 className="font-weight-bold">{t("CHANGE_PASSWORD")}</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <InputHoc name="currentPassword" error={errors?.currentPassword?.message} label={t("CURRENT_PASSWORD")} type="password"
                    ref={register({ required: 'Current Password is required.' })} />
                <InputHoc name="newPassword" error={errors?.newPassword?.message} label={t("NEW_PASSWORD")} type="password"
                    ref={register({ required: 'New Password is required.' })} />
                <InputHoc name="reNewPassword" error={errors?.reNewPassword?.message} label={t("RETYPE_PASSWORD")} type="password"
                    ref={register({ required: 'Retype New Password is required.', validate: v => v === watch('newPassword') || "Passwords don't match." })} />
                <button type="submit" className={classnames("mr-2 btn btn-themed btn-mid-long")}>{t("UPDATE")}</button>
            </form>

            <div className="TacVerification row">
                {isValidateOtpState && (
                    <div className="validate-otp-footer col-lg-12 m-t-5">
                        <h4 className="text-center">Enter OTP</h4>
                        <OtpInput
                            containerStyle={"otp-input-container justify-content-center"}
                            value={tacValue}
                            onChange={_otpFieldOnChangeHandler}
                            numInputs={OTPSettings._OTP_INPUT_LENGTH}
                            separator={<span>-</span>}
                            shouldAutoFocus
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default ChangePassword;