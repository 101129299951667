import React, { useMemo, useState, useRef, useEffect, useContext, Fragment } from "react";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { Loading, Report, Block } from "notiflix";
import InputHoc from 'components/form/InputHoc';
import { CommonDao, AdminDao } from "data";

import { Row, Col, ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, ApiKey, BtnTypes, SweetAlert, ApiUrl, PolicyActionConstant, PolicyObjectConstant, TableId } from "util/Constant";
import { useForm, useFieldArray } from 'react-hook-form';
import { useResizeDetector } from "react-resize-detector";
import { useTranslation } from "react-i18next";
import ButtonRound from "components/buttons/ButtonRound";
import BrandModal from "components/modals/BrandModal";
import useSWR from "swr";
import { isEmailExisted } from "./forms/RegistrationUtils";
import { Can } from "config/user-access";
import { PageSettings } from "config/page-settings";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "recoil/Atoms";

/// <summary>
/// Author: Ong Sze Hua
/// Edited: Chris
/// - Integrated admin management API's
/// </summary>
const AdminListing = () => {
    const { t } = useTranslation();
    const { ref: _tableWrapperRef } = useResizeDetector();
    const _tableRef = useRef();
    const userId = useRecoilValue(userIdSelector);

    const { data: rolesJson } = useSWR([`${ApiUrl._API_GET_ROLE_LIST}?paginate=false`, ApiKey._API_GET]);
    const rolesOption = useMemo(() => {
        return rolesJson?.[ApiKey._API_SUCCESS_KEY] ?
            rolesJson[ApiKey._API_DATA_KEY] : [];
    }, [rolesJson]);
    const [modal, setModal] = useState(false);
    const [user, setUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const { register, control, handleSubmit, errors, watch, setValue, reset } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'jurisdictionInCharge'
    });
    const [jurisdictionOptions, setJurisdictionOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const _watchGroupSelection = watch('group');
    const [groupOptions, setGroupOptions] = useState([
        { label: 'None', value: 0 },
        { label: 'Proposed name 1', value: 1 },
        { label: 'Proposed name 2', value: 2 },
        { label: 'Proposed name 3', value: 3 },
        { label: 'Proposed name 4', value: 4 },
    ]);
    const {
        isXsDevices
    } = useContext(PageSettings);

    const _COLUMN = useMemo(() => [
        {
            Header: "EMAIL",
            accessor: "email",
            isRequiredColumn: true
        },
        {
            Header: "NAME",
            accessor: "name",
            isRequiredColumn: true
        },
        {
            Header: "ROLE",
            accessor: "role",
            Cell: ({ row }) => {
                if (row.original?.role?.name) {
                    return (row.original?.role?.name);
                } else {
                    return (<> - </>);
                }
            }
        },
        {
            Header: "PHONE_NUMBER",
            accessor: "phoneNumber"
        },
        {
            Header: "JURISDICTION",
            accessor: "jurisdictionInCharge",
            Cell: ({ row }) => (<div>
                {
                    row.original.jurisdictionInCharge.map((j) => {
                        return (<>
                            {
                                j.departments.map((d) => {
                                    return (<>{<li>{j.name} - {d.name}</li>}</>)
                                })
                            }
                        </>)
                    })
                }
            </div>),
            isRequiredColumn: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (<>
                <ButtonRound type={BtnTypes.EDIT} medium onClick={() => {
                    toggle(row.original.id)
                }} />
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" },
            isRequiredColumn: true
        }
    ]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const processJurisdictionForApi = (jurisdictionArr) => {
        let tempJurisdictionArr = [];

        /// <summary>
        /// Author: Chris
        /// </summary>
        const processDepartments = (departmentArr) => {
            let tempDepartmentArr = [];

            departmentArr.forEach(item => {
                tempDepartmentArr.push({ id: item.value });
            });

            return tempDepartmentArr;
        }

        jurisdictionArr.forEach(item => {
            tempJurisdictionArr.push({
                id: item.jurisdictionId,
                departments: processDepartments(item.departments)
            });
        });

        return tempJurisdictionArr;
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const processJurisdictionFromApi = (jurisdictionArr, departmentOptions) => {
        let tempJurisdictionArr = [];

        /// <summary>
        /// Author: Chris
        /// </summary>
        const processDepartments = (departmentArr) => {
            let tempDepartmentArr = [];

            departmentArr.forEach(item => {
                let selectedOption = departmentOptions.find(x => x.value === item.id);

                tempDepartmentArr.push({ ...selectedOption });
            });

            return tempDepartmentArr;
        };

        jurisdictionArr.forEach(item => {
            tempJurisdictionArr.push({
                jurisdictionId: item.id,
                departments: processDepartments(item.departments)
            })
        });

        return tempJurisdictionArr;
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const prepareModal = async (userGuid) => {
        let commonDao = new CommonDao();
        let adminDao = new AdminDao();
        let departmentList = [];

        await commonDao.getJurisdictionList().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setJurisdictionOptions(responseJson[ApiKey._API_DATA_KEY].map(item => {
                    return {
                        label: `(${item.shortName}) ${item.name}`,
                        value: parseInt(item.id),
                    }
                }));
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        });

        await commonDao.getDepartmentList().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                departmentList = responseJson[ApiKey._API_DATA_KEY].map(item => {
                    return {
                        label: item.name,
                        value: parseInt(item.id),
                    }
                });

                setDepartmentOptions(departmentList);
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        });

        if (userGuid !== null) {
            await adminDao.getAdminById(userGuid).then(async responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];

                    if (data.jurisdictionInCharge.length > 0) {
                        data = {
                            ...data,
                            jurisdictionInCharge: processJurisdictionFromApi(data.jurisdictionInCharge, departmentList)
                        }
                    }
                    else {
                        data = {
                            ...data,
                            jurisdictionInCharge: [
                                {
                                    jurisdictionId: null,
                                    departments: null
                                }
                            ]
                        }
                    }

                    setUserEmail(data?.email);

                    // Object.keys(data).map(key => {
                    //     setValue(key, data[key]);
                    // });
                    reset(data);

                    setUser(true);
                }
                else {
                    Report.Warning(
                        responseJson[ApiKey._API_MESSAGE_KEY],
                        responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            });
        }
        else {
            reset({ roleId: "" });
            setValue("phoneNumber", "852");
            append({}); // append a new jurisdiction & department row
        }
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const toggle = async (userGuid = null) => {
        if (user === null && !modal) {
            prepareModal(userGuid);
            setModal(true);
        }
        else {
            remove();
            setModal(false);
            setUser(null);
        }
    };

    const modalOnClosed = () => {
        setUser(null);
    }

    ///<summary>
    ///Author: Chris
    ///</summary>
    const onSubmit = (data) => {
        let loadingTimeout = setTimeout(() => {
            Loading.Circle('Creating admin...');
        }, 250);

        let newData = {
            ...data,
            jurisdictionInCharge: processJurisdictionForApi(data.jurisdictionInCharge)
        };

        (async () => {

            let adminDao = new AdminDao();
            await adminDao.createOrUpdateAdmin(newData).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success(
                        t('notiflix:SUCCESS'),
                        `${t('notiflix:ADMIN_CHANGE_SUCCESS')} ${user !== null ? t('notiflix:EDITED') : t('notiflix:CREATED')}.`,
                        t('notiflix:OKAY'),
                        () => {
                            _tableRef.current.reFetch();
                            toggle();
                        }
                    );
                }
                else {
                    Report.Warning(
                        responseJson[ApiKey._API_MESSAGE_KEY],
                        responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => {
                clearTimeout(loadingTimeout);
                Loading.Remove();
            });

        })();
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        if (_watchGroupSelection) {
            let selectedNoneOption = _watchGroupSelection.findIndex((x) => x.value === 0);

            if (selectedNoneOption !== -1 && _watchGroupSelection.length > 1) {
                setValue('group', [_watchGroupSelection[selectedNoneOption]]);
            }

            setGroupOptions(groupOptions.map(item => {
                return { ...item, isDisabled: item.value !== 0 && selectedNoneOption !== -1 }
            }));
        }
    }, [_watchGroupSelection]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const tableHeight = useMemo(() => {
        if (_tableWrapperRef.current != null) {
            return _tableWrapperRef.current.clientHeight;
        }
    }, [_tableWrapperRef?.current]);

    return (<>
        <div id="AdminManagement" className="d-flex flex-column h-100">
            {/* <div className="filter-panel-container border-radius-none">
                <Row className="filter-panel-body p-15">
                    <Col xl={12}>
                        <button className="btn btn-themed btn-mid-long pull-right mr-1" onClick={() => toggle()} ><i className="las la-plus mr-1"></i>{t("ADD")} {t("ADMIN")}</button>
                    </Col>
                </Row>
            </div> */}
            <div className="flex-grow-1">
                <div className="table-brand-wrapper h-100" ref={_tableWrapperRef}>
                    <div>
                        <ReactTable
                            ref={_tableRef}
                            tableMinHeight={tableHeight}
                            columns={_COLUMN}
                            url={ApiUrl._API_GET_ADMIN_LIST}
                            customButtons={
                                isXsDevices
                                ? 
                                <div className="btn-group">
                                    <button className="btn btn-themed btn-rounded" onClick={() => toggle()} ><i className="las la-plus"></i></button>
                                </div>
                                :
                                <button className="btn btn-themed tbl-custom-brand-btn pull-right mr-1" onClick={() => toggle()} ><i className="las la-plus mr-1"></i>{t("ADD")} {t("ADMIN")}</button>
                            }
                            tableColumnPreference={[userId, TableId._USERS_MANAGEMENT_ADMIN]}
                        />
                    </div>
                </div>
            </div>
        </div>
        <BrandModal
            customBody
            isOpen={modal}
            toggler={toggle}
            size="lg"
            title={user ? t("userManagement:EDIT_ADMIN") : t("userManagement:ADD_NEW_ADMIN")}
            onClosed={modalOnClosed}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    <Row xl={2}>
                        <Col xl={5}>
                            <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.admin_user_management} passThrough>
                                {allowed => {
                                    return <InputHoc name="email" label="EMAIL" ref={register({
                                        required: 'Email is required.',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        },
                                        validate: async value => (userEmail === value) || (await isEmailExisted(value) || "This email address already exists")
                                    })}
                                        error={errors?.email}
                                        // readOnly={user ? true : false}
                                        readOnly={!allowed}
                                    />
                                }}
                            </Can>
                        </Col>
                        <Col xl={5}>
                            <InputHoc name={`roleId`} defaultValue={null} label="ROLE" inputType={InputTypes.SELECT}
                                options={rolesOption.map((role) => {
                                    return {
                                        label: role.name,
                                        value: role.id
                                    }
                                })}
                                rules={{ required: true }} control={control} error={errors?.roleId} />
                        </Col>
                    </Row>
                    <Row xl={2}>
                        <Col xl={5}>
                            <InputHoc name="name" label="NAME" ref={register({
                                required: true
                            })}
                                error={errors?.name}
                            />
                        </Col>
                        <Col xl={5}>
                            <InputHoc name="phoneNumber" label="PHONE_NUMBER" inputType={InputTypes.PHONE} control={control} rules={{ required: true }} error={errors?.phoneNumber} />
                        </Col>
                    </Row>
                    {
                        fields.map((item, index) => (
                            <Row key={item.id}>
                                <Col xl={5}>
                                    <InputHoc name={`jurisdictionInCharge[${index}].jurisdictionId`} defaultValue={item.jurisdictionId} label="JURISDICTION" inputType={InputTypes.SELECT}
                                        options={jurisdictionOptions.filter(v => !(watch(`jurisdictionInCharge`)?.some(f => f.jurisdictionId == v.value)) || watch(`jurisdictionInCharge[${index}]`)?.jurisdictionId == v.value)}
                                        rules={{ required: true }} control={control} error={errors?.jurisdictionInCharge?.[index]?.jurisdictionId} />
                                </Col>
                                <Col xl={5}>
                                    <InputHoc name={`jurisdictionInCharge[${index}].departments`} defaultValue={item.departments} label="DEPARTMENTS" inputType={InputTypes.SELECT} options={departmentOptions} rules={{ required: true }} control={control} error={errors?.jurisdictionInCharge?.[index]?.departments} isMulti selectClasses='h-auto' />
                                </Col>
                                <Col xl={1} className="d-flex m-auto">
                                    {
                                        index !== 0 ?
                                            <ButtonRound medium type={BtnTypes.DELETE} onClick={() => remove(index)} /> :
                                            <ButtonRound medium type={BtnTypes.ADD} onClick={() => append({})} />
                                    }
                                </Col>
                            </Row>
                        ))
                    }
                    <InputHoc name="id" label="" hidden ref={register} />
                </ModalBody>
                <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                    <button type="button" className="btn btn-min-width btn-themed grayscale-100" onClick={() => toggle()}>{t("CANCEL")}</button>
                    <button type="submit" className="btn btn-min-width btn-themed">{t("SAVE")}</button>
                </ModalFooter>
            </form>
        </BrandModal>
    </>)
}

export default AdminListing;