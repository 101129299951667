import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { WebUrl, ApiKey, CompanyStatus, CompanyNameCheckStatus, DateFormat, InputTypes, LoadingStateText, BtnTypes, SweetAlert, ApiUrl } from 'util/Constant';
import { CompanyStatusToTranslateKey, formatNumber, getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty } from 'util/Utility';
import { RoleType, CompanyProcessState } from 'util/Constant';
import NavigationButton from 'components/buttons/NavigationButton';
import { useRecoilState, useRecoilValue } from 'recoil';
import { incorporationDetails, companySecretaryState } from 'recoil/Incorporation';
import { CompanyDao } from "data";
import { useParams } from 'react-router-dom';
import { Notify, Block, Report } from "notiflix";
import TabPanel from "../../components/panel/TabPanel";
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { userRolesSelector, authCredentialState } from "recoil/Atoms";
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import InputHoc from "components/form/InputHoc";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import ButtonRound from "components/buttons/ButtonRound";
import NumberFormat from 'react-number-format';
import BrandModal from "components/modals/BrandModal";
import { isEmpty } from "lodash";
import useSWR from "swr";
import { CustomFetcher } from "util/CustomFetcher";
import { Can, UserAccessContext } from 'config/user-access'
import { PolicyActionConstant, PolicyObjectConstant } from "../../util/Constant";
import { PageSettings } from "config/page-settings";

///<summary>
///Author: Ong Sze Hua
/// Temporarily hide CRM for client comments @ 20210319 by Lewis
///</summary>
const CompanyPortfolio = props => {

    const _history = useHistory();
    const { companyId } = useParams();
    const { t } = useTranslation();

    const _formRef = useRef();
    const [fyeId, setFyeId] = useState(null);
    const userRole = useRecoilValue(userRolesSelector);
    const [companyProfile, setCompanyProfile] = useState({});
    const [currentTab, setCurrentTab] = useState([]);
    const [currentShareTab, setCurrentShareTab] = useState(0);
    const [financialYear, setFinancialYear] = useState();
    const { handleSubmit, register, control, errors } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showRejectIncorporationRequestModal, setShowRejectIncorporationRequestModal] = useState(false);
    const authCredential = useRecoilValue(authCredentialState);
    const [hiddenMenu, setHiddenMenu] = useState(false);

    const {
        isXsDevices,
        isSmDevices,
    } = useContext(PageSettings)
    const ability = useContext(UserAccessContext);

    const _FIELDS = [
        {
            label: 'CI_NUM',
            name: 'ciNumber',
            ref: register({ required: true })
        },
        {
            control: control,
            onChange: (value) => value,
            label: 'DATE_OF_INCORP',
            name: 'incorporationDate',
            inputType: InputTypes.DATEPICKER,
            ref: register({ required: true })
        }

    ];

    const _REJECT_INCORPORATION_REQUEST_FIELDS = [
        {
            label: 'REMARK',
            name: 'remark',
            inputType: InputTypes.TEXTAREA,
            ref: register({ required: true }),
            rows: 2,
            style: { resize: "none", height: "auto" }
        }
    ];

    const _MENU_KEYS = {
        _ANNUAL_COMPLIANCE: "Annual Compliance",
        _CUSTOMER_RELATION_MANAGER: "Customer Relation Manager",
        _KYC_RESULT: "KYC Result",
        _PORTFOLIO_DOCUMENT: "Portfolio Document",
        _PORTFOLIO_SERVICES: "Portfolio Services",
        _SERVICE_HISTORY_MODAL: "Service History Modal",
        _FINANCES: "Finances",
        _LETTER: "Letter",
    }

    const _PAGE_NAVIGATION_MENU = useMemo(() => {
        let componentStateProps = { companyProfile, userRole };

        let menus = {
            [_MENU_KEYS._PORTFOLIO_SERVICES]: {
                component: () => (
                    <li onClick={() => _history.push({
                        pathname: WebUrl._COMPANY_MANAGEMENT_SERVICES.replace(":companyId", companyId), state: { ...componentStateProps }
                    })}>
                        <img src={require('../../assets/img/ui/company-services.svg')} />{t("SERVICES")}
                    </li>),
            },
            [_MENU_KEYS._PORTFOLIO_DOCUMENT]: {
                component: () => {
                    return <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.company_portfolio_document} passThrough>
                        {
                            allowed => allowed ?
                                <li onClick={() => _history.push({
                                    pathname: WebUrl._COMPANY_MANAGEMENT_DOCUMENT.replace(":companyId", companyId),
                                    state: { ...componentStateProps }
                                })}>
                                    <img alt="" src={require('../../assets/img/ui/company-documents.svg')} />{t("DOCUMENTS")}
                                </li>
                                :
                                <></>
                        }
                    </Can>
                }
            },
            [_MENU_KEYS._FINANCES]: {
                component: () => {
                    return <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                        {
                            allowed => {
                                if (allowed) {
                                    return <>
                                        <li
                                            onClick={() => _history.push({
                                                pathname: WebUrl._COMPANY_MANAGEMENT_FINANCES.replace(":companyId", companyId),
                                                state: { ...componentStateProps }
                                            })}>
                                            <img alt="" src={require('../../assets/img/ui/company-finances.svg')} />{t("FINANCES")}</li>
                                    </>
                                } else {
                                    return <></>
                                }
                            }
                        }
                    </Can>
                },
            },
            [_MENU_KEYS._KYC_RESULT]: {
                component: () => {
                    if (!userRole.isAccountant) {
                        return (<>
                            <li
                                onClick={() => _history.push({
                                    pathname: WebUrl._COMPANY_MANAGEMENT_KYC_AML.replace(":companyId", companyId),
                                    state: { ...componentStateProps }
                                })}>
                                <img src={require('../../assets/img/ui/company-kyc.svg')} />{t("KYC_AML")}
                            </li>
                        </>);
                    }
                    return <></>
                }
            },
            [_MENU_KEYS._ANNUAL_COMPLIANCE]: {
                component: () => {
                    if ((companyProfile?.status == CompanyStatus._LIVE && userRole.isSuperAdmin) ||
                        (companyProfile?.status == CompanyStatus._LIVE && userRole.isAccountant && financialYear?.isEsaApplicable)) {
                        return (
                            <li onClick={() => _history.push({
                                pathname: WebUrl._COMPANY_MANAGEMENT_ANN_COMPLIANCES.replace(":companyId", companyId),
                                state: { ...componentStateProps }
                            })}>
                                <img src={require('../../assets/img/ui/company-annual-compliance.svg')} />{t("ANN_COMPLIANCES")}
                            </li>)
                    }
                    return <></>
                },
            },
            [_MENU_KEYS._LETTER]: {
                component: () => {
                    if (companyProfile?.status == CompanyStatus._LIVE) {
                        return (
                            <li onClick={() => _history.push({
                                pathname: WebUrl._COMPANY_MANAGEMENT_LETTERS.replace(":companyId", companyId),
                                state: { ...componentStateProps }
                            })}>
                                <img src={require('../../assets/img/ui/company-annual-compliance.svg')} />{t("LETTER")}
                            </li>)
                    }
                    return <></>
                },
            }
        };

        return menus;

    }, [companyProfile, userRole, companyId]);

    const { data: companyProfileJson, mutate: compProfileMutate } = useSWR([
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div#productionKycPage"
    ]);

    const { data: fyeJson } = useSWR(fyeId ? [
        ApiUrl._API_GET_FINANCIAL_YEAR.replace(':financialYearId', fyeId),
        ApiKey._API_GET,
        null,
        "div#productionKycPage"
    ] : null);

    useEffect(() => {

        if (companyProfileJson?.[ApiKey._API_SUCCESS_KEY]) {

            let { data } = companyProfileJson;

            setCompanyProfile(data);

            // only live company have fye
            if (data.status == CompanyStatus._LIVE) {
                setFyeId(data.currentFinancialYearId);
            }

            if (data.kycQuestion) {
                let temp = {};
                Object.keys(data.kycQuestion).map((key) => {
                    temp[key] = 0;
                });
                setCurrentTab(temp);
            }

        }

    }, [companyProfileJson]);


    const toggleRejectIncorporationRequestModal = () => {
        setShowRejectIncorporationRequestModal(prev => !prev);
    };

    const cancelRejectIncorporationRequestHandler = () => {
        toggleRejectIncorporationRequestModal();
    };

    const toggleModal = () => {
        setShowModal(prev => !prev);
    };

    const cancelHandler = () => {
        toggleModal();
    };

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const performCompanyGoLive = useCallback(async (formData) => {

        var companyId = companyProfile.id;

        await CustomFetcher(
            ApiUrl._API_COMPANY_GO_LIVE.replace(':companyId', companyId),
            ApiKey._API_POST,
            formData,
            "#go-live-form"
        ).then(res => {

            if (res[ApiKey._API_SUCCESS_KEY]) {

                compProfileMutate();
                Notiflix.Report.Success(
                    res[ApiKey._API_MESSAGE_KEY],
                    t("COMPANY_SUCCESSFULLY_GO_LIVE"),
                    t(SweetAlert._OK), () => {
                        toggleModal();
                    });
            }
            else {
                let errors = res[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(t('OPERATION_FAILURE'), firstError?.detail);
                    }
                }
            }

        })

    }, [companyProfile.id]);

    ///<summary>
    ///Author: Robin
    ///</summary>
    const rejectIncorporationRequest = useCallback(async (formData) => {

        var companyId = companyProfile.id;

        await CustomFetcher(
            ApiUrl._API_COMPANY_REJECT_INCORPORATION_REQUEST.replace(':companyId', companyId),
            ApiKey._API_POST,
            formData,
            "#reject-incorporation-request-form"
        ).then(res => {

            if (res[ApiKey._API_SUCCESS_KEY]) {
                compProfileMutate();
                Notiflix.Report.Success(
                    res[ApiKey._API_MESSAGE_KEY],
                    t("COMPANY_HAS_BEEN_REJECTED"),
                    t(SweetAlert._OK), () => cancelRejectIncorporationRequestHandler());
            }
            else {
                let errors = res[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(t('OPERATION_FAILURE'), firstError?.detail);
                    }
                }
            }

        })
    }, [companyProfile.id]);

    ///<summary>
    ///Author: Andrew
    ///</summary>
    const renderAddress = (model, defaultValue = "") => {
        if (!model) {
            return defaultValue;
        }

        let temp = [model.address1];
        if (model.address2) {
            temp.push(model.address2);
        }

        return [...temp, model?.country?.label].join(', ');
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const isGoLiveBtnShow = useMemo(() => {

        if (companyProfile && !isEmpty(companyProfile)) {
            let { status, onGoingNameCheck, processStateId, ...restCompProfileProps } = companyProfile;

            // for a company to go live, it need to be on registering status, namecheck is approved 
            // only admin role can go live
            if (status == CompanyStatus._REGISTERING &&
                onGoingNameCheck?.status == CompanyNameCheckStatus._APPROVED &&
                ability.can(PolicyActionConstant.write, PolicyObjectConstant.company) &&
                processStateId != CompanyProcessState._IDLE &&
                processStateId != CompanyProcessState._NAME_CHECK &&
                processStateId != CompanyProcessState._PENDING_INFORMATION &&
                processStateId != CompanyProcessState._PENDING_PAYMENT) {
                return true;
            }
        }

        return false;

    }, [companyProfile, ability]);

    useEffect(() => {
        stringIsNullOrEmpty(companyId) ?? _history.goBack();
    }, [companyId]);

    return (
        <div id="productionKycPage" className="panel panel-brand panel-flex" style={{ overflow: 'hidden' }}>
            {Object.keys(companyProfile).length > 0 ?
                <>
                    <div className="panel-header ">
                        <h1 className="mb-0 title title-with-dot d-flex align-items-center">
                            <NavigationButton />{t("COMPANY_PORTFOLIO")}
                        </h1>
                    </div>
                    <div className="portfolio-wrapper h-100">
                        <div className="portfolio-header">
                            <div className="text-center">
                                <img alt={`${companyProfile?.jurisdiction.name}`} src={getCountryFlagIconByJurisdictionName(companyProfile?.jurisdiction.name)}
                                    onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/country-flag/Hong Kong.png" }}
                                    className="jurisdiction-flag" /><strong className="f-s-13 p-5">{companyProfile?.jurisdiction?.name}</strong>
                            </div>
                            <div class="m-l-auto m-r-auto">
                                <div className="text-center">
                                    <div className="text-pill-brand">
                                        <strong>
                                            {companyProfile?.englishName}
                                            {companyProfile?.chineseName && <>({companyProfile?.chineseName})</>}
                                        </strong>
                                    </div>
                                    {
                                        companyProfile?.corporateIdentityNumber &&
                                        <div className="f-s-14"><strong>CI {t("NUMBER_SHORT")}:</strong> {companyProfile?.corporateIdentityNumber}</div>
                                    }
                                    <div>
                                        <strong>{t("INCORPORATION_DATE")}:</strong>
                                        {
                                            !stringIsNullOrEmpty(companyProfile.incorporationDate) ?
                                                moment(companyProfile.incorporationDate).format(DateFormat._DATE_ONLY)
                                                : '-'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="m-l-auto m-r-auto">
                                <div className="company-status-pill">
                                    <div>
                                        {
                                            companyProfile?.accountant &&
                                            <>{companyProfile?.accountant?.firm.name} {companyProfile?.accountant?.name}<br></br></>
                                        }
                                        {companyProfile?.requestor?.name}
                                    </div>
                                    {
                                        (() => {
                                            let companyStatusName = t(CompanyStatusToTranslateKey(companyProfile?.status));
                                            switch (companyProfile?.status) {
                                                case CompanyStatus._REJECTED:
                                                    return (
                                                        <span className="company-status-danger" style={{ height: "fit-content" }}>{companyStatusName}</span>
                                                    )
                                                    break;
                                                case CompanyStatus._REGISTERING:
                                                    return (
                                                        <span className="company-status-warning" style={{ height: "fit-content" }}>{companyStatusName}</span>
                                                    )
                                                    break;
                                                case CompanyStatus._LIVE:
                                                    return (
                                                        <span className="company-status-success" style={{ height: "fit-content" }}>{companyStatusName}</span>
                                                    )
                                                    break;
                                                case CompanyStatus._DEREGISTERING:
                                                    return (
                                                        <span className="company-status-warning" style={{ height: "fit-content" }}>{companyStatusName}</span>
                                                    )
                                                    break;
                                                case CompanyStatus._DEREGISTERED:
                                                    return (
                                                        <span className="company-status-danger" style={{ height: "fit-content" }}>{companyStatusName}</span>
                                                    )
                                                    break;
                                                default:
                                                    break;
                                            }
                                        })()
                                    }
                                </div>
                                <div className="portfolio-action-btn">
                                    {
                                        isGoLiveBtnShow &&
                                        <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company} passThrough>
                                            {
                                                allowed => allowed ?
                                                    <>
                                                        <div className="d-flex">
                                                            <strong className="m-auto m-r-10">{t("LIVE_OR_REJECT")}</strong>
                                                        </div>
                                                        <button type="button" className="btn btn-rounded btn-green portfolio-live-reject-btn" onClick={() => toggleModal(true)}><i className="fas fa-check"></i>{t("APPROVE")}</button>
                                                        <button type="button" className="btn btn-rounded btn-red portfolio-live-reject-btn" onClick={() => { setShowRejectIncorporationRequestModal(true) }}><i className="fas fa-times"></i>{t("REJECT")}</button>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </Can>
                                    }
                                </div>
                            </div>

                            <div className="hidden-menu-icon">
                                <i class="las la-angle-double-down" onClick={() => { setHiddenMenu(!hiddenMenu) }}></i>
                            </div>
                        </div>
                        <div className="portfolio-body">
                            {
                                <ul className={`portfolio-tabs ${hiddenMenu && "show-hidden"}`}>
                                    {
                                        Object.entries(_PAGE_NAVIGATION_MENU).map(([k, v], index) => (React.createElement(v.component)))
                                    }
                                </ul>
                            }
                            <Row>
                                <Col md={6} className="p-0">
                                    <div className="portfolio-section h-100">
                                        <p>
                                            <Row>
                                                <Col md={4}><strong>{t("REGISTERED_ADDRESS")}</strong></Col>
                                                <Col md={8}>{renderAddress(companyProfile?.address?.registeredAddress, "10/F., Tower A, Billion Centre, 1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, China")}</Col>
                                            </Row>
                                        </p>
                                        <p>
                                            <Row>
                                                <Col md={4}><strong>{t("MAILING_ADDRESS")}</strong></Col>
                                                <Col md={8}>{renderAddress(companyProfile?.address?.deliveryAddress, "10/F., Tower A, Billion Centre, 1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, China")}</Col>
                                            </Row>
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} className="p-0">
                                    <div className="portfolio-section pt-0">
                                        {
                                            companyProfile?.shareCapitals?.length == 0 ?
                                                <p className="mt-3">{t("CAPITAL_SHARES_UNAVAILABLE")}</p>
                                                :
                                                <>
                                                    <Tabs value={currentShareTab} onChange={(e, tab) => { setCurrentShareTab(tab) }}>
                                                        {
                                                            companyProfile?.shareCapitals?.map((share, index) => {
                                                                return <Tab label={t(`incorporation:${share.classOfShare.name}`)} />
                                                            })
                                                        }
                                                    </Tabs>
                                                    {
                                                        companyProfile?.shareCapitals?.map((share, index) => {
                                                            return (
                                                                <TabPanel value={currentShareTab} index={index}>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{t("CURRENCY")}</th>
                                                                                <td>{share.currency.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th width="300">{t("SHARE_CAPITAL")}</th>
                                                                                <td>{share.currency.code} &nbsp;<NumberFormat value={share.shareCapital} displayType={'text'} thousandSeparator={true} /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th width="300">{t("SHARE_ALLOTED")}</th>
                                                                                <td>{share.currency.code} &nbsp;<NumberFormat value={share.issuedShares} displayType={'text'} thousandSeparator={true} /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th width="300">{t("ISSUED_SHARE")}</th>
                                                                                <td><NumberFormat value={share.issuedShares} displayType={'text'} thousandSeparator={true} /></td>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </TabPanel>
                                                            )
                                                        })
                                                    }
                                                </>
                                        }
                                    </div>
                                </Col>
                                <Col md={6} className="p-0">
                                    <div className="portfolio-section">
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <strong className="officer-label">{t("SHAREHOLDERS")} {companyProfile?.officers?.filter(o => o.isShareholder).length > 0 ? `(${companyProfile?.officers?.filter(o => o.isShareholder).length})` : ''}</strong>
                                                    <div className="officer-list">
                                                        {
                                                            companyProfile?.officers?.filter(o => o.isShareholder).length == 0 &&
                                                            <div>{t("NO_SHAREHOLDER_AVAILABLE")}</div>
                                                        }
                                                        {
                                                            companyProfile?.officers?.filter(o => o.isShareholder).map((o) => {
                                                                let shares = o.shares.map(s => {
                                                                    let found = companyProfile.shareCapitals.find(sc => sc.id === s.companyShareCapitalId);
                                                                    let result = {
                                                                        typeOfShare: t(found.classOfShare.name.toUpperCase()) + " - " + found.currency.code,
                                                                        sharePercentage: Math.round((s.shareAllocated / found.issuedShares * 100 + Number.EPSILON) * 100) / 100,
                                                                        shareAllocated: s.shareAllocated
                                                                    };
                                                                    return result;
                                                                })
                                                                return <div className="justify-content-between">
                                                                    <div>
                                                                        <strong>
                                                                            {`${o.officer.englishName}`} {o.officer?.chineseName}
                                                                        </strong>
                                                                    </div>
                                                                    <div>
                                                                        <ButtonRound medium type={BtnTypes.PIE_CHART} style={{ cursor: 'initial', marginRight: '3px' }}
                                                                            title={shares.map(x => <strong><span>{x.typeOfShare}
                                                                                &nbsp;<NumberFormat value={x.shareAllocated} displayType={'text'} thousandSeparator={true} />&nbsp;
                                                                                ({x.sharePercentage}%)</span></strong>)} />

                                                                        <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={() => _history.push(
                                                                            {
                                                                                pathname: WebUrl._COMPANY_MANAGEMENT_OFFICER_PROFILE.replace(":companyId", companyId),
                                                                                state: {
                                                                                    data: {
                                                                                        officerId: o.officerId,
                                                                                        officerTypeId: o.officer.officerTypeId,
                                                                                        companyId: companyId,
                                                                                        companyProfile: companyProfile,
                                                                                        isShareHolder: o.isShareholder,
                                                                                        isDirector: o.isDirector,
                                                                                        status: o.status,
                                                                                        pageTitle: "Production KYC Details",
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </div>

                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <strong className="officer-label">{t("COMPANY_SECRETARY")}</strong>
                                                    <div className="officer-list">
                                                        {
                                                            companyProfile?.companySecretary
                                                                ?
                                                                <div>
                                                                    <strong>{companyProfile.companySecretary.englishName} {companyProfile.companySecretary.englishSurname}</strong>
                                                                    <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={() => _history.push(
                                                                        {
                                                                            pathname: WebUrl._COMPANY_MANAGEMENT_OFFICER_PROFILE.replace(":companyId", companyId),
                                                                            state: {
                                                                                data: {
                                                                                    officerId: companyProfile?.companySecretary?.id,
                                                                                    officerTypeId: companyProfile?.companySecretary?.officerTypeId,
                                                                                    companyId: companyId,
                                                                                    companyProfile: companyProfile,
                                                                                    status: companyProfile?.status,
                                                                                    pageTitle: "Production KYC Details",
                                                                                }
                                                                            }
                                                                        }
                                                                    )} />
                                                                </div>
                                                                :
                                                                <div>
                                                                    <strong>YICOM</strong>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div>
                                                    <strong className="officer-label">{t("DIRECTORS")} {companyProfile?.officers?.filter(o => o.isDirector).length > 0 ? `(${companyProfile?.officers?.filter(o => o.isDirector).length})` : ''}</strong>
                                                    <div className="officer-list">
                                                        {
                                                            companyProfile?.officers?.filter(o => o.isDirector).length == 0 &&
                                                            <div>{t("incorporation:NO_DIRECTOR")}</div>
                                                        }
                                                        {
                                                            companyProfile?.officers?.filter(o => o.isDirector).map((o) => {
                                                                return <div>
                                                                    <strong>{`${o.officer.englishName}`} {o.officer?.chineseName}</strong>
                                                                    <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={() => _history.push(
                                                                        {
                                                                            pathname: WebUrl._COMPANY_MANAGEMENT_OFFICER_PROFILE.replace(":companyId", companyId),
                                                                            state: {
                                                                                data: {
                                                                                    officerId: o.officerId,
                                                                                    officerTypeId: o.officer.officerTypeId,
                                                                                    companyId: companyId,
                                                                                    companyProfile: companyProfile,
                                                                                    isShareHolder: o.isShareholder,
                                                                                    isDirector: o.isDirector,
                                                                                    status: o.status,
                                                                                    pageTitle: "Production KYC Details",
                                                                                }
                                                                            }
                                                                        })} />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <BrandModal
                        isOpen={showModal}
                        toggler={cancelHandler}
                        title={t("IS_COMPANY_READY_TO_LIVE")}
                        customBody
                    >
                        <ModalBody id={"go-live-form"}>
                            <Row className="form-group">
                                <Col xl={4} md={12}>
                                    <InputHoc label="COMPANY_ENGLISH_NAME" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.englishName} />
                                </Col>
                                <Col xl={4} md={12}>
                                    <InputHoc label="COMPANY_CHINESE_NAME" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.chineseName} />
                                </Col>
                                <Col xl={4} md={12}>
                                    <InputHoc label="JURISDICTION" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.jurisdiction?.name} />
                                </Col>
                            </Row>
                            <form onSubmit={handleSubmit(performCompanyGoLive)} ref={_formRef}>
                                <Row>
                                    {
                                        _FIELDS.map((item, index) => {
                                            return <>
                                                <Col xl={12} key={index}>
                                                    <InputHoc {...item} formGroupClass='m-b-5' error={errors?.[item.name]} />
                                                </Col>
                                            </>
                                        })
                                    }
                                </Row>
                            </form>
                        </ModalBody>
                        <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                            <button type="button" className="btn btn-themed grayscale-100 btn-min-width" onClick={cancelHandler}>{t("CANCEL")}</button>
                            <button type="submit" className="btn btn-themed btn-min-width" onClick={() => submitForm(_formRef)}>{t("SUBMIT")}</button>
                        </ModalFooter>
                    </BrandModal>

                    <BrandModal
                        isOpen={showRejectIncorporationRequestModal}
                        toggler={cancelRejectIncorporationRequestHandler}
                        title={t("REJECT_INCORP_REQ")}
                        customBody
                    >
                        <ModalBody id="reject-incorporation-request-form">
                            <Row className="form-group">
                                <Col xl={4} md={12}>
                                    <InputHoc label="COMPANY_ENGLISH_NAME" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.englishName} />
                                </Col>
                                <Col xl={4} md={12}>
                                    <InputHoc label="COMPANY_CHINESE_NAME" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.chineseName} />
                                </Col>
                                <Col xl={4} md={12}>
                                    <InputHoc label="JURISDICTION" formGroupClass='m-b-5' disabled={true} defaultValue={companyProfile?.jurisdiction?.name} />
                                </Col>
                            </Row>
                            <form onSubmit={handleSubmit(rejectIncorporationRequest)} ref={_formRef}>
                                <Row>
                                    {
                                        _REJECT_INCORPORATION_REQUEST_FIELDS.map((item, index) => {
                                            return <>
                                                <Col xl={12} key={index}>
                                                    <InputHoc {...item} formGroupClass='m-b-5' error={errors?.[item.name]} />
                                                </Col>
                                            </>
                                        })
                                    }
                                </Row>
                            </form>
                        </ModalBody>
                        <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                            <button type="button" className="btn btn-themed grayscale-100 btn-min-width" onClick={cancelRejectIncorporationRequestHandler}>{t("CANCEL")}</button>
                            <button type="submit" className="btn btn-themed btn-min-width" onClick={() => submitForm(_formRef)}>{t("SUBMIT")}</button>
                        </ModalFooter>
                    </BrandModal>
                </>
                :
                <div className="d-flex flex-grow-1">
                    <img className="company-not-found-img" src={require("../../assets/img/ui/graphic-nodatafound.svg")} />
                    <figcaption className="no-found-text-pos-absolute-bottom">{t("COMPANY_NOT_FOUND")}</figcaption>
                </div>
            }
        </div >
    )
}

export default CompanyPortfolio;
