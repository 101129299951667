import React, { useMemo, useState, useEffect, useRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { useHistory } from 'react-router-dom';
import { PaymentMethod, InputTypes, ApiKey, WebUrl, DateFormat, PaymentStatus, BtnTypes, ApiUrl, Icon, DefaultCurrency, DefaultCurrencyId, TableId } from '../../util/Constant';
import DatePicker from "react-datepicker";
import { Row, Col, Card, CardBody, Button, Label, Form } from "reactstrap";
import { useForm } from 'react-hook-form';
import ViewReceiptModal from './ViewReceiptModal';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import moment from 'moment';
import ButtonRound from "components/buttons/ButtonRound";
import NumberFormat from "react-number-format";
import { useResizeDetector } from "react-resize-detector";
import InputHoc from 'components/form/InputHoc';
import { authCredentialState } from "recoil/Atoms";
import { useRecoilValue } from "recoil";
import { formatNumber } from "util/Utility";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis
/// </summary>
const PaymentHistory = props => {
    const _formRef = useRef();
    const _history = useHistory();
    const { t } = useTranslation();
    const { ref: _tableWrapperRef } = useResizeDetector();
    const [isModalOpen, setModalOpen] = useState(false);
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [selectedRow, setSelectedRow] = useState();
    const { register, control, handleSubmit, errors, reset, watch, setValue } = useForm();
    const today = useMemo(() => moment().toDate(), []);
    const { currencyPreference, id:userId } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const columns = useMemo(() => [
        {
            Header: 'ORDER_DATE',
            headerStyle: { width: '1%' },
            className: 'nowrap',
            accessor: el => el?.order?.createdDate ? moment(el.order?.createdDate).format(DateFormat._DATE_FORMAT) : ' - '
        },
        {
            Header: 'PAYMENT_DATE',
            headerStyle: { width: '1%' },
            className: 'nowrap',
            accessor: el => el?.processedDate ? moment(el.processedDate).format(DateFormat._DATE_FORMAT) : ' - '
        },
        {
            Header: 'TRANSACTION_ID',
            accessor: 'transactionId',
            isDefaultHidden: true
        },
        {
            Header: 'PAYMENT_TYPE',
            accessor: "paymentType.name",
            Cell: ({ row }) => (
                <>
                    <strong>{row.original.paymentType.name}</strong>
                </>
            )
        },
        {
            Header: 'ACCOUNTANT',
            accessor: "paidBy.name",
            isDefaultHidden: true
        },
        {
            Header: 'MATTER',
            accessor: el => {
                var result = "";
                el?.order?.orderCarts && el.order.orderCarts.map(item => result += item.serviceRequest?.productMatter.name);
                return result;
            },
            Cell: ({ row }) => (
                row.original.order.orderCarts.map((item) => {
                    return (
                        <div className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            {item.serviceRequest?.productMatter.name}<br />
                        </div>
                    )
                })
            ),
            isRequiredColumn: true
        },
        {
            Header: "COMPANY_NAME",
            accessor: el => {
                var result = "";
                el?.order?.orderCarts && el.order.orderCarts.map(item => result += item.serviceRequest?.company.englishName);
                return result;
            },
            className: 'nowrap overflow-hidden text-truncate',
            Cell: ({ row }) => (
                row.original.order.orderCarts.map((item) => {
                    return (
                        <div className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            {item.serviceRequest?.company.englishName}<br />
                        </div>
                    )
                })
            ),
            isRequiredColumn: true
        },
        {
            Header: 'PRICE',
            Cell: ({ row }) => <div className="d-flex flex-column">
                <NumberFormat value={row.original.totalAmount} thousandSeparator displayType={'text'} prefix={`${defaultCurrencyCode} `} decimalScale={2} fixedDecimalScale />
                {
                    currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                    <NumberFormat className="quote-currency" value={row.original.totalAmount * currencyPreference?.exchangeRate?.rate} thousandSeparator displayType={'text'} prefix={`≈ ${currencyPreference?.code} `} decimalScale={2} fixedDecimalScale />
                }

            </div>,
            accessor: 'totalAmount',
            headerStyle: { width: '1%' },
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            headerStyle: { width: '1%' },
            className: 'text-center',
            Cell: ({ row }) => {
                let status = {};

                switch (row.original.status) {
                    case PaymentStatus._SUCCESS:
                        status = { classes: 'badge badge-success', text: t('SUCCESS'), icon: 'fa fa-check' };
                        break;
                    case PaymentStatus._PENDING:
                        status = { classes: 'badge badge-warning', text: t('PENDING'), icon: 'las la-spinner' };
                        break;
                    case PaymentStatus._FAILED:
                        status = { classes: 'badge badge-danger', text: t('FAILED'), icon: 'fa fa-times' };
                        break;
                }

                return <span className={status.classes}>{status.text}</span>
            },
        },
        {
            Header: "RECEIPT",
            headerStyle: { width: '1%' },
            Cell: ({ row }) => (row.original.status == PaymentStatus._SUCCESS ? (
                <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => {
                    _history.push({
                        pathname: WebUrl._PAYMENT_CHECKOUT, state: {
                            data: {
                                paymentId: row.original.id
                            }
                        }
                    })
                }} />
            ) : <></>),
            disableFilters: true,
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], [currencyPreference]);

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        reset();
        setFiltersForm({});
    };

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const filterOnSubmitHandler = (value) => {
        var startDate = moment(value.startDate).format(DateFormat._DATE_ONLY);
        var endDate = moment(value.endDate).format(DateFormat._DATE_ONLY);
        let tempObj = {
            ...value,
            startDate: startDate,
            endDate: endDate,
        }
        setFiltersForm(tempObj);
    };

    const goTo = (row) => {
        _history.push({
            pathname: WebUrl._PAYMENT_CHECKOUT, state: {
                data: {
                    paymentId: row.id
                }
            }
        })
    }

    const tableHeight = useMemo(() => {
        if (_tableWrapperRef.current != null) {
            return _tableWrapperRef.current.clientHeight;
        }
    }, [_tableWrapperRef?.current]);

    return <>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("PAYMENT_HISTORY")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="panel-brand p-0 h-100">
                    <div className="d-flex flex-column h-100">
                        <form onSubmit={handleSubmit(filterOnSubmitHandler)} autoComplete='off'>
                            <Row className="row-top-head-misc">
                                <Col xl={10}>
                                    <Row>
                                        <Col xl={3}>
                                            <InputHoc prefix={{ icon: Icon._CALENDER }} label='Start Date' smallnote={t('START_DATE')} name='startDate' inputType={InputTypes.DATEPICKER} control={control}
                                                onChange={(value) => { moment(value).isAfter(watch('endDate')) && setValue('endDate', value) }} defaultValue={today} />
                                        </Col>
                                        <Col xl={3}>
                                            <InputHoc prefix={{ icon: Icon._CALENDER }} label='End Date' smallnote={t('END_DATE')} name='endDate' inputType={InputTypes.DATEPICKER} control={control}
                                                minDate={watch("startDate") ?? today} defaultValue={today} />
                                        </Col>
                                    </Row>
                                    {/* {<FormBuilder
                                        fields={PaymentHistoryFilters({})}
                                        formRef={_formRef}
                                        onSubmit={filterOnSubmitHandler}
                                        reset={resetFilters}
                                        resetTriggerReset={setResetFilters}
                                        resetValues={{}}
                                    />} */}
                                </Col>
                                <Col xl={2} className="btn-list d-flex">
                                    <button type="submit" className="btn btn-themed btn-brand-round m-l-auto" /*onClick={() => submitForm(_formRef)}*/><i className="las la-search" /></button>
                                    <button type="button" className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                </Col>
                            </Row>
                        </form>
                        <div className="table-brand-wrapper h-100" style={{ flex: 1 }} ref={_tableWrapperRef}>
                            <div style={{ height: tableHeight }}>
                                <ReactTable
                                    columns={columns}
                                    url={ApiUrl._API_GET_PAYMENT_HISTORY_LIST}
                                    filterFormfield={filtersForm}
                                    setPagination
                                    stickyFooter
                                    stickyHeader
                                    getRowProps={(row) => {
                                        let props = row.getRowProps(row);
                                        return {
                                            ...props,
                                            onClick: () => {
                                                setSelectedRow(row);
                                            },
                                            onDoubleClick: () => {
                                                goTo(row.original);
                                            },
                                            className: row == selectedRow ? "selected-row" : ""
                                        }
                                    }}
                                    enableSearch
                                    tableColumnPreference={[userId, TableId._PAYMENT_HISTORY]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ViewReceiptModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </>;
}

const PaymentHistoryFilters = () => {
    const _FORM_FIELDS = [
        {
            rowOptions: { xl: 2 },
            columns: [
                { prefix: { icon: Icon._CALENDER }, label: 'Start Date', smallnote: 'Start Date', name: 'startDate', input: InputTypes.DATEPICKER, columnOptions: { xl: 3 }, defaultValue: moment().toDate() },
                { prefix: { icon: Icon._CALENDER }, label: 'End Date', smallnote: 'End Date', name: 'endDate', input: InputTypes.DATEPICKER, columnOptions: { xl: 3 }, defaultValue: moment().toDate() },
            ]
        }
    ]

    return _FORM_FIELDS;
}


export default PaymentHistory;
