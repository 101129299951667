import React from 'react';
import { Backdrop, makeStyles } from '@material-ui/core';

const DocumentViewer = props => {
    let { isViewing, setIsViewing, file, style } = props;
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            ...style
        },
    }));

    const classes = useStyles();

    return <>
        <Backdrop open={isViewing} className={classes.backdrop}>
            <div className="w-100 h-100" style={{
                display: "flex",
                flexDirection: "column"
            }}>
                <div style={{
                    backgroundColor: "rgb(50, 54, 57)",
                    textAlign: "right"
                }}>
                    <div className="btn expand-theme m-5 btn-sm" style={{ color: "white" }} id="close_btn" onClick={() => { setIsViewing(false) }}>
                        <i class="las la-times"></i>
                    </div>
                </div>
                <object className="w-100 height-full" data={`${file}`} type="application/pdf">
                </object>
            </div>
        </Backdrop>
    </>;
}

export default DocumentViewer;