import React, { useRef, useEffect, useState, useMemo } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, ApiKey, BtnTypes, LoadingStateText } from 'util/Constant';
import Notiflix, { Report, Confirm, Notify, Block } from 'notiflix';
import FactSheetForm from '../forms/FactSheetForm';
import FactSheetGroupManagement from '../FactSheetGroupManagement';
import FormBuilder from 'components/form/FormBuilder';
import { FactSheetDao } from 'data';
import BrandModal from 'components/modals/BrandModal';
import ButtonRound from 'components/buttons/ButtonRound';
import { useTranslation } from 'react-i18next';

const FactSheetWizard = props => {

    const { t } = useTranslation();
    const { factSheetId } = props;
    const [refreshTable, setRefreshTable] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [modal, setModal] = useState(false);
    const [isAdd, setIsAdd] = useState(false);

    return (
        <>
            <div className="section-split">
                <div className="section-wrapper flex-grow-1">
                    <div className="section-header justify-content-end">
                        <button type="button" className="btn btn-themed btn-min-width" style={{ padding: '0.6rem 1rem', height: '38px' }} onClick={() => { setModal(!modal); setIsAdd(true) }}>{t("systemConfiguration:ADD_GROUP")}</button>
                    </div>
                    <div className="section-body">
                        <FactSheetGroupManagement setSelectedGroup={setSelectedGroup} refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
                    </div>
                </div>
                <div className="section-wrapper flex-grow-1" id="factSheetGroupItems">
                    <div className="section-header justify-content-between">
                        {
                            selectedGroup &&
                            <>
                                <h4 className="mb-0 mr-2">{selectedGroup.name}</h4>
                                <ButtonRound medium type={BtnTypes.EDIT} onClick={() => setModal(true)} />
                            </>
                        }
                    </div>
                    <div className="section-body y-scrollbar-2" style={{ flex: 1 }}>
                        <FactSheetForm factSheetId={factSheetId} selectedGroupId={selectedGroup?.id} />
                    </div>
                </div>
            </div>
            <CategoriesModal modal={modal} setModal={setModal}
                selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
                setRefreshTable={setRefreshTable} isAdd={isAdd} setIsAdd={setIsAdd} />
        </>
    );
};

const CategoriesModal = props => {
    const { modal, setModal, selectedGroup: propsSelectedGroup, setSelectedGroup: propsSetSelectedGroup, setRefreshTable, isAdd, setIsAdd } = props;
    const { t } = useTranslation();
    const _ref = useRef();
    const [reset, setReset] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [iconList, setIconList] = useState([]);
    let factsheetDao = new FactSheetDao();
    const _FIELDS = useMemo(() => [
        {
            columns: [
                { label: "CATEGORY", name: "name", input: InputTypes.INPUT, rules: { required: 'Category Name is required' } }
            ]
        },
        {
            columns: [
                { label: "ICON", name: "iconClassId", input: InputTypes.SELECT, options: iconList, rules: { required: "Icon is required" } }
            ]
        },
        {
            columns: [
                { label: 'INDEX', name: "sortKey", input: InputTypes.NUMBER_INPUT, rules: { required: "Index is required" } }
            ]
        }
    ], [iconList]);

    const toggle = () => {
        setModal(!modal);
        setIsAdd(false);
    }

    const getFactSheetItemGroupIconList = async () => {
        let factsheetDao = new FactSheetDao();
        await factsheetDao.getIconList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                let tempData = data.map(i => {
                    return {
                        value: i.id,
                        label: i.name,
                        icon: i.iconClassname
                    }
                });
                setIconList(tempData);
            }
        })
    }

    useEffect(() => {
        getFactSheetItemGroupIconList();
    }, []);

    const createOrUpdateGroup = async (group) => {

        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);

        if (selectedGroup) {
            group["id"] = selectedGroup.id;
        }

        await factsheetDao.createOrUpdateGroup(group).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setRefreshTable(prevState => !prevState);
                toggle();
                propsSetSelectedGroup(response[ApiKey._API_DATA_KEY]);
                Notiflix.Notify.Success(selectedGroup ? t('notiflix:GROUP_EDITED') : t("notiflix:GROUP_ADDED"));
            }
        }).finally(() => Block.Remove(".modal-content"));
    }

    //<summary>
    //Author: Sze Hua
    //</summary>
    useEffect(() => {
        if (modal) {
            setSelectedGroup(isAdd ? null : propsSelectedGroup);
            setReset(prev => !prev);
        }
    }, [modal]);

    return (<>
        <BrandModal modalSize="md" isOpen={modal} title={selectedGroup ? t("systemConfiguration:EDIT_GROUP") : t("systemConfiguration:ADD_NEW_GROUP")} customBody toggler={toggle} centered backdrop='static'>
            <ModalBody>
                <FormBuilder onSubmit={createOrUpdateGroup} fields={_FIELDS} formRef={_ref}
                    reset={reset} resetTriggerReset={setReset} resetValues={selectedGroup}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-themed btn-min-width" onClick={() => {
                    _ref.current.dispatchEvent(new Event('submit', { cancelable: true }));
                }}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>)
}

export default FactSheetWizard;