import React, { useState, useEffect, useContext, useRef } from "react";
import { PageSettings } from '../../config/page-settings.js';
import StepZilla from "react-stepzilla";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, InputTypes, Language, OTPKey, SessionKey, WebUrl } from "../../../src/util/Constant"
import { GoUnverified, GoVerified } from "react-icons/go";
import OtpInput from 'react-otp-input';
import { OTPSettings } from '../../util/Constant';
import { Button, Label, FormGroup, Form, Row, Col } from "reactstrap";
import { Card, CardBody } from 'reactstrap'
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import { appState, authCredentialState, isLoggedInState, registerFormState } from "recoil/Atoms.js";
import InputHoc from "components/form/InputHoc.js";
import Notiflix, { Block, Notify, Report } from 'notiflix';
import CircularProgress from '@material-ui/core/CircularProgress';
import OtpModal from '../../components/modals/OtpModal';

import AccountRegistrationDao from "data/AccountRegistrationDao.js";
import CommonDao from "data/CommonDao.js";
import { createMultiPartFormBody, stringIsNullOrEmpty, InitLanguage } from "util/Utility.js";
import { isPhoneNumExisted, isEmailExisted, isUsernameExisted, checkPasswordComplexity } from "./forms/RegistrationUtils.js";
import classNames from "classnames";
import { CustomFetcher } from "util/CustomFetcher.js";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import DataAccessObject from "data/DataAccessObject.js";
import { Config, ConfigEnum } from "util/Config.js";

/// <summary>
/// Author: Lewis
/// </summary>
const Registration = props => {
    const _context = useContext(PageSettings);
    const _formRef = useRef(null);
    const [registerFormValue, setRegisterFormState] = useRecoilState(registerFormState);
    const resetRegisterFormState = useResetRecoilState(registerFormState);
    const setAppState = useSetRecoilState(appState);
    const _history = useHistory();
    const { t, i18n } = useTranslation();

    const { data: languageList } = useSWR(
        ApiUrl._API_GET_LANGUAGE_LIST,
        async (url) => {
            let dao = new DataAccessObject();
            let tempList = [];
            await dao.get(url).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    tempList = jsonResponse[ApiKey._API_DATA_KEY];
                }
            });
            return tempList;
        }
    );

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);
        return (() => {
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
            resetRegisterFormState();
        });
    }, []);

    ///  <summary>
    /// Author: CJ(Jiann)
    /// </summary>
    useEffect(() => {
        if(_history.location.search != ""){
            let language = new URLSearchParams(window.location.search).get('lang');
            var selectedLanguage = InitLanguage(language);
            i18n.changeLanguage(selectedLanguage);
            localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
        }
    }, [_history])

    const _wizardFormArray = [
        { name: t('FIRM_DETAIL'), component: <RegisterFirmDetails setRegisterFormState={setRegisterFormState} registerFormValue={registerFormValue} /> },
        { name: t('PERSONAL_DETAIL'), component: <RegisterPersonalDetails setRegisterFormState={setRegisterFormState} registerFormValue={registerFormValue} /> },
        { name: t('VERIFICATION'), component: <RegisterVerificationAttachment setRegisterFormState={setRegisterFormState} registerFormValue={registerFormValue} setAppState={setAppState} /> },
        { name: t('TERM_N_CONDITION'), component: <RegisterTnc setRegisterFormState={setRegisterFormState} registerFormValue={registerFormValue} /> },
    ];

    return (
        <>
            <div className="container d-flex flex-column h-100" id="RegistrationPage">
                <Card className="card-register panel-brand mt-auto" style={{ margin: 0 }}>
                    <div className="pt-4 y-scrollbar-2" style={{overflowY: "auto"}}>
                        <div className="text-center">
                            <h1 className="title title-with-dot">{t('REGISTER')}</h1>
                        </div>
                        <StepZilla
                            preventEnterSubmission={true}
                            showNavigation={false}
                            stepsNavigation={false}
                            steps={_wizardFormArray}
                            showSteps={true}
                            nextButtonText="Next"
                            backButtonText="Back"
                        />
                    </div>
                </Card>
                <div className="d-flex align-items-center p-l-15 p-t-10 mb-auto">
                    <span>{t('LANGUAGE')}</span>
                    <div className="d-flex">
                        {languageList && (
                            languageList.map(data => (
                                <div className={"pointer-cursor countries language-" + data.code} onClick={() => { i18n.changeLanguage(data.code) }} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

/// <summary>
/// Author: Lewis
/// </summary>
const RegisterFirmDetails = ({ jumpToStep, setRegisterFormState, registerFormValue }) => {

    const { register, handleSubmit, control, errors } = useForm({ defaultValues: registerFormValue });
    const [servicesList, setServicesList] = useState([]);
    const [expertiseList, setExpertiseList] = useState([]);
    const { t } = useTranslation();
    const { isXsDevices } = useContext(PageSettings);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = (data) => {
        setRegisterFormState(prevState => {
            let firmSubmitData = {};
            var servicesIdArr = new Array(), experiseIdArr = new Array();

            var servicesArr = data?.services;
            var experisesArr = data?.expertise;

            firmSubmitData['services'] = servicesArr;
            firmSubmitData['expertise'] = experisesArr;

            if (servicesArr) {
                servicesArr.map(x => {
                    servicesIdArr.push({
                        id: x.id,
                        value: x.id,
                        label: servicesList.find((e) => { return e.id == x.id }).name
                    })
                });
                firmSubmitData['firm.services'] = servicesIdArr;
                servicesIdArr.map((item, idx) => {
                    firmSubmitData[`firm.services[${idx}].Id`] = item.id
                });
            }

            if (experisesArr) {
                experisesArr.map(x => {
                    experiseIdArr.push({
                        id: x.id,
                        value: x.id,
                        label: expertiseList.find((e) => { return e.id == x.id }).name
                    });
                });
                firmSubmitData['firm.expertise'] = experiseIdArr;
                experiseIdArr.map((item, idx) => {
                    firmSubmitData[`firm.expertise[${idx}].Id`] = item.id
                });
            }

            firmSubmitData['firm.name'] = data.firmName;
            firmSubmitData['firm.practitionerName'] = data.practitionerName;

            return { ...prevState, ...firmSubmitData, currentStep: 1 }
        });
        jumpToStep(1);
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchServicesList = async () => {
        let commonDao = new CommonDao();

        await commonDao.getServicesList().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var services = responseJson[ApiKey._API_DATA_KEY];
                services.map(item => {
                    item['label'] = item['name'];
                    item['value'] = item['id'];
                })
                setServicesList(services);
            }
        })
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchExpertisesList = async () => {
        let commonDao = new CommonDao();

        await commonDao.getExpertisesList().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var expertises = responseJson[ApiKey._API_DATA_KEY];
                expertises.map(item => {
                    item['label'] = item['name'];
                    item['value'] = item['id'];
                })
                setExpertiseList(expertises);
            }
        })
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (servicesList.length === 0) fetchServicesList();
        if (expertiseList.length === 0) fetchExpertisesList();
    }, [])

    return (
        <>
            <form className="panel-pad-body registrationInnerForm y-scrollbar-2" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xl={6} md={6}>
                        <InputHoc label='FIRM_NAME' name='firmName' inputType={InputTypes.INPUT} ref={register({ required: 'Firm Name is required.' })} defaultValue={registerFormValue["firm.name"]} error={errors?.firmName?.message} />
                        <InputHoc label='PRACTITIONER_NAME' name='practitionerName' inputType={InputTypes.INPUT} ref={register({ required: 'Practitioner Name is required.' })} defaultValue={registerFormValue["firm.practitionerName"]} error={errors?.practitionerName?.message} />
                    </Col>
                    <Col xl={6} md={6}>
                        <InputHoc formGroupClass="brand-dropdown" label='EXPERTISE' isMulti={true} selectClasses='h-auto' name='expertise' inputType={InputTypes.SELECT} control={control} options={expertiseList} rules={{ required: 'Expertise is required.' }} defaultValue={registerFormValue["firm.expertise"].length > 0 ? registerFormValue["firm.expertise"] : null} error={errors?.expertise?.message} />
                        <InputHoc formGroupClass="brand-dropdown" label='SERVICES' isMulti={true} selectClasses='h-auto' name='services' inputType={InputTypes.SELECT} control={control} options={servicesList} rules={{ required: 'Services is required.' }} defaultValue={registerFormValue["firm.services"] > 0 ? registerFormValue["firm.services"] : null} error={errors?.services?.message} />
                    </Col>
                </Row>
            </form>
            <div className="panel-foot panel-foot-buttons">
                <div className="btn-min-width empty"></div>
                <Link to={WebUrl._LOGIN} className="btn btn-link-secondary">{t('RETURN_TO_LOGIN')}</Link>
                <button type="button" onClick={handleSubmit(onSubmit)} className={`btn btn-themed ${isXsDevices ? 'btn-group' : 'btn-min-width'}`}>{isXsDevices ? <i class="las la-angle-right"></i> : t('NEXT')}</button>
            </div>
        </>
    )
};

/// <summary>
/// Author: Lewis
/// </summary>
const RegisterPersonalDetails = ({ jumpToStep, setRegisterFormState, registerFormValue }) => {
    const { register, control, handleSubmit, watch, errors, trigger } = useForm({ defaultValues: registerFormValue });
    const password = useRef({});
    const urlQuery = useQuery();
    const { t } = useTranslation();
    const { isXsDevices } = useContext(PageSettings);

    useEffect(() => {
        setRegisterFormState(prevState => ({ ...prevState, currentStep: 1 }));
    }, []);

    password.current = watch("password", "");

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = async (data) => {
        let password = data?.password;
        let isValid = await checkPasswordComplexity(password);
        if (!isValid) {
            return;
        }

        let emailVerification = false;
        if (registerFormValue?.email === data?.email && registerFormValue?.emailVerification) {
            emailVerification = true;
        }
        setRegisterFormState(prevState => ({ ...prevState, ...data, emailVerification: emailVerification, currentStep: 2 }));
        jumpToStep(2);
    }

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    const isReferralCodeExist = async (referralCode) => {
        if (stringIsNullOrEmpty(referralCode)) {
            return true;
        }

        let dao = new AccountRegistrationDao();

        return await dao.checkReferralCode(referralCode).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                return true
            }
            return false
        })
    }

    return (
        <>
            <form className="panel-pad-body registrationInnerForm y-scrollbar-2" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-6">
                        <InputHoc type="input" label='NAME' name='name' inputType={InputTypes.INPUT}
                            ref={register({ required: 'Name is required.', maxLength: 50 })} maxLength={50}
                            error={errors?.name?.message} />
                    </div>
                    <div className="col-md-6">
                        <InputHoc type="input" label='EMAIL' name='email' inputType={InputTypes.INPUT}
                            ref={register({
                                required: 'Email is required.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address"
                                },
                                validate: async value => await isEmailExisted(value) || "This email address already exists"
                            })} error={errors?.email?.message} autoComplete="new-password" />
                    </div>
                    {/* <div className="col-md-6">
                        <InputHoc type="input" label='Username' name='username' inputType={InputTypes.INPUT}
                            ref={register({
                                required: 'Username is required.',
                                validate: async value => await isUsernameExisted(value) || "This username already exists"
                            })}
                            error={errors?.username?.message} />
                    </div> */}
                    <div className="col-md-6">
                        <InputHoc type="password" label='PASSWORD' name='password' inputType={InputTypes.INPUT}
                            ref={register({
                                required: 'Password is required.'
                            })}
                            onChange={() => trigger("retypePassword")}
                            error={errors?.password?.message} autoComplete="new-password" />
                    </div>
                    <div className="col-md-6">
                        <InputHoc type="password" label='RETYPE_PASSWORD' name='retypePassword' inputType={InputTypes.INPUT}
                            ref={register({
                                validate: value => value === password.current || "The passwords do not match"
                            })}
                            onChange={() => trigger("retypePassword")}
                            error={errors?.retypePassword?.message} />
                    </div>
                    <div className="col-md-6">
                        <InputHoc className="form-control" type="input" label='PHONE_NUMBER' name='phoneNumber' inputType={InputTypes.PHONE} control={control}
                            rules={{
                                required: 'Phone is required.',
                                validate: async value => await isPhoneNumExisted(value) || "This phone number already exists"
                            }} error={errors?.phoneNumber} />

                    </div>
                    {//temporary remove referral code
                        /* <div className="col-md-6">
                            <InputHoc type="input" label='Referral Code (Optional)' name='referralCode' inputType={InputTypes.INPUT}
                                defaultValue={urlQuery.get("ref")}
                                ref={register({
                                    required: false,
                                    validate: value => isReferralCodeExist(value)
                                })} error={errors?.referralCode} />
                        </div> */
                    }
                </div>
            </form>
            <div className="panel-foot panel-foot-buttons">
                <button type="button" onClick={() => { jumpToStep(registerFormValue.currentStep - 1) }} className={`btn btn-themed btn-rest ${isXsDevices ? 'btn-group' : 'btn-min-width'}`}>{isXsDevices ? <i class="las la-angle-left"></i> : t('PREVIOUS')}</button>
                <Link to={WebUrl._LOGIN} className="btn btn-link-secondary">{t('RETURN_TO_LOGIN')}</Link>
                <button type="button" onClick={() => handleSubmit(onSubmit)()} className={`btn btn-themed ${isXsDevices ? 'btn-group' : 'btn-min-width'}`}>{isXsDevices ?  <i class="las la-angle-right"></i> :  t('NEXT')}</button>
            </div>
        </>
    )
};

/// <summary>
/// Author: Lewis
/// </summary>
const RegisterVerificationAttachment = ({ jumpToStep, setRegisterFormState, registerFormValue, setAppState }) => {
    const { register, control, handleSubmit, errors } = useForm({ defaultValues: registerFormValue });
    const [otpValue, setOtpValue] = useState('');
    const [isOtpPending, setOtpPending] = useState(false);
    const [isOtpValid, setOtpValid] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [remainSeconds, setRemainSeconds] = useState(0);
    const [attemptNum, setAttemptNum] = useState(OTPSettings._OTP_RESEND_ATTEMPT);
    const [messagesObj, setMessagesObj] = useState({ success: false, message: null });
    const { t } = useTranslation();
    useEffect(() => {
        setRegisterFormState(prevState => ({ ...prevState, currentStep: 2 }));
    }, []);
    const { isXsDevices } = useContext(PageSettings);
    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = (data) => {
        if (registerFormValue.emailVerification) {
            setOtpValid(true);
            setRegisterFormState(prevState => ({
                ...prevState, ...data,
                certificationAttachment: data?.certificationAttachment,
                identificationCards: data?.identificationCards,
                emailVerification: true,
                currentStep: 3,
            }));
            jumpToStep(3);
            return;
        }
        else {
            setRegisterFormState(prevState => ({
                ...prevState, ...data,
                certificationAttachment: data?.certificationAttachment,
                identificationCards: data?.identificationCards,
            }));
            getActivationCode();
        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const getActivationCode = async () => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));

        let dao = new AccountRegistrationDao();

        Block.Circle(registerFormValue.blockContainer);

        await dao.requestActivationCode(registerFormValue).then(responseJson => {
            Block.Remove(registerFormValue.blockContainer);
            let data = responseJson[ApiKey._API_DATA_KEY];
            setRemainSeconds(data?.remainingCooldown);
            setAttemptNum(0);
            setOtpModal(true);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                // setRegisterFormState(prevState => ({ ...prevState, emailVerification: true }));
                setOtpPending(true);
                setMessagesObj({ success: true, message: 'notiflix:OTP_REQUEST_SUCCESS' });
                return
            }
            else {
                if (data?.remainingCooldown) {
                    setMessagesObj({ success: false, message: 'notiflix:OTP_REQUEST_COOLDOWN' });
                }
            }
            // Notify.Failure(responseJson[ApiKey._API_MESSAGE_KEY]);
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const verifyActivationCode = async (otp) => {

        let dao = new AccountRegistrationDao();
        let data = { email: registerFormValue.email, otp: otp };
        await dao.verifyActivationCode(data).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setOtpModal(false);
                setOtpValid(true);
                setRegisterFormState(prevState => ({
                    ...prevState,
                    emailVerification: true,
                    currentStep: 3
                }));
                jumpToStep(3);
                //handleSubmit(onSubmit)();
                return
            }
            setOtpValid(false);
            setOtpValue('');
            setMessagesObj({ success: false, message: responseJson[ApiKey._API_MESSAGE_KEY] });
        })

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (otpValue.length == OTPSettings._OTP_INPUT_LENGTH) {
            verifyActivationCode(otpValue);
            return
        }
        setOtpValid(false);
    }, [otpValue]);

    useEffect(() => {
        if (attemptNum === 0) {
            const interval = setInterval(() => {
                setRemainSeconds((t) => {
                    let tempRemainTime = t - 1;
                    if (tempRemainTime === 0) {
                        clearInterval(interval);
                        setAttemptNum(OTPSettings._OTP_RESEND_ATTEMPT);
                    }
                    return tempRemainTime;
                });
            }, 1000);
        }
    }, [attemptNum]);

    return (
        <>
            <form className="registrationInnerForm panel-pad-body y-scrollbar-2" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className={classNames("col-md-6 form-group", { "has-error": errors?.certificationAttachment?.message })}>
                        <label>1. {t('CERT_ATTACHMENT')}</label>
                        <div>
                            <InputHoc className="panel-brand br-1" formGroupClass="form-file-uploader" name="certificationAttachment" inputType={InputTypes.FILEUPLOAD} control={control} rules={{ required: 'Attachment is required.' }} accept={{ image: true, pdf: true }} />
                        </div>
                    </div>
                    <div className={classNames("col-md-6 form-group", { "has-error": errors?.identificationCards?.message })}>
                        <label>2. {t('IDENTIFICATION_CARD')}</label>
                        <div>
                            <InputHoc className="panel-brand br-1" formGroupClass="form-file-uploader" name="identificationCards" inputType={InputTypes.FILEUPLOAD} control={control} rules={{ required: 'Attachment is required.' }} accept={{ image: true, pdf: true }} />
                        </div>
                    </div>
                </div>
            </form>
            <div className="panel-foot panel-foot-buttons">
                <button type="button" onClick={() => { jumpToStep(registerFormValue.currentStep - 1) }} className={`btn btn-themed btn-rest ${isXsDevices ? 'btn-group' : 'btn-min-width'}`}>{isXsDevices ? <i class="las la-angle-left"></i> : t('PREVIOUS')}</button>
                <button type="button" onClick={handleSubmit(onSubmit)} className="btn btn-themed btn-min-width">{t('VERIFY_EMAIL')}</button>
            </div>
            <OtpModal
                id="registration-otp-modal"
                isOpen={otpModal}
                toggler={setOtpModal}
                onChange={setOtpValue}
                inputLength={OTPSettings._OTP_INPUT_LENGTH}
                mode={OTPKey._EMAIL}
                value={otpValue}
                onResend={getActivationCode}
                remainSeconds={remainSeconds}
                attemptNum={attemptNum}
            >
                <h2 className="title title-with-dot mb-4">{t('VERIFY_EMAIL')}</h2>
                <p style={{ maxWidth: '80%' }}>{t('PLEASE_ENTER_OTP')} {registerFormValue.email}</p>
                {messagesObj?.message &&
                    <span className={messagesObj.success ? "text-success" : "text-danger"}>
                        {t(`${messagesObj?.message}`)}
                    </span>
                }
            </OtpModal>
        </>
    )
}

/// <summary>
/// Author: Lewis
/// </summary>
const RegisterTnc = ({ jumpToStep, setRegisterFormState, registerFormValue }) => {
    const _history = useHistory();
    const { t, i18n } = useTranslation();
    const [isChecked, setChecked] = useState(false);
    const { handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    let configInstance = Config.getInstance();
    const [authCredential, setAuthCredential] = useRecoilState(authCredentialState);
    const [isLoggedIn, setLoggedIn] = useRecoilState(isLoggedInState);

    const { isXsDevices } = useContext(PageSettings);
    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = () => {
        (async () => {
            let dao = new AccountRegistrationDao();
            let tempLanguage = Language.getValues().find(val => val.lang === i18n.language);
            let { identificationCards, certificationAttachment, ...rest } = registerFormValue;
            let postForm = createMultiPartFormBody({ ...rest, languageId: tempLanguage?.id ?? 1 });
            postForm.append("identificationCards", identificationCards ? identificationCards?.[0] : null);
            postForm.append("certificationAttachment", certificationAttachment ? certificationAttachment?.[0] : null);

            // setIsLoading(true);
            Block.Circle(registerFormValue.blockContainer);

            await dao.signUpAccount(postForm).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let responseData = responseJson[ApiKey._API_DATA_KEY];
                    configInstance.setData(ConfigEnum._IS_LOGGED_IN, true);
                    setLoggedIn(true);
                    setAuthCredential(responseData);
                    _history.push(WebUrl._GET_STARTED);
                } else {
                    setIsLoading(false);
                    Block.Remove(registerFormValue.blockContainer);
                }
            })
        })();
    }

    return (
        <>
            <form className="registrationInnerForm panel-pad-body y-scrollbar-2" onSubmit={handleSubmit(onSubmit)}>
                <div className="panel-brand br-1 registration-phase-complete">
                    <div className="section-split" style={{height: '38vh'}}>
                        <div className="section-wrapper">
                            <div className="section-header title title-with-dot m-0">
                                {t('FIRM_DETAIL')}
                            </div>
                            <div className="section-body y-scrollbar-2">
                                <Row>
                                    <Col xl={6} xs={6}><b>{t('FIRM_NAME')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue["firm.name"]}</Col>
                                    <Col xl={6} xs={6}><b>{t('PRACTITIONER_NAME')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue["firm.practitionerName"]}</Col>
                                    <Col xl={6} xs={6}><b>{t('EXPERTISE')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue?.["firm.expertise"].map(e => e.label).join(", ")}</Col>
                                    <Col xl={6} xs={6}><b>{t('SERVICES')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue?.["firm.services"].map(e => e.label).join(", ")}</Col>
                                </Row>
                            </div>
                        </div>
                        <div className="section-wrapper">
                            <div className="section-header title title-with-dot m-0">
                                {t('PERSONAL_DETAIL')}
                            </div>
                            <div className="section-body y-scrollbar-2">
                                <Row>
                                    <Col xl={6} xs={6}><b>{t('NAME')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue.name}</Col>
                                    <Col xl={6} xs={6}><b>{t('EMAIL')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue.email}</Col>
                                    <Col xl={6} xs={6}><b>{t('PHONE_NUMBER')}</b></Col>
                                    <Col xl={6} xs={6}>{registerFormValue.phoneNumber}</Col>
                                    <Col xl={12} xs={12} className="font-italic text-light-darker">{t('2_DOC_ATTACH')}</Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div> */}
                </div>
                <div className="text-gray-lighter text-center mt-2">
                    {t('CONFIRM_ALL_INFO_TO_COMPLETE')}
                </div>
                <div className="form-group form-check mt-1 mb-0 login-field checkbox checkbox-css pl-0">
                    <input type="checkbox" id="inlineCssCheckbox1" className="form-check-input" onChange={() => {
                        setChecked(!isChecked);
                        setRegisterFormState(prevState => ({ ...prevState, termsAndConditionVerification: true }));
                    }} checked={isChecked} />
                    <label for="inlineCssCheckbox1" style={{ width: 'fit-content', margin: 0 }}>{t('AGREE_WITH')}</label><a className="text-blue" href="https://www.yicom.com/?lang=en" target="_blank"> {t('TERM_OF_SERVICE')}</a>
                </div>
            </form>
            <div className="panel-foot panel-foot-buttons">
                <button type="button" onClick={() => { jumpToStep(registerFormValue.currentStep - 1) }} className={`btn btn-themed btn-rest ${isXsDevices ? 'btn-group' : 'btn-min-width'}`}>{isXsDevices ? <i class="las la-angle-left"></i> : t('PREVIOUS')}</button>
                <button className="btn btn-lg btn-themed btn-min-width" type="button" onClick={() => handleSubmit(onSubmit)()} disabled={!isChecked}>{t('COMPLETE_REGISTER')}</button>
            </div>
        </>
    )
};

export default Registration;