import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author: CJ(Jiann)
 */
class BusinessIntelligenceDao extends DataAccessObject {

    async getBIChart() {
        return this.get(ApiUrl._API_GET_BUSINESS_INTELLIGENCE_CHART);
    }
    async getBIChartByFirmId(firmId) {
        return this.get(ApiUrl._API_GET_BUSINESS_INTELLIGENCE_CHART_BY_FIRM_ID.replace(":firmId", firmId));
    }
}

export default BusinessIntelligenceDao;
