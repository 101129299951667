import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Lewis
 * DataAccessObject's fetch will return the promise.
 */
class AccountRegistrationDao extends DataAccessObject {

    async requestActivationCode({ email, name }) {
        return this.post(ApiUrl._API_REQUEST_ACTIVATION_CODE, { "email": email, "name": name });
    }

    async verifyActivationCode({ email, otp }) {
        return this.post(ApiUrl._API_VERIFY_ACTIVATION_CODE, { "email": email, "otp": otp });
    }

    async signUpAccount(postData) {
        return this.postForm(ApiUrl._API_ACCOUNT_REGISTRATION, postData);
    }

    async registrationThroughAdmin(postData) {
        return this.postForm(ApiUrl._API_ACCOUNT_REGISTRATION_THROUGH_ADMIN, postData);
    }

    async checkUsername(username) {
        return this.get(`${ApiUrl._API_CHECK_USERNAME}/${username}`);
    }

    async checkEmail(email) {
        return this.post(ApiUrl._API_CHECK_EMAIL, { "email": email });
    }

    async checkPhoneNum(phoneNum) {
        return this.get(`${ApiUrl._API_CHECK_PHONENUMBER}/${phoneNum}`);
    }

    async checkReferralCode(referralCode) {
        return this.get(`${ApiUrl._API_CHECK_REFERRAL_CODE}/${referralCode}`);
    }

    async checkPasswordComplexity(password) {
        return this.post(`${ApiUrl._API_CHECK_PASSWORD_COMPLEXITY}`, { password });
    }
}

export default AccountRegistrationDao;
