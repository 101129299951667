import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { ApiKey, WebUrl, matterType } from 'util/Constant';
import { JurisdictionDao } from 'data';
import { Link, useParams } from "react-router-dom";
import ServicesList from "./settings/ServicesList";
import TabPanel from 'components/panel/TabPanel';
import FactSheetListing from './settings/FactSheetListing';
import NavigationButton from 'components/buttons/NavigationButton';
import classNames from 'classnames';
import { getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty } from 'util/Utility';
import { useHistory, useLocation } from 'react-router';
import GovernmentDepartment from './settings/GovernmentDepartment';
import { useTranslation } from 'react-i18next';

///<summary>
///Author: Andrew
///</summary>
const JurisdictionWizard = () => {
    const { t } = useTranslation();
    const _location = useLocation();
    const _history = useHistory();
    const { jurisdictionId } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const [jurisdictionDetail, setJurisdictionDetail] = useState();
    const [factSheetId, setFactSheetId] = useState();
    const [isActive, setIsActive] = useState(false);
    const _TABS = {
        FACT_SHEET: { id: 0, title: t("FACT_SHEET") },
        //SHARE_CAPITAL_PACKAGE: { id: 1, title: "Standard Share Capital Package" },
        INCORPORATION: { id: 2, title: t("systemConfiguration:INCORPORATION") },
        INCORPORATION_PACKAGE: { id: 3, title: t("systemConfiguration:ANNUAL_PACKAGE") },
        SERVICES: { id: 4, title: t("SERVICES") },
        MISCELLANEOUS: { id: 5, title: t("systemConfiguration:MISCELLANEOUS") },
        ANNUAL_COMPLIANCE: { id: 6, title: t("systemConfiguration:ANNUAL_COMPLIANCE") },
        // PREVIEW_PROFILE: { id: 7, title: "Preview Profile" },
        GOVERNMENT: { id: 8, title: t("systemConfiguration:GOVERNMENT_DEPARTMENT") },
    };

    let dao = new JurisdictionDao();

    ///<summary>
    ///Author: Andrew
    ///</summary>
    const getInitData = useCallback(async (jurisdictionId) => {
        await dao.getJurisdictionById(jurisdictionId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setJurisdictionDetail(response[ApiKey._API_DATA_KEY]);
            }
        });

        await dao.getFactSheetListByJurisdiction(jurisdictionId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setFactSheetId(response[ApiKey._API_DATA_KEY].id);
            }
        })
    }, [jurisdictionId]);

    useEffect(() => {

        if (stringIsNullOrEmpty(jurisdictionId)) {
            _history.goBack();
            return;
        }

        getInitData(jurisdictionId);

    }, [jurisdictionId]);

    useEffect(() => {
        setIsActive(!isActive);
    }, [currentTab])


    // ///<summary>
    // ///Author: Lewis
    // Temporarily not persist the tabs as it causing multiple rerender issue on appending query params way
    // ///</summary>
    // useEffect(() => {
    //     let search = _location.search;

    //     if (stringIsNullOrEmpty(search)) {
    //         setCurrentTab(0);
    //         return;
    //     }

    //     let params = new URLSearchParams(search);
    //     if (params) {
    //         let tab = parseInt(params.get("tab"));
    //         let listOfTabIds = Object.values(_TABS).map(x => x.id);
    //         listOfTabIds.includes(tab) ? setCurrentTab(tab) : setCurrentTab(0);
    //     }
    // }, []);

    return (
        <div className="panel panel-brand p-0 panel-flex">
            <div className="panel-header">
                <div className="d-flex align-items-center">
                    <NavigationButton />
                    <h1 className="page-header mb-0 title title-with-dot">{jurisdictionDetail?.name} {t("systemConfiguration:JURISDICTION_WIZARD")}</h1>
                </div>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="panel-body-inner-content with-br-radius" style={{ borderRadius: "20px", backgroundColor: "white" }}>
                    <div className="vertical-tab-view h-100">
                        <div className="left-section">
                            <div className="hidden-menu-icon m-15">
                                <i class="las la-bars" onClick={() => { setIsActive(!isActive) }}></i>
                            </div>
                            <div className={`hidden-menu ${isActive && "active"}`}>
                                <div className="section-header">
                                    <h4 className="title title-with-dot mb-0 pl-2">
                                        {
                                            jurisdictionDetail &&
                                            <img src={getCountryFlagIconByJurisdictionName(jurisdictionDetail.name)}
                                                onError={(e) => { e.target.onerror = null; e.target.src = "assets/img/country-flag/Hong Kong.png" }}
                                                className="jurisdiction-flag mr-2" />
                                        }
                                        {t("systemConfiguration:SETTINGS")}
                                    </h4>
                                </div>
                                <ul className="vertical-tabs">
                                    {
                                        Object.keys(_TABS).map((key) => {
                                            return <li className={classNames({ "active-menu": currentTab == _TABS[key].id })}
                                                onClick={() => {

                                                    // const path = _location.pathname;
                                                    // let params = new URLSearchParams({
                                                    //     tab: _TABS[key].id
                                                    // });

                                                    // _history.push({
                                                    //     pathname: path,
                                                    //     search: `?${params}`
                                                    // })

                                                    setCurrentTab(_TABS[key].id)
                                                }}
                                                index={key}>
                                                <i className="las la-cog"></i>{_TABS[key].title}
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="right-section d-flex flex-column">
                            {
                                factSheetId &&
                                <TabPanel value={currentTab} index={_TABS.FACT_SHEET.id} className="flex-grow-1">
                                    <FactSheetListing factSheetId={factSheetId} />
                                </TabPanel>
                            }
                            <TabPanel value={currentTab} index={_TABS.INCORPORATION.id} className="flex-grow-1">
                                <ServicesList jurisdictionId={jurisdictionId} matterTypeId={matterType._INCORPORATION} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={_TABS.INCORPORATION_PACKAGE.id} className="flex-grow-1">
                                <ServicesList jurisdictionId={jurisdictionId} matterTypeId={matterType._ANNUAL_PACKAGE} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={_TABS.SERVICES.id} className="flex-grow-1">
                                <ServicesList jurisdictionId={jurisdictionId} matterTypeId={matterType._SERVICES} />
                            </TabPanel>
                            <TabPanel value={currentTab} index={_TABS.MISCELLANEOUS.id} className="flex-grow-1">
                                <ServicesList jurisdictionId={jurisdictionId} matterTypeId={matterType._MISCELLANEOUS} canAddService />
                            </TabPanel>
                            <TabPanel value={currentTab} index={_TABS.ANNUAL_COMPLIANCE.id} className="flex-grow-1">
                                <ServicesList jurisdictionId={jurisdictionId} matterTypeId={matterType._ANNUAL_COMPLIANCE} canAddService />
                            </TabPanel>
                            <TabPanel value={currentTab} index={_TABS.GOVERNMENT.id} className="flex-grow-1">
                                <GovernmentDepartment jurisdictionId={jurisdictionId} />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JurisdictionWizard;