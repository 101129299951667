import { ApiUrl, ApiKey, DateFormat, OfficerTypeID } from 'util/Constant';
import { compareObjectDiffAttributesAndGetProps, createMultiPartFormBody, filterFileObjectToSubmit } from 'util/Utility';
import DataAccessObject from './DataAccessObject';
import moment from "moment";

/**
 * Author: Lewis
 */
class CompanyServiceDao extends DataAccessObject {

    //<summary>
    //Author: Lewis
    //</summary>
    async submitRejection(companyId, productMatterId, formData) {
        return this.post(ApiUrl._API_SUBMIT_REJECT_REQUEST
            .replace(":companyId", companyId)
            .replace(":productMatterId", productMatterId), formData);
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async getServicesOngoingList(companyId) {
        return this.get(ApiUrl._API_GET_SERVICES_ONGOING_LIST.replace(":companyId", companyId));
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async getOnGoingRequestEntry(companyId, productMatterId) {
        return this.get(ApiUrl._API_GET_COMPANY_NAME_REQUEST_CHANGES
            .replace(":companyId", companyId)
            .replace(":productMatterId", productMatterId)
        );
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async getRemarkListing(serviceRequestId) {
        return this.get(ApiUrl._API_GET_REMARK_LIST.replace(":serviceRequestId", serviceRequestId));
    }

    //<summary>
    //Author: Lewis
    //</summary>
    async checkHasTaskRemaining(serviceRequestId) {
        return this.get(ApiUrl._API_CHECK_HAS_TASK_REMAINING.replace(":serviceRequestId", serviceRequestId));
    }

    //<summary>
    //Author: Lewis
    //</summary>
    changeOfCompanyName(funcName) {

        const submitRequest = async (companyId, productMatterId, formValue) => {
            let postValue = {
                companyId: companyId,
                productMatterId: productMatterId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SUBMIT_COMPANY_NAME_SERVICE_REQUEST, postValue);
        }

        const updateEntries = async (serviceRequestId, companyId, formValue) => {
            let postValue = {
                companyId: companyId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SERVICE_REQUEST_CHANGE_OF_COMPANY_NAME_UPDATE
                .replace(":serviceRequestId", serviceRequestId), postValue);
        }

        const retrieveAdminSiteChanges = async (serviceRequestId) => {
            return this.get(ApiUrl._API_ADMIN_GET_COMPANY_NAME_REQUEST_CHANGES.replace(":serviceRequestId", serviceRequestId));
        }

        const applyChanges = async (serviceRequestId) => {
            return this.post(ApiUrl._API_APPLY_COMPANY_NAME_REQUEST_COMPLETE.replace(":serviceRequestId", serviceRequestId));
        }

        let funcMapper = {
            "submitRequest": submitRequest,
            "applyChanges": applyChanges,
            "retrieveAdminSiteChanges": retrieveAdminSiteChanges,
            "updateEntries": updateEntries,
        }

        return funcMapper[funcName];
    }

    //<summary>
    //Author: Lewis
    //</summary>
    increaseShareCapital = (funcName) => {

        const submitRequest = async (postData) => {
            return this.post(ApiUrl._API_SUBMIT_INCREASE_SHARE_CAPITAL_REQUEST, postData);
        }

        let funcMapper = {
            "submitRequest": submitRequest,
        }

        return funcMapper[funcName];
    }

    //<summary>
    //Author: Lewis
    //</summary>
    changeOfDirector = (funcName) => {

        const retrieveOngoingChanges = async (serviceRequestId) => {
            return this.get(ApiUrl._API_ADMIN_RETRIEVE_DIRECTOR_MANAGEMENT_ON_GOING_CHANGES.replace(":serviceRequestId", serviceRequestId));
        }

        const applyChanges = async (serviceRequestId) => {
            return this.post(ApiUrl._API_APPLY_DIRECTOR_MANAGEMENT_CHANGES.replace(":serviceRequestId", serviceRequestId));
        }

        const submitRequest = async (companyId, productMatterId, mapIds, removeIds) => {
            let postValue = {
                companyId: companyId,
                productMatterId: productMatterId,
                mapIds, removeIds
            };

            return this.post(ApiUrl._API_SUBMIT_ADD_REMOVE_DIRECTOR_REQUEST, postValue);
        }

        const updateEntries = async (serviceRequestId, mapIds, removeIds) => {
            let postValue = {
                mapIds, removeIds
            };

            return this.post(ApiUrl._API_SERVICE_REQUEST_ADD_REMOVE_DIRECTOR_UPDATE
                .replace(":serviceRequestId", serviceRequestId), postValue);
        }

        let funcMapper = {
            "retrieveOngoingChanges": retrieveOngoingChanges,
            "applyChanges": applyChanges,
            "submitRequest": submitRequest,
            "updateEntries": updateEntries
        }

        return funcMapper[funcName];
    }

    //<summary>
    //Author: Sze Hua
    //</summary>
    transferShares(funcName) {

        const submitRequest = async (companyId, productMatterId, formValue) => {
            let postValue = {
                companyId: companyId,
                productMatterId: productMatterId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SUBMIT_TRANSFER_SHARE_SERVICE_REQUEST, postValue);
        }

        const updateEntries = async (serviceRequestId, companyId, formValue) => {
            let postValue = {
                companyId: companyId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SERVICE_REQUEST_CHANGE_OF_COMPANY_NAME_UPDATE
                .replace(":serviceRequestId", serviceRequestId), postValue);
        }

        //const retrieveAdminSiteChanges = async (serviceRequestId) => {
        //    return this.get(ApiUrl._API_ADMIN_GET_COMPANY_NAME_REQUEST_CHANGES.replace(":serviceRequestId", serviceRequestId));
        //}

        const applyChanges = async (serviceRequestId) => {
            return this.post(ApiUrl._API_APPLY_TRANSFER_SHARE_REQUEST_COMPLETE.replace(":serviceRequestId", serviceRequestId));
        }

        let funcMapper = {
            "submitRequest": submitRequest,
            "applyChanges": applyChanges,
            //"retrieveAdminSiteChanges": retrieveAdminSiteChanges,
            "updateEntries": updateEntries,
        }

        return funcMapper[funcName];
    }

    //<summary>
    //Author: Sze Hua
    //</summary>
    allotShares(funcName) {

        const submitRequest = async (companyId, productMatterId, formValue) => {
            let postValue = {
                companyId: companyId,
                productMatterId: productMatterId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SUBMIT_TRANSFER_SHARE_SERVICE_REQUEST, postValue);
        }

        const updateEntries = async (serviceRequestId, companyId, formValue) => {
            let postValue = {
                companyId: companyId,
                changesValue: {
                    entries: [{
                        Id: companyId,
                        Changes: formValue
                    }]
                }
            };

            return this.post(ApiUrl._API_SERVICE_REQUEST_CHANGE_OF_COMPANY_NAME_UPDATE
                .replace(":serviceRequestId", serviceRequestId), postValue);
        }

        //const retrieveAdminSiteChanges = async (serviceRequestId) => {
        //    return this.get(ApiUrl._API_ADMIN_GET_COMPANY_NAME_REQUEST_CHANGES.replace(":serviceRequestId", serviceRequestId));
        //}

        const applyChanges = async (serviceRequestId) => {
            return this.post(ApiUrl._API_APPLY_TRANSFER_SHARE_REQUEST_COMPLETE.replace(":serviceRequestId", serviceRequestId));
        }

        let funcMapper = {
            "submitRequest": submitRequest,
            "applyChanges": applyChanges,
            //"retrieveAdminSiteChanges": retrieveAdminSiteChanges,
            "updateEntries": updateEntries,
        }

        return funcMapper[funcName];
    }
}



export default CompanyServiceDao;