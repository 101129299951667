import React, { useMemo, useEffect, useRef, useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import ReactTable from 'components/react-table/ReactTable';
import { ApiKey, Status, ApiUrl, WebUrl, DateFormat, RoleType, LoadingStateText, InputTypes } from 'util/Constant';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { alertNotification, authCredentialState, notificationState } from 'recoil/Atoms';
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import { AuthenticationDao, BusinessIntelligentDao, CompanyDao, FactSheetDao, NotificationDao } from "data";
import moment from 'moment';
import { getCountryFlagIconByJurisdictionName, setNotificationBadge, setNotificationColor, stringIsNullOrEmpty } from 'util/Utility';
import StepZilla from "react-stepzilla";
import FormBuilder from "components/form/FormBuilder";
import { nameCheckForm } from "pages/incorporation/forms/SubmitNameCheckForm";
import { Loading, Report, Block, Confirm } from "notiflix";


const Dashboard = props => {
    const setAlarmNotify = useSetRecoilState(alertNotification);
    const _history = useHistory();
    const chartDivRef = useRef();
    const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
    const [seriesData, setSeriesData] = useState([]);
    const notificationList = useRecoilValue(notificationState);
    const authCredential = useRecoilValue(authCredentialState);
    const [totalUnread, setTotalUnread] = useState(0);
    let biDao = new BusinessIntelligentDao();

    useEffect(() => {

        if (chartDivRef.current) {
            setChartSize({ width: chartDivRef.current.offsetWidth, height: chartDivRef.current.offsetHeight });
        }

    }, [])

    useEffect(() => {
        getBIChart();
    }, [])

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (notificationList.length > 0) {
            var unReadNotifications = notificationList.filter(el => el.isRead == false);
            setTotalUnread(unReadNotifications.length);
        }
    }, [notificationList]);

    const getBIChart = async () => {
        var firmId = authCredential?.firmId;
        if (authCredential?.firmId) {

            await biDao.getBIChartByFirmId(firmId).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    let tempArr = data.map(d => {
                        return { name: d.jurisdictionName, data: d.totalCompany }
                    });
                    setSeriesData(tempArr);
                }
            })
        }
        else {

            await biDao.getBIChart().then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    let tempArr = data.map(d => {
                        return { name: d.jurisdictionName, data: d.totalCompany }
                    });
                    setSeriesData(tempArr);
                }
            })
        }
    }

    const _DEADLINE_COLUMNS = useMemo(() => [
        {
            Header: "Company Name",
            accessor: "englishName",
            Cell: ({ row }) => {
                return (
                    <div className="btn w-auto" onClick={() => _history.push({
                        pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                        state: {
                            status: row.original.status
                        }
                    }
                    )}>
                        <span>{row.original.englishName}</span>
                    </div >
                );
            }

        },
        {
            Header: "Jurisdiction",
            accessor: "jurisdiction",
            Cell: ({ row }) => {
                return (
                    <div className="btn w-auto" onClick={() => _history.push({
                        pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                        state: {
                            status: row.original.status
                        }
                    }
                    )}>
                        <span>{row.original.jurisdiction.shortName}</span>
                    </div >
                );
            }

        },
        {
            Header: "CI Number",
            accessor: "corporateIdentityNumber",
            Cell: ({ row }) => {
                return (
                    <div className="btn w-auto" onClick={() => _history.push({
                        pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                        state: {
                            status: row.original.status
                        }
                    }
                    )}>
                        <span>{row.original.corporateIdentityNumber}</span>
                    </div >
                );
            }
        },
        {
            Header: "Matter",
            accessor: "matter",
            Cell: ({ row }) => (

                row.original.companyAnnualCompliances.map((annual, i) => {
                    return (<>
                        <div className="btn" index={i} onClick={() => _history.push({
                            pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                            state: {
                                status: row.original.status
                            }
                        }
                        )}>
                            <span>{annual.productMatter?.name}</span>
                        </div ><br />
                    </>);
                })

            )
        },
        {
            Header: "Deadline",
            accessor: "deadline",
            Cell: ({ row }) => (

                row.original.companyAnnualCompliances.map((annual, i) => {
                    return (<>
                        <div className="btn" index={i} onClick={() => _history.push({
                            pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                            state: {
                                status: row.original.status
                            }
                        }
                        )}>
                            <span>{moment(annual.systemDeadline).format(DateFormat._DATE_ONLY)}</span>
                        </div><br />
                    </>);
                })

            )
        },
        {
            Header: "",
            accessor: "isExpired",
            Cell: ({ row }) => (
                row.original.companyAnnualCompliances.map((annual, index) => {
                    return (<>
                        {
                            annual.isExpired == true
                                ?
                                <>
                                    <div className="btn" index={index} onClick={() => _history.push({
                                        pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                                        state: {
                                            status: row.original.status
                                        }
                                    }
                                    )}>
                                        <span className="badge badge-danger">Missed</span>
                                    </div><br />
                                </>
                                :
                                <>
                                    <div className="btn" index={index} onClick={() => _history.push({
                                        pathname: WebUrl._INCORPORATION_COMPANY_PORTFOLIO.replace(":companyId", row.original.id),
                                        state: {
                                            status: row.original.status
                                        }
                                    }
                                    )}>
                                        <span className="badge badge-warning">Upcoming</span>
                                    </div><br />
                                </>
                        }

                    </>);
                })
            ),
            disableFilters: true,
            disableSortBy: true,
        }
    ], [])

    /// <summary>
    /// Author: Andrew
    ///
    function renderStatus(status, statusText) {
        var statusClass = '';
        switch (status) {
            case 1:
                statusClass = 'badge-success'
                break;
            case 2:
                statusClass = 'badge-yellow'
                break;
            case 3:
                statusClass = 'badge-warning'
                break;
            case 4:
                statusClass = 'badge-danger'
                break;
        }
        return <span className={'badge ' + statusClass}>{statusText}</span>;
    }


    return (
        <div className="d-flex h-100" id="Dashboard">
            <Col xl={8} className="left-section">
                <div className="panel panel-brand panel-flex">
                    <div className="panel-header p-b-0">
                        <h1 className="page-header title title-with-dot">Dashboard</h1>
                    </div>
                    <div className="panel-body y-scrollbar-2">
                        <div>
                            {/* <div className="card mb-3" ref={chartDivRef} style={{ minHeight: "550px", maxWidth: "100%" }}>
                            {
                                seriesData.length > 0 &&
                                <BICharts chartHeight={chartSize.height} chartWidth={"100%"} data={seriesData} />
                            }
                        </div> */}
                            <div className="panel panel-brand-inner">
                                <div class="panel-header">
                                    Upcoming Deadline
                            </div>
                                <div className="table-brand-wrapper">
                                    <ReactTable columns={_DEADLINE_COLUMNS} url={authCredential.firmId != null ? ApiUrl._API_GET_CLOSE_DEADLINE_COMPANY_BY_FIRM_ID.replace(":firmId", authCredential.firmId)
                                        : ApiUrl._API_GET_CLOSE_DEADLINE_COMPANY} initialPageSize={5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xl={4} className="right-section">
                <div className="panel panel-brand panel-flex">
                    <div className="panel-body y-scrollbar-2">
                        <div className="panel panel-brand-inner panel-notification">
                            <div class="panel-header">
                                <img src={require("../../assets/img/icon/notification-black.svg")} className="mr-2" />Recent Notifications ({totalUnread})
                        </div>
                            <div className="notification-wrapper" id="notiticationList">
                                <ul className="listing-wrapper y-scrollbar-2 m-0" style={{ height: '190px' }} id="notification-list-bullet">
                                    {notificationList.length > 0 &&
                                        notificationList.map((data, index) => {
                                            return (
                                                <li key={data.id} onClick={() => { _history.push(WebUrl._USERS_NOTIFICATIONS, { id: data.id, idx: index }) }} >
                                                    <div className="d-flex ">
                                                        <span className={setNotificationColor(data.priorityName)}><i className="fas fa-circle fa-xs"></i></span>
                                                        <Col xs={10} className="pr-0">
                                                            <h6 className="f-s-15 notification-text">{data.title}</h6>
                                                            <div className="short-description" style={{ fontWeight: "initial" }}>
                                                                <p className="notification-text fs-14">
                                                                    {data.messageBody}
                                                                </p>
                                                            </div>
                                                            <div className="d-flex justify-between">
                                                                <span className={setNotificationBadge(data.priorityName)}>{data.priorityName}</span>
                                                                <div className="f-s-11">
                                                                    {
                                                                        !stringIsNullOrEmpty(data.createdDate) && <p className="m-0">{`${moment.utc(data.createdDate).fromNow(true)} ago`}</p>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </Col>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="panel-footer text-center">
                                <Link to={WebUrl._USERS_NOTIFICATIONS} className="btn btn-themed btn-min-width">View All</Link>
                            </div>
                        </div>

                        <div className="panel panel-brand panel-referrer">
                            <div className="panel-body">
                                <h3 className="title text-primary">Referral Program</h3>
                                <p>Increase your profit margin by referring a friend!</p>
                                <Link to={WebUrl._REFERRALS} className="btn btn-themed btn-min-width">Click here!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    );
}

export default Dashboard;