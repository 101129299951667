import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Andrew
/// </summary>

const BrandModal = (props) => {

    const modalProps = {
        ...props,
        modalSize: props.modalSize ?? 'lg',
    }

    const onScrollHandler = (e) => e.stopPropagation();
    const { t } = useTranslation();
    const modalId = useMemo(() => {
        return props?.id ? props.id : uuidv4();
    }, [props?.id]);

    return (
        <div id={modalId} onScroll={onScrollHandler} className="brand-modal-wrapper">
            <Modal size={modalProps.modalSize} className={classnames("modal-brand", modalProps.className)} isOpen={props.isOpen} centered onClosed={props.onClosed} >
                <ModalHeader>
                    <button className="btn btn-close-modal" onClick={() => props.toggler(false)}><i className="las la-times"></i></button>
                    <div className="title title-with-dot">{props.title}</div>
                </ModalHeader>
                {
                    props.customBody ?
                        <>{props.children}</> :
                        <>
                            <ModalBody className={props.bodyClassName}>
                                {props.children}
                            </ModalBody>
                            {
                                props.hasFooter &&
                                <ModalFooter className="panel-foot panel-foot-buttons">
                                    {
                                        props.onCancel &&
                                        <button className="btn btn-themed btn-min-width" onClick={() => { props.onCancel() }}>{t('CANCEL')}</button>
                                    }
                                    {
                                        props.onSubmit &&
                                        <button className="btn btn-themed btn-min-width" onClick={() => { props.onSubmit() }}>{t('SUBMIT')}</button>
                                    }
                                </ModalFooter>
                            }
                        </>
                }
            </Modal>
        </div>
    );
};

export default BrandModal;