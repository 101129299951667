import React, { useMemo, useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { DocumentType, WebUrl, Icon, ApiKey, DateFormat, CompanyDocumentStatus, InputTypes, ApiUrl, FileType, deleteConfirm, LoadingStateText, BtnTypes, SweetAlert, TableId } from 'util/Constant';
import InputHoc from "components/form/InputHoc";
import ReactTable from 'components/react-table/ReactTable';
import NavigationButton from 'components/buttons/NavigationButton';
import { Row, ModalBody, ModalFooter, UncontrolledTooltip, Col } from 'reactstrap';
import { CompanyDao, CommonDao } from '../../data';
import moment from 'moment';
import Notiflix, { Block, Report, Notify } from 'notiflix';
import { useForm } from 'react-hook-form';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import { userIdSelector, userRolesSelector } from "recoil/Atoms";
import { useRecoilValue } from 'recoil';
import BrandModal from '../../components/modals/BrandModal';
import DocumentViewer from 'components/document-viewer/DocumentViewer';
import ButtonRound from 'components/buttons/ButtonRound';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PageSettings } from 'config/page-settings';
import { useResizeDetector } from 'react-resize-detector';
import { stringIsNullOrEmpty } from 'util/Utility';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { Can } from 'config/user-access';
import { PolicyActionConstant, PolicyObjectConstant } from '../../util/Constant';

/// <summary>
/// Author: Lewis
/// </summary>
const CompanyDocuments = ({ props }) => {

    const { companyId } = useParams();
    const _history = useHistory();
    const _location = useLocation();

    const { t } = useTranslation();
    var userRole = useRecoilValue(userRolesSelector);
    const userId = useRecoilValue(userIdSelector);
    const { isXlDevices } = useContext(PageSettings);
    const { ref: _contentRef } = useResizeDetector();
    const [company, setCompany] = useState();
    const [isViewing, setIsViewing] = useState(false);
    const [documentOpts, setDocumentOpts] = useState([]);
    const [documentTypeOpts, setDocumentTypeOpts] = useState([]);
    const [file, setFile] = useState({});
    const [modal, setModal] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false)
    const [uploadSignatureModal, setUploadSignatureModal] = useState(false);
    const [selectedCompanyDocumentId, setSelectedCompanyDocumentId] = useState();

    const _STYLE_INLINE_UL = {
        display: "inline-block",
        padding: 0,
        margin: "1px"
    };

    const _STYLE_NO_BULLET = {
        listStyleType: "none",
        padding: 0,
        margin: 0
    };

    const _ACCOUNTANT_COLUMN = useMemo(() => [
        {
            Header: "UPLOAD_DATE",
            accessor: el => el.uploadedDate ? moment(el.uploadedDate).format(DateFormat._DATE_ONLY) : ""
        },
        {
            Header: "DOCUMENT_TYPE",
            accessor: el => el.document?.documentType?.name ?? ""
        },
        {
            Header: "NAME_OF_DOCUMENTS",
            accessor: "document.name",
            isRequiredColumn: true
        },
        {
            Header: "EFFECTIVE_DATE",
            accessor: el => el.effectiveDate ? moment(el.effectiveDate).format(DateFormat._DATE_ONLY) : ""
        },
        {
            Header: "UPLOADER",
            accessor: "user.name",
            Cell: ({ row }) => {
                return (
                    row.original.docAttachment.fileMeta.map((file) => {
                        return (<><span>{row.original?.user?.name}</span><br /></>);
                    })
                );
            }
        },
        {
            Header: "ATTACHMENT",
            Cell: ({ row }) => {
                return (
                    row.original.docAttachment.fileMeta.map((file) => {
                        return (
                            <div>
                                {
                                    file.extension == FileType._PDF &&
                                    <>
                                        <ButtonRound type={BtnTypes.VIEW} className="mr-1" small onClick={() => {
                                            setIsViewing(true);
                                            setViewFile(file.requestPath);
                                        }} />
                                    </>
                                }
                                <Link className="brand-anchor"
                                    to={file.requestPath}
                                    target={"_blank"}
                                    download={file.filename}>
                                    <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} title={t('DOWNLOAD_FILE')} />
                                </Link>
                            </div>
                        );
                    })
                );
            },
            disableSortBy: true,
            disableFilters: true,
            isRequiredColumn: true
        },
        {
            Header: "SIGNATURE",
            Cell: ({ row }) => {
                return (
                    <>
                        {
                            (() => {
                                if (row.original.status === CompanyDocumentStatus._REJECT) {
                                    return <> - </>
                                }

                                if (row.original.requireSignature) {
                                    if (row.original.signedBy) {
                                        return (
                                            <>
                                                {
                                                    row.original.signedDocument.fileMeta.map((file) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    file.extension == FileType._PDF &&
                                                                    <>
                                                                        <ButtonRound small type={BtnTypes.VIEW} className="m-r-5" onClick={() => {
                                                                            setIsViewing(true);
                                                                            setViewFile(file.requestPath);
                                                                        }} />
                                                                    </>
                                                                }

                                                                <Link className="brand-anchor m-r-5"
                                                                    to={file.requestPath}
                                                                    target={"_blank"}
                                                                    download={file.filename}>
                                                                    <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} title={t('DOWNLOAD_FILE')} />
                                                                </Link>

                                                                <a className="btn-link btn-sm"
                                                                    id={"signed_by_link_" + row.original.id}>
                                                                    {t('UPLOADED_SIGNATURE')}
                                                                </a>
                                                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" style={{ textAlign: "left" }} placement="bottom" target={"signed_by_link_" + row.original.id}>
                                                                    <ul style={_STYLE_INLINE_UL}>
                                                                        <li style={_STYLE_NO_BULLET}><small> {t('UPLOAD_BY')}: {row.original?.signedByUser?.username}</small></li>
                                                                        <li style={_STYLE_NO_BULLET}><small> {t('UPLOAD_DATE')}: {moment(row.original?.signedDate).format(DateFormat._DATE_FORMAT)}</small></li>
                                                                    </ul>
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                        );
                                    } else {
                                        return <>
                                            <ButtonRound small type={BtnTypes.FILE_UPLOAD} title={t('UPLOAD_SIGNATURE')} onClick={() => {
                                                setSelectedCompanyDocumentId(row.original?.id);
                                                setUploadSignatureModal(!uploadSignatureModal);
                                            }} />
                                        </>
                                    }
                                } else {
                                    return <> {t('NO_SIGNATURE_NEEDED')} </>
                                }
                            })()
                        }
                    </>
                )
            },
            disableSortBy: true,
            disableFilters: true,
            isRequiredColumn: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <>
                        {
                            (() => {
                                switch (row.original.status) {
                                    case CompanyDocumentStatus._ADOPT:
                                        return (
                                            <span className="badge badge-success">{t("ADOPTED")}</span>
                                        )
                                        break;
                                    case CompanyDocumentStatus._PENDING:
                                        return (
                                            <span className="badge badge-warning">{t("PENDING")}</span>
                                        )
                                        break;
                                    case CompanyDocumentStatus._REJECT:
                                        return (
                                            <span className="badge badge-danger">{t("REJECTED")}</span>
                                        )
                                        break;
                                    default:
                                        return (
                                            <span> - </span>
                                        )
                                        break;
                                }
                            })()}
                    </>
                )
            },
            isRequiredColumn: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return (<>
                    {
                        row.original.status == CompanyDocumentStatus._PENDING &&
                        <>
                            <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_document} passThrough>
                                {
                                    allowed => allowed ?
                                        <>
                                            <ButtonRound type={BtnTypes.CHECK_CIRCLE} className="mr-1" title={t('APPROVE')} small onClick={() => {
                                                Notiflix.Confirm.Show(t('notiflix:ARE_YOU_SURE'), t('notiflix:ADOPT_THE_DOCUMENT'), t('notiflix:YES'), t('notiflix:NO'), () => {
                                                    adoptCompanyDocument(row.original.id)
                                                });

                                            }} />
                                            <ButtonRound type={BtnTypes.LA_TIMES} title={t('REJECT')} small onClick={() => {
                                                Notiflix.Confirm.Show(t('notiflix:ARE_YOU_SURE'), t("notiflix:REJECT_THE_DOCUMENT"), t("notiflix:YES"), t("notiflix:NO"), () => {
                                                    rejectCompanyDocument(row.original.id)
                                                }, "", deleteConfirm);
                                            }} />
                                        </>
                                        :
                                        <></>
                                }
                            </Can>
                            
                        </>
                    }

                </>);
            },
            disableSortBy: true,
            disableFilters: true,
            isRequiredColumn: true
        }
    ], [])

    const setViewFile = async (filePathString) => {
        setFile(filePathString);
    }

    const adoptCompanyDocument = async (documentId) => {

        let dao = new CompanyDao();

        await dao.adoptCompanyDocument(documentId).then((response) => {

            if (response[ApiKey._API_SUCCESS_KEY]) {

                Report.Success(
                    t('notiflix:ADOPTED_COMPANY_DOC'),
                    response[ApiKey._API_MESSAGE_KEY],
                    t(SweetAlert._OK)
                );

                setRefreshTable(true);
                setRefreshTable(false);
            }
            else {
                Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        })
    }

    const rejectCompanyDocument = async (documentId) => {

        let dao = new CompanyDao();

        await dao.rejectCompanyDocument(documentId).then((response) => {

            if (response[ApiKey._API_SUCCESS_KEY]) {

                Report.Success(
                    t('notiflix:REJECTED_COMPANY_DOC'),
                    response[ApiKey._API_MESSAGE_KEY],
                    t(SweetAlert._OK)
                );

                setRefreshTable(true);
                setRefreshTable(false);
            }
            else {
                Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        })
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (stringIsNullOrEmpty(companyId)) {
            _history.goBack();
            return;
        }

    }, [companyId]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const { data: documentsJson } = useSWR([ApiUrl._API_GET_DOCUMENT_LIST, ApiKey._API_GET, null]);
    const { data: documentTypeJson } = useSWR([ApiUrl._API_GET_DOCUMENT_TYPE_LIST, ApiKey._API_GET, null]);
    const { data: incorpJson } = useSWR([
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId), ApiKey._API_GET, null, "div#company-document-blocker"
    ]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (incorpJson?.[ApiKey._API_SUCCESS_KEY])
            setCompany(incorpJson[ApiKey._API_DATA_KEY]);

        if (documentsJson?.[ApiKey._API_SUCCESS_KEY])
            setDocumentOpts(documentsJson[ApiKey._API_DATA_KEY].map(
                d => ({ label: d.name, value: d.id, documentTypeId: d.documentTypeId })
            ));

        if (documentTypeJson?.[ApiKey._API_SUCCESS_KEY])
            setDocumentTypeOpts(documentTypeJson[ApiKey._API_DATA_KEY].map(d => ({ label: d.name, value: d.id })));

    }, [incorpJson, documentsJson, documentTypeJson]);

    /// <summary>
    /// Author: Lewis
    /// </summary>

    const fixedContentHeight = useMemo(() => {
        if (_contentRef.current) {
            return _contentRef.current.clientHeight - 15;
        }
    }, [_contentRef.current]);

    return (
        <div className="panel panel-brand panel-flex" id="company-document-blocker">
            <div className="panel-header">
                <h1 className="mb-0 page-header title title-with-dot"><NavigationButton />{t("COMPANY_DOCUMENTS")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames('panel-body-inner-content with-br-radius', {
                    'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices
                })}>
                    <div className="d-flex flex-column h-100">
                        <Row className="flex-grow-1">
                            <Col xl={12}>
                                <div className="d-flex flex-column h-100">
                                    <div className="filter-panel-container">
                                        <Row className="filter-panel-body p-t-15">
                                            <Col xl={12}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <div className="text-pill-brand">
                                                            <strong className="f-s-16">
                                                                {company?.englishName}
                                                                {company?.chineseName && <>({company?.chineseName})</>}
                                                            </strong>
                                                            <div className="ci-number"><strong>CI {t("NUMBER_SHORT")}:</strong>{company?.corporateIdentityNumber ? company.corporateIdentityNumber : "-"}</div>
                                                        </div>
                                                    </div>
                                                    {
                                                        <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_document}>
                                                            <button className="btn btn-themed btn-sm btn-min-width" onClick={() => { setModal(!modal); }}>
                                                                {t("GENERATE_DOCUMENT")}
                                                            </button>
                                                        </Can>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="flex-grow-1" ref={_contentRef}>
                                        <div style={{ height: fixedContentHeight }}>
                                            <ReactTable
                                                columns={_ACCOUNTANT_COLUMN}
                                                url={ApiUrl._API_GET_COMPANY_DOCUMENT_LIST.replace(":companyId", companyId)}
                                                refreshTable={refreshTable}
                                                setPagination
                                                enableSearch
                                                tableColumnPreference={[userId, TableId._COMPANY_DOCUMENTS]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={file} />
                <CompanyDocumentForm modal={modal} setModal={setModal} documentOpts={documentOpts}
                    documentTypeOpts={documentTypeOpts} setRefreshTable={setRefreshTable} company={company} />
                <UploadSignatureForm modal={uploadSignatureModal} setModal={setUploadSignatureModal}
                    companyDocumentId={selectedCompanyDocumentId} setCompanyDocumentId={setSelectedCompanyDocumentId} setRefreshTable={setRefreshTable} company={company} />
            </div>
        </div>);
}

const CompanyDocumentForm = props => {
    const { modal, setModal, documentOpts, documentTypeOpts, setRefreshTable, company } = props;
    const { t } = useTranslation();
    const _formRef = useRef();
    const [reset, setReset] = useState(false);
    const [selectedDocType, setSelectedDocType] = useState();
    const [formValues, setFormValues] = useState({});

    const _FIELDS = useMemo(() => {
        const _FORM_FIELDS = [
            {
                rowOptions: { xl: 12 },
                columns: [
                    {
                        prefix: { icon: Icon._ADD_DOCUMENT_TYPE }, label: t("DOCUMENT_TYPE"), name: 'documentTypeId', placeholder: t("DOCUMENT_TYPE"), input: InputTypes.SELECT, options: documentTypeOpts, columnOptions: { xl: 12 },
                        onChange: (value) => { setSelectedDocType(value); setFormValues({ documentTypeId: value, documentId: null }); }
                    },

                ]
            },
            {
                rowOptions: { xl: 12 },
                columns: [
                    {
                        prefix: { icon: Icon._ADD_DOCUMENT }, label: t("DOCUMENT"), name: 'documentId', placeholder: t("DOCUMENT"), input: InputTypes.SELECT,
                        options: documentOpts.filter(d => !selectedDocType || d.documentTypeId === selectedDocType), columnOptions: { xl: 12 },
                        rules: { required: "Document is required" }
                    },

                ]
            }
        ];

        return _FORM_FIELDS;
    }, [documentOpts, documentTypeOpts, selectedDocType]);

    ///<summary>
    ///Author: Robin
    ///</summary>
    const generateCompanyDocument = async (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let companyDao = new CompanyDao();
        await companyDao.generateCompanyDocument(company?.id, data?.documentId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(t('notiflix:DOCUMENT_UPLOAD_SUCCESS'));
                setRefreshTable(true);
                setRefreshTable(false);
                toggle();
            } else {
                let error = response[ApiKey._API_FIRST_ERROR_KEY];
                if (error) {
                    Notify.Warning(error?.detail);
                }
            }
        }).finally(() => Block.Remove(".modal-content"));
    };

    useEffect(() => {
        if (!modal) {
            setFormValues({});
            if (selectedDocType)
                setSelectedDocType(null);
        }
    }, [modal]);

    useEffect(() => {
        setReset((prevState) => !prevState);
    }, [formValues])

    const toggle = () => { setModal(!modal); }

    return <>
        <BrandModal modalSize={"md"} customBody title={t("GENERATE_COMPANY_DOCUMENT")} isOpen={modal} toggler={toggle} centered backdrop='static'>
            <ModalBody>
                <FormBuilder fields={_FIELDS} formRef={_formRef} onSubmit={generateCompanyDocument}
                    reset={reset} resetTriggerReset={setReset} resetValues={formValues} />
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-min-width btn-themed grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" onClick={() => submitForm(_formRef)} className="btn btn-min-width btn-themed">{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>
}

const UploadSignatureForm = props => {
    const { modal, setModal, companyDocumentId, setCompanyDocumentId, setRefreshTable, company } = props;
    const _formRef = useRef();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const { t } = useTranslation();
    const FormKeys = {
        _SIGNED_DOC_ATTACH: "signedDocumentAttachment"
    };

    const _WATCH_SIGNED_DOC_ATTACH = watch(FormKeys._SIGNED_DOC_ATTACH);

    ///<summary>
    ///Author: Robin
    ///</summary>
    const uploadSignatureDocument = async (data) => {
        var companyId = company?.id;

        let companyDao = new CompanyDao();
        await companyDao.uploadSignedCompanyDocument(companyId, companyDocumentId, data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(t('notiflix:SIGNED_DOCUMENT_UPLOAD_SUCCESS'));
                setRefreshTable(true);
                setRefreshTable(false);
                toggle();
            }
        })
    };

    const toggle = () => {
        setCompanyDocumentId(null);
        setModal(!modal);
    }

    return <>
        <BrandModal isOpen={modal} title={t("UPLOAD_SIGNED_DOCUMENT")} toggler={toggle} centered backdrop='static'>
            <form onSubmit={handleSubmit(uploadSignatureDocument)} ref={_formRef} autoComplete="off">
                <ModalBody>
                    <div className="document-upload">
                        <InputHoc name={FormKeys._SIGNED_DOC_ATTACH} error={errors?.[FormKeys._SIGNED_DOC_ATTACH]?.message}
                            label="ATTACHMENT" inputType={InputTypes.FILEUPLOAD} control={control} />
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-center">
                    <button type="button" className="btn btn-themed btn-rest btn-min-width" onClick={toggle}>{t("CANCEL")}</button>
                    <button type="button" onClick={() => {
                        Notiflix.Confirm.Show(t("notiflix:UPLOAD_SIGNATURE"), isEmpty(_WATCH_SIGNED_DOC_ATTACH) ? t("notiflix:UPLOAD_SIGNED_DOCUMENT") : t("notiflix:CONFIRM_UPLOAD_SIGNED_DOCUMENT"), t("notiflix:YES"), t("notiflix:NO"), () => {
                            submitForm(_formRef);
                        });
                    }} className="btn btn-themed btn-min-width">{t("SAVE")}</button>
                </ModalFooter>
            </form>
        </BrandModal>
    </>
}


export default CompanyDocuments;