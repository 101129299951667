import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useResizeDetector } from 'react-resize-detector';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { RoleDao } from 'data';
import { isEmpty } from 'lodash';
import Notiflix, { Block } from 'notiflix';
import { PageSettings } from 'config/page-settings';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import BrandModal from 'components/modals/BrandModal';
import ButtonRound from 'components/buttons/ButtonRound';
import ReactTable from "components/react-table/ReactTable";
import { InputTypes, BtnTypes, ApiKey, LoadingStateText, SweetAlert, ApiUrl } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import RolePolicyObjectAssignActionsModal from './RolePolicyObjectAssignActionsModal'

//<summary>
//Author: Robin
//</summary>
export const RoleUsersInRoleModal = props => {

    const { modal, setModal, usersInRole, setUsersInRole } = props;

    const { t } = useTranslation();
    const { ref: wrapperRef } = useResizeDetector();

    const _USER_COLUMN = useMemo(() => [
        {
            id: "name",
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                if (row.original.name) {
                    return (row.original.name);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "email",
            Header: "EMAIL",
            accessor: "email",
            Cell: ({ row }) => {
                if (row.original.email) {
                    return (row.original.email);
                } else {
                    return <> - </>
                }
            }
        }
    ], []);

    const toggle = () => {
        setModal(!modal);
        setUsersInRole([]);
    }

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    return (<>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={t("systemConfiguration:USERS_IN_ROLE")}
            customBody
        >
            <ModalBody className="p-0" id="role">
                <div className="d-flex flex-column h-100">
                    <div className="flex-grow-1" ref={wrapperRef}>
                        <div style={{ height: fixedContentHeight }}>
                            <ReactTable
                                enableSearch
                                columns={_USER_COLUMN}
                                data={usersInRole}
                                setPagination
                                isInheritHeight
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("DONE")}</button>
            </ModalFooter>
        </BrandModal>
    </>);
};

export default RoleUsersInRoleModal;