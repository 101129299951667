import React from "react";
import { Report, Block } from "notiflix";
import i18n from 'i18next';
import DataAccessObject from "data/DataAccessObject";
import { ApiKey, LoadingStateText, SweetAlert } from "./Constant";

/// <summary>
/// Author: Lewis
/// </summary>
export const CustomFetcher = async (...args) => {

    let [url, methodKey, postData, blockerId, ...restArgs] = args;
    if (!url) return;

    let dao = new DataAccessObject();

    if (blockerId) Block.Circle(blockerId, i18n.t(LoadingStateText._PLEASE_WAIT));

    switch (methodKey) {

        case ApiKey._API_GET:

            return await dao.get(url).then(res => {

                res[ApiKey._API_SUCCESS_KEY] ??
                    Report.Warning(
                        res[ApiKey._API_MESSAGE_KEY],
                        res[ApiKey._API_FIRST_ERROR_KEY].detail ?? "Request failed.",
                        i18n.t(SweetAlert._OK),
                    );

                return res;

            }).finally(() => {
                if (blockerId) Block.Remove(blockerId);
            });

        case ApiKey._API_POST:

            return await dao.post(url, postData).then(res => {

                if(res.status == 200) {
                    res[ApiKey._API_SUCCESS_KEY] ??
                        Report.Warning(
                            res[ApiKey._API_MESSAGE_KEY],
                            res[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                            i18n.t(SweetAlert._OK),
                        );
                } 
                
                return res;

            }).finally(() => {
                if (blockerId) Block.Remove(blockerId);
            });

        default:
            break;
    }
}