import React, { useMemo, useEffect, useRef, useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import { ApiKey, WebUrl, LoadingStateText, InputTypes, RegexValidation, SweetAlert, Language, ApiUrl } from 'util/Constant';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { alertNotification, authCredentialState, notificationState } from 'recoil/Atoms';
import { Card, CardBody, Row, Col, CardHeader } from "reactstrap";
import { CompanyDao, FactSheetDao } from "data";
import { cleanObject, getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty } from 'util/Utility';
import StepZilla from "react-stepzilla";
import FormBuilder from "components/form/FormBuilder";
import { Loading, Report, Block, Confirm } from "notiflix";
import InputHoc from "components/form/InputHoc";
import { useTranslation } from "react-i18next";
import { CustomFetcher } from "util/CustomFetcher";

const GetStarted = (props) => {

    const [factSheet, setFactSheet] = useState({});

    const getFactSheet = async () => {
        let dao = new FactSheetDao();
        await dao.getFactSheetList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY][0];
                setFactSheet(data);
            }
        })
    }

    const _dashboardWizzardArray = [
        { name: '1', component: <Welcome /> },
        { name: '2', component: <SubmitNameCheck factSheet={factSheet} /> },
        { name: '3', component: <NameCheckResult /> }
    ]

    useEffect(() => {
        getFactSheet();
    }, []);

    return (
        <div className="panel panel-brand panel-flex" id="brand-dashboard">
            <div className="h-100 panel-body y-scrollbar-2" style={{ padding: "6.25rem" }}>
                <StepZilla
                    preventEnterSubmission={true}
                    showNavigation={false}
                    steps={_dashboardWizzardArray}
                    stepsNavigation={false}
                    showSteps={false}
                    nextButtonCls='btn btn-prev pull-right'
                    backButtonCls='btn btn-next pull-left'
                />
            </div>
        </div>
    );
}

const Welcome = ({ jumpToStep }) => {

    const { t } = useTranslation();

    return (
        <div id="welcome-dashboard">
            <div className="d-flex justify-content-center">
                <div>
                    <div className="page-header title title-with-dot" style={{ justifyContent: "center" }}>{t("WELCOME_TO_YICOM")}</div>
                    <p className="grayscale-100">{t("MANAGE_CMP_AT_YOUR_FINGER_TIPS")}</p>
                </div>
            </div>
            <div className="d-flex-center justify-content-center flex-wrap p-40">
                <img src={require("../../assets/img/ui/grapic-starting.png")} className="width-350 m-5 m-r-40 m-b-0 intro-brand-image" />
                <div className="width-450">
                    <div className="page-header title title-with-question-mark d-inline-block">{t("STARTING_YOUR_CMP")}</div>
                    <p className="grayscale-100">{t("WONDERING_HOW_TO_START")}</p>
                    <div className="btn btn-themed width-150" onClick={() => jumpToStep(1)}>{t("START_NOW")}</div>
                </div>
            </div>
        </div>
    );
}

const SubmitNameCheck = props => {
    let { factSheet, jumpToStep, ...rest } = props;

    const { t, i18n } = useTranslation();
    const formRef = useRef();
    const [reset, setReset] = useState(false);
    const { firmId } = useRecoilValue(authCredentialState);

    const _ENGLISH_COMPANY_NAME_POSTFIX = "LIMITED";
    const _CHINESE_COMPANY_NAME_POSTFIX = "有限公司";
    const _CARD_BODY_STYLE = {
        padding: 0,
        backgroundColor: "white"
    }

    const nameCheckFormFields = useMemo(() => {
        const _FIELDS = [
            {
                rowOptions: { xl: 1 },
                columns: [
                    {
                        label: t("PROPOSED_NAME"),
                        name: "englishName",
                        input: InputTypes.INPUT,
                        columnOptions: { xl: 12 },
                        onChange: (e) => { e.target.value = e.target.value.toUpperCase() },
                        postfix: _ENGLISH_COMPANY_NAME_POSTFIX.toUpperCase(),
                        formGroupClass: "m",
                        rules: { required: "Company Name is required" },
                        placeholder: t("PROPOSED_NAME"),
                    },
                    {
                        label: `${t("PROPOSED_CN_NAME")} (${t("OPTIONAL")})`,
                        name: "chineseName",
                        input: InputTypes.INPUT,
                        columnOptions: { xl: 12 },
                        postfix: _CHINESE_COMPANY_NAME_POSTFIX,
                        rules: {
                            required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") }
                        },
                        showError: true,
                        placeholder: `${t("PROPOSED_NAME")} (${t("CHINESE")})`
                    },
                    { label: "Firm ID", name: "firmId", value: firmId ? firmId : null, hidden: true, rules: { required: false } },
                ],
            },
        ];
        return _FIELDS;
    }, [firmId]);

    const createCompanyNameCheck = (formData) => {
        formData = cleanObject(formData);

        let dao = new CompanyDao();

        formData.jurisdictionId = 1;
        formData.englishName = `${formData.englishName.toUpperCase()} ${_ENGLISH_COMPANY_NAME_POSTFIX}`;

        if (!stringIsNullOrEmpty(formData.chineseName)) {
            formData.chineseName += _CHINESE_COMPANY_NAME_POSTFIX;
        }

        Confirm.Show(
            t('SUBMIT_NAME_CHECK'),
            `${t("notiflix:CONFIRM")}${t("SUBMIT_NAME_CHECK")}?`,
            t("notiflix:YES"),
            t("notiflix:NO"),
            async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                await CustomFetcher(
                    ApiUrl._API_CREATE_COMPANY_NAME_CHECK,
                    ApiKey._API_POST,
                    formData,
                    "div#company-namecheck-form",
                ).then(res => {

                    if (res[ApiKey._API_SUCCESS_KEY]) {
                        Report.Success(
                            t("notiflix:SUBMIT_NAME_CHECK_SUCCESS"),
                            t("notiflix:SUCCESS_CMP_NAME_SUBMIT_REDIRECT"),
                            t("notiflix:OK"),
                            () => {
                                jumpToStep(2);
                            })
                        return;
                    }
                    else if (res[ApiKey._API_STATUS_KEY] == 400) {
                        let errorMessage = "";
                        if (res[ApiKey._API_ERROR_KEY]?.EnglishName) {
                            errorMessage += `${t('notiflix:ENG_NAME_CHECK_SUBMIT_FAILED_MSG')}<br />`;
                        }
                        if (res[ApiKey._API_ERROR_KEY]?.ChineseName) {
                            errorMessage += `${t('notiflix:CN_NAME_CHECK_SUBMIT_FAILED_MSG')}<br />`;
                        }
                        console.log("ERRORMESSAGE", errorMessage);
                        Report.Warning(
                            t('notiflix:NAME_CHECK_SUBMIT_FAILED'),
                            errorMessage,
                            t('notiflix:OK')
                        );
                    }
                    setReset(prevState => !prevState);

                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            },
            () => { return });
    };

    /// usage : set input size dynamically with placeholder string size
    useEffect(() => {
        const hinterNote = document.querySelectorAll('#hinter-get-started-note');
        for (var i = 0; i < hinterNote.length; i++) {

            let size = i18n.language === Language._ENGLISH_GB ? hinterNote[i].getAttribute('placeholder').length
                : hinterNote[i].getAttribute('placeholder').length * 2;

            hinterNote[i].setAttribute('size', size);
        }
    }, [i18n.language]);

    return (
        <div id="dashboard-nameCheck">
            <div className="factsheet-hint">
                <div className="page-header title title-with-dot">{t("LETS_GET_STARTED")}</div>
                <Row className="w-100">
                    <Col xl={3}></Col>
                    <Col xl={6}>
                        <InputHoc
                            formGroupClass="hinter-grp-cls"
                            id="hinter-get-started-note"
                            disabled={true}
                            prefix={<img src={require("../../assets/img/icon/bulb.svg")} />}
                            placeholder={t("HELP_CHECK_CMP_AVAILABILITY")}
                        />
                    </Col>
                    <Col xl={3}></Col>
                </Row>
            </div>
            <div className="instruction-content d-flex flex-wrap">
                <Col xl={6} md={12} sm={12} className="factsheet-detail">
                    <div className="d-flex flex-column">
                        <img src={require("../../assets/img/ui/grapic-letstart.png")} className="width-350 intro-brand-image m-auto" />
                        <Card className="panel-brand factsheet-card mt-5 w-50 overflow-hidden" style={{ borderRadius: '1.5rem' }}>
                            <CardHeader style={{ borderRadius: '1.5rem 1.5rem 0 0' }}>
                                <Row>
                                    <Col>
                                        <img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(factSheet.title)} />
                                        {factSheet.title}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody style={_CARD_BODY_STYLE} className="y-scrollbar-2">
                                {
                                    factSheet.itemGroups.length !== 0
                                        ?
                                        <div className="factsheet-details">
                                            <table className={`table table-factsheet `}>
                                                <tbody>
                                                    {
                                                        factSheet.itemGroups.map((groups, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <th>{groups.name}</th>
                                                                    <td>
                                                                        <ul className="ul-factsheet">
                                                                            {
                                                                                groups.items.map((items, index) => {
                                                                                    return (

                                                                                        <li key={index}>{items.text ? items.text : "-"}</li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div>{t("NO_DATA_AVAILABLE")}</div>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Col>
                <Col xl={6} md={12} sm={12} className="namecheck-submit" id="company-namecheck-form">
                    <div className="form-short w-60 m-0">
                        <div className="page-header title title-with-dot ">{t("SUBMIT_NAME_CHECK")}</div>
                        <FormBuilder
                            fields={nameCheckFormFields}
                            onSubmit={createCompanyNameCheck}
                            formRef={formRef}
                            reset={reset}
                            resetTriggerReset={setReset}
                        />
                    </div>
                    <div className="d-flex justify-content-start">
                        <button type="submit" className="btn btn-themed btn-min-width m-r-5"
                            onClick={() => {
                                formRef.current.dispatchEvent(
                                    new Event("submit", { cancelable: true })
                                );
                            }}
                        >{t("SUBMIT")}</button>
                        <button type="button" className="btn btn-min-width" onClick={() => jumpToStep(0)}>{t("CANCEL")}</button>
                    </div>
                </Col>
            </div>
        </div>
    )
}

const NameCheckResult = props => {
    const { t } = useTranslation();
    const _history = useHistory();

    return (<>
        <div className="d-flex justify-content-center">
            <div>
                <div className="page-header title title-with-dot" style={{ justifyContent: "center" }}>{t("HOLD_ON_BE_PATIENT")}</div>
                <p className="grayscale-100">{t("THANK_YOU_TRUSTING_YICOM_WORK_ON_CONFIRMATION")}</p>
            </div>
        </div>
        <div className="d-flex-center justify-content-center flex-wrap p-40">
            <img src={require("../../assets/img/ui/register-success.png")} className="width-350 m-r-40 intro-brand-image" />
            <div className="width-450 mt-5">
                <div className="page-header title title-with-exclamation-mark d-inline-block">{t("SUBMITTED_SUCCESSFULLY")}</div>
                <p className="grayscale-100">{`${t("SUBMIT_NAMECHECK_TAKE_FEW_DAYS_MSG")} "${t("COMPANY_MANAGEMENT")}"`}</p>
                <div className="btn btn-themed width-150" onClick={() => _history.push(WebUrl._COMPANY_MANAGEMENT)}>{t("OKAY")}</div>
            </div>
        </div>
    </>)
}

export default GetStarted;