import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Controller } from 'react-hook-form';
import { stringIsNullOrEmpty } from 'util/Utility';
import { trigger } from 'swr';

/// <summary>
/// Author: Chris
/// </summary>
const TextArea = forwardRef((props, ref) => {
    const {
        error,
        minRows,
        maxRows,
        className,
        control,
        onChange: propsOnChange,
        defaultValue: propsDefaultValue,
        ...rest
    } = props;

    const [hasError, setHasError] = useState();
    const [defaultValue, setDefaultValue] = useState(null);

    const inputClasses = classnames(
        'form-control',
        'form-control-lg',
        'm-b-5',
        'line-height-lg',
        'y-scrollbar-2',
        { 'has-error': hasError },
        className
    );

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    if (control != undefined) {
        return (
            <Controller
                {...rest}
                defaultValue={propsDefaultValue}
                control={control}
                render={({ onChange, onBlur, value, name, ...otherProps }) => {
                    return (
                        <TextareaAutosize
                            ref={ref}
                            name={name}
                            minRows={minRows}
                            maxRows={maxRows}
                            className={inputClasses}
                            disabled={rest?.disabled ? true : false}
                            onChange={(e) => {
                                onChange(e.target.value);
                                propsOnChange && propsOnChange(e)
                            }}
                            onBlur={onBlur}
                            value={defaultValue !== null ? defaultValue : value}
                            //value={!stringIsNullOrEmpty(propsDefaultValue) ? propsDefaultValue : defaultValue }
                            {...otherProps}
                            {...rest}
                        />
                    )
                }}
            />
        )
    }

    return <TextareaAutosize ref={ref} {...rest} className={inputClasses} minRows={minRows} maxRows={maxRows} />
});

TextArea.defaultProps = {
    rows: 4,
    placeholder: ' '
}

export default TextArea;