import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Notiflix, { Block } from "notiflix";
import { InputTypes, SelectField, WebUrl, ApiKey, ApiUrl, deleteConfirm, BtnTypes, Icon, LoadingStateText, DefaultCurrencyId, DefaultCurrency, TableId } from "util/Constant";
import { formatJurisdictionList } from "util/Utility.js";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import InputHoc from "components/form/InputHoc";
import ProductDao from '../../data/ProductDao';
import CurrencyDao from '../../data/CurrencyDao';
import NumberFormat from 'react-number-format';
import { useRecoilValue } from 'recoil';
import { authCredentialState, currenciesState } from 'recoil/Atoms';
import TabPanel from '../../components/panel/TabPanel';
import { Tab, Tabs } from '@material-ui/core';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import ButtonRound from "components/buttons/ButtonRound";
import BrandModal from "components/modals/BrandModal";
import { useResizeDetector } from "react-resize-detector";
import { useTranslation } from 'react-i18next';
import useSWR from "swr";

///<summary>
///Author: Ong Sze Hua
///</summary>
const ServiceFeeManagement = props => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [product, setProduct] = useState();
    const formRef = useRef();
    const [selectedDiscountRate, setSelectedDiscountRate] = useState();
    const [selectedCommissionRate, setSelectedCommissionRate] = useState();
    const productType = { income: 1, expense: 2 };
    const [expenseRefreshTable, setExpenseRefreshTable] = useState(false);
    const [incomeRefreshTable, setIncomeRefreshTable] = useState(false);
    const [expenseFiltersForm, setExpenseFiltersForm] = useState({ productTypeId: productType.expense });
    const [incomeFiltersForm, setIncomeFiltersForm] = useState({ productTypeId: productType.income });
    const [selectedRow, setSelectedRow] = useState();
    const [currencyList, setCurrencyList] = useState([]);
    const { ref: wrapperRef } = useResizeDetector();
    const { currencyPreference, ...restAuthProps } = useRecoilValue(authCredentialState);
    const { register, control, handleSubmit, errors, reset, watch } = useForm();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    const [resetFilters, setResetFilters] = useState(false);
    const _searchFormRef = useRef();
    const _searchFields = useMemo(() => [
        {
            rowOptions: { xl: 8 },
            columns: [
                { prefix: { icon: Icon._SEARCH_JURISDICTION }, label: t('SEARCH_JURISDICTION'), name: 'jurisdictionId', placeholder: t("SEARCH_JURISDICTION"), input: InputTypes.SELECT, options: jurisdictionOptions, columnOptions: { xl: 4, md: 4 } },
                { prefix: '#', label: t('systemConfiguration:SEARCH_PRODUCT_CODE'), name: 'productCode', placeholder: t("systemConfiguration:SEARCH_PRODUCT_CODE"), input: InputTypes.INPUT, columnOptions: { xl: 4, md: 4 } },
                { prefix: '#', label: t('systemConfiguration:SEARCH_DESCRIPTION'), name: 'description', placeholder: t("systemConfiguration:SEARCH_DESCRIPTION"), input: InputTypes.INPUT, columnOptions: { xl: 4, md: 4 } }

            ]
        }
    ], [jurisdictionOptions]);

    const _PRICE_COLUMN = useMemo(() => {
        let result = [
            {
                id: "Price",
                Header: "PRICE_BASE",
                accessor: "price",
                Cell: ({ value }) => <>
                    {DefaultCurrency.toUpperCase()} <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                </>,
                isRequiredColumn: true
            }
        ];
        if (currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate)
            result.push(
                {
                    id: "Quote",
                    Header: "PRICE_QUOTE",
                    accessor: "quoteCurrency.quotePrice",
                    Cell: ({ row, value }) => <>{row.original.quoteCurrency?.code} <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    </>
                }
            )
        return result;
    }, [currencyPreference]);

    const _PRODUCT_COLUMN = useMemo(() => [
        {
            id: "Code",
            Header: "systemConfiguration:CODE",
            accessor: "productCode",
            className: "width-100"
        },
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => <div style={{ whiteSpace: 'initial' }}>
                {row.original.name}
            </div>,
            filterValue: ({ row }) => row.original.name,
            className: "w-25",
            isRequiredColumn: true
        },
        // {
        //     id: "Currency",
        //     Header: "CURRENCY",
        //     accessor: "currencyId",
        //     Cell: ({ row }) => <>
        //         {
        //             currencyList.find(c => c.value == row.original.currencyId)?.label
        //         }
        //     </>,
        //     filterValue: ({ row }) => currencyList.find(c => c.value == row.original.currencyId)?.label || "",
        //     className: "width-100"
        // },
        ..._PRICE_COLUMN,
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => <div style={{ whiteSpace: 'pre-wrap' }}>
                {row.original.description}
            </div>,
            filterValue: ({ row }) => row.original.description,
            className: "w-25"
        },
    ], [currencyList, _PRICE_COLUMN]);

    const _INCOME_COLUMN = useMemo(() => [
        ..._PRODUCT_COLUMN,
        //temporary hide discount and commision
        //{
        //    id: "Discount",
        //    Header: "DISCOUNT",
        //    Cell: ({ row }) => <>
        //        {(row.original.discountRate || row.original.discountAmount) && <div>
        //            <div className="btn-round expand-theme btn-sm mr-1" id={"discountBtn" + (row.original.id)} >{row.original.discountRate ? <i className="las la-percentage"></i> : <i className="las la-dollar-sign"></i>}</div>
        //            <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"discountBtn" + (row.original.id)}>
        //                <table style={{ fontSize: "13px", lineHeight: 1, textAlign: 'left' }}>
        //                    <tbody>
        //                        {row.original.discountRate &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Discount Rate (%) :</td>
        //                                <td>{row.original.discountRate}</td>
        //                            </tr>
        //                        }
        //                        {row.original.discountRateCapped &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Discount Capped ($) :</td>
        //                                <td>{row.original.discountRateCapped}</td>
        //                            </tr>
        //                        }
        //                        {row.original.discountAmount &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Discount Amount ($) :</td>
        //                                <td>{row.original.discountAmount}</td>
        //                            </tr>
        //                        }
        //                    </tbody>
        //                </table>
        //            </UncontrolledTooltip>
        //        </div>
        //        }
        //    </>,
        //    disableSortBy: true
        //},
        //{
        //    id: "Commission",
        //    Header: "COMMISSION",
        //    Cell: ({ row }) => <>
        //        {(row.original.commissionRate || row.original.commissionAmount) && <div>
        //            <div className="btn-round expand-theme btn-sm mr-1" id={"commissionBtn" + (row.original.id)} >{row.original.commissionRate ? <i className="las la-percentage"></i> : <i className="las la-dollar-sign"></i>}</div>
        //            <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"commissionBtn" + (row.original.id)}>
        //                <table style={{ fontSize: "13px", lineHeight: 1, textAlign: 'left' }}>
        //                    <tbody>
        //                        {row.original.commissionRate &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Commission Rate (%) :</td>
        //                                <td>{row.original.commissionRate}</td>
        //                            </tr>
        //                        }
        //                        {row.original.commissionRateCapped &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Commission Capped ($) :</td>
        //                                <td>{row.original.commissionRateCapped}</td>
        //                            </tr>
        //                        }
        //                        {row.original.commissionAmount &&
        //                            <tr>
        //                                <td style={{ overflowWrap: "nowrap", width: "100px" }}>Commission Amount ($) :</td>
        //                                <td>{row.original.commissionAmount}</td>
        //                            </tr>
        //                        }
        //                    </tbody>
        //                </table>
        //            </UncontrolledTooltip>
        //        </div>
        //        }
        //    </>,
        //    disableSortBy: true
        //},
        {
            Header: "ACTION",
            Cell: ({ row }) => <div className="btn-list-wrapper d-flex">
                <ButtonRound medium type={BtnTypes.EDIT} onClick={() => {
                    toggle();
                    setProduct(row.original, { productTypeId: productType.income });
                    setSelectedDiscountRate(row.original.discountRate);
                    setSelectedCommissionRate(row.original.commissionRate);
                }} />
                <ButtonRound medium type={BtnTypes.DELETE} onClick={() => {
                    Notiflix.Confirm.Show(`${t('DELETE')} ${row.original.productCode}?`, `${t('DO_YOU_WISH_TO_DELETE')} ${row.original.name}?`, t('YES'), t('NO'),
                        () => { deleteProduct(row.original.id) }, '', deleteConfirm)
                }} />
            </div>,
            disableSortBy: true,
            style: { overflow: "visible" },
            isRequiredColumn: true
        }

    ], [_PRODUCT_COLUMN]);

    const _EXPENSE_COLUMN = useMemo(() => [
        ..._PRODUCT_COLUMN,
        {
            Header: "ACTION",
            Cell: ({ row }) => <div className="btn-list-wrapper d-flex">
                <ButtonRound medium type={BtnTypes.EDIT} onClick={() => { toggle(); setProduct(row.original, { productTypeId: productType.expense }) }} />
                <ButtonRound medium type={BtnTypes.DELETE} onClick={() => {
                    Notiflix.Confirm.Show(`${t('DELETE')} ${row.original.productCode}?`, `${t('DO_YOU_WISH_TO_DELETE')} ${row.original.name}?`, t('YES'), t('NO'),
                        () => {
                            deleteProduct(row.original.id)
                        }, '', deleteConfirm)
                }} />
            </div>,
            disableSortBy: true,
            style: { overflow: "visible" },
            isRequiredColumn: true
        }
    ], [_PRODUCT_COLUMN]);


    const _TABS = {
        INCOME: { id: 0, title: t("systemConfiguration:INCOME"), productType: productType.income, column: _INCOME_COLUMN, filtersForm: incomeFiltersForm, refreshTable: incomeRefreshTable, icon: require("../../assets/img/icon/income.svg") },
        EXPENSE: { id: 1, title: t("systemConfiguration:EXPENSE"), productType: productType.expense, column: _EXPENSE_COLUMN, filtersForm: expenseFiltersForm, refreshTable: expenseRefreshTable, icon: require("../../assets/img/icon/expenses.svg") }
    };

    const [currentTab, setCurrentTab] = useState(_TABS.INCOME.id);

    // async function getCurrencyList() {
    //     let currencyDao = new CurrencyDao();
    //     await currencyDao.getCurrencyList().then(responseJson => {
    //         if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //             let data = responseJson[ApiKey._API_DATA_KEY];
    //             data = data.map(d => { return { label: d.code, value: d.id } })
    //             setCurrencyList(data);
    //         }
    //     })
    // }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let params = { ...data, productTypeId: product.productTypeId };
        let msg;
        if (product?.name) {
            params["id"] = product.id;
            params["productCode"] = product.productCode;
            params["jurisdictionId"] = product.jurisdictionId;
        }

        let dao = new ProductDao();
        (async () => {
            if (product.productTypeId === productType.income) {
                if (params.id)
                    msg = t("notiflix:SUCCESS_UPDATE_INCOME");
                else
                    msg = t("notiflix:SUCCESS_CREATE_INCOME");
                await dao.createOrUpdateIncomeProduct(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setIncomeRefreshTable(prevState => !prevState);
                        Notiflix.Notify.Success(msg);
                        toggle();
                    }
                }).finally(() => Block.Remove(".modal-content"));
            }
            else {
                if (params.id)
                    msg = t("notiflix:SUCCESS_UPDATE_EXPENSE");
                else
                    msg = t("notiflix:SUCCESS_CREATE_EXPENSE");
                await dao.createOrUpdateExpenseProduct(params).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setExpenseRefreshTable(prevState => !prevState);
                        Notiflix.Notify.Success(msg);
                        toggle();
                    }
                }).finally(() => Block.Remove(".modal-content"));
            }
        })();
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(!modal); };
    const onClosed = () => { setProduct(null); setSelectedDiscountRate(true); setSelectedCommissionRate(true); }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    async function deleteProduct(id) {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        let productDao = new ProductDao();
        await productDao.deleteProduct(id).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t("notiflix:SUCCESS_REMOVE_PRODUCT"));
                setIncomeRefreshTable(prevState => !prevState);
                setExpenseRefreshTable(prevState => !prevState);
            }
        }).finally(() => Block.Remove(".notiflix-confirm-content"));
    }

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    const filterOnSubmitHandler = (value) => {
        if (currentTab === _TABS.INCOME.id) {
            setIncomeFiltersForm(() => { return { ...value, productTypeId: productType.income } })
        }
        else if (currentTab === _TABS.EXPENSE.id) {
            setExpenseFiltersForm(() => { return { ...value, productTypeId: productType.expense } })
        }
    };

    /// <summary>
    /// Author : Ong Sze Hua
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        if (currentTab === _TABS.INCOME.id) {
            setIncomeFiltersForm({ productTypeId: productType.income })
        }
        else if (currentTab === _TABS.EXPENSE.id) {
            setExpenseFiltersForm({ productTypeId: productType.expense })
        }
    };

    useEffect(() => {
        setResetFilters(!resetFilters);
        setIncomeFiltersForm({ productTypeId: productType.income });
        setExpenseFiltersForm({ productTypeId: productType.expense });
    }, [currentTab]);

    useEffect(() => {
        if (product) {
            if (product.name)
                reset(product);
            else
                reset({})
        }
    }, [product]);

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    return (<>
        <div className="panel panel-brand panel-flex" id="ProductManagement">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot">{t("SERVICE_FEE_MANAGEMENT")}</h1>
                <Tabs value={currentTab} onChange={(e, newTab) => { setCurrentTab(newTab) }} variant="scrollable">
                    {Object.values(_TABS).map(data => <Tab icon={<img src={data.icon} className="mr-2" />} label={data.title} classes={{ wrapper: "d-inline" }} />)}
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="panel-body-inner-content with-br-radius">
                    {
                        Object.values(_TABS).map(data => <>
                            <TabPanel value={currentTab} index={data.id} key={data.id} style={{ height: "100%" }}>
                                <div className="d-flex flex-column h-100">
                                    <div className="filter-panel-container border-radius-none" >
                                        <Row className="filter-panel-body p-t-15">
                                            <Col xl={8}>
                                                <FormBuilder fields={_searchFields} formRef={_searchFormRef} onSubmit={filterOnSubmitHandler}
                                                    reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                                            </Col>
                                            <Col xl={4} className="btn-list d-flex justify-content-end">
                                                <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_searchFormRef)}><i className="las la-search" /></button>
                                                <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="flex-grow-1" ref={wrapperRef}>
                                        <div style={{ height: fixedContentHeight }}>
                                            <ReactTable
                                                enableSearch
                                                columns={data.column}
                                                refreshTable={data.refreshTable}
                                                filterFormfield={data.filtersForm}
                                                setPagination url={ApiUrl._API_GET_PRODUCTS}
                                                customButtons={
                                                    <button type="button" className="btn btn-themed btn-min-width tbl-custom-brand-btn" onClick={() => { toggle(); setProduct({ productTypeId: data.productType }) }} >{t("ADD")} {data.title}</button>
                                                }
                                                tableColumnPreference={[restAuthProps?.id, `${TableId._SERVICE_FEE_MANAGEMENT}-${data.id}`]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </>)
                    }
                </div>
            </div>
        </div>
        <BrandModal
            id="ProductManagementModal"
            isOpen={modal}
            toggler={toggle}
            title={product?.name ? product?.productTypeId === productType.income ? t("systemConfiguration:EDIT_INCOME") : t("systemConfiguration:EDIT_EXPENSE") : product?.productTypeId === productType.income ? t("systemConfiguration:ADD_INCOME") : t("systemConfiguration:ADD_EXPENSE")}
            customBody
            onClosed={onClosed}
        >
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef} autoComplete="off">
                    <Row>
                        {
                            product && !product.name &&
                            <>
                                <Col xs={5}>
                                    <InputHoc name="jurisdictionId" error={errors?.jurisdictionId?.message} label={t("JURISDICTION")} inputType={InputTypes.SELECT} options={jurisdictionOptions}
                                        control={control} rules={{ required: 'Jurisdiction is required.' }} />
                                </Col>
                            </>
                        }
                        <Col xs={product?.name ? 12 : 7}>
                            <InputHoc name="name" error={errors?.name?.message} label={t("NAME")} ref={register({ required: 'Name is required.' })} />
                        </Col>
                        {/* <Col xs={5}>
                            <InputHoc name="currencyId" error={errors?.currencyId?.message} label={t("CURRENCY")} inputType={InputTypes.SELECT} options={currencyList}
                                control={control} rules={{ required: 'Currency is required.' }} />
                        </Col> */}
                        <Col xs={5}>
                            <InputHoc name="price" error={errors?.price?.message} label={t("PRICE")} rules={{ required: 'Price is required.', pattern: { value: /^\d+(,\d{3})*(\.\d{1,2})?$/, message: "Only accept number" } }} inputType={InputTypes.NUMBER_INPUT} control={control} minValue={0} />
                        </Col>
                        <Col xs={7}>
                            <InputHoc name="description" error={errors?.description?.message} label={`${t("DESCRIPTION")} (${t("OPTIONAL")})`} inputType={InputTypes.TEXTAREA} ref={register()} />
                        </Col>
                    </Row>
                </form>
                {
                    //temporary hide discount and commision
                    //product?.productTypeId === productType.income &&
                    //<>
                    //    <strong>Discount Type</strong>
                    //    <Row className="m-t-5">
                    //        <div className={classnames("col-xl-6 item-row", { 'active': selectedDiscountRate })} onClick={() => { setSelectedDiscountRate(true) }}>
                    //            <Card className="h-100">
                    //                <CardBody>
                    //                    <div className="chk-item d-flex justify-center m-auto"><span className={classnames("check-icon", { 'active': selectedDiscountRate })}><i className="fa fa-check-circle"></i></span></div>
                    //                    <strong className="m-auto">Rate Based</strong>
                    //                </CardBody>
                    //            </Card>
                    //        </div>
                    //        <div className={classnames("col-xl-6 item-row", { 'active': !selectedDiscountRate })} onClick={() => { setSelectedDiscountRate(false) }}>
                    //            <Card className="h-100">
                    //                <CardBody>
                    //                    <div className="chk-item d-flex justify-center m-auto"><span className={classnames("check-icon", { 'active': !selectedDiscountRate })}><i className="fa fa-check-circle"></i></span></div>
                    //                    <strong className="m-auto">Amount Based</strong>
                    //                </CardBody>
                    //            </Card>
                    //        </div>
                    //        {
                    //            selectedDiscountRate ?
                    //                <>
                    //                    <Col xl={6}><InputHoc name="discountRate" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.discountRate?.message} label="Discount Rate (Optional)" ref={register({ pattern: { value: /^0*(?:[1-9][0-9]?|100)$/, message: "Only accept whole number till 100" } })} /></Col>
                    //                    <Col xl={6}><InputHoc name="discountRateCapped" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.discountRateCapped?.message} label="Discount Capped (Optional)" ref={register({ pattern: { value: /^\d+(,\d{3})*(\.\d{1,2})?$/, message: "Only accept number" } })} /></Col>
                    //                </>
                    //                :
                    //                <Col xl={12}><InputHoc className="col-xl-12" name="discountAmount" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.discountAmount?.message} label="Discount Amount (Optional)" ref={register({ pattern: { value: /^\d+(,\d{3})*(\.\d{1,2})?$/, message: "Only accept number" } })} /></Col>
                    //        }
                    //    </Row>
                    //    <strong>Commission Type</strong>
                    //    <Row className="m-t-5">
                    //        <div className={classnames("col-xl-6 item-row", { 'active': selectedCommissionRate })} onClick={() => { setSelectedCommissionRate(true) }}>
                    //            <Card className="h-100">
                    //                <CardBody>
                    //                    <div className="chk-item d-flex justify-center m-auto"><span className={classnames("check-icon", { 'active': selectedCommissionRate })}><i className="fa fa-check-circle"></i></span></div>
                    //                    <strong className="m-auto">Rate Based</strong>
                    //                </CardBody>
                    //            </Card>
                    //        </div>
                    //        <div className={classnames("col-xl-6 item-row", { 'active': !selectedCommissionRate })} onClick={() => { setSelectedCommissionRate(false) }}>
                    //            <Card className="h-100">
                    //                <CardBody>
                    //                    <div className="chk-item d-flex justify-center m-auto"><span className={classnames("check-icon", { 'active': !selectedCommissionRate })}><i className="fa fa-check-circle"></i></span></div>
                    //                    <strong className="m-auto">Amount Based</strong>
                    //                </CardBody>
                    //            </Card>
                    //        </div>
                    //        {
                    //            selectedCommissionRate ?
                    //                <>
                    //                    <Col xl={6}><InputHoc name="commissionRate" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.commissionRate?.message} label="Commission Rate (Optional)" ref={register({ pattern: { value: /^0*(?:[1-9][0-9]?|100)$/, message: "Only accept whole number till 100" } })} /></Col>
                    //                    <Col xl={6}><InputHoc name="commissionRateCapped" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.commissionRateCapped?.message} label="Commission Capped (Optional)" ref={register({ pattern: { value: /^\d+(,\d{3})*(\.\d{1,2})?$/, message: "Only accept number" } })} /></Col>
                    //                </>
                    //                :
                    //                <Col xl={12}><InputHoc name="commissionAmount" inputType={InputTypes.NUMBER_INPUT} control={control} error={errors?.commissionAmount?.message} label="Commission Amount (Optional)" ref={register({ pattern: { value: /^\d+(,\d{3})*(\.\d{1,2})?$/, message: "Only accept number" } })} /></Col>
                    //        }
                    //    </Row>
                    //</>
                }
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-themed btn-min-width" onClick={() => handleSubmit(onSubmit)()} >{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
        {/* <Modal isOpen={modal} toggle={toggle} centered backdrop='static' className="modal-brand" id="ProductManagementModal">
            <ModalHeader>
                <button className="btn btn-close-modal" onClick={toggle}><i className="las la-times"></i></button>
                <div className="title">{product?.name ? product?.productTypeId === productType.income ? "Edit Income" : "Edit Expense" : product?.productTypeId === productType.income ? "Add Income" : "Add Expense"}</div>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef} autoComplete="off">

                <ModalFooter className="panel-foot panel-foot-buttons">
                    <button type="button" className="btn btn-themed" onClick={toggle}>Cancel</button>
                    <button type="submit" className="btn btn-themed">Save</button>
                </ModalFooter>
            </form>
        </Modal> */}
    </>)
}

export default ServiceFeeManagement;
