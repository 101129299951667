import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { cartState, orderInfoState, authCredentialState } from 'recoil/Atoms';
import { getCartItemPrices, formatNumber, constructStripeTransactionType, useQuery, stringIsNullOrEmpty, isObjectEmpty, getCountryFlagIconByJurisdictionName } from 'util/Utility';
import { useForm, FormProvider } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil';
import { Col, Card, CardBody, Label, UncontrolledTooltip, Modal, ModalBody, ModalFooter } from 'reactstrap';
import EmptyShoppingCart from 'assets/img/user/empty-cart-1.png';
import { BtnTypes, InputTypes, StripeStatus, DefaultCurrency, ApiKey, PaymentMethod, SweetAlert, LoadingStateText, DefaultCurrencyId } from "util/Constant";
import classnames from "classnames";
import ReactTable from "components/react-table/ReactTable";
import { Loading, Report, Block, Confirm, Notify } from "notiflix";
import { PaymentDao, AccountantDao } from "data";
import { useHistory, useLocation } from "react-router";
import ButtonRound from "components/buttons/ButtonRound";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from "react-i18next";
import BrandModal from "components/modals/BrandModal";


/// <summary>
/// Author: Lewis
/// Temporarily hide Add Ons Table Column for client comments @ 20210319
/// </summary>
const OrderSummary = React.forwardRef((props, ref) => {
    const { jumpToStep, showFormStep, setShowFormStep } = props;

    const { t } = useTranslation();

    const methods = useForm();
    const formRef = useRef();
    const _location = useLocation();
    const _history = useHistory();

    const { state } = useLocation();
    const [checkOutList, setCheckOutList] = useState([]);
    const [cartList, setCartList] = useState([]);
    const [checkAll, setCheckAll] = useState({ checked: false, id: -1 });
    const [totalCart, setTotalCart] = useState(0);
    const [cartRecoil, setCartRecoil] = useRecoilState(cartState);
    const [paymentMethod, setPaymentMethod] = useState();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
    const [orderInfoRecoil, setOrderInfoRecoil] = useRecoilState(orderInfoState);
    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();
    const stripe = useStripe();
    let query = useQuery(_location);

    const [selectedCart, setSelectedCart] = useState();
    const [detailModal, setDetailModal] = useState();

    const _MAX_MISC_PREVIEW = 2;
    const _DETAIL_MODAL = {
        PRICE: "1",
        ADD_ON: "2"
    };

    /// <summary>
    /// Author: Andrew
    /// 
    /// </summary>
    const toggleDetailModal = (type, model) => {
        setSelectedCart(model);
        setDetailModal(type);
    }

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const renderAddOnColumn = (model) => {
        let miscServices = model.company.jurisdiction.productMiscellaneouMatters;
        let addOnCartItems = model.cartItems.filter((c) => miscServices.some(misc => misc.id == c.productMatterId));
        let totalAddOn = addOnCartItems.reduce((a, b) => + a + b.product.price, 0);
        let addOnElem = totalAddOn > 0 ? `HK$ ${formatNumber(totalAddOn)} (${addOnCartItems.length} items)` : <span style={{ opacity: .7 }}>Select one of our add ons :</span>;

        return <div>
            <div>{addOnElem}</div>
            <div className="d-flex align-items-center">
                {
                    miscServices.slice(0, _MAX_MISC_PREVIEW).map(item => {
                        let isSelected = model.cartItems.find(x => x.productMatterId == item.id);
                        return <AddOnSelection isSelected={isSelected} cartId={model.id} addOn={item} onComplete={getCartList} />
                    })
                }
                {
                    miscServices.length > _MAX_MISC_PREVIEW &&
                    <span className="btn btn-link" onClick={() => { toggleDetailModal(_DETAIL_MODAL.ADD_ON, model) }}>More Add Ons</span>
                }
            </div>
        </div>
    }

    /// <summary>
    /// Author: Andrew
    /// </summary>   
    const sumOfCartItems = (cartItems) => {
        return cartItems.reduce((a, b) => + a + b.product.price, 0);
    }

    const _COLUMNS = useMemo(() => [
        {
            Header: (<input type="checkbox" checked={checkAll.checked} onChange={() => { updateCheckOutList(checkAll.id); }} />),
            id: 'chk-all',
            Cell: ({ row }) => {
                return <input type="checkbox" checked={checkOutList.includes(row.original.id) ? true : false} onChange={() => { updateCheckOutList(row.original.id); }}></input>
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'ACCOUNTANT',
            Cell: ({ row }) => (
                row.original.addToCartBy.name
            ),
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'JURISDICTION',
            Cell: ({ row }) => (
                <>
                    <div className="d-flex">
                        <div id={`${row.original.serviceRequest.company.jurisdiction.shortName}_${row.index}`}>
                            <img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(row.original.serviceRequest.company.jurisdiction.name)} />
                            <span>{row.original.serviceRequest.company.jurisdiction.name}</span>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={`${row.original.serviceRequest.company.jurisdiction.shortName}_${row.index}`}>{row.original.serviceRequest.company.jurisdiction.name}</UncontrolledTooltip>
                    </div>
                </>

            ),
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'MATTER',
            Cell: ({ row }) => {
                //let subtotal = 0;
                //row.original.cartItems.filter((c) => !row.original.serviceRequest.company.jurisdiction.productMiscellaneouMatters.some(misc => misc.id == c.productMatterId)).map(item => {
                //    subtotal += getCartItemPrices(item.product);
                //});

                //return (
                //    <div className="d-flex">
                //        HK$ { formatNumber(subtotal)}
                //    &nbsp; ({row.original.cartItems.filter((c) => !row.original.serviceRequest.company.jurisdiction.productMiscellaneouMatters.some(misc => misc.id == c.productMatterId)).length} items)
                //    </div>
                //)

                let subtotal = 0;
                row.original.cartItems.map(item => {
                    // temporary remove discount
                    //subTotal += getCartItemPrices(item.product);
                    subtotal += item.product.price;
                });

                return (
                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                        <div>{row.original.serviceRequest?.productMatter.name}</div>
                        <div style={{ flexBasis: "100%", height: "0" }}></div>
                        <small className="d-flex">
                            <div className="d-flex flex-column">
                                {defaultCurrencyCode} {formatNumber(subtotal)}
                                {
                                    currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                    <span className="quote-currency">&#8776; {currencyPreference?.code} {formatNumber(subtotal * currencyPreference?.exchangeRate?.rate)}</span>
                                }
                            </div>
                            &nbsp; ({row.original.cartItems.length} items)
                        </small>
                    </div>
                )
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => (
                <>
                    <div><span className="badge-lang-en">EN</span> {row.original.serviceRequest.company.englishName}</div>
                    {
                        row.original.serviceRequest.company.chineseName &&
                        <>
                            <div><span className="badge-lang-zh">CN</span>{row.original.serviceRequest.company.chineseName}</div>
                        </>
                    }

                </>
            ),
            disableFilters: true,
            disableSortBy: true,
            Footer: <strong className="text-primary">{t("TOTAL")}:</strong>
        },
        // {
        //     Header: 'Add Ons',
        //     Cell: ({ row }) => {
        //         return renderAddOnColumn(row.original)
        //     },
        //     disableFilters: true,
        //     disableSortBy: true,
        //     Footer: <div className="f-s-13" style={{ textAlign: "right" }}>Total:</div>
        // },
        {
            Header: 'PRICE',
            Cell: ({ row }) => {
                let subTotal = 0;
                row.original.cartItems.map(item => {
                    // temporary remove discount
                    //subTotal += getCartItemPrices(item.product);
                    subTotal += item.product.price;
                })
                return <div className="d-flex">
                    <div className="d-flex flex-column">
                        <span className="text-primary">{defaultCurrencyCode} {formatNumber(subTotal)}</span>
                        {
                            currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                            <span className="quote-currency">&#8776; {currencyPreference?.code} {formatNumber(subTotal * currencyPreference?.exchangeRate?.rate)}</span>
                        }
                    </div>
                    <div className="d-flex align-self-center">
                        <i onClick={() => { toggleDetailModal(_DETAIL_MODAL.PRICE, row.original) }} className="las la-question-circle f-s-19 ml-1"></i>
                    </div>
                </div>
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: <div className="d-flex flex-column">
                <strong className="text-primary">{defaultCurrencyCode} {formatNumber(totalCart)}</strong>
                {
                    currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                    <strong className="quote-currency">&#8776; {currencyPreference?.code} {formatNumber(totalCart * currencyPreference?.exchangeRate?.rate)}</strong>
                }
            </div>
        },
        {
            id: "ACTION",
            Cell: ({ row }) => (<>
                <ButtonRound type={BtnTypes.DELETE} small onClick={() => {
                    Confirm.Show(t("DELETE_CONFIRMATION"), `${t("DO_YOU_WISH_TO_DELETE")} <strong>${(row.original.serviceRequest.company.englishName)}</strong> ${t("FROM_CART")}?`,
                        t("YES"), t("NO"), function () { deleteCart(row.original.id); })
                }} />
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" },
            Footer: ""
        }
    ]);

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function deleteCart(id) {
        let loadingTimeout = setTimeout(() => {
            Loading.Circle('Deleting cart...');
        }, 250);

        let accountDao = new AccountantDao();
        await accountDao.deleteCart(id).then(responseJson => {

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Report.Success(
                    t(SweetAlert._OPERATION_SUCCESS),
                    t("ITEM_REMOVE_SUCCESSFULLY"),
                    t(SweetAlert._OK),
                    () => {
                        getCartList();
                        updateCheckOutList(id);
                    }
                );
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
            clearTimeout(loadingTimeout);
            Loading.Remove();
        })
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function handleCallback() {
        try {
            let loadingTimeout = setTimeout(() => {
                Loading.Circle('Processing Payment...');
            }, 250);

            let paymentIntentId = query.get('payment_intent');
            let clientSecret = query.get('payment_intent_client_secret');
            let redirectStatus = query.get('redirect_status');
            let paymentId = "";

            let params = {
                "IntentId": paymentIntentId,
                "ClientSecret": clientSecret,
                "Status": (redirectStatus == StripeStatus._FAIL) ? -1 : 1
            }

            var paymentDao = new PaymentDao();
            await paymentDao.handleAlipayCallback(params).then(responseJson => {
                clearTimeout(loadingTimeout);
                Loading.Remove();

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    paymentId = responseJson[ApiKey._API_DATA_KEY];
                }
                else {
                    Report.Warning(
                        responseJson[ApiKey._API_MESSAGE_KEY],
                        responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            })

            if (!stringIsNullOrEmpty(paymentId)) {
                _history.replace({
                    pathname: _location.pathname,
                    state: {
                        data: {
                            paymentId: paymentId,
                            showStep: true
                        }
                    }
                })
                jumpToStep(2);

            }
            else {
                _history.replace({
                    pathname: _location.pathname
                })
            }
        }
        catch (err) {
            Report.Failure(
                t(SweetAlert._ERROR_HAS_OCCUR),
                err,
                t(SweetAlert._OK),
            );
        }
    }

    /// <summary>
    /// Author: Wind
    /// Temporarily hide cheque & bank transfer payment method
    /// </summary>
    const getPaymentMethod = async () => {
        try {
            Block.Circle("#paymentCard");

            let paymentDao = new PaymentDao();
            await paymentDao.getPaymentMethod().then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var paymentMehodData = responseJson[ApiKey._API_DATA_KEY];
                    paymentMehodData = paymentMehodData.filter((el) => el.id !== 4);
                    paymentMehodData = paymentMehodData.filter((el) => el.id !== 5);
                    setPaymentMethod(paymentMehodData);
                }
                else {
                    Report.Warning(
                        responseJson[ApiKey._API_MESSAGE_KEY],
                        responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => { Block.Remove("#paymentCard") });
        } catch (error) {
            Report.Failure(
                t(SweetAlert._ERROR_HAS_OCCUR),
                error,
                t(SweetAlert._OK),
            );
        }

    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    const getCartList = async () => {
        Block.Circle('#OrderSummary', t(LoadingStateText._PLEASE_WAIT));

        let accountDao = new AccountantDao();
        await accountDao.getAccountantCart().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var data = responseJson[ApiKey._API_DATA_KEY];
                setCartList(data);

                if (selectedCart) {
                    setSelectedCart(data.find(d => d.id == selectedCart.id));
                }

                setCartRecoil(data);
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => {
            Block.Remove('#OrderSummary');
        })
    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    function updateCheckOutList(checkId) {
        if (checkId == checkAll.id) {
            if (checkAll.checked) {
                setCheckAll({ checked: false });
                setCheckOutList([]);
            }
            else {
                setCheckAll({ checked: true });
                setCheckOutList(cartList.map(a => a.id));
            }
        }
        else {
            if (checkOutList.includes(checkId)) {
                let filteredArray = checkOutList.filter(item => item !== checkId);
                setCheckOutList(filteredArray);

                if (checkAll.checked) {
                    setCheckAll({ checked: false });
                }
            }
            else {
                setCheckOutList([...checkOutList, checkId]);
            }
        }
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getCartList();
    }, []);

    /// <summary>
    /// Author: Robin
    /// </summary>
    useEffect(() => {
        if (cartList.length > 0) {
            getPaymentMethod();
        }
    }, [cartList]);

    useEffect(() => {
        if (state?.data?.paymentId) {
            jumpToStep(2);
        } else {
            let paymentIntentId = query.get('payment_intent');
            let clientSecret = query.get('payment_intent_client_secret');
            let redirectStatus = query.get('redirect_status');
            if (!stringIsNullOrEmpty(paymentIntentId) && !stringIsNullOrEmpty(clientSecret) && !stringIsNullOrEmpty(redirectStatus)) {
                handleCallback();
            }
            else {
                jumpToStep(0);
            }
        }
    }, [state]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (cartList.length !== cartRecoil.length) {
            getCartList();
        }
    }, [cartRecoil]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        let total = 0;
        if (checkOutList) {
            checkOutList.forEach(function (item, index) {
                let obj = cartList.find(o => o.id === item);
                if (obj) {
                    obj.cartItems.map(item => {
                        // temporary remove discount
                        //total += getCartItemPrices(item.product);
                        total += item.product.price;
                    })
                }
            });
        }
        setTotalCart(total);
    }, [checkOutList]);

    /// <summary>
    /// Author: YJ
    /// redirect to card payment after recoil state updated
    /// </summary>
    useEffect(() => {
        if ((orderInfoRecoil.paymentMethod == PaymentMethod._CREDITCARD && orderInfoRecoil.carts != null) ||
            (orderInfoRecoil.paymentMethod == PaymentMethod._WECHATPAY && !isObjectEmpty(orderInfoRecoil.details))) {
            jumpToStep(1);
        }
    }, [orderInfoRecoil]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function processAliPay(transactionType) {
        let clientSecret = null;

        let params = {
            "Currency": DefaultCurrency,
            "Type": transactionType,
            "Carts": checkOutList.map(cartId => {
                return {
                    id: cartId,
                };
            })
        };

        let paymentDao = new PaymentDao();
        await paymentDao.processPaymentWithAlipay(params).then(res => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                clientSecret = res[ApiKey._API_DATA_KEY];
            }
            else {
                throw res[ApiKey._API_MESSAGE_KEY];
            }
        }).catch(err => {
            throw err;
        });

        if (clientSecret == null) {
            throw "Client Secret Not Found";
        }

        /// start confirm payment
        await stripe.confirmAlipayPayment(clientSecret, {
            return_url: `${window.location.href}`,
        }).then((result) => {
            if (result.error) {
                throw result.error.message;
            }
        });
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function processWechatPay(transactionType) {
        let details = {};
        let params = {
            "Currency": DefaultCurrency,
            "Type": transactionType,
            "Carts": checkOutList.map(cartId => {
                return {
                    id: cartId,
                };
            })
        };

        let paymentDao = new PaymentDao();
        await paymentDao.processPaymentWithWechat(params).then(res => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                details = {
                    "qrCodeUrl": res[ApiKey._API_DATA_KEY]["qrCodeUrl"],
                    "clientSecret": res[ApiKey._API_DATA_KEY]["clientSecret"],
                    "sourceId": res[ApiKey._API_DATA_KEY]["sourceId"]
                };
            }
            else {
                throw res[ApiKey._API_MESSAGE_KEY];
            }
        }).catch(err => {
            throw err;
        });

        setOrderInfoRecoil({ details: details, paymentMethod: selectedPaymentMethod });
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async () => {

        let loadingTimeout = setTimeout(() => {
            Loading.Circle('Creating Order...');
        }, 500);

        try {
            let transactionType = constructStripeTransactionType(selectedPaymentMethod);

            if (!stripe) {
                throw "Not prepared";
            }
            else if (stringIsNullOrEmpty(transactionType)) {
                throw "Payment type not supported";
            }

            /// start handle for difference payment method.
            if (selectedPaymentMethod == PaymentMethod._ALIPAY) {
                await processAliPay(transactionType);
            }
            else if (selectedPaymentMethod == PaymentMethod._WECHATPAY) {
                await processWechatPay(transactionType);
            }
            else if (selectedPaymentMethod == PaymentMethod._CREDITCARD) {
                setOrderInfoRecoil({
                    carts: checkOutList.map(cartId => {
                        return {
                            id: cartId,
                        };
                    }), paymentMethod: selectedPaymentMethod
                });
            }
        }
        catch (err) {
            Report.Failure(
                'Error',
                err,
                'Okay',
            );
        }
        finally {
            clearTimeout(loadingTimeout);
            Loading.Remove();
        }
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useImperativeHandle(ref, () => ({
        submitOrderCart() {
            if (checkOutList.length == 0) {
                Notify.Warning(t("PLEASE_SELECT_AT_LEAST_ONE_ITEM_CHECKOUT"));
                return;
            }
            onSubmit();
        }
    }), [checkOutList.length, selectedPaymentMethod]);

    const renderModalTable = (selectedCart) => {
        let totalPrice = sumOfCartItems(selectedCart.cartItems);
        let sumOfCartItemsStr = formatNumber(totalPrice);
        let sumOfCartItemsQuoteStr;
        if (currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate) {
            sumOfCartItemsQuoteStr = formatNumber(totalPrice * currencyPreference?.exchangeRate?.rate);
        }
        return <>
            <table className="table table-compact">
                <tbody>
                    <tr>
                        <td><strong>{t("MATTERS")}</strong></td>
                        <td className="d-flex flex-column">
                            <div>
                                ({selectedCart.cartItems.length} {selectedCart.cartItems.length > 1 ? t('items') : t('item')})
                                &nbsp;&nbsp;<span className="text-theme-1">{defaultCurrencyCode} {sumOfCartItemsStr}</span>
                            </div>
                            {sumOfCartItemsQuoteStr &&
                                <span className="quote-currency">&#8776; {currencyPreference?.code} {sumOfCartItemsQuoteStr}</span>}
                        </td>
                    </tr>
                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                    {
                        selectedCart.cartItems.map((item) => {
                            return <tr>
                                <td>{item.product.name}</td>
                                <td className="d-flex flex-column"><span className="text-theme-1">{defaultCurrencyCode} {formatNumber(item.product.price)}</span>
                                    {item.product?.quoteCurrency?.id != DefaultCurrencyId && item.product?.quoteCurrency?.exchangeRate?.rate &&
                                        <span className="quote-currency">&#8776; {item.product?.quoteCurrency?.code} {formatNumber(item.product?.quoteCurrency?.quotePrice)}</span>}
                                </td>
                            </tr>
                        })
                    }
                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                    {/* <tr>
                    <td><strong>{t("SUMMARY")}</strong></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>{t("MATTER_SUBTOTAL")}</td>
                    <td className="d-flex flex-column"><span className="text-theme-1">{defaultCurrencyCode} {sumOfCartItemsStr}</span>
                        {sumOfCartItemsQuoteStr &&
                            <span className="quote-currency">&#8776; {currencyPreference?.code} {sumOfCartItemsQuoteStr}</span>}
                    </td>
                </tr>
                <tr><td>&nbsp;</td><td>&nbsp;</td></tr> */}
                </tbody>
                <tfoot style={{ borderTop: "1px solid lightgray" }}>
                    <tr>
                        <td><strong>{t("TOTAL")}</strong></td>
                        <td className="d-flex flex-column"><span className="text-theme-1">{defaultCurrencyCode} {sumOfCartItemsStr}</span>
                            {sumOfCartItemsQuoteStr &&
                                <span className="quote-currency">&#8776; {currencyPreference?.code} {sumOfCartItemsQuoteStr}</span>}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </>
    }

    return (
        <>
            <div id="OrderSummary" className="panel-body ">
                <FormProvider {...methods}>
                    {cartList.length === 0 ? (
                        <Col xs="12" className="text-center">
                            <img alt="" style={{ 'width': '25%', 'marginBottom': '40px' }} src={EmptyShoppingCart} />
                            <h3>{t("SHOPPING_CART_IS_EMPTY")}</h3>
                        </Col>
                    ) : (
                        <>
                            <div className="panel panel-brand-inner panel-cart-summary">
                                <div className="panel-header"><img alt="" className="header-icon" src={require('../../assets/img/ui/icon-shopping.svg')} />{t("CART_SUMMARY")}</div>
                                <div className="table-brand-wrapper table-cart-summary">
                                    <ReactTable tableClassName="table-td-valign-top" columns={_COLUMNS} data={cartList} />
                                </div>
                            </div>
                            <div className="panel panel-brand-inner panel-cart-summary">
                                <div className="panel-header"><img alt="" className="header-icon" src={require('../../assets/img/ui/icon-payment.svg')} />{t("SELECT_PAYMENT_METHOD")}</div>
                                <div className="panel-body">
                                    <div className="pmt-method-wrapper d-lg-flex d-xs-inline" id={"paymentCard"}>
                                        {paymentMethod && paymentMethod.map((pmt, index) => {
                                            return (
                                                <Card key={index} className={classnames("pmt-method-selection", { "active": selectedPaymentMethod == pmt.id })} onClick={() => { setSelectedPaymentMethod(pmt.id) }}>
                                                    <CardBody>
                                                        <i className="fa fa-check-circle check-icon"></i>
                                                        <img alt="" src={require(`../../assets/img/${pmt.paymentImagePath}.png`)} />
                                                        <strong>{pmt.name}</strong>
                                                    </CardBody>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </FormProvider>

                <Modal id="addOnModal" isOpen={detailModal == _DETAIL_MODAL.ADD_ON} backdrop={true} disableBackdrop={true} centered>
                    <ModalBody>
                        <div>
                            <h3>What are Add On Services?</h3>
                            <p>Get more out of your service with our add-ons!</p>
                        </div>
                        <div className="checkbox-block-list">
                            {/*
                            selectedCart?.serviceRequest.company.jurisdiction.productMiscellaneouMatters.map((item) => {
                                let isSelected = selectedCart.cartItems.find(x => x.productMatterId == item.id);
                                return <AddOnSelection isSelected={isSelected} cartId={selectedCart.id} addOn={item} onComplete={getCartList} />
                            })
                        */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-themed m-0" onClick={toggleDetailModal}>Done</button>
                    </ModalFooter>
                </Modal>

                <BrandModal
                    id="priceModal"
                    modalSize="md"
                    isOpen={detailModal == _DETAIL_MODAL.PRICE}
                    toggler={toggleDetailModal}
                    title={t("PRICE_DETAILS")}
                    customBody
                >
                    <ModalBody style={{ padding: "20px 40px 20px 40px" }}>
                        <div className="price-detail-modal-content-wrapper" style={{ backgroundColor: "white", borderRadius: "0.2rem", padding: 10 }}>
                            {
                                selectedCart &&
                                renderModalTable(selectedCart)
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-center">
                        <button type="button" className="btn btn-themed btn-min-width" onClick={toggleDetailModal}>Done</button>
                    </ModalFooter>
                </BrandModal>
            </div>
        </>
    )
});

/// <summary>
/// Author: Andrew
/// </summary>
const AddOnSelection = ({ isSelected, cartId, addOn, onComplete }) => {
    /// <summary>
    /// Author: Wind
    /// </summary>
    const updateCartItem = async (cartId, productId) => {
        let loadingTimeout = setTimeout(() => {
            Block.Circle('#cartList');
        }, 250);

        let accountDao = new AccountantDao();
        await accountDao.updateCartItem(cartId, productId).then(responseJson => {
            clearTimeout(loadingTimeout);
            Block.Remove('#cartList', 500);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                onComplete();
            }
            else {
                Report.Failure(
                    'Error',
                    'Failed to update add on service. Please try again.',
                    'Okay',
                );
            }
        })
    };

    return (
        <Label className={classnames('checkbox-selection', { 'checkbox-selection--selected': isSelected })}>
            <input type="checkbox"
                checked={isSelected ? true : false}
                onClick={() => { updateCartItem(cartId, addOn.id) }} />
            <strong className="mr-1">{addOn.name}</strong>
            <span>+HK$ {addOn.price}</span>
            <div className="checkbox-tick">
                <i className="las la-check" />
            </div>
        </Label>
    )
};

export default OrderSummary;