import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';
import { createMultiPartFormBody } from '../util/Utility';

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class DocumentDao extends DataAccessObject {

    async createDocument(postData) {
        return this.post(ApiUrl._API_CREATE_DOCUMENT, postData);
    }
    async createDocumentType(postData) {
        return this.post(ApiUrl._API_CREATE_DOCUMENT_TYPE, postData);
    }

    async removeDocumentById(id) {
        return this.post(ApiUrl._API_REMOVE_DOCUMENT.replace(":id", id));
    }

    async removeDocumentTypeById(id) {
        return this.post(ApiUrl._API_REMOVE_DOCUMENT_TYPE.replace(":id", id));
    }

    async createOrUpdateDocumentTemplate(postData) {
        let { templateAttachment } = postData;
        var formBody = createMultiPartFormBody(postData);
        if (templateAttachment) {
            formBody.append("templateAttachment", templateAttachment[0]);
        }
        return this.postForm(ApiUrl._API_CREATE_OR_UPDATE_DOCUMENT_TEMPLATE, formBody);
    }
}

export default DocumentDao;

