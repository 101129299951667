import React from 'react';
import classnames from 'classnames';

/// <summary>
/// Author: Chris
/// </summary>
export const DetermineIncorporationStatusIcon = (formSteps, stepIndex, stepper = false) => {
    let iconClasses = {
        'fa': true,
        'f-s-16': true,
        'mr-2': !stepper,
    };

    if (!formSteps[stepIndex].collapsed && !formSteps[stepIndex].status) {
        // iconClasses = { ...iconClasses, 'fa-spinner fa-spin text-gray': true };
        return <img className="step-incomplete" src={require("../../assets/img/ui/edit.svg")} />
    }
    else {
        iconClasses = { ...iconClasses, 'fa-check-circle text-green': formSteps[stepIndex].status, 'fa-times-circle text-red': !formSteps[stepIndex].status };
        return <i className={classnames(iconClasses)}></i>;
    }
};