import React, { useMemo, useState, useRef, useEffect } from "react";
import { Row, Col, ModalFooter, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { CompanyDao } from "data";
import { ApiKey, InputTypes, DateFormat, deleteConfirm, ApiUrl, BtnTypes, Icon, LoadingStateText, CompanyNameCheckStatus, SweetAlert, PolicyActionConstant, PolicyObjectConstant, TableId } from "../../util/Constant";
import { Confirm, Notify, Report, Block } from 'notiflix';
import ButtonRound from "components/buttons/ButtonRound";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { formatJurisdictionList, getCountryFlagIconByJurisdictionName, stringIsNullOrEmpty } from "util/Utility";
import InputHoc from "components/form/InputHoc";
import ReactResizeDetector, { useResizeDetector } from "react-resize-detector";
import { useTranslation } from "react-i18next";
import BrandModal from "components/modals/BrandModal";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import useSWR from "swr";
import { Can } from "config/user-access";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "recoil/Atoms";

///<summary>
///Author: CJ(Jiann)
///</summary>
const ProductionNameCheck = () => {
    const { t } = useTranslation();

    const _filterFormRef = useRef();
    const _tableRef = useRef();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [isFilterOpen, setFilterOpen] = useState(false);
    const { height, ref: wrapperRef } = useResizeDetector();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);


    ///<summary>
    ///Author: Lewis
    ///</summary>
    const _FORM_FIELDS = useMemo(() => [
        {
            rowOptions: { xl: 3 },
            columns: [
                { prefix: { icon: Icon._PROFILE }, label: `${t('SEARCH_ACCOUNTANT')}`, placeholder: `${t('SEARCH_ACCOUNTANT')}`, name: 'accountantName', smallnote: `${t("NAME")}, ${t("NUMBER")}, ${t("FIRM")}, ${t("ETC")}`, input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                {
                    prefix: { icon: Icon._SEARCH_JURISDICTION },
                    label: `${t('SEARCH_JURISDICTION')}`, placeholder: `${t('SEARCH_JURISDICTION')}`, name: 'jurisdictionId', input: InputTypes.SELECT, options: [
                        //{ label: "All", value: "" },
                        ...jurisdictionOptions,
                    ], columnOptions: { xl: 3, md: 6 }
                },
            ]
        }
    ], [jurisdictionOptions]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
        _tableRef.current.reFetch();
    };

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight
        }
    }, [wrapperRef?.current, isFilterOpen]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container border-radius-none">
                <Row className="filter-panel-body p-t-15">
                    <Col xl={10}>
                        <FormBuilder fields={_FORM_FIELDS} formRef={_filterFormRef} onSubmit={filterOnSubmit}
                            reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                    </Col>
                    <Col xl={2} className="btn-list d-flex">
                        <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                    </Col>
                </Row>
            </div>
            <div className="flex-grow-1" ref={wrapperRef}>
                <NameCheckList filterFormfield={filtersForm} ref={_tableRef} tableHeight={fixedContentHeight} />
            </div>
        </div>
    );
};

/// <summary>
/// Author: Lewis
/// </summary>
const NameCheckList = React.forwardRef((props, ref) => {
    let { filterFormfield, tableHeight } = props;
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [selectedNameCheckId, setSelectedNameCheckId] = useState();
    const [refresh, setRefresh] = useState(false);
    const [followUpModal, setFollowUpModal] = useState(false);
    const [nameCheckId, setNameCheckId] = useState();
    const userId = useRecoilValue(userIdSelector);

    let companyDao = new CompanyDao();

    const _COLUMN = useMemo(() => [
        {
            Header: "ACCOUNTANT",
            accessor: "requestor.name",
        },
        {
            Header: "JURISDICTION",
            accessor: "jurisdiction.name",
            Cell: ({ row }) => {
                return <div className="d-flex">
                    <div><img style={{ width: 24, height: 16, marginRight: 10 }} src={getCountryFlagIconByJurisdictionName(row.original.jurisdiction.name)} /></div>
                    <span>{row.original.jurisdiction.name}</span>
                </div>
            },
            isRequiredColumn: true
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="m-b-5 nowrap text-overflow-ellipsis">
                            <span className="badge badge-purple">EN</span>
                            <span style={{ paddingLeft: '3%' }}>{row.original.englishName}</span>
                        </div>
                        {
                            !stringIsNullOrEmpty(row.original?.chineseName) && <>
                                <div className="nowrap">
                                    <span className="badge badge-light-green">CN</span>
                                    <span style={{ paddingLeft: '3%' }}>{row.original.chineseName}</span>
                                </div>
                            </>
                        }
                    </div>
                )
            },
            style: { width: 200 },
            isRequiredColumn: true
        },
        {
            Header: "REQUEST_DATE",
            accessor: "requestDate",
            Cell: ({ row }) => {
                let parsedDate = "";

                if (row.original?.requestDate) {
                    parsedDate = moment(row.original.requestDate).format(DateFormat._DATE_ONLY);

                    return parsedDate != 'Invalid date' ? parsedDate : row.original.requestDate;
                }

                return "N/A";
            },
        },
        {
            Header: "LATEST_FOLLOW_UP_DATE",
            accessor: "latestFollowUp.followUpDateTime",
            Cell: ({ row }) => (
                row.original?.latestFollowUp ? moment(row.original.latestFollowUp.followUpDateTime).format(DateFormat._DATE_ONLY) : "N/A"
            ),
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => (<>
                <ButtonRound className="mr-1" type={BtnTypes.FA_VIEW} medium onClick={() => { setSelectedNameCheckId(row.original.id); setShowModal(true); }} />
                {
                    row.original.status === 0 &&
                    <ButtonRound type={BtnTypes.CALENDER} className="btn-themed" medium title={t('NEW_FOLLOW_UP')} onClick={() => { setFollowUpModal(!followUpModal); setNameCheckId(row.original.id); }} />
                }
            </>),
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: "AVAILABILITY",
            Cell: ({ row }) => (
                <div class="switcher">
                    {/* when pending, means still able to update namecheck avaibility */}
                    {
                        row.original.status === CompanyNameCheckStatus._PENDING &&
                        <>
                            <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_namecheck} passThrough>
                                {
                                    allowed => allowed ?
                                        <>
                                            <input type="checkbox" id={"chk" + row.original.id} checked={row.original.isAvailable}
                                                onChange={() => {
                                                    if (row.original.isAvailable) {
                                                        setAvailability(row.original.id, "Set Unavailable", false)
                                                    }
                                                    else {
                                                        setAvailability(row.original.id, "Set Available", true)
                                                    }
                                                }
                                                } />
                                            <label for={"chk" + row.original.id}></label>
                                        </>
                                        :
                                        <> - </>
                                }
                            </Can>
                        </>
                    }
                    {
                        (row.original.status === CompanyNameCheckStatus._APPROVED || row.original.status === CompanyNameCheckStatus._REJECTED) &&
                        <>
                            {
                                row.original.isAvailable ? <i className="fas fa-check fa-lg mt-2" style={{ color: 'green' }}></i> : <i class="fas fa-times fa-lg mt-2" style={{ color: 'red' }}></i>
                            }
                        </>
                    }
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            isRequiredColumn: true
        },
        {
            id: "confirm",
            Header: "CONFIRM",
            Cell: ({ row }) => (
                row.original.status !== CompanyNameCheckStatus._PENDING
                    ?
                    <div> - </div>
                    :
                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_namecheck} passThrough>
                        {
                            allowed => allowed ?
                                <button type="button" className="btn btn-themed btn-cfm" onClick={() =>
                                    confirmStatusCompanyNameCheck(row.original.id, row.original.isAvailable)
                                }>{t("CONFIRM")}</button>
                                :
                                <> - </>
                        }
                    </Can>
            ),
            disableSortBy: true,
            disableFilters: true,
            isRequiredColumn: true
        }
    ], []);

    const setAvailability = (nameCheckId, remark, availability) => {

        var date = moment().format(DateFormat._DATE_FORMAT);
        Confirm.Show(
            t('notiflix:CONFIRM_CHANGES'),
            t('CURRENT_DATE') + ": " + date.toString() + '<br>' + t("WILL_BE_SAVE_IN_HISTORY"),
            t("notiflix:CONFIRM"),
            t("notiflix:CANCEL"),
            async () => {

                if (availability) {

                    await companyDao.setApproveAvailability(nameCheckId).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            updateFollowUpDateAndRemark(nameCheckId, date, remark);
                            setRefresh(prevState => !prevState);
                            ref.current.reFetch();
                        }
                        else {
                            Report.Warning(
                                response[ApiKey._API_MESSAGE_KEY],
                                response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t('notiflix:REQUEST_FAILED'),
                                t(SweetAlert._OK),
                            );
                        }
                    });

                } else {

                    await companyDao.setRejectAvailability(nameCheckId).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            updateFollowUpDateAndRemark(nameCheckId, date, remark);
                            setRefresh(prevState => !prevState);
                            ref.current.reFetch();
                        }
                        else {
                            Report.Warning(
                                response[ApiKey._API_MESSAGE_KEY],
                                response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                                t(SweetAlert._OK),
                            );
                        }
                    });

                }
            }
        )
    }

    const confirmStatusCompanyNameCheck = (nameCheckId, isNamecheckAvailable) => {

        var date = moment().format(DateFormat._DATE_FORMAT);
        Confirm.Show(
            t('notiflix:CONFIRM_COMPANY_AVAILABLE'),
            t('CURRENT_DATE') + ": " + date.toString() + '<br>' + t('WILL_BE_SAVE_IN_HISTORY'),
            t('notiflix:CONFIRM'),
            t('notiflix:CANCEL'),
            async () => {
                Block.Circle(".notiflix-confirm-content", t(LoadingStateText._PLEASE_WAIT));

                if (isNamecheckAvailable) {

                    await companyDao.confirmApproveCompanyNameCheck(nameCheckId).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {

                            updateFollowUpDateAndRemark(nameCheckId, date, "Company Name Check Approved.");
                            setRefresh(prevState => !prevState);
                            ref.current.reFetch();

                            Report.Success(
                                t("notiflix:NAME_CHECK_APPROVAL"),
                                t("notiflix:NAME_CHECK_HAS_BEEN_APPROVED"),
                                t(SweetAlert._OK),
                            )

                        }
                        else {

                            Report.Warning(
                                response[ApiKey._API_MESSAGE_KEY],
                                response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                                t(SweetAlert._OK),
                            );

                        }

                    }).finally(() => Block.Remove(".notiflix-confirm-content"));
                }
                else {
                    await companyDao.confirmRejectCompanyNameCheck(nameCheckId).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            updateFollowUpDateAndRemark(nameCheckId, date, "Company Name Check Rejected.");
                            setRefresh(prevState => !prevState);
                            ref.current.reFetch();

                            Report.Success(
                                t("notiflix:NAME_CHECK_REFUSAL"),
                                t("notiflix:NAME_CHECK_HAS_BEEN_REJECTED"),
                                t(SweetAlert._OK),
                            )

                        }
                        else {

                            Report.Warning(
                                response[ApiKey._API_MESSAGE_KEY],
                                response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                                t(SweetAlert._OK),
                            );

                        }

                    }).finally(() => Block.Remove(".notiflix-confirm-content"));
                }
            }
        )
    }


    const updateFollowUpDateAndRemark = async (nameCheckId, currentDate, remark) => {

        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        var date = moment(currentDate).format(DateFormat._DATE_FORMAT);

        await companyDao.updateFollowUpDateAndRemark(nameCheckId, date, remark).then((response) => {

            if (response[ApiKey._API_SUCCESS_KEY]) {

                Notify.Success(t("notiflix:NAME_CHECK_AVAILBILITY_CHANGED"));
                setFollowUpModal(false);
                setRefresh(prevState => !prevState);

            }
            else {
                Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove(".modal-content"));
    }

    return (
        <>
            <ReactTable
                ref={ref}
                columns={_COLUMN}
                url={ApiUrl._API_GET_NAMECHECK_LIST}
                enableGlobalFilter
                filterFormfield={filterFormfield}
                tableMinHeight={tableHeight}
                tableMinWidth={1000}
                tableColumnPreference={[userId, TableId._COMPANY_NAME_CHECK]}
            />
            <ProductionNameCheckRemark
                showModal={showModal}
                setShowModal={setShowModal}
                selectedNameCheckId={selectedNameCheckId}
                refreshTable={refresh}
                setRefreshTable={setRefresh} />
            <ProductionNameCheckFollowUp followUpModal={followUpModal}
                setFollowUpModal={setFollowUpModal}
                updateRemark={updateFollowUpDateAndRemark}
                nameCheckId={nameCheckId} />
        </>
    )
});

const ProductionNameCheckRemark = (props) => {

    let { showModal, setShowModal, selectedNameCheckId, refreshTable, setRefreshTable } = props;
    const [nameCheckRemarkList, setNameCheckRemarkList] = useState([]);
    const { t } = useTranslation();
    let companyDao = new CompanyDao();

    useEffect(() => {
        if (selectedNameCheckId) {
            getNameCheckRemarkList(selectedNameCheckId);
        }
    }, [selectedNameCheckId, refreshTable]);

    const toggleModal = () => {
        setShowModal(false);
    }

    const getNameCheckRemarkList = async (nameCheckId) => {

        await companyDao.getNameCheckRemarksList(nameCheckId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setNameCheckRemarkList(data);
            }
        })
    }

    const _COLUMN = useMemo(() => [
        {
            Header: "USER",
            accessor: "followUpBy.name",
            Cell: ({ row }) => (
                <div>{row.original.followUpBy?.name ? row.original.followUpBy.name : "N/A"}</div>

            ),
        },
        {
            Header: "FOLLOW_UP_DATE",
            accessor: "followUpDateTime",
            Cell: ({ row }) => (
                <div className="d-inline-flex">
                    {row.original.followUpDateTime ? moment(row.original.followUpDateTime).format(DateFormat._DATE_ONLY) : "N/A"}
                    <p className="text-secondary-lighter">&nbsp;&nbsp;{row.original.followUpDateTime ? moment(row.original.followUpDateTime).format(DateFormat._DISPLAY_TIME_ONLY) : ""}</p>
                </div>

            ),
        },
        {
            Header: "REMARK",
            accessor: "remark",
            Cell: ({ row }) => (
                <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>{row.original.remark ? row.original.remark : "N/A"}</div>
            ),
        }
    ], [])

    return (
        <BrandModal
            isOpen={showModal}
            toggler={() => setShowModal(!showModal)}
            title={t("FOLLOW_UP_LIST")}
            customBody
        >
            <ModalBody className="p-0">
                {
                    nameCheckRemarkList.filter(f => f.id == selectedNameCheckId).map((list) => {
                        return (
                            <ReactTable
                                columns={_COLUMN}
                                data={list.listOfFollowUp}
                                enableGlobalFilter
                                footerClassname="footer-white"
                            />
                        );
                    })
                }
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => { setShowModal(!showModal) }}>{t("CLOSE")}</button>
            </ModalFooter>
        </BrandModal>
    )
};

const ProductionNameCheckFollowUp = (props) => {
    let { followUpModal, setFollowUpModal, updateRemark, nameCheckId, ...rest } = props;
    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const _ref = useRef();

    const addNewFollowUp = async (data) => {
        let currentDate = moment().format(DateFormat._DATE_FORMAT);
        updateRemark(nameCheckId, currentDate, data.remarks);
    }
    return (
        <BrandModal
            isOpen={followUpModal}
            toggler={() => { setFollowUpModal(!followUpModal) }}
            title={`${t("CONFIRM_FOLLOW_UP")}?`}
            customBody
        >
            <ModalBody className="p-0">
                <form onSubmit={handleSubmit(addNewFollowUp)} ref={_ref} style={{ padding: "0px 40px" }}>
                    <h4>{t("FILL_IN_YOUR_REMARKS_BELOW")}</h4>
                    <InputHoc name="remarks" inputType={InputTypes.TEXTAREA} ref={register} required minRows={5} maxLength={450} />
                    <p>{t("CURRENT_DATE")}: {moment().format(DateFormat._DATE_FORMAT)} {t("WILL_BE_SAVE_IN_HISTORY")}</p>
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={() => { setFollowUpModal(!followUpModal) }}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(addNewFollowUp)()}>{t("YES")}</button>
            </ModalFooter>
        </BrandModal>
    );
}

export default ProductionNameCheck;