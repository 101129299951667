import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useResizeDetector } from 'react-resize-detector';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { RoleDao } from 'data';
import { isEmpty } from 'lodash';
import Notiflix, { Block } from 'notiflix';
import { PageSettings } from 'config/page-settings';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import BrandModal from 'components/modals/BrandModal';
import ButtonRound from 'components/buttons/ButtonRound';
// import ReactTable from "components/react-table/ReactTable";
import { ReactTablev2 as ReactTable } from "components/react-table";
import { InputTypes, BtnTypes, ApiKey, LoadingStateText, SweetAlert, ApiUrl } from 'util/Constant';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import RolePolicyObjectAssignActionsModal from './RolePolicyObjectAssignActionsModal'
import { Can } from 'config/user-access'
import { PolicyObjectConstant, PolicyActionConstant } from 'util/Constant'

//<summary>
//Author: Robin
//</summary>
export const RoleAssignPolicyModal = props => {

    const { modal, setModal, role, setRole } = props;

    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const { isXlDevices } = useContext(PageSettings);
    const _filterFormRef = useRef();
    const { ref: wrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [refreshTable, setRefreshTable] = useState(false);
    const _tableRef = useRef();

    const { data: actionsJson } = useSWR([ApiUrl._API_GET_ACTIONS, ApiKey._API_GET]);
    const actionsOption = useMemo(() => {
        return actionsJson?.[ApiKey._API_SUCCESS_KEY] ?
            actionsJson[ApiKey._API_DATA_KEY] : [];
    }, [actionsJson]);

    // Modal
    const [roleName, setRoleName] = useState("");
    const [roleObject, setRoleObject] = useState("");
    const [roleActions, setRoleActions] = useState([]);
    const [policyObjectAssignActionsModal, setPolicyObjectAssignActionsModal] = useState(false);


    const _SEARCH_FORMS = useMemo(() => {

        const _FORM_FIELDS = [
            {
                rowOptions: { xl: 6 },
                columns: [
                    { prefix: '', label: t('systemConfiguration:SEARCH_MODULE'), name: 'object', placeholder: t("systemConfiguration:SEARCH_MODULE"), input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                ]
            }
        ];

        return _FORM_FIELDS;

    }, []);

    const _ROLE_COLUMN = useMemo(() => [
        {
            id: "role",
            Header: "systemConfiguration:ROLE",
            accessor: "role",
            Cell: ({ row }) => {
                if (row.original.role) {
                    return (row.original.role);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "obj",
            Header: "systemConfiguration:MODULE",
            accessor: "object",
            Cell: ({ row }) => {
                if (row.original.object) {
                    return (row.original.object);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "acts",
            Header: "systemConfiguration:PERMISSION",
            accessor: "actions",
            Cell: ({ row }) => {
                if (row.original.actions && row.original.actions.length) {
                    var joinedActions = row.original.actions.join(', ');
                    return (joinedActions);
                } else {
                    if (role?.casbinName === "role_superuser") {
                        return <> * </>
                    } else {
                        return <> - </>
                    }
                }
            }
        },
        {
            id: "Action",
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="m-b-5 d-flex">
                        <Can do={PolicyActionConstant.grant} this={PolicyObjectConstant.role}>
                            {
                                allowed => (allowed && role?.casbinName !== "role_superuser") &&
                                    <ButtonRound className="mr-2" type={BtnTypes.EDIT} small onClick={() => {
                                        setRoleName(row.original.role);
                                        setRoleObject(row.original.object);
                                        setRoleActions(row.original.actions);
                                        setPolicyObjectAssignActionsModal(true);
                                    }} />
                            }

                        </Can>
                    </div>
                </>);
            },
            disableSortBy: true,
        }
    ], [role]);

    const toggle = () => {
        resetFiltersFormValue();
        setModal(!modal);
        setRole({});
    }

    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    useEffect(() => {
    }, [role]);

    return (<>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={t("systemConfiguration:ROLE_POLICIES")}
            customBody
        >
            <ModalBody className="p-0" id="role">
                <div className="d-flex flex-column h-100">
                    <div className="filter-panel-container border-radius-none" >
                        <Row className="filter-panel-body p-t-15">
                            <Col xl={8}>
                                <FormBuilder
                                    fields={_SEARCH_FORMS}
                                    formRef={_filterFormRef}
                                    onSubmit={filterOnSubmit}
                                    reset={resetFilters}
                                    resetTriggerReset={setResetFilters}
                                    resetValues={{}}
                                />
                            </Col>
                            <Col xl={4} className="btn-list d-flex justify-content-end">
                                <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                                <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                            </Col>
                        </Row>
                    </div>
                    <div className="flex-grow-1" ref={wrapperRef}>
                        {/* <div style={{ height: fixedContentHeight }}> */}
                        {
                            !isEmpty(role) &&
                            <ReactTable
                                ref={_tableRef}
                                tableMinHeight={fixedContentHeight}
                                enableSearch
                                columns={_ROLE_COLUMN}
                                url={ApiUrl._API_GET_ROLE_POLICIES_LIST.replace(':roleId', role.id)}
                                refreshTable={refreshTable}
                                filterFormfield={filtersForm}
                                setPagination
                                isInheritHeight
                                initialPageSize={5}
                            />
                        }
                        {/* </div> */}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("DONE")}</button>
            </ModalFooter>
        </BrandModal>
        <RolePolicyObjectAssignActionsModal
            modal={policyObjectAssignActionsModal}
            setModal={setPolicyObjectAssignActionsModal}
            role={role}
            roleName={roleName}
            roleObject={roleObject}
            roleActions={roleActions}
            actionsOption={actionsOption}
            setRefreshTable={() => _tableRef.current.reFetch()}
        />
    </>);
};

export default RoleAssignPolicyModal;