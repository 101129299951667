import React, { useState, useEffect, useMemo } from 'react';
import { ApiKey, RegionOfBusiness, BtnTypes, InputTypes, IncorpComponentKeys, KycFormKeys } from 'util/Constant';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import _ from 'lodash/object';
import { IncorporationInfo, kycQuestionState } from 'recoil/Incorporation';
import KycSourceOfFunds from './KycSourceOfFunds';
import KycNatureOfBusiness from './KycNatureOfBusiness';
import KycRegionOfBusiness from './KycRegionOfBusiness';

import 'awesome-steps/dist/style.css';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

/// <summary>
/// Author: Chris
/// </summary>
const KycComponent = ({ formSteps, setFormSteps: setOuterFormSteps, index, toggleStepComplete }) => {
    const { t } = useTranslation();
    const incorporationInfo = useRecoilValue(IncorporationInfo);
    const [kycQuestion, setKycQuestion] = useRecoilState(kycQuestionState);
    const resetKycQuestion = useResetRecoilState(kycQuestionState);

    useEffect(() => {

        if (
            !isEmpty(kycQuestion?.natureOfBusiness) &&
            (
                !isEmpty(kycQuestion?.regionOfBusiness) &&
                (kycQuestion?.regionOfBusiness?.region?.length > 0 || kycQuestion?.regionOfBusiness?.regionOthers)
            ) &&
            !isEmpty(kycQuestion?.sourceOfFunds)
        ) {
            toggleStepComplete(IncorpComponentKeys._KYC, true);
        }
        else {
            toggleStepComplete(IncorpComponentKeys._KYC, false);
        }

    }, [kycQuestion]);

    useEffect(() => {
        if (incorporationInfo?.kycQuestion) {
            setKycQuestion(incorporationInfo.kycQuestion);
            return
        }
    }, [index, incorporationInfo?.kycQuestion]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    // when component unmount, clean recoil state
    useEffect(() => {
        return resetKycQuestion;
    }, []);

    return <div className="section-incorp">
        <div className="title">{t("KYC_QUESTIONS")}</div>
        <div className="panel panel-brand mb-0" style={{ overflow: 'hidden' }}>
            <table className="table table-kyc">
                <tbody>
                    <KycSourceOfFunds />
                    <KycNatureOfBusiness />
                    <KycRegionOfBusiness />
                </tbody>
            </table>
        </div>
    </div>
}

export default KycComponent;