import React, { useState, useContext } from 'react';
import { Route, withRouter, Link, Redirect, useLocation, Switch, useHistory, BrowserRouter as Router } from 'react-router-dom';
import { _ROUTES, _PUBLIC_ROUTES } from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useEffect } from 'react';
import { ApiKey, ApiUrl, RoleType, SidebarType, WebUrl } from 'util/Constant.js';
import { Report } from 'notiflix';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AccountantDao from '../../data/AccountantDao.js';
import classnames from "classnames";
import useSWR from 'swr';
import {
	isLoggedInState,
	authCredentialState,
	documentTypeState,
	companyProcessStates,
	languagesState,
	currenciesState
} from 'recoil/Atoms.js';
import { isEmpty } from 'lodash';
import Error404View from 'components/error-graphic/Error404NotFound';
import { useTranslation } from 'react-i18next';


/// <summary>
/// Author: Chris
/// </summary>
const compareRoutes = (routePath, path) => {
	const splitRoutePath = routePath.split('/').slice(1);
	const splitPath = path.split('/').slice(1);

	splitRoutePath.map((item, index) => {
		if (item.charAt(0) === ':') {
			splitRoutePath.splice(index, 1);

			if (splitPath[index] !== undefined) {
				splitPath.splice(index, 1);
			}
		}
	});

	return splitRoutePath.join('/') === splitPath.join('/');
}

const setTitle = (path, routeArray) => {
	var pageTitle = 'YICOM';

	for (var i = 0; i < routeArray.length; i++) {
		if (compareRoutes(routeArray[i].path, path)) {
			pageTitle = `${routeArray[i].title} | ` + pageTitle;
		}
	}

	document.title = pageTitle;
}

/// <summary>
/// Author: Lewis
/// </summary>
const PublicRoute = ({ component: Component, authRequired, ...rest }) => {
	const [isLoggedIn, setLoggedIn] = useRecoilState(isLoggedInState);
	return (
		// authRequired = false meaning public route
		// authRequired = true meaning restricted route
		<Route {...rest} render={props => (
			(isLoggedIn && authRequired) ?
				<Redirect to={WebUrl._GET_STARTED} /> : <Component {...props} />
		)} />
	);
};

/// <summary>
/// Author: Lewis
/// </summary>
const PrivateRoute = (props) => {
	let { component: Component, authRequired, ...rest } = props;

	const _history = useHistory();
	const _context = useContext(PageSettings);
	const [isLoggedIn, setLoggedIn] = useRecoilState(isLoggedInState);
	const [authCredential, setAuthCredentialState] = useRecoilState(authCredentialState);
	const setDocumentTypes = useSetRecoilState(documentTypeState);
	const setCompanyProcessStates = useSetRecoilState(companyProcessStates);
	const setCurrenciesState = useSetRecoilState(currenciesState);
	const [languageOptions, setLanguageOptions] = useRecoilState(languagesState);
	const { t } = useTranslation();
	/// <summary>
	/// Author: Lewis
	/// </summary>
	const { data: compProcessStateJson } = useSWR([
		!isEmpty(authCredential) ? ApiUrl._API_GET_COMPANY_PROCESS_STATE_LIST : null, ApiKey._API_GET
	]);

	/// <summary>
	/// Author: Lewis
	/// </summary>
	const { data: currencyJson } = useSWR([
		!isEmpty(authCredential) ? ApiUrl._API_GET_CURRENCY_LIST : null, ApiKey._API_GET
	]);

	/// <summary>
	/// Author: Lewis
	/// </summary>
	const { data: documentTypeJson } = useSWR([
		!isEmpty(authCredential) ? ApiUrl._API_GET_DOCUMENT_TYPE_LIST : null, ApiKey._API_GET
	]);

	/// <summary>
	/// Author: Lewis
	/// </summary>
	const { data: languagesJson } = useSWR([
		!isEmpty(authCredential) ? ApiUrl._API_GET_LANGUAGE_LIST : null, ApiKey._API_GET
	]);

	/// <summary>
	/// Author: Lewis
	/// </summary>
	useEffect(() => {

		if (documentTypeJson?.[ApiKey._API_SUCCESS_KEY]) {
			setDocumentTypes(documentTypeJson[ApiKey._API_DATA_KEY].map((item) =>
				({ value: item.id, label: item.name, id: item.id })));
		}

		if (compProcessStateJson?.[ApiKey._API_SUCCESS_KEY]) {
			setCompanyProcessStates(compProcessStateJson[ApiKey._API_DATA_KEY].map((item) => 
				({...item, name: t(`${item.name}`)})));
		}

		if (languagesJson?.[ApiKey._API_SUCCESS_KEY]) {
			setLanguageOptions(languagesJson[ApiKey._API_DATA_KEY].map(({ id, name, code }) =>
				({ id, name, code, label: name, value: id })));
		}

		if (currencyJson?.[ApiKey._API_SUCCESS_KEY]) {
			setCurrenciesState(currencyJson[ApiKey._API_DATA_KEY].map(({ id, name }) =>
				({ label: name, value: id })));
		}
	}, [
		compProcessStateJson,
		currencyJson,
		documentTypeJson,
		languagesJson,
	])

	/// <summary>
	/// Author: Lewis
	/// </summary>
	const checkIsAuthorized = async () => {

		let accountDao = new AccountantDao();

		await accountDao.checkUserAuthorization().then(response => {

			if (response?.[ApiKey._API_DATA_KEY]) {
				let data = response[ApiKey._API_DATA_KEY];
				setAuthCredentialState(response[ApiKey._API_DATA_KEY]);
				setLoggedIn(true);
				if (data.role.id == RoleType._SUPERUSER || data.role.id == RoleType._ADMIN) {
					_context.setOptions('sideBarButton', true);
					_context.setOptions('activeSidebar', localStorage.getItem("activeSidebar") ? localStorage.getItem("activeSidebar") : SidebarType._ACCOUTANT);
				}
				else {
					_context.setOptions('sideBarButton', false);
					_context.setOptions('activeSidebar', SidebarType._ACCOUTANT);
				}
			}
			else if (response.status == 401) {
				localStorage.clear();
				setLoggedIn(false);
				_history.replace(WebUrl._LOGIN);
			}
			
		});
	}

	/// <summary>
	/// Author: Lewis
	/// </summary>
	useEffect(() => {
		checkIsAuthorized();
	}, []);
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to login page	
	return (<>
		<Route {...rest} render={renderProps => (
			isLoggedIn ? <Component {...renderProps} /> : <Redirect to={WebUrl._LOGIN} />
		)} />
	</>);
};

const Content = ({ history }) => {
	const [isLoggedIn, setLoggedIn] = useRecoilState(isLoggedInState);

	/// <summary>
	/// Author: Chris
	/// Lewis: Added private & public routes
	/// </summary>
	useEffect(() => {
		var routes = _ROUTES;
		if (_PUBLIC_ROUTES.map(item => item.path).includes(history.location.pathname)) {
			routes = _PUBLIC_ROUTES;
		}

		setTitle(history.location.pathname, routes);

		return history.listen(() => {
			setTitle(history.location.pathname, routes);
		});
	}, [history.location.pathname, isLoggedIn]);

	return (
		<PageSettings.Consumer>
			{({ pageContentFullWidth, pageContentClass, pageContentInverseMode, pageFooter, isXsDevices, isSmDevices, isMdDevices, isLgDevices, isXlDevices }) => (
				<div className={classnames(
					"content",
					{
						[pageContentClass]: pageContentClass,
						'content-full-width': pageContentFullWidth,
						'content-inverse-mode': pageContentInverseMode,
						'has-footer': pageFooter,
						"-xs-resolution": isXsDevices,
						"-xl-resolution": isXlDevices,
						"-md-resolution": isMdDevices,
						"-lg-resolution": isLgDevices,
						"-sm-resolution": isSmDevices,
					}
				)}>
					<Switch>
						{_PUBLIC_ROUTES.map((route, index) => (
							<PublicRoute {...route} />
						))}
						{_ROUTES.map((route, index) => (
							<PrivateRoute {...route} />
						))}
						<Route path="" render={(props) => <Error404View {...props} urlPath={history.location.pathname}/>}/>
					</Switch>
				</div>
			)}
		</PageSettings.Consumer>
	)
}

export default withRouter(Content);
