import AuthenticationDao from 'data/AuthenticationDao';
import { atom, selector } from 'recoil';
import { ConfigEnum } from 'util/Config';
import { ApiKey, PaymentMethod, RoleType } from '../util/Constant';
import { CompanyDao } from 'data';

/// <summary>
/// Author: Chris
/// </summary>
export const appState = atom({
    key: 'appState',
    default: {
        isBusy: false,
        message: 'Loading...'
    }
});

export const isLoggedInState = atom({
    key: ConfigEnum._IS_LOGGED_IN,
    default: false,
})

export const authCredentialState = atom({
    key: 'authCredentialState',
    default: {}
})

/// <summary>
/// Author: Lewis
/// </summary>
export const languagesState = atom({
    key: 'languagesState',
    default: []
})

/// <summary>
/// Author: Robin
/// </summary>
export const userIdSelector = selector({
    key: 'userIdSelector',
    get: ({ get }) => {
        let { id, ...props } = get(authCredentialState);
        return id;
    },
});

export const usernameSelector = selector({
    key: 'usernameSelector',
    get: ({ get }) => {
        let { username, ...props } = get(authCredentialState);
        return username;
    },
});

/// <summary>
/// Author: Lewis
/// </summary>
export const userRolesSelector = selector({
    key: 'userRolesSelector',
    get: ({ get }) => {
        var roles = { isSuperAdmin: false, isAccountant: false, isAdmin: false };
        let { role, ...props } = get(authCredentialState);

        if (role?.id != undefined) {
            if (role.id == RoleType._SUPERUSER) {
                roles.isSuperAdmin = true;
            }
            else if (role.id == RoleType._ACCOUNTANT) {
                roles.isAccountant = true;
            }
            else {
                roles.isAdmin = true;
            }
        }
        return roles;
    },
});

/// <summary>
/// Author: Lewis
/// </summary>
export const loginFormState = atom({
    key: 'loginFormState',
    default: {
        username: '',
        password: '',
        otpMethod: '',
        oneTimePassword: '',
    },
})

/// <summary>
/// Author: Lewis
/// </summary>
export const jurisdictionState = atom({
    key: 'jurisdictionListState',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const countriesState = atom({
    key: 'countryListState',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const documentTypeState = atom({
    key: 'documentTypeState',
    default: [],
});

/// <summary>
/// Author: Lewis
/// </summary>
export const registerFormState = atom({
    key: 'registerFormState',
    default: {
        'currentStep': '0',
        'email': '',
        'name': '',
        'username': '',
        'phoneNumber': '',
        'password': '',
        'retypePassword': '',
        'firm.name': '',
        'firm.services': '',
        'firm.expertise': '',
        'firm.practitionerName': '',
        'certificationAttachment': '',
        'identificationCards': '',
        'emailVerification': false,
        'termsAndConditionVerification': false,
        'blockContainer': 'div.card-register'
    }
})

/// <summary>
/// Author: Chris
/// </summary>
export const enquiryList = atom({
    key: 'enquiryList',
    default: []
});


/// <summary>
/// Author: Chris
/// Editted: Wind
/// </summary>
export const cartState = atom({
    key: 'cartState',
    default: []
});


/// <summary>
/// Author: Lewis
/// </summary>
export const notificationState = atom({
    key: 'notificationState',
    default: []
})

/// <summary>
/// Author: Lewis
/// </summary>
export const alertNotification = atom({
    key: 'alertNotification',
    default: []
})

/// <summary>
/// Author: Chris
/// </summary>
export const tempCompanyName = atom({
    key: 'tempCompanyName',
    default: 'Company'
});

/// <summary>
/// Author: Wind
/// </summary>
export const orderInfoState = atom({
    key: 'orderInfoState',
    default: {}
});

/// <summary>
/// Author: Lewis
/// </summary>
export const personFormIdentitiesState = atom({
    key: 'PersonFormIdentitiesState',
    default: [
        { label: 'ID', value: 'ID' },
        { label: 'PASSPORT', value: 'PASSPORT' },
    ],
});

/**
 * Author : Robin
 */
export const companyProcessStates = atom({
    key: 'companyProcessStates',
    default: [],
});

/**
 * Author : Robin
 * Composite Key: ClassOfShareId + CurrencyId
 */
export const ubTempShareState = atom({
    key: 'ubTempShareState',
    default: [],
    dangerouslyAllowMutability: true
});

export const ubTempShareSelector = selector({
    key: 'ubTempShareSelector',
    get: ({ classOfShareId, currencyId }) => ({ get }) => {
        let { share, ...props } = get(ubTempShareState);
        return share;
    },
});

/// <summary>
/// Author: Sze Hua
/// </summary>
export const companyCurrentTab = atom({
    key: 'companyCurrentTab',
    default: 0
});

export const notificationUpdate = atom({
    key: 'notificationUpdate',
    default: false
});

export const currenciesState = atom({
    key: 'currenciesState',
    default: []
})