import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SessionKey, Language, ApiKey, SweetAlert } from "../../../util/Constant.js";
import CommonDao from 'data/CommonDao.js';
import { useRecoilState } from 'recoil';
import { languagesState } from 'recoil/Atoms.js';
import { Notify, Report } from 'notiflix';

/// <summary>
/// Author: -
/// </summary>
const DropdownLanguage = props => {
    const { i18n } = useTranslation();
    const [languageOptionVisibility, setLanguageOptionVisibility] = useState(false);
    const [languages, setlanguages] = useRecoilState(languagesState);
    let commonDao = new CommonDao();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const getAvailableLanguageOptions = async () => {
        await commonDao.getLanguageList().then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY];
                setlanguages(data.map(({ id, name, code }) => ({ id, name, code, value: code })));
            }
            else {
                Report.Warning(SweetAlert._OPERATION_FAIL, json[ApiKey._API_DATA_KEY], SweetAlert._BACK);
            }
        })
    }

    useEffect(() => {
        getAvailableLanguageOptions();
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    // useEffect(() => {
    //     onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_GB));
    // }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function onChangeLanguage(selectedLanguage) {
        // i18n.changeLanguage(selectedLanguage);
        localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
    }

    return (
        <Dropdown isOpen={languageOptionVisibility} toggle={() => setLanguageOptionVisibility(!languageOptionVisibility)} className="dropdown navbar-languager" tag="li">
            <DropdownToggle className="dropdown-toggle" tag="a">
                <span className="las la-globe"></span>&nbsp;
                {/* <span className="name d-sm-inline">{languages.filter(i => i.value == (localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_GB))[0].prefix}</span> */}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                {
                    languages.map((language, index) => {
                        return <DropdownItem key={index} onClick={() => { onChangeLanguage(language.value) }}><span>{language.name}</span></DropdownItem>
                    })
                }
            </DropdownMenu>
        </Dropdown>
    );
};

export default DropdownLanguage;