import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';

import Dashboard from 'pages/dashboard/Dashboard';
import GetStarted from 'pages/dashboard/GetStarted';

import {
    Login,
    PaymentHistory,
    Registration,
    UserManagement,
    ProfileManager,
    NotificationView,
    AccountantDetails,
    AddAccountantForm,
    PaymentCheckout,
    ForgotPassword,
    RegisterCompletePage,
    ResetPassword
} from 'pages/user';
import {
    CompanyManagementListing,
    CompanyPortfolio,
    CompanyServices,
    CompanyDocuments,
    CompanyFinances,
    KycAmlResult,
    AnnualCompliance,
    CompanyOfficerProfile,
    CompanyLetters
} from 'pages/company-management';
import {
    AddNewCompany,
    CreateOrEditIncorporation
} from 'pages/incorporation';
import {
    ContactUs,
    AdminChatView
} from 'pages/chathub';
import {
    ServiceFeeManagement,
    JurisdictionManagement,
    DocumentManagement,
    CurrencyManagement,
    JurisdictionWizard,
    CurrencyRatioChart,
    RoleManagement
} from 'pages/configuration';
import {
    AddNewPeople,
    IndividualManagement
} from 'pages/individual-management';
import {
    ProductionList,
} from 'pages/production';
import { RenewalList } from 'pages/renewal';
import { KycSearch } from "pages/kyc";
import { FinancesList } from 'pages/finances';


export const _PUBLIC_ROUTES = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={WebUrl._LOGIN} />,
        authRequired: false,
    },
    {
        path: WebUrl._LOGIN,
        title: 'Login',
        component: () => <Login />,
        authRequired: false,
    },
    {
        path: WebUrl._REGISTER,
        title: 'Registration',
        component: () => <Registration />,
        authRequired: false,
    },
    {
        path: WebUrl._FORGOT_PASSWORD,
        title: 'Forgot Password',
        component: () => <ForgotPassword />,
        authRequired: false,
    },
    {
        path: WebUrl._REGISTER_COMPLETE,
        title: "Registration Complete",
        component: () => <RegisterCompletePage />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._RESET_PASSWORD,
        title: "Reset Password",
        exact: false,
        component: () => <ResetPassword />,
        authRequired: false,
    }
]

export const _ROUTES = [
    {
        path: WebUrl._DASHBOARD,
        title: 'Dashboard',
        component: () => <Dashboard />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._GET_STARTED,
        title: 'Get Started',
        component: () => <GetStarted />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._ADD_NEW_COMPANY,
        title: 'Add New Company',
        component: () => <AddNewCompany />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._USERS_MANAGEMENT,
        title: 'Users Management',
        component: () => <UserManagement />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._JURISDICTION_MANAGEMENT,
        title: 'Jurisdiction Management',
        component: () => <JurisdictionManagement />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._JURISDICTION_WIZARD,
        title: 'Jurisdiction Wizard',
        component: () => <JurisdictionWizard />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._CURRENCY_MANAGEMENT,
        title: 'Currency Management',
        component: () => <CurrencyManagement />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._CURRENCY_RATIO_CHART,
        title: 'Currency Chart',
        component: () => <CurrencyRatioChart />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._DOCUMENT_MANAGEMENT,
        title: 'Document Management',
        component: () => <DocumentManagement />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._SERVICE_FEES_MANAGEMENT,
        title: 'Service Fee Management',
        component: () => <ServiceFeeManagement />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._ROLE_MANAGEMENT,
        title: 'Role Management',
        component: () => <RoleManagement />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT,
        title: 'Company Management',
        component: () => <CompanyManagementListing />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_PORTFOLIO,
        title: 'Company Porfolio',
        component: () => <CompanyPortfolio />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_DOCUMENT,
        title: 'Company Documents',
        exact: true,
        component: () => <CompanyDocuments />,
        authRequired: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_FINANCES,
        title: 'Company Finances',
        exact: true,
        component: () => <CompanyFinances />,
        authRequired: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_SERVICES,
        title: 'Company Services',
        exact: true,
        component: () => <CompanyServices />,
        authRequired: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_KYC_AML,
        title: 'Company KYC/AML',
        exact: true,
        authRequired: false,
        component: () => <KycAmlResult />,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_ANN_COMPLIANCES,
        title: 'Company Annual Compliance',
        exact: true,
        authRequired: false,
        component: () => <AnnualCompliance />,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_OFFICER_PROFILE,
        title: 'Company Officer Profile',
        exact: true,
        authRequired: false,
        component: () => <CompanyOfficerProfile />,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_EDIT_INCORPORATION,
        title: 'Incorporation',
        authRequired: false,
        component: () => <CreateOrEditIncorporation edit={true} />,
        exact: true,
    },
    {
        path: WebUrl._PAYMENT_HISTORY,
        title: 'Payment History',
        component: () => <PaymentHistory />,
        authRequired: false,
        exact: true,
    },
    {
        path: WebUrl._ACCOUNTANT_CONTACT_US_VIEW,
        title: 'Contact Us',
        component: () => <ContactUs />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._ADMIN_CHAT_VIEW,
        title: 'Admin Chat',
        component: () => <AdminChatView />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROFILE_MANAGEMENT,
        title: 'User Profile',
        component: () => <ProfileManager />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._USERS_NOTIFICATIONS,
        title: 'User Notification',
        component: () => <NotificationView />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._INDIVIDUAL_MANAGEMENT,
        title: 'Individual Management',
        component: () => <IndividualManagement />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PRODUCTION_LIST,
        title: 'Production List',
        component: () => <ProductionList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._RENEWAL_LIST,
        title: 'Renewal List',
        component: () => <RenewalList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._ACCOUNTANT_DETAILS,
        title: 'Accountant Details',
        component: () => <AccountantDetails />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._ADD_NEW_ACCOUNTANT,
        title: 'New Accountant',
        component: () => <AddAccountantForm />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PAYMENT_CHECKOUT,
        title: 'Payment Checkout',
        component: () => <PaymentCheckout />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._KYC_INDEX,
        title: 'Kyc Search',
        component: () => <KycSearch />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._INDIVIDUAL_MANAGEMENT_ADD_PERSON,
        title: 'Add Person',
        component: () => <AddNewPeople />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._FINANCES_LIST,
        title: 'Finances List',
        component: () => <FinancesList />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._COMPANY_MANAGEMENT_LETTERS,
        title: 'Company Letters',
        component: () => <CompanyLetters />,
        authRequired: true,
        exact: true,
    },
];