import React, { useState, useEffect } from 'react';
import { Config, ConfigEnum } from 'util/Config';
import { ApiKey, TableId } from "util/Constant";
import { appState, authCredentialState, isLoggedInState } from '../recoil/Atoms';
import AuthenticationDao from 'data/AuthenticationDao';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Notify } from "notiflix";
import { clearLocalStorage } from 'util/Utility';

/// <summary>
/// Author: Lewis
/// </summary>
export const useAuthController = (props) => {
    const { loginCallback, getOtpCallback, validateCredentialsCallback } = props

    let dao = new AuthenticationDao();
    let configInstance = Config.getInstance();
    const [authCredential, setAuthCredential] = useRecoilState(authCredentialState);
    const resetAuthCredential = useResetRecoilState(authCredentialState);
    const [isLoggedIn, setLoggedIn] = useRecoilState(isLoggedInState);
    const [errorMessages, setErrorMessages] = useState('');
    const setAppState = useSetRecoilState(appState);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        var loggedIn = configInstance.getValue(ConfigEnum._IS_LOGGED_IN);
        if (loggedIn) setLoggedIn(loggedIn);
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const validateCredentials = async (data) => {

        setAppState(prevState => ({ ...prevState, isBusy: true }));

        await dao.validateLogin(data).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                validateCredentialsCallback.success();
                setLoggedIn(true);
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            validateCredentialsCallback.fail(responseJson[ApiKey._API_DATA_KEY]);
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const login = async (data) => {

        setAppState(prevState => ({ ...prevState, isBusy: true }));

        await dao.login(data).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let responseData = responseJson[ApiKey._API_DATA_KEY];
                configInstance.setData(ConfigEnum._IS_LOGGED_IN, true);
                setLoggedIn(true);
                setAuthCredential(responseData.user);
                loginCallback.success();
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            loginCallback.fail(responseJson);
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const logout = async () => {

        setAppState(prevState => ({ ...prevState, isBusy: true }));
        var response = await dao.performLogout().then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let exclude = Object.values(TableId);
                clearLocalStorage(exclude);
                // localStorage.clear();
                resetAuthCredential();
            }
        });
        setAppState(prevState => ({ ...prevState, isBusy: false }));
        return response;
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const getOtp = async (data, isInitialRequest = true) => {

        setAppState(prevState => ({ ...prevState, isBusy: true }));

        await dao.requestOtp(data).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                getOtpCallback.success(responseJson[ApiKey._API_DATA_KEY]);
                return
            }
            setErrorMessages(responseJson[ApiKey._API_MESSAGE_KEY]);
            getOtpCallback.fail(responseJson[ApiKey._API_DATA_KEY]);
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));

        if (!isInitialRequest) {
            Notify.Success("Successfully requested OTP.");
        }

    }

    return { login, isLoggedIn, validateCredentials, logout, authCredential, getOtp, errorMessages }
};

useAuthController.defaultProps = {
    loginCallback: { success: () => void (0), fail: () => void (0) },
    getOtpCallback: { success: () => void (0), fail: () => void (0) },
    validateCredentialsCallback: { success: () => void (0), fail: () => void (0) },
    emailVerifyCallback: { success: () => void (0), fail: () => void (0) }
}
