import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, CardBody, Card } from "reactstrap";
import { Input, Select } from 'components/form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useForm } from "react-hook-form";
import { AiFillEye } from "react-icons/ai";
import OtpInput from 'react-otp-input';
import { OTPSettings, ApiKey, WebUrl, ResetPasswordResult, ApiUrl } from '../../util/Constant';
import InputHoc from "components/form/InputHoc";
import AccountantDao from "data/AccountantDao";
import Notiflix, { Report, Notify } from "notiflix";
import { PageSettings } from "../../config/page-settings";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import DataAccessObject from "data/DataAccessObject";

/// <summary>
/// Author: Lewis
/// </summary>
const ResetPassword = props => {
    const _location = useLocation();
    const _history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const _context = useContext(PageSettings);
    const [userInfo, setUserInfo] = useState({});
    const values = queryString.parse(_location.search);
    const [pageType, setPageType] = useState();

    const { t, i18n } = useTranslation();

    const { data: languageList } = useSWR(
        ApiUrl._API_GET_LANGUAGE_LIST,
        async (url) => {
            let dao = new DataAccessObject();
            let tempList = [];
            await dao.get(url).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    tempList = jsonResponse[ApiKey._API_DATA_KEY];
                }
            });
            return tempList;
        }
    );

    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);

        (async () => {
            let dao = new AccountantDao();
            var uid = values.uid
            if (!uid) {
                _history.push(WebUrl._LOGIN)
                return;
            }
            await dao.checkResetCodeAvailability(uid).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    setUserInfo(data);
                    setPageType("Reset");
                } else {
                    var responseData = response[ApiKey._API_DATA_KEY];
                    setUserInfo(responseData);
                    setPageType("Expired");
                }
            })
        })();

        return (() => {
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
        });
    }, [])


    const resetPassword = async (data) => {
        let dao = new AccountantDao();
        var uid = values.uid;
        if (data.newPassword != data.confirmPassword) {
            Report.Failure(t('notiflix:PASSWORD_NOT_MATCH'), "", t('notiflix:CLOSE'));
        } else {
            let postData = { oneTimeCode: uid, ...data };
            await dao.resetForgetPassword(postData).then((response) => {
                var success = response[ApiKey._API_SUCCESS_KEY];

                if (success) {
                    Report.Success(t('notiflix:PASSWORD_CHANGED'), "", t('OKAY'));
                    setPageType("Success");
                } else {
                    Report.Failure(t('notiflix:PASSWORD_CHANGE_ERROR'), response[ApiKey._API_FIRST_ERROR_KEY]?.detail, t('OKAY'))
                    // switch (response[ApiKey._API_MESSAGE_KEY]) {
                    //     case ResetPasswordResult._LINK_EXPIRED:
                    //         Report.Failure(t('notiflix:LINK_EXPIRED'), "", t('notiflix:CLOSE'));
                    //         break;
                    //     case ResetPasswordResult._INVALID_PASSWORD:
                    //         var errors = response[ApiKey._API_ERROR_KEY];
                    //         var errorHtml = "";
                    //         errors.forEach((error) => {
                    //             errorHtml += `<li>${error.detail}</li>`;
                    //         });
                    //         errorHtml = `<ul>${errorHtml}</ul>`;

                    //         Report.Warning(t('notiflix:INVALID_PASSOWRD'), errorHtml ?? "", t('OKAY'));
                    //         break;
                    //     default:
                    //         Report.Failure(t('notiflix:ERROR'), t('notiflix:PASSWORD_CHANGE_ERROR'), t('notiflix:CLOSE'));
                    //         return;
                    // }
                }
            });
        }

    }

    const sendForgotPasswordEmail = async (data) => {
        Notiflix.Loading.Circle(t('notiflix:SENDING_EMAIL'));
        let dao = new AccountantDao();
        await dao.sendForgotPasswordEmail(userInfo.username).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Loading.Remove();
                Report.Success(t('notiflix:EMAIL_SENT'), "", t('OKAY'))
                _history.push(WebUrl._RESET_PASSWORD);
            } else if (response[ApiKey._API_MESSAGE_KEY] == "NOT_FOUND") {
                Notiflix.Loading.Remove();
            } else {
                Notiflix.Loading.Remove();
                Report.Failure(t('notiflix:ERROR'), "", t('notiflix:CLOSE'))
            }
            Notiflix.Loading.Remove();
        })
    }

    return (
        <div id="forgot-password-page">
            <div className="container d-flex flex-column h-100">
                <Card className="panel-brand card-login">
                    <CardBody className="p-5">
                        {(() => {
                            switch (pageType) {
                                case "Reset":
                                    return (
                                        <>
                                            <Row>
                                                <Col xl={6} md={6} xs={12} className="p-40">
                                                    <img className="img-responsive" src={require("../../assets/img/ui/graphic-resetpassword.svg")} />
                                                </Col>
                                                <Col xl={6} md={6} xs={12} className="p-40">
                                                    <div className="d-flex h-100 form-wrapper" style={{ width: '100%' }}>
                                                        <Form onSubmit={handleSubmit(resetPassword)} autoComplete='off'>
                                                            <h2 className="title title-with-dot">{t('RESET_PASSWORD')}</h2>
                                                            <p className="m-b-30">{t('UNVERIFIED_ACTION')}</p>
                                                            <InputHoc className="w-75" label="NEW_PASSWORD" type="password" name="newPassword"
                                                                rules={{ required: "New Password is Required" }} control={control} />
                                                            <InputHoc className="w-75" label="RETYPE_PASSWORD" type="password" name="confirmPassword"
                                                                rules={{ required: "Confirm Password is Required" }} control={control} />
                                                            <div>
                                                                <button type="submit" className="btn btn-themed btn-min-width m-t-40">{t('RESET')}</button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <h1 className="title">Reset Password</h1>
                                            <table className="mb-3 w-100">
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td><b>Username</b></td>
                                                    <td>{userInfo?.username}</td>
                                                </tr><tr>
                                                    <td><b>Email</b></td>
                                                    <td>{userInfo?.email}</td>
                                                </tr><tr>
                                                    <td><b>Reset Code</b></td>
                                                    <td>{values.uid}</td>
                                                </tr>
                                            </table>
                                            <span>Please contact SBC Admin if you doesn't request this action.</span><br /><br />

                                            <div className="form-group">
                                                <Form onSubmit={handleSubmit(resetPassword)} >
                                                    <p className="m-3">New Password</p>
                                                    <Input className="form-control-md m-3" label="New Password" type="password" name="newPassword"
                                                        ref={register({ required: "New Password is Required" })} /><br />
                                                    <p className="m-3">Confirm Password</p>
                                                    <Input className="form-control-md m-3" label="Confirm Password" type="password" name="confirmPassword"
                                                        ref={register({ required: "Confirm Password is Required" })} /><br />
                                                    <button type="submit" className="btn btn-lg btn-green">Reset Password</button>
                                                </Form>
                                            </div> */}
                                        </>
                                    )
                                case "Expired":
                                    return (
                                        <>
                                            <Row>
                                                <Col xs={12} className="p-40">
                                                    <h2 className="title title-with-dot">{t('LINK_EXPIRED')}</h2>
                                                    <p className="m-b-30">{t('PASSWORD_LINK_EXPIRED')}</p>
                                                    <img className="img-responsive m-auto" src={require("../../assets/img/ui/graphic-linkexpired.svg")} />
                                                    <Form onSubmit={handleSubmit(sendForgotPasswordEmail)}>
                                                        <div className="d-flex justify-content-center m-t-40">
                                                            <button type="submit" className="btn btn-themed btn-min-width">{t('RESENT')}</button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            {/* <h1 className="title">Link Has Expired</h1>
                                            <p>The link is no longer valid. Click Resent Email button to reset again.</p>
                                            <div className="form-group">
                                                <Form onSubmit={handleSubmit(sendForgotPasswordEmail)}>

                                                    <button type="submit" className="btn btn-lg btn-green">Resend Link</button>
                                                </Form>
                                            </div> */}
                                        </>
                                    )
                                case "Success":
                                    return (
                                        <>
                                            <Row>
                                                <Col xl={6} md={6} xs={12} className="p-40">
                                                    <img className="img-responsive m-auto" src={require("../../assets/img/ui/forgt-pw.svg")} />
                                                </Col>
                                                <Col xl={6} md={6} xs={12} className="p-40 align-self-center">
                                                    <h2 className="title title-with-dot">{t('PASSWORD_RESET')}</h2>
                                                    <p className="m-b-30">{t('PASSWORD_RESET_SUCCESS')}</p>
                                                    {/* <img className="img-responsive m-auto" src={require("../../assets/img/ui/forgt-pw.svg")} /> */}
                                                    <Form onSubmit={handleSubmit(sendForgotPasswordEmail)}>
                                                        <div className="d-flex justify-content-start m-t-40">
                                                            <button type="submit" className="btn btn-themed btn-min-width" onClick={() => { _history.push(WebUrl._LOGIN) }}>{t('GO_TO_LOGIN')}</button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            {/* <h1 className="title">Password Reset</h1>
                                            <div className="form-group">
                                                <p>Password have been reset, you are good to go</p>
                                                <button type="submit" className="btn btn-lg btn-green" onClick={() => { _history.push(WebUrl._LOGIN) }}>Go Back to Login</button>
                                            </div> */}
                                        </>
                                    )
                                default:
                                    return (<></>)
                            }
                        })()}
                    </CardBody>
                </Card>
                <div className="d-flex align-items-center p-l-15 p-t-10 mb-auto">
                    <span>{t('LANGUAGE')}</span>
                    <div className="d-flex">
                        {languageList && (
                            languageList.map(data => (
                                <div className={"pointer-cursor countries language-" + data.code} onClick={() => { i18n.changeLanguage(data.code) }} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ResetPassword;