import React, { useState, useMemo, useEffect, useRef, useContext } from 'react';
import { Row, Col, Collapse, ModalBody, ModalFooter } from 'reactstrap';
import { ApiKey, ApiUrl, BtnTypes, OfficerTypeID, DateFormat, WebUrl, InputTypes, Icon, TableId } from 'util/Constant';
import 'awesome-steps/dist/style.css';
import { stringIsNullOrEmpty, cleanObject } from 'util/Utility';
import { ReactTablev2 as ReactTable } from "components/react-table";
import _ from 'lodash/object';
import ButtonRound from 'components/buttons/ButtonRound';
import { useRecoilValue } from 'recoil';
import { authCredentialState } from 'recoil/Atoms';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import OfficerPersonalDetail from './OfficerPersonalDetail';
import { useHistory } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import { PageSettings } from 'config/page-settings';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import BrandModal from 'components/modals/BrandModal';

/// <summary>
/// Author : Lewis
/// </summary>
const IndividualManagement = (props) => {

    const { t } = useTranslation();
    const [officerType, setOfficerType] = useState('');
    const [fetchOfficerId, setFetchOfficerId] = useState('');
    const [pNumber, setPnumber] = useState(null);

    return (
        <div className="panel panel-brand panel-flex" id="IndividualManagement">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("INDIVIDUAL_MANAGEMENT")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <OfficerComponent
                    pNumber={pNumber}
                    setPnumber={setPnumber}
                    officerType={officerType}
                    initOfficerTablePageSize={10}
                    setOfficerType={setOfficerType}
                    fetchOfficerId={fetchOfficerId}
                    setFetchOfficerId={setFetchOfficerId}
                />
            </div>
        </div>
    )
}

/// <summary>
/// Author : Lewis
/// </summary>
const OfficerComponent = (props) => {

    const { t } = useTranslation();
    const { 
        isXsDevices,
        isSmDevices,
        isMdDevices,
        isLgDevices,
        isXlDevices
     } = useContext(PageSettings);

    let {
        setOfficerType,
        officerType,
        setFetchOfficerId,
        fetchOfficerId,
        applyOfficerCallback,
        setPnumber,
        pNumber,
        rowOnClickDataCallback,
    } = props;

    const _OFFICER_TYPE_KEY = { _CORPORATE: 'Corporate', _INDIVIDUAL: 'Individual' };
    const _PANEL_BODY_STYLE = {
        backgroundColor: "#f9f9fa",
        // borderRadius: "0 0 2rem 2rem",
        // boxShadow: "#ad813a47 0 8px 20px"
    };

    const _history = useHistory();
    const [filtersForm, setFiltersForm] = useState({});
    const [resetFilters, setResetFilters] = useState(false);
    const _formRef = useRef();
    const { ref: wrapperRef } = useResizeDetector();

    const { firmId, role, ...restProps } = useRecoilValue(authCredentialState);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [modal, setModal] = useState(false);

    const [officerCount, setOfficerCount] = useState(null);

    const _COLUMNS = useMemo(() => [
        {
            Header: 'INDEX',
            Cell: ({ row }) => (row.index + 1),
            width: "5%"
        },
        {
            Header: "NAME", accessor: (el) => {
                return <div>
                    <div className="m-b-5 nowrap">
                        <span className="badge badge-purple">EN</span>
                        <span style={{ paddingLeft: '3%' }}>{el.englishName}</span>
                    </div>
                    {
                        !stringIsNullOrEmpty(el?.chineseName) && <>
                            <div className="nowrap">
                                <span className="badge badge-light-green">CN</span>
                                <span style={{ paddingLeft: '3%' }}>{el.chineseName}</span>
                            </div>
                        </>
                    }
                </div>
            },
            isRequiredColumn: true,
        },
        {
            Header: 'P#',
            accessor: 'pNumber'
        },
        {
            Header: 'EMAIL',
            accessor: 'email',
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original?.email ? <span>{row.original.email}</span> : <span>-</span>}
                    </>
                )
            }
        },
        {
            Header: 'PHONE_NUMBER',
            accessor: 'phoneNumber',
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original?.phoneNumber ? <span>{row.original.phoneNumber}</span> : <span>-</span>}
                    </>
                )
            }
        },
        {
            Header: 'ACTION',
            Cell: ({ row }) => {
                return (
                    <div className="btn-list-wrapper">
                        {typeof (setOfficerType) == "function" && (
                            <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => {
                                (row.original?.officerTypeId == OfficerTypeID._CORPORATE) ? setOfficerType(_OFFICER_TYPE_KEY._CORPORATE) : setOfficerType(_OFFICER_TYPE_KEY._INDIVIDUAL);
                                setFetchOfficerId(row.original.id);
                                setPnumber(row.original.pNumber);
                                toggle();
                            }} />
                        )}
                        {typeof (applyOfficerCallback) == 'function' && (
                            <ButtonRound type={BtnTypes.CHECK_CIRCLE} small onClick={() => {
                                applyOfficerCallback(row.original);
                            }} />
                        )}
                        {typeof (rowOnClickDataCallback) == "function" && (
                            <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => rowOnClickDataCallback(row.original)} />
                        )}
                    </div>
                )
            },
            disableFilters: true,
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], []);

    const _FORM_FIELDS = [
        {
            rowOptions: { xl: 1 },
            columns: [
                { prefix: { icon: Icon._PROFILE }, label: `${t("SEARCH_ID_PASSPORT")}`, placeholder: `${t("SEARCH_ID_PASSPORT")}`, name: 'identityNumber', input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6, sm: 6 } },
                { prefix: '#', label: `${t("SEARCH_ENG_NAME")}`, placeholder: `${t("SEARCH_ENG_NAME")}`, name: 'englishName', input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6, sm: 6 } },
                { prefix: { icon: Icon._EMAIL }, label: `${t("SEARCH_EMAIL")}`, placeholder: `${t("SEARCH_EMAIL")}`, name: 'email', input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6, sm: 6 }, autoComplete: "new-password" },
                { prefix: { icon: Icon._SEARCH_PHONE_NUMBER }, label: `${t("SEARCH_PHONE_NUMBER")}`, placeholder: `${t("SEARCH_PHONE_NUMBER")}`, name: 'phoneNumber', input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6, sm: 6 }, autoComplete: "new-password" }
            ]
        }
    ]

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    /// <summary>
    /// Author: CJ(Jiann)
    /// </summary>
    const toggle = () => {
        setModal(!modal);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const filterOnSubmitHandler = (value) => setFiltersForm(value);

    /// <summary>
    /// Author : Sze Hua
    /// </summary>
    const setRefreshOfficerTable = () => setRefreshTable(prevState => !prevState);

    /// <summary>
    /// Author : Sze Hua
    /// </summary>
    const onAddOfficer = (officer) => {
        if (officer.officerTypeId === OfficerTypeID._CORPORATE)
            setOfficerType(_OFFICER_TYPE_KEY._CORPORATE)
        else if (officer.officerTypeId === OfficerTypeID._INDIVIDUAL)
            setOfficerType(_OFFICER_TYPE_KEY._INDIVIDUAL)
        setPnumber(officer.pNumber);
        setFetchOfficerId(officer.id);
        setRefreshOfficerTable();
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current) {
            return wrapperRef.current.clientHeight
        }
    }, [wrapperRef?.current, isFilterOpen]);

    return <div className={classNames('panel-body-inner-content with-br-radius', { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}>
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container">
                {
                    (isXsDevices || isSmDevices) ? (
                        <div className="filter-panel-container border-radius-one">
                            <Row className="filter-panel-header">
                                <div className="filter-panel-header-content">
                                    <div className="btn btn-light btn-filter" onClick={() => setIsFilterOpen(prevState => !prevState)}>
                                        <img alt="" className="mr-2" src={require("../../assets/img/icon/filter.svg")} />{t("FILTER")}
                                    </div>
                                    {
                                        isFilterOpen &&
                                        <div className="d-flex btn-list">
                                            <button className="btn btn-themed btn-brand-round" onClick={() => {submitForm(_formRef); setIsFilterOpen(prevState => !prevState);}}><i className="las la-search" /></button>
                                            <button className="btn btn-themed btn-brand-round grayscale-100 m-r-auto" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                        </div>
                                    }
                                </div>
                            </Row>
                            <Collapse isOpen={isFilterOpen}>
                                <Row className="filter-panel-body">
                                    <Col xl={10}>
                                        <FormBuilder
                                        fields={_FORM_FIELDS}
                                        formRef={_formRef}
                                        onSubmit={filterOnSubmitHandler}
                                        formClasses="individual-management-filter-form"
                                        reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                                    </Col>
                                </Row>
                            </Collapse>
                        </div>
                    ) :
                    <div className="filter-panel-container border-radius-none">
                        <Row className="filter-panel-body p-15">
                            <Col xl={8}>
                                <FormBuilder
                                    fields={_FORM_FIELDS}
                                    formRef={_formRef}
                                    onSubmit={filterOnSubmitHandler}
                                    formClasses="individual-management-filter-form"
                                    reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                            </Col>
                            <Col xl={2} className="d-flex btn-list justify-content-end">
                                <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_formRef)}><i className="las la-search" /></button>
                                <button className="btn btn-themed btn-brand-round grayscale-100 m-r-auto" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                            </Col>
                            <Col xl={2}>
                                <button
                                    className="btn btn-sm btn-themed btn-min-width pull-right"
                                    onClick={() => {
                                        _history.push({ pathname: WebUrl._INDIVIDUAL_MANAGEMENT_ADD_PERSON })
                                    }} >{t("ADD_NEW_PERSON")} (P#)</button>
                            </Col>
                        </Row>
                    </div>
                }
            </div>

            <div className="flex-grow-1" ref={wrapperRef}>
                <Row className="m-0 h-100">
                    <Col className="p-0" style={{ borderBottomLeftRadius: "2em" }}>
                        <div className="table-brand-wrapper h-100">
                            {
                                role?.id &&
                                <ReactTable
                                    columns={_COLUMNS}
                                    tableMinHeight={fixedContentHeight}
                                    url={ApiUrl._API_SEARCH_EXISTING_OFFICER}
                                    initQueryProps={!stringIsNullOrEmpty(firmId) ? { firmId: firmId } : {}}
                                    filterFormfield={filtersForm}
                                    fetchOnSuccess={(json, count) => setOfficerCount(count)}
                                    customButtons={
                                        <>
                                            {officerCount &&
                                                <Link className="brand-anchor"
                                                    to={`${ApiUrl._API_INDIVIDUAL_MANAGEMENT_EXPORT_EXCEL}?${new URLSearchParams(!stringIsNullOrEmpty(firmId) ? { firmId: firmId, ...(cleanObject(filtersForm)), pageSize: officerCount } : { ...(cleanObject(filtersForm)), pageSize: officerCount })}`}
                                                    target={"_parent"} download>
                                                    <ButtonRound medium style={{ lineHeight: '0px' }} type={BtnTypes.FILE_DOWNLOAD} title="Download Excel" />
                                                </Link>}
                                            {isXsDevices &&
                                                <div className="btn-group ml-2">
                                                    <button type="type" className="btn btn-themed btn-rounded" onClick={() => { _history.push(WebUrl._ADD_NEW_ACCOUNTANT) }} ><i className="fas fa-plus"></i></button>
                                                </div>}
                                        </>
                                    }
                                    tableColumnPreference={[restProps?.id, TableId._INDIVIDUAL_MANAGEMENT]}
                                />
                            }
                        </div>
                    </Col>
                    {
                        (!isXlDevices) ?
                            <BrandModal 
                                isOpen={!stringIsNullOrEmpty(fetchOfficerId) && modal}
                                toggler={toggle}
                                title={`P# ${t('PROFILE_INFORMATION')} (${pNumber && pNumber})`}
                                customBody
                                id="person-detail-mobile"
                            >
                                <ModalBody>
                                    <OfficerPersonalDetail fetchOfficerId={fetchOfficerId} />
                                </ModalBody>
                                <ModalFooter>
                                    <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                                </ModalFooter>
                            </BrandModal>
                        :
                        <Col
                            className="p-0 y-scrollbar-2"
                            style={{ ..._PANEL_BODY_STYLE, overflowY: "scroll", height: fixedContentHeight - 50 }}
                        >
                            <div className="m-5">
                                <h3 className="my-4 mx-4"><img alt="" className="mr-3" src={require("../../assets/img/icon/edit.svg")} />P# {t("PROFILE_INFORMATION")} {pNumber && "(" + pNumber + ")"}</h3>
                                {
                                    !stringIsNullOrEmpty(fetchOfficerId) ?
                                        <OfficerPersonalDetail fetchOfficerId={fetchOfficerId} />
                                        :
                                        <div className="d-flex align-items-center justify-content-center" >
                                            <div className="d-lg-block d-md-none">
                                                <img className="search-officer-png mr-5" src={require("../../assets/img/ui/search-graphic.svg")} alt="" />
                                                <h4 className="text-center">{t("SELECT_A_PROFILE_TO_VIEW")}</h4>
                                            </div>
                                        </div>
                                }
                            </div>
                        </Col>
                    }
                </Row>
            </div>
        </div>
    </div>
}


export default IndividualManagement;