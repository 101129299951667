import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WebUrl } from 'util/Constant';

/// <summary>
/// Author: Chris
/// </summary>
const NavigationButton = ({ url, classes, state }) => {
    const _history = useHistory();
    const _defaultClasses = 'btn btn-brand-round btn-themed';
    const _containerStyles = {
        display: 'inline-block',
        marginRight: '15px'
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    const btnHandler = () => {
        if (url) {
            _history.push(url, {
                state: { ...state }
            });
        }
        else if (_history.length > 0) {
            _history.goBack();
        }
        else {
            // _history.push(WebUrl._DASHBOARD);
            _history.push(WebUrl._GET_STARTED);
        }
    }

    return (
        <div style={_containerStyles}>
            <div className={classes ?? _defaultClasses} onClick={() => btnHandler()}><i className="las la-arrow-left"></i></div>
        </div>
    );
}

export default NavigationButton;