import React, { useMemo, useRef, useState, useContext, useEffect } from "react";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { Row, Col, Collapse, UncontrolledTooltip } from "reactstrap";
import { WebUrl, Status, SelectField, ApiUrl, ApiKey, InputTypes, CompanyStatus, BtnTypes, Icon, SweetAlert, DateFormat, TableId } from 'util/Constant';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Notiflix from "notiflix";
import { doubleClickNavigate, renameObjectKeys, parseInt, stringIsNullOrEmpty, formatJurisdictionList, CompanyStatusToTranslateKey } from 'util/Utility';
import { CompanyDao } from "data";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "components/panel/TabPanel";
import ProductionNameCheck from "./ProductionNameCheck";
import ButtonRound from "components/buttons/ButtonRound";
import { useRecoilValue } from "recoil";
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import { PageSettings } from "config/page-settings";
import classNames from "classnames";
import useSWR from "swr";
import { Can } from "config/user-access";
import { PolicyObjectConstant, PolicyActionConstant } from "../../util/Constant";
import Error403View from 'components/error-graphic/Error403NoAccess';
import { isEmpty } from "lodash";
import moment from "moment";
import { userIdSelector } from "recoil/Atoms";

///<summary>
///Author: Jia Ren GOH
///</summary>
const ProductionList = (props) => {

    const { t } = useTranslation();
    const _history = useHistory();
    const _location = useLocation();
    const { isXlDevices } = useContext(PageSettings);
    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        _PRODUCTION_NAME_CHECK: { id: 0, title: "COMPANY_NAME_CHECK" },
        _PRODUCTION_LIST: { id: 1, title: "SERVICE_REQUEST_LIST" },
    }

    useEffect(() => {
        let search = _location.search;

        if (stringIsNullOrEmpty(search)) {
            setCurrentTab(0);
            return;
        }

        let params = new URLSearchParams(search);
        if (params) {
            let tab = parseInt(params.get("tab"));
            setCurrentTab(tab);
        }
    }, []);

    return (<>
        <div className="panel panel-brand panel-flex" id="ProductionList">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot mb-0 ">{t("PRODUCTION_LIST")}</h1>
                <Tabs value={currentTab} onChange={(e, newTab) => {
                    const path = _location.pathname;
                    let params = new URLSearchParams({
                        tab: newTab
                    });

                    _history.push({
                        pathname: path,
                        search: `?${params}`
                    })

                    setCurrentTab(newTab);
                }} variant="scrollable">
                    <Tab icon={<img alt="" src={require('../../assets/img/icon/add-document-gold.svg')} className="mr-2" />} classes={{ wrapper: "d-inline text-theme-1" }} label={t(_TABS._PRODUCTION_NAME_CHECK.title)} />
                    <Tab icon={<img alt="" src={require('../../assets/img/icon/product-name-check.svg')} className="mr-2" />} classes={{ wrapper: "d-inline text-theme-1" }} label={t(_TABS._PRODUCTION_LIST.title)} />
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames('panel-body-inner-content with-br-radius',
                    { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}
                >
                    <div className="h-100 d-flex flex-column">
                        <TabPanel value={currentTab} index={_TABS._PRODUCTION_NAME_CHECK.id} className="flex-grow-1">
                            <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_namecheck_list} passThrough>
                                {
                                    allowed => allowed ?
                                        <ProductionNameCheck />
                                        :
                                        <Error403View />
                                }
                            </Can>
                        </TabPanel>
                        <TabPanel value={currentTab} index={_TABS._PRODUCTION_LIST.id} className="flex-grow-1">
                            <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_production_list} passThrough>
                                {
                                    allowed => allowed ?
                                        <ServiceRequestList />
                                        :
                                        <Error403View />
                                }
                            </Can>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ProductionList;

const ServiceRequestList = (props) => {

    const userId = useRecoilValue(userIdSelector);
    const { t } = useTranslation();

    const _history = useHistory();
    const _tableRef = useRef();
    const _filterFormRef = useRef();

    const { ref: wrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [selectedRow, setSelectedRow] = useState();
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [companyStatusList, setCompanyStatusList] = useState([]);

    const markAsComplete = async (companyId, serviceRequestId) => {

        let dao = new CompanyDao();
        Notiflix.Block.Circle(".reactTable", "Refreshing...");

        await dao.markServiceRequestAsComplete(companyId, serviceRequestId).then((json) => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                _tableRef.current.reFetch();
            } else {
                var firstError = json[ApiKey._API_FIRST_ERROR_KEY];
                Notiflix.Report.Warning(
                    firstError?.title,
                    firstError?.detail,
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => {
            Notiflix.Block.Remove(".reactTable");
        });

    }

    const _SERVICE_STATUS = [
        { label: 'ONGOING', value: 'false' },
        { label: 'COMPLETED', value: 'true' }
    ]

    const _COLUMNS = useMemo(() => [
        {
            Header: 'ACCOUNTANT',
            accessor: "company.requestor.name",
            Cell: ({ row }) => {
                return <span className="font-weight-bold f-s-14">{row.original?.company?.requestor.name}</span>
            }
        },
        {
            Header: 'COMPANY_NAME',
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="m-b-5 nowrap text-overflow-ellipsis">
                            <span className="badge badge-purple">EN</span>
                            <span id={`comp-name-ctrl-tooltip-${row.original.id}`} style={{ paddingLeft: '3%' }}>{row.original.company.englishName}</span>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="bottom-right" target={`comp-name-ctrl-tooltip-${row.original.id}`}>{row.original.company.englishName}</UncontrolledTooltip>
                        {
                            !stringIsNullOrEmpty(row.original?.company?.chineseName) && <>
                                <div className="nowrap">
                                    <span className="badge badge-light-green">CN</span>
                                    <span style={{ paddingLeft: '3%' }}>{row.original.company.chineseName}</span>
                                </div>
                            </>
                        }
                    </div>
                )
            },
            isRequiredColumn: true
        },
        {
            Header: 'JURISDICTION',
            accessor: "company.jurisdiction.name",
            Cell: ({ row }) => {
                return <span className="font-weight-bold f-s-14">{row.original?.company?.jurisdiction.name}</span>
            }
        },
        {
            Header: 'MATTER',
            accessor: "productMatter.name",
            Cell: ({ row }) => {
                return <span>{row.original?.productMatter?.name}</span>
            }
        },
        {
            Header: 'SERVICE_STATUS',
            accessor: "isCompleted",
            Cell: ({ row }) => {
                return (
                    <>
                        {
                            row.original.isCompleted ?
                                <span className="badge badge-success">{t("COMPLETED")}</span> :
                                <span className="badge badge-warning">{t("ON_GOING")}</span>
                        }
                    </>
                );
            }
        },
        {
            Header: 'TASK_STATUS',
            accessor: "hasTaskRemaining",
            Cell: ({ row }) => {
                return (
                    <>
                        {
                            row.original.hasTaskRemaining ?
                                <span className="badge badge-warning">{t("SOME_TASK_REMAINING")}</span> :
                                <span className="badge badge-success">{t("ALL_TASK_COMPLETED")}</span>
                        }
                    </>
                );
            }
        },
        {
            Header: 'REQUEST_DATE',
            accessor: el => el.requestDate ? moment(el.requestDate).format(DateFormat._DATE_ONLY) : "",
            isRequiredColumn: true
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {

                return (
                    <div className="btn-list-wrapper d-flex">
                        <ButtonRound medium type={BtnTypes.FA_VIEW} onClick={
                            () => _history.push(
                                WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(":companyId", row.original?.company?.id)
                            )} />
                        {
                            (!row.original.isCompleted && !row.original.hasTaskRemaining) &&
                            <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company} passThrough>
                                {
                                    allowed => allowed ?
                                        <ButtonRound medium type={BtnTypes.CHECK_CIRCLE} onClick={() => {
                                            Notiflix.Confirm.Show(`${t('notiflix:MARK_AS_COMPLETE')}?`, `${t('notiflix:MARK_SERVICE_AS_COMPLETE')}`, t('notiflix:YES'), t('notiflix:NO'),
                                                () => {
                                                    let companyId = row.original?.companyId;
                                                    let serviceRequestId = row.original?.id;
                                                    markAsComplete(companyId, serviceRequestId);
                                                })
                                        }} title={t('notiflix:MARK_AS_COMPLETED')} />
                                        :
                                        <> - </>
                                }
                            </Can>
                        }
                        {/*
                            row.original.productMatter.matterTypeId == 3 &&
                            <>
                                {
                                    //temporary hide
                                    //!row.original.isCompleted &&
                                    //<ButtonRound medium type={BtnTypes.DOUBLE_RIGHT} onClick={
                                    //    () => {

                                    //        let serviceRequestId = row.original.company.serviceRequests.find(x => x.productMatterId == row.original.productMatterId).id;

                                    //        _history.push({
                                    //            pathname: WebUrl._PRODUCTION_SERVICES_CHANGES_REQUEST_ADMIN_VIEW, state: {
                                    //                serviceRequestsId: serviceRequestId,
                                    //                productMatterId: row.original.productMatterId,
                                    //                companyId: row.original.company.id
                                    //            }
                                    //        })
                                    //    }

                                    //} />
                                }
                                {
                                    (!row.original.isCompleted && !row.original.hasTaskRemaining) &&
                                    <ButtonRound medium type={BtnTypes.CHECK_CIRCLE} onClick={() => {
                                        Notiflix.Confirm.Show('Mark As Complete?', 'Mark the service request as complete?', 'Yes', 'No',
                                            () => {
                                                let companyId = row.original?.companyId;
                                                let serviceRequestId = row.original?.id;
                                                markAsComplete(companyId, serviceRequestId);
                                            })
                                    }} title="Mark as completed" />
                                }
                            </>
                        */}
                    </div>
                )
            },
            disableSortBy: true,
            style: { overflow: "visible" },
            isRequiredColumn: true
        },
    ], []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchCompanyStatus = async () => {

        let companyDao = new CompanyDao();

        await companyDao.getCompanyStatus().then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setCompanyStatusList(() => {
                    var statusList = [{ label: t('ALL'), value: "" }];
                    response[ApiKey._API_DATA_KEY].map(item => {
                        statusList.push(renameObjectKeys({ id: 'value', name: 'label' }, {id: item.id, name: t(`${CompanyStatusToTranslateKey(item.id)}`)}));
                    });
                    return statusList;
                });
            }
        })
    };

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {
        fetchCompanyStatus();
    }, [])

    const _FORM_FIELDS = useMemo(() => [
        {
            rowOptions: { xl: 3 },
            columns: [
                { prefix: { icon: Icon._PROFILE }, label: `${t('SEARCH_ACCOUNTANT')}`, placeholder: `${t('SEARCH_ACCOUNTANT')}`, name: 'accountantName', smallnote: `${t("NAME")}, ${t("NUMBER")}, ${t("FIRM")}, ${t("ETC")}`, input: InputTypes.INPUT, columnOptions: { xl: 2, md: 6 } },
                { prefix: { icon: Icon._PROFILE }, label: `${t('SEARCH_COMPANY_NAME')}`, placeholder: `${t('SEARCH_COMPANY_NAME')}`, name: 'englishName', smallnote: `${t("C_NUMBER")}, ${t("COMPANY_NAME")}, CI ${t("ETC")}`, columnOptions: { xl: 3, md: 6 } },
                { prefix: { icon: Icon._SEARCH_JURISDICTION }, label: `${t('SEARCH_JURISDICTION')}`, placeholder: `${t('SEARCH_JURISDICTION')}`, name: 'jurisdictionId', input: InputTypes.SELECT, options: jurisdictionOptions, columnOptions: { xl: 2, md: 6 } },
                { prefix: { icon: Icon._COMPANY_STATUS }, label: t('COMPANY_STATUS'), placeholder: t('COMPANY_STATUS'), name: 'status', input: InputTypes.SELECT, options: companyStatusList, checkboxInline: true, columnOptions: { xl: 2, md: 6 } },
                { prefix: { icon: Icon._COMPANY_STATUS }, label: t('SERVICE_STATUS'), placeholder: t('SERVICE_STATUS'), name: 'isCompleted', input: InputTypes.SELECT, options: _SERVICE_STATUS.map((item) => {return {label: t(`${item.label}`), value: item.value}}), columnOptions: { xl: 2, md: 6 } },
            ]
        }
    ], [
        companyStatusList,
        jurisdictionOptions,
    ]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }

        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current, isFilterOpen]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container border-radius-none">
                {/* <Row className="filter-panel-header">
                    <div className="btn btn-light btn-filter" onClick={() => setFilterOpen(prevState => !prevState)}>
                        <img alt="" className="mr-2" src={require("../../assets/img/icon/filter.svg")} />{t("FILTER")}
                    </div>
                </Row>
                <Collapse isOpen={isFilterOpen}>

                </Collapse> */}
                <Row className="filter-panel-body p-t-15">
                    <Col xl={11}>
                        <FormBuilder fields={_FORM_FIELDS} formRef={_filterFormRef} onSubmit={filterOnSubmit}
                            reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />
                    </Col>
                    <Col xl={1} className="btn-list d-flex">
                        <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                    </Col>
                </Row>
            </div>
            <div className="flex-grow-1" ref={wrapperRef}>
                <ReactTable
                    ref={_tableRef}
                    tableMinHeight={fixedContentHeight}
                    columns={_COLUMNS}
                    url={ApiUrl._API_RETRIEVE_COMPANY_SERVICE_REQUEST}
                    filterFormfield={filtersForm}
                    getRowProps={(row) => {
                        return {
                            ...doubleClickNavigate(_history, WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(':companyId', row.original?.company?.id)),
                            onClick: (e) => {
                                setSelectedRow(row.id);
                            },
                            class: row.id == selectedRow && "table-row-selected"
                        }
                    }}
                    tableColumnPreference={[userId, TableId._SERVICE_REQUEST_LIST]}
                />
            </div>
        </div>
    )
}