import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { StripeJsKey } from './util/Constant';
import './localization/i18n.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/site.scss';
import './scss/react.scss';
import 'react-phone-input-2/lib/style.css'
import 'react-responsive-modal/styles.css';

//js
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { isLoggedInState } from 'recoil/Atoms.js';
import { Config, ConfigEnum } from 'util/Config.js';

// ========================================
const stripePromise = loadStripe(StripeJsKey._PUBLISHABLE_KEY);

const atomsByKey = {
    [ConfigEnum._IS_LOGGED_IN]: isLoggedInState,
};

const initializeState = ({ set }) => {
    let configInstance = Config.getInstance();
    set(atomsByKey[ConfigEnum._IS_LOGGED_IN], configInstance.getValue(ConfigEnum._IS_LOGGED_IN));
}

ReactDOM.render(
    <RecoilRoot initializeState={initializeState}>
        <Elements stripe={stripePromise}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Elements>
    </RecoilRoot>,
    document.getElementById('root')
);