import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { InputTypes, ApiKey, RoleType, SweetAlert, SessionKey, BtnTypes, LoadingStateText, AttachmentStatus, ApiUrl, FileType, DateFormat, AttachmentType } from "util/Constant";
import { Row, Col, Card, CardBody, CardHeader, CardFooter, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { AccountantDao, CommonDao } from 'data';
import { Confirm, Block, Notify, Report } from "notiflix";
import { isEmpty, uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import InputHoc from "components/form/InputHoc";
import ButtonRound from "components/buttons/ButtonRound";
import ReactTable from "components/react-table/ReactTable";
import BrandModal from "components/modals/BrandModal";
import useSWR from "swr";
import DocumentViewer from "components/document-viewer/DocumentViewer";
import moment from "moment";
import ImageModal from "components/modals/ImageModal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Sze Hua
/// </summary>
const AccountantAttachment = props => {
    const { certificateAttachment, identificationAttachment, pendingAttachment, userId, getUser } = props;
    const { t } = useTranslation();
    const [historyModal, setHistoryModal] = useState(false);
    const latestCertFile = useMemo(() => {
        if (!isEmpty(certificateAttachment)) {
            let tempLatestFile = certificateAttachment.fileMeta.find(f => f.id === certificateAttachment.lastUploadedFileMetaId);
            if (tempLatestFile?.accountantVerifications?.verificationStatus === AttachmentStatus._REJECTED) {
                let currentFile = certificateAttachment.fileMeta.find(f => f.id === certificateAttachment.currentVersionFileMetaId);
                return currentFile;
            }
            return tempLatestFile;
        }
        return null;
    }, [certificateAttachment?.currentVersionFileMetaId, certificateAttachment?.lastUploadedFileMetaId]);
    const latestIdFile = useMemo(() => {
        if (!isEmpty(identificationAttachment)) {
            let tempLatestFile = identificationAttachment.fileMeta.find(f => f.id === identificationAttachment.lastUploadedFileMetaId);
            if (tempLatestFile?.accountantVerifications?.verificationStatus === AttachmentStatus._REJECTED) {
                let currentFile = identificationAttachment.fileMeta.find(f => f.id === identificationAttachment.currentVersionFileMetaId);
                return currentFile;
            }
            return tempLatestFile;
        }
        return null;
    }, [identificationAttachment?.currentVersionFileMetaId, identificationAttachment?.lastUploadedFileMetaId]);

    const _CERT_FORM = useForm();
    const { formState: { isDirty: certFormIsDirty } } = _CERT_FORM;
    const _ID_FORM = useForm();
    const { formState: { isDirty: idFormIsDirty } } = _ID_FORM;
    const accountantDao = useMemo(() => new AccountantDao());

    const toggleHistoryModal = () => setHistoryModal(prev => !prev);

    const submitCertAttachment = (data) => {
        if (certFormIsDirty) {
            Confirm.Show(t('notiflix:CONFIRMATION'), `${t('notiflix:ARE_YOU_SURE_UPDATE')} <b>${t('CERTIFICATE_DOCUMENT')}</b>?`, t('notiflix:YES'), t('notiflix:NO'), async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                let firstFile = data?.certificationAttachment?.[0];
                let attachmentId = certificateAttachment?.id;
                await accountantDao.updateCertificateAttachment(userId, attachmentId, firstFile).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                        getUser();
                    }
                    else {
                        let errors = jsonResponse[ApiKey._API_ERROR_KEY];
                        if (errors) {
                            let firstError = errors[0];
                            if (firstError) {
                                Report.Warning(firstError?.title, firstError?.detail);
                            }
                        }
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            });
        }
        else {
            Notify.Warning(t('notiflix:PLEASE_UPLOAD_FILE_AND_CLICK'));
        }
    }
    const submitIdAttachment = (data) => {
        if (idFormIsDirty) {
            Confirm.Show(t('notiflix:CONFIRMATION'), `${t('notiflix:ARE_YOU_SURE_UPDATE')} <b>${t('IDENTIFICATION_CARD')}</b>?`, t('notiflix:YES'), t('notiflix:NO'), async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                let firstFile = data?.identificationAttachment?.[0];
                let attachmentId = identificationAttachment?.id;
                await accountantDao.updateIdentificationAttachment(userId, attachmentId, firstFile).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                        getUser();
                    }
                    else {
                        let errors = jsonResponse[ApiKey._API_ERROR_KEY];
                        if (errors) {
                            let firstError = errors[0];
                            if (firstError) {
                                Report.Warning(firstError?.title, firstError?.detail);
                            }
                        }
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            });
        }
        else {
            Notify.Warning(t('notiflix:PLEASE_UPLOAD_FILE_AND_CLICK'));
        }
    }

    // const renderIcon = (status) => {
    //     let iconClass;
    //     let iconId = uniqueId(`status-${status}-`);
    //     let tooltipMsg;
    //     if (status === AttachmentStatus._APPROVED) {
    //         iconClass = "fa-check-circle text-green";
    //         tooltipMsg = "Approved";
    //     }
    //     else if (status === AttachmentStatus._REJECTED) {
    //         iconClass = "fa-times-circle text-red";
    //         tooltipMsg = "Rejected";
    //     }
    //     else if (status === AttachmentStatus._PENDING) {
    //         iconClass = "fa-info-circle text-warning";
    //         tooltipMsg = "Pending";
    //     }

    //     return <>
    //         <i id={iconId} className={`fas float-left m-t-1 mr-1 ${iconClass}`}></i>
    //         <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={iconId}>{tooltipMsg}</UncontrolledTooltip>
    //     </>;
    // }

    useEffect(() => {
        if (!isEmpty(latestCertFile)) {
            _CERT_FORM.reset({ certificationAttachment: [latestCertFile] })
        }

    }, [latestCertFile]);

    useEffect(() => {
        if (!isEmpty(latestIdFile)) {
            _ID_FORM.reset({ identificationAttachment: [latestIdFile] })
        }

    }, [latestIdFile]);

    return <div id="accountant-attachment">
        <div className="d-flex m-t-30 mb-3" style={{ alignItems: "end" }}>
            <img className="mr-2" src={require("../../assets/img/icon/change-password.svg")} /><h4 className="font-weight-bold">{t('ATTACHMENT')}</h4>
            <ButtonRound className="btn-sm btn-themed ml-auto" type={BtnTypes.VIEW_HISTORY} onClick={toggleHistoryModal} />
        </div>
        <Row>
            <Col className="d-flex">
                <form onSubmit={_CERT_FORM.handleSubmit(submitCertAttachment)} className="d-flex flex-column w-100">
                    <InputHoc name="certificationAttachment"
                        error={_CERT_FORM.errors?.certificationAttachment}
                        label={t('CERTIFICATE_DOCUMENT')}
                        inputType={InputTypes.FILEUPLOAD}
                        control={_CERT_FORM.control} rules={{ required: 'Certificate Document is required.' }}
                        accept={{ image: true, pdf: true }}
                        disabled={typeof pendingAttachment?.certificate === 'boolean' && pendingAttachment?.certificate}
                        miscLabel={!certFormIsDirty && (typeof pendingAttachment?.certificate === 'boolean' && pendingAttachment?.certificate ? t('PENDING_APPROVAL') : t('APPROVED'))}
                        miscLabelClass={classNames({
                            "text-warning": (typeof pendingAttachment?.certificate === 'boolean' && pendingAttachment?.certificate && !certFormIsDirty)
                        })}
                        className={classNames("file-upload-border-white", {
                            "file-upload-border-orange": (typeof pendingAttachment?.certificate === 'boolean' && pendingAttachment?.certificate && !certFormIsDirty)
                        })}
                    // editable={typeof pendingAttachment?.certificate === 'boolean' && !pendingAttachment?.certificate}
                    />
                    {
                        typeof pendingAttachment?.certificate === 'boolean' && !pendingAttachment?.certificate &&
                        <button type="submit" className={classnames("btn btn-themed w-100 p-10 m-t-auto")}>{t('UPDATE')}</button>
                    }
                </form>
            </Col>
            <Col className="d-flex">
                <form onSubmit={_ID_FORM.handleSubmit(submitIdAttachment)} className="d-flex flex-column w-100">
                    <InputHoc name="identificationAttachment"
                        error={_ID_FORM.errors?.identificationAttachment}
                        label={t('IDENTIFICATION_CARD')}
                        inputType={InputTypes.FILEUPLOAD}
                        control={_ID_FORM.control} rules={{ required: 'Identification Card is required.' }}
                        accept={{ image: true, pdf: true }}
                        disabled={typeof pendingAttachment?.identification === 'boolean' && pendingAttachment?.identification}
                        miscLabel={!idFormIsDirty && (typeof pendingAttachment?.identification === 'boolean' && pendingAttachment?.identification ? t('PENDING_APPROVAL') : t('APPROVED'))}
                        miscLabelClass={classNames({
                            "text-warning": (typeof pendingAttachment?.identification === 'boolean' && pendingAttachment?.identification && !idFormIsDirty)
                        })}
                        className={classNames("file-upload-border-white", {
                            "file-upload-border-orange": (typeof pendingAttachment?.identification === 'boolean' && pendingAttachment?.identification && !idFormIsDirty)
                        })}
                    // editable={typeof pendingAttachment?.identification === 'boolean' && !pendingAttachment?.identification}
                    />
                    {
                        typeof pendingAttachment?.identification === 'boolean' && !pendingAttachment?.identification &&
                        <button type="submit" className={classnames("btn btn-themed w-100 p-10 m-t-auto")}>{t('UPDATE')}</button>
                    }
                </form>
            </Col>
        </Row>
        <hr />
        <AttachmentHistoryModal showModal={historyModal} toggler={toggleHistoryModal} userId={userId} />
    </div>
}

const AttachmentHistoryModal = props => {
    const { showModal, toggler, userId } = props;
    const { t } = useTranslation();
    const [isViewing, setIsViewing] = useState();
    const [file, setFile] = useState();
    const [showImageModal, setShowImageModal] = useState(false);

    const _COLUMN = useMemo(() => [
        {
            Header: "NAME",
            accessor: el => el.fileMeta ? `${el.fileMeta?.filename}${el.fileMeta?.extension}` : "",
            style: { width: "15%", whiteSpace: 'pre-line', wordBreak: 'break-word' }
        },
        {
            Header: "TYPE",
            accessor: el => {
                let result;
                if (el.fileTypeAttachmentId === AttachmentType._CERTIFICATE) {
                    result = "Certificate";
                }
                else if (el.fileTypeAttachmentId === AttachmentType._IDENTIFICATION) {
                    result = "Identification"
                }
                return result;
            },
            Cell: ({ row, value }) => {
                let iconClass;

                if (row.original.fileTypeAttachmentId === AttachmentType._CERTIFICATE) {
                    iconClass = "la-stamp";
                }
                else if (row.original.fileTypeAttachmentId === AttachmentType._IDENTIFICATION) {
                    iconClass = "la-id-card"
                }

                return <div className="d-flex flex-align-center">
                    <i className={classNames("mr-1 las", iconClass)} style={{ fontSize: '1.35rem' }}></i><span className={"badge badge-light"} >{value}</span>
                </div>
            },
        },
        {
            Header: "ATTACHMENT",
            Cell: ({ row }) => {
                let fileMeta = row.original?.fileMeta;
                return <>
                    <Link className="brand-anchor mr-1"
                        to={fileMeta?.requestPath}
                        target={"_blank"} download>
                        <ButtonRound className="btn-sm btn-themed" type={BtnTypes.FILE_DOWNLOAD} />
                    </Link>
                    {
                        fileMeta?.extension == FileType._PDF &&
                        <ButtonRound className="btn-sm btn-themed" type={BtnTypes.VIEW} onClick={() => setViewFile(fileMeta?.requestPath)} />
                    }
                    {
                        !(Object.values(FileType).includes(fileMeta?.extension)) &&
                        <ButtonRound className="btn-sm btn-themed" type={BtnTypes.VIEW} onClick={() => { setFile(fileMeta?.requestPath); toggleImageModal(); }} />
                    }
                </>
            },
            width: "1%",
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: "STATUS",
            accessor: "verificationStatus",
            Cell: ({ value }) => {
                let result;
                if (value === AttachmentStatus._APPROVED) {
                    result = <span className="badge badge-success">{t('APPROVED')}</span>
                }
                else if (value === AttachmentStatus._PENDING) {
                    result = <span className="badge badge-warning">{t('PENDING')}</span>
                }
                else if (value === AttachmentStatus._REJECTED) {
                    result = <span className="badge badge-danger">{t('REJECTED')}</span>
                }
                return result;
            },
            width: "1%",
            disableFilters: true,
        },
        {
            Header: "UPLOAD_DATE",
            accessor: el => el.fileMeta?.createdDate ? moment(el.fileMeta.createdDate).format(DateFormat._DATE_FORMAT) : "",
            width: "1%",
        },
        {
            Header: "REMARK",
            accessor: el => el?.remark ?? "N/A",
            style: { width: "50%", whiteSpace: 'pre-line', wordBreak: 'break-word' }
        }
    ], []);

    const setViewFile = async (filePathString) => {
        setFile(filePathString);
        setIsViewing(true);
    }

    const toggleImageModal = () => setShowImageModal(prev => !prev);

    return <>
        <BrandModal
            isOpen={showModal}
            toggler={toggler}
            title={t('ATTACHMENT_HISTORY')}
            customBody
        >
            <ModalBody className="p-0">
                {
                    <ReactTable
                        columns={_COLUMN}
                        url={ApiUrl._API_GET_HISTORY_ATTACHMENTS_LIST.replace(":userId", userId)}
                        setPagination
                        initialPageSize={5}
                        pageFooterClass={"page-footer-white"}
                    />
                }
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-end">
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={toggler}>{t('CLOSE')}</button>
            </ModalFooter>
            <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={file} style={{ borderRadius: '2rem', overflow: 'hidden' }} />
            <ImageModal showImageModal={showImageModal} toggle={toggleImageModal} attachmentUrl={file} style={{ borderRadius: '2rem' }} />
        </BrandModal>
    </>
}

export default AccountantAttachment;
