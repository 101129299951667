import React, { useRef, useEffect, useState } from 'react';
import { InputTypes, ApiKey, LoadingStateText } from 'util/Constant';
import { useForm } from 'react-hook-form';
import FactSheetDao from '../../../data/FactSheetDao';
import InputHoc from '../../../components/form/InputHoc';
import { Report, Confirm, Notify, Block } from 'notiflix';
import { stringIsNullOrEmpty } from '../../../util/Utility';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author : Andrew
/// </summary>
const FactSheetForm = (props) => {
    const { factSheetId, selectedGroupId, ...rest } = props;
    const { register, control, handleSubmit, errors, reset, trigger, setError, setValue, getValues, watch } = useForm();
    const formRef = useRef();

    const { t } = useTranslation();
    const [groupList, setGroupList] = useState([]);
    const [itemList, setItemList] = useState([]);

    let dao = new FactSheetDao();

    useEffect(() => {
        getGroupList();
    }, []);

    useEffect(() => {
        if (selectedGroupId) {
            getItemList(factSheetId, selectedGroupId);
        } else {
            return;
        }
    }, [selectedGroupId]);

    const getGroupList = async () => {
        await dao.getGroupList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setGroupList(response[ApiKey._API_DATA_KEY].map(({ name, id }) => {
                    return {
                        label: name,
                        value: id,
                    }
                }));
                setValue("factSheetItemGroupId", response[ApiKey._API_DATA_KEY][0].id);
            }
        });
    }

    const getItemList = async (factSheetId, categoryId) => {
        await dao.getItemList(factSheetId, categoryId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setItemList(response[ApiKey._API_DATA_KEY]);
            }
        })
    }

    const createOrUpdateItem = async (item) => {
        await dao.createOrUpdateItem(item).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                getItemList(factSheetId, selectedGroupId);
                Notify.Success(t('notiflix:ITEM_SAVED'));
            }
        });
    }

    const createOrUpdateGroupItems = async (item) => {
        await dao.createOrUpdateItemByGroup(itemList).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                let temp = response[ApiKey._API_DATA_KEY];
                if (temp !== undefined)
                    setItemList(response[ApiKey._API_DATA_KEY]);
                Notify.Success(t('notiflix:ALL_ITEM_SAVED'));
            }
        })
    }

    const deleteItem = (item, index) => {
        Confirm.Show(
            t('notiflix:DELETE_CONFIRMATION'),
            `${t('notiflix:ARE_YOU_SURE_YOU_WANT_TO_DELETE')}?`,
            t('notiflix:YES'),
            t('notiflix:NO'),
            function () {
                if (item.id) {
                    Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                    dao.deleteItem(item.id).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            setItemList(itemList.filter(i => i.id != item.id));
                            Notify.Success(t('notiflix:ITEM_DELETED'));
                        }
                    }).finally(() => Block.Remove(".notiflix-confirm-content"));
                } else {
                    itemList.splice(index, 1);
                    setItemList([...itemList]);
                }
            }, "", { titleColor: '#000000', okButtonBackground: '#f64e60' }
        );
    }

    const addNewItem = () => {
        itemList.push({
            text: "",
            factSheetId: factSheetId,
            factSheetItemGroupId: selectedGroupId,
        });
        setItemList([...itemList]);
    }

    const onItemTextChange = (e, index) => {
        itemList[index].text = e.target.value;
        setItemList([...itemList]);
    }

    return (
        <>
            {
                selectedGroupId ?
                    <form ref={formRef} onSubmit={handleSubmit(createOrUpdateGroupItems)} control={control} autoComplete='off'>
                        <div className="panel-body ">
                            {
                                itemList.map((item, index) => {
                                    return (
                                        <div className="d-flex" key={item.id}>
                                            <div className="flex-grow-1 mr-3">
                                                <InputHoc name={`text[${index}]`} label={t("DETAIL")} rules={{ required: 'Detail Required' }}
                                                    defaultValue={item.text} onChange={(e) => { onItemTextChange(e, index) }} control={control} />
                                            </div>
                                            <div className="btn-list d-flex align-items-center">
                                                <div className="btn btn-rounded btn-themed btn-flat-icon" onClick={() => { !stringIsNullOrEmpty(item?.text.trim()) && createOrUpdateItem(item) }}><i className="las la-check"></i></div>
                                                <div className="btn btn-round btn-red btn-flat-icon" onClick={() => deleteItem(item, index)}><i className="las la-trash-alt"></i></div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="btn-list flex-center panel-body">
                            <button type="button" className="btn btn-themed btn-min-width" onClick={addNewItem}>{t("ADD_ITEM")}</button>
                            <button type="submit" className="btn btn-themed btn-min-width">{t("SAVE")}</button>
                        </div>
                    </form> :
                    <div className="empty-state-wrapper">
                        <img src={require("../../../assets/img/ui/search-graphic.svg")} />
                        <p>{t("systemConfiguration:SELECT_A_GROUP_TO_VIEW_INFO")}</p>
                    </div>
            }
        </>
    );
};

export default FactSheetForm;