import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import Notiflix, { Block } from "notiflix";
import { InputTypes, SelectField, WebUrl, ApiKey, ApiUrl, deleteConfirm, BtnTypes, LoadingStateText } from "util/Constant";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { useHistory } from "react-router";
import CurrencyDao from "data/CurrencyDao.js";
import { stringIsNullOrEmpty } from "util/Utility.js";
import ButtonRound from "components/buttons/ButtonRound";
import { isEmpty } from 'lodash';
import BrandModal from "components/modals/BrandModal";
import { useTranslation } from 'react-i18next';
import { currenciesState } from 'recoil/Atoms.js';
import { useSetRecoilState } from 'recoil';

///<summary>
///Author: Ong Sze Hua
///</summary>
const CurrencyManagement = props => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [currency, setCurrency] = useState();
    const [currencyData, setCurrencyData] = useState([]);
    const setCurrenciesState = useSetRecoilState(currenciesState);
    const formRef = useRef();
    const _history = useHistory();

    const _FIELDS = [
        {
            rowOptions: { xl: 1 },
            columns: [
                { label: t('systemConfiguration:CURRENCY_NAME'), name: "name", input: InputTypes.INPUT, rules: { required: 'Currency Name is required' }, defaultValue: currency && currency.name }
            ]
        },
        {
            rowOptions: { xl: 1 },
            columns: [
                { label: t('systemConfiguration:ABBREVIATION_CODE'), name: "code", input: InputTypes.INPUT, rules: { required: 'Abbreviation Code is required' }, defaultValue: currency && currency.code }
            ]
        },
        {
            rowOptions: { xl: 1 },
            columns: [
                { label: t('systemConfiguration:SYMBOL'), name: "symbol", input: InputTypes.INPUT, rules: { required: 'Symbol is required' }, defaultValue: currency && currency.symbol }
            ]
        }
    ];

    const _COLUMN = useMemo(() => [
        {
            id: "Currency Name",
            Header: "systemConfiguration:CURRENCY_NAME",
            accessor: "name"
        },
        {
            id: "Abbreviation Code",
            Header: "systemConfiguration:ABBREVIATION_CODE",
            accessor: "code"
        },
        {
            id: "Symbol",
            Header: "systemConfiguration:SYMBOL",
            accessor: "symbol"
        },
        {
            id: "Exchange Rate (Base/Quote)",
            Header: "systemConfiguration:EXCHANGE_RATE_BQ",
            accessor: "exchangeRate.rate",
            Cell: ({ row }) => <>
                {
                    (row.original.exchangeRate && !isEmpty(row.original.exchangeRate)) &&
                    `${row.original.exchangeRate.fromCurrency}/${row.original.code} : ${row.original.exchangeRate.rate}`
                }
            </>
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => (<div className="btn-list-wrapper d-flex">
                <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => _history.push(WebUrl._CURRENCY_RATIO_CHART, { currencyId: row.original.id })} />
                <ButtonRound type={BtnTypes.EDIT} medium onClick={() => { toggle(); setCurrency(row.original) }} />
                <ButtonRound type={BtnTypes.DELETE} medium onClick={() => {
                    Notiflix.Confirm.Show(t('DELETE_CONFIRMATION'), t('ARE_YOU_SURE_TO_DELETE'), t('YES'), t('NO'),
                        function () {//Yes
                            deleteCurrency(row.original.id);
                        }, '', deleteConfirm)
                }} />
            </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [currencyData])

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(!modal); setCurrency(null) };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function getCurrencyList() {
        Notiflix.Block.Circle("#currencyList", LoadingStateText._PLEASE_WAIT);
        let dao = new CurrencyDao();
        (async () => {
            await dao.getCurrencyList().then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Block.Remove("#currencyList");
                    let result = responseJson[ApiKey._API_DATA_KEY];
                    setCurrencyData(result);
                    let temp = result.map(({ id, name }) => ({ label: name, value: id }));
                    setCurrenciesState(temp);
                }
            })
        })();
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let params = {
            "name": data.name,
            "code": data.code,
            "symbol": data.symbol
        };
        let msg = "";

        if (!stringIsNullOrEmpty(currency)) {
            params["id"] = currency.id;
            msg = t("notiflix:SUCCESS_UPDATE_CURRENCY");
        }
        else {
            msg = t("notiflix:SUCCESS_CREATE_CURRENCY");
        }

        let dao = new CurrencyDao();

        (async () => {
            await dao.createOrEditCurrency(params).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    getCurrencyList();
                    Notiflix.Notify.Success(msg);
                }
                toggle();
            }).finally(() => Block.Remove(".modal-content"));
        })();
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function deleteCurrency(id) {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        let dao = new CurrencyDao();
        (async () => {
            await dao.deleteCurrency(id).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    getCurrencyList();
                }
            }).finally(() => Block.Remove(".notiflix-confirm-content"));
        })();
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        getCurrencyList();
    }, []);

    return (<>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("CURRENCY_MANAGEMENT")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2">
                <div className="panel-body-inner-content with-br-radius h-auto">
                    <div className="d-flex flex-column">
                        <div className="filter-panel-container">
                            <Row className="filter-panel-body p-15">
                                <Col xl={12}>
                                    <button type="button" className="btn btn-min-width btn-themed" onClick={toggle} ><i className="las la-plus mr-1"></i>{t("systemConfiguration:ADD_CURRENCY")}</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1">
                            <ReactTable columns={_COLUMN} data={currencyData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={currency ? t("systemConfiguration:EDIT_CURRENCY") : t("systemConfiguration:ADD_CURRENCY")}
            customBody
            size="md"
        >
            <ModalBody>
                <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={formRef} />
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-themed btn-min-width" onClick={() => { submitForm(formRef) }}>
                    {t("SAVE")}
                </button>
            </ModalFooter>
        </BrandModal>
    </>)
}

export default CurrencyManagement;
