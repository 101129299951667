import React, { useState, useEffect, useContext } from "react";
import { PageSettings } from '../../config/page-settings.js';
import { Link, useHistory } from "react-router-dom";
import { ApiKey, ApiUrl, WebUrl } from "../../../src/util/Constant"
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import DataAccessObject from "data/DataAccessObject.js";

/// <summary>
/// Author: Lewis
/// </summary>
const RegisterCompletePage = () => {
    const _context = useContext(PageSettings);
    const _history = useHistory();

    const { t, i18n } = useTranslation();

    const { data: languageList } = useSWR(
        ApiUrl._API_GET_LANGUAGE_LIST,
        async (url) => {
            let dao = new DataAccessObject();
            let tempList = [];
            await dao.get(url).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    tempList = jsonResponse[ApiKey._API_DATA_KEY];
                }
            });
            return tempList;
        }
    );

    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);
        return (() => {
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
        });
    }, []);

    return (
        <div className="container" style={{ paddingTop: "10rem" }} id="registrationComplete">
            <Card className="card-login panel-brand">
                <CardBody>
                    <div className="flex-center" style={{ height: '400px', padding: '0 6rem', margin: '3rem 0' }}>
                        <div className="d-lg-block d-md-none">
                            <img src={require('../../assets/img/ui/register-success.png')} style={{ maxWidth: '400px', marginRight: '4rem' }} alt="Register Complete" />
                        </div>
                        <div style={{ flex: 1 }}>
                            <h1 className="title f-w-700 mb-4">{t('REGISTRATION_COMPLETE')}</h1>
                            <p style={{ marginBottom: "3rem", maxWidth: '450px' }}>
                                {t('THANK_YOU_CHOOSING_YICOM')}!<br></br>
                                {t('REVIEW_PROCESS_MAY_TAKE_AWHILE')}
                            </p>
                            <button onClick={() => { _history.push(WebUrl._LOGIN) }} className="btn btn-themed btn-min-width">{t('GO_TO_LOGIN')}</button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <div className="d-flex align-items-center p-l-15 p-t-10 mb-auto">
                <span>{t('LANGUAGE')}</span>
                <div className="d-flex">
                    {languageList && (
                        languageList.map(data => (
                            <div className={"pointer-cursor countries language-" + data.code} onClick={() => { i18n.changeLanguage(data.code) }} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegisterCompletePage;