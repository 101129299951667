import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactTable from 'components/react-table/ReactTable';
import { ApiUrl, DateFormat, RoleType, InputTypes, Icon } from "util/Constant";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import moment from 'moment';
import { useResizeDetector } from "react-resize-detector";
import classnames from 'classnames';
import { PageSettings } from "config/page-settings";
import { useForm } from 'react-hook-form';
import InputHoc from 'components/form/InputHoc';
import { Can, UserAccessContext } from 'config/user-access'

/// <summary>
/// Author: Robin
/// </summary>
const PermissionListing = props => {
    const { permissions } = props;
    const _ref = useRef();
    const ability = useContext(UserAccessContext);
    const { isXlDevices } = useContext(PageSettings);
    const { ref: _tableWrapperRef } = useResizeDetector();
    const { register, control, handleSubmit, errors, reset, watch, setValue } = useForm();
    const today = useMemo(() => moment().toDate(), []);

    const _PERMISSION_COLUMN = useMemo(() => [
        {
            Header: '#',
            accessor: 'index',
            style: { width: "15px" },
            Cell: ({ row }) => {
                return (<>{row.index + 1}</>);
            }
        },
        {
            Header: 'systemConfiguration:PERMISSION',
            accessor: 'object',
            Cell: ({ row }) => {
                if (row.original.object) {
                    return (row.original.object);
                } else {
                    return (<> - </>);
                }
            }
        },
        {
            Header: 'ACTION',
            accessor: 'action',
            Cell: ({ row }) => {
                if (row.original.action) {
                    return (row.original.action);
                } else {
                    return (<> - </>);
                }
            }
        }
    ], []);

    const tableHeight = useMemo(() => {

        if (_tableWrapperRef.current != null) {
            return _tableWrapperRef.current.clientHeight;
        }

    }, [_tableWrapperRef?.current]);

    return (
        <div className={classnames('panel-body-inner-content with-br-radius', { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}>
            <div className="d-flex flex-column h-100">
                <div className="flex-grow-1" ref={_tableWrapperRef}>
                    <div style={{ height: tableHeight }}>
                        <ReactTable
                            stickyHeader
                            stickyFooter
                            columns={_PERMISSION_COLUMN}
                            data={ability.can('manage', 'all') ? [{
                                object: 'manage',
                                action: 'all'
                            }]
                                :
                                permissions}
                            setPagination
                            enableSearch
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PermissionListing;