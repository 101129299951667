import React, { useMemo, useState, useEffect, useRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { ApiKey, ApiUrl, BtnTypes, FileType, FinanceType, LoadingStateText, InputTypes, DateFormat, DefaultCurrency, DefaultCurrencyId, SweetAlert } from "util/Constant";
import { Can } from "config/user-access";
import { CompanyDao } from "data";
import moment from 'moment';
import { useRecoilState, useRecoilValue } from "recoil";
import { authCredentialState, userRolesSelector } from "../../recoil/Atoms";
import NumberFormat from 'react-number-format';
import ButtonRound from "components/buttons/ButtonRound";
import Notiflix, { Block, Notify } from "notiflix";
import { useForm } from "react-hook-form";
import InputHoc from 'components/form/InputHoc';
import { useHistory, useLocation } from "react-router";
import { UncontrolledTooltip, ModalBody, ModalFooter } from 'reactstrap';
import DocumentViewer from "components/document-viewer/DocumentViewer";
import { useTranslation } from "react-i18next";
import BrandModal from "components/modals/BrandModal";
import { stringIsNullOrEmpty } from "util/Utility";
import { useResizeDetector } from "react-resize-detector";
import { PolicyActionConstant, PolicyObjectConstant } from "../../util/Constant";


const FinancesListing = ({ companyId, ...rest }) => {

    const _history = useHistory();
    const { t, i18n } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const [invoiceModal, setInvoiceModal] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [viewModal, setViewModal] = useState(false);
    const [isViewing, setIsViewing] = useState(false);
    const [filePath, setFilePath] = useState({});
    const [refreshTable, setRefreshTable] = useState(false);
    const [cartItem, setCartItem] = useState({
        id: 0,
        attachmentType: "Default",
    });
    const invoiceRef = useRef();
    const [company, setCompany] = useState(null);
    const userRole = useRecoilValue(userRolesSelector);
    const { ref: tableWrapperRef } = useResizeDetector();

    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    const _IN_COLUMN = useMemo(() => [
        {
            id: "in",
            Header: <div className="d-flex justify-content-center">{t('IN')}</div>,
            Footer: ({ rows }) => {
                let completedTotal = calcInTotal('completed', rows);
                let incompleteTotal = calcInTotal('incomplete', rows);
                let total = completedTotal + incompleteTotal;
                return <>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT_COMPLETED')} :</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={completedTotal} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={completedTotal * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT_INCOMPLETE')}:</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={incompleteTotal} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={incompleteTotal * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT')}:</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={total} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={total * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                </>
            }
            ,
            columns: [
                {
                    id: "inUnitPrice",
                    Header: 'UNIT_PRICE',
                    accessor: "price",
                    Cell: ({ row }) => (<>
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                                row.original.price ?
                                <div className="d-flex flex-column">
                                    <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={row.original.price} displayType={'text'} thousandSeparator={true} />
                                    {
                                        row.original?.quoteCurrency?.id != DefaultCurrencyId && row.original?.quoteCurrency?.exchangeRate?.rate &&
                                        <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${row.original?.quoteCurrency?.code} `} value={row.original?.quoteCurrency?.quotePrice} displayType={'text'} thousandSeparator={true} />
                                    }
                                </div> : "-"
                        }
                    </>
                    ),
                    style: { overflow: "visible" }
                },
                // temporary remove discount
                // {
                //     Header: 'Discount',
                //     accessor: "discountRate",
                //     Cell: ({ row }) => (<>
                //         {
                //             row.original.product.productTypeId == FinanceType._INCOME &&
                //                 row.original.discountRate ? row.original.discountRate + " %" : "-"
                //         }
                //     </>
                //     ),
                //     style: { overflow: "hidden" }
                // },
                // {
                //     Header: 'Discount Amount',
                //     accessor: "discountAmount",
                //     Cell: ({ row }) => (<>
                //         {
                //             row.original.product.productTypeId == FinanceType._INCOME &&
                //                 row.original.discountAmount ? <><NumberFormat decimalScale={2} fixedDecimalScale value={row.original.discountAmount} displayType={'text'} thousandSeparator={true} /></> : "-"
                //         }
                //     </>
                //     ),
                //     style: { overflow: "hidden" }
                // },
                // {
                //     Header: 'Final Price',
                //     accessor: "finalPrice",
                //     Cell: ({ row }) => (<>
                //         {
                //             //chkFinancesInType(row.original.type) &&
                //             row.original.product.productTypeId == FinanceType._INCOME &&
                //                 row.original.finalPrice ? <div className="d-flex flex-column">
                //                 <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={row.original.finalPrice} displayType={'text'} thousandSeparator={true} />
                //                 {
                //                     row.original?.quoteCurrency?.id != DefaultCurrencyId && row.original?.quoteCurrency?.exchangeRate?.rate &&
                //                     <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${row.original?.quoteCurrency?.code} `} value={row.original.finalPrice * row.original?.quoteCurrency?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                //                 }
                //             </div> : "-"
                //         }
                //     </>
                //     ),
                //     style: { overflow: "visible" }
                // },
                {
                    id: "inInvoice",
                    Header: "INVOICE",
                    Cell: ({ row }) => (<div style={{ display: "flex" }}>
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                            (row.original?.invoiceAttachment?.id &&
                                <ButtonRound small className="mr-1" type={BtnTypes.VIEW} onClick={() => {
                                    if (row.original?.invoiceAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                        Notiflix.Notify.Warning(".doc & .docx " + t("notiflix:ARE_NOT_VIEWABLE") + t("notiflix:DOWNLOAD_TO_VIEW"));
                                    } else {
                                        setIsViewing(!isViewing);
                                        setCartItem({ id: row.original.id, attachmentType: "Invoice" });
                                        setFilePath(row.original?.invoiceAttachment?.fileMeta[0]?.requestPath);
                                    }
                                }} />
                            )
                        }
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                            (row.original.confirmBy ?
                                row.original?.invoiceAttachment &&
                                <>
                                    <a className="btn-sm btn-round btn-themed mb-1" target="_blank" id="download_income_invoice"
                                        href={row.original?.invoiceAttachment?.fileMeta[0]?.requestPath} download >
                                        <i class="las la-file-download"></i>
                                    </a>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_income_invoice">{t('DOWNLOAD')}</UncontrolledTooltip>
                                </>
                                :
                                <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                                    {
                                        (allowed) => allowed ?
			                                 <ButtonRound small className="btn-themed" type={BtnTypes.FILE_UPLOAD} title={t('UPLOAD')} onClick={() => {
			                                     setCartItem({ id: row.original.id, attachmentType: "Invoice" });
			                                     setInvoiceModal(!invoiceModal);
			                                 }} />
                                            :
                                        <> - </>
                                    }
                                </Can>
                            )
                        }
                    </div>
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    style: { overflow: "visible" }
                },
                {
                    id: 'inReceipt',
                    Header: "RECEIPT",
                    Cell: ({ row }) => (<div style={{ display: "flex" }}>
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                            (row.original?.receiptAttachment?.id &&
                                <>
                                    <ButtonRound type={BtnTypes.VIEW} className="mr-1" small onClick={() => {
                                        if (row.original?.receiptAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                            Notiflix.Notify.Warning(".doc & .docx " + t("notiflix:ARE_NOT_VIEWABLE") + t("notiflix:DOWNLOAD_TO_VIEW"));
                                        } else {
                                            setIsViewing(!isViewing);
                                            setCartItem({ id: row.original.id, attachmentType: "Receipt" });
                                            setFilePath(row.original?.receiptAttachment?.fileMeta[0]?.requestPath);
                                        }
                                    }} />
                                </>
                            )
                        }
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                            (row.original.confirmBy
                                ?
                                row.original?.receiptAttachment &&
                                <>
                                    <a className="btn-sm btn-round btn-themed mb-1" id="download_income_receipt" target="_blank"
                                        href={row.original?.receiptAttachment?.fileMeta[0]?.requestPath} download >
                                        <i class="las la-file-download"></i>
                                    </a>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_income_receipt">{t('DOWNLOAD')}</UncontrolledTooltip>
                                </>
                                :
                                <>
                                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                                        {
                                            (allowed) => allowed ?
			                                     <ButtonRound type={BtnTypes.FILE_UPLOAD} title={t('UPLOAD')} small onClick={() => {
			                                         setCartItem({ id: row.original.id, attachmentType: "Receipt" });
			                                         setInvoiceModal(!invoiceModal);
			                                     }} />
                                                :
                                                <> - </>
                                        }
                                    </Can>
                                </>
                            )
                        }
                    </div>
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    style: { overflow: "visible", width: 500 }
                },
                {
                    id: "inConfirmation",
                    Header: 'CONFIRMATION',
                    Cell: ({ row }) => (<>
                        {
                            row.original.product.productTypeId == FinanceType._INCOME &&
                            (row.original.confirmBy
                                ?
                                (<span class="__status-text badge badge-success">{t('COMPLETED')}</span>)
                                :
                                <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                                    {
                                        allowed => allowed ? 
		                                    <button type="button" className="btn btn-xs btn-themed" style={{ width: "80%", padding: "1px" }}
		                                        onClick={() => {
		                                            if (row.original.invoiceAttachment != null || row.original.receiptAttachment != null) {
		                                                Notiflix.Confirm.Show(t('CONFIRMATION'), t('notiflix:DO_YOU_CONFIRM') + '<b>' + row.original?.productMatterName + '</b>' + t('notiflix:DOCUMENT_ARE_VALID') + '?', t('YES'), t('NO'),
		                                                    function () {//Yes
		                                                        let dao = new CompanyDao();
		                                                        dao.confirmPaymentReceiptOrInvoice(row.original.id).then((response) => {
		                                                            if (response[ApiKey._API_SUCCESS_KEY]) {
		                                                                Notiflix.Notify.Success(t('notiflix:UPLOAD_SUCCESS'));
		                                                                setRefreshTable(false);
		                                                                setRefreshTable(true);
		                                                            } else {
		                                                                Notiflix.Notify.Failure(SweetAlert._OPERATION_FAIL);
		                                                            }
		                                                        })
		                                                    })
		                                            }
		                                            else {
		                                                Notiflix.Report.Warning(t("notiflix:CONFIRMATION_WARNING"), t("notiflix:PLEASE_UPLOAD_INVOICE_OR_RECEIPT"), t("notiflix:OK"));
		                                            }
		                                        }}
		                                    >{t('CONFIRM')}</button>
                                            :
                                            <> - </>
                                    }
                                </Can>)
                        }
                    </>
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    style: { overflow: "visible" }
                }
            ],
            disableSortBy: true,
            disableFilters: true,
        },
    ], [currencyPreference]);

    const _OUT_COLUMN = useMemo(() => [
        {
            id: "out",
            Header: <div className="d-flex justify-content-center">{t('OUT')}</div>,
            Footer: ({ rows }) => {
                let completedTotal = calcOutTotal('completed', rows);
                let incompleteTotal = calcOutTotal('incomplete', rows);
                let total = completedTotal + incompleteTotal;
                return <>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT_COMPLETED')}:</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={completedTotal} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={completedTotal * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT_INCOMPLETE')}:</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={incompleteTotal} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={incompleteTotal * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                    <div className="footer-summary">
                        <span>{t('TOTAL_AMOUNT')}:</span>
                        <span>
                            <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={total} displayType={'text'} thousandSeparator={true} />
                            {
                                currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${currencyPreference?.code} `} value={total * currencyPreference?.exchangeRate?.rate} displayType={'text'} thousandSeparator={true} />
                            }
                        </span>
                    </div>
                </>
            },
            columns: [
                {
                    id: "outUnitPrice",
                    accessor: "price",
                    Header: 'UNIT_PRICE',
                    Cell: ({ row }) => (<>
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE ? (
                                row.original.price ? <div className="d-flex flex-column">
                                    <NumberFormat decimalScale={2} fixedDecimalScale prefix={`${defaultCurrencyCode} `} value={row.original.price} displayType={'text'} thousandSeparator={true} />
                                    {
                                        row.original?.quoteCurrency?.id != DefaultCurrencyId && row.original?.quoteCurrency?.exchangeRate?.rate &&
                                        <NumberFormat className="quote-currency" decimalScale={2} fixedDecimalScale prefix={` ≈ ${row.original?.quoteCurrency?.code} `} value={row.original?.quoteCurrency?.quotePrice} displayType={'text'} thousandSeparator={true} />
                                    }
                                </div>
                                    : (<InputHoc name={'unitPrice'} label="Unit Price" />))
                                : "-"
                        }
                    </>
                    ),
                    style: { overflow: "visible" }
                },
                {
                    id: "outInvoice",
                    Header: "INVOICE",
                    Cell: ({ row }) => (<div style={{ display: "flex" }}>
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE &&
                            (row.original?.invoiceAttachment?.id &&
                                <>
                                    <ButtonRound type={BtnTypes.VIEW} className="mr-1" small onClick={() => {
                                        if (row.original?.invoiceAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                            Notiflix.Notify.Warning(".doc & .docx " + t("notiflix:ARE_NOT_VIEWABLE") + t("notiflix:DOWNLOAD_TO_VIEW"));
                                        } else {
                                            setIsViewing(!isViewing);
                                            setCartItem({ id: row.original.id, attachmentType: "Invoice" });
                                            setFilePath(row.original?.invoiceAttachment?.fileMeta[0]?.requestPath);
                                        }

                                    }} />
                                </>
                            )
                        }
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE &&
                            (row.original.confirmBy
                                ?
                                row.original?.invoiceAttachment &&
                                <>
                                    <a className="btn-sm btn-themed btn-round mb-1" target="_blank" id="download_expense_invoice"
                                        href={row.original?.invoiceAttachment?.fileMeta[0]?.requestPath} download >
                                        <i class="las la-file-download"></i>
                                    </a>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_expense_invoice">{t('DOWNLOAD')}</UncontrolledTooltip>
                                </>
                                :
                                <>
                                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                                        {
                                            allowed => allowed ? 
			                                     <ButtonRound type={BtnTypes.FILE_UPLOAD} title={t('UPLOAD')} small onClick={() => {
			                                         setCartItem({ id: row.original.id, attachmentType: "Invoice" });
			                                         setInvoiceModal(!invoiceModal);
			                                     }} />
                                                :
                                            <> - </>
                                        }
                                    </Can>
                                </>

                            )
                        }
                    </div>
                    ),
                    disableSortBy: true,
                    disableFilters: true
                },
                {
                    Header: 'PROOF_OF_PAYMENT',
                    Cell: ({ row }) => (<div style={{ display: "flex" }}>
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE &&
                            (row.original?.receiptAttachment?.id &&
                                <>
                                    <ButtonRound type={BtnTypes.VIEW} className="mr-1" small onClick={() => {
                                        if (row.original?.receiptAttachment?.fileMeta[0]?.extension != FileType._PDF) {
                                            Notiflix.Notify.Warning(".doc & .docx " + t("notiflix:ARE_NOT_VIEWABLE") + t("notiflix:DOWNLOAD_TO_VIEW"));
                                        } else {
                                            setIsViewing(!isViewing);
                                            setCartItem({ id: row.original.id, attachmentType: "Receipt" });
                                            setFilePath(row.original?.receiptAttachment?.fileMeta[0]?.requestPath);
                                        }

                                    }} />

                                </>
                            )
                        }
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE &&
                            (row.original.confirmBy
                                ?
                                row.original?.receiptAttachment &&
                                <>
                                    <a className="btn-sm btn-round btn-themed mb-1" target="_blank" id="download_expense_receipt"
                                        href={row.original?.receiptAttachment?.fileMeta[0]?.requestPath} download>
                                        <i class="las la-file-download"></i>
                                    </a>
                                    <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_expense_receipt">{t('DOWNLOAD')}</UncontrolledTooltip>
                                </>
                                :
                                <>
                                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_portfolio_finance} passThrough>
                                        {
                                            allowed => allowed ? 
			                                    <ButtonRound type={BtnTypes.FILE_UPLOAD} title={t('UPLOAD')} small onClick={() => {
			                                        setCartItem({ id: row.original.id, attachmentType: "Receipt" });
			                                        setInvoiceModal(!invoiceModal);
			                                    }} />
                                                :
                                            <> - </>
                                        }
                                    </Can>
                                </>
                            )
                        }
                    </div>
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    style: { overflow: "visible" }
                },
                {
                    id: "outConfirmation",
                    Header: 'CONFIRMATION',
                    Cell: ({ row }) => (<>
                        {
                            row.original.product.productTypeId == FinanceType._EXPENSE && (
                                row.original.confirmBy
                                    ?
                                    (<span class="__status-text badge badge-success">{t('COMPLETED')}</span>)
                                    :
                                    (
                                        userRole?.isAdmin || userRole?.isSuperAdmin &&
                                        <button type="button" className="btn btn-themed" style={{ width: "80%", padding: "1px" }}
                                            onClick={() => {
                                                if (row.original.invoiceAttachment != null || row.original.receiptAttachment != null) {
                                                    Notiflix.Confirm.Show(t('Confirmation'), t('notiflix:DO_YOU_CONFIRM') + '<b>' + row.original.productMatterName + '</b>' + t('notiflix:DOCUMENT_ARE_VALID') + '?', t('YES'), t('NO'),
                                                        function () {//Yes
                                                            let dao = new CompanyDao();
                                                            dao.confirmPaymentReceiptOrInvoice(row.original.id).then((response) => {
                                                                if (response[ApiKey._API_SUCCESS_KEY]) {
                                                                    Notiflix.Notify.Success(t('notiflix:UPLOAD_SUCCESS'));
                                                                    setRefreshTable(false);
                                                                    setRefreshTable(true);
                                                                } else {
                                                                    Notiflix.Notify.Failure(SweetAlert._OPERATION_FAIL);
                                                                }
                                                            })
                                                        })
                                                } else {
                                                    Notiflix.Report.Warning(t("notiflix:CONFIRMATION_WARNING"), t("notiflix:PLEASE_UPLOAD_INVOICE_OR_RECEIPT"), t("notiflix:OK"));
                                                }
                                            }
                                            }>{t('CONFIRM')}</button>))
                        }
                    </>
                    ),
                    disableSortBy: true,
                    disableFilters: true,
                    style: { overflow: "visible" }
                }
            ],
            disableSortBy: true,
            disableFilters: true,
        }
    ], [currencyPreference]);

    const _COLUMN = useMemo(() => [
        {
            id: "info",
            Header: <div className="d-inline-block text-pill-brand">
                <strong className="f-s-16">
                    {company?.englishName}
                    {company?.chineseName && <>({company?.chineseName})</>}
                </strong>
                <div className="ci-number"><strong>CI {t("NUMBER_SHORT")}:</strong>{company?.corporateIdentityNumber}</div>
            </div>,
            Footer: "",
            columns: [
                {
                    Header: "DATE",
                    accessor: "date",
                    Cell: ({ row }) => (<>
                        <div>{row.original.date ? moment(row.original.date).format(DateFormat._DATE_ONLY) : "-"}</div>
                    </>)
                },
                {
                    Header: "MATTER",
                    accessor: "productMatterName",
                    Cell: ({ row }) => (<>
                        <div style={{ width: 150, whiteSpace: 'pre-line' }}>{row.original.productMatterName}</div>
                    </>
                    ),
                    style: { overflow: "visible" }
                },
            ],
            disableSortBy: true,
            disableFilters: true,
        },
        ..._IN_COLUMN,
        ..._OUT_COLUMN
    ], [company, _IN_COLUMN, _OUT_COLUMN])

    ///<summary>
    ///Author: Robin
    ///</summary>
    const getProfile = async (companyId) => {
        Block.Circle("div.company-finance", LoadingStateText._PLEASE_WAIT);
        let companyDao = new CompanyDao();
        await companyDao.getCompanyDetailsById(companyId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                let data = response[ApiKey._API_DATA_KEY];

                setCompany(data);
            }
        });

        Block.Remove("div.company-finance");
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function calcInTotal(category, rows = null) {
        var total = 0;
        if (rows !== null) {
            if (category === 'completed') {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._INCOME && row.original.confirmBy) {
                        total += row.original.finalPrice;
                    }
                })
            }
            else if (category === 'incomplete') {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._INCOME && !row.original.confirmBy) {
                        total += row.original.finalPrice;
                    }
                })
            }
            else {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._INCOME) {
                        total += row.original.finalPrice;
                    }
                })
            }
        }

        return total;
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function calcOutTotal(category, rows = null) {
        var total = 0;
        if (rows !== null) {
            if (category === 'completed') {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._EXPENSE && row.original.confirmBy) {
                        total += row.original.price
                    }
                })
            }
            else if (category === 'incomplete') {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._EXPENSE && !row.original.confirmBy) {
                        row.original.price &&
                            (total += row.original.price)
                    }
                })
            }
            else {
                rows.forEach(row => {
                    if (row.original.product.productTypeId == FinanceType._EXPENSE) {
                        row.original.price &&
                            (total += row.original.price)
                    }
                })
            }
        }
        return total;
    }

    const uploadFinanceInvoice = async (data) => {

        let postData = { id: cartItem.id, ...data };

        Notiflix.Confirm.Show(
            t('notiflix:UPLOAD_FILE'),
            t('notiflix:ARE_YOU_SURE') + t('notiflix:WANT_UPLOAD_FILE') + "?",
            t('notiflix:YES'),
            t('notiflix:NO'),
            async function () {
                let dao = new CompanyDao();
                await dao.postCompanyFinanceInvoiceDocument(companyId, postData).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        setInvoiceModal(!invoiceModal);
                        setRefreshTable(false);
                        setRefreshTable(true);
                    }
                })
            }
        )

    }

    const setToggle = async () => {
        setInvoiceModal(!invoiceModal);
    }

    const setViewToggle = async () => {
        setViewModal(!viewModal);
    }

    const onDocumentLoadSuccess = async ({ numPages }) => {
        setNumPages(numPages);
    }

    useEffect(() => {

        if (stringIsNullOrEmpty(companyId)) {
            _history.goBack();
        }

        getProfile(companyId);

    }, [companyId]);

    const fixedContentHeight = useMemo(() => {
        if (tableWrapperRef.current != null) {
            return tableWrapperRef.current.clientHeight;
        }
    }, [tableWrapperRef?.current]);

    return (
        <>
            <div className="d-flex flex-column h-100" ref={tableWrapperRef}>
                <div className="table-finance-wrapper flex-grow-1" style={{ height: fixedContentHeight }}>
                    <ReactTable
                        tbodyColSpan={13}
                        columns={_COLUMN}
                        setCustomFooters
                        url={companyId ? ApiUrl._API_GET_COMPANY_FINANCES_LIST.replace(":companyId", companyId) : null}
                        refreshTable={refreshTable}
                    />
                </div>
            </div>
            <BrandModal
                isOpen={invoiceModal}
                toggler={setToggle}
                title={cartItem.attachmentType == "Invoice" ? t("UPLOAD_INVOICE_FILE") : t("UPLOAD_RECEIPT_FILE")}
                customBody
            >
                <ModalBody>
                    <form onSubmit={handleSubmit(uploadFinanceInvoice)} ref={invoiceRef} autoComplete="off">
                        <InputHoc name={cartItem.attachmentType == "Invoice" ? "invoiceAttachment" : "receiptAttachment"} inputType={InputTypes.FILEUPLOAD} control={control} />
                    </form>
                </ModalBody>
                <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                    <button className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={setToggle}>{t("CANCEL")}</button>
                    <button className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(uploadFinanceInvoice)()} >{t("SAVE")}</button>
                </ModalFooter>
            </BrandModal>
            <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={filePath} />
        </>
    )
}

export default FinancesListing;