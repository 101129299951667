import React, { useState, useEffect, useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import classnames from 'classnames';
import { stringIsNullOrEmpty } from 'util/Utility';

/// <summary>
/// Author: Chris
/// </summary>
const Select = (props) => {
    const {
        error,
        options,
        isMulti,
        selectClasses,
        creatable,
        readOnly,
        onChange: propsOnChange,
        disabled,
        formatOptionLabel,
        placeholder,
        ...rest
    } = props;
    const { Option, SingleValue } = components;
    const CustomSelectOption = props => (
        <Option {...props}>
            {
                props?.data?.icon &&
                <><i className={props.data.icon} />&nbsp;</>
            }
            {props.data.label}
        </Option>
    )

    const CustomSelectValue = props => (
        <SingleValue {...props}>
            {
                props?.data?.icon &&
                <><i className={props.data.icon} />&nbsp;</>
            }
            {props.data.label}
        </SingleValue>
    )
    const [defaultValue, setDefaultValue] = useState(null);

    const GRAY_COLOR = '#dfe1e4';
    const THEME_COLOR = '#e6ac00';
    const RED_COLOR = '#f64e60';

    const _selectStyles = useMemo(() => (
        {
            container: (provided) => ({
                ...provided,
                marginBottom: '5px',
                paddingRight: 0,
                //height: isMulti && 'auto !important',
                paddingTop: '0 !important',
                paddingBottom: isMulti && '0 !important'
            }),
            control: (provided, state) => ({
                ...provided,
                backgroundColor: 'unset',
                border: 'none',
                borderRadius: 0,
                height: isMulti ? 'auto' : '36px',
                minHeight: isMulti ? '34px' : '30px',
                boxShadow: 0,
                borderWidth: (readOnly || disabled) ? 0 : 2,
                borderColor: error ? RED_COLOR : state.isFocused ? THEME_COLOR : 'unset',
                // marginTop: '-9px',
                '&:hover': {
                    borderColor: error ? RED_COLOR : state.isFocused ? THEME_COLOR : GRAY_COLOR,
                }
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: isMulti ? 'auto' : '36px',
                marginTop: isMulti && '5px',
                paddingLeft: 0,
                paddingTop: isMulti && '0',
                paddingBottom: 0,
                overflow: 'hidden',
                whiteSpace: 'noWrap',
                textOverflow: 'ellipsis',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: '25px',
                marginTop: '0.45rem',
                display: (readOnly || disabled) ? 'none' : 'flex'
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none'
            }),
            placeholder: (provided, state) => ({
                ...provided,
            }),
            singleValue: (provided) => ({
                ...provided,
                marginTop: isMulti ? '-5px' : '0px',
            }),
            input: (provided) => ({
                ...provided,
                lineHeight: 0,
                // transform: 'translateY(-25%)',
                // position: isMulti ? 'relative' : 'absolute',
                // top: '50%',
                // marginTop: isMulti ? '-25px' : '-10px',
                // height: isMulti && '14px',
            }),
            menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999
            }),
            multiValueRemove: (provided) => ({
                ...provided,
                display: (readOnly || disabled) ? 'none' : 'flex',
                '&:hover': {
                    background: 'radial-gradient(ellipse at top, #FDF0D8, #FFECBF, #F2D595, #E3BA5F)',
                    color: '#fff',
                    borderRadius: '50px'
                }
            }),
            multiValue: (provided) => ({
                ...provided,
                backgroundColor: "#F2F3F5",
                borderRadius: "50px",
                margin: '0 .3rem',
                fontSize: '80%'
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                color: "#D1A030",
                paddingRight: '6px',
                paddingLeft: '6px',
                fontSize: '100%'
            })
        }), [isMulti, readOnly, disabled, error]);

    const [toggleClass, setToggleClass] = useState();

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        setToggleClass(props.defaultValue);
        setDefaultValue(props.defaultValue);
    }, [props.defaultValue]);

    return (
        <Controller
            {...rest}
            render={
                ({ onChange, value, placeholder, ...otherProps }) => {
                    return (<>
                        <ReactSelect
                            className={
                                classnames({
                                    [selectClasses]: selectClasses,
                                    'react-select-selected': !stringIsNullOrEmpty(value),
                                    'form-control': true
                                })
                            }
                            placeholder={props.placeholder}
                            onFocus={
                                () => { setToggleClass(true) }
                            }
                            onMenuClose={
                                () => { setToggleClass(value != null) }
                            }
                            onChange={
                                (e) => {
                                    setToggleClass(isMulti ? e !== null : e.value !== null);
                                    onChange(isMulti ? e : e.value);
                                    setDefaultValue(null);
                                    propsOnChange && propsOnChange(isMulti ? e : e.value);
                                }
                            }
                            value={isMulti ? value : (options.length > 0) && options?.filter(x => x.value == (defaultValue !== null ? defaultValue : value))}
                            styles={_selectStyles}
                            options={options}
                            isMulti={isMulti}
                            menuPortalTarget={document.body}
                            isDisabled={readOnly || disabled}
                            formatOptionLabel={formatOptionLabel}
                            components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
                            {...otherProps}
                        />
                    </>);
                }
            }
        />
    );
};

Select.defaultProps = {
    defaultValue: null,
    menuPlacement: 'auto',
    isMulti: false,
}

export default Select;