import React from 'react'

import {
    useTable,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    usePagination,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useAsyncDebounce
} from 'react-table'

import { isEmpty, uniqueId } from 'lodash';
import classnames from "classnames";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cleanObject, stringIsNullOrEmpty } from 'util/Utility';
import useSWR from 'swr';
import { ApiKey } from 'util/Constant';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CustomPaginationFooter } from '.';

const GlobalFilter = ({ preGlobalFilteredRows, setGlobalFilter, value, setValue, gotoPage }) => {
    const { t } = useTranslation();
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined); gotoPage(0); }, 200)

    return (
        <div className="form-group d-inline-block ml-4 m-t-auto m-b-auto globalFilter">
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${t("FILTER")} ${count} ${t("ENTRIES")}...`}
                style={{ fontSize: '1rem' }}
                className="form-control d-inline"
            />
        </div>
    )
}


const headerProps = (props, { column }) => {
    return [
        props,
        column.getSortByToggleProps(),
        {
            className: column.headerClassName,
            style: {
                flex: "unset",
                display: "inline-flex",
                width: column.style?.width ? column.style.width : column.width,
                ...column.style,
            },
        },
    ]
};

const cellProps = (props, { cell }) => {
    return [
        props,
        {
            style: {
                ...cell.column.style,
                flex: cell.column.style?.width ? `${cell.column.style.width} 0 auto` : "100 0 auto",
                width: cell.column.style?.width ? `${cell.column.style.width}px` : "100px",
            },
            className: cell.column.className
        }
    ]
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {

        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        const [checkboxId, setCheckboxId] = React.useState(uniqueId("rt-checkbox-id-"));

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <div className="react-table-checkbox">
                <div className="round-checkbox" onClick={(e) => e.stopPropagation()}>
                    <input id={checkboxId} type="checkbox" ref={resolvedRef} {...rest} />
                    <label for={checkboxId}></label>
                    <label for={checkboxId}>{rest.label}</label>
                </div>
            </div >
        )
    }
)

const ReactTablev2 = React.forwardRef(({
    url,
    data,
    columns,
    enableCheckbox,
    enableGlobalFilter,
    customPageOptions,
    initialPageIndex,
    initialPageSize,
    pageCount: controlledPageCount,
    tableMinHeight,
    tableMinWidth,
    filterFormfield,
    customButtons,
    initQueryProps,
    getRowProps = () => ({}),
    fetchOnSuccess = () => ({}),
    setCheckboxIds,
    footerClassname,
    children,
    tableColumnPreference,
    ...restProps
}, ref) => {

    const _PAGE_RANGE = 6;
    const { t } = useTranslation();
    const [tableId, setTableId] = React.useState(uniqueId('rt-table-id-'));
    const [tableBodyId, setTableBodyId] = React.useState(uniqueId('rt-tbody-id-'));
    const [pageRange, setPageRange] = React.useState([]);
    const [tablePageCount, setTablePageCount] = React.useState(0);
    const [tableData, setTableData] = React.useState([]);
    const [globalFilterValue, setGlobalFilterValue] = React.useState();
    const [afterCallApi, setAfterCallApi] = React.useState(false);
    const [isFilterValuesChanged, setIsFilterValuesChanged] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [afterInitHidden, setAfterInitHidden] = React.useState(false);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: "5rem", // minWidth is only used as a limit for resizing
            width: "10rem", // width is used for both the flex-basis and flex-grow
            maxWidth: "35rem", // maxWidth is only used as a limit for resizing
        }),
        []
    );

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const paginationOptions = React.useMemo(
        () => (
            customPageOptions.length > 0 ?
                customPageOptions : [5, 10, 20, 30, 40, 50]
        ),
        [customPageOptions]
    );

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const range = React.useCallback((start, end) => {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const paginationNavBtns = React.useCallback((pageOptionsLength, pageIndex) => {
        let start = Math.max(1, (pageIndex + 1) - Math.floor(_PAGE_RANGE / 2));
        let end = start + _PAGE_RANGE;
        if (end >= pageOptionsLength) {
            let diff = end - pageOptionsLength;
            start = Math.max(start - diff, 1);
            end = pageOptionsLength;
        }
        let pgRange = range(start, end);
        setPageRange(pgRange);
    }, []);

    const initTableProps = React.useMemo(() => {
        if (stringIsNullOrEmpty(url) && data)
            setAfterCallApi(true);

        return {
            columns,
            data: !stringIsNullOrEmpty(url) ? tableData : data,
            initialState: { pageIndex: 0, pageSize: initialPageSize ? initialPageSize : 10 },
            manualPagination: !stringIsNullOrEmpty(url) ? true : false,
            pageCount: tablePageCount,
            autoResetPage: false,
        }
    }, [tablePageCount, tableData, url, data]);

    const {
        // table row/col props
        getTableProps,
        getTableBodyProps,
        footerGroups,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        visibleColumns,

        // pagination
        page,
        setPageSize,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,

        // filters
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,

        // hide columns
        setHiddenColumns,
        toggleHideColumn,

        state: {
            pageIndex,
            pageSize,
            globalFilter,
            selectedRowIds,
            hiddenColumns
        },
    } = useTable(
        {
            ...initTableProps,
            defaultColumn: defaultColumn
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useResizeColumns,
        useFlexLayout,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.allColumns.push(columns => {
                return enableCheckbox ?
                    [
                        // Let's make a column for selection
                        {
                            id: 'selection',
                            disableResizing: true,
                            style: {
                                width: 20
                            },
                            // minWidth: 35,
                            // width: 35,
                            // maxWidth: 35,
                            // The header can use the table's getToggleAllRowsSelectedProps method
                            // to render a checkbox
                            Header: ({ getToggleAllRowsSelectedProps }) => (
                                <div>
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                                </div>
                            ),
                            // The cell can use the individual row's getToggleRowSelectedProps method
                            // to the render a checkbox
                            Cell: ({ row }) => (
                                <div>
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                </div>
                            ),
                            disableSortBy: true,
                            isRequiredColumn: true
                        },
                        ...columns,
                    ]
                    :
                    [...columns];
            })
            hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
                // fix the parent group of the selection button to not be resizable
                const selectionGroupHeader = headerGroups[0].headers[0];
                selectionGroupHeader && (selectionGroupHeader.canResize = false);
            })
        }
    )

    const columnsChecked = React.useMemo(() => {
        return allColumns.filter(c => !c.isRequiredColumn).length === visibleColumns.filter(c => !c.isRequiredColumn).length;
    }, [allColumns, visibleColumns]);

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    React.useEffect(() => {
        if (allColumns) {
            let tempHiddenColumns = [];
            let isDefaultHiddenColumns = true;

            if (tableColumnPreference?.[0] && tableColumnPreference?.[1]) {
                let columnPreferenceValues = localStorage.getItem(tableColumnPreference);

                if (!isEmpty(columnPreferenceValues)) {
                    isDefaultHiddenColumns = false;
                    columnPreferenceValues = columnPreferenceValues.split(",");
                    allColumns.map((col, index) => {
                        if (columnPreferenceValues?.[index] === "true" && !col.isRequiredColumn) {
                            tempHiddenColumns.push(col.id);
                        }
                    });
                }
            }

            if (isDefaultHiddenColumns) {
                allColumns.map(col => {
                    if (col.isDefaultHidden === true) {
                        tempHiddenColumns.push(col.id);
                    }
                });
            }
            setHiddenColumns(tempHiddenColumns);
            !afterInitHidden && setAfterInitHidden(true);
        }
    }, [setHiddenColumns, tableColumnPreference, allColumns]);
    
    /// <summary>
    /// Author: Sze Hua
    /// To store user column preference by the hidden columns
    /// </summary>
    React.useEffect(() => {
        if (tableColumnPreference?.[0] && tableColumnPreference?.[1] && allColumns && afterInitHidden) {
            let tempHiddenColumns = allColumns.map(col => hiddenColumns.includes(col.id));
            localStorage.setItem(tableColumnPreference, tempHiddenColumns);
        }
    }, [tableColumnPreference, hiddenColumns]);

    React.useEffect(() => {

        if (stringIsNullOrEmpty(url)) {
            /// initiate table page count when page size changes
            paginationNavBtns(pageOptions.length, pageIndex);
        }

    }, [url, pageOptions, pageSize]);

    React.useEffect(() => {
        if (!isEmpty(filterFormfield)) {
            setIsFilterValuesChanged(true);
        }
    }, [filterFormfield])

    const urlBuilder = React.useMemo(() => {

        if (!stringIsNullOrEmpty(url)) {
            // filterFormfield = cleanObject(filterFormfield);
            var extraFilters = cleanObject({ ...filterFormfield, ...initQueryProps });
            var queryParams = { page: pageIndex + 1, pageSize: pageSize, ...extraFilters }
            if (isFilterValuesChanged) {
                setIsFilterValuesChanged(false);
                queryParams.page = 1;
                gotoPage(0);
            }
            return `${url}?` + new URLSearchParams(queryParams);
        }

        return null;

    }, [url, pageSize, pageIndex, filterFormfield, initQueryProps]);

    const { data: fetchedData, mutate } = useSWR(
        urlBuilder ? [urlBuilder, ApiKey._API_GET, null, `div#${tableBodyId} `] : null
    );

    /// fetched and set data to the table
    React.useEffect(() => {

        if (fetchedData?.[ApiKey._API_SUCCESS_KEY]) {

            let { data, totalCount } = fetchedData;
            setTableData(data ? data : []);
            setTablePageCount(data ? Math.ceil(totalCount / pageSize) : 0);
            paginationNavBtns(data ? Math.ceil(totalCount / pageSize) : 0, pageIndex);
            fetchOnSuccess(data ? data : [], totalCount);
        }
        if (!isEmpty(fetchedData) && !afterCallApi) {
            setAfterCallApi(true);
        }

    }, [fetchedData]);

    // set checkedboxIds
    React.useEffect(() => {
        if (typeof (setCheckboxIds) == "function" && enableCheckbox) {
            setCheckboxIds(selectedFlatRows.map(j => j.original.id));
        }
    }, [selectedRowIds, setCheckboxIds]);

    React.useImperativeHandle(ref, () => ({
        reFetch() {
            mutate();
        },
        getPageIndex() {
            return pageIndex;
        },
        canNextPage() {
            return canNextPage;
        },
        canPreviousPage() {
            return canPreviousPage;
        },
        getPageRange() {
            return pageRange;
        },
        getPageOptions() {
            return pageOptions;
        },
        gotoPage(page) {
            gotoPage(page);
        },
        nextPage() {
            nextPage();
        },
        previousPage() {
            previousPage();
        },
        getPageCount() {
            return pageCount;
        }
    }), [
        previousPage,
        nextPage,
        gotoPage,
        mutate,
        pageIndex,
        canPreviousPage,
        canNextPage,
        pageRange,
        pageOptions,
        pageCount,
    ]);

    const toggleDropdownOpen = () => setDropdownOpen(prev => !prev);

    return (
        // <ReactTableContext.Provider>
        <div {...getTableProps()} className="ReactTable -striped -highlight" style={{ ...restProps.style, height: tableMinHeight }} id={tableId}>
            {/* <div>
                <code>
                    {JSON.stringify(
                        {
                            pageIndex,
                            pageSize,
                            pageCount,
                            canNextPage,
                            canPreviousPage,
                        },
                        null,
                        2
                    )}
                </code>
            </div> */}
            <div className={classnames("rt-thead -global-filter -pagination -hide-column", { "p-0": !enableGlobalFilter })}>
                <div className="d-flex justify-content-between">
                    <div>
                        {
                            page.length > 0 &&
                            (
                                <div className="d-inline form-group m-t-auto m-b-auto">
                                    <strong>{t("SHOW")}&nbsp;&nbsp;</strong>
                                    <select className="form-control d-inline"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                        style={{
                                            width: 'auto',
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        {paginationOptions.map(pageSize => (
                                            // Highlight colour is 'browser-specific'
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )
                        }
                        {
                            enableGlobalFilter &&
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                setGlobalFilter={setGlobalFilter}
                                value={globalFilterValue}
                                setValue={setGlobalFilterValue}
                                gotoPage={gotoPage}
                            />
                        }
                    </div>
                    <div className="d-flex flex-center">
                        {customButtons && <div>
                            {customButtons}
                        </div>}
                        <ButtonDropdown
                            isOpen={dropdownOpen}
                            toggle={toggleDropdownOpen}
                            className="ml-2"
                        >
                            <DropdownToggle className="btn btn-rounded btn-themed"><i className="fa fa-ellipsis-v"></i></DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-columns" right>
                                <div className="dropdown-item-content-wrapper y-scrollbar-2 x-scrollbar-2">
                                    <DropdownItem toggle={false} type="button">
                                        <IndeterminateCheckbox
                                            label={t("ALL_COLUMN")}
                                            onChange={() => {
                                                allColumns.filter(c => !c.isRequiredColumn).map(c => toggleHideColumn(c.id, columnsChecked))
                                            }}
                                            checked={columnsChecked}
                                        />
                                    </DropdownItem>
                                    <hr className="mt-1 mb-2" />
                                    {
                                        allColumns.map((c) => (
                                            typeof (c.render('Header')) !== "object" &&
                                            <DropdownItem toggle={false} type="button">
                                                <IndeterminateCheckbox {...!c.isRequiredColumn ? c.getToggleHiddenProps() : { value: true, checked: true, disabled: true }} label={t(c.Header) + (c.isRequiredColumn ? "*" : "")} />
                                            </DropdownItem>
                                        ))
                                    }
                                </div>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                </div>
            </div>
            <div className="rt-table y-scrollbar-2 x-scrollbar-2">

                {headerGroups.map((headerGroup, z) => {

                    const hasParent = headerGroup.headers?.[1]?.parent == undefined ? false : true;

                    return (
                        <div className={classnames("rt-thead scroller-head-anchor",
                            {
                                "-headerGroups": hasParent == true, "-header": hasParent == false
                            })}
                            style={{ minWidth: "fit-content" }}
                        >
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className="rt-tr"
                            >
                                {headerGroup.headers.map(column => (
                                    <div {...column.getHeaderProps(headerProps)} className="rt-th"
                                        style={
                                            {
                                                textAlign: "left",
                                                flex: column.style?.width ? `${column.style.width} 0 auto` : "100 0 auto",
                                                width: column.style?.width ? `${column.style.width}px` : "100px",
                                            }
                                        }
                                    >
                                        {typeof (column.render('Header')) !== "object" ? t(column.render('Header')) : column.render('Header')}
                                        {column.disableSortBy == true ? null :
                                            <span style={{ marginLeft: 'auto' }}>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <i className="fas fa-sort-down" style={{ marginLeft: '3px' }} />
                                                        : <i className="fas fa-sort-up" style={{ marginLeft: '3px' }} />
                                                    : <i className="fas fa-sort" style={{ marginLeft: '3px' }} />
                                                }
                                            </span>
                                        }
                                        {/* Use column.getResizerProps to hook up the events correctly */}
                                        {/* resizer div */}
                                        {/* {column.canResize && (
                                            <div
                                                {...column.getResizerProps()}
                                                className={`rt-resizable -header -cursor -pointer rt-th ${column.isResizing ? 'isResizing' : ''} `}
                                            />
                                        )} */}
                                    </div>

                                ))}
                            </div>
                        </div>
                    )
                })}
                <div {...getTableBodyProps()} className="rt-tbody scroller-body-anchor y-scrollbar-2 x-scrollbar-2" style={{ minWidth: "fit-content" }}>
                    <div id={tableBodyId}>
                        {
                            rows.length > 0 ? <>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <div className="rt-tr-group">
                                            <div {...row.getRowProps(getRowProps(row))} className={classnames("rt-tr ", { "-odd": i % 2 != 0 }, { "-even": i % 2 == 0 })}>
                                                {row.cells.map((cell, j) => {
                                                    return (
                                                        <div {...cell.getCellProps(cellProps)} className={"rt-td"}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                                :
                                afterCallApi && (
                                    <div className="rt-tr-group">
                                        <div className={"rt-td"}>
                                            <div className="d-flex flex-column h-100">
                                                <img alt="" className="no-data-found-gph" src={require("../../assets/img/ui/graphic-nodatafound.svg")} />
                                                <p className="no-data-found-text">{t("NO_DATA_AVAILABLE")}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>

            <CustomPaginationFooter
                footerClassname={footerClassname}
                pageIndex={pageIndex}
                canPreviousPage={canPreviousPage}
                pageRange={pageRange}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                pageCount={pageCount}
            />
            {
                /* 
                TODO
                Default pagination footer 
                */
            }
            {/* <div className="pagination-bottom">
                    <div class="-pagination">
                        <div class="-previous">
                            <button type="button" disabled="" class="-btn">Previous</button>
                        </div><div class="-center">
                            <span class="-pageInfo">
                                <div class="-pageJump">
                                    <input type="number" value="1" />
                                </div>
                                <span class="-totalPages">278</span></span>
                            <span class="select-wrap -pageSizeOptions">
                                <select>
                                    <option value="5">5 rows</option>
                                    <option value="10">10 rows</option>
                                    <option value="20">20 rows</option>
                                    <option value="25">25 rows</option>
                                    <option value="50">50 rows</option>
                                    <option value="100">100 rows</option>
                                </select>
                            </span></div>
                        <div class="-next">
                            <button type="button" class="-btn">Next</button>
                        </div>
                    </div>
                </div> */}
        </div >
        // </ReactTableContext.Provider>
    )
});

ReactTablev2.defaultProps = {
    url: null,
    data: [],
    customPageOptions: [],
    columns: [],
    initialPageIndex: 1,
    pageCount: 10,
    style: {},
    enableGlobalFilter: true,
    filterFormfield: {},
    initQueryProps: {}
}

ReactTablev2.propTypes = {
    url: PropTypes.string,
    data: PropTypes.array,
    customPageOptions: PropTypes.array,
    columns: PropTypes.array,
    initialPageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    style: PropTypes.object,
    enableGlobalFilter: PropTypes.bool,
    filterFormfield: PropTypes.object,
    initQueryProps: PropTypes.object,
}

export default ReactTablev2;
