import React, { useState, useEffect, useRef, useMemo } from "react";
import StepZilla from "react-stepzilla";
import OrderSummary from "./OrderSummary";
import { orderInfoState } from 'recoil/Atoms';
import { useRecoilState, useResetRecoilState } from 'recoil';
import 'react-credit-cards/lib/styles.scss';
import { useLocation } from 'react-router-dom';
import NavigationButton from 'components/buttons/NavigationButton';
import Payment from './Payment';
import PaymentResult from './PaymentResult';
import classNames from "classnames";
import { PaymentMethod } from "util/Constant";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis
/// </summary>
const PaymentCheckout = props => {

    const _stepRef = useRef(null);
    const { t } = useTranslation();
    const { state } = useLocation();
    const [currentStep, setCurrentStep] = useState(0);
    const [showFormSteps, setShowFormSteps] = useState(true);
    const [orderInfoRecoil, setOrderInfoRecoil] = useRecoilState(orderInfoState);
    const resetOrderInfoRecoil = useResetRecoilState(orderInfoState);

    const _wizardFormArray = useMemo(() => [
        { name: t('CART'), component: <OrderSummary showFormSteps={showFormSteps} setShowFormSteps={setShowFormSteps} ref={_stepRef} /> },
        { name: t('PAYMENT'), component: <Payment showFormSteps={showFormSteps} setShowFormSteps={setShowFormSteps} ref={_stepRef} /> },
        { name: t('PAYMENT_STATUS'), component: <PaymentResult showFormSteps={showFormSteps} setShowFormSteps={setShowFormSteps} /> },
    ], [_stepRef, t]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (state?.data && state.data.paymentId && (state.data.showStep == null || !state.data.showStep)) {
            setShowFormSteps(false);
        }

    }, [state]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        return () => { resetOrderInfoRecoil(); }
    }, []);

    return (
        <>
            <div id="paymentCheckout" className="panel panel-brand panel-flex">
                <div className="panel-header p-b-0 d-flex justify-content-between">
                    <div className="d-flex">
                        {(currentStep === 0 || currentStep === 1) && (
                            <NavigationButton />
                        )}
                        <h1 className="page-header title title-with-dot">{t("CART_SUMMARY")}</h1>
                    </div>
                </div>
                <div className="panel-body y-scrollbar-2 h-100">
                    <div className="d-flex flex-column h-100">
                        {
                            <StepZilla
                                preventEnterSubmission={true}
                                showNavigation={false}
                                steps={_wizardFormArray}
                                showSteps={showFormSteps}
                                stepsNavigation={false}
                                nextButtonCls='btn btn-prev pull-right'
                                backButtonCls='btn btn-next pull-left'
                                onStepChange={(step) => setCurrentStep(step)}
                            />
                        }
                    </div>
                </div>
                {currentStep !== 2 && (
                    <div className={classNames("panel-footer d-flex",
                        { "justify-content-end": currentStep == 0 },
                        { "justify-content-between": currentStep == 1 || currentStep == 2 }
                    )}>
                        {
                            /// cart order step
                            currentStep == 0 &&
                            <button type="button" className="btn btn-min-width btn-themed" onClick={() => _stepRef.current.submitOrderCart()}>{t("CONTINUE")}</button>
                        }
                        {
                            currentStep == 1 && <>
                                <button type="button" className="btn btn-themed btn-rest btn-min-width" onClick={() => _stepRef.current.paymentBackHandler()}>{t("BACK")}</button>
                                {orderInfoRecoil.paymentMethod == PaymentMethod._CREDITCARD && <button type="button" className="btn btn-themed btn-min-width"
                                    onClick={_stepRef.current.submitCreditCardPayment}
                                >{`${t("SUBMIT")} ${t("PAYMENT")}`}</button>
                                }
                            </>
                        }
                        {
                            currentStep == 2 && <div style={{ minHeight: 51 }}></div>
                        }
                    </div>
                )}
            </div>
        </>
    );
}

export default PaymentCheckout;
