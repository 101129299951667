import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Lewis
/// </summary>
class CommonDao extends DataAccessObject {

    async getServicesList() {
        return this.get(ApiUrl._API_GET_SERVICES_LIST);
    }

    async getExpertisesList() {
        return this.get(ApiUrl._API_GET_EXPERTISES_LIST);
    }

    async getCountryById(countryId) {
        return this.get(`${ApiUrl._API_GET_COUNTRRY_BY_ID}/${countryId}`)
    }
    
    async getCountryList(url) {
        return this.get(url);
    }

    async getJurisdictionById(jurisdictionId) {
        return this.get(`${ApiUrl._API_GET_JURISDICTION_BY_ID}/${jurisdictionId}`);
    }

    async getJurisdictionList() {
        return this.get(ApiUrl._API_GET_JURISDICTION_LIST);
    }

    async getDepartmentList() {
        return this.get(ApiUrl._API_GET_DEPARTMENT_LIST);
    }

    async getDocumentList() {
        return this.get(ApiUrl._API_GET_DOCUMENT_LIST);
    }

    async getDocumentTypeList() {
        return this.get(ApiUrl._API_GET_DOCUMENT_TYPE_LIST);
    }

    async getUrgencyList() {
        return this.get(ApiUrl._API_GET_URGENCY_LIST);
    }

    async getLanguageList() {
        return this.get(ApiUrl._API_GET_LANGUAGE_LIST);
    }
}

export default CommonDao;
