import common from "./common.json";
import incorporation from "./incorporation.json";
import api from "./api.json";
import userManagement from "./user-management.json";
import systemConfiguration from "./systemConfiguration.json";
import notiflix from "./notiflix.json";

/// <summary>
/// Author: Robin
/// </summary>
export default {
    common,
    incorporation,
    api,
    userManagement,
    systemConfiguration,
    notiflix
};