import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { Tab, Tabs } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import { FaUserEdit } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import classnames from 'classnames';
import moment from 'moment';

// tabs component
import ProfileEdit from './ProfileEdit';
import ActivityLogs from './ActivityLogs';
import PermissionListing from './PermissionListing';

import { Status, WebUrl, DateFormat, ApiKey, RoleType } from 'util/Constant';
import { AccountantDao } from "data";
import { useRecoilState, useRecoilValue } from "recoil";
import { authCredentialState } from "recoil/Atoms";
import TabPanel from 'components/panel/TabPanel';
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis
/// </summary>
const ProfileManager = (props) => {
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation();

    const _activityLogs = useMemo(() => [
        {
            Header: '#',
            accessor: 'index',
            Cell: ({ row }) => {
                return (<>{row.index + 1}</>);
            }
        },
        {
            Header: 'USER',
            accessor: 'user',
            Cell: ({ row }) => {
                return (<>{row.original?.user?.name ? row.original.user.name : "-"}</>)
            }
        },
        {
            Header: 'LOG_DATE',
            accessor: 'logDate',
            Cell: ({ row }) => {
                return (<>{moment(row.original.logDate).format(DateFormat._DATE_FORMAT)}</>);
            }
        },
        {
            Header: 'LOGGER',
            accessor: 'logger',
            Cell: ({ row }) => {
                return (<>{row.original.logger}</>);
            }
        },
        {
            Header: 'MESSAGE',
            accessor: 'message',
            Cell: ({ row }) => {
                return (<>{row.original.message}</>);
            },
        }
    ], []);

    const [userOpts, setUserOpts] = useState([]);
    const authCredential = useRecoilValue(authCredentialState);

    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    const getUserList = async () => {
        let dao = new AccountantDao();
        await dao.getUserList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                let tempOpts = data.map(d => { return { label: d.name, value: d.username } });
                setUserOpts(tempOpts);
            }
        })
    }

    useEffect(() => {
        getUserList();
    }, [])

    /// <summary>
    /// Author: Andrew
    /// </summary>
    const handleTabChange = (e, newValue) => setTabValue(newValue);

    return (<>
        <div className="panel panel-brand panel-flex" id="ProfileEdit">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot">{t("PROFILE_MANAGEMENT")}</h1>
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                    <Tab icon={<img src={require("../../assets/img/icon/admin.svg")} className="mr-2" alt="" />} label={t("PROFILE")} classes={{ wrapper: "d-inline" }} />
                    {
                        authCredential && authCredential?.role?.id == RoleType._SUPERUSER && 
                            <Tab icon={<img src={require("../../assets/img/icon/activity-log-gold.svg")} className="mr-2" alt="" />} label={t("ACTIVITY_LOGS")} classes={{ wrapper: "d-inline" }} />
                    }
                    {
                        authCredential && authCredential?.role?.id == RoleType._SUPERUSER && 
                            <Tab icon={<img src={require("../../assets/img/icon/admin.svg")} className="mr-2" alt="" />} label={t("systemConfiguration:PERMISSION")} classes={{ wrapper: "d-inline" }} />
                    }
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="d-flex flex-column h-100">
                    <TabPanel value={tabValue} index={0} className="flex-grow-1">
                        <ProfileEdit />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} className="flex-grow-1">
                        <PermissionListing permissions={authCredential.permissions} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} className="flex-grow-1">
                        {
                            userOpts.length != 0 && authCredential.role != null &&
                            <ActivityLogs columns={_activityLogs} userOpts={userOpts} userType={authCredential.role.id} />
                        }
                    </TabPanel>
                </div>
            </div>
        </div>
    </>)
}

export default ProfileManager;