import _ from 'lodash/object';
import classnames from 'classnames';
import { CompanyDao } from "data";
import { Confirm, Notify, Report, Block } from 'notiflix';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import { Collapse, Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiKey, DefaultCurrency, DefaultCurrencyId, IncorpComponentKeys, LoadingStateText, SweetAlert, TabsViewStyleProps } from 'util/Constant';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { companySecretaryState, IncorporationInfo } from 'recoil/Incorporation';
import { Tab, Tabs } from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import TabPanel from 'components/panel/TabPanel';
import NumberFormat from 'react-number-format';


import { DetermineIncorporationStatusIcon } from './DetermineIncorporationStatusIcon';
import { useTranslation } from 'react-i18next';
import { authCredentialState } from 'recoil/Atoms';

/// <summary>
/// Author: Chris
/// </summary>
const AddressCompanySecretaryAccordion = ({ formSteps, setFormSteps: setOuterFormSteps, index, toggleStepComplete }) => {

    const { t } = useTranslation();
    const _OPTION_KEYS = { SPECIAL: 'special', CUSTOM: 'custom' };
    const _INITIAL_SELECTED_OPTIONS_STATE = Object.values(_OPTION_KEYS).reduce((obj, key) => {
        return { ...obj, [key]: false };
    }, {});
    const [incorporationInfo, setIncorporationInfo] = useRecoilState(IncorporationInfo);
    const [companySecretary, setCompanySecretary] = useRecoilState(companySecretaryState);
    const [companyAddress, setCompanyAddress] = useState({});
    const resetCompanySecretary = useResetRecoilState(companySecretaryState);
    const [showModal, setShowModal] = useState(false);
    const [incorporationPackage, setIncorporationPackage] = useState([]);
    const [selectedOption, setSelectedOption] = useState(_INITIAL_SELECTED_OPTIONS_STATE);
    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    let dao = new CompanyDao();

    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    const getIncorporationPackage = async (jurisdictionId) => {
        await dao.getIncorporationPackage(jurisdictionId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                let data = response[ApiKey._API_DATA_KEY];
                setIncorporationPackage(data);
            }
        })
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const setCurrentInnerModalStepComplete = (index, isCollapse) => {
        var index = parseInt(index);

        setInnerModalFormSteps((prevState) => {
            if (prevState[index + 1] != undefined) {
                return {
                    ...prevState,
                    [index]: { ...prevState[index], status: true, collapsed: isCollapse },
                    [index + 1]: { ...prevState[index + 1], status: false, collapsed: false }
                };
            }
            return { ...prevState, [index]: { ...prevState[index], status: true, collapsed: isCollapse } };
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const CompanySecretaryAccordion = ({ setInnerModalFormSteps, index, companyAddress }) => {
        const incorporationInfo = useRecoilValue(IncorporationInfo);
        const companySecretary = useRecoilValue(companySecretaryState);
        const [tabValue, setTabValue] = useState(0);
        const _TABS_STYLE_PROPS = { ...TabsViewStyleProps };

        // <summary>
        // Author: Lewis
        // </summary>
        const handleTabChange = (e, newValue) => setTabValue(newValue);

        const SearchExistingProfileForm = ({ setInnerModalFormSteps }) => {

            const applyExistingCompanySecretary = (secretaryData) => {
                var companyId = incorporationInfo.id;
                Confirm.Show(
                    `Are you sure to select ${secretaryData.pNumber}?`,
                    'This secretary will be chosen as your company secretary.',
                    'Yes',
                    'No',
                    async () => {
                        await dao.createExistingIndividualCompanySecretary(companyId, secretaryData).then((response) => {
                            if (response[ApiKey._API_SUCCESS_KEY]) {
                                var data = response[ApiKey._API_DATA_KEY];
                                setCompanySecretary(data);
                                setCurrentInnerModalStepComplete(index, true);
                            }
                        })
                    }
                );
            }

            return <>
                {/* <SearchOfficerForms applyOfficerCallback={applyExistingCompanySecretary} /> */}
            </>;
        }

        // <summary>
        // Author: Chris
        // </summary>
        const CompanySecretaryForm = ({ setInnerModalFormSteps }) => {
            const _formRef = useRef();
            const incorporationInfo = useRecoilValue(IncorporationInfo);
            const companySecretary = useRecoilValue(companySecretaryState);

            const onSubmitCompanyIndividualSecreatary = async (data) => {
                var companyId = incorporationInfo.id;
                Block.Circle("div#swipeviews-wrapper", LoadingStateText._PLEASE_WAIT);
                if (data.ciNumber) {
                    await dao.createCompanyCorporateSecreatary(companyId, data).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            Report.Success("Successfully created new secretary!", "", "Okay", () => {
                                setCompanySecretary(response[ApiKey._API_DATA_KEY]);
                                setCurrentInnerModalStepComplete(index, true);
                            });
                        }
                    })
                } else {
                    await dao.createCompanyIndividualSecreatary(companyId, data).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            Report.Success("Successfully created new secretary!", "", "Okay", () => {
                                setCompanySecretary(response[ApiKey._API_DATA_KEY]);
                                setCurrentInnerModalStepComplete(index, true);
                            });
                        }
                    })
                }
                Block.Remove("div#swipeviews-wrapper");
            }

            return <>
                {/* <PersonForm onSubmit={onSubmitCompanyIndividualSecreatary} formRef={_formRef} resetFormValues={companySecretary} /> */}
                <div className="text-right m-t-10">
                    <button type="button" className="btn btn-themed" onClick={() => submitForm(_formRef)}>Save</button>
                </div>
            </>;
        }

        const _TABS_COMPONENT = {
            0: { name: Object.values(companySecretary).length > 0 ? 'Edit P#' : 'New P#', component: CompanySecretaryForm },
            1: { name: 'Existing P#', component: SearchExistingProfileForm },
        };

        return <>
            <Tabs value={tabValue} onChange={handleTabChange} {..._TABS_STYLE_PROPS}>
                {Object.entries(_TABS_COMPONENT).map(([k, v], index) => <Tab label={v.name} key={index} />)}
            </Tabs>
            <div id="swipeviews-wrapper">
                <SwipeableViews
                    onSwitching={() => Block.Circle("div#swipeviews-wrapper", LoadingStateText._PLEASE_WAIT)}
                    axis='x'
                    index={tabValue}
                    onChangeIndex={handleTabChange}
                >
                    {
                        Object.entries(_TABS_COMPONENT).map(([k, v], index) => {
                            return (
                                <TabPanel value={tabValue} index={index} className="p-10">
                                    {React.createElement(v.component, { setInnerModalFormSteps, companySecretary })}
                                </TabPanel>
                            )
                        })
                    }
                </SwipeableViews>
            </div>
        </>
    }

    /// <summary>
    /// Author: Chris
    /// </summary>
    const AddressAccordion = ({ setInnerModalFormSteps, index, companyAddress }) => {
        const _formRef = useRef();
        const _fields = [];
        const [reset, setReset] = useState(false);
        const incorporationInfo = useRecoilValue(IncorporationInfo);

        const onSubmitRegisterAddress = async (data) => {
            var companyId = incorporationInfo.id;
            Block.Circle("div#swipeviews-wrapper", LoadingStateText._PLEASE_WAIT);

            await dao.createAddress(companyId, data).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success("Successfully Created Address!", "The address has been successfully added.", "Okay", () => {
                        setCompanyAddress(response[ApiKey._API_DATA_KEY]);
                        setCurrentInnerModalStepComplete(index, false);
                        setReset(prevState => !prevState);
                    });
                }
            })

            Block.Remove("div#swipeviews-wrapper");
        }

        /// <summary>
        /// Author: Lewis
        /// </summary>
        useEffect(() => {
            if (Object.values(companyAddress).length > 0) {
                setReset(!reset);
                setCurrentInnerModalStepComplete(index, false);
            };
        }, [companyAddress]);

        return <>
            <FormBuilder fields={_fields} onSubmit={onSubmitRegisterAddress} formRef={_formRef} watchFields={['isDeliverySameAsRegisteredAddress']} reset={reset} resetTriggerReset={setReset} resetValues={companyAddress} />
            <div className="text-right m-t-10">
                <button type="button" className="btn btn-themed" onClick={() => submitForm(_formRef)}>Save</button>
            </div>
        </>;
    }

    const _INIT_INNER_FORM_STEPS = {
        1: { collapsed: false, status: false, name: 'Company Secretary', component: CompanySecretaryAccordion },
        2: { collapsed: true, status: false, name: 'Company Address Details', component: AddressAccordion },
    };

    const [innerModalformSteps, setInnerModalFormSteps] = useState(_INIT_INNER_FORM_STEPS);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        setInnerModalFormSteps((prevState) => {
            if (Object.values(companySecretary).length > 0) {
                prevState[1] = { ...prevState[1], status: true, collapsed: true }
            }
            return prevState;
        });
    }, [companySecretary]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const toggleCollapse = (index) => {
        setInnerModalFormSteps(prevState => {
            let newCollapseObj = {};
            Object.keys(prevState).map(key => {
                newCollapseObj = {
                    ...newCollapseObj,
                    [key]: { ...prevState[key], collapsed: parseInt(key) !== parseInt(index) }
                }
            });
            return newCollapseObj;
        });
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    const toggleModal = () => {
        setShowModal(prevState => !prevState);
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    const selectIncorporationPackage = (selectedKey, incorpData) => {
        var companyId = incorporationInfo.id;
        Confirm.Show(
            t("CONFIRMATION"),
            t("YICOM_ADDRESS_AND_SECRETARY"),
            t('YES'),
            t('NO'),
            async () => {

                await dao.useIncorporationPackage(companyId, incorpData).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                        setCompanyAddress({});
                        setCompanySecretary({});
                        setSelectedOption({ ..._INITIAL_SELECTED_OPTIONS_STATE, [_OPTION_KEYS.SPECIAL]: true });
                        setInnerModalFormSteps(prevState => {
                            return {
                                1: { ...prevState[1], status: false },
                                2: { ...prevState[2], status: false },
                            }
                        })
                    }
                    else {
                        Report.Warning(
                            response[ApiKey._API_MESSAGE_KEY],
                            response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                            t(SweetAlert._OK),
                        );
                    }
                })
            }
        )
    };

    const selectionHandler = (selectedKey, incorp, isEditable) => {
        if (!isEditable && selectedOption[selectedKey]) {
            return;
        };

        if (selectedKey == _OPTION_KEYS.SPECIAL) {
            selectIncorporationPackage(_OPTION_KEYS.SPECIAL, incorp)
        }
        else if (selectedKey == _OPTION_KEYS.CUSTOM) {
            toggleModal();
        }
    };

    //<summary>
    // Author: CJ(Jiann)
    //</summary>
    const provideOwnIncorporationPackage = () => {
        if (innerModalformSteps[1].status && innerModalformSteps[2].status) {
            Confirm.Show(
                t("CONFIRMATION"),
                t("CUSTOM_PACKAGE_WOULD_BE_USED"),
                t("YES"),
                t('NO'),
                async () => {

                    await dao.useProvideOwnIncorporationPackage(incorporationInfo.id).then((response) => {
                        if (response[ApiKey._API_SUCCESS_KEY]) {
                            toggleModal();
                            setSelectedOption({ ..._INITIAL_SELECTED_OPTIONS_STATE, [_OPTION_KEYS.CUSTOM]: true });
                            Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                        }
                        else {
                            Report.Warning(
                                response[ApiKey._API_MESSAGE_KEY],
                                response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                                t(SweetAlert._OK),
                            );
                        }
                    })
                }
            );
        }
    }

    useEffect(() => {
        if (selectedOption[_OPTION_KEYS.SPECIAL] || selectedOption[_OPTION_KEYS.CUSTOM]) {
            toggleStepComplete(IncorpComponentKeys._ADDRESS_SECRETARY, true);
        }
    }, [selectedOption]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        resetCompanySecretary();
        if (incorporationInfo.useIncorporationAnnualPackage) {
            setSelectedOption(prevState => ({ ...prevState, [_OPTION_KEYS.SPECIAL]: true }));
        }
        else if (incorporationInfo.companySecretary) {
            setCompanyAddress(incorporationInfo.address);
            setCompanySecretary(incorporationInfo.companySecretary);
            setSelectedOption(prevState => ({ ...prevState, [_OPTION_KEYS.CUSTOM]: true }));
        }
    }, [incorporationInfo?.useIncorporationAnnualPackage, incorporationInfo?.companySecretary]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (incorporationInfo?.jurisdiction?.id) {
            getIncorporationPackage(incorporationInfo.jurisdiction.id);
        }
    }, [incorporationInfo?.jurisdiction])

    return <div className="section-incorp">
        <div className="title">{t("ADDRESS_AND_COMPANY_SECRETARY")}</div>
        <Row>
            {
                incorporationPackage &&
                incorporationPackage.map((incorp, index) => {
                    return (
                        <Col key={index} xl='4'>
                            <Card className={classnames('incorporation-card', { 'active': selectedOption[_OPTION_KEYS.SPECIAL] })}>
                                <CardHeader>{incorp?.name}</CardHeader>
                                <CardBody>
                                    <div className="content">
                                        {
                                            incorp?.description?.split("\\n").map((text, index) => {
                                                return (
                                                    <div key={index}><i className="fa fa-circle f-s-8 mr-2 text-theme"></i>
                                                        {text}
                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            incorp?.address &&
                                            <div><i className="fa fa-circle f-s-8 mr-2 text-theme"></i>
                                                {incorp?.address?.address1 + ', ' + incorp?.address?.countryName}
                                            </div>
                                        }
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <div className="text-center mb-2">
                                        <b className="d-flex flex-center">
                                            <div className="d-flex flex-column">
                                                <NumberFormat value={incorp?.price} displayType={'text'} thousandSeparator={true} prefix={`${defaultCurrencyCode} `} />
                                                {
                                                    incorp?.quoteCurrency?.id != DefaultCurrencyId && incorp?.quoteCurrency?.exchangeRate?.rate &&
                                                    <NumberFormat className="quote-currency" value={incorp?.quoteCurrency?.quotePrice} displayType={'text'} thousandSeparator={true} prefix={` ≈ ${incorp?.quoteCurrency?.code} `} />
                                                }
                                            </div>
                                            <span className="ml-2">{t('PER_ANNUM')}</span>
                                        </b>
                                    </div>
                                    <button className="btn btn-themed btn-block"
                                        disabled={selectedOption[_OPTION_KEYS.SPECIAL] ? true : false}
                                        onClick={() => selectionHandler(_OPTION_KEYS.SPECIAL, incorp)}>
                                        {selectedOption[_OPTION_KEYS.SPECIAL] ? t("SELECTED") : t("SELECT")}
                                    </button>
                                </CardFooter>
                            </Card>
                        </Col>
                    );
                })
            }
        </Row>
        {
            showModal == true ?
                <Modal isOpen={showModal} size="xl">
                    <ModalHeader toggle={toggleModal} style={{ textAlign: 'center' }}>Provide Your Own</ModalHeader>
                    <ModalBody className="p-0">
                        <div id="incorporationAccordion" className="accordion">
                            {
                                Object.keys(innerModalformSteps).map((key, index) => (
                                    <Card key={index}>
                                        <CardHeader className={'card-header pointer-cursor ' + (innerModalformSteps[key].collapsed ? 'collapsed ' : '')} onClick={() => toggleCollapse(key)}>
                                            {DetermineIncorporationStatusIcon(innerModalformSteps, key)}
                                            <b>{innerModalformSteps[key].name}</b>
                                        </CardHeader>
                                        <Collapse isOpen={!innerModalformSteps[key].collapsed}>
                                            <CardBody>
                                                {React.createElement(innerModalformSteps[key].component, { setInnerModalFormSteps, index: index + 1, companyAddress })}
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                ))
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-default" onClick={toggleModal}>{t("CANCEL")}</button>
                        <button type="button" className="btn btn-themed" onClick={provideOwnIncorporationPackage}>{t("DONE")}</button>
                    </ModalFooter>
                </Modal>
                :
                <></>
        }
    </div>;
}

export default AddressCompanySecretaryAccordion;