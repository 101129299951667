import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import Notiflix, { Block, Notify } from "notiflix";
import { useForm } from "react-hook-form";
import { WebUrl, ApiKey, ApiUrl, AnnualComplianceStatus, DateFormat, LoadingStateText, BtnTypes, RoleType, TableId } from "util/Constant";
import { stringIsNullOrEmpty } from "util/Utility";
import NavigationButton from 'components/buttons/NavigationButton';
import { CompanyDao } from 'data';
import Moment from 'moment';
import { useLocation, useHistory, useParams } from "react-router";
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from 'components/panel/TabPanel';
import AnnualComplianceDocumentList from "./AnnualComplianceDocumentListing";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Can } from "config/user-access";
import { PolicyObjectConstant, PolicyActionConstant } from "util/Constant";
import ButtonRound from "components/buttons/ButtonRound";
import Error403View from 'components/error-graphic/Error403NoAccess';
import { useRecoilValue } from "recoil";
import { authCredentialState, userRolesSelector, userIdSelector } from "recoil/Atoms";

/// <summary>
/// Author: Ong Sze Hua
/// Modify: Robin
/// </summary>
const AnnualCompliance = (props) => {

    const { t } = useTranslation();
    const _location = useLocation();
    const _history = useHistory();
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);

    const { data: companyProfileJson } = useSWR([
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', companyId),
        ApiKey._API_GET,
        null,
        "div.company-annual-compliance"
    ]);

    useEffect(() => {

        if (companyProfileJson?.[ApiKey._API_SUCCESS_KEY])
            setCompany(companyProfileJson[ApiKey._API_DATA_KEY]);

    }, [companyProfileJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {
        stringIsNullOrEmpty(companyId) ?? _history.goBack();
    }, [companyId]);

    return (<div className="panel panel-brand panel-flex">
          
        <div className="panel-header">
            <h1 className="page-header mb-0 title title-with-dot"><NavigationButton />{t("ANN_COMPLIANCES")}</h1>
        </div>
        <div className="panel-body table-annual-compliance-wrapper">
            <CompanyAnnualComplianceTable company={company} />
        </div>
    </div>)


}

const CompanyAnnualComplianceTable = props => {
    let { company } = props;
    const userId = useRecoilValue(userIdSelector);

    const _tableRef = useRef();
    // const [annualComplianceList, setAnnualComplianceList] = useState([]);
    const [annualCompliance, setAnnualCompliance] = useState([]);
    const [modal, setModal] = useState(false);

    const _FYE_APPLICABLE_JURISDICTION = ["SG"];
    const _isFyeApplicable = _FYE_APPLICABLE_JURISDICTION.includes(company?.jurisdiction?.shortName);

    const { register, control, handleSubmit, errors, reset, watch } = useForm();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        COMPANY_ANNUAL_COMPLIANCE_LIST: { id: 0, title: "Annual Compliance List" },
        COMPANY_ANNUAL_COMPLIANCE_DOCUMENT_LIST: { id: 1, title: "Document List" }
    };
    const userRole = useRecoilValue(userRolesSelector);

    const _COLUMNS = useMemo(() => {
        let column = [
            {
                Header: "MATTER",
                Cell: ({ row }) => <>
                    {
                        row.original.productMatter?.name
                    }
                </>
            },
            {
                Header: "SYSTEM_DEADLINE",
                Cell: ({ row }) => <>
                    {
                        row.original.systemDeadline ?
                            Moment(row.original.systemDeadline).format(DateFormat._DATE_ONLY) :
                            <> - </>
                    }
                </>
            },
            {
                Header: "GOVERNMENT_DEADLINE",
                Cell: ({ row }) => <>
                    {
                        row.original.governmentDeadline ?
                            Moment(row.original.governmentDeadline).format(DateFormat._DATE_ONLY) :
                            <> - </>
                    }
                </>
            },
            {
                Header: "STATUS",
                accessor: "status",
                Cell: ({ row }) => <>
                    {
                        row.original.isCompleted ?
                            <span class="__status-text badge badge-success">{t('COMPLETED')}</span>
                            :
                            <span class="__status-text badge badge-warning">{t('PENDING')}</span>
                    }
                </>
            }            
        ];
        if(userRole?.isAdmin || userRole?.isSuperAdmin) {
            column.push(
                {
                    Header: "ACTION",
                    Cell: ({ row }) => {
                        return <>
                            {
                                !row.original?.isCompleted &&
                                <>
                                    {/*
                                        <div id={"actDirect" + (row.original.id)} className="btn-sm btn-round expand-theme mr-1" onClick={() => {
                                            setModal(!modal); 
                                            setAnnualCompliance(row.original)
                                        }}><i className={("las la-arrow-circle-right")}></i></div>
                                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"actDirect" + (row.original.id)}>Redirect</UncontrolledTooltip>
                                    */}
                                    <Can I={PolicyActionConstant.write} this={PolicyObjectConstant.company_annualCompliance}>
                                        <ButtonRound type={BtnTypes.CHECK_CIRCLE} title={t('COMPLETE')} small
                                            onClick={() => {
                                                Notiflix.Confirm.Show(`${t('notiflix:CONFIRM')}?`, `${t('notiflix:COMPLETE_ANNUAL_COMPLIANCE_MATTER')}?`, t('notiflix:YES'), t('notiflix:NO'),
                                                    function () {//Yes
                                                        setCompleteStatus(row.original.id);
                                                    }
                                                );
                                            }} />
                                        {/* <div id={"actComplete" + (row.original.id)} className="btn-sm btn-round expand-theme" onClick={() => Notiflix.Confirm.Show(`${t('notiflix:CONFIRM')}?`, `${t('notiflix:COMPLETE_ANNUAL_COMPLIANCE_MATTER')}?`, t('notiflix:YES'), t('notiflix:NO'),
                                            function () {//Yes
                                                setCompleteStatus(row.original.id);
                                            }
                                        )}><i className={("las la-check")}></i></div>
                                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={"actComplete" + (row.original.id)}>{t('COMPLETE')}</UncontrolledTooltip> */}
                                    </Can>
                                </>
                            }
                        </>
                    },
                    disableSortBy: true,
                    style: { overflow: "visible" }
                }
            )
        }
        return column;
    }, [userRole])

    ///<summary>
    ///Author: Robin
    ///</summary>
    // const retrieveCompanyCompliances = async () => {
    //     let companyId = company?.id;
    //     Notiflix.Block.Circle("#annualCompliances", LoadingStateText._PLEASE_WAIT);
    //     let companyDao = new CompanyDao();
    //     await companyDao.retrieveCompanyCompliances(companyId).then(responseJson => {
    //         if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //             let data = responseJson[ApiKey._API_DATA_KEY] ?? [];
    //             setAnnualComplianceList(data);
    //         }
    //     });

    //     Notiflix.Block.Remove("#annualCompliances");
    // }

    const { data: annualComplianceList, mutate: mutateAnnualComplianceList } = useSWR(company ? ["retrieveCompanyCompliances", company.id] : null,
        async (method, id) => {
            let tempResult = [];
            Block.Circle("#annualCompliances", LoadingStateText._PLEASE_WAIT);
            let companyDao = new CompanyDao();
            await companyDao[method](id).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    tempResult = responseJson[ApiKey._API_DATA_KEY] ?? [];
                }
            }).finally(() => Block.Remove("#annualCompliances"));
            return tempResult;
        }
    );

    ///<summary>
    ///Author: Robin
    ///</summary>
    const setCompleteStatus = async (id) => {
        let status = AnnualComplianceStatus._COMPLETED;
        Notiflix.Block.Circle("#annualCompliances", LoadingStateText._PLEASE_WAIT);

        let companyDao = new CompanyDao();
        await companyDao.updateAnnualComplianceStatus(id, status).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                mutateAnnualComplianceList();
                // setAnnualComplianceList((prevState) => (
                //     prevState.map((item, index) => {
                //         if (item.id === data.id) {
                //             if (data.status == 1)
                //                 item.isCompleted = true;
                //             //item.status = data.status;
                //         }

                //         return item;
                //     })
                // ));
            }
        })

        Notiflix.Block.Remove("#annualCompliances");
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    // useEffect(() => {
    //     if (company) {
    //         retrieveCompanyCompliances();
    //     }
    // }, [company]);

    return (<div className="table-brand-wrapper company-annual-compliance">
        <div className="table-header d-flex justify-content-between border-radius-none">
            <div className="d-flex align-items-center">
                <div className="text-pill-brand">
                    <strong className="f-s-16">
                        {company?.englishName}
                        {company?.chineseName && <>({company?.chineseName})</>}
                    </strong>
                    <div className="ci-number"><strong>CI {t('NUMBER_SHORT')}:</strong>{company?.corporateIdentityNumber}</div>
                </div>
            </div>
        </div>

        <div id="annualCompliances">
            {/* {<Tabs value={currentTab} onChange={(e, newTab) => { setCurrentTab(newTab) }} variant="scrollable">
                <Tab
                    icon={<img src={require('../../assets/img/icon/add-document-gold.svg')} className="mr-2" />}
                    classes={{ wrapper: "d-inline" }}
                    label={_TABS.COMPANY_ANNUAL_COMPLIANCE_LIST.title} />
                <Tab
                    icon={<img src={require('../../assets/img/icon/add-document-gold.svg')} className="mr-2" />}
                    classes={{ wrapper: "d-inline" }}
                    label={_TABS.COMPANY_ANNUAL_COMPLIANCE_DOCUMENT_LIST.title}
                />
            </Tabs>} */}
            <Card className="m-b-5 m-t-5">
                <CardHeader>
                    <table className="justify-content-start m-b-5" style={{ fontSize: 14 }} cellPadding="0">
                        {
                            company?.incorporationAnnualPackage &&
                            <>
                                <tr>
                                    <th style={{ textAlign: "right", paddingRight: "2px" }}>{t('systemConfiguration:ANNUAL_PACKAGE')} : </th>
                                    <td>
                                        <span style={{ fontWeight: 'normal' }}>{company.incorporationAnnualPackage.name}</span> <br />
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: "right", paddingRight: "2px" }}><small>{t('EXPIRE_DATE')} : </small></th>
                                    <td>
                                        <small>
                                            {company?.annualPackageExpiryDate ? Moment(company?.annualPackageExpiryDate).format(DateFormat._DATE_ONLY) : "-"}
                                        </small>
                                    </td>
                                </tr>
                                <tr className="spacer"><td height="5" colspan="2"></td></tr>
                            </>
                        }
                        <tr>
                            <th style={{ textAlign: "right", paddingRight: "2px" }}>{t('INCORPORATION_DATE')} : </th>
                            <td>
                                <span style={{ fontWeight: 'normal' }}>{Moment(company?.incorporationDate).format(DateFormat._DATE_ONLY)}</span>
                            </td>
                        </tr>
                        {
                            _isFyeApplicable &&
                            <tr>
                                <th style={{ textAlign: "right", paddingRight: "2px" }}>{t('systemConfiguration:FINANCIAL_YEAR_END')} : </th>
                                <td>
                                    <span style={{ fontWeight: 'normal' }}>
                                        {
                                            (() => {
                                                let month = company?.financialYearEndMonth - 1;
                                                let day = company?.financialYearEndDay;

                                                if (!month || !day) {
                                                    return '-';
                                                }

                                                return Moment().month(month).date(day).format(DateFormat._MONTH_DAY_ONLY);
                                            })()
                                        }
                                    </span>
                                </td>
                            </tr>
                        }
                    </table>
                </CardHeader>
                <CardBody className="p-0">
                    <TabPanel value={currentTab} index={_TABS.COMPANY_ANNUAL_COMPLIANCE_LIST.id}>
                        <ReactTable
                            ref={_tableRef}
                            columns={_COLUMNS}
                            data={annualComplianceList}
                            tableColumnPreference={[userId, TableId._ANNUAL_COMPLIANCE]}
                        />
                    </TabPanel>
                    {/* {<TabPanel value={currentTab} index={_TABS.COMPANY_ANNUAL_COMPLIANCE_DOCUMENT_LIST.id}>
                        <AnnualComplianceDocumentList companyId={company?.id} />
                    </TabPanel>} */}
                </CardBody>
            </Card>
        </div>
    </div>)
}

export default AnnualCompliance;