import React, { useEffect, useState, useMemo } from 'react';
import { PageSettings } from './config/page-settings.js';
import { UserAccessContext } from './config/user-access.jsx';
import { useUserAccessControl } from 'hooks';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right';
import Content from './components/content/content.jsx';
import { ApiKey, SidebarType, RoleType, WebUrl, HubMethodKeys, SessionKey, SweetAlert, LoadingStateText } from './util/Constant';
import classNames from 'classnames';
import { CommonDao, CompanyDao, CurrencyDao } from '../src/data';
import Notiflix, { Report, Block } from "notiflix";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import { authCredentialState, documentTypeState, notificationState, companyProcessStates, languagesState, currenciesState } from 'recoil/Atoms.js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import DataAccessObject, { _API_URL } from 'data/DataAccessObject.js';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import useSWR, { SWRConfig } from 'swr'
import { CustomFetcher } from 'util/CustomFetcher.js';

/// <summary>
/// Author: Andrew
/// </summary>
export default function App() {

    /// boostrap standard breakpoints
    const isXsDevices = useMediaQuery({ query: '(max-width: 575.98px)' });
    const isSmDevices = useMediaQuery({ query: '(min-width: 576px) and (max-width: 767.98px)' });
    const isMdDevices = useMediaQuery({ query: '(min-width: 768px) and (max-width: 991.98px)' });
    const isLgDevices = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1199.98px)' });
    const isXlDevices = useMediaQuery({ query: '(min-width: 1200px)' });

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: amber[500],
            },
            secondary: {
                main: amber[500],
            },
        },
    });

    Notiflix.Confirm.Init({
        position: 'center',
        titleColor: '#000000',
        plainText: false,
        messageMaxLength: 500,
        backgroundColor: '#f8f8f8',
        okButtonColor: '#f8f8f8',
        okButtonBackground: '#32c682',
        cancelButtonColor: '#000000',
        cancelButtonBackground: '#f8f8f8',
    })

    Notiflix.Notify.Init({
        position: 'top-right',
        useIcon: true,
        fontSize: '15px',
        timeout: 3000,
        pauseOnHover: true,
        useGoogleFont: true,
        fontFamily: "Arial",
        distance: "40px",
        success: {
            background: '#3ec764',
            textColor: '#000000',
            childClassName: 'success',
        },
    })

    Notiflix.Report.Init({
        success: { backOverlayColor: 'rgba(0, 0, 0, 0.2)' },
        plainText: false,
        titleMaxLength: 50
    });

    var floatSubMenuRemoveTime = 250;
    var floatSubMenuRemove;
    var floatSubMenuCalculate;

    const handleSidebarOnMouseOut = (e) => {
        if (pageOptions.pageSidebarMinify) {
            floatSubMenuRemove = setTimeout(() => {
                this.setState(state => ({
                    pageFloatSubMenuActive: false
                }));
            }, floatSubMenuRemoveTime);
        }
    }

    const handleSidebarOnMouseOver = (e, menu) => {
        if (pageOptions.pageSidebarMinify) {
            if (menu.children) {
                var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';

                clearTimeout(floatSubMenuRemove);
                clearTimeout(floatSubMenuCalculate);

                this.setState(state => ({
                    pageFloatSubMenu: menu,
                    pageFloatSubMenuActive: true,
                    pageFloatSubMenuLeft: left
                }));

                var offset = e.currentTarget.offsetParent.getBoundingClientRect();

                floatSubMenuCalculate = setTimeout(() => {
                    var targetTop = offset.top;
                    var windowHeight = window.innerHeight;
                    var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
                    var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

                    if ((windowHeight - targetTop) > targetHeight) {
                        top = offset.top + 'px';
                        bottom = 'auto';
                        arrowTop = '20px';
                        arrowBottom = 'auto';
                        lineTop = '20px';
                        lineBottom = 'auto';
                    } else {
                        var aBottom = (windowHeight - targetTop) - 21;
                        top = 'auto';
                        bottom = '0';
                        arrowTop = 'auto';
                        arrowBottom = aBottom + 'px';
                        lineTop = '20px';
                        lineBottom = aBottom + 'px';
                    }

                    this.setState(state => ({
                        pageFloatSubMenuTop: top,
                        pageFloatSubMenuBottom: bottom,
                        pageFloatSubMenuLineTop: lineTop,
                        pageFloatSubMenuLineBottom: lineBottom,
                        pageFloatSubMenuArrowTop: arrowTop,
                        pageFloatSubMenuArrowBottom: arrowBottom,
                        pageFloatSubMenuOffset: offset
                    }));
                }, 0);

            } else {
                floatSubMenuRemove = setTimeout(() => {
                    this.setState(state => ({
                        pageFloatSubMenu: '',
                        pageFloatSubMenuActive: false
                    }));
                }, floatSubMenuRemoveTime);
            }
        }
    }

    const toggleActiveSidebar = () => {
        let newActiveSidebar = pageOptions.activeSidebar == SidebarType._ACCOUTANT ? SidebarType._ADMIN : SidebarType._ACCOUTANT;
        setOptions('activeSidebar', newActiveSidebar);
    }

    const setAllPageOptions = (value) => {
        setPageOptions(
            pageOptions =>
            ({
                ...pageOptions,
                pageHeader: value,
                pageSidebar: value,
                pageContentFullWidth: !value,
            })
        );
    }

    const [pageOptions, setPageOptions] = useState({
        activeSidebar: SidebarType._ACCOUTANT,
        pageTopMenu: true,
        pageHeader: true,
        pageSidebar: true,
        pageContent: true,
        sideBarButton: false,
        pageSidebarMinify: false,
        pageSidebarToggled: false,
        pageContentFullWidth: false,
        pageHeaderLanguageBar: false,
        setAllPageOptions: setAllPageOptions,
        toggleMobileSidebar: () => toggleOptions('pageSidebarToggled'),
        toggleSidebarMinify: () => toggleOptions('pageSidebarMinify'),
        toggleActiveSidebar: toggleActiveSidebar,
        handleSidebarOnMouseOver: handleSidebarOnMouseOver,
        handleSidebarOnMouseOut: handleSidebarOnMouseOut,
        setOptions: (option, value) => { setOptions(option, value) },
        setMultipleOptions: (options) => { setMultipleOptions(options) },
        isXsDevices,
        isSmDevices,
        isMdDevices,
        isLgDevices,
        isXlDevices
    });

    const { t, i18n } = useTranslation();
    const { buildAbilityFor } = useUserAccessControl();
    const authCredential = useRecoilValue(authCredentialState);
    const setNotifications = useSetRecoilState(notificationState);
    const setDocumentTypes = useSetRecoilState(documentTypeState);
    const setCompanyProcessStates = useSetRecoilState(companyProcessStates);
    const setCurrenciesState = useSetRecoilState(currenciesState);
    const [languageOptions, setLanguageOptions] = useRecoilState(languagesState);
    const [notificationHubConn, setNotificationHubConn] = useState(null);

    let commonDao = new CommonDao();
    let companyDao = new CompanyDao();

    /// <summary>
    /// Author: Lewis
    /// Trigger responsiveness when size of browser changed
    /// </summary>
    useEffect(() => {
        setPageOptions(prevState => ({
            ...prevState,
            isXsDevices,
            isSmDevices,
            isMdDevices,
            isLgDevices,
            isXlDevices,
        }))
    }, [
        isXsDevices,
        isSmDevices,
        isMdDevices,
        isLgDevices,
        isXlDevices,
    ]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleOptions = option => {
        setPageOptions(pageOptions => ({ ...pageOptions, [option]: !pageOptions[option] }));
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const setOptions = (option, value) => {
        let tempOptions = pageOptions;
        if (option == 'activeSidebar') {
            localStorage.setItem('activeSidebar', value);
        }
        tempOptions[option] = value;
        setPageOptions({ ...pageOptions });
    };

    /// <summary>
    /// Author: Robin
    /// </summary>
    const setMultipleOptions = (options) => {
        setPageOptions(pageOptions => ({
            ...pageOptions,
            ...options
        }));
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const initNotificationWebsocket = () => {
        const connect = new HubConnectionBuilder()
            .withUrl(`${_API_URL}/api/message`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Warning)
            .build();

        setNotificationHubConn(connect);
    }

    /// <summary>
    /// Author: Lewis
    /// authenticated api calls & init websocket
    /// </summary>
    useEffect(() => {
        if (!isEmpty(authCredential)) {
            if (isEmpty(notificationHubConn)) {
                initNotificationWebsocket();
            }
        }
        else {
            // logout clear connections
            if (notificationHubConn != null) {
                notificationHubConn.stop()
                    .then(() => {
                        setNotificationHubConn(null);
                    });
            }
        }
    }, [authCredential]);

    useEffect(() => {

        if (languageOptions.length > 0 && !isEmpty(authCredential)) {
            // set user preferred languages
            let { languageId, ...restAuthProps } = authCredential;
            
            // if languageId is null(old user), default langugage set to 1(english)
            let setSelectedLanguage = languageId != null ? languageOptions.filter(x => x.id == languageId)[0]?.code : 1;
            i18n.changeLanguage(setSelectedLanguage);
            localStorage.setItem(SessionKey._LANGUAGE, setSelectedLanguage);
        }

    }, [authCredential, languageOptions]);

    /// <summary>
    /// Author: Lewis
    /// notification hubs
    /// </summary>
    useEffect(() => {
        if (notificationHubConn && notificationHubConn.state === HubConnectionState.Disconnected) {
            notificationHubConn
                .start()
                .then(() => {
                    notificationHubConn.on(HubMethodKeys._NOTIFICATION, (jsonObject) => {
                        let { notification, ...rest } = jsonObject;
                        setNotifications(prevState => {
                            var newState = [...prevState];
                            if (newState.length >= 5) {
                                newState = newState.slice(0, 4);
                            }
                            newState = [{ ...notification }, ...newState];
                            return newState;
                        });
                        Notiflix.Notify.Success(t("YOU_HAVE_A_NEW_NOTIFICATION"));
                    });
                }).catch((error) => console.log(error));
        }
    }, [notificationHubConn]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const _pageClasses = classNames(
        'fade page-sidebar-fixed show page-container',
        {
            'page-header-fixed': pageOptions.pageHeader,
            'page-without-sidebar': !pageOptions.pageSidebar,
            'page-sidebar-minified': pageOptions.pageSidebarMinify,
            'page-sidebar-toggled': pageOptions.pageSidebarToggled,
            'page-with-right-sidebar': pageOptions.pageHeader && (pageOptions.activeSidebar == SidebarType._ADMIN || localStorage.getItem('activeSidebar') == SidebarType._ADMIN)
        }
    )

    return (
        <PageSettings.Provider value={pageOptions}>
            <UserAccessContext.Provider value={buildAbilityFor(authCredential)}>
                <ThemeProvider theme={theme}>
                    {/* for more swr config value, look for documentations at https://www.npmjs.com/package/swr */}
                    <SWRConfig
                        value={{
                            revalidateOnFocus: true,
                            revalidateOnReconnect: true,
                            focusThrottleInterval: 60000*30, // 30 mins revalidate api calls (60k ms per min)
                            fetcher: (...args) => CustomFetcher(...args)
                        }}
                    >
                        <div className={_pageClasses}>
                            {pageOptions.pageHeader && (<Header />)}
                            {pageOptions.pageSidebar && (<SidebarRight />)}
                            {pageOptions.pageSidebar && (<Sidebar />)}
                            {pageOptions.pageContent && (<Content />)}
                        </div>
                    </SWRConfig>
                </ThemeProvider>
            </UserAccessContext.Provider>
        </PageSettings.Provider >
    )
}