import React, { useMemo, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from 'reactstrap';
import Notiflix, { Block } from "notiflix";
import { InputTypes, BtnTypes, ApiKey, LoadingStateText, Frequency, DateFormat } from "util/Constant";
import InputHoc from "components/form/InputHoc";
import { JurisdictionDao } from "data";
import ButtonRound from "components/buttons/ButtonRound";
import AnnualComplianceRecurrenceRuleModal from "./AnnualComplianceRecurrenceRuleModal";
import { useTranslation } from "react-i18next";
import moment from "moment";


const ServiceListAssignRule = (props) => {
    const { modal, setModal, jurisdictionId, productMatterId, productMatterName } = props;

    const { t } = useTranslation();
    const [ruleList, setRuleList] = useState([]);
    const [selectedRule, setSelectedRule] = useState();

    const { register, control, handleSubmit, errors, reset, watch, setValue } = useForm();

    const [refreshRuleList, setRefreshRuleList] = useState(false);
    // const [addRuleModal, setAddRuleModal] = useState(false);
    const [ruleModal, setRuleModal] = useState(false);
    const [isEditRule, setIsEditRule] = useState();

    const _RECURRENCE_FREQUENCY_OPTS = {
        DAILY: { label: t("systemConfiguration:DAILY"), value: Frequency._DAILY, smallnote: t('systemConfiguration:DAY') },
        WEEKLY: { label: t("systemConfiguration:WEEKLY"), value: Frequency._WEEKLY, smallnote: t('systemConfiguration:WEEK') },
        MONTHLY: { label: t("systemConfiguration:MONTHLY"), value: Frequency._MONTLY, smallnote: t('systemConfiguration:MONTH') },
        ANNUALLY: { label: t("systemConfiguration:ANNUALLY"), value: Frequency._YEARLY, smallnote: t("systemConfiguration:YEAR") },
    };
    const _RECURRENCE_SOURCE_TYPE = {
        INCORPORATION: { label: t("INCORPORATION_DATE"), value: 1 },
        FYE: { label: t("systemConfiguration:FINANCIAL_YEAR_END"), value: 2 },
        SPECIFIC_DATE: { label: t("systemConfiguration:SPECIFIC_DATE"), value: 3 }
    };
    const DateOpts = {
        _DAY: { label: "Day", value: 1, minNum: 1, maxNum: 365 },
        _MONTH: { label: "Month", value: 2, minNum: 1, maxNum: 12 }
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const getRuleList = async () => {
        let dao = new JurisdictionDao();
        await dao.getAnnualComplianceRuleList(jurisdictionId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setRuleList(data);
            }
        });
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const getAssignedRule = async () => {
        let dao = new JurisdictionDao();
        await dao.getAssignedAnnualComplianceRule(jurisdictionId, productMatterId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];

                if (data) {
                    setSelectedRule(data);
                }
            }
        });
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const saveChanges = async () => {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        let requestData = {
            id: selectedRule.id
        };

        let dao = new JurisdictionDao();
        await dao.assignAnnualComplianceRule(jurisdictionId, productMatterId, requestData).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                Notiflix.Report.Success(t("notiflix:SUCCESS"), t("notiflix:CHANGES_APPLIED"))
            }
        }).finally(() => Block.Remove(".notiflix-confirm-content"));
    }

    const toggle = () => {
        setModal((prevState) => !prevState);
        setRuleList([]);
        setSelectedRule(null);
    };

    // const toggleAddRuleModal = () => {
    //     setAddRuleModal((prevState) => !prevState);
    // };

    const toggleEditRuleModal = () => {
        setRuleModal((prevState) => !prevState);
    };

    ///<summary>
    ///Author: Robin
    ///</summary>
    useEffect(() => {
        if (modal) {
            getRuleList();
            getAssignedRule();
        }
    }, [modal, refreshRuleList]);

    useEffect(() => {
        if (selectedRule) {
            setValue('rule', selectedRule?.id);
        }
    }, [selectedRule])

    const ruleOptions = useMemo(() => {
        if (ruleList) {
            return ruleList.map((item) => {
                return {
                    label: item?.annualComplianceRuleMeta?.name ?? item?.annualComplianceRuleType?.name,
                    value: item?.id,
                    name: item?.annualComplianceRuleMeta?.name,
                    isCustom: item?.annualComplianceRuleType?.hasCustomRule
                }
            });
        }
    }, [ruleList]);

    const _IS_CUSTOM_OPTIONS = useMemo(() => {
        return [
            { label: t("systemConfiguration:CUSTOM_RECURRENCE") + '?', value: true },
        ]
    }, []);

    const isCustom = useMemo(() => {
        return selectedRule && selectedRule?.annualComplianceRuleType?.hasCustomRule;
    }, [selectedRule]);

    const formatOptionLabel = ({ value, label, name, isCustom }) => (
        <div style={{ display: "flex" }}>
            {
                isCustom ?
                    <div>{name}  <span className="badge badge-light">{label}</span></div>
                    :
                    <div>{label}</div>
            }
        </div>
    );

    const renderRuleDetail = (ruleDetail) => {
        if (ruleDetail) {
            let isCustom = ruleDetail.annualComplianceRuleType?.hasCustomRule;
            let tempFrequencyOption = Object.values(_RECURRENCE_FREQUENCY_OPTS).find(opt => opt.value === ruleDetail.annualComplianceRuleMeta?.frequency);
            let tempSourceType = Object.values(_RECURRENCE_SOURCE_TYPE).find(opt => opt.value === ruleDetail.annualComplianceRuleMeta?.repeatType);
            let tempRepeatStart = "";
            if (ruleDetail.annualComplianceRuleMeta?.repeatStart) {
                tempRepeatStart = `: ${moment(ruleDetail.annualComplianceRuleMeta.repeatStart).format(DateFormat._DATE_ONLY)}`;
            }
            let labelSysDeadlineFreq = Object.values(DateOpts).find(opt => opt.value === ruleDetail.annualComplianceRuleMeta?.systemDeadlineFrequency);

            return (
                <Row>
                    <Col xl={4} className="mb-3"><b>{t("NAME")}</b></Col>
                    <Col xl={8}>{ruleDetail.annualComplianceRuleMeta?.name ?? ruleDetail.annualComplianceRuleType?.name}</Col>
                    <Col xl={4} className="mb-3"><b>{t("DESCRIPTION")}</b></Col>
                    <Col xl={8}>{ruleDetail.description}</Col>
                    {isCustom && (
                        <>
                            <Col xl={4} className="mb-3"><b>{t("systemConfiguration:RECURRENCE_FREQUENCY")}</b></Col>
                            <Col xl={8}>{tempFrequencyOption?.label}</Col>
                            <Col xl={4} className="mb-3"><b>{t("systemConfiguration:EVERY")}</b></Col>
                            <Col xl={8}>{`${ruleDetail.annualComplianceRuleMeta?.every} ${tempFrequencyOption?.smallnote}`}</Col>
                            <Col xl={4} className="mb-3"><b>{t("FROM")}</b></Col>
                            <Col xl={8}>{tempSourceType?.label + tempRepeatStart}</Col>
                        </>
                    )}
                    {labelSysDeadlineFreq && (
                        <>
                            <Col xl={4} className="mb-3"><b>Before Assgined Deadline</b></Col>
                            <Col xl={8}>{`${ruleDetail.annualComplianceRuleMeta?.systemDeadlineBefore} ${labelSysDeadlineFreq.label}`}</Col>
                        </>
                    )}
                </Row>
            );
        }
        return ""
    }

    return (<>
        <div isOpen={modal} toggle={toggle} className="h-100">
            <div className="table-brand-wrapper h-100 d-flex flex-column">
                {/* <div className="table-header d-flex align-center justify-between d-none" style={{ padding: '.8rem' }}>
                    <ButtonRound type={BtnTypes.ARROW_LEFT} title="Back" className="btn-small-round" onClick={() => props.setModal(false)}></ButtonRound>
                    {
                        <button type="button" style={{ padding: '0 1rem' }} className="btn btn-themed btn-min-width" onClick={() => {
                            Notiflix.Confirm.Show(t("notiflix:SAVE_CHANGES") + "?", t("notiflix:SELECTED_RULE_WILL_BE_ASSIGNED"), t("YES"), t("NO"), () => {
                                saveChanges();
                            });
                        }}>{t("SAVE")}</button>
                    }
                </div> */}
                <div className="section-split assigned-rule-wrapper">
                    <div className="section-wrapper">
                        <div className="section-header">
                            <div className="d-flex flex-center">
                                <ButtonRound type={BtnTypes.ARROW_LEFT} className="btn-sm mr-2" title={"Back"} onClick={() => props.setModal(false)} />
                                {/* <ButtonRound type={BtnTypes.ARROW_LEFT} title="Back" className="btn-small-round" onClick={() => props.setModal(false)}></ButtonRound> */}
                                <h3 className="m-0">{productMatterName}</h3>
                            </div>
                            <ButtonRound type={BtnTypes.PLUS} title="Add rule" className="btn-md btn-themed" style={{ marginLeft: 'auto' }} onClick={() => { setIsEditRule(false); toggleEditRuleModal() }}></ButtonRound>
                        </div>
                        <div className="section-body">
                            <div className="input-wrapper rule-body">
                                <Row>
                                    <Col xl="10">
                                        <form autoComplete="off" onSubmit={() => { }}>
                                            <InputHoc
                                                label={t("systemConfiguration:ASSIGNED_RULE")}
                                                name="rule"
                                                error={errors?.rule?.message}
                                                inputType={InputTypes.SELECT}
                                                options={ruleOptions}
                                                ref={register}
                                                control={control}
                                                placeholder="Assign Rule"
                                                isClearable
                                                onChange={(value) => {
                                                    var rule = ruleList.filter(r => r.id == value)[0];
                                                    if (rule) {
                                                        setSelectedRule((prevState) => rule);
                                                    }
                                                }}
                                                formatOptionLabel={formatOptionLabel}
                                                style={{ height: "100%", width: "100%" }}
                                                smallnote={isCustom ? t('CUSTOMIZE') : t("SYSTEM_GENERATED")}
                                            />
                                        </form>
                                    </Col>
                                    {
                                        isCustom &&
                                        <Col xl="2" className="my-auto">
                                            <ButtonRound type={BtnTypes.EDIT} title="Edit rule" className="btn-md btn-themed" style={{ marginLeft: 'auto' }} onClick={() => { setIsEditRule(true); toggleEditRuleModal() }}></ButtonRound>
                                        </Col>
                                    }
                                </Row>
                                {/* <InputHoc
                                    name="isCustom"
                                    error={errors?.isCustom?.message}
                                    inputType={InputTypes.CHECKBOX}
                                    options={_IS_CUSTOM_OPTIONS}
                                    ref={register}
                                    control={control}
                                    value={isCustom}
                                    defaultValue={isCustom}
                                    onChange={(e) => {
                                        var isChecked = e.target.checked;
                                    }}
                                    isSwitcher
                                    disabled
                                /> */}
                            </div>
                        </div>
                    </div>
                    <div className="section-wrapper">
                        <div className="section-header">
                            <span className="text-pill-brand">{t("DETAIL")}</span>
                            {
                                <button type="button" style={{ padding: '0.27rem 1rem' }} className="btn btn-themed btn-min-width ml-auto" onClick={() => {
                                    Notiflix.Confirm.Show(t("notiflix:SAVE_CHANGES") + "?", t("notiflix:SELECTED_RULE_WILL_BE_ASSIGNED"), t("YES"), t("NO"), () => {
                                        saveChanges();
                                    });
                                }}>{t("SAVE")}</button>
                            }
                            {/*<ButtonRound type={BtnTypes.ARROW_LEFT} title="Back" className="btn-small-round" onClick={() => props.setModal(false)}></ButtonRound>*/}
                        </div>
                        <div className="section-body rule-detail-body">
                            {
                                selectedRule ?
                                    <div className="d-flex mx-auto">
                                        {
                                            renderRuleDetail(selectedRule)
                                        }
                                        {/* {
                                            selectedRule?.description
                                        } */}
                                    </div> :
                                    <div className="empty-state-wrapper">
                                        <img src={require("../../../assets/img/ui/search-graphic.svg")} />
                                        <p>{t("systemConfiguration:SELECT_ROLE_TO_VIEW_INFORMATION")}</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <AnnualComplianceRecurrenceRuleModal
            modal={addRuleModal}
            setModal={setAddRuleModal}
            setRule={setSelectedRule}
            jurisdictionId={jurisdictionId}
            productMatterId={productMatterId}
            setRefreshRuleList={setRefreshRuleList}
        /> */}
        <AnnualComplianceRecurrenceRuleModal
            modal={ruleModal}
            setModal={setRuleModal}
            rule={selectedRule}
            setRule={setSelectedRule}
            jurisdictionId={jurisdictionId}
            productMatterId={productMatterId}
            setRefreshRuleList={setRefreshRuleList}
            isEditRule={isEditRule}
        />
    </>
    )
}

export default ServiceListAssignRule;