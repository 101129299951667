import React from 'react';
import { Route } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import SidebarNavList from './sidebar-nav-list.jsx';
import AccountantMenu, { AdminMenu } from './menu.jsx';

class SidebarNav extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            active: -1,
            clicked: -1,
            accountantMenus: AccountantMenu,
            adminMenus: AdminMenu,
            userType: props.userType
        };
    }

    handleExpand(e, i, match) {
        e.preventDefault();

        if (this.state.clicked === -1 && match) {
            this.setState(state => ({
                active: -1,
                clicked: 1
            }));
        } else {
            this.setState(state => ({
                active: (this.state.active === i ? -1 : i),
                clicked: 1
            }));
        }
    }

    render() {
        return (
            <ul className="nav">
                {this.context.pageSidebarSearch && (
                    <li className="nav-search">
                        <input type="text" className="form-control" placeholder="Sidebar menu filter..." onKeyUp={this.handleSidebarSearch} />
                    </li>
                )}

                {this.state.userType == "Accountant" &&
                    <>
                        <li className="nav-header title title-with-dot f-s-24 d-flex"><strong>Accountant</strong></li>
                        {this.state.accountantMenus.map((menu, i) => (
                            <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
                                <SidebarNavList
                                    data={menu}
                                    key={i}
                                    expand={(e) => this.handleExpand(e, i, match)}
                                    active={i === this.state.active}
                                    clicked={this.state.clicked}
                                />
                            )} />
                        ))}
                    </>
                }
                {this.state.userType == "Admin" &&
                    <>
                        <li className="nav-header title title-with-dot f-s-24"><strong>Admin</strong></li>
                        {this.state.adminMenus.map((menu, i) => (
                            <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
                                <SidebarNavList
                                    data={menu}
                                    key={i}
                                    expand={(e) => this.handleExpand(e, i, match)}
                                    active={i === this.state.active}
                                    clicked={this.state.clicked}
                                />
                            )} />
                        ))}
                    </>
                }
            </ul>
        );
    }
}

export default SidebarNav;