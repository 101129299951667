import React, { useEffect, useState, useRef, useMemo } from 'react';
import Avatar from 'react-avatar';
import { Row, Col } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import Notiflix, { Notify } from "notiflix";
import { ChatBox } from 'react-chatbox-component';
import 'react-chatbox-component/dist/style.css';

import { _API_URL } from 'data/DataAccessObject.js';
import { ApiKey, SidebarType, RoleType, WebUrl, ApiUrl, HubMethodKeys, DateFormat } from 'util/Constant';
import { userIdSelector, usernameSelector } from 'recoil/Atoms';
import { ChatDao } from 'data';
import { useTranslation } from 'react-i18next';
import { Chat } from './AdminChatView';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    Avatar as ChatAvatar,
    ConversationHeader,
    MessageList,
    Message,
    MessageInput,
    MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import moment from 'moment';
import { isEmpty, groupBy } from 'lodash';

const ContactUs = (props) => {
    const userId = useRecoilValue(userIdSelector);
    const username = useRecoilValue(usernameSelector);
    const user = {
        "uid": userId
    }

    const { t } = useTranslation();
    const [chatRoomId, setChatRoomId] = useState("");
    const [chatHubConnection, setChatHubConnection] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const messagesDate = useMemo(() => {
        let tempResult = [];
        tempResult = groupBy(messages, (item) => moment.utc(item.messageDate).calendar({
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: DateFormat._DATE_ONLY
        }));
        return tempResult;
    }, [messages]);
    const msgListRef = useRef();

    const addChat = (message) => {
        setMessages((prevState) => ([
            ...prevState,
            message
        ]))
    }

    const initConnection = () => {
        const connect = new HubConnectionBuilder()
            .withUrl(`${_API_URL}${ApiUrl._API_CHAT}`)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Warning)
            .build();

        connect.on(HubMethodKeys._BROADCAST_MESSAGE, (message) => {
            addChat(message);
        });

        connect.on(HubMethodKeys._CHAT_SESSION_INITIALIZED, (id, participant) => {
            if (id) {
                setChatRoomId(id);
            }
        });

        //connect.on(HubMethodKeys._CHAT_SESSION_ENDED, (id, participant) => {

        //});

        connect.on(HubMethodKeys._NEW_CHAT_REQUEST, (chatRoom) => {

        });

        setChatHubConnection(connect);
    }

    const endConnection = () => {
        if (chatHubConnection != null) {
            chatHubConnection.stop();
            setChatHubConnection(null);
        }
    }

    const initializeChatSession = async () => {
        try {
            await chatHubConnection.invoke("InitializeChatSession");
        } catch (err) {
            console.error(err);
        }
    }

    //No need to end session for now.
    //const endChatSession = async () => {
    //    try {
    //        await chatHubConnection.invoke("EndChatSession", chatRoomId);
    //    } catch (err) {
    //        console.error(err);
    //    }
    //}

    const sendChat = async (message) => {
        try {
            let participant = {
                id: userId,
                name: username
            }

            await chatHubConnection.invoke("SendMessage", chatRoomId, message, participant);
        } catch (err) {
            console.error(err);
        }
        finally {
            msgListRef.current.scrollToBottom();
        }
    }

    const retrieveMessages = async (chatRoomId) => {
        let dao = new ChatDao();
        await dao.retrieveMessages(chatRoomId).then((json) => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                let data = json[ApiKey._API_DATA_KEY] ?? [];
                setMessages(data);
            }
        }).finally(() => {

        })
    }

    useEffect(() => {
        initConnection();
        return (() => {
            endConnection();
        })
    }, []);

    /// <summary>
    /// Author: Robin
    /// </summary>
    useEffect(() => {
        if (chatHubConnection) {
            chatHubConnection
                .start()
                .then(() => {
                    initializeChatSession();
                })
                .catch((error) => console.log(error));
        }
    }, [chatHubConnection]);

    useEffect(() => {
        if (chatRoomId) {
            retrieveMessages(chatRoomId);
        }
    }, [chatRoomId])

    return <>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("CONTACT_ADMINISTRATOR")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="chat-content panel panel-brand m-0">
                    {
                        chatRoomId &&
                        <MainContainer>
                            <ChatContainer key={chatRoomId}>
                                <MessageList ref={msgListRef}>
                                    {!isEmpty(messagesDate) && (
                                        Object.entries(messagesDate).map(([key, val]) => {
                                            return (
                                                <>
                                                    <MessageSeparator content={key} />
                                                    {val.map(msg => {
                                                        let isUser = (user.uid === msg.sender.uid);
                                                        return <Message
                                                            model={{
                                                                direction: isUser ? "outgoing" : "incoming",
                                                                type: "custom"
                                                            }}
                                                        >
                                                            {!isUser && (
                                                                <ChatAvatar>
                                                                    <Avatar className="avatar" name={msg.sender?.name ?? "ME"} size="40" textSizeRatio={2} round maxInitials={2} />
                                                                </ChatAvatar>
                                                            )}
                                                            <Message.CustomContent>
                                                                <Chat message={msg} user={user} />
                                                            </Message.CustomContent>
                                                        </Message>
                                                    })}
                                                </>
                                            );
                                        })
                                    )}
                                </MessageList>
                                <MessageInput
                                    placeholder="Type a message"
                                    attachButton={false}
                                    onSend={(innerHtml, textContent, innerText) => { sendChat(innerText); }}
                                    onPaste={(evt) => {
                                        evt.preventDefault();
                                    }}
                                />
                            </ChatContainer>
                        </MainContainer>
                    }
                </div>
            </div>
        </div>
    </>;
}

export default ContactUs;
