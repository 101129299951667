import { isEmpty } from 'lodash';
import set from 'lodash/set';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import ImageUploading from "react-images-uploading";
import { stringIsNullOrEmpty } from 'util/Utility';
import ModalImage from 'react-modal-image';
import { useTranslation } from 'react-i18next';

///<summary>
///Author: Lewis
///</summary>
const ImageUploader = React.forwardRef((props, ref) => {
    let { value, onChangeCallback, disabled, defaultValue, single, ...otherProps } = props;
    const { t } = useTranslation();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const toDataUrl = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);

            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const getBase64String = async (imgUrl) => {
        const data = await fetch(imgUrl);
        const blob = await data.blob();

        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onloadend = function (e) {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const _MAX_NUMBER = single ? 1 : 5;
    const _DATA_URL_KEY = "data_url";
    const [images, setImages] = useState([]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {
        // multiple image input
        if (typeof (value) == "object") {
            if (value.length > 0) {
                const promises = value.map(async fileObject => {
                    let { filename, url, ...rest } = fileObject;
                    // for repopulate purposes !
                    if (!stringIsNullOrEmpty(filename) && !stringIsNullOrEmpty(url)) {
                        const base64String = await getBase64String(url).then(result => result);
                        return { [_DATA_URL_KEY]: base64String, file: dataURLtoFile(base64String, filename) };
                    }
                    else {
                        return { ...rest };
                    }
                });
                Promise.all(promises).then(imagesToSet => setImages(imagesToSet));
            }
            // else {
            //     setImages([]);
            // }
        }
        else {
            setImages([]);
        }
    }, [value]);

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={(inputImages, addUpdateIndex) => {
                onChangeCallback(inputImages, addUpdateIndex); // set values to the react hook form before submit
                setImages(inputImages);
            }}
            maxNumber={_MAX_NUMBER}
            dataURLKey={_DATA_URL_KEY}
        >
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                // write your building UI
                <div className="upload__image-wrapper" style={imageList.length == 0 ? { paddingTop: 20, paddingBottom: 20 } : {}}>
                    {
                        !disabled && <>
                            {imageList.length != _MAX_NUMBER && (
                                <>
                                    <p className="m-0">{t("MAX_FILE_SIZE_SUPPORT_TYPE")}</p>
                                    <button className="fileUploaderButton"
                                        style={isDragging ? { color: "red" } : null} type="button" onClick={onImageUpload} {...dragProps} >
                                        {t("CLICK_OR_DROP_HERE")}
                                    </button>
                                </>
                            )}
                        </>
                    }
                    {/* {imageList.length > 0 && <button type="button" className="removeAllFileButton" onClick={onImageRemoveAll}></button>} */}
                    <div className="imagePreviewWrapper">
                        {imageList.map((image, index) => (
                            <div key={index} className="imageItemPreviewContainer">
                                {
                                    (!disabled) && <>
                                        <button className="btn btn-round btn-sm imageDeleteBtn" type="button" onClick={() => onImageRemove(index)}><i className="fas fa-times-circle text-red" /></button>
                                        {/* <button className="btn btn-round btn-md imageUploadBtn" type="button" onClick={() => onImageUpdate(index)}><i className="fas fa-upload text-blue" /></button> */}
                                    </>
                                }
                                <ModalImage small={image.data_url} medium={image.data_url} alt="preview" />
                                {/* <img src={image.data_url} alt="preview" width="100%" /> */}
                            </div>
                        ))}
                        {(disabled && imageList.length == 0) && (
                            <i className="fas fa-box-open opacity-3" style={{ fontSize: "30px" }} />
                        )}
                    </div>
                </div>
            )}
        </ImageUploading>
    )
});

///<summary>
///Author: Lewis
///</summary>
const CustomImageUploader = (props) => {
    let { defaultValue, disabled, single, ...rest } = props;

    return (
        <Controller
            {...rest}
            render={({ onChange, value, ...otherProps }) => {
                return (
                    <ImageUploader {...otherProps} onChangeCallback={(inputImages, addUpdateIndex) => onChange(inputImages)} value={value} single={single}
                        disabled={disabled} />
                )
            }} />
    );
}

CustomImageUploader.defaultProps = {
    single: true,
}

export default CustomImageUploader;


