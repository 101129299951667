import React, { useMemo, useState, useEffect } from "react";
import { CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { CurrencyDao } from "data";
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from 'components/buttons/NavigationButton';
import classnames from 'classnames';
import moment from 'moment';
import { Block } from 'notiflix';
import { ApiKey, LoadingStateText, DateFormat, WebUrl } from "../../util/Constant";
import { isEmpty } from 'lodash';
import { Tabs, Tab } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

///<summary>
///Author: Ong Sze Hua
///</summary>
const CurrencyRatioChart = props => {
    const { t } = useTranslation();
    const _location = useLocation();
    const _history = useHistory();
    const currencyId = useMemo(() => _location?.state?.currencyId, [_location]);
    const today = useMemo(() => moment().startOf('day').toDate(), []);
    const [exchangeRates, setExchangeRates] = useState([]);

    const ChartRange = useMemo(() => ({
        _ALL: { label: t('ALL'), id: 0 },
        _ONE_MONTH: { label: '1 ' + t('MONTH'), id: 1 },
        _SIX_MONTH: { label: '6 ' + t('MONTH'), id: 2 },
        _ONE_YEAR: { label: '1 ' + t('YEAR'), id: 3 }
    }), []);


    const [selectedChartRange, setSelectedChartRange] = useState(ChartRange._ONE_MONTH.id);

    const _CHART_OPTIONS = useMemo(() => ({
        chart: {
            id: 'area-datetime',
            zoom: {
                enabled: false
            },
            redrawOnParentResize: false,
            redrawOnWindowResize: false,
            animations: {
                enabled: true,
                dynamicAnimation: {
                    enabled: false
                },
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            type: 'datetime'
        },
        markers: {
            size: 0
        },
        tooltip: {
            x: {
                format: 'yyyy-MM-dd'
            }
        },
        colors: ['#e5ad06']
    }), []);

    const _CHART_SERIES = useMemo(() => {
        var data = [];
        var fromCurrency = "";
        if (!isEmpty(exchangeRates)) {
            data = exchangeRates.map(e => [moment(e.createdDate), e.rate]);
            fromCurrency = exchangeRates[0]?.fromCurrency;
        }
        var result = [
            {
                name: `${t("systemConfiguration:BASE_CURRENCY")}(${fromCurrency})`,
                data: data
                //data: [
                //    ["2021-01-01", 0.1],
                //    ["2021-02-01", 0.2],
                //    ["2021-03-01", 0.15],
                //    ["2021-04-01", 0.22],
                //    ["2021-05-01", 0.13],
                //    ["2021-05-02", 0.13],
                //    ["2021-05-03", 0.13],
                //    ["2021-05-04", 0.13],
                //    ["2021-05-05", 0.13],
                //    ["2021-05-06", 0.13],
                //    ["2021-05-07", 0.13],
                //    ["2021-06-01", 0.17],
                //    ["2021-07-01", 0.1],
                //    ["2021-08-01", 0.2],
                //    ["2021-09-01", 0.15],
                //    ["2021-10-01", 0.22],
                //    ["2021-11-01", 0.13],
                //    ["2021-12-01", 0.17],
                //]
            }
        ];
        return result;
    }, [exchangeRates]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function updateData(timeline) {
        setSelectedChartRange(timeline);
        switch (timeline) {
            case ChartRange._ONE_MONTH.id:
                ApexCharts.exec('area-datetime',
                    'zoomX',
                    moment(today).subtract(1, 'months').toDate().getTime(),
                    today.getTime()
                )
                break
            case ChartRange._SIX_MONTH.id:
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment(today).subtract(6, 'months').toDate().getTime(),
                    today.getTime()
                )
                break
            case ChartRange._ONE_YEAR.id:
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment(today).subtract(1, 'years').toDate().getTime(),
                    today.getTime()
                )
                break
            case ChartRange._ALL.id:
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    !isEmpty(exchangeRates) ? moment(exchangeRates[0].createdDate).toDate().getTime() : today.getTime(),
                    today.getTime()
                )
                break
            default:
        }
    }

    const getCurrencyExchangeRate = async () => {

        let currencyDao = new CurrencyDao();
        Block.Circle("#currencyRatioChart", LoadingStateText._PLEASE_WAIT);
        await currencyDao.getCurrencyExchangeRatesByCurrencyId(currencyId).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                setExchangeRates(jsonResponse[ApiKey._API_DATA_KEY]);
            }
        }).finally(() => Block.Remove("#currencyRatioChart"));
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (!currencyId) {
            _history.goBack();
        }
        getCurrencyExchangeRate();
    }, []);

    ///<summary>
    ///Author: Sze Hua
    ///</summary>
    useEffect(() => {
        if (_CHART_SERIES && !isEmpty(_CHART_SERIES[0]?.data)) {
            updateData(ChartRange._ONE_MONTH.id);
        }
    }, [_CHART_SERIES]);

    return (<>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot"><NavigationButton />{t("systemConfiguration:CURRENCY_RATIO_CHART")}</h1>
            </div>
            <div classname="panel-body y-scrollbar-2" id="currencyRatioChart">
                <div className="table-brand-wrapper" style={{ borderRadius: "2em" }}>
                    <div className="table-header">
                        <CardBody id="currencyRatioChart">
                            <div className="d-flex justify-center">
                                <Tabs value={selectedChartRange} variant="scrollable">
                                    <Tab classes={{ wrapper: "d-inline text-theme-1" }} label={ChartRange._ALL.label} onClick={() => updateData(ChartRange._ALL.id)} />
                                    <Tab classes={{ wrapper: "d-inline text-theme-1" }} label={ChartRange._ONE_MONTH.label} onClick={() => updateData(ChartRange._ONE_MONTH.id)} />
                                    <Tab classes={{ wrapper: "d-inline text-theme-1" }} label={ChartRange._SIX_MONTH.label} onClick={() => updateData(ChartRange._SIX_MONTH.id)} />
                                    <Tab classes={{ wrapper: "d-inline text-theme-1" }} label={ChartRange._ONE_YEAR.label} onClick={() => updateData(ChartRange._ONE_YEAR.id)} />
                                </Tabs>
                            </div>
                            <Chart
                                options={_CHART_OPTIONS}
                                series={_CHART_SERIES}
                                type="area"
                                height="500"
                                width="100%"
                            />
                        </CardBody>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default CurrencyRatioChart;
