import React, { useMemo, useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { DocumentType, WebUrl, Icon, ApiKey, DateFormat, CompanyDocumentStatus, InputTypes, ApiUrl, FileType, deleteConfirm, LoadingStateText, BtnTypes, SweetAlert, TableId } from 'util/Constant';
import InputHoc from "components/form/InputHoc";
import ReactTable from 'components/react-table/ReactTable';
import NavigationButton from 'components/buttons/NavigationButton';
import { Row, ModalBody, ModalFooter, UncontrolledTooltip, Col } from 'reactstrap';
import { CompanyDao, CommonDao, JurisdictionDao } from '../../data';
import moment from 'moment';
import Notiflix, { Block, Report, Notify } from 'notiflix';
import { useForm } from 'react-hook-form';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import { userIdSelector, userRolesSelector } from "recoil/Atoms";
import { useRecoilValue } from 'recoil';
import BrandModal from '../../components/modals/BrandModal';
import DocumentViewer from 'components/document-viewer/DocumentViewer';
import ButtonRound from 'components/buttons/ButtonRound';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PageSettings } from 'config/page-settings';
import { useResizeDetector } from 'react-resize-detector';
import { stringIsNullOrEmpty } from 'util/Utility';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { Can } from 'config/user-access';
import { PolicyActionConstant, PolicyObjectConstant } from '../../util/Constant';
import DataAccessObject from 'data/DataAccessObject';

/// <summary>
/// Author: Sze Hua
/// </summary>
const CompanyLetters = ({ props }) => {
    const { companyId } = useParams();
    const _history = useHistory();
    const { t } = useTranslation();
    const { isXlDevices } = useContext(PageSettings);
    const { ref: _contentRef } = useResizeDetector();
    const userId = useRecoilValue(userIdSelector);

    const userRole = useRecoilValue(userRolesSelector);
    const [filePath, setFilePath] = useState();
    const [isViewing, setIsViewing] = useState();
    const [refreshTable, setRefreshTable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedLetterTypeId, setSelectedLetterTypeId] = useState();
    const [filterValues, setFilterValues] = useState({});

    const companyDao = useMemo(() => new CompanyDao(), []);
    const jurisdictionDao = useMemo(() => new JurisdictionDao(), []);
    const fixedContentHeight = useMemo(() => {
        if (_contentRef.current) {
            return _contentRef.current.clientHeight - 15;
        }
    }, [_contentRef.current]);

    const FormKeys = {
        _GOV_DEPARTMENT_ID: "govDepartmentId",
        _LETTER_TYPE_ID: "letterTypeId"
    };

    const { control, errors, handleSubmit, watch, setValue, reset } = useForm();

    const _WATCH_GOV_DEPARTMENT_ID = watch(FormKeys._GOV_DEPARTMENT_ID);

    const { data: company } = useSWR(["getCompanyDetailsById", companyId], async (method, id) => {
        let tempResult;
        await companyDao[method](id).then(res => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                tempResult = res[ApiKey._API_DATA_KEY];
            }
        });
        return tempResult;
    });


    const { data: govDepartmentList } = useSWR(company?.jurisdiction?.id ? ["getGovernmentDeparmentList", company.jurisdiction.id] : null,
        async (method, id) => {
            let tempResult = [];
            await jurisdictionDao[method](id).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    tempResult = res[ApiKey._API_DATA_KEY] ?? [];
                }
            });
            return tempResult;
        }
    );

    const govDeparmentOpts = useMemo(() => {
        let tempOpts = [];
        if (govDepartmentList) {
            tempOpts = govDepartmentList.map(data => ({ label: data.name, value: data.id, ...data }));
        }
        return tempOpts;
    }, [govDepartmentList]);

    const { data: letterTypeList } = useSWR((company?.jurisdiction?.id && _WATCH_GOV_DEPARTMENT_ID) ? ["getLetterTypesListByGovernmentDeparment", company.jurisdiction.id, _WATCH_GOV_DEPARTMENT_ID] : null,
        async (method, jurisdictionId, govDepartmentId) => {
            let tempResult = [];
            await jurisdictionDao[method](jurisdictionId, govDepartmentId).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    tempResult = res[ApiKey._API_DATA_KEY] ?? [];
                }
            });
            return tempResult;
        }
    );

    const letterTypeOpts = useMemo(() => {
        let tempOpts = [];
        if (letterTypeList) {
            tempOpts = letterTypeList.map(data => ({ label: data.name, value: data.id, ...data }));
        }
        return tempOpts;
    }, [letterTypeList]);

    const _COLUMNS = useMemo(() => {
        let tempColumn = [
            // {
            //     Header: "Department",
            //     accessor: "department.name",
            //     style: { width: '15%', whiteSpace: 'pre-line', wordBreak: 'break-word' }
            // },
            {
                Header: "Letter Type",
                accessor: "letterTypeName",
                style: { whiteSpace: 'pre-line', wordBreak: 'break-word' }
            },
            {
                Header: "Action",
                Cell: ({ row }) => {

                    return (
                        <>
                            {row.original.letterAttachment && (
                                <>
                                    <Link className="brand-anchor mr-1"
                                        to={row.original?.letterAttachment?.requestPath}
                                        target={"_blank"}
                                        download={row.original?.letterAttachment?.filename}
                                    >
                                        <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} />
                                    </Link>
                                    <ButtonRound
                                        className="mr-2"
                                        type={BtnTypes.VIEW}
                                        small
                                        onClick={() => {
                                            if (row.original?.letterAttachment?.extension != FileType._PDF) {
                                                Notify.Warning(t("systemConfiguration:DOCX_FILE_NOT_VIEWABLE"))
                                            } else {
                                                setIsViewing(true);
                                                setFilePath(row.original?.letterAttachment?.requestPath);
                                            }
                                        }}
                                    />
                                </>
                            )}
                            {/* <ButtonRound
                                small
                                type={BtnTypes.FILE_UPLOAD}
                                onClick={() => {
                                    setSelectedLetterTypeId(row.original.id)
                                    toggleShowModal();
                                }}
                            /> */}
                        </>
                    );
                },
                // style: { width: '1%' },
                disableSortBy: true,
                disableFilters: true
            }
        ];
        return tempColumn;
    }, [userRole]);

    const toggleShowModal = () => setShowModal(prev => !prev);
    const handleFilterValues = (data = {}) => setFilterValues(data);

    return (
        <div className="panel panel-brand panel-flex" id="company-document-blocker">
            <div className="panel-header">
                <h1 className="mb-0 page-header title title-with-dot"><NavigationButton />{"Company Letters"}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames('panel-body-inner-content with-br-radius', {
                    'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices
                })}>
                    <div className="d-flex flex-column h-100">
                        <Row className="flex-grow-1">
                            <Col xl={12}>
                                <div className="d-flex flex-column h-100">
                                    <div className="filter-panel-container">
                                        <form onSubmit={handleSubmit(handleFilterValues)} autoComplete="off">
                                            <Row className="filter-panel-body d-flex align-items-center">
                                                <Col xl={10}>
                                                    <Row xl={4} className="d-flex align-items-center">
                                                        <Col>
                                                            <div className="text-pill-brand">
                                                                <strong className="f-s-16">
                                                                    {company?.englishName}
                                                                    {company?.chineseName && <>({company?.chineseName})</>}
                                                                </strong>
                                                                <div className="ci-number"><strong>CI {t("NUMBER_SHORT")}:</strong>{company?.corporateIdentityNumber ? company.corporateIdentityNumber : "-"}</div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <InputHoc name={FormKeys._GOV_DEPARTMENT_ID} label="Government Department"
                                                                placeholder={"Government Department"}
                                                                inputType={InputTypes.SELECT}
                                                                options={govDeparmentOpts}
                                                                control={control}
                                                                onChange={() => { setValue(FormKeys._LETTER_TYPE_ID, ""); }}
                                                            />
                                                        </Col>
                                                        {_WATCH_GOV_DEPARTMENT_ID && (
                                                            <Col>
                                                                <InputHoc name={FormKeys._LETTER_TYPE_ID} label="Letter Type"
                                                                    placeholder={"Letter Type"}
                                                                    inputType={InputTypes.SELECT}
                                                                    options={letterTypeOpts} control={control}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col xl={2} className="btn-list d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-themed btn-brand-round"><i className="las la-search" /></button>
                                                    <button type="button" className="btn btn-themed btn-brand-round grayscale-100" onClick={() => { handleFilterValues(); reset(); }}><i className="las la-redo-alt" /></button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                    <div className="flex-grow-1" ref={_contentRef}>
                                        <div style={{ height: fixedContentHeight }}>
                                            <ReactTable
                                                columns={_COLUMNS}
                                                url={ApiUrl._API_COMPANY_ANNUAL_COMPLIANCE_LETTERS.replace(":companyId", companyId)}
                                                refreshTable={refreshTable}
                                                setPagination
                                                enableSearch
                                                filterFormfield={filterValues}
                                                customButtons={

                                                    (userRole.isSuperAdmin || userRole.isAdmin) && (
                                                        <button className="btn btn-themed btn-sm btn-min-width tbl-custom-brand-btn" onClick={() => { toggleShowModal(); }}>
                                                            {"Upload Letter"}
                                                        </button>
                                                    )
                                                }
                                                tableColumnPreference={[userId, TableId._COMPANY_LETTERS]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={filePath} />
                <LetterModal showModal={showModal} toggleShowModal={toggleShowModal} companyId={companyId} jurisdictionId={company?.jurisdiction?.id} setRefreshTable={setRefreshTable} govDeparmentOpts={govDeparmentOpts} />
            </div>
        </div>
    );
}

const LetterModal = props => {
    const { showModal, toggleShowModal, companyId, jurisdictionId, setRefreshTable, govDeparmentOpts } = props;
    const { t } = useTranslation();

    const companyDao = useMemo(() => new CompanyDao(), []);
    const jurisdictionDao = useMemo(() => new JurisdictionDao(), []);
    const { control, errors, handleSubmit, watch, setValue } = useForm();

    const FormKeys = {
        _GOV_DEPARTMENT_ID: "govDepartmentId",
        _LETTER_TYPE_ID: "letterTypeId",
        _LETTER_ATTACHMENT: "letterAttachment",
    }

    const _WATCH_GOV_DEPARTMENT_ID = watch(FormKeys._GOV_DEPARTMENT_ID);
    const _WATCH_LETTER_TYPE_ID = watch(FormKeys._LETTER_TYPE_ID);

    const { data: letterTypeList } = useSWR((jurisdictionId && _WATCH_GOV_DEPARTMENT_ID) ? ["getLetterTypesListByGovernmentDeparment", jurisdictionId, _WATCH_GOV_DEPARTMENT_ID] : null,
        async (method, jurisdictionId, govDepartmentId) => {
            let tempResult = [];
            await jurisdictionDao[method](jurisdictionId, govDepartmentId).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    tempResult = res[ApiKey._API_DATA_KEY] ?? [];
                }
            });
            return tempResult;
        }
    );

    const letterTypeOpts = useMemo(() => {
        let tempOpts = [];
        if (letterTypeList) {
            tempOpts = letterTypeList.map(data => ({ label: data.name, value: data.id, ...data }));
        }
        return tempOpts;
    }, [letterTypeList]);

    const onSubmit = async (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        data[FormKeys._LETTER_ATTACHMENT] = data?.[FormKeys._LETTER_ATTACHMENT]?.[0];
        await companyDao.uploadCompanyLetter(companyId, data).then((res) => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(res[ApiKey._API_MESSAGE_KEY]);
                toggleShowModal();
                setRefreshTable(prev => !prev);
            }
            else {
                let errors = res[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        }).finally(() => Block.Remove(".modal-content"));
    }

    return (
        <>
            <BrandModal
                modalSize="md"
                title={"Upload letter"}
                customBody
                isOpen={(showModal && companyId && jurisdictionId)}
                toggler={toggleShowModal}
                centered
                backdrop='static'
            >
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <ModalBody>
                        <Row>
                            <Col xl={6}>
                                <InputHoc name={FormKeys._GOV_DEPARTMENT_ID} label="Government Department"
                                    inputType={InputTypes.SELECT}
                                    options={govDeparmentOpts}
                                    control={control}
                                    rules={{ required: true }}
                                    error={errors?.[FormKeys._GOV_DEPARTMENT_ID]}
                                    onChange={() => { setValue(FormKeys._LETTER_TYPE_ID, ""); setValue(FormKeys._LETTER_ATTACHMENT, ""); }}
                                />
                            </Col>
                            <Col xl={6}>
                                <InputHoc name={FormKeys._LETTER_TYPE_ID} label="Letter Type"
                                    inputType={InputTypes.SELECT}
                                    options={letterTypeOpts} control={control}
                                    rules={{ required: true }}
                                    error={errors?.[FormKeys._LETTER_TYPE_ID]}
                                    disabled={!_WATCH_GOV_DEPARTMENT_ID}
                                    onChange={() => setValue(FormKeys._LETTER_ATTACHMENT, "")}
                                />
                            </Col>
                            <Col xl={12}>
                                <InputHoc name={FormKeys._LETTER_ATTACHMENT} label="Letter"
                                    inputType={InputTypes.FILEUPLOAD}
                                    control={control} accept={{ pdf: true }}
                                    rules={{ required: true }}
                                    error={errors?.[FormKeys._LETTER_ATTACHMENT]}
                                    disabled={!_WATCH_LETTER_TYPE_ID}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="panel-foot panel-foot-buttons justify-content-center">
                        <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggleShowModal}>{t("CANCEL")}</button>
                        <button type="submit" className="btn btn-sm btn-themed btn-min-width">{t("SAVE")}</button>
                    </ModalFooter>
                </form>
            </BrandModal>
        </>
    )

}


export default CompanyLetters;