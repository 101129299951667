import React, { useState, useMemo, useEffect, useRef } from 'react';
import { ModalFooter, Row, Col } from 'reactstrap';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authCredentialState, personFormIdentitiesState } from 'recoil/Atoms';
import { CompanyDao } from 'data';
import { OfficerTypeID, ApiKey, ApiUrl, InputTypes, OfficerRoleId, BtnTypes, CreateOfficerStepFormRefKey, Language, LoadingStateText } from 'util/Constant';
import { cleanObject, formatJurisdictionList, stringIsNullOrEmpty } from 'util/Utility';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import Notiflix, { Confirm, Block } from "notiflix";
import { isEmpty } from "lodash";
import InputHoc from 'components/form/InputHoc';
import { createOfficerFormState } from 'recoil/Incorporation';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

const _FORM_KEYS = { INDIVIDUAL: 'individual', CORPORATE: 'corporate' };

///<summary>
///Author: Lewis
///</summary>
const SearchOfficerForm = (props) => {
    let {
        index,
        parentKey,
        jumpToStep,
        officerTypeId,
        setFetchOfficerId,
        setSearchResultList,
        companyId,
        beneficiaryId
    } = props;

    const { t, i18n } = useTranslation();
    const _formRef = useRef(null);
    const _ID_OPTIONS = useRecoilValue(personFormIdentitiesState);
    const { firmId } = useRecoilValue(authCredentialState);
    const setCreateOfficerFormValues = useSetRecoilState(createOfficerFormState);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const activeType = useMemo(() => {

        return officerTypeId === OfficerTypeID._CORPORATE ?
            _FORM_KEYS.CORPORATE :
            _FORM_KEYS.INDIVIDUAL;

    }, [officerTypeId]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const triggerSearchApi = async (formValue) => {

        let companyDao = new CompanyDao();

        Block.Circle("div.modal-content", LoadingStateText._PLEASE_WAIT);
        formValue = cleanObject(formValue);
        if (beneficiaryId) {
            formValue.beneficiaryId = beneficiaryId;
        }

        if (isEmpty(formValue) || (Object.keys(formValue).length == 1 && !stringIsNullOrEmpty(formValue.firmId))) {
            setCreateOfficerFormValues(formValue);
            Block.Remove("div.modal-content");
            jumpToStep(index + 1);
            return;
        }

        formValue.officerTypeId = activeType == _FORM_KEYS.INDIVIDUAL ? OfficerTypeID._INDIVIDUAL : OfficerTypeID._CORPORATE;

        var apiUrl = `${ApiUrl._API_SEARCH_OFFICER_WITHOUT_SAME_PARENT.replace(":companyId", companyId)}?${new URLSearchParams({ ...formValue })}`;

        Notiflix.Notify.Merge({ messageMaxLength: 1000 });

        await companyDao.get(apiUrl).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                let count = jsonResponse[ApiKey._API_DATA_COUNT_KEY];
                let searchedResult = jsonResponse[ApiKey._API_DATA_KEY];

                if (count === 0) {
                    setCreateOfficerFormValues(formValue);
                    Block.Remove("div.modal-content");
                    jumpToStep(index + 1);
                }
                else {
                    let msgBody = "" + t('SELECT_EXISTING_PERSON');

                    if (count === 1) {

                        let { id, englishSurname, englishName, identityType, phoneNumber, ciNumber, identityNumber, officerTypeId } = searchedResult[0];

                        if (officerTypeId == OfficerTypeID._INDIVIDUAL) {
                            msgBody += '<table id="match-one-officer">'
                                + (englishSurname ? (`<tr><td>${t("ENGLISH_SURNAME")}:</td><td>` + englishSurname + '</td>') : '')
                                + `<tr><td>${t("ENGLISH_NAME")}:</td><td>` + englishName + '</td>'
                                + `<tr><td>${t("IDENTITY_TYPE")}: <span class="badge badge-info">` + identityType + '</span></td><td>' + identityNumber + '</td>'
                                + `<tr><td>${t("PHONE_NUMBER")}:</td><td>` + phoneNumber + '</td>'
                                + '</tr></table>';
                        }
                        else if (officerTypeId == OfficerTypeID._CORPORATE) {
                            msgBody += '<table id="match-one-officer">'
                                + `<tr><td>${t("ENGLISH_NAME")}:</td><td>` + englishName + '</td>'
                                + `<tr><td>${t("CI_NUM")}:</td><td>` + ciNumber + '</td>'
                                + '</tr></table>';
                        }
                    }
                    if (count > 0) {
                        Confirm.Show(
                            count + ` ${t('MATCHES_COUNT')}`,
                            msgBody,
                            t("YES"),
                            t("NO"),
                            () => {
                                if (count == 1) {
                                    setFetchOfficerId(searchedResult[0].id);
                                    Block.Remove("div.modal-content");
                                    jumpToStep(index + 3);
                                }
                                // more than one count, jump to searched result tables for user to select
                                else if (count > 1) {
                                    setSearchResultList(searchedResult);
                                    Block.Remove("div.modal-content");
                                    jumpToStep(index + 4);
                                }
                            },
                            () => {
                                setCreateOfficerFormValues(formValue);
                                Block.Remove("div.modal-content");
                                jumpToStep(index + 1)
                            }
                        );
                    }
                }
            }
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const formFields = useMemo(() => {
        const _FORM_CONST = {
            [_FORM_KEYS.INDIVIDUAL]: [
                {
                    rowOptions: { xl: 3 },
                    columns: [
                        { label: 'ENGLISH_NAME', name: 'englishName', columnOptions: { xl: 6 } },
                        {
                            label: 'IDENTITY_TYPE',
                            name: 'identityType',
                            input: InputTypes.SELECT,
                            options: _ID_OPTIONS.map((item) => {return {label: t(`${item.label.toUpperCase()}`), value: item.value}}),
                            columnOptions: { xl: 6 }
                        },
                        { label: 'IDENTIFICATION_NUM', name: 'identityNumber', columnOptions: { xl: 6 } },
                        { label: 'PHONE_NUMBER', name: 'phoneNumber', input: InputTypes.PHONE, columnOptions: { xl: 6 } },
                        { name: 'firmId', columnOptions: { xl: 6 }, rules: { required: false }, hidden: true, value: firmId ? firmId : null },
                    ]
                }
            ],
            [_FORM_KEYS.CORPORATE]: [
                {
                    rowOptions: { xl: 3 },
                    columns: [
                        { label: 'ENGLISH_NAME', name: 'englishName', columnOptions: { xl: 6 } },
                        { label: 'CI_NUM', name: 'ciNumber', columnOptions: { xl: 6 } },
                        { label: 'CHINESE_NAME', name: 'chineseName', columnOptions: { xl: 6 } },
                        {
                            label: 'JURISDICTION_OF_INCORP',
                            name: 'jurisdictionId',
                            input: InputTypes.SELECT,
                            options: jurisdictionOptions,
                            columnOptions: { xl: 6 }
                        },
                        { name: 'firmId', columnOptions: { xl: 6 }, rules: { required: false }, hidden: true, value: firmId ? firmId : null },
                    ]
                }
            ]
        }
        return _FORM_CONST[activeType];
    }, [
        activeType,
        firmId,
        jurisdictionOptions
    ]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    /// usage : set input size dynamically with placeholder string size
    useEffect(() => {
        const hinterNote = document.querySelectorAll('#officer-searcher-hinter');
        for (var i = 0; i < hinterNote.length; i++) {

            let size = i18n.language === Language._ENGLISH_GB ? hinterNote[i].getAttribute('placeholder').length
                : hinterNote[i].getAttribute('placeholder').length * 2;

            hinterNote[i].setAttribute('size', size);
        }
    }, [i18n.language]);

    return <div className="search-officer-modal">
        <Row className="w-100">
            <Col xl={2}></Col>
            <Col xl={8}>
                <InputHoc
                    id="officer-searcher-hinter"
                    formGroupClass="officer-search-input-grp-cls"
                    disabled={true}
                    prefix={<img src={require("../../../assets/img/icon/bulb.svg")} />}
                    placeholder={
                        officerTypeId == OfficerTypeID._INDIVIDUAL ?
                            t("SEARCH_OR_CREATE_EXISTING_OFFICER") :
                            t("SEARCH_OR_CREATE_EXISTING_CORPORATE")
                    }
                />
            </Col>
            <Col xl={2}></Col>
        </Row>
        <div className="person-form-wrapper p-25">
            <FormBuilder fields={formFields} onSubmit={triggerSearchApi} formRef={_formRef} />
        </div>
        <ModalFooter className="panel-foot panel-foot-buttons justify-content-end">
            <button className="btn btn-themed btn-min-width" onClick={() => submitForm(_formRef)} >{t("SEARCH")}</button>
        </ModalFooter>
    </div>
}

export default SearchOfficerForm;