import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { orderInfoState, cartState, authCredentialState } from 'recoil/Atoms';
import { useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil';
import 'react-credit-cards/lib/styles.scss';
import { Row, Col, Card, CardBody, CardHeader, Button, UncontrolledTooltip } from 'reactstrap';
import {
    WebUrl,
    PaymentStatus,
    DateFormat,
    ApiUrl,
    BtnTypes,
    ApiKey,
    DefaultCurrencyId,
    DefaultCurrency,
} from 'util/Constant';
import {
    stringIsNullOrEmpty,
    getCartItemPrices,
    formatNumber,
} from 'util/Utility';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Loading, Report, Block, Confirm, Notify } from "notiflix";
import { AccountantDao, PaymentDao } from "data";
import moment from "moment";
import ReactTable from "components/react-table/ReactTable";
import NumberFormat from "react-number-format";
import ButtonRound from "components/buttons/ButtonRound";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis   
/// </summary>
const PaymentResult = React.forwardRef((props, ref) => {
    const { jumpToStep, showFormSteps, setShowFormSteps } = props;
    Report.Merge({
        success: { backOverlayColor: 'rgba(0, 0, 0, 0.2)' },
        plainText: false,
        titleMaxLength: 70
    });

    const { t } = useTranslation();
    const _history = useHistory();
    const { state } = useLocation();
    const orderInfoRecoil = useRecoilValue(orderInfoState);
    const resetOrderInfoRecoil = useResetRecoilState(orderInfoState);
    const [paymentSummary, setPaymentSummary] = useState([]);
    const [paymentItem, setPaymentItem] = useState([]);
    const [pageInitialized, setPageInitialized] = useState(false);
    const [cartRecoil, setCartRecoil] = useRecoilState(cartState);
    const [selectedRow, setSelectedRow] = useState(null);
    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    const _COLUMNS = useMemo(() => [
        {
            Header: '#',
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'ACCOUNTANT',
            Cell: ({ row }) => {
                return <div>{paymentSummary?.order?.orderBy?.name}</div>;
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'JURISDICTION',
            Cell: ({ row }) => {
                return <div>{row.original.serviceRequest?.company.jurisdiction.shortName}</div>;
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'MATTER',
            Cell: ({ row }) => {
                return <div>
                    {row.original.serviceRequest?.productMatter.name}
                    {
                        row.original.orderCartItems.map(item => {
                            // temporary remove discount
                            // let total = getCartItemPrices(item);
                            let total = item.price;
                            return <small style={{ marginLeft: "2px", color: "#9e9e9e" }}>
                                <div className="label-chkbox mb-0">
                                    - {item.productName}
                                </div>
                                <div className="ml-3">
                                    <NumberFormat value={total.toFixed(2)} thousandSeparator displayType={'text'} prefix={`+${defaultCurrencyCode} `} />
                                    {
                                        currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                        <NumberFormat className="quote-currency" value={formatNumber(total * currencyPreference?.exchangeRate?.rate)} thousandSeparator displayType={'text'} prefix={` ≈ ${currencyPreference?.code} `} />
                                    }
                                </div>
                            </small>
                        })
                    }
                </div>;
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        },
        {
            Header: 'NAME_OF_COMPANY',
            Cell: ({ row }) => <>
                <div><span className="badge-lang-en">EN</span> {row.original.serviceRequest.company.englishName}</div>
                {
                    row.original.serviceRequest.company.chineseName &&
                    <>
                        <div><span className="badge-lang-zh">CN</span>{row.original.serviceRequest.company.chineseName}</div>
                    </>
                }
            </>,
            disableFilters: true,
            disableSortBy: true,
            Footer: <div style={{ textAlign: "right" }}><strong className="text-primary">Total:</strong></div>
        },
        {
            Header: 'PRICE',
            Cell: ({ row }) => {
                let total = 0;
                row.original.orderCartItems.map(item => {
                    // temporary remove discount
                    //total += getCartItemPrices(item);
                    total += item.price;
                })
                return <div className="d-flex flex-column">
                    <NumberFormat className="text-primary" value={total.toFixed(2)} thousandSeparator displayType={'text'} prefix={`${defaultCurrencyCode} `} />
                    {
                        currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                        <NumberFormat className="quote-currency" value={formatNumber(total * currencyPreference?.exchangeRate?.rate)} thousandSeparator displayType={'text'} prefix={` ≈ ${currencyPreference?.code} `} />
                    }
                </div>
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: <div style={{ textAlign: "left" }}><strong className="text-primary">HK$ <NumberFormat value={paymentSummary.totalAmount?.toFixed(2)} thousandSeparator displayType={'text'} /></strong></div>
        },
        {
            Header: 'ACTION',
            headerStyle: { width: '5%' },
            Cell: ({ row }) => {
                return (
                    <div className="btn-list-wrapper d-flex">
                        <ButtonRound title={"View Company"} medium type={BtnTypes.DOUBLE_RIGHT_PROCEED} onClick={() => {
                            goTo(row.original?.serviceRequest?.company?.id);
                        }} />
                    </div>
                )
            },
            disableFilters: true,
            disableSortBy: true,
            Footer: ""
        }
    ], [paymentSummary, currencyPreference]);

    const goTo = (companyId) => {
        _history.push({
            pathname: WebUrl._COMPANY_MANAGEMENT_PORTFOLIO.replace(":companyId", companyId),
            state: {
                pageTitle: "Company Portfolio"
            }
        })
    }

    if (state?.data?.paymentId) {
        var paymentId = state.data.paymentId;
    } else {
        var paymentId = orderInfoRecoil.paymentId;
    }

    const getPaymentResult = async () => {
        let loadingTimeout = setTimeout(() => {
            Block.Circle('#paymentResultSection');
        }, 250);

        let paymentDao = new PaymentDao();
        await paymentDao.getPaymentSummary(paymentId).then(responseJson => {
            clearTimeout(loadingTimeout);
            Block.Remove('#paymentResultSection', 500);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var data = responseJson[ApiKey._API_DATA_KEY];
                setPageInitialized(true);
                setPaymentSummary(data);
                setPaymentItem(data.order.orderCarts);
            }
            else {
                Report.Failure(
                    'Error',
                    'Failed to retrieve payment summary. Please try again.',
                    'Okay',
                );
            }
        })
    };

    const getCartList = async () => {
        let dao = new AccountantDao();
        await dao.getAccountantCart().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setCartRecoil(data);
            }
        })
    }

    const retryPayment = () => {
        delete state?.data;
        resetOrderInfoRecoil();
        jumpToStep(0);
        setShowFormSteps(true);
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        getPaymentResult();
        getCartList();
        setShowFormSteps(false);
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        return () => { resetOrderInfoRecoil(); }
    }, []);

    useImperativeHandle(ref, (() => {

    }), []);

    return (
        <div id="paymentResultSection" className="panel-flex">
            {paymentSummary &&
                <>
                    {pageInitialized &&
                        <div className="payment-result-wrapper text-center">
                            {paymentSummary?.status === PaymentStatus._SUCCESS
                                ?
                                <div className="payment-state-wrapper">
                                    <img alt="" src={require("../../assets/img/ui/successful.svg")} />
                                    <div className="content-wrapper">
                                        <h2 className="f-w-800">{t("PAYMENT_SUCCESS")}!</h2>
                                        <p>{t("PAYMENT_SUCCESS_MESSAGE_LINE1")}<br></br>{t("PAYMENT_SUCCESS_MESSAGE_LINE2")}</p>
                                    </div>
                                </div>
                                :
                                <div className="payment-state-wrapper">
                                    <img alt="" src={require("../../assets/img/ui/payment-failed.png")} />
                                    <div className="content-wrapper">
                                        <h2 className="f-w-800">{t("PAYMENT_FAILED")}!</h2>
                                        <p>
                                            {t("PAYMENT_FAILED_MESSAGE")} <br />
                                            {!stringIsNullOrEmpty(paymentSummary.remark) && "Reason: " + paymentSummary.remark}
                                        </p>
                                        <div>
                                            <button type="button" className="btn btn-sm btn-min-width btn-themed" onClick={() => retryPayment()} style={{ fontSize: ".8rem", padding: ".5rem" }}>
                                                {t("RETRY_PAYMENT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="panel-body y-scrollbar-2" id="paymentReceipt">
                        <div className="table-brand-wrapper">
                            <div className="table-header p-0">
                                <div className="payment-summary-header p-15">
                                    <strong className="f-w-800">{t("ITEM_PURCHASED")}</strong>
                                    <div className="d-flex btn-list-wrapper">
                                        {paymentSummary.status == PaymentStatus._SUCCESS && <>
                                            <a className="brand-anchor" href={ApiUrl._API_DOWNLOAD_PAYMENT_RECEIPT.replace(":paymentId", paymentSummary.id)} target={"_parent"}>
                                                <ButtonRound medium className="btn-themed" type={BtnTypes.FILE_DOWNLOAD} title="Download Receipt" />
                                            </a>
                                            <ButtonRound medium className="btn-themed" type={BtnTypes.VIEW_HISTORY} title="View History Payment" onClick={() => _history.push(WebUrl._PAYMENT_HISTORY)} />
                                        </>}
                                    </div>
                                </div>
                                <div className="p-10">
                                    <table style={{ width: '35%' }}>
                                        <tr className="d-flex justify-content-between">
                                            <th>{t("CUSTOMER_NAME")}</th>
                                            <td>{paymentSummary?.paidBy?.name}</td>
                                        </tr>
                                        <tr className="d-flex justify-content-between">
                                            <th>{t("PAYMENT_METHOD")}</th>
                                            <td>
                                                {paymentSummary?.paymentType?.name}
                                            </td>
                                        </tr>
                                        <tr className="d-flex justify-content-between">
                                            <th>{t("TRANSACTION_DATE")}</th>
                                            <td>{moment(paymentSummary.processedDate).format(DateFormat._DATE_FORMAT)}</td>
                                        </tr>
                                        <tr className="d-flex justify-content-between">
                                            <th>{t("TRANSACTION_ID")}</th>
                                            <td>{paymentSummary.transactionId}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="payment-summary-table">
                                <ReactTable
                                    columns={_COLUMNS}
                                    data={paymentItem}
                                    getRowProps={(row) => {
                                        let props = row.getRowProps(row);
                                        return {
                                            ...props,
                                            onClick: () => {
                                                setSelectedRow(row);
                                            },
                                            onDoubleClick: () => {
                                                goTo(row.original?.serviceRequest?.company?.id);
                                            },
                                            className: row == selectedRow ? "selected-row" : ""
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
});

export default PaymentResult;
