import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import {
    Card, CardBody, Col, CardHeader, Row, ModalBody, ModalFooter
} from 'reactstrap';
import { ApiKey, ApiUrl, DateFormat, NotificationPriority, BtnTypes, InputTypes, Icon, SweetAlert, TableId } from '../../util/Constant';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationDao } from 'data';
import { ReactTablev2 as ReactTable } from 'components/react-table';
import moment from 'moment';
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import ButtonRound from 'components/buttons/ButtonRound';
import { Confirm, Notify, Report } from 'notiflix';
import { notificationUpdate, userIdSelector } from 'recoil/Atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useResizeDetector } from 'react-resize-detector';
import { PageSettings } from 'config/page-settings';
import classnames from "classnames";
import { isEmpty } from 'lodash';
import { cleanObject, stringIsNullOrEmpty } from 'util/Utility';
import { useTranslation } from 'react-i18next';
import BrandModal from 'components/modals/BrandModal';

/// <summary>
/// Author: Lewis
/// </summary>
const NotificationView = ({ props }) => {

    const { 
        isXsDevices,
        isSmDevices,
        isMdDevices,
        isLgDevices,
        isXlDevices
    } = useContext(PageSettings);
    const { t } = useTranslation();
    const _tableRef = useRef();
    const _notificationFiltersForm = useRef(null);
    const [resetFilters, setResetFilters] = useState(false);
    const _location = useLocation();
    const [viewNotificationIdx, setViewNotificationIdx] = useState(null);
    const [content, setContent] = useState({});
    const [notificationData, setNotificationData] = useState([]);
    const [checkedItem, setCheckedItem] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const setNotificationUpdate = useSetRecoilState(notificationUpdate);
    const { ref: _tblWrapperRef } = useResizeDetector();
    const userId = useRecoilValue(userIdSelector);

    const _FORM_FIELDS = [
        {
            rowOptions: { xl: 12 },
            columns: [
                { prefix: "#", label: 'Search Title', placeholder: t('SEARCH_TITLE'), name: 'title', input: InputTypes.INPUT, columnOptions: { xl: 6 } },
                { prefix: { icon: Icon._SEARCH }, label: 'Search Message', placeholder: t('SEARCH_MESSAGE'), name: 'messageBody', input: InputTypes.INPUT, columnOptions: { xl: 6 } }
            ]
        }
    ];

    const _COLUMNS = useMemo(() => [
        {
            id: "isRead",
            Header: "READ_UNREAD",
            Cell: ({ row }) => <>
                {row.original.isRead ? <img key={`read-${row.original.id}`} src={require("../../assets/img/icon/notification-readed.svg")} /> : <img key={`read-${row.original.id}`} src={require("../../assets/img/icon/notification-unread.svg")} />}
            </>,
            disableSortBy: true,
            style: { width: 20 },
        },
        {
            Header: "TITLE",
            accessor: "title",
            Cell: ({ row }) => <>
                <div className="ml-0 d-flex td-notification-title" style={{ width: !content && '65vw' }}>
                    <span className={setNotificationColor(row.original.priorityName)}><i class="fas fa-circle fa-xs"></i></span>
                    <div style={{ width: !content && '63vw' }}>
                        <div className={`${!row.original.isRead && "font-weight-bold"}`}>{row.original.title}</div>
                        <div className={`foot-note ${content && "width-50 text-nowrap"}`}><p>{row.original.messageBody}</p></div>
                    </div>
                </div>
            </>,
            style: { width: 150 },
            isRequiredColumn: true
        },
        {
            Header: "CREATED_DATE",
            accessor: el => el.createdDate ? moment(el.createdDate).format(DateFormat._DISPLAY_DATE_TIME) : "N/A",
            Cell: ({ row, value }) => <>
                <div className="foot-note">
                    <p>{
                        // row.original?.createdDate ?
                        //     moment(row.original.createdDate).format(DateFormat._DISPLAY_DATE_TIME) : "N/A"
                        value
                    }</p>
                </div>
            </>,
            style: { width: 60 },
        }
    ], [content]);

    //<summary>
    //Authro: CJ(Jiann)
    //</summary>
    const setNotificationColor = (notificationPriority) => {
        switch (notificationPriority) {
            case NotificationPriority._URGENT:
                return "danger";
            case NotificationPriority._HIGH:
                return "warning";
            case NotificationPriority._NORMAL:
                return "general";
            case NotificationPriority._LOW:
                return "success";
            default:
                return "gray";

        }
    }

    //<summary>
    //Authro: CJ(Jiann)
    //</summary>
    const setNotificationBadge = (notificationPriority) => {
        switch (notificationPriority) {
            case NotificationPriority._URGENT:
                return "badge badge-danger";
            case NotificationPriority._HIGH:
                return "badge badge-warning";
            case NotificationPriority._NORMAL:
                return "badge badge-primary";
            case NotificationPriority._LOW:
                return "badge badge-success";
            default:
                return "badge badge-secondary";
        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const deleteSingleNotificationHandler = (id) => {

        Confirm.Show(
            t(SweetAlert._DELETE_CONFIRMATION),
            t("ARE_YOU_SURE_TO_DELETE"),
            t("YES"),
            t("NO"),
            async () => {
                let dao = new NotificationDao();
                await dao.deleteSingleNotification(id).then((response) => {
                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        // setRefreshTable((prevState) => !prevState);
                        setNotificationUpdate(prev => !prev);
                        _tableRef.current.reFetch();
                        setViewNotificationIdx(null);
                        Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                    }
                    else {
                        Report.Warning(
                            response[ApiKey._API_MESSAGE_KEY],
                            response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                            t(SweetAlert._OK),
                        );
                    }
                })
            }
        );

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const viewNotificationHandler = (idx, id) => {
        if (idx == viewNotificationIdx) {
            setViewNotificationIdx(null);
            return
        }
        setViewNotificationIdx(parseInt(idx));
        readNotifications([id], [idx]);
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (viewNotificationIdx != null) {
            var contentToSet = {
                id: notificationData?.[viewNotificationIdx]?.id,
                title: notificationData?.[viewNotificationIdx]?.title,
                message: notificationData?.[viewNotificationIdx]?.messageBody,
                dateTime: notificationData?.[viewNotificationIdx]?.createdDate && moment(notificationData?.[viewNotificationIdx]?.createdDate).format(DateFormat._DISPLAY_DATE_TIME),
                priorityName: notificationData?.[viewNotificationIdx]?.priorityName,
            };
            contentToSet = cleanObject(contentToSet);
            setContent(contentToSet);
            return;
        }

        setContent({});

    }, [viewNotificationIdx, checkedItem, notificationData])


    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    function readNotifications(ids, idx) {

        let notificationDao = new NotificationDao();

        (async () => {
            await notificationDao.readNotifications(ids).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _tableRef.current.reFetch();
                }
            })
        })();

    }

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    function checkReadNotification() {
        readNotifications(selectedIds, checkedItem);
    }

    /// <summary>
    /// Author: Ong Sze Hua
    /// </summary>
    function deleteNotifications() {

        let notificationDao = new NotificationDao();

        Confirm.Show(t(SweetAlert._DELETE_CONFIRMATION), t("ARE_YOU_SURE_TO_DELETE"), t("YES"), t("NO"), async () => {

            await notificationDao.deleteNotifications(selectedIds).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _tableRef.current.reFetch();
                    setCheckedItem([]);
                    // setNotificationUpdate(prev => !prev);
                    // setViewNotificationIdx(null);
                }
                else {
                    Report.Warning(
                        responseJson[ApiKey._API_MESSAGE_KEY],
                        responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            });
        })
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }

        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (_location.state) {
            let { idx, id } = _location.state
            viewNotificationHandler(idx, id);
        }
    }, [_location.state]);

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const tableHeight = useMemo(() => {
        if (_tblWrapperRef.current != null) {
            return _tblWrapperRef.current.clientHeight;
        }
    }, [_tblWrapperRef?.current]);

    return (<>
        <div className="panel panel-brand panel-flex" id="notificationView">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t("NOTIFICATION")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classnames('panel-body-inner-content with-br-radius', { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}>
                    <div className="d-flex flex-column h-100">
                        <div className="filter-panel-container border-radius-none" >
                            <Row className="filter-panel-body p-t-15">
                                <Col xl={6} className="d-flex justify-content-between">
                                    <div>
                                        <FormBuilder
                                            fields={_FORM_FIELDS}
                                            formRef={_notificationFiltersForm}
                                            onSubmit={filterOnSubmit}
                                            reset={resetFilters}
                                            resetTriggerReset={setResetFilters}
                                            resetValues={{}}
                                        />
                                    </div>
                                    <div className="d-flex btn-list">
                                        <button className="btn btn-themed btn-brand-round pull-left" onClick={() => submitForm(_notificationFiltersForm)}><i className="las la-search" /></button>
                                        <button className="btn btn-themed btn-brand-round pull-left grayscale-100 mr-auto" onClick={resetFiltersFormValue}><i className="las la-redo-alt" /></button>
                                    </div>
                                </Col>
                                <Col xl={6} className="p-0 d-flex justify-content-end btn-list">
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1">
                            <Row className="m-0 h-100">
                                <Col xl={!isEmpty(content) ? 6 : 12} className="p-0">
                                    <div className="h-100 p-0 border-radius-none" ref={_tblWrapperRef}>
                                        <div style={{ height: tableHeight }}>
                                            <ReactTable
                                                ref={_tableRef}
                                                columns={_COLUMNS}
                                                tableMinHeight={tableHeight}
                                                filterFormfield={filtersForm}
                                                url={ApiUrl._API_GET_NOTIFICATIONS}
                                                fetchOnSuccess={(tableData) => setNotificationData(tableData)}
                                                enableCheckbox={true}
                                                setCheckboxIds={setSelectedIds}
                                                customButtons={
                                                    !isEmpty(selectedIds) &&
                                                    <div className="btn-list">
                                                        <button className="btn btn-themed btn-min-width tbl-custom-brand-btn" onClick={checkReadNotification}><i className="fa fa-edit" />{t("MARK_AS_READ")}</button>
                                                        <button className="btn btn-themed btn-min-width grayscale-100 tbl-custom-brand-btn" style={{ width: 100 }}
                                                            onClick={deleteNotifications}><i className="fa fa-trash" /> {t("DELETE")}</button>
                                                    </div>
                                                }
                                                getRowProps={(row) => {
                                                    return {
                                                        onClick: (e) => {
                                                            viewNotificationHandler(row.index, row.original.id);
                                                        },
                                                        style: {
                                                            background: viewNotificationIdx === row.index && '#F9F9FA'
                                                        },
                                                        className: "row-hover"
                                                    }
                                                }}
                                                tableColumnPreference={[userId, TableId._NOTIFICATION_LIST]}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                {
                                    (!isEmpty(content) && isXlDevices) ? 
                                        <Col xl={6} className="p-0">
                                            <Card>
                                                <CardHeader className="notification-header border-radius-none">
                                                    <Row>
                                                        <Col xl={8}>
                                                            <h3>{content.title}</h3>
                                                            <p>{content.dateTime}</p>
                                                        </Col>
                                                        <Col xl={4} className="justify-content-end">
                                                            <span className={setNotificationBadge(content.priorityName)}>{content.priorityName}</span>
                                                            <ButtonRound type={BtnTypes.DELETE} medium onClick={() => { deleteSingleNotificationHandler(content.id) }} />
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="notification-body">
                                                    {content.message}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    :
                                    <BrandModal
                                        isOpen={!isEmpty(content) && !isXlDevices}
                                        toggler={() => setContent({})}
                                        title={"Notification"}
                                        customBody
                                    >
                                        <ModalBody>
                                            <Col xl={6} className="p-0">
                                                <Card>
                                                    <CardHeader className="notification-header border-radius-none">
                                                        <Row className="justify-content-around">
                                                            <div>
                                                                <h3>{content.title}</h3>
                                                                <p>{content.dateTime}</p>
                                                            </div>
                                                            <div className="justify-content-end">
                                                                <span className={setNotificationBadge(content.priorityName)}>{content.priorityName}</span>
                                                                <ButtonRound type={BtnTypes.DELETE} medium onClick={() => { deleteSingleNotificationHandler(content.id) }} />
                                                            </div>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody className="notification-body" style={{whiteSpace: 'pre-wrap'}}>
                                                        {content.message}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={() => setContent({})}>{t("CANCEL")}</button>
                                        </ModalFooter>
                                    </BrandModal>
                                }
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default NotificationView;