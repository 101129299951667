import React, { useState, useMemo, useEffect, useContext, useRef, useImperativeHandle } from 'react';
import { CardBody, ModalBody, Card, ModalFooter, Col, Row, CardHeader, Collapse } from 'reactstrap';
import StepZilla from "react-stepzilla";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { authCredentialState, personFormIdentitiesState } from 'recoil/Atoms';
import { OfficerTypeID, ApiKey, ApiUrl, InputTypes, RegexValidation } from 'util/Constant';
import moment from 'moment';
import { createOfficerFormState } from 'recoil/Incorporation';
import { isEmpty, stubTrue } from 'lodash';
import InputHoc from 'components/form/InputHoc';
import { useForm } from 'react-hook-form';
import { cleanObject, formatCountryList, formatJurisdictionList, stringIsNullOrEmpty } from 'util/Utility';
import { Report } from 'notiflix';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

const _FORM_KEYS = { INDIVIDUAL: 'individual', CORPORATE: 'corporate' };

///<summary>
///Author: Lewis
///</summary>
const CreateOfficerForm = React.forwardRef((props, ref) => {
    let {
        index,
        companyId,
        onCreateNewOfficer,
        officerTypeId,
        setFetchOfficerId,
        jumpToStep: outerJumpToStep,
        refreshCompanyOfficers
    } = props;

    const { t } = useTranslation();

    const [currentStep, setCurrentStep] = useState(0);
    const stepFunctionRef = useRef(null);
    const setCreateOfficerFormValues = useSetRecoilState(createOfficerFormState);
    const resetCreateOfficerFormValue = useResetRecoilState(createOfficerFormState);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const backToSearchForm = () => {
        outerJumpToStep(0);
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useImperativeHandle(ref, () => ({
        // created officer that which bind to company
        onSuccessCreateCompanyOfficer(responseValue) {
            let officerType = responseValue.officer.officerTypeId == OfficerTypeID._CORPORATE ? "Corporate" : "Individual";
            let msg = responseValue.officer.officerTypeId == OfficerTypeID._CORPORATE ? t("SUCCESS_ADD_CORP_OFFICER") : t("SUCCESS_ADD_IND_OFFICER");

            Report.Success(
                t("notiflix:ADD_COMPANY_OFFICER_SUCCESS"),
                msg,
                t("notiflix:OK"), () => {

                    if (typeof (refreshCompanyOfficers) == "function") {
                        // refreshCompanyOfficers(companyId);
                        refreshCompanyOfficers();
                    }

                    resetCreateOfficerFormValue();

                    // populate officer details form
                    setCreateOfficerFormValues(responseValue.officer);
                    setFetchOfficerId(responseValue.officer.id);

                    // jump to step 3 - display values for role assignment
                    outerJumpToStep(3);
                });
        },
        // created officer that which not yet bind to company
        onSuccessCreateOfficer(responseValue) {

            let officerType = responseValue.officerTypeId == OfficerTypeID._CORPORATE ? "Corporate" : "Individual";
            let msg = responseValue.officerTypeId == OfficerTypeID._CORPORATE ? t("SUCCESS_ADD_CORP_OFFICER") : t("SUCCESS_ADD_IND_OFFICER");

            Report.Success(
                t("SUCCESS_ADD_CORP_OFFICER"),
                msg,
                t("OK"), () => {
                    resetCreateOfficerFormValue();

                    // populate officer details form
                    setCreateOfficerFormValues(responseValue);
                    setFetchOfficerId(responseValue.id);

                    // jump to step 3 - display values for role assignment
                    outerJumpToStep(3);
                });
        }
    }), [t]);

    ///<summary>
    ///Author: Lewis
    /// reset form recoil state when component unmounted
    ///</summary>
    useEffect(() => {
        return resetCreateOfficerFormValue;
    }, []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const _wizardFormArray = useMemo(() => {
        return [
            {
                name: officerTypeId == OfficerTypeID._CORPORATE ? t('CORPORATE_DETAIL') : t('OFFICER_DETAIL'),
                component: <OfficerDetailsForm
                    officerTypeId={officerTypeId}
                    ref={stepFunctionRef}
                />
            },
            {
                name: t('ADDRESS_DETAIL'),
                component: <OfficerAttachmentForm
                    officerTypeId={officerTypeId}
                    companyId={companyId}
                    refreshCompanyOfficers={refreshCompanyOfficers}
                    ref={stepFunctionRef}
                    onCreateNewOfficer={onCreateNewOfficer}
                    setCreateOfficerFormValues={setCreateOfficerFormValues}
                />
            },
        ]
    }, [officerTypeId, companyId, refreshCompanyOfficers, stepFunctionRef, onCreateNewOfficer]);


    return <>
        <div>
            <StepZilla
                id="create-officer-multistep"
                className="step-zilla step-zilla-brand w-100"
                preventEnterSubmission={true}
                showNavigation={false}
                steps={_wizardFormArray}
                showSteps={true}
                stepsNavigation={false}
                nextButtonCls='btn btn-prev pull-right'
                backButtonCls='btn btn-next pull-left'
                startAtStep={0}
                onStepChange={(step) => setCurrentStep(step)}
            />
        </div>
        <ModalFooter className="panel-foot panel-foot-buttons justify-content-end">
            <button
                className="btn btn-themed btn-mid-long grayscale-100"
                onClick={() => {
                    currentStep == 0 ? backToSearchForm() : stepFunctionRef.current.backToFirstStep();
                }}>{t("BACK")}</button>
            <button className="btn btn-themed btn-mid-long m-l-5" onClick={() => {
                if (currentStep == 0) {

                    stepFunctionRef.current.submitOfficerDetails();
                    return;
                }
                stepFunctionRef.current.createOfficer();
            }}>{t("NEXT")}</button>
        </ModalFooter>
    </>
});

///<summary>
///Author: Lewis
///</summary>
const OfficerDetailsForm = React.forwardRef((props, ref) => {

    let { officerTypeId, jumpToStep } = props;

    const { t } = useTranslation();
    const [isReset, setIsReset] = useState(false);
    const [countryAbbv, setCountryAbbv] = useState('hk');
    const [firstSubmit, setFirstSubmit] = useState(false);
    const _ID_OPTIONS = useRecoilValue(personFormIdentitiesState);
    const { firmId, ...restAuthProps } = useRecoilValue(authCredentialState);
    const { control, handleSubmit, errors, reset, formState, trigger } = useForm();
    const [createOfficerFormValue, setCreateOfficerFormValues] = useRecoilState(createOfficerFormState);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const inputFields = useMemo(() => {

        let fields = {
            [_FORM_KEYS.INDIVIDUAL]: [
                { label: 'ENGLISH_NAME', name: 'englishName', rules: { required: "required" } },
                {
                    label: 'NATIONALITY', name: 'nationality', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" },
                    onChange: (countryId) => {
                        setCountryAbbv(countryOptions.filter(x => x.id == countryId)[0].shortCode.toLowerCase())
                    },
                },
                { label: 'WECHAT', name: 'wechat', rules: { required: false } },
                { label: 'ENGLISH_SURNAME', name: 'englishSurname', rules: { required: "required" } },
                {
                    label: 'PHONE_NUMBER', name: 'phoneNumber', inputType: InputTypes.PHONE, country: countryAbbv, rules: { required: "required" },
                },
                { label: 'DATE_OF_BIRTH', name: 'dateOfBirth', inputType: InputTypes.DATEPICKER, rules: { required: "required" }, defaultValue: moment().toDate() },
                { label: 'CHINESE_NAME', name: 'chineseName', rules: { required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") } } },
                {
                    label: 'IDENTITY_TYPE', name: 'identityType', options: _ID_OPTIONS.map((item) => {return {label: t(`${item.label}`), value: item.value}}), inputType: InputTypes.SELECT, rules: { required: "required" },
                },
                {
                    label: 'EMAIL', name: 'email', type: 'email',
                    rules: {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                        }
                    },
                },
                {
                    label: 'CHINESE_SURNAME', name: 'chineseSurname', rules: { required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") } },
                },
                { label: 'IDENTIFICATION_NUM', name: 'identityNumber', rules: { required: "Idenfication Number is required" } },
                { name: 'firmId', rules: { required: false }, hidden: true, value: firmId ? firmId : null },
            ],
            [_FORM_KEYS.CORPORATE]: [
                { label: 'ENGLISH_NAME', name: 'englishName', rules: { required: "required" }, columnOptions: { xl: 6 } },
                {
                    label: 'CHINESE_NAME', name: 'chineseName', columnOptions: { xl: 6 },
                    rules: {
                        required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") }
                    }
                },
                { label: 'JURISDICTION_OF_INCORP', name: 'jurisdictionId', options: jurisdictionOptions, inputType: InputTypes.SELECT, rules: { required: "required" } },
                { label: 'DATE_OF_INCORP', name: 'dateOfIncorporation', inputType: InputTypes.DATEPICKER, rules: { required: "required" }, defaultValue: moment().toDate() },
                { label: `CI ${t('NUMBER_SHORT')}`, name: 'ciNumber', rules: { required: "required" }, columnOptions: { xl: 6 } },
                { label: 'CERTIFICATE_ATTACHMENT', name: 'certificateAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, single: true, rules: { required: "required" }, columnOptions: { xl: 6 } },
                { name: 'firmId', rules: { required: false }, hidden: true, value: firmId ? firmId : null },
            ]
        }

        let fieldsToRender = fields[officerTypeId == OfficerTypeID._CORPORATE ? _FORM_KEYS.CORPORATE : _FORM_KEYS.INDIVIDUAL];
        return fieldsToRender.map(xprops => {
            if (isEmpty(xprops)) return {};
            return { control, error: errors?.[xprops.name], ...xprops };
        });

    }, [
        officerTypeId,
        countryAbbv,
        errors,
        firmId,
        formState,
        countryOptions,
        jurisdictionOptions
    ]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const onFormSubmit = (formValue) => {
        formValue = cleanObject(formValue);
        setCreateOfficerFormValues(prev => { return { ...prev, ...formValue } });
        if (typeof (jumpToStep) == "function") {
            jumpToStep(1);
        }
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useImperativeHandle(ref, () => ({
        submitOfficerDetails() {
            handleSubmit(onFormSubmit)();
        }
    }), []);

    useEffect(() => {
        handleSubmit(onFormSubmit)();
        setFirstSubmit(true)
    }, []);

    useEffect(() => {
        if (!isEmpty(createOfficerFormValue) && firstSubmit) {
            reset(createOfficerFormValue);
            setIsReset(true);
        }
    }, [reset, createOfficerFormValue, firstSubmit]);

    useEffect(() => {
        if (isReset)
            trigger();
    }, [trigger, isReset]);

    return <div className="create-officer-details-1">
        <Row className="m-0">
            {
                officerTypeId == OfficerTypeID._INDIVIDUAL ?
                    <Col xl={12}>
                        <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/ui/individual.svg")} />{t("PERSONAL_DETAIL")}</strong></p>
                    </Col>
                    :
                    <>
                        <Col xl={6}>
                            <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/ui/corporate.svg")} />{t("COMPANY_DETAIL")}</strong></p>
                        </Col>
                        <Col xl={6}>
                            <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/icon/attachment.svg")} />CI {t("DETAIL")}</strong></p>
                        </Col>
                    </>
            }
        </Row>
        <form onSubmit={handleSubmit(onFormSubmit)} autoComplete="off">
            {
                officerTypeId == OfficerTypeID._INDIVIDUAL ?
                    <Row className="m-0">
                        {
                            inputFields.map((fieldProps, index) => {
                                if (isEmpty(fieldProps)) return <Col xl={4}></Col>

                                return (
                                    <React.Fragment key={fieldProps.name}>
                                        <Col xl={4}><InputHoc {...fieldProps} /></Col>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Row> :
                    <Row className="m-0">
                        <Col className="p-0" xl={6}>
                            <Row className="m-0">
                                {
                                    inputFields.slice(0, 4).map((fieldProps, index) => {
                                        return (
                                            <React.Fragment key={fieldProps.name}>
                                                <Col xl={12}><InputHoc {...fieldProps} /></Col>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        <Col className="p-0" xl={6}>
                            {
                                inputFields.slice(4, 6).map((fieldProps, index) => {
                                    return (
                                        <React.Fragment key={fieldProps.name}>
                                            <Col xl={12}><InputHoc {...fieldProps} /></Col>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Col>
                    </Row>
            }
        </form>
    </div >
});

///<summary>
///Author: Lewis
///</summary>
const OfficerAttachmentForm = React.forwardRef((props, ref) => {

    let {
        officerTypeId,
        jumpToStep,
        onCreateNewOfficer,
        onSuccessCreateOfficer,
        setCreateOfficerFormValues
    } = props;

    const createOfficerFormValue = useRecoilValue(createOfficerFormState);
    const { firmId, ...restAuthProps } = useRecoilValue(authCredentialState);
    const { control, handleSubmit, errors, reset, register, formState, getValues, trigger } = useForm();

    const { t } = useTranslation();
    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    let inputFields = useMemo(() => {

        let fields = {
            [_FORM_KEYS.INDIVIDUAL]: [
                { label: 'ADDRESS', name: 'address1', inputType: InputTypes.TEXTAREA, minRows: 4, rules: { required: "required" }, columnOptions: { xl: 6 } },
                { label: 'ID_ATTACHMENT', name: 'identityAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, rules: { required: "required" }, columnOptions: { xl: 3 } },
                { label: 'ADDRESS_PROOF', name: 'addressProofAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, rules: { required: "required" }, columnOptions: { xl: 3 } },
                { label: 'COUNTRY', name: 'countryId', id: 'ctry', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" }, columnOptions: { xl: 6 } },
            ],
            [_FORM_KEYS.CORPORATE]: [
                { label: 'ADDRESS', name: 'address1', inputType: InputTypes.TEXTAREA, minRows: 4, rules: { required: "required" } },
                { label: 'COUNTRY', name: 'countryId', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" } },
            ]
        }

        let fieldsToRender = fields[officerTypeId == OfficerTypeID._CORPORATE ? _FORM_KEYS.CORPORATE : _FORM_KEYS.INDIVIDUAL];
        return fieldsToRender.map(xprops => {
            return { control, error: errors?.[xprops.name], ...xprops };
        });

    }, [
        control,
        officerTypeId,
        errors,
        formState,
        countryOptions
    ]);

    ///<summary>
    ///Author: Lewis
    /// call this function from parent component
    ///</summary>
    useImperativeHandle(ref, () => ({
        backToFirstStep() { jumpToStep(0); setAttachmentFormToOfficerForm(); },
        createOfficer() { handleSubmit(onFormSubmit)() }
    }), []);

    useEffect(() => {
        handleSubmit(onFormSubmit)();
    }, []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {
        if (!isEmpty(createOfficerFormValue)) {
            reset(createOfficerFormValue);
        }
    }, [createOfficerFormValue]);

    const setAttachmentFormToOfficerForm = async () => {
        const formData = getValues();
        setCreateOfficerFormValues(prev => { return { ...prev, ...formData } });
    };

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const onFormSubmit = async (formValue) => {
        formValue = cleanObject(formValue);
        if (!stringIsNullOrEmpty(firmId)) {
            formValue.firmId = firmId;
        }
        let postData = { ...createOfficerFormValue, ...formValue };
        // on create callback
        if (typeof (onCreateNewOfficer) == "function") {
            onCreateNewOfficer(postData);
        }
    }

    return <div className="create-officer-address-attachment-2">
        {
            officerTypeId == OfficerTypeID._INDIVIDUAL ?
                <Row md={2} className="m-0">
                    <Col xl={6}>
                        <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/icon/address-info.svg")} />{t("ADDRESS_INFO")}</strong></p>
                    </Col>
                    <Col xl={6}>
                        <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/icon/attachment.svg")} /> {t("ATTACHMENT")}</strong></p>
                    </Col>
                    {
                        inputFields.map(fieldProps => {
                            return <React.Fragment key={fieldProps.name}>
                                <Col {...fieldProps.columnOptions}>
                                    <InputHoc {...fieldProps} />
                                </Col>
                            </React.Fragment>
                        })
                    }
                </Row> :
                <Row className="m-0">
                    <Col xl={12} className="p-0">
                        <p><strong><img className="step-incomplete m-r-5" src={require("../../../assets/img/icon/address-info.svg")} /> {t("ADDRESS_INFO")}</strong></p>
                        <Row className="m-0">
                            <Col xl={6} className="p-0">
                                <Row className="m-0">
                                    {
                                        inputFields.map(fieldProps => {
                                            return <React.Fragment key={fieldProps.name}>
                                                <Col xl={12}>
                                                    <InputHoc {...fieldProps} />
                                                </Col>
                                            </React.Fragment>
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        }
    </div >
});

export default CreateOfficerForm;