import React, { useState, forwardRef, useEffect, useMemo } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { Controller, useFormContext, useForm } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import classNames from "classnames";
import { isEmpty } from "lodash";
import ModalImage from 'react-modal-image';
import { stringIsNullOrEmpty } from "util/Utility";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

///<summary>
///Author: Sze Hua
///</summary>
const FileUpload = props => {
    const { accept, disabled, editable, className, miscLabel, miscLabelClass, ...restProps } = props;

    return <Controller
        {...restProps}
        render={({ onChange, value }) => <CustomDropZone
            {...restProps}
            // onDrop={(acceptedFiles) => {
            //     let files;
            //     if (Object.keys(acceptedFiles).length === 0) {
            //         files = null;
            //     }
            //     else {
            //         files = acceptedFiles;
            //     }
            //     onChange(files)
            // }}
            onChange={onChange}
            value={value}
            accept={accept}
            disabled={disabled}
            editable={editable}
            className={className}
            miscLabel={miscLabel}
            miscLabelClass={miscLabelClass}
        />}
    />
}

///<summary>
///Author: Sze Hua
///</summary>
const CustomDropZone = props => {
    const { accept: propsAccept, disabled: propsDisabled, onChange, value: propsValue, editable, className: propsClassName, miscLabel, miscLabelClass, ...restProps } = props;

    const AcceptFileType = {
        _PDF: { type: "application/pdf", formatMsg: ".pdf" },
        _MSWORD: { type: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document", formatMsg: ".doc, .docx" },
        _IMAGE: { type: "image/jpeg, image/png", formatMsg: ".jpg, .png" }
    };
    const { t } = useTranslation();
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (!isEmpty(propsValue)) {
            const promises = propsValue.map(async fileObject => {
                let { filename, requestPath } = fileObject;
                if (!stringIsNullOrEmpty(filename) && !stringIsNullOrEmpty(requestPath)) {
                    const base64String = await getBase64String(requestPath).then(result => result);
                    return dataURLtoFile(base64String, filename);
                }
                else {
                    return fileObject;
                }
            });
            Promise.all(promises).then(values => { setValue(values) });
        }
        else {
            setValue([]);
        }
    }, [propsValue]);

    const { acceptFile, acceptFileFormat } = useMemo(() => {
        let acceptFile = [];
        let acceptFileFormat = [];
        if (!isEmpty(propsAccept)) {
            let { pdf, msword, image } = propsAccept;
            if (pdf) {
                acceptFile.push(AcceptFileType._PDF.type);
                acceptFileFormat.push(AcceptFileType._PDF.formatMsg);
            }
            if (msword) {
                acceptFile.push(AcceptFileType._MSWORD.type);
                acceptFileFormat.push(AcceptFileType._MSWORD.formatMsg);
            }
            if (image) {
                acceptFile.push(AcceptFileType._IMAGE.type);
                acceptFileFormat.push(AcceptFileType._IMAGE.formatMsg);
            }
        }
        else {
            acceptFile.push(AcceptFileType._PDF.type);
            acceptFileFormat.push(AcceptFileType._PDF.formatMsg);
            acceptFile.push(AcceptFileType._MSWORD.type);
            acceptFileFormat.push(AcceptFileType._MSWORD.formatMsg);
        }
        return { acceptFile: acceptFile.join(", "), acceptFileFormat: acceptFileFormat.join(", ") };
    }, [propsAccept]);

    const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
        //onDrop,
        multiple: false,
        accept: acceptFile
    });

    const renderPreview = (fileType, file) => {
        if (AcceptFileType._IMAGE.type.split(", ").includes(fileType)) {
            return <ModalImage small={URL.createObjectURL(file)} medium={URL.createObjectURL(file)} alt="preview" className="image-preview" />;
        }
        else if (AcceptFileType._PDF.type === fileType) {
            return <div className="d-flex flex-column">
                <a className="brand-anchor align-self-center" rel="noopener noreferrer" style={{ width: 'fit-content' }}
                    href={URL.createObjectURL(file)}
                    target={"_blank"}>
                    <img src={require("../../assets/img/pdf-file-icon.png")} className="m-auto preview-file-icon" />
                </a>
                <span className="preview-file-name">{file.name}</span>
            </div>;
        }
        else if (AcceptFileType._MSWORD.type.split(", ").includes(fileType)) {
            return <div className="d-flex flex-column">
                <a className="brand-anchor align-self-center" rel="noopener noreferrer" style={{ width: 'fit-content' }}
                    href={URL.createObjectURL(file)}
                    target={"_blank"} download={file.name}>
                    <img src={require("../../assets/img/msword-file-icon.png")} className="m-auto preview-file-icon" />
                </a>
                <span className="preview-file-name">{file.name}</span>
            </div>;;
        }
    }

    const removeFile = () => {
        onChange(null);
    }

    useEffect(() => {
        if (!isEmpty(acceptedFiles)) {
            onChange(acceptedFiles);
        }
        else {
            onChange(null);
        }
    }, [acceptedFiles])

    const getBase64String = async (fileUrl) => {
        const data = await fetch(fileUrl);
        const blob = await data.blob();

        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onloadend = function (e) {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    }

    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    return isEmpty(value) ? (
        <>
            <div className={classNames(propsClassName)}>
                {!propsDisabled ? (
                    <div className={classNames("file-upload")}>
                        <div {...getRootProps()} className={classNames("w-100 h-100 file-upload-wrapper")} tabIndex='-1'>
                            <input {...getInputProps()} />
                            <div className="d-flex flex-column text-center f-s-14">
                                <img className="m-l-auto m-r-auto" src={require("../../assets/img/ui/upload-file.svg")} />
                                <p className="text-primary f-w-600">{t("PLEASE_DROP_FILE_HERE_OR_CLICK_TO_UPLOAD")}</p>
                                <p>{`(${t("ONLY_ACCEPT")} ${acceptFileFormat} ${t("FORMAT")})`}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="upload__image-wrapper file-preview">
                        <div className="imagePreviewWrapper">
                            <i className="fas fa-box-open fa-5x m-auto opacity-3" />
                        </div>
                    </div>
                )}
            </div>
            <div className="file-preview-misc"></div>
        </>
    ) : (
        <>
            <div className={classNames(propsClassName)}>
                <div className="upload__image-wrapper file-preview">
                    <div className="imagePreviewWrapper">
                        {
                            value.map((file, index) => (
                                <div key={index} className="imageItemPreviewContainer">
                                    {
                                        // (!propsDisabled) && <>
                                        //     <button className="btn btn-round btn-sm imageDeleteBtn" type="button" onClick={removeFile} ><i className="las la-trash-alt text-red" /></button>
                                        // </>
                                    }
                                    {
                                        // editable &&
                                        // <div {...getRootProps()}>
                                        //     <input {...getInputProps()} />
                                        //     <button className="btn btn-round btn-sm imageUploadBtn" type="button"><i className="fas fa-cloud-upload-alt" /></button>
                                        // </div>
                                    }
                                    {
                                        renderPreview(file.type, file)
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="file-preview-overlay imagePreviewWrapper">
                        <div className="file-preview-overlay-text"><img alt="" src={require("../../assets/img/icon/fullscreen.svg")} className="mr-1" />{t('CLICK_TO_EXPAND')}</div>
                    </div>
                </div>
            </div>
            <div className="d-flex file-preview-misc">
                <span className={miscLabelClass}>{miscLabel}</span>
                {(!propsDisabled) && (
                    <div className="btn btn-themed-default text-light-darker file-delete-btn ml-auto" onClick={removeFile}><img src={require("../../assets/img/icon/icon-deletegrey.svg")} />{t('DELETE')}</div>
                )}
            </div>
        </>
    )
}

// const FileUpload = (props) => {
//     const { button, ...restProps } = props;
//     const blackColor = 'black';
//     const greyColor = '#c6ced5';
//     const [borderColor, setBorderColor] = useState(greyColor);
//     const [txtColor, setTxtColor] = useState(blackColor);

//     ///<summary>
//     ///Author: Ong Sze Hua
//     ///</summary>
//     const onDrop = (acceptedFiles, onChange) => {
//         if (Object.keys(acceptedFiles).length === 0) {
//             let color = 'red';
//             setBorderColor(color);
//             setTxtColor(color);
//             onChange("");
//         }
//         else {
//             setBorderColor(greyColor);
//             setTxtColor(blackColor)
//             onChange(acceptedFiles);
//         }
//     };

//     ///<summary>
//     ///Author: Ong Sze Hua
//     ///</summary>
//     function render(files) {
//         let msg = null;
//         if (Object.keys(files).length !== 0) {
//             files.map(file => msg = file.name)
//         }
//         else {
//             if (button) {
//                 return <p className="m-0 p-0 f-s-10" style={{ color: txtColor }}>(Only accept .PDF, .DOC, .DOCX format)</p>
//             }
//             else {
//                 return <div className="d-flex flex-column">
//                     <img className="m-l-auto m-r-auto" src={require("../../assets/img/ui/upload-file.svg")} />
//                     <p className="text-primary f-w-600">Please drop file here or click to upload</p>
//                     <p>(Only accept .PDF, .DOC, .DOCX format)</p>
//                 </div>
//             }
//         }
//         return msg;
//     }

//     return (
//         <Controller
//             {...restProps}
//             render={({ onChange }) => <div
//                 style={{
//                     borderColor: borderColor
//                 }}
//                 className={classNames({ "file-upload": !button })}
//             >
//                 <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles, onChange)} multiple={false}
//                     accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//                 >
//                     {({ getRootProps, getInputProps, acceptedFiles }) => (
//                         <div {...getRootProps()} style={{ width: '100%', height: '100%' }} className={classNames({ "file-upload-wrapper": !button })} tabIndex='-1'>
//                             <input {...getInputProps({ onChange })} />
//                             {
//                                 button ? <>
//                                     <Row className="m-0 p-0">
//                                         <Col xs='5' className="m-0 p-0"><button type="button" className="btn btn-sm btn-indigo">Choose File</button></Col>
//                                         <Col xs='7' className="m-0 p-0  d-flex align-items-center">{render(acceptedFiles)}</Col>
//                                     </Row>
//                                 </>
//                                     :
//                                     <span style={{ color: txtColor, textAlign: 'center' }} className="f-s-14">
//                                         {render(acceptedFiles)}
//                                     </span>
//                             }

//                         </div>
//                     )}
//                 </Dropzone>
//             </div>}
//         />
//     );
// }


FileUpload.propTypes = {
    accept: PropTypes.object,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    className: PropTypes.string,
    miscLabel: PropTypes.string,
    miscLabelClass: PropTypes.string,
}

FileUpload.defaultProps = {
    accept: { pdf: true, msword: true, image: false },
    disabled: false,
    editable: false,
    className: "",
    miscLabel: "",
    miscLabelClass: "",
}

export default FileUpload;