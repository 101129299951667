import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { InputTypes, ApiKey, LoadingStateText, SweetAlert, ApiUrl } from 'util/Constant';
import { useForm } from 'react-hook-form';
import { RoleDao } from 'data';
import InputHoc from "components/form/InputHoc";
import Notiflix, { Block } from 'notiflix';
import { isEmpty } from 'lodash';
import BrandModal from 'components/modals/BrandModal';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { formatJurisdictionList } from 'util/Utility';

//<summary>
//Author: Robin
//</summary>
export const RoleModal = props => {

    const { modal, setModal, setRefreshTable, role, setRole } = props;

    const _ref = useRef();
    const { t } = useTranslation();
    const { register, control, handleSubmit, errors, reset, watch } = useForm();

    const toggle = () => {
        setModal(!modal);
        setRole({});
    }

    const createOrUpdateRole = async (data) => {
        Block.Circle("div#role", LoadingStateText._PLEASE_WAIT);
        if (role.id != null) data.id = role.id;

        let dao = new RoleDao();

        await dao.createOrUpdateRole(data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t('notiflix:CHANGE_ROLE_SUCCESS'));
                setRefreshTable(prevState => !prevState);
                toggle();
            }
            else {
                Notiflix.Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t(SweetAlert._ERROR_HAS_OCCUR),
                    t(SweetAlert._OK),
                );
            }
        }).finally(() => Block.Remove("div#role"));
    };

    useEffect(() => {
        if (role.id != null) {
            reset(role);
        }
        else
            reset({});
    }, [role])

    return (<>
        <BrandModal
            isOpen={modal}
            toggler={toggle}
            title={isEmpty(role) ? t("systemConfiguration:ADD_ROLE") : t("systemConfiguration:EDIT_ROLE")}
            customBody
        >
            <ModalBody id="role">
                <form onSubmit={handleSubmit(createOrUpdateRole)} ref={_ref} autoComplete="off">
                    <Row>
                        <Col xl={12}>
                            <InputHoc name="name" label="NAME" inputType={InputTypes.INPUT} control={control} rules={{ required: 'required' }} error={errors?.name?.message} />
                            <InputHoc name="description" label="DESCRIPTION" inputType={InputTypes.TEXTAREA} ref={register} maxRows={4} />
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-sm btn-themed btn-min-width grayscale-100" onClick={toggle}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-sm btn-themed btn-min-width" onClick={() => handleSubmit(createOrUpdateRole)()}>{t("SAVE")}</button>
            </ModalFooter>
        </BrandModal>
    </>);
};

export default RoleModal;