import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from "react";
import { orderInfoState } from 'recoil/Atoms';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import AlipayQR from 'assets/img/sample-alipay-qr.png';
import ReactCreditCards from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss';
import { Card, CardBody, CardHeader } from 'reactstrap';
import FormBuilder from "components/form/FormBuilder";
import {
    PaymentMethod,
    InputTypes,
    PaymentStatus,
    DefaultCurrency,
    ApiKey,
    StripeSourceStatus,
    SweetAlert
} from 'util/Constant';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    stringIsNullOrEmpty,
    constructStripeTransactionType,
    isObjectEmpty
} from 'util/Utility';
import { useForm } from 'react-hook-form';
import InputHoc from 'components/form/InputHoc';
import { useHistory, useLocation } from 'react-router-dom';
import { Loading, Report } from "notiflix";
import { PaymentDao } from "data";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Lewis
/// </summary>
const Payment = React.forwardRef((props, ref) => {
    const { jumpToStep } = props;

    const { t } = useTranslation();
    const { control, handleSubmit, errors, register, watch, formState: { isSubmitted } } = useForm();
    const formRef = useRef();

    const _POLLING_TIMEOUT = 5000;
    const [creditCardState, setCreditCardState] = useState({
        cvc: '888',
        expiry: '1212',
        focus: '',
        name: 'XXXXXXXXXX',
        number: '4242424242424242',
    });
    const orderInfoRecoil = useRecoilValue(orderInfoState);
    const resetOrderInfoRecoil = useResetRecoilState(orderInfoState);
    const [cardError, setCardError] = useState("");
    const sourceInterval = useRef(null);
    const chargeInterval = useRef(null);
    const _history = useHistory();
    const _location = useLocation();
    const stripe = useStripe();
    const element = useElements();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const inputOnChangeHandler = ({ target }) => {
        switch (target.name) {
            case 'number':
                target.value = formatCreditCardNumber(target.value);
                setCreditCardState({ ...creditCardState, [target.name]: target.value })
                return
            case 'expiry':
                target.value = formatExpirationDate(target.value);
                setCreditCardState({ ...creditCardState, [target.name]: target.value })
                return
            case 'cvc':
                target.value = formatCVC(target.value);
                setCreditCardState({ ...creditCardState, [target.name]: target.value })
                return
            default:
                setCreditCardState({ ...creditCardState, [target.name]: target.value })
                break
        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const inputOnFocusHandler = ({ target }) => {
        setCreditCardState({ ...creditCardState, ['focus']: target.name })
    }

    const _fields = [
        {
            columns: [
                {
                    label: "Card Number", name: 'number', type: 'tel', columnOptions: { xl: 12 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Card Number is required' }
                },
                {
                    label: "Name", name: 'name', columnOptions: { xl: 12 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Name is required' }
                },
                {
                    label: "Expiry", name: 'expiry', columnOptions: { xl: 6 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Expiry is required' }
                },
                {
                    label: "CVC", name: 'cvc', columnOptions: { xl: 6 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'CVC is required' }
                },
            ]
        }
    ]

    const _chequefields = [
        {
            columns: [
                {
                    label: "Payee", name: 'name', columnOptions: { xl: 12 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Payee Details is required' }
                },
                {
                    label: "Amount ($)", name: 'number', columnOptions: { xl: 12 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Payment Amount is required' }
                },
                {
                    label: "Cheque Number", name: 'number', columnOptions: { xl: 12 },
                    onChange: inputOnChangeHandler, onFocus: inputOnFocusHandler, rules: { required: 'Cheque Number is required' }
                },
            ]
        }
    ]

    /// <summary>
    /// Author: YJ
    /// </summary>
    const ProcessChargePolling = async (chargeId) => {
        chargeInterval.current = setInterval(async () => {
            try {
                let paymentDao = new PaymentDao();
                await paymentDao.processWechatPayCharge(chargeId).then(res => {
                    if (res[ApiKey._API_SUCCESS_KEY]) {
                        let payment = res[ApiKey._API_DATA_KEY];

                        if (payment.status !== PaymentStatus._PENDING) {
                            clearInterval(chargeInterval.current);
                            _history.replace({
                                pathname: _location.pathname,
                                state: {
                                    data: {
                                        paymentId: payment.id,
                                        showStep: true
                                    }
                                }
                            })

                            Report.Success("Success", "Payment Successful", "OK", () => jumpToStep(2));
                        }
                    }
                    else {
                        throw res[ApiKey._API_MESSAGE_KEY];
                    }
                }).catch(err => {
                    throw err;
                });
            }
            catch (err) {
                clearInterval(chargeInterval.current);
            }
        }, _POLLING_TIMEOUT);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const ProcessSourcePolling = async () => {
        try {
            let details = orderInfoRecoil.details;
            let callApiStatusArray = [StripeSourceStatus._CANCELED, StripeSourceStatus._SUCCESS, StripeSourceStatus._FAILED];

            stripe.retrieveSource({ id: details.sourceId, client_secret: details.clientSecret })
                .then(async (res) => {
                    var source = res.source;
                    if (callApiStatusArray.includes(source.status)) {
                        let paymentDao = new PaymentDao();
                        await paymentDao.processWechatPaySource(source.id).then(async (res) => {
                            if (res[ApiKey._API_SUCCESS_KEY]) {
                                /// mean having charge Id
                                if (!stringIsNullOrEmpty(res[ApiKey._API_DATA_KEY]["chargeId"])) {
                                    /// if the charge status is success, then redirect to payment page. There is no need for
                                    /// interval for charge
                                    if (res[ApiKey._API_DATA_KEY]["chargeStatus"] == PaymentStatus._SUCCESS) {
                                        _history.replace({
                                            pathname: _location.pathname,
                                            state: {
                                                data: {
                                                    paymentId: res[ApiKey._API_DATA_KEY]["paymentId"],
                                                    showStep: true
                                                }
                                            }
                                        })

                                        Report.Success("Success", "Payment Successful", "OK", () => jumpToStep(2));
                                    }
                                    else {
                                        await ProcessChargePolling(res[ApiKey._API_DATA_KEY]["chargeId"]);
                                    }
                                }
                                /// mean it was the failed payment, directly redirect to payment page
                                else {
                                    _history.replace({
                                        pathname: _location.pathname,
                                        state: {
                                            data: {
                                                paymentId: res[ApiKey._API_DATA_KEY]["paymentId"],
                                                showStep: true
                                            }
                                        }
                                    })
                                }
                                clearInterval(sourceInterval.current);
                            }
                            else {
                                throw res[ApiKey._API_MESSAGE_KEY];
                            }
                        }).catch(err => {
                            throw err;
                        });
                    }
                })
                .catch((err) => {
                    throw err;
                })
        }
        catch (err) {
            clearInterval(sourceInterval.current);
        }
    }

    /// <summary>
    /// Author: Wind
    /// Editted: YJ
    /// </summary>
    useEffect(() => {
        /// currently only credit card and wechat will enter here
        if ((orderInfoRecoil.paymentMethod != PaymentMethod._CREDITCARD && orderInfoRecoil.paymentMethod != PaymentMethod._WECHATPAY) ||
            (orderInfoRecoil.paymentMethod == PaymentMethod._CREDITCARD && orderInfoRecoil.carts == null) ||
            (orderInfoRecoil.paymentMethod == PaymentMethod._WECHATPAY && isObjectEmpty(orderInfoRecoil.details))) {
            jumpToStep(0);
        }
        else if (orderInfoRecoil.paymentMethod == PaymentMethod._WECHATPAY) {
            sourceInterval.current = setInterval(async () => {
                ProcessSourcePolling();
            }, _POLLING_TIMEOUT);
        }

        return () => {
            if (chargeInterval.current) {
                clearInterval(chargeInterval.current);
            }

            if (sourceInterval.current) {
                clearInterval(sourceInterval.current);
            }
        }
    }, []);

    /// <summary>
    /// Author: YJ
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(orderInfoRecoil)) {
            jumpToStep(0);
        }
    }, [orderInfoRecoil])

    /// <summary>
    /// Author: Wind
    /// </summary>
    const onSubmit = async (data, e) => {
        let loadingTimeout = setTimeout(() => {
            Loading.Circle('Processing Payment...');
        }, 500);

        try {
            if (!stringIsNullOrEmpty(cardError)) {
                return;
            }

            let transactionType = constructStripeTransactionType(orderInfoRecoil.paymentMethod);

            if ((orderInfoRecoil.paymentMethod != PaymentMethod._CREDITCARD) || stringIsNullOrEmpty(transactionType)) {
                throw "Payment type not supported";
            }
            else if (!stripe || !element) {
                throw "Not prepared";
            }

            let clientSecret = null;

            let params = {
                "Currency": DefaultCurrency,
                "Type": transactionType,
                "Carts": orderInfoRecoil.carts,
                "Name": data.name
            };

            let paymentDao = new PaymentDao();
            await paymentDao.processPaymentWithCard(params).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    clientSecret = res[ApiKey._API_DATA_KEY];
                }
                else {
                    Report.Warning(
                        res[ApiKey._API_MESSAGE_KEY],
                        res[ApiKey._API_FIRST_ERROR_KEY].detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).catch(err => {
                throw err;
            });

            if (clientSecret == null) {
                throw "Client Secret Not Found";
            }

            /// start confirm payment
            await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: element.getElement(CardElement),
                    billing_details: {
                        name: data.name
                    }
                }
            }).then(async (result) => {
                let errorMessage = "";
                let params = {
                };

                if (result.error) {
                    params["IntentId"] = result.error.payment_intent.id;
                    params["ClientSecret"] = result.error.payment_intent.client_secret;
                    params["Status"] = PaymentStatus._FAILED;
                    errorMessage = result.error.message;
                }
                else {
                    params["IntentId"] = result.paymentIntent.id;
                    params["ClientSecret"] = result.paymentIntent.client_secret;
                    params["Status"] = PaymentStatus._SUCCESS;
                }

                await paymentDao.handleCardPaymentCallback(params).then(res => {
                    if (res[ApiKey._API_SUCCESS_KEY]) {

                        _history.replace({
                            pathname: _location.pathname,
                            state: {
                                data: {
                                    paymentId: res[ApiKey._API_DATA_KEY],
                                    showStep: true
                                }
                            }
                        })

                        if (!stringIsNullOrEmpty(errorMessage)) {
                            Report.Failure(t(SweetAlert._OPERATION_FAIL), errorMessage, t(SweetAlert._OK), () => jumpToStep(2));
                        }
                        else {
                            Report.Success(t(SweetAlert._OPERATION_SUCCESS), "Payment Successful", t(SweetAlert._OK), () => jumpToStep(2));
                        }
                    }
                    else {
                        Report.Warning(
                            res[ApiKey._API_MESSAGE_KEY],
                            res[ApiKey._API_FIRST_ERROR_KEY].detail ?? "Request failed.",
                            t(SweetAlert._OK),
                        );
                    }
                }).catch(err => {
                    throw err;
                });
            });
        }
        catch (err) {
            Report.Failure(
                t(SweetAlert._ERROR_HAS_OCCUR),
                err,
                t(SweetAlert._OK),
            );
        }
        finally {
            clearTimeout(loadingTimeout);
            Loading.Remove();
        }
    }

    /// <summary>
    /// Author: YJ
    /// styles for stripe card element
    /// </summary>
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize: "20px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            classes: {
                base: "form-control-md form-control"
            }
        }),
        []
    );

    useImperativeHandle(ref, () => ({
        paymentBackHandler() {
            resetOrderInfoRecoil();
            jumpToStep(0);
        },
        submitCreditCardPayment() {
            handleSubmit(onSubmit)();
        }
    }), []);

    return (
        <div className="d-flex flex-column h-100" id="paymentSection">
            <div className="flex-grow-1">
                <form onSubmit={handleSubmit(onSubmit)} className="h-100">
                    {orderInfoRecoil.paymentMethod == PaymentMethod._ALIPAY && (
                        <div className="payment-qr-container text-center">
                            <h3>{t("MERCHANTS_QR")}</h3>
                            <div className="qr-code-canvas-wrapper">
                                <img alt="" src={AlipayQR} />
                            </div>
                        </div>
                    )}
                    {orderInfoRecoil.paymentMethod == PaymentMethod._WECHATPAY && (

                        <div className="payment-qr-container text-center">
                            <InputHoc
                                formGroupClass="hinter"
                                disabled={true}
                                prefix={<img src={require("../../assets/img/icon/bulb.svg")} />}
                                placeholder="The final payment status will be shown on Wechat."
                            />
                            <h3>{t("MERCHANTS_QR")}</h3>
                            <div className="d-flex flex-column justify-content-center">
                                <div className="qr-code-canvas-wrapper">
                                    <QRCode value={orderInfoRecoil.details.qrCodeUrl} />
                                </div>
                                <div>
                                    <img src={require(`../../assets/img/wechat-pay.png`)} id="wechat-pay-logo" />
                                </div>
                            </div>
                        </div>
                    )}
                    {orderInfoRecoil.paymentMethod == PaymentMethod._CREDITCARD && (
                        <div className="flex-center mb-4">
                            <div style={{ width: '320px' }}>
                                <ReactCreditCards
                                    cvc={creditCardState.cvc}
                                    expiry={creditCardState.expiry}
                                    focused={creditCardState.focus}
                                    name={creditCardState.name}
                                    number={creditCardState.number}
                                />
                            </div>
                            <div className="panel panel-brand-inner" style={{ width: '560px' }}>
                                <div className="panel-header">{t("CREDIT_CARD_DETAILS")}</div>
                                <div className="panel-body">
                                    <InputHoc name="name" showError={true} error={errors.name?.message} className="card-input" formGroup={false} inputType={InputTypes.INPUT} control={control} ref={register({ required: 'Name is required.' })} placeholder="NAME" />
                                    <CardElement
                                        options={options}
                                        onReady={(event) => {
                                            setCardError(t("CARD_INFORMATION_INCOMPLETE"));
                                        }}
                                        onChange={(event) => {
                                            if (!event.complete) {
                                                setCardError(event.error != null && !stringIsNullOrEmpty(event.error.message) ? event.error.message : t("CARD_INFORMATION_INCOMPLETE"));
                                            }
                                            else {
                                                setCardError("");
                                            }
                                        }}
                                    />
                                    {isSubmitted && !stringIsNullOrEmpty(cardError) && <span className="input-error">{cardError}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                    {orderInfoRecoil.paymentMethod == PaymentMethod._BANKTRANSFER && (
                        <>
                            <div className="mb-5" style={{ padding: '15%', paddingTop: '0' }}>
                                <Card>
                                    <CardHeader>Bank Transfer</CardHeader>
                                    <CardBody>
                                        <div style={{ textAlign: 'center', marginTop: '1%' }}>
                                            <InputHoc inputType={InputTypes.ATTACHMENT} control={control} name="bankTransferSlip" label="UPLOAD_BANK_TRANSFER_SLIP" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </>
                    )}
                    {orderInfoRecoil.paymentMethod == PaymentMethod._CHEQUE && (
                        <>
                            <div className="mb-5" style={{ padding: '25%', paddingTop: '0' }}>
                                <Card style={{ padding: '5%' }}>
                                    <CardHeader>
                                        Cheque Details
                                    </CardHeader>
                                    <CardBody>
                                        <FormBuilder formRef={formRef} fields={_chequefields} onSubmit={onSubmit} />
                                        <div style={{ textAlign: 'center', marginTop: '5%', /*borderStyle: 'solid', borderWidth: '2px', borderColor: 'lightgrey'*/ }}>
                                            <InputHoc inputType={InputTypes.ATTACHMENT} control={control} name="chequeSlip" label="Upload Cheque Slip" />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </>
                    )}

                </form>
            </div>
        </div>
    )
});

export default Payment;
