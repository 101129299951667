import React, { useState, useMemo, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import ReactTable from "../../components/react-table/ReactTable";
import { DateFormat, ApiUrl, ApiKey } from "../../util/Constant";
import CompanyServiceDocuments from "./CompanyServiceDocuments";

/// <summary>
/// Author: Lewis
/// </summary>
export default ({ isModalOpen, setModalOpen, companyId, companyInfo }) => {
    const [requestService, setRequestService] = useState();
    const _SERVICE_HISTORY = useMemo(() => [
        {
            Header: "#",
            accessor: "index",
            Cell: ({ row }) => {
                return (<>{row.index + 1}</>);
            }
        },
        {
            Header: "Service",
            accessor: "servie",
            Cell: ({ row }) => {
                return (<>{row.original.productMatter.name}</>)
            }
        },
        {
            Header: "Request Date",
            accessor: "requestDate",
            Cell: ({ row }) => {
                return (<>{moment(row.original.requestDate).format(DateFormat._DATE_ONLY)}</>)
            }
        },
        {
            Header: "Completed Date",
            accessor: "completedDate",
            Cell: ({ row }) => {
                return (<>{moment(row.original.completedDate).format(DateFormat._DATE_ONLY)}</>)
            }
        },
        {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (<>
                <div className="btn btn-rounded btn-info btn-themed mr-2" id="info_btn" onClick={() => { setRequestService(row.original) }}>
                    <i class="las la-info-circle"></i>
                </div>
                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="info_btn">View Info</UncontrolledTooltip>

            </>),
            disableFilters: true,
            disableSortBy: true,
        }

    ], []);

    return (<>
        {
            requestService
                ?
                <CompanyServiceDocuments requestService={requestService} setRequestService={setRequestService} />
                :
                <ReactTable
                    columns={_SERVICE_HISTORY}
                    url={ApiUrl._API_GET_COMPANY_SERVICE_REQUEST_LIST_BY_COMPANY_ID.replace(":companyId", companyId)}
                    setPagination
                />
        }
    </>)
}