import React, { useState, useEffect, useContext } from 'react';
import TabPanel from 'components/panel/TabPanel';
import AccountantListing from './AccountantListing';
import AdminListing from './AdminListing';
import { Tab, Tabs } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { stringIsNullOrEmpty } from 'util/Utility';
import { PageSettings } from 'config/page-settings';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PolicyActionConstant, PolicyObjectConstant } from 'util/Constant';
import Error403View from 'components/error-graphic/Error403NoAccess';
import { Can } from 'config/user-access'

const _TABS = {
    ACCOUNTANTS: { id: 0, title: "ACCOUNTANT" },
    ADMINS: { id: 1, title: "ADMIN" }
};

const UserManagement = () => {

    const { isXlDevices } = useContext(PageSettings);
    const { t } = useTranslation();
    const _location = useLocation();
    const _history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        let search = _location.search;

        if (stringIsNullOrEmpty(search)) {
            setCurrentTab(0);
            return;
        }

        let params = new URLSearchParams(search);
        if (params) {
            let tab = parseInt(params.get("tab"));
            let listOfTabIds = Object.values(_TABS).map(x => x.id);
            listOfTabIds.includes(tab) ? setCurrentTab(tab) : setCurrentTab(0);
        }
    }, []);

    return (
        <div className="panel panel-brand panel-flex" id="UserWizard">
            {
                <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_user_management} passThrough>
                    {
                        allowed => allowed ?
                            <>
                                <div className="panel-header p-b-0">
                                    <h1 className="page-header title title-with-dot">{t("USER_MANAGEMENT")}</h1>
                                    <Tabs
                                        value={currentTab}
                                        onChange={(e, newTab) => {
                                            const path = _location.pathname;
                                            let params = new URLSearchParams({
                                                tab: newTab
                                            });

                                            _history.push({
                                                pathname: path,
                                                search: `?${params}`
                                            })

                                            setCurrentTab(newTab)
                                        }}
                                        variant="scrollable"
                                    >
                                        <Tab icon={<img alt="" src={require('../../assets/img/icon/accountant.svg')} className="mr-2" />} label={t(_TABS.ACCOUNTANTS.title)} classes={{ wrapper: "d-inline text-theme-1" }} />
                                        <Tab icon={<img alt="" src={require('../../assets/img/icon/admin.svg')} className="mr-2" />} label={t(_TABS.ADMINS.title)} classes={{ wrapper: "d-inline text-theme-1" }} />
                                    </Tabs>
                                </div>
                                <div className="panel-body h-100">
                                    <div className={classNames(
                                        'panel-body-inner-content',
                                        {
                                            'with-btm-br-radius': currentTab === _TABS.ACCOUNTANTS.id,
                                            'with-br-radius': currentTab === _TABS.ADMINS.id
                                        },
                                    )}
                                    >
                                        <div className="d-flex flex-column h-100">
                                            <TabPanel value={currentTab} index={_TABS.ACCOUNTANTS.id} className="flex-grow-1" >
                                                <AccountantListing />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={_TABS.ADMINS.id} className="flex-grow-1">
                                                <AdminListing />
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <Error403View />
                    }
                </Can>
            }

        </div>
    );
};

export default UserManagement;