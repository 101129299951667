import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class CurrencyDao extends DataAccessObject {

    async getCurrencyList() {
        return this.get(ApiUrl._API_GET_CURRENCY_LIST);
    }

    async createOrEditCurrency(postData) {
        return this.post(ApiUrl._API_GET_CURRENCY_CREATE_OR_EDIT, postData);
    }

    async deleteCurrency(id) {
        return this.delete(ApiUrl._API_DELETE_CURRENCY.replace(":id", id));
    }

    async getCurrencyExchangeRatesByCurrencyId(currencyId) {
        return this.get(ApiUrl._API_GET_CURRENCY_EXCHANGE_RATE_BY_CURRENCY_ID.replace(":currencyId", currencyId));
    }
}

export default CurrencyDao;
