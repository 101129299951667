import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Collapse, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { WebUrl, ApiKey, CompanyRegistrationType, LoadingStateText, IncorpComponentKeys, SweetAlert, CompanyProcessState, ApiUrl } from 'util/Constant';
import 'awesome-steps/dist/style.css';
import { Confirm, Notify, Report, Block } from 'notiflix';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { IncorporationInfo } from 'recoil/Incorporation';
import { useLocation, useHistory } from 'react-router-dom';
import { propertyIsUndefined, generateRandString, stringIsNullOrEmpty } from 'util/Utility';
import NavigationButton from 'components/buttons/NavigationButton';
import _ from 'lodash/object';
import { CompanyDao } from 'data';

import AddressSecretaryComponent from './AddressSecretaryComponent';
import CompanyNameComponent from './CompanyNameComponent';
import { DetermineIncorporationStatusIcon } from './DetermineIncorporationStatusIcon';
import CompanyOfficersComponent from './CompanyOfficersComponent';
import KycComponent from './KycComponent';
import UbTreeHierarchyComponent from './UbTreeHierarchyComponent';
import ResponsiveModal from 'react-responsive-modal';
import { companyProcessStates } from 'recoil/Atoms';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useSWR from 'swr';
import { CustomFetcher } from 'util/CustomFetcher';

/// <summary>
/// Author: Lewis
/// </summary>
const styles = {
    root: {
        zIndex: 1045
    },
    modal: {
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
        overflow: "none",
        width: "100%",
        padding: "0",
        margin: "0",
        height: "100%",
        minWidth: "100%",
        justifyContent: "center"
    },
    overlay: {
        backgroundColor: "#1cccc",
        padding: 0,
        background: "rgba(253, 248, 235, 0.95)",
    },
    closeIcon: {
        fill: "#D5A945"
    }
};

/// <summary>
/// Author: Chris
/// </summary>
const CreateOrEditIncorporation = (props) => {

    let companyDao = new CompanyDao();

    const { t } = useTranslation();
    const { state } = useLocation();
    const _history = useHistory();
    const resetIncorporationState = useResetRecoilState(IncorporationInfo);
    const [incorporationInfo, setIncorporationInfo] = useRecoilState(IncorporationInfo);
    const [isBackdropUbTreeShow, setBackdropUbTreeShow] = useState(false);
    const [processStateId, setProcessStateId] = useState(0);
    const sectionRef = useRef([]);
    const [scrollStepIndex, setScrollStepIndex] = useState(0);
    const [stepState, setStepState] = useState([
        {
            title: 'Company Info', name: 'COMPANY_INFO', component: CompanyNameComponent, key: IncorpComponentKeys._COMPANY_INFO,
            collapsed: false, status: false
        },
        {
            title: 'Address', name: 'ADDRESS_AND_COMPANY_SECRETARY', component: AddressSecretaryComponent, key: IncorpComponentKeys._ADDRESS_SECRETARY,
            collapsed: false, status: false
        },
        {
            title: 'Officers', name: 'SHAREHOLDERS_AND_DIRECTORS', component: CompanyOfficersComponent, key: IncorpComponentKeys._OFFICERS,
            collapsed: false, status: false
        },
        {
            title: 'Kyc Questions', name: 'KYC_QUESTIONS', component: KycComponent, key: IncorpComponentKeys._KYC,
            collapsed: false, status: false
        },
        {
            title: 'Ultimate Beneficiary', name: 'ULTIMATE_BENEFICIARY', component: UbHierarchyIncorpWrapper, key: IncorpComponentKeys._UB,
            collapsed: false, status: false
        },
    ]);

    const toggleStepComplete = async (componentKey, state) => {
        setStepState(prevState => {
            var newState = [...prevState];
            newState.map(o => {
                if (o.key == componentKey) {
                    o.status = state;
                }
            })
            return newState;
        });
    };

    const { data: incorpJson } = useSWR([
        ApiUrl._API_GET_COMPANY_DETAILS.replace(':companyId', state?.companyId),
        ApiKey._API_GET,
        null,
        "div#incorporationPageWrapper"
    ]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const cancelHandler = () => _history.goBack();

    /// <summary>
    /// Author: Chris
    /// Edited: Lewis - when component unmount, clean recoil state
    /// </summary>
    useEffect(() => {
        document.title = 'Add Incorporation | YICOM Incorp';
        return resetIncorporationState
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (!state?.companyId) {
            _history.goBack();
            return;
        }

    }, [state]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (incorpJson?.[ApiKey._API_SUCCESS_KEY]) {
            setIncorporationInfo(incorpJson[ApiKey._API_DATA_KEY]);
            setProcessStateId(incorpJson[ApiKey._API_DATA_KEY]['processStateId']);
        }

    }, [incorpJson]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const isAllAccordionComplete = () => {
        var statusList = stepState.map(item => item.status);
        const arrayChecker = statusList => statusList.every(v => v === true);
        return arrayChecker(statusList);
    }

    /// <summary>
    /// Author: Wind
    /// Modified: Robin
    /// </summary>
    const addToCart = useCallback(() => {

        let isComplete = isAllAccordionComplete();
        if (!isComplete) {
            Notify.Warning(t("notiflix:FILL_IN_AL_DETAILS_BEFORE_REQEUEST_SERVICE"));
            return;
        }

        Confirm.Show(
            `${t("ADD_TO_CART")}?`,
            `${t("DO_YOU_WISH_TO_CONTINUE")} <br/>`,
            t('notiflix:CONFIRM'),
            t('notiflix:CANCEL'),
            async () => {

                await CustomFetcher(
                    ApiUrl._API_SUBMIT_COMPANY_INCORPORATION_REQUEST.replace(':id', state.companyId),
                    ApiKey._API_POST,
                ).then(res => {

                    res[ApiKey._API_SUCCESS_KEY] &&
                        Report.Success(
                            t("notiflix:ADD_TO_CART_SUCCESS"),
                            res[ApiKey._API_MESSAGE_KEY],
                            t(SweetAlert._OK),
                            () => _history.push(WebUrl._PAYMENT_CHECKOUT)
                        )
                });
            }
        )

    }, [state?.companyId]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const scrollHandler = useCallback((e) => {

        const { scrollTop } = e.target;

        let boundingHeights = [];
        let activeIndex = 0;

        let eachSectionClientHeights = stepState.map((o, i) => sectionRef.current[i].clientHeight);
        eachSectionClientHeights.unshift(0);

        const cumulativeSum = (sum => value => sum += value)(0);
        let cummulativeHeights = eachSectionClientHeights.map(cumulativeSum);

        for (var i = 0; i < cummulativeHeights.length - 1; i++) {
            boundingHeights.push([cummulativeHeights[i] - 50, cummulativeHeights[i + 1] - 50])
        }

        boundingHeights.map((arr, index) => {
            if (scrollTop >= arr[0] && scrollTop < arr[1]) {
                activeIndex = index;
            }
        });

        setScrollStepIndex(activeIndex);

    }, []);

    return (
        <div className="panel-flex panel panel-brand" id="incorporationPageWrapper" style={{ overflow: 'hidden' }}>
            <div className="d-flex align-items-center panel-header border-bottom-brand" style={{ padding: '1.5rem 2rem' }}>
                <NavigationButton />
                <h2 className="mb-0" style={{ fontWeight: 900, marginTop: '0.2rem' }}>{
                    incorporationInfo.registrationType == CompanyRegistrationType._TRANSFER_IN ?
                        <>{t("TRANSFER_IN")}{incorporationInfo?.englishName && `- ${incorporationInfo.englishName}`} {incorporationInfo?.chineseName && `(${incorporationInfo?.chineseName})`}</>
                        : <>{t("ADD_INCORP")} {incorporationInfo?.englishName && `- ${incorporationInfo.englishName}`} {incorporationInfo?.chineseName && `(${incorporationInfo?.chineseName})`}</>
                }</h2>
            </div>
            <div className="panel-body p-0 h-100 d-flex">
                <div className="sidebar-incorp" style={{ width: "30%" }}>
                    <ul class="step-progress">
                        {
                            stepState.map((step, index) => (
                                <li
                                    key={index}
                                    className={classnames("step-progress-item",
                                        { "is-done": stepState[index].status },
                                        { "is-incomplete": !stepState[index].status },
                                        // { "current": scrollStepIndex == index }
                                    )}
                                    onClick={() => sectionRef.current[index].scrollIntoView({ block: 'start', behavior: 'smooth' })}>
                                    <div className="panel panel-stepper">
                                        <div className={classnames("panel-body", { "active": scrollStepIndex == index })}>
                                            {t(step.name)}
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div id="incorporationAccordion" className="y-scrollbar-2" style={{ overflowY: 'scroll', width: "70%" }} onScroll={(e) => scrollHandler(e)}>
                    {
                        stepState.map((step, index) => (
                            <div key={index} ref={el => sectionRef.current[index] = el}>
                                {React.createElement(step.component, {
                                    index,
                                    incorporationInfo,
                                    toggleStepComplete,
                                    isBackdropUbTreeShow,
                                    setBackdropUbTreeShow,
                                    companyId: incorporationInfo.id,
                                    isEdit: true
                                })}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="panel-footer justify-content-end d-flex">
                <button className="btn btn-themed btn-rest btn-min-width m-r-15" onClick={cancelHandler}>{t("BACK")}</button>
                {processStateId === CompanyProcessState._PENDING_INFORMATION &&
                    <button className="btn btn-themed btn-min-width m-r-15" onClick={addToCart}>{t("ADD_TO_CART")}</button>
                }
            </div>
            <ResponsiveModal
                classNames="ub-full-modal"
                open={isBackdropUbTreeShow}
                onClose={() => setBackdropUbTreeShow(false)}
                styles={styles}
                animationDuration={100}
                focusTrapped={true}
                closeIconSize={40}
                showCloseIcon={true}
            >
                <div className="w-100 h-100">
                    {/* <div style={{ position: "absolute", width: "100%" }}>
                        <h4 className="text-theme-1 text-center p-t-25">Click ESC to Close Full Screen View</h4>
                    </div> */}
                    <UbTreeHierarchyComponent
                        index={4}
                        companyId={incorporationInfo.id}
                        toggleStepComplete={toggleStepComplete}
                        isBackdropUbTreeShow={isBackdropUbTreeShow}
                        setBackdropUbTreeShow={setBackdropUbTreeShow}
                        isEdit={true}
                    />
                </div>
            </ResponsiveModal>
        </div>
    )
}

/// <summary>
/// Author: Lewis
/// </summary>
const UbHierarchyIncorpWrapper = (props) => {
    let {
        companyId,
        toggleStepComplete,
        isBackdropUbTreeShow,
        setBackdropUbTreeShow,
        isEdit
    } = props;

    const { t } = useTranslation();

    return (
        <div className="section-incorp d-flex flex-column ub-section">
            <div className="title">{t("ULTIMATE_BENEFICIARY_DETAILS")}</div>
            <div className="panel panel-brand mb-0 ub-tree-graph-panel flex-grow-1">
                <UbTreeHierarchyComponent
                    companyId={companyId}
                    setBackdropUbTreeShow={setBackdropUbTreeShow}
                    isBackdropUbTreeShow={isBackdropUbTreeShow}
                    toggleStepComplete={toggleStepComplete}
                    isEdit={isEdit}
                />
            </div>
        </div>
    )
}

export default CreateOrEditIncorporation;