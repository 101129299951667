import React, { useState, forwardRef } from "react";
import ReactInputColor from 'react-input-color';
import { Controller, useFormContext, useForm } from 'react-hook-form';

///<summary>
///Author: Ong Sze Hua
///</summary>
const InputColor = (props) => {
    const { defaultValue } = props;
    const _INITIAL_COLOR = defaultValue ? defaultValue : '#000000';

    return (
        <Controller
            render={({ onChange }) => (
                <ReactInputColor initialValue={_INITIAL_COLOR} onChange={(color) => onChange(color.hex)} />
            )}
            {...props}
        />
    );
}

export default InputColor;
