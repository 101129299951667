import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Report, Confirm, Block, Notify } from "notiflix";
import { InputTypes, BtnTypes, ApiKey, ApiUrl, matterType, DefaultCurrencyId, DefaultCurrency, LoadingStateText, deleteConfirm, FileType, TableId } from "util/Constant";
import { formatCountryList, formatNumber, stringIsNullOrEmpty } from "util/Utility.js";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import ButtonRound from "components/buttons/ButtonRound";
import InputHoc from "components/form/InputHoc";
import { useFieldArray, useForm } from "react-hook-form";
import BrandModal from "components/modals/BrandModal";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import useSWR from "swr";
import { JurisdictionDao } from "data";
import { Link } from "react-router-dom";
import DocumentViewer from "components/document-viewer/DocumentViewer";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "recoil/Atoms";


///<summary>
///Author: Sze Hua
///</summary>
const GovernmentDepartment = props => {
    const { jurisdictionId } = props;

    const { t } = useTranslation();

    const [showInnerTab, setShowInnerTab] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [department, setDepartment] = useState();
    const [departmentId, setDepartmentId] = useState();
    const [reset, setReset] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const _formRef = useRef();
    const userId = useRecoilValue(userIdSelector);

    const jurisdictionDao = useMemo(() => new JurisdictionDao(), []);

    const _COLUMNS = useMemo(() => [
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            style: { width: '30%', whiteSpace: 'pre-line', wordBreak: 'break-word' },
            isRequiredColumn: true
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            style: { width: '60%', whiteSpace: 'pre-line', wordBreak: 'break-word' }
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => {
                return (
                    <>
                        <ButtonRound medium type={BtnTypes.ARROW_RIGHT} onClick={() => { toggleShowInnerTab(); setDepartmentId(row.original.id) }} className="btn-themed mr-1" title={t('MORE')} />
                        <ButtonRound medium type={BtnTypes.EDIT} onClick={() => { toggleShowModal(); handleSetDepartment(row.original) }} className="mr-1" />
                        <ButtonRound
                            medium
                            type={BtnTypes.DELETE}
                            onClick={() => {
                                Confirm.Show(t('DELETE_CONFIRMATION'),
                                    t('ARE_YOU_SURE_TO_DELETE'),
                                    t('YES'),
                                    t('NO'),
                                    function () { onClickDeleteDeparment(row.original.id) },
                                    '',
                                    deleteConfirm);
                            }}
                        />
                    </>
                );
            },
            disableSortBy: true,
            disableFilters: true,
            style: { width: '1%' },
            isRequiredColumn: true
        },
    ], []);

    const _FIELDS = useMemo(() => [
        {
            rowOptions: { xl: 1 },
            columns: [
                {
                    label: t('NAME'), name: "name", input: InputTypes.INPUT, rules: { required: 'Name is required' }
                },
                {
                    label: t('DESCRIPTION'), name: "description", input: InputTypes.TEXTAREA, rules: { required: 'Description is required' }, maxLength: 256
                }
            ]
        }
    ], []);

    const toggleShowModal = () => { setShowModal(prev => !prev) };
    const toggleShowInnerTab = () => { setShowInnerTab(prev => !prev) };

    const handleSetDepartment = (data) => {
        if (data) {
            setDepartment(data);
        }
        else {
            setDepartment();
        }
        setReset(true);
    }

    const onSubmit = async (data) => {
        if (department?.id) {
            await jurisdictionDao.updateGovernmentDepartment(jurisdictionId, department.id, data).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(t('notiflix:UPDATE_GOVERN_DPT_SUCCESS'));
                    setRefreshTable(prev => !prev);
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            }).finally(() => { toggleShowModal(); });
        }
        else {
            await jurisdictionDao.createGovernmentDepartment(jurisdictionId, data).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(t('notiflix:CREATE_GOVERN_DPT_SUCCESS'));
                    setRefreshTable(prev => !prev);
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            }).finally(() => { toggleShowModal(); });
        }
    }

    const onClickDeleteDeparment = async (id) => {
        await jurisdictionDao.deleteGovernmentDepartment(jurisdictionId, id).then(res => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(t('notiflix:DELETE_GOVERN_DPT_SUCCESS'));
                setRefreshTable(prev => !prev);
            }
            else {
                let errors = res[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        });
    }

    return (
        <>
            {!showInnerTab ? (
                <>
                    <div className="d-flex flex-column h-100">
                        <ReactTable
                            tblFooterClass={"rt-tbl-footer-white"}
                            enableSearch
                            columns={_COLUMNS}
                            setPagination
                            url={ApiUrl._API_GOVERNMENT_DEPARTMENT.replace(":jurisdictionId", jurisdictionId)}
                            refreshTable={refreshTable}
                            customButtons={
                                <button type="button" className="btn btn-themed tbl-custom-brand-btn" onClick={() => toggleShowModal()}>
                                    {t("systemConfiguration:ADD_GOVERNMENT_DEPARTMENT")}
                                </button>
                            }
                            tableColumnPreference={[userId, TableId._GOVERNMENT_DEPARTMENT_MANAGEMENT]}
                        />
                    </div>
                </>
            ) : (
                <LetterTypeList showInnerTab={showInnerTab} toggleShowInnerTab={toggleShowInnerTab} jurisdictionId={jurisdictionId} governmentDepartmentId={departmentId} />
            )}
            {/* <div className="section-split">
                <div className="section-wrapper flex-grow-1">
                    <div className="section-header justify-content-end">
                        <button type="button" className="btn btn-themed btn-min-width" style={{ padding: '0.6rem 1rem', height: '38px' }} onClick={() => { toggleShowModal() }}>
                            Add Government Department
                        </button>
                    </div>
                    <div className="section-body">
                        <ReactTable
                            tblFooterClass={"rt-tbl-footer-white"}
                            enableSearch
                            columns={_COLUMNS}
                            setPagination
                            url={ApiUrl._API_GOVERNMENT_DEPARTMENT.replace(":jurisdictionId", jurisdictionId)}
                            refreshTable={refreshTable}
                        />
                    </div>
                </div>
                <div className="section-wrapper flex-grow-1" id="factSheetGroupItems">
                    <div className="section-header justify-content-between">
                        {
                            department &&
                            <>
                                <h4 className="mb-0 mr-2">{department.name}</h4>
                                <ButtonRound medium type={BtnTypes.EDIT} onClick={() => toggleShowModal()} />
                            </>
                        }
                    </div>
                    <div className="section-body y-scrollbar-2" style={{ flex: 1 }}>
                        <LetterTypeList jurisdictionId={jurisdictionId} governmentDepartmentId={department?.id} />
                    </div>
                </div>
            </div> */}
            <BrandModal
                modalSize="md"
                title={department ? t("systemConfiguration:EDIT_GOVERNMENT_DEPARTMENT") : t("systemConfiguration:ADD_GOVERNMENT_DEPARTMENT")}
                customBody
                isOpen={showModal}
                toggler={toggleShowModal}
                centered
                backdrop='static'
                onClosed={() => {
                    handleSetDepartment();
                }}
            >
                <ModalBody>
                    <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={_formRef} reset={reset} resetTriggerReset={setReset} resetValues={department} />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={() => toggleShowModal()}>{t("CANCEL")}</button>
                    <button type="submit" className="btn btn-themed btn-min-width" onClick={() => { submitForm(_formRef) }}>{t("SAVE")}</button>
                </ModalFooter>
            </BrandModal>
        </>
    );
}

const LetterTypeList = props => {
    const { showInnerTab, toggleShowInnerTab, jurisdictionId, governmentDepartmentId } = props;

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState();
    const [letterType, setLetterType] = useState();
    const [reset, setReset] = useState(false);
    const [refreshTable, setRefreshTable] = useState();
    const _formRef = useRef();
    const userId = useRecoilValue(userIdSelector);
    // const [isViewing, setIsViewing] = useState(false);
    // const [filePath, setFilePath] = useState();

    // const { control, handleSubmit, errors, reset, watch } = useForm();
    // const { fields, append, remove, move } = useFieldArray(
    // {
    //     control,
    //         name: "department"
    // }
    // );

    const jurisdictionDao = useMemo(() => new JurisdictionDao(), []);

    const _COLUMN = useMemo(() => [
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            style: { width: '30%', whiteSpace: 'pre-line', wordBreak: 'break-word' },
            isRequiredColumn: true
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            style: { width: '60%', whiteSpace: 'pre-line', wordBreak: 'break-word' }
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => {
                return (
                    <>
                        {/* {row.original.templateFile && (
                            <>
                                <Link className="brand-anchor mr-1"
                                    to={row.original?.templateFile?.fileMeta[0]?.requestPath}
                                    target={"_blank"}
                                    download={row.original?.templateFile?.fileMeta[0]?.filename}
                                >
                                    <ButtonRound small type={BtnTypes.FILE_DOWNLOAD} />
                                </Link>
                                <ButtonRound
                                    className="mr-2"
                                    type={BtnTypes.VIEW}
                                    small
                                    onClick={() => {
                                        if (row.original?.templateFile?.fileMeta[0].extension != FileType._PDF) {
                                            Notify.Warning(t("systemConfiguration:DOCX_FILE_NOT_VIEWABLE"))
                                        } else {
                                            setIsViewing(true);
                                            setFilePath(row.original?.templateFile?.fileMeta[0].requestPath);
                                        }
                                    }}
                                />
                            </>
                        )} */}
                        <ButtonRound medium type={BtnTypes.EDIT} onClick={() => { toggleShowModal(); handleSetLetterType(row.original) }} className="mr-1" />
                        <ButtonRound
                            medium
                            type={BtnTypes.DELETE}
                            onClick={() => {
                                Confirm.Show(t('DELETE_CONFIRMATION'),
                                    t('ARE_YOU_SURE_TO_DELETE'),
                                    t('YES'),
                                    t('NO'),
                                    function () { onClickDeleteLetterType(row.original.id) },
                                    '',
                                    deleteConfirm);
                            }}
                        />
                    </>
                );
            },
            disableSortBy: true,
            disableFilters: true,
            style: { width: '1%' },
            isRequiredColumn: true
        },
    ], []);

    const _FIELDS = useMemo(() => {
        let tempFields = [
            {
                rowOptions: { xl: 1 },
                columns: [
                    {
                        label: t('NAME'), name: "name", input: InputTypes.INPUT, rules: { required: 'Name is required' }
                    },
                    {
                        label: t('DESCRIPTION'), name: "description", input: InputTypes.TEXTAREA, rules: { required: 'Description is required' }, maxLength: 256
                    }
                ]
            }
        ];
        // if (!letterType) {
        //     tempFields[0].columns.push({ label: "Letter", name: "letterAttachment", input: InputTypes.FILEUPLOAD, rules: { required: 'Letter is required' }, accept: { pdf: true } });
        // }
        return tempFields;
    }, [letterType]);

    // const { data: letterTypesList, mutate } = useSWR([ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES, ApiKey._API_GET, jurisdictionId, governmentDepartmentId],
    //     async (url, method, jurisdictionId, governmentDepartmentId) => {
    //         let result = [];
    //         await jurisdictionDao.getLetterTypesListByGovernmentDeparment(jurisdictionId, governmentDepartmentId).then(res => {
    //             if (res[ApiKey._API_SUCCESS_KEY]) {
    //                 result = res[ApiKey._API_DATA_KEY] ?? [];
    //             }
    //             else {
    //                 let errors = res[ApiKey._API_ERROR_KEY];
    //                 if (errors) {
    //                     let firstError = errors[0];
    //                     if (firstError) {
    //                         Report.Warning(firstError?.title, firstError?.detail);
    //                     }
    //                 }
    //             }
    //         });
    //         return result;
    //     }
    // );

    const handleSetLetterType = data => {
        if (data) {
            setLetterType(data);
        }
        else {
            setLetterType();
        }
        setReset(true);
    }

    const toggleShowModal = () => { setShowModal(prev => !prev) };

    const onSubmit = async (data) => {
        if (letterType?.id) {
            await jurisdictionDao.updateLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, letterType.id, data).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(t('notiflix:UPDATE_LTR_SUCCESS'));
                    // mutate();
                    setRefreshTable(prev => !prev);
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            }).finally(() => { toggleShowModal(); });
        }
        else {
            await jurisdictionDao.createLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, data).then(res => {
                if (res[ApiKey._API_SUCCESS_KEY]) {
                    Notify.Success(t('notiflix:CREATE_LTR_SUCCESS'));
                    // mutate();
                    setRefreshTable(prev => !prev);
                }
                else {
                    let errors = res[ApiKey._API_ERROR_KEY];
                    if (errors) {
                        let firstError = errors[0];
                        if (firstError) {
                            Report.Warning(firstError?.title, firstError?.detail);
                        }
                    }
                }
            }).finally(() => { toggleShowModal(); });
        }
    }

    const onClickDeleteLetterType = async (id) => {
        await jurisdictionDao.deleteLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, id).then(res => {
            if (res[ApiKey._API_SUCCESS_KEY]) {
                Notify.Success(t('notiflix:DELETE_LTR_SUCCESS'));
                // mutate();
                setRefreshTable(prev => !prev);
            }
            else {
                let errors = res[ApiKey._API_ERROR_KEY];
                if (errors) {
                    let firstError = errors[0];
                    if (firstError) {
                        Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        });
    }

    return (
        <>
            <div className="d-flex flex-column h-100" isOpen={showInnerTab} toggle={toggleShowInnerTab}>
                <div className="d-flex justify-between align-items-center section-header">
                    <ButtonRound type={BtnTypes.ARROW_LEFT} className="btn-sm" title={"Back"} onClick={() => toggleShowInnerTab()} />
                    <button type="button" className="btn btn-themed tbl-custom-brand-btn" style={{ padding: '0.6rem 1rem' }} onClick={() => toggleShowModal()}>
                        {t("systemConfiguration:ADD_LETTER_TYPE")}
                    </button>
                </div>
                <ReactTable
                    tblFooterClass={"rt-tbl-footer-white"}
                    enableSearch
                    columns={_COLUMN}
                    setPagination
                    url={ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId)}
                    refreshTable={refreshTable}
                    tableColumnPreference={[userId, TableId._LETTER_TYPE_MANAGEMENT]}
                />
            </div>
            {/* {governmentDepartmentId ? (
                <form ref={_formRef} onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <div className="panel-body ">
                        {
                            fields.map((data, i) => {
                                return (
                                    <>
                                        <div key={`department[${i}]${data.id}`} className="d-flex">
                                            <div className="flex-grow-1 mr-3">
                                                <InputHoc name={`department[${i}].name`} label="Name" rules={{ required: 'Name is Required' }} control={control} />
                                                <InputHoc name={`department[${i}].description`} label="Description" inputType={InputTypes.TEXTAREA} rules={{ required: 'Description is Required' }} control={control} />
                                            </div>
                                            <div className="btn-list d-flex align-items-center">
                                                <ButtonRound medium type={BtnTypes.CHECK_CIRCLE} />
                                                <ButtonRound medium type={BtnTypes.DELETE} onClick={() => { remove(i); }} />
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className="btn-list flex-center panel-body">
                        <button type="button" className="btn btn-themed btn-min-width" onClick={() => append({})}>Add Item</button>
                        <button type="submit" className="btn btn-themed btn-min-width">Save</button>
                    </div>
                </form>) : (
                <div className="empty-state-wrapper">
                    <img src={require("../../../assets/img/ui/search-graphic.svg")} />
                    <p>Please select a group to view information.</p>
                </div>
            )} */}
            <BrandModal
                modalSize="md"
                title={letterType ? t("systemConfiguration:EDIT_LETTER_TYPE") : t("systemConfiguration:ADD_LETTER_TYPE")}
                customBody
                isOpen={showModal}
                toggler={toggleShowModal}
                centered
                backdrop='static'
                onClosed={() => {
                    handleSetLetterType();
                }}
            >
                <ModalBody>
                    <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={_formRef} reset={reset} resetTriggerReset={setReset} resetValues={letterType} />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <button type="button" className="btn btn-themed btn-min-width grayscale-100" onClick={() => toggleShowModal()}>{t("CANCEL")}</button>
                    <button type="submit" className="btn btn-themed btn-min-width" onClick={() => { submitForm(_formRef) }}>{t("SAVE")}</button>
                </ModalFooter>
            </BrandModal>
            {/* <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={filePath} /> */}
        </>
    );
}

export default GovernmentDepartment;