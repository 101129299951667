import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';
import { Input as InputField } from 'reactstrap';
import { removeCommaParseInt } from 'util/Utility';

/// <summary>
/// Author: Robin
/// </summary>
const NumberInput = (props) => {
    const {
        error,
        readOnly,
        onChange: propsOnChange,
        maxValue,
        defaultValue: propsDefaultValue,
        className: propsClassName,
        ...rest
    } = props;

    const [defaultValue, setDefaultValue] = useState(null);

    const inputClasses = classnames(
        'form-control-md', {
        'has-error': error,
        'form-control': !readOnly,
        'form-control-plaintext': readOnly
    }, propsClassName
    );

    /// <summary>
    /// Author: Lewis
    /// Disabled number input field on scroll change value
    /// </summary>
    const onWheel = (e) => {
        if (rest.type == "number") {
            e.target.blur();
        }
    }

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    const withCapValue = (inputValue) => {
        if (isNaN(inputValue))
            return;
        else if (Number(inputValue) === 0)
            return 0;
        else if (Number(inputValue) <= maxValue)
            return inputValue;
        return maxValue.toString();
    }

    useEffect(() => {
        setDefaultValue(propsDefaultValue);
    }, [propsDefaultValue]);

    return (
        <Controller
            {...rest}
            render={(
                { onChange, onBlur, value, name, ref }
            ) => {
                return (
                    <NumberFormat
                        ref={ref}
                        getInputRef={ref}
                        className={inputClasses}
                        thousandSeparator={true}
                        thousandsGroupStyle={'thousand'}
                        displayType={readOnly ? 'text' : 'input'}
                        onWheel={onWheel}
                        type={'text'}
                        isNumericString={(rest.type && rest.type === "number") ? false : true}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            let inputValue = !isNaN(maxValue) ? withCapValue(floatValue) : floatValue;
                            onChange(inputValue);
                            propsOnChange && propsOnChange(inputValue);
                        }}
                        disabled={rest?.disabled ? true : false}
                        onBlur={onBlur}
                        // value={value}
                        name={name}
                        allowNegative={false}
                        value={defaultValue !== null ? defaultValue : value}
                    />
                )
            }}
        />
    );
};

NumberInput.defaultProps = {
    defaultValue: null,
    placeholder: " "
}

export default NumberInput;