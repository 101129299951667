import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { WebUrl } from 'util/Constant.js';
import { PageSettings } from '../../config/page-settings.js';

/// <summary>
/// Author: CJ(Jiann)
/// </summary>

const ErrorNotFound = props => {
    let { urlPath, ...rest} = props;
    const _history = useHistory();
    const _context = useContext(PageSettings);
    const { t } = useTranslation();

    const btnHandler = () => {
        _history.goBack();
    }
    return (<>
        <div className="login-bg"></div>
        <div className="d-flex align-items-center" style={{flexDirection: 'column'}}>
            <img src={require("../../assets/img/ui/graphic-error403.svg")} className="width-500"/>
            <h1>{t('403_NOT_FOUND')}</h1>
            <p>{t('NO_PERMISSION')}</p>
            <button type="button" className="btn btn-min-width btn-themed" onClick={() => btnHandler() }>{t('BACK')}</button>
        </div>
    </>);
}

export default ErrorNotFound;
