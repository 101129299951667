import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author: CJ(Jiann)
 */
class FactSheetDao extends DataAccessObject {

    async getFactSheetList() {
        return this.get(ApiUrl._API_GET_FACT_SHEET_LIST);
    }

    async getGroupList() {
        return this.get(ApiUrl._API_GET_FACT_SHEET_GROUP_LIST);
    }

    async getItemList(factSheetId, categoryId) {
        return this.get(ApiUrl._API_GET_ITEM_LIST.replace( ':factSheetId', factSheetId).replace( ':categoryId', categoryId ) );
    }

    async createOrUpdateGroup(category) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_GROUP, category);
    }

    async createOrUpdateItem(item) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_ITEM, item);
    }

    async createOrUpdateItemByGroup(items) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_ITEM_BY_GROUP, items);
    }

    async softDeleteGroup(id) {
        return this.delete(ApiUrl._API_DELETE_GROUP.replace(':id', id));
    }

    async deleteItem(id) {
        return this.delete(ApiUrl._API_DELETE_ITEM.replace(':id', id));
    }

    async getIconList() {
        return this.get(ApiUrl._API_GET_FACT_SHEET_ITEM_GROUP_ICON_LIST);
    }
}

export default FactSheetDao;
