import { ApiUrl, OTPKey } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Robin
 * DataAccessObject's fetch will return the promise.
 */
class AuthenticationDao extends DataAccessObject {

    async login(data) {
        return this.post(ApiUrl._API_LOGIN, data);
    }

    async validateLogin(data) {
        return this.post(ApiUrl._API_ACCOUNT_VALIDATION, data, null, false);
    }

    async requestOtp({ otpMethod, email }) {
        var url = otpMethod === OTPKey._EMAIL ? ApiUrl._API_EMAIL_OTP : ApiUrl._API_SMS_OTP
        return this.post(url, { "email": email});
    }

    async requestActivationCode(email) {
        return this.post(ApiUrl._API_REQUEST_ACTIVATION_CODE, { "email": email });
    }

    async performLogout() {
        return this.get(ApiUrl._API_LOGOUT);
    }
}

export default AuthenticationDao;
