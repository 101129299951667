import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import ImageUploader from 'react-images-upload';

/// <summary>
/// Author: Chris
/// </summary>
const Attachment = (props) => {
    const { defaultImageList, editing, ...rest } = props;

    const [pictureArray, setPictureArray] = useState([]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onDrop = useCallback((picture) => {
        setPictureArray(prevState => prevState.concat(picture));
    }, [pictureArray]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (defaultImageList.length > 0) {
            setPictureArray(defaultImageList);
        }
    }, [defaultImageList]);

    if (!editing) {
        return (
            <ul className="list-unstyled attachment-container">
                {defaultImageList?.length > 0 ? (
                    defaultImageList.map((item, idx) => <li className="justify-content-center d-flex"><img className="m-auto" src={item} style={{ maxWidth: "-webkit-fill-available" }} /></li>
                    )) : (
                        <li><p className="f-s-50 text-center m-40"><i className="las la-box-open" /></p></li>
                    )
                }
            </ul>
        )
    }

    return (
        <Controller
            {...rest}
            render={({ value, onChange, ...otherProps }) => (
                <ImageUploader
                    imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
                    maxFileSize={5242880}
                    singleImage={props?.singleImage ? true : false}
                    withPreview={true}
                    withIcon={false}
                    buttonText='Choose images'
                    onChange={(value) => onChange(value)}
                    defaultImages={pictureArray}
                    {...otherProps}
                />)} />
        //     as={<ImageUploader />}
        //     imgExtension={['.jpg', '.gif', '.png', '.gif', 'jpeg']}
        //     maxFileSize={5242880}
        //     singleImage={false}
        //     withPreview={true}
        //     withIcon={false}
        //     buttonText='Choose images'
        //     onChange={onDrop}
        //     {...props}
        // />
    );
};

Attachment.defaultProps = {
    defaultValue: null,
    defaultImageList: [],
    editing: true,
}

export default Attachment;