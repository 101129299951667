import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Input from './Input';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { DateFormat } from 'util/Constant';

/// <summary>
/// Author: Chris
/// </summary>
const Datepicker = (props) => {
    const [toggleClass, setToggleClass] = useState(false);
    const { showMonthYearPicker, onChange:propsOnChange, ...rest } = props;

    return (
        <Controller
            {...props}
            render={({ value, ...otherProps }) => {
                return (
                    <ReactDatepicker
                        value={value ? moment(value).format(DateFormat._DATE_ONLY) : moment(props?.defaultValue).format(DateFormat._DATE_ONLY)}
                        autoComplete="off"
                        dateFormat={showMonthYearPicker ? "yyyy/MM" : "yyyy/MM/dd"}
                        selected={new Date(value)}
                        customInput={<Input />}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode={'select'}
                        disabled={props?.disabled}
                        onFocus={() => { setToggleClass(true) }}
                        wrapperClassName={classnames({ 'datepicker-selected': (value || props?.defaultValue || toggleClass) ? true : false })}
                        {...rest}
                        {...otherProps}
                        onChange={(e) => {
                            if (typeof propsOnChange === 'function')
                                propsOnChange(e);
                            otherProps.onChange(e);
                            setToggleClass(Boolean(e))
                        }}
                        onBlur={({ target: { value } }) => { otherProps.onBlur(value); setToggleClass(Boolean(value)) }}
                        portalId="datepicker-portal"
                        showMonthYearPicker={showMonthYearPicker}
                    />
                )
            }}
        />
    );
};

Datepicker.defaultProps = {
    defaultValue: new Date(),
}

export default Datepicker;