import NavigationButton from 'components/buttons/NavigationButton';
import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import TabPanel from 'components/panel/TabPanel';
import { Tab, Tabs } from '@material-ui/core';
import { OfficerDao } from 'data';
import { Report, Block } from 'notiflix';
import { ApiKey, ApiUrl, InputTypes, LoadingStateText, RegexValidation, SweetAlert, WebUrl } from 'util/Constant';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { authCredentialState, personFormIdentitiesState } from 'recoil/Atoms';
import moment from 'moment';
import { isEmpty } from 'lodash';
import InputHoc from 'components/form/InputHoc';
import { Col, ModalBody, ModalFooter, Row } from 'reactstrap';
import BrandModal from 'components/modals/BrandModal';
import { cleanObject, formatCountryList, formatJurisdictionList, stringIsNullOrEmpty } from 'util/Utility';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

const _FORM_KEYS = { INDIVIDUAL: 'individual', CORPORATE: 'corporate' };

///<summary>
///Author: Lewis
///</summary>
const AddNewPeople = () => {

    const _history = useHistory();
    const { t } = useTranslation();
    const [countryAbbv, setCountryAbbv] = useState('hk');
    const { firmId } = useRecoilValue(authCredentialState);
    const _ID_OPTIONS = useRecoilValue(personFormIdentitiesState);
    const [isDirtyModalShow, setIsDirtyModalShow] = useState(false);
    const { control, errors, handleSubmit, formState: { isDirty, ...restFormState } } = useForm();

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: countryJson } = useSWR([ApiUrl._API_GET_COUNTRRY_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const countryOptions = useMemo(() => {
        return countryJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatCountryList(countryJson[ApiKey._API_DATA_KEY]) : [];
    }, [countryJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        INDIVIDUAL: { id: 0, title: "INDIVIDUAL" },
        CORPORATE: { id: 1, title: "CORPORATE" }
    };

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { detailsField, attachmentField } = useMemo(() => {
        let fields = {
            [_FORM_KEYS.INDIVIDUAL]: [
                { label: 'ENGLISH_NAME', name: 'englishName', rules: { required: "required" } },
                {
                    label: 'NATIONALITY', name: 'nationality', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" },
                    onChange: (countryId) => {
                        setCountryAbbv(countryOptions.filter(x => x.id == countryId)[0].shortCode.toLowerCase())
                    },
                },
                { label: 'WECHAT', name: 'wechat', rules: { required: false } },
                { label: 'ENGLISH_SURNAME', name: 'englishSurname', rules: { required: "required" } },
                {
                    label: 'PHONE_NUMBER', name: 'phoneNumber', inputType: InputTypes.PHONE, country: countryAbbv, rules: { required: "required" },
                },
                { label: 'DATE_OF_BIRTH', name: 'dateOfBirth', inputType: InputTypes.DATEPICKER, rules: { required: "required" }, defaultValue: moment().toDate() },
                { label: 'CHINESE_NAME', name: 'chineseName', rules: { required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") } } },
                {
                    label: 'IDENTITY_TYPE', name: 'identityType', options: _ID_OPTIONS, inputType: InputTypes.SELECT, rules: { required: "required" },
                },
                {
                    label: 'EMAIL', name: 'email', type: 'email',
                    rules: {
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                        }
                    },
                },
                { label: 'CHINESE_SURNAME', name: 'chineseSurname', rules: { required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") } } },
                { label: 'IDENTIFICATION_NUM', name: 'identityNumber', rules: { required: "Idenfication Number is required" } },
                { name: 'firmId', rules: { required: false }, hidden: true, value: firmId ? firmId : null },
            ],
            [_FORM_KEYS.CORPORATE]: [
                { label: 'ENGLISH_NAME', name: 'englishName', rules: { required: "required" }, columnOptions: { xl: 6 } },
                { label: 'CHINESE_NAME', name: 'chineseName', rules: { required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: t("PLEASE_FILL_IN_CN") } }, columnOptions: { xl: 6 } },
                { label: 'JURISDICTION_OF_INCORP', name: 'jurisdictionId', options: jurisdictionOptions, inputType: InputTypes.SELECT, rules: { required: "required" } },
                { label: 'DATE_OF_INCORP', name: 'dateOfIncorporation', inputType: InputTypes.DATEPICKER, rules: { required: "required" }, defaultValue: moment().toDate() },
                { label: 'COUNTRY', name: 'countryId', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" } },
                { label: 'ADDRESS', name: 'address1', inputType: InputTypes.TEXTAREA, minRows: 4, rules: { required: "required" } },
                { name: 'firmId', rules: { required: false }, hidden: true, value: firmId ? firmId : null },
            ]
        }

        let attachmentFields = {
            [_FORM_KEYS.INDIVIDUAL]: [
                { label: 'COUNTRY', name: 'countryId', options: countryOptions, inputType: InputTypes.SELECT, rules: { required: "required" }, columnOptions: { xl: 12 } },
                { label: 'ADDRESS', name: 'address1', inputType: InputTypes.TEXTAREA, minRows: 4, rules: { required: "required" }, columnOptions: { xl: 12 } },
                { label: 'ID_ATTACHMENT', name: 'identityAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, rules: { required: "required" }, columnOptions: { xl: 6 } },
                { label: 'ADDRESS_PROOF', name: 'addressProofAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, rules: { required: "required" }, columnOptions: { xl: 6 } },
            ],
            [_FORM_KEYS.CORPORATE]: [
                { label: 'CI_NUM', name: 'ciNumber', rules: { required: "required" } },
                { label: 'CERT_ATTACHMENT', name: 'certificateAttachment', inputType: InputTypes.FILEUPLOAD, accept: { pdf: true, image: true }, rules: { required: "required" }, columnOptions: { xl: 6 } },
            ]
        }

        let detailsField = fields[currentTab == 0 ? _FORM_KEYS.INDIVIDUAL : _FORM_KEYS.CORPORATE];
        let attachmentField = attachmentFields[currentTab == 0 ? _FORM_KEYS.INDIVIDUAL : _FORM_KEYS.CORPORATE];

        detailsField = detailsField.map(xprops => {
            if (isEmpty(xprops)) return {};
            return { control, error: errors?.[xprops.name], ...xprops };
        });

        attachmentField = attachmentField.map(xprops => {
            if (isEmpty(xprops)) return {};
            return { control, error: errors?.[xprops.name], ...xprops };
        });

        return { detailsField, attachmentField };
    }, [
        firmId,
        currentTab,
        control,
        errors,
        countryAbbv,
        countryOptions,
        jurisdictionOptions,
        restFormState
    ]);

    const onFormSubmit = async (formValue) => {

        let officerDao = new OfficerDao();

        formValue = cleanObject(formValue);
        if (!stringIsNullOrEmpty(firmId)) {
            formValue.firmId = firmId;
        }

        if (currentTab == 0) {

            Block.Circle("div#AddNewPeople", LoadingStateText._PLEASE_WAIT);

            await officerDao.createIndividualOfficer(formValue).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success(t(SweetAlert._OPERATION_SUCCESS), t("SUCCESS_ADD_IND_OFFICER"), t(SweetAlert._OK), () => {
                        _history.goBack();
                        return;
                    });
                }
                else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => Block.Remove("div#AddNewPeople"));

        }
        else {

            Block.Circle("div#AddNewPeople", LoadingStateText._PLEASE_WAIT);

            await officerDao.createCorporateOfficer(formValue).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    Report.Success(t(SweetAlert._OPERATION_SUCCESS), t("SUCCESS_ADD_CORP_OFFICER"), t(SweetAlert._OK), () => {
                        _history.goBack();
                        return;
                    });
                } else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => Block.Remove("div#AddNewPeople"));

        }
    }

    //<summary>
    //Author: Lewis
    //</summary>
    const toggleDirtyModal = () => setIsDirtyModalShow(prevState => !prevState);

    //<summary>
    //Author: Lewis
    //</summary>
    useEffect(() => {
        handleSubmit(onFormSubmit)();
    }, [currentTab]);

    return (
        <>
            <div className="panel panel-brand panel-flex" id="AddNewPeople">
                <div className="panel-header p-b-0">
                    <h1 className="page-header title title-with-dot"><NavigationButton /> {t("ADD_NEW_PERSON")}</h1>
                    <Tabs value={currentTab} onChange={(e, newTab) => {

                        if (isDirty) {
                            toggleDirtyModal();
                            return;
                        }

                        setCurrentTab(newTab);

                    }}
                        variant="scrollable"
                    >
                        <Tab icon={<img src={require('../../assets/img/ui/individual.svg')} className="mr-2" />} label={t(_TABS.INDIVIDUAL.title)} classes={{ wrapper: "d-inline" }} />
                        <Tab icon={<img src={require('../../assets/img/ui/corporate.svg')} className="mr-2" />} label={t(_TABS.CORPORATE.title)} classes={{ wrapper: "d-inline" }} />
                    </Tabs>
                </div>
                <div className="panel-body y-scrollbar-2 h-100 p-0">
                    <div className="mt-4 p-l-40 p-r-40" id="officer-form-submit-blocker">
                        <TabPanel value={currentTab} index={_TABS.INDIVIDUAL.id}>
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row>
                                    <Col xl={12}>
                                        <p><strong><img className="step-incomplete m-r-5" src={require("../../assets/img/ui/individual.svg")} />{t("PERSONAL_DETAIL")}</strong></p>
                                    </Col>
                                    {
                                        detailsField.map((fieldProps, index) => {
                                            return (
                                                <Col xl={4} key={fieldProps.name}>
                                                    <InputHoc {...fieldProps} />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <Row className="m-0">
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}><p><strong><img className="step-incomplete m-r-5" src={require("../../assets/img/icon/address-info.svg")} /> {t("ADDRESS_INFO")}</strong></p></Col>
                                            {
                                                attachmentField.slice(0, 2).map(fieldProps => {
                                                    return <Col {...fieldProps.columnOptions} key={fieldProps.name}>
                                                        <InputHoc {...fieldProps} />
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}><p><strong><img className="step-incomplete m-r-5" src={require("../../assets/img/icon/attachment.svg")} /> {t("ATTACHMENT")}</strong></p></Col>
                                            {
                                                attachmentField.slice(2, 4).map(fieldProps => {
                                                    return <Col {...fieldProps.columnOptions}>
                                                        <InputHoc {...fieldProps} />
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </form>
                        </TabPanel>
                        <TabPanel value={currentTab} index={_TABS.CORPORATE.id}>
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <Row>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}>
                                                <p><strong><img className="step-incomplete m-r-5" src={require("../../assets/img/ui/corporate.svg")} /> {t("CORPORATE_DETAIL")}</strong></p>
                                            </Col>
                                            {
                                                detailsField.map((fieldProps, index) => {
                                                    return (
                                                        <Col xl={12} key={fieldProps.name}>
                                                            <InputHoc {...fieldProps} />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                    <Col xl={6}>
                                        <Col xl={12}>
                                            <p><strong><img className="step-incomplete m-r-5" src={require("../../assets/img/icon/attachment.svg")} /> {t("CI_DETAIL")}</strong></p>
                                        </Col>
                                        {
                                            attachmentField.map((fieldProps, index) => {
                                                return (
                                                    <Col xl={12} key={fieldProps.name}>
                                                        <InputHoc {...fieldProps} />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </TabPanel>
                    </div>
                </div>
                <div className="panel-footer text-center d-flex justify-content-end" style={{ backgroundColor: "transparent" }}>
                    <button className="btn btn-themed btn-mid-long" onClick={() => {
                        handleSubmit(onFormSubmit)()
                    }}>{t("SUBMIT")}</button>
                </div>
            </div>
            <BrandModal
                isOpen={isDirtyModalShow}
                toggler={toggleDirtyModal}
                customBody={true}
                title={t("NOT_YET_SAVE")}
                className="form-dirty-check-modal"
                modalSize="md"
            >
                <ModalBody className="p-0 text-center">
                    <p className="m-t-20 m-b-20">{t("DETAIL_NOT_SAVED_ARE_YOU_SURE_LEAVE")}</p>
                </ModalBody>
                <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                    <button className="btn btn-themed btn-min-width grayscale-100" onClick={toggleDirtyModal}>{t("NO")}</button>
                    <button className="btn btn-themed btn-min-width" onClick={() => {
                        toggleDirtyModal();
                        setCurrentTab(prevState => prevState === 0 ? 1 : 0);
                    }}>{t("YES")}</button>
                </ModalFooter>
            </BrandModal>
        </>
    )
}

export default AddNewPeople;