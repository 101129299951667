import { ApiUrl } from 'util/Constant';
import { createMultiPartFormBody } from 'util/Utility';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
class AccountantDao extends DataAccessObject {

    async checkUserAuthorization() {
        return this.get(ApiUrl._API_CHECK_AUTHORIZATION, null, false);
    }
    async getAccountantsList() {
        return this.get(ApiUrl._API_GET_ACCOUNTANT_LIST);
    }
    async getAllAccountantCount() {
        return this.get(ApiUrl._API_GET_ALL_ACCOUNTANT_COUNT);
    }
    async getPendingAccountantCount() {
        return this.get(ApiUrl._API_GET_PENDING_ACCOUNTANT_COUNT);
    }
    async getPendingApprovalAccountantList() {
        return this.get(ApiUrl._API_GET_PENDING_APPROVAL_ACCOUNTANT_LIST);
    }
    async getAccountantDetails(accountantID) {
        return this.get(ApiUrl._API_GET_ACCOUNTANT_DETAILS.replace(":id", accountantID));
    }
    async updateAccountantStatus(accountantID, approvalDecision) {
        return this.post(ApiUrl._API_UPDATE_ACCOUNTANT_APPROVAL_DETAILS.replace(":id", accountantID).replace(":decision", approvalDecision));
    }
    async updateAccountantActive(accountantID, activeStatusChange) {
        return this.post(ApiUrl._API_UPDATE_ACCOUNTANT_ACTIVE_STATUS.replace(":id", accountantID).replace(":decision", activeStatusChange));
    }
    async updateAccountantActive_List(accountantID, activeStatusChange) {
        return this.post(ApiUrl._API_UPDATE_ACCOUNTANT_ACTIVE_STATUS_LIST.replace(":id", accountantID).replace(":decision", activeStatusChange));
    }
    async getUser() {
        return this.get(ApiUrl._API_GET_USER);
    }
    async getUserList() {
        return this.get(ApiUrl._API_GET_USER_LIST_OPTION);
    }
    async updateAccountantProfile(postData) {
        const { id, ...restPostData } = postData;
        return this.put(ApiUrl._API_UPDATE_ACCOUNTANT_PROFILE.replace(":userId", id), restPostData);
    }
    async updateAccountantEmail(postData) {
        const { id, ...restPostData } = postData;
        return this.put(ApiUrl._API_UPDATE_ACCOUNTANT_EMAIL.replace(":userId", id), restPostData);
    }
    async changePassword(postData) {
        return this.post(ApiUrl._API_CHANGE_PASSWORD, postData);
    }
    async updateAdminProfile(postData) {
        const { id, ...restPostData } = postData;
        return this.put(ApiUrl._API_UPDATE_ADMIN_PROFILE.replace(":userId", id), restPostData);
    }
    async checkResetCodeAvailability(resetOTP) {
        return this.get(ApiUrl._API_CHECK_RESET_OTP_AVAILABILITY.replace(":otp", resetOTP));
    }
    async sendForgotPasswordEmail(email) {
        return this.post(ApiUrl._API_SEND_RESET_PASSWORD_EMAIL, { "email": email });
    }
    async resetForgetPassword(postData) {
        return this.post(ApiUrl._API_RESET_PASSWORD, postData);
    }

    // Sub-Accountant
    async getSubAccountantList() {
        return this.get(ApiUrl._API_GET_SUBACCOUNTANT_LIST);
    }
    async addSubAccountant(data) {
        return this.post(ApiUrl._API_ADD_SUBACCOUNTANT, data);
    }
    async getSubAccountantProfile(accountantID) {
        return this.get(ApiUrl._API_GET_SUBACCOUNTANT_PROFILE.replace(":id", accountantID));
    }
    async updateSubAccountantProfile(data, accountantID) {
        return this.post(ApiUrl._API_UPDATE_SUBACCOUNTANT_PROFILE.replace(":id", accountantID), data);
    }
    async deleteSubAccountant(accountantID) {
        return this.post(ApiUrl._API_DELETE_SUBACCOUNTANT.replace(":id", accountantID));
    }

    // Sub-Accountant
    async getSubAccountantList() {
        return this.get(ApiUrl._API_GET_SUBACCOUNTANT_LIST);
    }
    async addSubAccountant(data) {
        return this.post(ApiUrl._API_ADD_SUBACCOUNTANT, data);
    }
    async getSubAccountantProfile(accountantID) {
        return this.get(ApiUrl._API_GET_SUBACCOUNTANT_PROFILE.replace(":id", accountantID));
    }
    async updateSubAccountantProfile(data, accountantID) {
        return this.post(ApiUrl._API_UPDATE_SUBACCOUNTANT_PROFILE.replace(":id", accountantID), data);
    }
    async deleteSubAccountant(accountantID) {
        return this.post(ApiUrl._API_DELETE_SUBACCOUNTANT.replace(":id", accountantID));
    }
    async resetPassword(accountantID) {
        return this.post(ApiUrl._API_RESET_PASSWORD.replace(":id", accountantID));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async createOrUpdateAccountantCart(companyId) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_ACCOUNTANT_CART.replace(":id", companyId));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async getAccountantCart() {
        return this.get(ApiUrl._API_GET_ACCOUNTANT_CART);
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async updateCartItem(cartId, productId) {
        return this.get(ApiUrl._API_UPDATE_CART_ITEM.replace(":cartId", cartId).replace(":productId", productId));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async deleteCart(cartId) {
        return this.delete(ApiUrl._API_DELETE_CART_ITEM.replace(":id", cartId));
    }

    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    async addCart(companyId, productMatterId) {
        return this.post(ApiUrl._API_CREATE_CART.replace(":companyId", companyId).replace(":productMatterId", productMatterId));
    }

    async getOwnerList(departmentId, jurisdictionId) {
        return this.get(ApiUrl._API_GET_TASK_OWNER_LIST.replace(":departmentId", departmentId).replace(":jurisdictionId", jurisdictionId));
    }

    async getActivityLogs(startDate, endDate) {
        return this.get(ApiUrl._API_GET_USER_ACTIVITY_LOGS.replace(":startDate", startDate).replace(":endDate", endDate));
    }

    async getReferralsHistory() {
        return this.get(ApiUrl._API_GET_REFERRAL_USERS);
    }

    async generateReferralCode() {
        return this.get(ApiUrl._API_GENERATE_REFERRAL_CODE);
    }

    async getHistoryAttachmentList(userId) {
        return this.get(ApiUrl._API_GET_HISTORY_ATTACHMENTS_LIST.replace(":userId", userId));
    }

    async updateCertificateAttachment(userId, attachmentId, data) {
        var formBody = new FormData();
        formBody.append('certificate', data);
        return this.postForm(ApiUrl._API_UPDATE_CERTIFICATE_ATTACHMENT.replace(":userId", userId).replace(":attachmentId", attachmentId), formBody);
    }

    async updateIdentificationAttachment(userId, attachmentId, data) {
        var formBody = new FormData();
        formBody.append('identification', data);
        return this.postForm(ApiUrl._API_UPDATE_IDENTIFICATION_ATTACHMENT.replace(":userId", userId).replace(":attachmentId", attachmentId), formBody);
    }

    async adminApproveAttachment(userId, postData) {
        return this.post(ApiUrl._API_ADMIN_APPROVE_ATTACHMENT.replace(":userId", userId), postData);
    }
    async adminRejectAttachment(userId, postData) {
        return this.post(ApiUrl._API_ADMIN_REJECT_ATTACHMENT.replace(":userId", userId), postData);
    }
}

export default AccountantDao;
