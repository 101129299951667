import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputTypes } from 'util/Constant';
import { getIconByName } from 'util/Utility';
import _ from 'lodash/object';

import { Select, Input, NumberInput, Textarea, Radio, Phone, Attachment, Datepicker, Checkbox, FileUpload, InputColor, CustomImageUploader } from 'components/form';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Chris
/// </summary>
const InputHoc = forwardRef((props, ref) => {
    const {
        showError,
        formGroup,
        inline,
        label,
        error,
        smallnote,
        inputType,
        labelClasses,
        formGroupClass,
        prefix,
        postfix,
        placeholder,
        labelIcon,
        ...otherProps
    } = props;

    const { t } = useTranslation();

    const InputComponents = {
        [InputTypes.INPUT]: Input,
        [InputTypes.NUMBER_INPUT]: NumberInput,
        [InputTypes.SELECT]: Select,
        [InputTypes.RADIO]: Radio,
        [InputTypes.PHONE]: Phone,
        [InputTypes.TEXTAREA]: Textarea,
        [InputTypes.ATTACHMENT]: Attachment,
        [InputTypes.DATEPICKER]: Datepicker,
        [InputTypes.CHECKBOX]: Checkbox,
        [InputTypes.FILEUPLOAD]: FileUpload,
        [InputTypes.INPUT_COLOR]: InputColor,
        [InputTypes.IMAGE_UPLOADER]: CustomImageUploader,
    }

    const MaterialUI = [InputTypes.INPUT, InputTypes.NUMBER_INPUT, InputTypes.SELECT, InputTypes.TEXTAREA, InputTypes.DATEPICKER];

    let wrapperClasses = classnames(
        {
            'form-group': formGroup,
            'd-flex': inline,
            [formGroupClass]: formGroupClass,
            'has-error': error
        },
    );

    if (typeof InputComponents[inputType] !== 'undefined') {
        return (
            <div className={wrapperClasses}>
                {postfix || prefix ?
                    <>
                        {label && <label className={classnames({ [labelClasses]: labelClasses })}>{t(label)}{labelIcon && labelIcon}</label>}
                        <div className="d-flex form-group-wrapper form-postfix">
                            {prefix &&
                                <span className="input-prefix">
                                    {prefix.icon ?
                                        <img src={getIconByName(prefix.icon)} alt="" />
                                        : prefix
                                    }
                                </span>
                            }
                            {React.createElement(InputComponents[inputType], { error: error, ref, placeholder: placeholder ?? t(placeholder), ...otherProps })}
                            {postfix && <span className="input-postfix">{postfix}</span>}
                        </div>
                    </> :
                    !MaterialUI.includes(inputType) ? (<>
                        {label && <label className={classnames({ [labelClasses]: labelClasses })}>{t(label)}{labelIcon && labelIcon}</label>}
                        {React.createElement(InputComponents[inputType], { error: error, placeholder: placeholder ?? t(placeholder), ref, ...otherProps })}
                    </>)
                        : (
                            <>
                                {label && <label className={classnames({ [labelClasses]: labelClasses })}>{t(label)}{labelIcon && labelIcon}</label>}
                                {React.createElement(InputComponents[inputType], { error: error, placeholder: placeholder ?? t(placeholder), ref, ...otherProps })}
                            </>
                        )
                }
                {smallnote && <span className="input-smallnote">{smallnote}</span>}
                {smallnote && error && <br />}
                {(showError && error) && <span className="input-error">{error}</span>}
            </div>
        );
    }

    return null;
});

InputHoc.defaultProps = {
    formGroup: true,
    inline: false,
    inputType: InputTypes.INPUT,
    formGroupClass: '',
    autoComplete: 'off',
    showError: false,
}

InputHoc.propTypes = {
    showError: PropTypes.bool,
    formGroup: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    smallnote: PropTypes.string,
    inputType: PropTypes.oneOf(_.values(InputTypes)),
    labelClasses: PropTypes.string,
    formGroupClass: PropTypes.string,
}

export default InputHoc;