import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { WebUrl, Status, ApiKey, AccountantStatus, ApiUrl, DateFormat, BtnTypes, InputTypes, Icon, LoadingStateText, FileType, TableId } from 'util/Constant';
import { UncontrolledTooltip, Tooltip, Card, CardBody, Row, Col, Collapse } from "reactstrap";
import AccountantDao from '../../data/AccountantDao';
import Moment from 'moment';
import Notiflix, { Block } from "notiflix";
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import ButtonRound from 'components/buttons/ButtonRound';
import { useHistory } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import _, { isEmpty } from 'lodash';
import { setAccountantStatusName } from 'util/Utility';
import { PageSettings } from 'config/page-settings';
import { useRecoilValue } from 'recoil';
import { userIdSelector } from 'recoil/Atoms';

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
const AccountantListing = () => {
    const { t } = useTranslation();

    const { ref: contentWrapperRef } = useResizeDetector();

    const _SEARCH_FORMS = useMemo(() => {
        const _STATUS = [
            { label: 'Active', value: true },
            { label: 'Inactive', value: false },
        ];
        const _FORM_FIELDS = [
            {
                columns: [
                    { prefix: { icon: Icon._PROFILE }, label: `${t("SEARCH_ENG_NAME")}`, placeholder: `${t("SEARCH_ENG_NAME")}`, name: 'name', input: InputTypes.INPUT, columnOptions: { xl: 2, md: 6 }, autoComplete: "new-password" },
                    { prefix: { icon: Icon._EMAIL }, label: `${t("SEARCH_EMAIL")}`, placeholder: `${t("SEARCH_EMAIL")}`, name: 'email', input: InputTypes.INPUT, columnOptions: { xl: 2, md: 6 }, autoComplete: "new-password" },
                    { prefix: { icon: Icon._SEARCH_PHONE_NUMBER }, label: `${t("SEARCH_PHONE_NUMBER")}`, placeholder: `${t("SEARCH_PHONE_NUMBER")}`, name: 'phoneNumber', input: InputTypes.INPUT, columnOptions: { xl: 2, md: 6 }, autoComplete: "new-password" },
                    //{ prefix: { icon: Icon._SELECT_ACTIVITY }, label: 'Status', placeholder: t('STATUS'), name: 'active', input: InputTypes.SELECT, options: _STATUS, columnOptions: { xl: 2, md: 6 } },
                ]
            }
        ];

        return _FORM_FIELDS;
    }, []);

    const [accountantCount, setAccountantCount] = useState(0);
    const [pendingAccountantCount, setPendingAccountantCount] = useState(0);
    const [verifyAttachmentCount, setVerifyAttachmentCount] = useState(0);
    const _history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);
    const _TAB_KEYS = {
        PENDING_ACCOUNTANT: "Pending Accountant",
        ALL_ACCOUNTANT: "All Accountant",
    }
    const _TABS = {
        [_TAB_KEYS.PENDING_ACCOUNTANT]: { id: 0, title: t("PENDING_APPROVAL"), count: pendingAccountantCount },
        [_TAB_KEYS.ALL_ACCOUNTANT]: { id: 1, title: t("ALL_ACCOUNTANT"), count: accountantCount },
    }

    const toggle = (index) => setCurrentTab(index);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fixedContentHeight = useMemo(() => {
        if (contentWrapperRef.current != null) {
            return contentWrapperRef.current.clientHeight - 53;
        }
    }, [contentWrapperRef?.current]);

    return <div className="d-flex flex-column h-100">
        <div id="UserManagement" className="flex-grow-1 management-tabs">
            <div className="d-flex flex-column h-100">
                <Row className="m-0">
                    {
                        Object.keys(_TABS).map((key, index) => {
                            return (<>
                                <Col>
                                    <div className={`${currentTab == index ? "active" : ""} card-header`} onClick={() => { toggle(index) }}>
                                        <h4 style={{ whiteSpace: 'nowrap' }}>{t(_TABS[key].title)} {"(" + _TABS[key].count + ")"}</h4>
                                    </div>
                                </Col>
                            </>);
                        })
                    }
                </Row>
                <Row className="m-0 h-100 flex-grow-1">
                    <div className="col-xl-12 p-0" ref={contentWrapperRef}>
                        <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.PENDING_ACCOUNTANT].id}>
                            <RenderTable
                                tabId={_TABS[_TAB_KEYS.PENDING_ACCOUNTANT].id}
                                _TABS={_TABS}
                                _TAB_KEYS={_TAB_KEYS}
                                _SEARCH_FORMS={_SEARCH_FORMS}
                                fixedContentHeight={fixedContentHeight}
                                setTotalCount={setPendingAccountantCount}
                                _history={_history}
                                _translation={t}
                            />
                        </Collapse>
                        <Collapse isOpen={currentTab == _TABS[_TAB_KEYS.ALL_ACCOUNTANT].id}>
                            <RenderTable
                                tabId={_TABS[_TAB_KEYS.ALL_ACCOUNTANT].id}
                                _TABS={_TABS}
                                _TAB_KEYS={_TAB_KEYS}
                                _SEARCH_FORMS={_SEARCH_FORMS}
                                fixedContentHeight={fixedContentHeight}
                                setTotalCount={setAccountantCount}
                                _history={_history}
                                _translation={t}
                            />
                        </Collapse>
                    </div>
                </Row>
            </div>
        </div>
    </div>;
}

const RenderTable = props => {
    const {
        tabId,
        _TABS,
        _TAB_KEYS,
        _SEARCH_FORMS,
        fixedContentHeight,
        setTotalCount,
        _history,
        _translation,
    } = props;

    const userId = useRecoilValue(userIdSelector);
    const { t } = useTranslation();

    const _tableRef = useRef();
    const _filterRef = useRef();
    const [filterValues, setFilterValues] = useState();
    const [reset, setReset] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    // const [refreshTable, setRefreshTable] = useState(false);
    const {
        isXsDevices,
        isSmDevices
    } = useContext(PageSettings);

    const { _COLUMN, _URL } = useMemo(() => {
        let tempColumn = [
            {
                Header: 'NAME',
                accessor: 'name',
                isRequiredColumn: true
            },
            {
                Header: 'EMAIL',
                accessor: 'email',
                isRequiredColumn: true
            },
            {
                Header: 'REGISTRATION_DATE',
                accessor: el => el.createdDate ? Moment(el.createdDate).format(DateFormat._DATE_FORMAT) : ""
            },
            {
                Header: 'PHONE_NUMBER',
                accessor: 'phoneNumber'
            },
            {
                Header: 'STATUS',
                accessor: 'accountantStatusName',
                Cell: ({ row, value }) => {
                    return (<>
                        <div className={setBadgeColour(row.original.accountantStatusId)}>{t(setAccountantStatusName(row.original.accountantStatusId))}</div>
                    </>
                    )
                },
                width: '1%'
            },
            {
                Header: 'ACTIVE',
                accessor: 'active',
                Cell: ({ value }) => {
                    return (<>
                        {
                            value ?
                                <span className="badge badge-success">{t("ACTIVE")}</span>
                                :
                                <span className="badge badge-danger">{t("INACTIVE")}</span>
                        }
                    </>)
                },
                width: '1%'
            }
        ];
        let tempUrl;
        if (tabId === _TABS[_TAB_KEYS.ALL_ACCOUNTANT].id) {
            tempColumn.push(...[
                {
                    id: "Attachment Status",
                    Header: 'Attachment Status',
                    accessor: el => Object.values(el.pendingAttachment).includes(true),
                    Cell: ({ row, value }) => {
                        if (value) {
                            return <>
                                <i class="fas fa-info-circle text-warning" id={`attachment-status-${tabId}-${row.original.id}`}></i>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="bottom-right" target={`attachment-status-${tabId}-${row.original.id}`}>{t('VERIFY_ATTACHMENT')}</UncontrolledTooltip>
                            </>
                        }
                        return "";
                    },
                    disableFilters: true,
                    disableSortBy: true,
                    width: '1%'
                },
                {
                    Header: 'ACTION',
                    Cell: ({ row }) => {
                        return (
                            <div className="btn-list-wrapper d-flex">
                                <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => _history.push(WebUrl._ACCOUNTANT_DETAILS, row.original.id)} />
                                {
                                    row.original.accountantStatusId == AccountantStatus._APPROVED &&
                                    (row.original.active ? row.original.active && (
                                        <ButtonRound iconClass={"las la-user-times"} className={"btn-round btn-danger"} medium title={`${t('notiflix:DISABLE')} ${t('ACCOUNTANT')}`} onClick={() =>
                                            Notiflix.Confirm.Show(`${t('notiflix:DISABLE')}${t('ACCOUNTANT')}`, `${t('notiflix:WISH_TO_DISABLE')}${t('ACCOUNTANT')}?`, t('notiflix:DISABLE'), t('notiflix:NO'),
                                                () => { changeActiveStatus(row.original.id, false) })}
                                        />
                                    ) : (
                                        <ButtonRound iconClass={"las la-user-check"} className={"btn-round"} medium title={`${t('notiflix:ENABLE')} ${t('ACCOUNTANT')}`} onClick={() =>
                                            Notiflix.Confirm.Show(`${t('notiflix:ENABLE')}${t('ACCOUNTANT')}`, `${t('notiflix:WISH_TO_ENABLE')}${t('ACCOUNTANT')}？`, t('notiflix:ENABLE'), t('notiflix:NO'),
                                                () => { changeActiveStatus(row.original.id, true) })}
                                        />
                                    ))
                                }
                            </div>
                        )
                    },
                    disableFilters: true,
                    disableSortBy: true,
                    isRequiredColumn: true
                }
            ]);
            tempUrl = ApiUrl._API_GET_ACCOUNTANT_LIST;
        }
        else if (tabId == _TABS[_TAB_KEYS.PENDING_ACCOUNTANT].id) {
            tempColumn.push({
                Header: 'ACTION',
                Cell: ({ row }) => <>
                    <ButtonRound type={BtnTypes.FA_VIEW} medium onClick={() => _history.push(WebUrl._ACCOUNTANT_DETAILS, row.original.id)} />
                </>,
                disableFilters: true,
                disableSortBy: true,
                isRequiredColumn: true
            });
            tempUrl = ApiUrl._API_GET_PENDING_APPROVAL_ACCOUNTANT_LIST;
        }

        return { _COLUMN: tempColumn, _URL: tempUrl };
    }, []);

    const filterValuesOnSubmit = (data) => setFilterValues(data);
    const retrieveCallback = (tableData, totalCount) => { setTotalCount(totalCount) };

    const resetFilterValues = () => {

        if (isEmpty(filterValues)) {
            _tableRef.current.reFetch();
            return;
        }

        setReset(prevState => !prevState);
        setFilterValues();
    };

    const changeActiveStatus = async (id, decision) => {
        let dao = new AccountantDao();
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        await dao.updateAccountantActive_List(id, decision).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                _tableRef.current.reFetch();
                // setRefreshTable(prev => !prev);
                Notiflix.Notify.Success(response[ApiKey._API_MESSAGE_KEY]);
            }
            else {
                Notiflix.Notify.Failure(t("notiflix:UPDATE_ACTIVE_STATUS_FAILED"));
            }
        }).finally(() => Block.Remove(".notiflix-confirm-content"));
    }

    function setBadgeColour(statusId) {
        switch (statusId) {
            case (AccountantStatus._PENDING_APPROVAL):
                return 'badge badge-warning';
                break;
            case (AccountantStatus._APPROVED):
                return 'badge badge-success';
                break;
            case (AccountantStatus._REJECTED):
                return 'badge badge-danger';
                break;
        }
    }

    return <>
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container">
                {
                    (isXsDevices || isSmDevices) ? (
                        <div className="filter-panel-container border-radius-one">
                            <Row className="filter-panel-header">
                                <div className="filter-panel-header-content">
                                    <div className="btn btn-light btn-filter" onClick={() => setIsFilterOpen(prevState => !prevState)}>
                                        <img alt="" className="mr-2" src={require("../../assets/img/icon/filter.svg")} />{t("FILTER")}
                                    </div>
                                    {
                                        isFilterOpen &&
                                        <div className="d-flex btn-list">
                                            <button className="btn btn-themed btn-brand-round m-l-auto" onClick={() => {submitForm(_filterRef); setIsFilterOpen(prevState => !prevState);}}><i className="las la-search" /></button>
                                            <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFilterValues()}><i className="las la-redo-alt" /></button>
                                        </div>
                                    }
                                </div>
                            </Row>
                            <Collapse isOpen={isFilterOpen}>
                                <Row className="filter-panel-body">
                                    <Col xl={10}>
                                        <FormBuilder
                                            fields={_SEARCH_FORMS}
                                            formRef={_filterRef}
                                            onSubmit={filterValuesOnSubmit}
                                            reset={reset}
                                            resetTriggerReset={setReset}
                                            resetValues={{}}
                                            autoComplete="new-password"
                                        />
                                    </Col>
                                </Row>
                            </Collapse>
                        </div>
                    ) :
                    <Row className="filter-panel-body">
                        <Col xl={10}>
                            <FormBuilder
                                fields={_SEARCH_FORMS}
                                formRef={_filterRef}
                                onSubmit={filterValuesOnSubmit}
                                reset={reset}
                                resetTriggerReset={setReset}
                                resetValues={{}}
                            />
                        </Col>
                        <Col xl={2} className="btn-list d-flex justify-content-end">
                            <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_filterRef)}><i className="las la-search" /></button>
                            <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFilterValues()}><i className="las la-redo-alt" /></button>
                        </Col>
                    </Row>
                }
            </div>
            <div className="flex-grow-1">
                <ReactTable
                    ref={_tableRef}
                    columns={_COLUMN}
                    url={_URL}
                    tableMinHeight={fixedContentHeight}
                    filterFormfield={filterValues}
                    fetchOnSuccess={retrieveCallback}
                    customButtons={
                        isXsDevices
                        ?
                            <div className="btn-group">
                                <button type="type" className="btn btn-themed btn-rounded" onClick={() => { _history.push(WebUrl._ADD_NEW_ACCOUNTANT) }} ><i className="fas fa-plus"></i></button>
                            </div>
                        :
                            <button type="type" className="btn btn-themed btn-min-width pull-right tbl-custom-brand-btn" onClick={() => { _history.push(WebUrl._ADD_NEW_ACCOUNTANT) }} >{t("ADD_NEW_ACCOUNTANT")}</button>
                    }
                    getRowProps={(row) => {
                        let props = row.getRowProps(row);
                        return {
                            ...props,
                            onDoubleClick: () => {
                                _history.push(WebUrl._ACCOUNTANT_DETAILS, row.original.id)
                            }
                        }
                    }}
                    tableColumnPreference={[userId, `${TableId._USERS_MANAGEMENT_ACCOUNTANT}-${tabId}`]}
                />
            </div>
        </div>
    </>
}

export default AccountantListing;