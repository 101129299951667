import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { authCredentialState, notificationState, notificationUpdate } from 'recoil/Atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { WebUrl, ApiKey, DateFormat, NotificationPriority } from 'util/Constant';
import { Link, useHistory } from 'react-router-dom';
import NotificationDao from './../../../data/NotificationDao';
import moment from 'moment';
import NoNotification from '../../../assets/img/user/no-notification.png';
import { isEmpty } from 'lodash';
import { stringIsNullOrEmpty, setNotificationColor, setNotificationBadge } from 'util/Utility';
import { useTranslation } from 'react-i18next';


/// <summary>
/// Author: Lewis
/// </summary>
const DropdownNotification = props => {

    const { t } = useTranslation();
    const authCredential = useRecoilValue(authCredentialState);
    const _history = useHistory();
    const [notificationItems, setNotificationItems] = useRecoilState(notificationState);
    const notificationStatus = useRecoilValue(notificationUpdate);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [totalUnread, setTotalUnread] = useState(0);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const _dropdownToggleHandler = () => (setDropdownOpen(!isDropdownOpen))

    // /// <summary>
    // /// Author: Ong Sze Hua
    // /// </summary>
    useEffect(() => {
        if (!isEmpty(authCredential)) {
            let params = { page: 1, pageSize: 5 };

            let notificationDao = new NotificationDao();
            (async () => {
                await notificationDao.getNotifications(params).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        setNotificationItems(jsonResponse[ApiKey._API_DATA_KEY]);
                    }
                })
            })()
        }
    }, [authCredential, notificationStatus]);



    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (notificationItems.length > 0) {
            var unReadNotifications = notificationItems.filter(el => el.isRead == false);
            setTotalUnread(unReadNotifications.length);
        }
        else if (notificationItems.length == 0 && totalUnread != 0) {
            setTotalUnread(0);
        }
    }, [notificationItems]);

    return (
        <Dropdown isOpen={isDropdownOpen} toggle={_dropdownToggleHandler} className="dropdown" tag="li" id="notificationList">
            <DropdownToggle className="f-s-14" tag="a" typeof="button" style={{ cursor: "pointer" }}>
                <img alt="" src={require("../../../assets/img/icon/notification-black.svg")} />
                {
                    !stringIsNullOrEmpty(totalUnread) && <span className="label">{totalUnread}</span>
                }
            </DropdownToggle>
            <DropdownMenu className="notification-list dropdown-menu-left" tag="ul" right>
                <div className="notification-dropdown-header border-bottom">
                    <img alt="" src={require("../../../assets/img/icon/notification-black.svg")} className="mr-2" /><h4>{t("RECENT_NOTIFICATIONS")}({totalUnread})</h4>
                </div>
                {
                    notificationItems.length > 0 ?
                        <>
                            {notificationItems.map((item, index) => (
                                <DropdownItem className={`border-bottom ${classnames('notification-menu-item', { 'opacity-5': item.isRead })}`}
                                    key={index}>
                                    <Row onClick={() => _history.push(WebUrl._USERS_NOTIFICATIONS, { id: item.id, idx: index })} id="notification-list-bullet">
                                        <span className={setNotificationColor(item.priorityName)}><i className="fas fa-circle fa-xs"></i></span>
                                        <Col xs={10} className="pr-0">
                                            <h6 className="f-s-15 notification-text">{item.title}</h6>
                                            <div className="short-description" style={{ fontWeight: "initial" }}>
                                                <p className="notification-text fs-14">
                                                    {item.messageBody}
                                                </p>
                                            </div>
                                            <div className="d-flex justify-between">
                                                <span className={setNotificationBadge(item.priorityName)}>{item.priorityName}</span>
                                                <div className="f-s-11">
                                                    {
                                                        !stringIsNullOrEmpty(item.createdDate) && <p className="m-0">{`${moment.utc(item.createdDate).fromNow(true)} ago`}</p>
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </DropdownItem>
                            ))}
                            <div className="centered">
                                <Link to={WebUrl._USERS_NOTIFICATIONS} onClick={_dropdownToggleHandler} className="btn btn-themed">{t("VIEW_MORE")}</Link>
                            </div>
                        </>
                        :
                        <div className="text-center p-t-20 p-b-20">
                            <img style={{ 'width': '35%', 'margin': 'auto' }} src={NoNotification} className="col-lg-12" />
                            <h4 className="m-t-20"> {t("NO_NOTIFICATION")} </h4>
                        </div>
                }
            </DropdownMenu>
        </Dropdown >
    )
}

export default DropdownNotification;
