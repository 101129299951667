import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BtnTypes } from 'util/Constant';
import classnames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';
import { stringIsNullOrEmpty } from 'util/Utility';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Chris
/// </summary>
const ButtonRound = ({ id, title, type, className, small, onClick, medium, iconClass, disabled, style, render }) => {
    const { t } = useTranslation();

    const _buttons = {
        [BtnTypes.ADD]: { classes: "btn-round btn-success", title: t('ADD'), icon: 'las la-plus' },
        [BtnTypes.DELETE]: { classes: "btn-round btn-danger", title: t('DELETE'), icon: 'las la-trash' },
        [BtnTypes.FA_VIEW]: { classes: "btn-round btn-themed", title: t('VIEW'), icon: 'fas fa-eye' },
        [BtnTypes.VIEW]: { classes: "btn-round btn-themed", title: t('VIEW'), icon: 'las la-eye' },
        [BtnTypes.EDIT]: { classes: "btn-round btn-themed", title: t('EDIT'), icon: 'las la-pencil-alt' },
        [BtnTypes.MISC]: { classes: "btn-round expand-blue", title: t('MISC'), icon: 'las fa-shopping-cart' },
        [BtnTypes.ADD_USER]: { classes: "btn-round expand-green", title: t('ADD_USER'), icon: 'las fa-user-plus' },
        [BtnTypes.VIEW_DOC]: { classes: "btn-round expand-theme", title: t('VIEW_DOC'), icon: 'las la-file' },
        [BtnTypes.VIEW_HISTORY]: { classes: "btn-round", title: t('VIEW_HISTORY'), icon: 'las la-history' },
        [BtnTypes.FILE_DOWNLOAD]: { classes: "btn-round btn-themed", title: t('DOWNLOAD_FILE'), icon: 'las la-file-download' },
        [BtnTypes.FILE_UPLOAD]: { classes: "btn-round btn-themed", title: t('UPLOAD'), icon: 'las la-upload' },
        [BtnTypes.ARROW_LEFT]: { classes: "btn-round", title: t('LEFT'), icon: 'las la-angle-left' },
        [BtnTypes.ARROW_RIGHT]: { classes: "btn-round", title: t('RIGHT'), icon: 'las la-angle-right' },
        [BtnTypes.THUMBS_UP]: { classes: "btn-round", title: t('THUMBS_UP'), icon: 'las la-thumbs-up' },
        [BtnTypes.CALENDER]: { classes: "btn-round", title: t('CALENDAR'), icon: 'las la-calendar-check' },
        [BtnTypes.ARROW_RIGHT_CIRCLE]: { classes: "btn-round", title: t('NEXT'), icon: 'las la-arrow-circle-right' },
        [BtnTypes.DOUBLE_RIGHT]: { classes: "btn-round btn-success", title: t('NEXT'), icon: 'fas fa-angle-double-right' },
        [BtnTypes.DOUBLE_RIGHT_PROCEED]: { classes: "btn-round btn-themed", title: t('PROCEED'), icon: 'fas fa-angle-double-right' },
        [BtnTypes.ADD_TO_CART]: { classes: "btn-round", title: t('ADD_TO_CART'), icon: 'las la-cart-plus' },
        [BtnTypes.IN_CART]: { classes: "btn-round", title: t('IN_CART'), icon: 'las la-cart-arrow-down' },
        [BtnTypes.CHECK_CIRCLE]: { classes: "btn-round btn-success", title: t('CHECKED'), icon: 'las la-check' },
        [BtnTypes.GEAR_COG]: { classes: "btn-round", title: t('SETTINGS'), icon: 'las la-cog' },
        [BtnTypes.GEAR_COG_BACKGROUND_THEMED]: { classes: "btn-round btn-themed", title: t('SETTINGS'), icon: 'las la-cog' },
        [BtnTypes.DOCUMENT]: { classes: "btn-round", title: t('ASSIGN_DOCUMENT_TEMPLATES'), icon: 'las la-briefcase' },
        [BtnTypes.LOCATION_ARROW]: { classes: "btn-round", title: t('VIEW_DETAIL'), icon: 'fas fa-location-arrow' },
        [BtnTypes.RULE]: { classes: "btn-round", title: t('ASSIGN_RULE'), icon: 'las la-clipboard-list' },
        [BtnTypes.MONEY]: { classes: "btn-round", title: t('SERVICE_FEE'), icon: 'las la-dollar-sign' },
        [BtnTypes.PLUS]: { classes: "btn-round", title: t('CREATE'), icon: 'las la-plus' },
        [BtnTypes.MINUS]: { classes: "btn-round btn-danger", title: t('DELETE'), icon: 'las la-minus' },
        [BtnTypes.LA_TIMES]: { classes: "btn-round btn-danger", title: t('CLOSE'), icon: 'las la-times' },
        [BtnTypes.IMAGE]: { classes: "btn-round btn-themed", title: t('IMAGE') },
        [BtnTypes.PIE_CHART]: { classes: "btn-round btn-themed", title: t('PIE_CHART'), icon: 'fas fa-chart-pie' },
        [BtnTypes.RIGHT]: { classes: "btn-round", title: t('NEXT'), icon: 'las la-arrow-right' },
        [BtnTypes.REMIND]: { classes: "btn-round btn-themed", title: t('REMIND'), icon: 'fas fa-bell' },

    };

    let btnStyles = {
        pointerEvents: disabled == true ? 'none' : 'initial'
    };

    let btnClasses = classnames(
        _buttons[type].classes,
        {
            'btn-sm': small,
        },
        {
            'btn-md': medium,
        },
        className
    );

    const [btnId, setbtnId] = useState(null);


    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(id)) {
            setbtnId(id);
            return;
        }
        setbtnId(uniqueId('round-btn-id-'));

    }, [id]);

    return (
        <div className={btnClasses} id={id ? id : btnId} onClick={onClick} style={{ ...btnStyles, ...style }}>
            {
                type === BtnTypes.IMAGE ?
                    render :
                    <i className={iconClass ? iconClass : _buttons[type].icon}></i>
            }
            {
                !stringIsNullOrEmpty(btnId) && <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="right" target={btnId}>{title ? title : _buttons[type].title}</UncontrolledTooltip>
            }
        </div>
    );
}

ButtonRound.defaultProps = {
    disabled: false,
    type: BtnTypes.ADD,
    small: false,
    medium: false,
    title: "",
    onClick: () => { return null },
}

ButtonRound.propTypes = {
    title: PropTypes.string || PropTypes.bool,
    type: PropTypes.string.isRequired
}

export default ButtonRound;