import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { WebUrl } from 'util/Constant.js';
import { PageSettings } from '../../config/page-settings.js';

/// <summary>
/// Author: CJ(Jiann)
/// </summary>

const ErrorNotFound = props => {
    let { urlPath, ...rest} = props;
    const _location = useLocation();
    const _history = useHistory();
    const _context = useContext(PageSettings);
    const { t } = useTranslation();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);
        return (() => {
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
        });
    }, []);

    return (<>
        <div className="login-bg"></div>
        <div className="d-flex align-items-center" style={{flexDirection: 'column'}}>
            <img src={require("../../assets/img/ui/graphic-error404.svg")} className="width-500" />
            <h1>{t('404_NOT_FOUND')}</h1>
            <p><b>{urlPath}</b> {t('IS_NOT_AVAILABLE')}</p>
            <button type="button" className="btn btn-min-width btn-themed" onClick={() => {_history.push(WebUrl._GET_STARTED)}}>{t('RETURN_DASHBOARD')}</button>
        </div>
    </>);
}

export default ErrorNotFound;
