import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { UncontrolledTooltip } from 'reactstrap';
import Notiflix from "notiflix";
import { InputTypes, BtnTypes, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc";
import { CommonDao, JurisdictionDao } from "data";
import TabPanel from 'components/panel/TabPanel';
import ButtonRound from "components/buttons/ButtonRound";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";

const ServiceListAssignDocument = (props) => {
    const { modal, setModal, jurisdictionId, productMatterId, productMatterName, setRefreshProdMatterTable } = props;

    const [currentTab, setCurrentTab] = useState(0);
    const { t } = useTranslation();
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);

    ///<summary>
    ///Author: Robin
    ///</summary>
    const getDocumentList = async () => {
        let dao = new CommonDao();
        await dao.getDocumentList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setDocumentList(data);
            }
        })
        await dao.getDocumentTypeList().then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                let temOpts = data.map(d => { return { label: d.name, value: d.id } });
                setDocumentTypeList(temOpts);
            }
        })
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const getAssignedDocumentList = async () => {
        let dao = new JurisdictionDao();
        await dao.getAssignedDocumentList(jurisdictionId, productMatterId).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY] ?? [];
                setSelectedDocuments(data);
            }
        });
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const saveChanges = async () => {
        let dao = new JurisdictionDao();
        await dao.assignDocumentList(jurisdictionId, productMatterId, selectedDocuments).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                Notiflix.Report.Success(t('notiflix:SUCCESS'), t('notiflix:CHANGES_APPLIED'));
                setRefreshProdMatterTable();
            }
        });
    }

    const toggle = () => {
        setModal((prevState) => !prevState);
        setDocumentList([]);
        setSelectedDocuments([]);
    };

    const _TABS = {
        ASSIGN_DOCUMENT: { id: 0, title: t("systemConfiguration:ASSIGN_DOCUMENT") },
        ASSIGNED_DOCUMENT_LIST: { id: 1, title: t("systemConfiguration:ASSIGNED_DOCUMENT_LIST") }
    };

    const _DOCUMENT_COLUMN = useMemo(() => [
        {
            Header: "",
            id: "cbSelectDocument",
            Cell: ({ row }) => {
                // Try to search if selectedDocumentList has the document
                var selectedItems = selectedDocuments.filter((o) => o?.id === row.original?.id);
                var selectedItem = selectedItems && selectedItems[0];

                return <div className="checkbox-doc">
                    <InputHoc name={"cbSelectDocument"} inputType={InputTypes.CHECKBOX} onChange={(e) => {
                        var checked = e.target.checked;
                        if (checked) {
                            setSelectedDocuments((prevList) => {
                                var newList = [...prevList];
                                if (row.original) {
                                    newList.push(row.original);
                                }
                                return newList;
                            });
                        } else {
                            setSelectedDocuments((prevList) => {
                                return prevList.filter((o) => o?.id !== row?.original?.id);
                            });
                        }
                    }}
                        checkboxInline={true}
                        options={[{ value: row.original?.id }]}
                        defaultValue={selectedItem?.id}
                    />
                </div>
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                return (row.original?.name);
            }
        },
        {
            Header: "systemConfiguration:TYPE",
            accessor: "type",
            Cell: ({ row }) => {
                return (row.original?.documentType?.name);
            }
        },
        {
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => {
                return (row.original?.description);
            }
        },
        {
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="btn-list d-flex">
                        {
                            row.original?.templateFile &&
                            <>
                                <a className="btn btn-brand-blue btn-flat-icon" target="_blank" id="download_btn"
                                    href={row.original?.templateFile?.fileMeta[0].requestPath}
                                    download={row.original?.templateFile?.fileMeta[0].filename}>
                                    <img src={require("../../../assets/img/icon/icon-download.svg")} alt="" />
                                </a>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_btn">{t('DOWNLOAD_TEMPLATE')}</UncontrolledTooltip>
                            </>
                        }
                    </div>
                </>);
            },
            disableSortBy: true,
            width: '10%'
        }
    ])

    const _SELECTED_DOCUMENT_COLUMN = useMemo(() => [
        {
            Header: "#",
            id: "row",
            Cell: ({ row }) => {
                return (row.index + 1)
            },
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                return (row.original?.name);
            }
        },
        {
            Header: "TYPE",
            accessor: "type",
            Cell: ({ row }) => {
                return (row.original?.documentType?.name);
            }
        },
        {
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="btn-list d-flex">
                        {
                            row.original?.templateFile &&
                            <>
                                <a className="btn btn-brand-blue btn-flat-icon" target="_blank" id="download_btn"
                                    href={row.original?.templateFile?.fileMeta[0].requestPath}
                                    download={row.original?.templateFile?.fileMeta[0].filename}>
                                    <img src={require("../../../assets/img/icon/icon-download.svg")} alt="" />
                                </a>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_btn">{t('DOWNLOAD_TEMPLATE')}</UncontrolledTooltip>
                            </>
                        }
                        <ButtonRound small className="btn-flat-icon" type={BtnTypes.DELETE} onClick={() => {
                            setSelectedDocuments((prevList) => {
                                return prevList.filter((o) => o?.id !== row?.original?.id);
                            });
                        }} id={"unassign_document-" + row.index} title={false}></ButtonRound>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target={"unassign_document-" + row.index}>{t('UNASSIGN')}</UncontrolledTooltip>
                    </div>
                </>);
            },
            disableSortBy: true,
            width: '10%'
        }
    ])

    ///<summary>
    ///Author: Robin
    ///</summary>
    useEffect(() => {
        if (modal) {
            getDocumentList();
            getAssignedDocumentList();
        }
    }, [modal]);

    return (
        <div toggle={toggle}>
            <div className="brand-tabs">
                {
                    Object.keys(_TABS).map((key) => {
                        return <div className={classNames({ 'active-tab': currentTab == _TABS[key].id })} onClick={() => setCurrentTab(_TABS[key].id)}>{_TABS[key].title}</div>
                    })
                }
            </div>
            <div className="table-brand-wrapper">
                <div className="table-header d-flex align-center justify-between" style={{ padding: '.8rem' }}>
                    <ButtonRound type={BtnTypes.ARROW_LEFT} className="btn-small-round" onClick={() => props.setModal(false)}></ButtonRound>
                    {
                        currentTab == _TABS.ASSIGN_DOCUMENT.id &&
                        <button type="button" style={{ padding: '0 1rem' }} className="btn btn-themed btn-min-width" onClick={() => {
                            Notiflix.Confirm.Show(t("notiflix:SAVE_CHANGES")+ "?", t("notiflix:SELECTED_DOCUMENT_WILL_BE_ASSIGNED"), t("YES"), t("NO"), () => {
                                saveChanges();
                            });
                        }}>{t("SAVE")}</button>
                    }
                </div>
                <TabPanel value={currentTab} index={_TABS.ASSIGN_DOCUMENT.id}>
                    <ReactTable
                        className="assignDocumentTable"
                        columns={_DOCUMENT_COLUMN}
                        data={documentList}
                        setPagination
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={_TABS.ASSIGNED_DOCUMENT_LIST.id}>
                    <ReactTable
                        className="selectedDocumentTable"
                        columns={_SELECTED_DOCUMENT_COLUMN}
                        data={selectedDocuments}
                    />
                </TabPanel>
            </div>
        </div>
    )
}

export default ServiceListAssignDocument;
