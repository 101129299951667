import React, { useMemo, useState, useEffect, useRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { ApiKey, ApiUrl, BtnTypes, FileType, FinanceType, LoadingStateText, InputTypes, DateFormat } from "util/Constant";
import SplitPane from "react-split-pane/lib/SplitPane";
import Pane from "react-split-pane/lib/Pane";
import { Document, Page } from 'react-pdf';
import Loader from 'react-loader-spinner';
import { isEmpty } from 'lodash';
import { Card, CardBody, CardHeader, Collapse, UncontrolledTooltip, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FinancesListing from "./FinancesListing";

/// <summary>
/// Author: Lewis
/// </summary>
const CompanyServiceDocuments = (props) => {
    let { requestService, setRequestService, ...rest } = props;
    const [openIndex, setOpenIndex] = useState(0);

    const [isViewing, setIsViewing] = useState(false);
    const [file, setFile] = useState({});
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onDocumentLoadSuccess = async ({ numPages }) => {
        setNumPages(numPages);
    }

    const setViewFile = async (filePathString) => {
        setFile(filePathString);
    }

    const _CARD_HEADER = [
        { id: 0, title: "Document", component: FinanceDocumentTable({ requestId: requestService.id, isViewing, setIsViewing, setViewFile }) },
        {
            id: 1, title: "Finance", component: <FinancesListing requestId={requestService.id} />
        }
    ]

    return (<>

        <SplitPane split="vertical" minSize={50} defaultSize="100vh" className="react-split-pane" >
            <Pane minSize={isViewing ? "50%" : "100%"} initialSize={isViewing ? "65" : "100%"}>
                {
                    _CARD_HEADER.map((item, index) => {
                        return (<>
                            <Card key={index}>
                                <CardHeader onClick={() => { setOpenIndex(index) }}>{requestService?.productMatter?.name + "'s " + item.title}</CardHeader>
                                <Collapse isOpen={openIndex == index ? true : false}>
                                    <CardBody>
                                        {item.component}
                                        <div className="btn btn-themed m-2" onClick={() => { setRequestService(null) }}>Go Back</div>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </>)
                    }
                    )
                }
            </Pane>
            <Pane className="overflow-auto" initialSize="35%" minSize="20%">
                <div className="w-100">
                    <div className="m-l-2 d-flex justify-content-between">
                        <div className="pdf-navigator__wrapper d-flex justify-content-center">
                            <button onClick={() => { setPageNumber(pageNumber - 1) }} disabled={pageNumber === 1} className="btn btn-default">{"<"}</button>
                            {pageNumber && (<p className="page-text">Page {pageNumber} of {numPages}</p>)}
                            <button onClick={() => { setPageNumber(pageNumber + 1) }} disabled={pageNumber === numPages} className="btn btn-default">{">"}</button>
                        </div>
                        <div className="btn btn-sm" onClick={() => { setIsViewing((prevState) => !prevState) }}><i class="las la-times"></i></div>
                    </div>
                    {file &&
                        <Document
                            loading={
                                <div className="d-flex">
                                    <Loader
                                        className="m-auto"
                                        type="TailSpin"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                    />
                                </div>}
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}>
                            <Page wrap={false} scale={0.8} pageNumber={pageNumber} />
                        </Document>
                    }
                </div>
            </Pane>
        </SplitPane>
    </>)
}

const FinanceDocumentTable = props => {
    let { requestId, isViewing, setIsViewing, setViewFile, ...rest } = props;

    const _SERVICE_DOCUMENT = useMemo(() => [
        {
            Header: "#",
            accessor: "index",
            Cell: ({ row }) => { return (<>{row.index + 1}</>) }
        },
        {
            Header: "Document Name",
            accessor: "documentName",
            Cell: ({ row }) => { return (<>{row.original.document?.name}</>) }
        },
        {
            Header: "Document Type",
            accessor: "documentType",
            Cell: ({ row }) => { return (<>{row.original.document.documentType?.name}</>) }
        },
        {
            Header: "Document Code",
            accessor: "documentCode",
            Cell: ({ row }) => { return (<>{row.original.document?.documentCode}</>) }
        },
        {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => {
                return (
                    <>
                        <div className="btn-sm btn-round expand-theme mr-1 mb-1" id="view_expense_receipt"
                            onClick={() => {
                                setIsViewing(true);
                                setViewFile(row.original?.document?.templateFile?.fileMeta[0].requestPath);
                            }}
                        >
                            <i class="las la-eye"></i>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="view_expense_receipt">View</UncontrolledTooltip>
                    </>
                )
            }
        }
    ], []);

    useEffect(() => {
        if (isEmpty(requestId)) {
            return;
        }
    }, [requestId]);
    return (
        <ReactTable
            columns={_SERVICE_DOCUMENT}
            url={ApiUrl._API_GET_COMPANY_SERVICE_REQUEST_DOCUMENT_LIST_BY_REQUEST_ID.replace(":requestId", requestId)}
            setPagination />
    )
}



export default CompanyServiceDocuments;