import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { WebUrl, RoleType } from 'util/Constant';
import { useAuthController } from 'hooks';
import { useRecoilValue } from 'recoil';
import { authCredentialState } from 'recoil/Atoms';
import { useTranslation } from 'react-i18next';
import { PageSettings } from 'config/page-settings';

/// <summary>
/// Author: Chris
/// </summary>
const DropdownProfile = (props) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const authCredential = useRecoilValue(authCredentialState);
	const [user, setUser] = useState();
	const _history = useHistory();
	const { t } = useTranslation(); 
	const { logout } = useAuthController({});
	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	}

	const {
		isXsDevices
	} = useContext(PageSettings);

	/// <summary>
	/// Author: Ong Sze Hua
	/// </summary>
	useEffect(() => {
		setUser(authCredential);
	}, [authCredential]);


	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li" id="navbar-user-dropdown-profile">
			<DropdownToggle tag="a">
				<div className="navbar-user-info">
					{
						isXsDevices ? 
							<img alt="" src={require("../../../assets/img/icon/icon-profile-black.svg")} />
						:
						<>
							<span>{user?.name}</span>
							<span className="font-weight-light">@{user?.email}</span>
							<span><i className="fas fa-angle-down"></i></span>
						</>
					}
				</div>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-profile" tag="ul">
				{/* <span className="dropdown-item" style={{ cursor: 'default', userSelect: 'none', backgroundColor: 'transparent', fontWeight: '200' }}><i>@{user?.name}</i></span> */}

				{
					isXsDevices &&
					<>
						<div className="navbar-user-info">
							<span>{user?.name}</span>
							<span className="font-weight-light">@{user?.email}</span>
						</div>
						<div className="dropdown-divider"></div>
					</>

				}
				<Link to={WebUrl._PROFILE_MANAGEMENT}><DropdownItem>{t("EDIT_PROFILE")}<img alt="" src={require("../../../assets/img/icon/arrow-right.svg")} /></DropdownItem></Link>

				{/* <div className="dropdown-divider"></div> */}

				{
					// authCredential?.role?.id == RoleType._ACCOUNTANT && authCredential?.id == authCredential?.mainAccountantId
					// 	?
					// 	<Link to={WebUrl._SUB_ACCOUNT_MANAGEMENT}><DropdownItem>Sub Accounts</DropdownItem></Link>
					// 	:
					// 	<></>
				}
				{/*<Link to={WebUrl._REFERRALS}><DropdownItem>Referrals</DropdownItem></Link>*/}
				<div className="dropdown-divider"></div>
				<a href="/" onClick={(e) => e.preventDefault() }>
					<DropdownItem onClick={async () => {
						await logout().then(() => {
							_history.push(WebUrl._LOGIN)
						});
					}}>{t("LOG_OUT")}<img alt="" src={require("../../../assets/img/icon/arrow-right.svg")} /></DropdownItem>
				</a>
			</DropdownMenu>
		</Dropdown >
	);
}

export default DropdownProfile;
