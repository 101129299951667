import moment from 'moment';
/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_DATA_COUNT_KEY = "totalCount";
    static _API_FIRST_ERROR_KEY = "firstError";
    static _API_ERROR_KEY = "errors";
    static _API_STATUS_KEY = "status"

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_APPLICATION_PDF = "application/pdf";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_FORM_DATA = "multipart/form-data";
    static _API_POST = "POST";
    static _API_PUT = "PUT";
    static _API_GET = "GET";
    static _API_DELETE = "DELETE";
    static _API_USE_DAO = "DAO";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
    static _API_CHECK_AUTHORIZATION = "/api/account/checkAuthorization";
    static _API_CHECK_USERNAME = "/api/account/checkUsernameAvailability";
    static _API_CHECK_PHONENUMBER = "/api/account/checkPhoneNumberAvailability";
    static _API_CHECK_EMAIL = "/api/account/checkEmailAvailability";
    static _API_CHECK_REFERRAL_CODE = "/api/account/checkReferralCodeExist";
    static _API_CHECK_PASSWORD_COMPLEXITY = "/api/account/checkPasswordComplexity";

    static _API_LOGIN = "/api/account/login";
    static _API_LOGOUT = "/api/account/logout";
    static _API_ACCOUNT_VALIDATION = "/api/account/validateLogin";
    static _API_EMAIL_OTP = "/api/account/request/loginOtp/email";
    static _API_SMS_OTP = "/api/account/request/loginOtp/sms";
    static _API_REQUEST_ACTIVATION_CODE = "/api/account/request/activationCode";
    static _API_VERIFY_ACTIVATION_CODE = "/api/account/verify/activationCode";
    static _API_ACCOUNT_REGISTRATION = "/api/account/register";
    static _API_ACCOUNT_REGISTRATION_THROUGH_ADMIN = "/api/account/registerThroughAdmin";
    static _API_GET_SERVICES_LIST = "/api/service/list";
    static _API_GET_EXPERTISES_LIST = "/api/expertise/list";
    static _API_GET_COUNTRRY_LIST = "/api/country/list";
    static _API_GET_COUNTRRY_BY_ID = "/api/country";
    static _API_GET_JURISDICTION_BY_ID = "/api/jurisdiction";
    static _API_GET_FACT_SHEET_LIST_BY_JURISDICTION = "/api/jurisdiction/:id/factSheet";
    static _API_GET_JURISDICTION_LIST = "/api/jurisdiction/list";
    static _API_CREATE_OR_UPDATE_JURISDICTION = "/api/jurisdiction/createOrUpdate";
    static _API_DELETE_JURISDICTION = "/api/jurisdiction/:id";
    static _API_UPDATE_JURISDICTION_AVAILABILITY = "/api/jurisdiction/:id/update/availability";
    static _API_GET_OFFICER_BY_ID = "/api/officer";
    static _API_CREATE_INDIVIDUAL_OFFICER = "/api/officer/individual/create";
    static _API_CREATE_CORPORATE_OFFICER = "/api/officer/corporate/create";
    static _API_GET_OFFICERS_LIST = "/api/officer/list";
    static _API_UPDATE_CORPORATE_OFFICER = "/api/officer/corporate/update";
    static _API_UPDATE_INDIVIDUAL_OFFICER = "/api/officer/individual/update";
    static _API_GET_ALL_ACCOUNTANT_COUNT = "/api/account/list/all";
    static _API_GET_PENDING_ACCOUNTANT_COUNT = "/api/account/list/pending"
    static _API_GET_USER_LIST = "/api/account/retrieveUsers";
    static _API_GET_USER_LIST_OPTION = "/api/account/user/list";
    static _API_PROFILE = "/admin/api/account/me";
    static _API_COMPLY_ADVANTAGE_CREATE_SEARCH = "/admin/api/complyadvantage/searches";
    static _API_GET_REFERRAL_USERS = "/api/account/referral/users";
    static _API_GENERATE_REFERRAL_CODE = "/api/account/referral/generate/code";
    static _API_GET_LANGUAGE_LIST = "/api/account/language/list";

    static _API_GET_CURRENCY_LIST = "/api/currency/list";
    static _API_GET_CURRENCY_CREATE_OR_EDIT = "/api/currency/createOrEdit";
    static _API_DELETE_CURRENCY = "/api/currency/:id";
    static _API_GET_CURRENCY_EXCHANGE_RATE_BY_CURRENCY_ID = "/api/currency/:currencyId/exchangeRate/list";

    static _API_GET_FACT_SHEET_LIST = "/api/factsheet/list";
    static _API_GET_FACT_SHEET_GROUP_LIST = "/api/factsheet/categories/list";
    static _API_GET_ITEM_LIST = "/api/factsheet/:factSheetId/item/list/:categoryId";
    static _API_CREATE_OR_UPDATE_GROUP = "/api/factsheet/categories/group";
    static _API_CREATE_OR_UPDATE_ITEM = "/api/factsheet/item";
    static _API_CREATE_OR_UPDATE_ITEM_BY_GROUP = "/api/factsheet/items";
    static _API_DELETE_GROUP = "/api/factsheet/group/:id";
    static _API_DELETE_ITEM = "/api/factsheet/item/:id";
    static _API_GET_FACT_SHEET_ITEM_GROUP_ICON_LIST = "/api/factsheet/icon/list";

    static _API_GET_COMPANY_PROCESS_STATE_LIST = "/api/company/processState/list";
    static _API_GET_COMPANY_NAME_CHECK_LIST = "/api/company/nameCheck/list";
    static _API_GET_COMPANY_REMARKS_LIST = "/api/company/nameCheck/:id/remarks/list";
    static _API_GET_COMPANY_DETAILS = "/api/company/:companyId";
    static _API_CREATE_COMPANY_NAME_CHECK = "/api/company/nameCheck";

    static _API_CREATE_CUSTOM_COMPANY_ADDRESS = "/api/company/:companyId/useCustomCompanyAddress";

    static _API_GET_FINANCIAL_YEAR = "/api/company/financialYear/:financialYearId";
    static _API_GET_COMPANY_FINANCIAL_YEARS = "/api/company/:companyId/financialYears";
    static _API_CLOSE_AND_TRANSFER_FINANCIAL_YEAR = "/api/company/financialYear/:financialYearId/closing";
    static _API_CREATE_OR_UPDATE_FINANCIAL_YEAR_QUESTIONNAIRE = "/api/company/financialYearQuestionnaire/createOrUpdate";
    static _API_UPDATE_ANNUAL_COMPLIANCE_DOCUMENT = "/api/company/annualCompliance/document/update";
    static _API_UPDATE_ANNUAL_COMPLIANCE_STATUS = "/api/company/annualCompliance/status";
    static _API_SAVE_ESA_QUESTIONNAIRE = "/api/company/annualCompliance/:id/esaQuestionnaire";
    static _API_GET_CLOSE_DEADLINE_COMPANY = "/api/company/retrieveCloseDeadlineCompanies";
    static _API_GET_RENEWALS_LIST = "/api/company/renewals";
    static _API_GET_UPCOMING_ANNUAL_COMPLIANCES_DEADLINE = "/api/company/annualCompliances/deadLine/:months";
    static _API_GET_CLOSE_DEADLINE_COMPANY_BY_FIRM_ID = "/api/company/retrieveCloseDeadlineCompanies/:firmId";
    static _API_GET_COMPANY_ANNUAL_COMPLIANCES = "/api/company/:companyId/annualCompliance/list";
    static _API_GET_COMPANY_ANNUAL_COMPLIANCE_DOCUMENTS = "/api/company/:companyId/annualCompliance/document/list";
    static _API_REQUEST_SIGNATURE_COMPANY_ANNUAL_COMPLIANCE_DOCUMENT = "/api/company/:companyId/annualComplianceDocument/:annualComplianceDocumentId/requestSignature";

    static _API_MANUAL_SENT_ANNUAL_COMPLIANCE_DEADLINE_EMAIL = "/api/company/deadline/annualCompliance/sentEmail/:companyId";
    static _API_MANUAL_SEND_REMIND_COMPLIANCE = "/api/company/:companyId/annualCompliance/:annualComplianceId/reminder";
    static _API_MANUAL_SEND_REMIND_PACKAGE = "/api/company/:companyId/annualPackage/:productMatterId/reminder";

    //Finance
    static _API_GET_COMPANY_FINANCES_LIST = "/api/company/:companyId/finance/list";
    static _API_CONFIRM_PAYMENT_RECEIPT_OR_INVOICE = "/api/company/paymentReceipt/:orderCartItemId";
    static _API_POST_FINANCE_INVOICE_DOCUMENT = "/api/company/:companyId/cartItemInvoice/createOrUpdate";
    static _API_GET_FINANCE_BY_REQUEST_ID = "/api/company/finance/:companyId";

    //Document
    static _API_GET_DOCUMENT_LIST = "/api/document/list";
    static _API_GET_DOCUMENT_TYPE_LIST = "/api/document/type/list";
    static _API_UPLOAD_NEW_COMPANY_DOCUMENT = "/api/document/upload";
    static _API_UPLOAD_SIGNED_COMPANY_DOCUMENT = "/api/company/:id/companyDocument/:companyDocumentId/uploadSigned";
    static _API_REQUEST_SIGNATURE_SERVICE_REQUEST_DOCUMENT = "/api/company/:companyId/serviceRequestDocument/:serviceRequestDocumentId/requestSignature";
    static _API_CREATE_DOCUMENT = "/api/document/create";
    static _API_CREATE_DOCUMENT_TYPE = "/api/document/type/create";
    static _API_REMOVE_DOCUMENT = "/api/document/:id/remove";
    static _API_REMOVE_DOCUMENT_TYPE = "/api/document/:id/type/remove";
    static _API_CREATE_OR_UPDATE_DOCUMENT_TEMPLATE = "/api/document/create/template";
    static _API_GET_COMPANY_SERVICE_REQUEST_DOCUMENT = "/api/company/:id/serviceRequest/documents";

    //Officer
    static _API_CREATE_COMPANY_INDIVIDUAL_OFFICER = "/api/company/:companyId/officer/new/individual";
    static _API_CREATE_COMPANY_CORPORATE_OFFICER = "/api/company/:companyId/officer/new/corporate";
    static _API_SEARCH_EXISTING_OFFICER = "/api/officer/search";
    static _API_ADD_EXISTING_OFFICER = "/api/company/:companyId/officer/existing";
    static _API_GET_COMPANY_OFFICER_BY_ID = "/api/company/:companyId/officer/:officerId";
    static _API_GET_OFFICER_COMPANY_SHARE_LIST_BY_ID = "/api/company/officer/:officerId/share/list";
    static _API_GET_OFFICER_COMPANY_DIRECTOR_LIST_BY_ID = "/api/company/officer/:officerId/director/list";
    static _API_GET_OFFICER_COMPANY_LIST_BY_ID = "/api/company/officer/:officerId/company/list";
    static _API_GET_CHECK_OFFICER_BINDED_TO_COMPANY = "/api/company/:companyId/officer/:officerId/isOfficerBinded";
    static _API_SEARCH_OFFICER_WITHOUT_SAME_PARENT = "/api/officer/search/:companyId";

    //OfficerSearch
    static _API_GET_OFFICER_SEARCH_BY_OFFICER_ID = "/api/officerSearch/officer/:officerId";
    static _API_GET_OFFICER_SEARCH_BY_SEARCH_ID = "/api/officerSearch/search/:searchId";
    static _API_CREATE_OFFICER_SEARCH = "/api/officerSearch/search/";
    static _API_CREATE_OFFICER_SEARCH_BY_COMPANY_ID = "/api/officerSearch/search/company/:companyId";
    static _API_RESOLVE_OFFICER_SEARCH_DETAIL = "/api/officerSearch/detail/:officerSearchDetailId/resolve";
    static _API_RE_SEARCH_OFFICER_SEARCH = "/api/officerSearch/searchAgain/officer/:officerId";

    //Secreatary
    static _API_CREATE_COMPANY_INDIVIDUAL_SECREATARY = "/api/company/:companyId/secretary/new/individual";
    static _API_CREATE_COMPANY_CORPORATE_SECREATARY = "/api/company/:companyId/secretary/new/corporate"
    static _API_CREATE_EXISTING_COMPANY_SECRETARY = "/api/company/:companyId/secretary/existing";
    static _API_REMOVE_COMPANY_OFFICER = "/api/company/:companyId/officer/remove/:officerId";
    static _API_ASSIGN_COMPANY_OFFICER_ROLE = "/api/company/:companyId/officer/:officerId/roleAssignment";

    static _API_UPDATE_APPROVE_AVAILABILITY = "/api/company/availability/approve/:id";
    static _API_UPDATE_REJECT_AVAILABILITY = "/api/company/availability/reject/:id";
    static _API_UPDATE_APPROVE_COMPANY_NAME_CHECK = "/api/company/approveNameCheck/:id";
    static _API_UPDATE_REJECT_COMPANY_NAME_CHECK = "/api/company/rejectNameCheck/:id ";
    static _API_UPDATE_NAME_CHECK_FOLLOW_UP_DATE_AND_REMARK = "/api/company/nameCheck/:id/updateFollowUp";

    //Standard Share & Custom Share
    static _API_GET_COMPANY_DETAIL_BY_ID = "/api/company/:id";
    static _API_GET_COMPANY_ON_GOING_MATTERS_LIST_BY_COMPANY_ID = "/api/company/onGoingMatters/:companyId";
    static _API_GET_STANDARD_SHARE_CAPITAL_PACKAGE_LIST_BY_ID = "/api/company/standardShareCapital/:jurisdictionId/list";
    static _API_GET_CUSTOM_SHARE_CAPITAL_PACKAGE_LIST = "/api/company/:id/retrieveCustomShareCapitals";
    static _API_GET_CLASS_OF_SHARE_LIST = "/api/company/classOfShare/list";
    static _API_USE_COMPANY_STANDARD_CAPITAL_PACKAGE = "/api/company/useStandardShareCapitalPackage";
    static _API_CREATE_OR_UPDATE_COMPANY_CUSTOM_CAPITAL_PACKAGE = "/api/company/useCustomShareCapitalPackage";
    static _API_REMOVE_CUSTOM_COMPANY_SHARE_CAPITAL = "/api/company/:companyId/customShareCapital/:shareId";
    static _API_GET_COMPANY_OFFICERS_REMAINING_SHARES = "/api/company/:companyId/remainingOfficerShares";
    static _API_CHECK_SHARE_HELD = "/api/company/:companyId/officer/:officerId/companyShareCapitalId/:companyShareCapitalId/checkShareHeld";
    static _API_CREATE_SUBMIT_COMPANY_TRANSFER_IN = "/api/company/submitCompanyTransferIn";
    static _API_SUBMIT_COMPANY_INCORPORATION_REQUEST = "/api/company/:id/submitIncorporationRequest";
    static _API_SUBMIT_NEW_SERVICE_REQUEST = "/api/company/:companyId/productMatter/:productMatterId/submitServiceRequest";
    static _API_GET_COMPANY_SERVICE_REQUEST_LIST_BY_COMPANY_ID = "/api/company/:companyId/serviceRequest/list";
    static _API_GET_COMPANY_SERVICE_REQUEST_DOCUMENT_LIST_BY_REQUEST_ID = "/api/company/serviceRequestDocument/:requestId";

    //Incorporation
    static _API_GET_INCORPORATION_PACKAGE = "/api/company/incorporationAnnualPackage/:jurisdictionId/list";
    static _API_USE_INCORPORATION_PACKAGE = "/api/company/:companyId/useIncorporationAnnualPackage";
    static _API_USE_PROVIDE_OWN_INCOPRORATION_PACKAGE = "/api/company/:companyId/useProvideOwnIncorporationPackage"
    static _API_GET_SHARES_CAPITAL_LIST = "/api/company/:companyId/shareCapital/list";
    static _API_GET_COMPANY_OFFICER_LIST = "/api/company/:companyId/officer/list";
    static _API_UPDATE_COMPANY_OFFICER_INDIVIDUAL = "/api/company/:companyId/officer/update/individual";
    static _API_UPDATE_COMPANY_OFFICER_CORPORATE = "/api/company/:companyId/officer/update/corporate";
    static _API_COMPANY_GO_LIVE = "/api/company/goLive/:companyId";
    static _API_COMPANY_REJECT_INCORPORATION_REQUEST = "/api/company/:companyId/rejectIncorporationRequest";
    static _API_GET_COMPANY_DOCUMENT_LIST = "/api/company/:companyId/company-document/list";
    static _API_ADOPT_COMPANY_DOCUMENT = "/api/company/company-document/:documentId/approve";
    static _API_REJECT_COMPANY_DOCUMENT = "/api/company/company-document/:documentId/reject";
    static _API_GET_NAMECHECK_LIST = "/api/company/nameCheck/list";
    static _API_GET_COMPANY_OFFICER_LIST_BY_ROLE = "/api/company/:companyId/companyOfficer/list";
    static _API_DELETE_OFFICER_SHARE = "/api/company/:companyId/shareCapital/:companyShareCapitalId/officerShare/:officerId";
    static _API_ADD_OFFICER_SHARE = "/api/company/:companyId/officerShare/:officerId/add";
    static _API_POST_UB_TOTAL_SHARE_REMAINING = "/api/company/ubHierarchyShare/sibling/remaining";
    static _APT_POST_CALC_UB_SHARES = "/api/company/ubHierarchyShares/dependant/calc";
    static _APT_POST_CALC_SAME_HIERARCHY_UB_SHARES = "/api/company/ubHierarchyShares/sameLevel/calc";
    static _API_GET_UB_INFO = "/api/company/:companyId/ub/:beneficiaryId/info";
    static _API_UPDATE_UB_SHARES = "/api/company/:companyId/ub/:beneficiaryId/updateShares";
    static _API_UPDATE_UB_DIRECTORS = "/api/company/:companyId/ub/:beneficiaryId/updateDirectors";
    static _API_GET_UB_DESCENDANT_NODES = "/api/company/:companyId/ubHierarchy/:beneficiaryId/descendant/list";
    static _API_GET_UB_CHILDREN_NODES = "/api/company/:companyId/ubHierarchy/:beneficiaryId/children/list";
    static _API_GET_UB_SIBLING_NODES = "/api/company/:companyId/ubHierarchy/:beneficiaryId/siblingNode/list";
    static _API_UPDATE_UB_NODE = "/api/company/:companyId/ubHierarchy/updateNode";

    // Service Request
    static _API_GET_SERVICES_ONGOING_LIST = "/api/serviceRequest/company/:companyId/servicesOngoing/list";
    static _API_GET_COMPANY_NAME_REQUEST_CHANGES = "/api/serviceRequest/company/:companyId/productMatter/:productMatterId/retrieveOnGoingChanges";
    static _API_GET_REMARK_LIST = "/api/serviceRequest/:serviceRequestId/remarks";
    static _API_SUBMIT_REJECT_REQUEST = "/api/serviceRequest/company/:companyId/productMatter/:productMatterId/rejected";
    static _API_CHECK_HAS_TASK_REMAINING = "/api/serviceRequest/:serviceRequestId/hasTaskRemaining";
    static _API_MARK_SERVICE_REQUEST_COMPLETE = "/api/company/:companyId/serviceRequest/:serviceRequestId/markAsComplete";

    // 1. Change Of Company Name Service
    static _API_SUBMIT_COMPANY_NAME_SERVICE_REQUEST = "/api/changeOfCompanyName/submitRequest";
    static _API_APPLY_COMPANY_NAME_REQUEST_COMPLETE = "/api/changeOfCompanyName/:serviceRequestId/applyCompanyNameChanges";
    static _API_ADMIN_GET_COMPANY_NAME_REQUEST_CHANGES = "/api/changeOfCompanyName/:serviceRequestId/retrieveOngoingChanges";
    static _API_SERVICE_REQUEST_CHANGE_OF_COMPANY_NAME_UPDATE = "/api/changeOfCompanyName/:serviceRequestId/updateEntries";
    static _API_APPROVE_COMPANY_NAME_CHANGES = "/api/companyUpdateService/:serviceRequestId/applyCompanyNameChanges";

    // 2. Add Or Remove Director Service
    static _API_ADMIN_RETRIEVE_DIRECTOR_MANAGEMENT_ON_GOING_CHANGES = "/api/directorManagement/:serviceRequestId/retrieveOngoingChanges";
    static _API_APPLY_DIRECTOR_MANAGEMENT_CHANGES = "/api/directorManagement/:serviceRequestId/applyChanges";
    static _API_SUBMIT_ADD_REMOVE_DIRECTOR_REQUEST = "/api/directorManagement/submitRequest";
    static _API_SERVICE_REQUEST_ADD_REMOVE_DIRECTOR_UPDATE = "/api/directorManagement/:serviceRequestId/updateEntries";

    // 3. Increase share capital
    static _API_SUBMIT_INCREASE_SHARE_CAPITAL_REQUEST = "/api/increaseShareCapital/submitRequest";

    // 8. Allot Shares
    static _API_SUBMIT_ALLOT_SHARES_REQUEST = "/api/allotShares/submitRequest";
    static _API_APPLY_ALLOT_SHARES_REQUEST_COMPLETE = "/api/allotShares/:serviceRequestId/applyTransferShare";
    static _API_SERVICE_REQUEST_ALLOT_SHARES_UPDATE = "/api/allotShares/:serviceRequestId/updateEntries";

    // 9. Transfer of Shares Service
    static _API_SEARCH_EXISTING_SHAREHOLDER = "/api/transferShares/:companyId/searchExistingShareholder";
    static _API_SUBMIT_TRANSFER_SHARE_SERVICE_REQUEST = "/api/transferShares/submitRequest";
    static _API_APPLY_TRANSFER_SHARE_REQUEST_COMPLETE = "/api/transferShares/:serviceRequestId/applyTransferShare";
    static _API_SERVICE_REQUEST_TRANSFER_SHARES_SERVICE_UPDATE = "/api/transferShares/:serviceRequestId/updateEntries";

    //Product
    static _API_GET_REFERENCE_PRODUCT_LIST = "/api/product/referenceProduct/list";
    static _API_GET_PRODUCT_TYPE_LIST = "/api/product/productType/list";
    static _API_GET_PRODUCT_TYPE = "/api/product/productType/:id";
    static _API_GET_PRODUCT_LIST = "/api/product/jurisdictionProduct/list";
    static _API_GET_PRODUCT = "/api/product/:id";
    static _API_DELETE_PRODUCT = "/api/product/:id";
    static _API_GET_INCORPORATION_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/incorporation/list";
    static _API_CREATE_OR_UPDATE_INCORPORATION_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/incorporation/:productMatterId/createOrUpdate";
    static _API_GET_ANNUAL_PACKAGE_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/annualPackage/list";
    static _API_CREATE_OR_UPDATE_ANNUAL_PACKAGE_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/annualPackage/:productMatterId/createOrUpdate";
    static _API_GET_SERVICES_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/services/:productMatterId/list";
    static _API_CREATE_OR_UPDATE_SERVICES_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/services/:productMatterId/createOrUpdate";
    static _API_GET_MISCELLANEOUS_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/miscellaneous/:productMatterId/list";
    static _API_CREATE_OR_UPDATE_MISCELLANEOUS_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/miscellaneous/:productMatterId/createOrUpdate";
    static _API_GET_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/:productMatterId/list";
    static _API_CREATE_OR_UPDATE_PRODUCT_LIST_BY_JURISDICTION = "/api/product/jurisdiction/:jurisdictionId/:productMatterId/createOrUpdate";
    static _API_CREATE_OR_UPDATE_INCOME_PRODUCT = "/api/product/income";
    static _API_CREATE_OR_UPDATE_EXPENSE_PRODUCT = "/api/product/expense";
    static _API_GET_PRODUCT_MATTER_SERVICE_LIST_BY_JURISDICTION_MATTER_TYPE = "/api/productMatter/matter/:matterTypeId/jurisdiction/:jurisdictionId/list";
    static _API_CREATE_PRODUCT_MATTER = "/api/productMatter/create";
    static _API_UPDATE_PRODUCT_MATTER = "/api/productMatter/update";
    static _API_GET_CURRENT_ANNUAL_PACKAGE_PRODUCT_MATTER_BY_COMPANY_ID = "/api/company/:companyId/productmatter/annual-package/current";
    static _API_GET_ANNUAL_PACKAGE_PRODUCT_MATTER_LIST_BY_COMPANY_ID = "/api/company/:companyId/productmatter/annual-package/list";
    static _API_GET_SERVICE_PRODUCT_MATTER_LIST_BY_COMPANY_ID = "/api/company/:companyId/productmatter/service/list";
    static _API_GET_MISC_PRODUCT_MATTER_LIST_BY_COMPANY_ID = "/api/company/:companyId/productmatter/misc/list";
    static _API_CREATE_CART = "/api/account/add/:companyId/addCart/:productMatterId";
    static _API_GET_PRODUCTION_BENEFICIARY_OFFICERS = "/api/company/:companyId/beneficiaryOfficer/list";
    static _API_GET_PRODUCTS = "/api/product/list";
    static _API_GET_JURISDICTION_PRODUCT_MATTER_PRODUCTS = "/api/product/jurisdiction/:jurisdictionId/productMatter/:productMatterId/list";
    static _API_GET_PRODUCT_BY_CODE = "/api/product/productCode/:productCode";

    static _API_GET_USER = "/api/account/getUser";
    static _API_UPDATE_ACCOUNTANT_PROFILE = "/api/account/user/:userId/accountant";
    static _API_CHANGE_PASSWORD = "/api/account/changePassword";
    static _API_UPDATE_ADMIN_PROFILE = "/api/account/user/:userId/admin";
    static _API_GET_USER_ACTIVITY_LOGS = "/api/account/activity/logs";

    static _API_GET_ACCOUNTANT_LIST = "/api/account/list"; // all acc's list      
    static _API_GET_PENDING_APPROVAL_ACCOUNTANT_LIST = "/api/account/pendingApprovalList"; // pending-approval-list
    static _API_GET_ACCOUNTANT_DETAILS = "/api/account/:id"; // 1 acc details
    static _API_UPDATE_ACCOUNTANT_APPROVAL_DETAILS = "/api/account/update/status/:id/:decision"; // approve-or-reject
    static _API_UPDATE_ACCOUNTANT_ACTIVE_STATUS = "/api/account/update/active/:id/:decision"; // enable-or-disable (singular)
    static _API_UPDATE_ACCOUNTANT_ACTIVE_STATUS_LIST = "/api/account/update/active/list/:id/:decision"; // enable-or-disable (multi)
    static _API_UPDATE_ACCOUNTANT_EMAIL = "/api/account/user/:userId/email";

    static _API_GET_LIST_OF_COMPANY_NAME_CHECK = "/api/company/nameCheckReview/list";
    static _API_GET_COMPANY_STATUS_LIST = "/api/company/status/list";
    static _API_RETRIEVE_COMPANIES = "/api/company/retrieveCompany/list"; // retrieve after namecheck company list
    static _API_RETRIEVE_COMPANIES_FOR_ADMIN = "/api/company/admin/retrieveCompany/list";
    static _API_RETRIEVE_COMPANY_SERVICE_REQUEST = "/api/company/production/list"; // retrieve after namecheck company list
    static _API_RETRIEVE_COMPANY_ON_GOING_MATTER_BY_OWNER_ASSIGNEE = "/api/company/production/list/ownerassignee";

    static _API_GET_DEPARTMENT_LIST = "/api/department/list";
    static _API_GET_ADMIN_LIST = "/api/account/admin/list";
    static _API_CREATE_OR_UPDATE_ADMIN = "/api/account/admin";
    static _API_UPDATE_ADMIN = "/api/account/admin/:adminId";
    static _API_CREATE_OR_UPDATE_DEPARTMENT = "/api/department/createOrUpdate";
    static _API_REMOVE_DEPARTMENT = "/api/department/remove/:id";

    static _API_GET_REFERRAL_POINT_HISTORY_LIST = "/api/referralPoint/list";
    static _API_GET_REFERRAL_PACKAGE_TYPE = "/admin/api/referralpackage/type";
    static _API_GET_REFERRAL_PACKAGE_LIST = "/admin/api/referralpackage/list";
    static _API_GET_REFERRAL_PACKAGE_ICON_LIST = "/admin/api/referralpackage/badge/icon/list";
    static _API_CREATE_OR_UPDATE_REFERRAL_PACKAGE = "/admin/api/referralpackage/createOrUpdate";
    static _API_GET_REFERRAL_PACKAGE_DETAILS = "/admin/api/referralpackage/details/:id";
    static _API_DELETE_REFERRAL_PACKAGE = "/admin/api/referralpackage/delete/:id";
    static _API_GET_BENEFICIARY_TREE = "/api/company/:id/beneficiaryTree";
    static _API_ADD_BENEFICIARY = "/api/company/:id/addDescendantBeneficiary";
    static _API_REMOVE_BENEFICIARY = "/api/company/:id/removeBeneficiary/:nodeId";
    static _API_UPDATE_BENEFICIARY_UBO = "/api/company/:id/updateBeneficiaryUbo/:nodeId";

    static _API_ADD_SUBACCOUNTANT = "/api/account/subAccManagement/add"; // add subAccountant
    static _API_GET_SUBACCOUNTANT_LIST = "/api/account/SubAccList"; // get SubAccountant's List
    static _API_GET_SUBACCOUNTANT_PROFILE = "/api/account/SubAcc/:id"; // get 1 SubAcc details
    static _API_UPDATE_SUBACCOUNTANT_PROFILE = "/api/account/SubAccManagement/update/:id"; // update 1 SubAcc details
    static _API_DELETE_SUBACCOUNTANT = "/api/account/SubAccManagement/delete/:id"; // delete subAccountant
    //static _API_RESET_PASSWORD = "/api/account/SubAccManagement/resetPassword/:id"; // reset password
    static _API_CHECK_RESET_OTP_AVAILABILITY = "/api/account/resetOtp/availability/:otp";
    static _API_SEND_RESET_PASSWORD_EMAIL = "/api/account/forgot/password";
    static _API_RESET_PASSWORD = "/api/account/reset/password";

    static _API_CREATE_OR_UPDATE_ACCOUNTANT_CART = "/api/account/cart/:id";
    static _API_GET_ACCOUNTANT_CART = "/api/account/cart/list";
    static _API_UPDATE_CART_ITEM = "/api/account/cart/updatecartitem/:cartId/:productId";
    static _API_DELETE_CART_ITEM = "/api/account/cart/delete/:id";

    static _API_SAVE_KYC_QUESTIONS = "/api/company/:id/kycQuestion";
    static _API_DELETE_KYC_QUESTIONS = "/api/company/:id/kycQuestion/delete";

    // Jurisdiction
    static _API_GET_STANDARD_SHARE_CAPITAL_PACKAGE = "/api/jurisdiction/:id/standardShareCapitalPackage";
    static _API_STANDARD_SHARE_CAPITAL_PACKAGE = "/api/jurisdiction/:id/standardShareCapitalPackage";

    static _API_GET_JURISDICTION_ANNUAL_COMPLIANCE_LIST = "/api/jurisdiction/:id/annualCompliance/list";
    static _API_CREATE_OR_UPDATE_JURISDICTION_ANNUAL_COMPLIANCE = "/api/jurisdiction/annualCompliance/createOrUpdate";
    static _API_DELETE_JURISDICTION_ANNUAL_COMPLIANCE = "/api/jurisdiction/annualCompliance/:id";

    static _API_GET_ALL_JURISDICTION_ASSIGNED_DOCUMENT_LIST = "/api/jurisdiction/:jurisdictionId/documents";
    static _API_GET_ASSIGN_DOCUMENT_LIST = "/api/jurisdiction/:jurisdictionId/productMatter/:productMatterId/documents";
    static _API_ASSIGN_DOCUMENT_LIST = "/api/jurisdiction/:jurisdictionId/productMatter/:productMatterId/documents";
    static _API_GET_ANNUAL_COMPLIANCE_RULE_LIST = "/api/jurisdiction/:jurisdictionId/annualComplianceRule/list";
    static _API_GET_ANNUAL_COMPLIANCE_SYSTEM_RULE_LIST = "/api/jurisdiction/:jurisdictionId/annualComplianceRule/system/list";
    static _API_GET_ANNUAL_COMPLIANCE_CUSTOM_RULE_LIST = "/api/jurisdiction/:jurisdictionId/annualComplianceRule/custom/list";
    static _API_SAVE_ANNUAL_COMPLIANCE_CUSTOM_RULE = "/api/jurisdiction/:jurisdictionId/productMatter/:productMatterId/annualComplianceRule/custom";
    static _API_GET_ASSIGNED_ANNUAL_COMPLIANCE_RULE = "/api/jurisdiction/:jurisdictionId/productMatter/:productMatterId/annualComplianceRule";
    static _API_ASSIGN_ANNUAL_COMPLIANCE_RULE = "/api/jurisdiction/:jurisdictionId/productMatter/:productMatterId/annualComplianceRule";

    static _API_GOVERNMENT_DEPARTMENT = "/api/jurisdiction/:jurisdictionId/governmentDepartments";
    static _API_GOVERNMENT_DEPARTMENT_BY_ID = "/api/jurisdiction/:jurisdictionId/governmentDepartments/:governmentDepartmentId";
    static _API_GOVERNMENT_DEPARTMENT_LETTER_TYPES = "/api/jurisdiction/:jurisdictionId/governmentDepartments/:governmentDepartmentId/letterTypes";
    static _API_GOVERNMENT_DEPARTMENT_LETTER_TYPES_BY_ID = "/api/jurisdiction/:jurisdictionId/governmentDepartments/:governmentDepartmentId/letterTypes/:letterTypeId";

    // Payment
    static _API_GET_PAYMENT_METHOD = "/api/payment/type";
    static _API_CREATE_ORDER = "/api/payment/create/order";
    static _API_REVERT_ORDER = "/api/payment/revert/order/:orderId";
    static _API_MAKE_PAYMENT = "/api/payment/order/:orderId/:paymentMethod";
    static _API_PROCESS_PAYMENT = "/api/payment/process";
    static _API_GET_PAYMENT_SUMMARY = "/api/payment/summary/:paymentId";
    static _API_GET_PAYMENT_HISTORY_LIST = "/api/payment/history/list";
    static _API_UPDATE_PROPOSED_FYE = "/api/company/:id/updateProposedFyeMonth";
    static _API_GET_PAYMENT_HISTORY_LIST_BY_DATE = "/api/payment/history/:startDate/:endDate/list";
    static _API_PROCESS_ALIPAY_PAYMENT = "/api/payment/processPaymentWithAlipay";
    static _API_HANDLE_ALIPAY_CALLBACK = "/api/payment/handleAlipayCallback";
    static _API_PROCESS_CARD_PAYMENT = "/api/payment/processPaymentWithCard";
    static _API_HANDLE_CARD_PAYMENT_CALLBACK = "/api/payment/handleCardPaymentCallback";
    static _API_PROCESS_WECHAT_PAYMENT = "/api/payment/processPaymentWithWechat";
    static _API_PROCESS_WECHATPAY_SOURCE = "/api/payment/processWechatPaySource/:sourceId";
    static _API_PROCESS_WECHATPAY_CHARGE = "/api/payment/processWechatPayCharge/:chargeId";

    //pdf
    static _API_DOWNLOAD_PAYMENT_RECEIPT = "/api/pdf/:paymentId/receipt";

    //Excel
    static _API_COMPANY_MANAGEMENT_EXPORT_EXCEL = "/api/excel/company-management/generate";
    static _API_INDIVIDUAL_MANAGEMENT_EXPORT_EXCEL = "/api/excel/individual-management/generate";

    static _API_GET_NOTIFICATIONS = "/api/notification/list";
    static _API_READ_NOTIFICATIONS = "/api/notification/read";
    static _API_TOTAL_UNREAD_NOTIFICATIONS = "/api/notification/totalUnread";
    static _API_DELETE_NOTIFICATIONS = "/api/notification/delete";
    static _API_DELETE_NOTIFICATION = "/api/notification/single/:id/delete";

    // Module Policies Permission
    static _API_CREATE_OR_UPDATE_MODULE_POLICY = "/api/policy/createOrUpdatePolicy";
    static _API_GET_MODULE_LIST = "/api/policy/module/list";
    static _API_GET_POLICIES_LIST = "/api/policy/list";
    static _API_DELETE_POLICY = "/api/policy/deletePolicy/:policyId";
    static _API_ADD_USER_TO_POLICY = "/api/policy/addUser/:policyId/user/:userId";
    static _API_REMOVE_USER_FROM_POLICY = "/api/policy/deleteUser/:policyId/user/:userId";
    static _API_GET_POLICY_BY_ID = "/api/policy/:policyId";
    static _API_GET_USERS_BY_POLICY_ID = "/api/policy/:policyId/userList";

    //Business Intelligence
    static _API_GET_BUSINESS_INTELLIGENCE_CHART = "/api/businessIntelligence/list";
    static _API_GET_BUSINESS_INTELLIGENCE_CHART_BY_FIRM_ID = "/api/businessIntelligence/:firmId/list";

    static _API_GET_WORKFLOWS = "/api/workflow/list";
    static _API_GET_WORKFLOWS_BY_JURISDICTION = "/api/workflow/:jurisdictionId/list";
    static _API_CREATE_WORKFLOW = "/api/workflow/create";
    static _API_GET_WORKFLOW_BY_JURISDICTION_PRODUCT_MATTER = "/api/workflow/:jurisdictionId/:productMatterId";
    static _API_UPDATE_JURISDICTION_PRODUCT_MATTER_MAPPING = "/api/workflow/update/:jurisdictionId/:productMatterId";
    static _API_UPDATE_WORKFLOW = "/api/workflow/update";
    static _API_DELETE_WORKFLOW = "/api/workflow/delete/:id";

    // Task
    static _API_GET_TASK_DETAIL = "/api/company/task/:id";
    static _API_GET_TASK_REQUIRED_DOCUMENTS = "/api/company/task/:id/requiredDocument/list";
    static _API_GET_TEMPLATE_FIELD_NAMES = "/api/company/document/:documentId/getTemplateFieldNames";
    static _API_GET_COMPANY_VISIBLE_FIELD_NAMES = "/api/company/getVisibleFieldNames";
    static _API_GENERATE_COMPANY_DOCUMENT = "/api/company/:companyId/document/:documentId/generateCompanyDocument";
    static _API_GET_TASK_OWNER_LIST = "/api/account/owner/list/:departmentId/:jurisdictionId";
    static _API_GET_TASK_LIST_BY_ONGOING_MATTER_ID = "/api/company/task/:matterId/list";
    static _API_UPDATE_TASK_DETAIL = "/api/company/task/update";
    static _API_ADD_WORKFLOW_TASK_FOLLOWUP_REMARK = "/api/company/task/followUp/add";
    static _API_ON_CHANGE_WORKFLOW_TASK_OWNER_ASSIGNEE = "/api/company/workflow/task/owner/assign";

    // CRM
    static _API_GET_URGENCY_LIST = "/api/crm/urgency/list";
    static _API_GET_ENQUIRY_NATURE_OPTIONS = "/api/crm/enquiryNature/options";
    static _API_GET_CUSTOMER_RELATION_LIST = "/api/crm/list";
    static _API_GET_ADMIN_CUSTOMER_RELATION_LIST = "/api/crm/enquiry/admin";
    static _API_GET_CUSTOMER_RELATION_BY_ID = "/api/crm/:id";
    static _API_CREATE_CUSTOMER_RELATION = "/api/crm/create";
    static _API_CREATE_CUSTOMER_RELATION_FOLLOW_UP = "/api/crm/:customerRelationId/addFollowUps";
    static _API_RESOLVE_CUSTOMER_RELATION_ENQUIRY = "/api/crm/resolve/:id";

    // Hub
    static _API_CHAT = "/api/chat";
    static _API_RETRIEVE_PENDING_CHAT_REQUEST_LIST = "/api/chat/pendingRequest/list";
    static _API_RETRIEVE_MESSAGE_LIST = "/api/chat/:chatRoomId/message/list";

    // Accountant attachment
    static _API_GET_HISTORY_ATTACHMENTS_LIST = "/api/account/:userId/attachmentSubmissionHistory";
    static _API_UPDATE_CERTIFICATE_ATTACHMENT = "/api/account/:userId/attachments/:attachmentId/certificate";
    static _API_UPDATE_IDENTIFICATION_ATTACHMENT = "/api/account/:userId/attachments/:attachmentId/identification";
    static _API_ADMIN_APPROVE_ATTACHMENT = "/api/account/:userId/registrantAttachment/approve";
    static _API_ADMIN_REJECT_ATTACHMENT = "/api/account/:userId/registrantAttachment/reject";

    // Finances List
    static _API_GET_FINANCES_LIST = "/api/company/finances";

    // Role
    static _API_GET_ROLE_LIST = "/api/role/list";
    static _API_GET_ROLE_POLICIES_LIST = "/api/role/:roleId/policies";
    static _API_CREATE_OR_UPDATE_ROLE = "/api/role";
    static _API_GET_ACTIONS = "/api/role/policy/actions";
    static _API_ASSIGN_ACTIONS_TO_ROLE_OBJECT = "/api/role/policy/assignActionsToRoleObject";

    // Letter
    static _API_COMPANY_ANNUAL_COMPLIANCE_LETTERS = "/api/company/:companyId/annualCompliances/letters";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _LOGIN = "/login";
    static _DASHBOARD = "/dashboard";
    static _GET_STARTED = "/get-started";
    static _FORGOT_PASSWORD = "/forgot-password";

    static _ERROR_NOT_FOUND = "/error-not-found";

    // configuration
    static _SERVICE_FEES_MANAGEMENT = "/configuration/service-fee";
    static _JURISDICTION_MANAGEMENT = "/configuration/jurisdiction-management";
    static _JURISDICTION_WIZARD = "/configuration/jurisdiction-management/:jurisdictionId/wizard";
    static _CURRENCY_MANAGEMENT = "/configuration/currency";
    static _CURRENCY_RATIO_CHART = "/configuration/currency/ratio-chart";
    static _FACT_SHEET_CATEGORIES = "/configuration/fact-sheet/categories";
    static _ADMIN_FACT_SHEET = "/configuration/admin-fact-sheet";
    static _DOCUMENT_WIZARD = "/configuration/document";
    static _ROLE_MANAGEMENT = "/configuration/role-management";

    // company management
    static _COMPANY_MANAGEMENT = "/company-management";
    static _COMPANY_MANAGEMENT_DOCUMENT = "/company-management/portfolio/:companyId/documents";
    static _COMPANY_MANAGEMENT_SERVICES = "/company-management/portfolio/:companyId/services";
    static _COMPANY_MANAGEMENT_FINANCES = "/company-management/portfolio/:companyId/finances";
    static _COMPANY_MANAGEMENT_KYC_AML = "/company-management/portfolio/:companyId/kyc-aml";
    static _COMPANY_MANAGEMENT_ANN_COMPLIANCES = "/company-management/portfolio/:companyId/annual-compliance";
    static _COMPANY_MANAGEMENT_OFFICER_PROFILE = "/company-management/portfolio/:companyId/officer-profile";
    static _COMPANY_MANAGEMENT_LETTERS = "/company-management/portfolio/:companyId/letters";

    // individual management 
    static _INDIVIDUAL_MANAGEMENT_ADD_PERSON = "/individual-management/add";

    // incorporation
    static _ADD_NEW_COMPANY = "/company/add";
    static _COMPANY_MANAGEMENT_PORTFOLIO = "/company-management/portfolio/:companyId";
    static _COMPANY_MANAGEMENT_EDIT_INCORPORATION = "/company-management/incorporation/edit";

    // production
    static _PRODUCTION_LIST = "/production/production-list";

    // renewal listing
    static _RENEWAL_LIST = "/renewal/listing";

    // users management
    static _USERS_MANAGEMENT = "/users/user-management";
    static _PROFILE_MANAGEMENT = "/profile/management";
    static _USERS_NOTIFICATIONS = "/users/notification";
    static _ACCOUNTANT_DETAILS = "/users/user-management/details";
    static _ADD_NEW_ACCOUNTANT = "/users/accountants/add";

    // chathub
    static _ADMIN_CHAT_VIEW = "/admin/chathub";
    static _ACCOUNTANT_CONTACT_US_VIEW = "/accountant/chathub";

    // finances list
    static _FINANCES_LIST = "/finances/list";

    static _PORTFOLIO = "/portfolio-menu";
    static _REGISTER = "/register";
    static _REGISTER_COMPLETE = "/register-complete";
    static _COMPANY_LISTING = "/company-listing";
    static _CHECK_NAME = "/company-listing/check-name";
    static _CHECK_NAME = "/portfolio-management/check-name";

    static _PAYMENT_CHECKOUT = "/payment-checkout";
    static _PAYMENT_HISTORY = "/payment-history";
    static _BUSINESS_INTELLIGENCE = "/business-intelligence";
    static _SALES_REPORT = "/reports/sales";

    static _SUB_ACCOUNT_MANAGEMENT = "/sub-account/management";
    static _CUSTOMER_ENQUIRY = "/customer-enquiry";
    static _REFERRALS = "/referral";
    static _NOTIFICATION = "/notification";
    static _RESET_PASSWORD = "/reset-password";

    static _PORTFOLIO_ADD_INCORPORATION = "/portfolio/incorporation/add";
    static _PORTFOLIO_EDIT_INCORPORATION = "/portfolio/incorporation/edit";

    static _COMPANY_MANAGEMENT_ADD = "/company-management/add";
    static _COMPANY_MANAGEMENT_TRANSFER_INCORPORATION = "/company-management/add/transfer-in-application";
    static _COMPANY_MANAGEMENT_CHECK_STATUS = "/company-management/add/check-status";
    static _COMPANY_MANAGEMENT_ADD_PORTFOLIO = "/company-management/add/portfolio";
    static _COMPANY_MANAGEMENT_EXISTING_PORTFOLIO = "/company-management/existing-portfolio";
    static _COMPANY_MANAGEMENT_RENEWAL_LIST = "/company-management/existing-portfolio/renewal-list";
    static _COMPANY_MANAGEMENT_ONGOING_MATTER = "/company-management/existing-portfolio/on-going-matter";
    static _COMPANY_MANAGEMENT_VIEW_INCORP = "/company-management/view/incorporation"
    static _PORTFOLIO_DOCUMENTS = "/company-management/incorporation/documents";
    static _PORTFOLIO_SERVICES = "/company-management/services";
    static _PORTFOLIO_CUSTOMER_RELATION_MANAGER = "/company-management/customer-relation-manager";
    static _TRANSFER_SHARES_SERVICES = "/company-management/services/transfer-share";
    static _ALLOT_SHARES_SERVICES = "/company-management/services/allot-share";
    static _INCREASE_SHARES_SERVICES = "/company-management/services/increase-share";
    static _DECREASE_SHARES_SERVICES = "/company-management/services/decrease-share";
    static _ADD_REMOVE_DIRECTOR_SERVICES = "/company-management/services/add-remove-director";
    static _EDIT_PARTICULAR_COMPANY_OFFICER_SERVICES = "/company-management/services/edit-particular-company-officer";

    static _INCORP_DOC_METHOD_SELECTION = "/company-management/add/method-selection";
    static _INCORP_DOC_SIGNING = "/company-management/add/signing";

    static _INDIVIDUAL_MANAGEMENT = "/individual-management";
    static _INDIVIDUAL_MANAGEMENT_ADD_PROFILE = "/individual-management/add";
    static _INDIVIDUAL_MANAGEMENT_EXISTING_PROFILE = "/individual-management/existing-profile";

    static _KYC_INDEX = "/kyc";
    static _KYC_RESULT_LIST = "/kyc/result";
    static _KYC_RESULT_DETAIL = "/kyc/result/detail";

    static _FACT_SHEET = "/fact-sheet";
    static _INCORPORATION_ADD_PORTFOLIO = "/company-management/add/portfolio";
    static _INCORPORATION_EDIT_PORTFOLIO = "/company-management/edit/portfolio";
    static _INCORPORATION_TRANSFER_IN_COMPANY = "/incorporation/add/transfer-in-application";

    static _PRODUCTION_NAME_CHECK = "/production/name-check";
    static _PRODUCTION_FINANCE = "/production/finance";
    static _PRODUCTION_KYC = "/production/kyc";
    static _PRODUCTION_KYC_RESULT = "/production/kyc/result";
    static _PRODUCTION_REGISTRY = "/production/registry";
    static _PRODUCTION_SERVICES_CHANGES_REQUEST_ADMIN_VIEW = "/production/services-request/admin-view";
    static _FINANCES = "/production/finances";
    static _ANNUAL_COMPLIANCE = "/production/annual-compliance";
    static _COMPANY_ONGOING_MATTER_TASK_VIEW = "/production/task";
    static _SERVICES_PRICES = "/servicesprices";
    static _SERVICES_PRICES_SERVICES = "/servicesprices/services";
    static _SERVICES_PRICES_OTHER = "/servicesprices/other";
    static _SERVICES_PRICES_OTHER_DETAIL = "/servicesprices/other-detail";
    static _SERVICES_PRICES_INCORPORATION_DETAIL = "/servicesprices/services/detail";
    static _SERVICES_PRICES_MASTER_LIST = "/servicesprices/masterlist";
    static _ROLE_PERMISSION = "/users/role-permission";
    static _GROUP_DETAIL = "/users/role-permission/group-detail";
    static _DOCUMENT_MANAGEMENT = "/configuration/document/management";
    static _DEPARTMENT_MANAGEMENT = "/department/management";
    static _PRODUCT_MANAGEMENT = "/configuration/product-management";
    static _ADMIN_COMPANY_MANAGEMENT = "/company-management";
    static _SUMMARY_DASHBOARD = "/summary-dashboard";
    static _PRODUCTION_QC = "/production/ProductionQC";
    static _QC_DOCUMENTS = "/production/ProductionQC/documents/";
    static _SUMMARY = "/summary";
    static _ACCOUNTANTS = "/accountants/accountants";
    static _REFERRAL_PACKAGE = "/referralpackage/referralPackage";
    static _PANDADOC = "/document/pandadoc";
    static _WORKFLOW_MANAGEMENT = "/workflow/workflow-management";
    static _UPDATE_COMPANY_INFO_SERVICES = "/company-management/services/update-company-info";
    static _EDIT_COMPANY_SERVICES_REQUEST = "/company-management/services/edit-update-request";
    static _VIEW_COMPANY_SERVICES_REQUEST = "/company-management/services/view-update-request";
}

/// <summary>
/// Author: Lewis
/// For notiflix constant
/// </summary>
export class SweetAlert {
    static _OK = "OKAY";
    static _BACK = "BACK";
    static _ERROR = "ERROR";
    static _ERROR_HAS_OCCUR = "ERROR_HAS_OCCUR";
    static _OPERATION_SUCCESS = "OPERATION_SUCCESS";
    static _OPERATION_FAIL = "OPERATION_FAILURE";
    static _INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR";
    static _FORBIDDEN_ERROR = "FORBIDDEN_ERROR";
    static _PAGE_NOT_FOUND_ERROR = "PAGE_NOT_FOUND";
    static _MAKE_AN_ERROR_REPORT = "Make a report";
    static _DELETE_CONFIRMATION = "DELETE_CONFIRMATION";
    static _UNAUTHORISED = "UNAUTHORISED";
}

/// <summary>
/// Author: Lewis
/// </summary>
export class ComponentKeys {
    static _WORKFLOW_TASK_INFO = "Work Flow Task Info";
    static _WORKFLOW_TASK_REQUIRED_DOCUMENT_LIST = "Work Flow Required Document List";
    static _WORKFLOW_TASK_DOCUMENT_UPLOAD = "Work Flow Document Upload";
    static _OFFICER_ROLE_ASSIGNMENT = "Officer Role Assignment";
    static _OFFICER_DETAIL_COMPONENT = "Officer Details";

    // form
    static _BANK_LOAN_FORM = "Bank Loan Form";
    static _FILTER_FORM = "Filter Form";
    static _HIERARCHY_FORM = "Hierarchy Form";
    static _OTHER_FORM = "Other Form";
    static _TRADING_FORM = "Trading Form";
    static _ROLE_FORM = "Role Form";
    static _STOCK_INVESTMENT_FORM = "Stock Investment Form";
    static _PERSON_FORM = "Person Form";
    static _PROPERTY_INVESTMENT_FORM = "Property Investment Form";
    static _UNDER_EMPLOYMENT_FORM = "Under Employment Form";

    static _UPDATE_COMPANY_NAME_SERVICE = "Update Company Name Service";
    static _ALLOT_SHARES_SERVICE = "Allot Share Service";
    static _DECREASE_SHARES_SERVICE = "Decrease Share Service";
    static _INCREASE_SHARES_SERVICE = "Increase Share Service";
    static _TRANSFER_SHARE_SERVICE = "Transfer Share Service";
    static _UPDATE_COMPANY_OFFICER_SERVICE = "Update Company Officer Service";
    static _UPDATE_COMPANY_SECRETARY_SERVICE = "Update Company Secretary Service";
    static _UPDATE_COMPANY_ADDRESS_SERVICE = "Update Company Address Service";
    static _UPDATE_COMPANY_SHAREHOLDER_OR_DIRECTOR_SERVICE = "Update Company Shareholders or Directors Service";
    static _ADD_OR_REMOVE_DIRECTORS_SERVICE = "Add Or Remove Directors";
    static _NEW_SHAREHOLDERS_SHARES_ALLOTMENT_SERVICE = "New Shareholder Shares Allotment";
    static _TRANSFER_SHARE_SERVICE_SERVICE = "New Shareholder Shares Allotment";
}

/// <summary>
/// Author: Lewis
/// </summary>
export class DbOperationTypes {
    static _MODIFY = 1;
    static _REMOVE = 2;
    static _CREATE = 3;
    static _MAP = 4;
}

/// <summary>
/// Author: Lewis
/// </summary>
export class ModuleKeys {
    static _DASHBOARD = 'Dashboard';
    static _FACT_SHEET = 'Fact Sheet';
    static _COMPANY_MANAGEMENT = 'Company Management';
    static _INDIVIDUAL_MANAGEMENT = 'Individual Management';
    static _KYC = 'KYC';
    static _CRM = 'CRM';
    static _BUSINESS_INTELLIGENCE = 'Business Intelligence';
    static _SUMMARY = 'Summary';
    static _PRODUCTION_NAME_CHECK = 'Production Name Check';
    static _PRODUCTION_FINANCE = 'Production Finance';
    static _PRODUCTION_QC = 'Production QC';
    static _PRODUCTION_KYC = 'Production KYC';
    static _PRODUCTION_REGISTRY = 'Production Registry';
    static _SERVICES_PRICES = 'Services & Prices';
}

/// <summary>
/// Author: Lewis
/// </summary>
export const PermissionControl = {
    _EXECUTE: 'CanExecute',
    _CREATE: 'CanCreate',
    _READ: 'CanRead',
    _UPDATE: 'CanUpdate',
    _DELETE: 'CanDelete',
    _WRITE: 'CanWrite',
}

/// <summary>
/// Author: Lewis
/// </summary>
export const HubMethodKeys = {
    _NOTIFICATION: 'PushNotification',
    _CHAT_SESSION_INITIALIZED: 'ChatSessionInitialized',
    _CHAT_SESSION_ENDED: 'ChatSessionEnded',
    _BROADCAST_MESSAGE: 'BroadcastMessage',
    _NEW_CHAT_REQUEST: 'NewChatRequest',
    _CHAT_LOBBY_NEW_MESSAGE: 'ChatLobbyNewMessage',
}

export const RegexValidation = {
    _CHINESE_INPUT: /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u
}

/// <summary>
/// Author: Lewis
/// </summary>
export class LoadingStateText {
    static _PLEASE_WAIT = "Please wait..."
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _ENGLISH_GB = "en-GB";
    static _CHINESE_HK = "zh-HK";
    static _CHINESE_CN = "zh-CN";

    static getValues() {
        return [
            { lang: this._ENGLISH_GB, id: 1 },
            { lang: this._CHINESE_HK, id: 2 },
            { lang: this._CHINESE_CN, id: 3 },
        ];
    }
}

/// <summary>
/// Author: Chris
/// </summary>
export class Status {
    static _DELETED = -2;
    static _REJECTED = -1;
    static _INACTIVE = 0;
    static _ACTIVE = 1;
    static _PENDING = 2;
    static _EXPIRED = 3;
}

/// <summary>
/// Author: Lewis
/// </summary>
export class OfficerTypeID {
    static _INDIVIDUAL = 1;
    static _CORPORATE = 2;
}

/// <summary>
/// Author: Lewis
/// </summary>
export class OfficerRoleId {
    static _DIRECTOR = 1;
    static _SHAREHOLDER = 2;
}

/// <summary>
/// Author: Chris
/// </summary>
export const ShareholderType = {
    INDIVIDUAL: 'Individual',
    CORPORATE: 'Corporate'
}

/// <summary>
/// Author: Chris
/// </summary>
export const PositionKey = {
    DIRECTOR: 'director',
    SHAREHOLDER: 'shareholder',
    BENEFICIAL_OWNER: 'beneficialOwner',
    SECRETARY: 'secretary',
    NOMINEE_DIRECTOR: 'nomineeDirector'
}

/// <summary>
/// Author: Chris
/// </summary>
export const Position = {
    [PositionKey.DIRECTOR]: 'Director',
    [PositionKey.SHAREHOLDER]: 'Shareholder',
    [PositionKey.BENEFICIAL_OWNER]: 'Beneficial Owner',
    [PositionKey.SECRETARY]: 'Secretary',
    [PositionKey.NOMINEE_DIRECTOR]: 'Nominee Director'
}

/// <summary>
/// Author: Lewis
/// </summary>
export const KycFormKeys = {
    UNDER_EMPLOYMENT: 'underEmployment',
    PROPERTY_INVESTMENT: 'propertyInvestment',
    STOCK_INVESTMENT: 'stockInvestment',
    BANK_LOAN: 'bankLoan',
    OTHER: 'other',
    TRADING: 'trading'
}

/// <summary>
/// Author: Chris
/// </summary>
export const BtnTypes = {
    ADD: 'add',
    DELETE: 'delete',
    VIEW: 'view',
    EDIT: 'edit',
    MISC: 'misc',
    ADD_USER: 'add user',
    VIEW_DOC: 'view doc',
    VIEW_HISTORY: 'view history',
    FILE_DOWNLOAD: 'file download',
    ARROW_LEFT: 'arrow-left',
    ARROW_RIGHT: 'arrow-right',
    THUMBS_UP: 'thumbs-up',
    CALENDER: 'calendar',
    ARROW_RIGHT_CIRCLE: 'arrow-right-circle',
    ADD_TO_CART: 'cart-plus',
    IN_CART: 'cart-arrow-down',
    CHECK_CIRCLE: 'check-circle',
    GEAR_COG: 'gear-cog',
    GEAR_COG_BACKGROUND_THEMED: 'gear-cog-background-themed',
    DOCUMENT: 'document',
    LOCATION_ARROW: 'location-arrow',
    RULE: 'rule',
    DOUBLE_RIGHT: 'double-right',
    MONEY: 'money',
    FA_VIEW: 'fa-view',
    LA_TIMES: 'la-times',
    DOUBLE_RIGHT_PROCEED: 'double-right-proceed',
    PLUS: 'plus',
    MINUS: 'minus',
    FILE_UPLOAD: 'file-upload',
    IMAGE: 'image',
    PIE_CHART: 'pie-chart',
    RIGHT: 'right',
    REMIND: 'remind'
}


/// <summary>
/// Author: Lewis
/// </summary>
export class OTPKey {
    static _SMS = "SMS";
    static _EMAIL = "EMAIL";
}

/// <summary>
/// Author: Lewis
/// </summary>
export class OTPSettings {
    static _OTP_INPUT_LENGTH = 6;
    static _OTP_TIMER = 5; // seconds
    static _OTP_RESEND_ATTEMPT = 1;
}

/// <summary>
/// Author: Lewis
/// </summary>
export class PaymentType {
    static _WITHDRAWN = 'Withdrawn';
    static _PAID = 'Paid';
}

/// <summary>
/// Author: Lewis
/// </summary>
export class PaymentMethod {
    static _WECHATPAY = 1;
    static _ALIPAY = 2;
    static _CREDITCARD = 3;
    static _BANKTRANSFER = 4;
    static _CHEQUE = 5;
}

/// <summary>
/// Author: Chris
/// </summary>
export const InputTypes = {
    INPUT: 'input',
    NUMBER_INPUT: 'number',
    SELECT: 'select',
    RADIO: 'radio',
    PHONE: 'phone',
    TEXTAREA: 'textarea',
    ATTACHMENT: 'attachment',
    DATEPICKER: 'datepicker',
    CHECKBOX: 'checkbox',
    FILEUPLOAD: 'fileupload',
    INPUT_COLOR: 'inputcolor',
    IMAGE_UPLOADER: 'imageuploader',
}

/// <summary>
/// Author: Lewis
/// </summary>
export const KycFormsKey = {
    UNDER_EMPLOYMENT: "Under Employment",
    PROPERTY_INVESTMENT: "Property Investment",
    STOCK_INVESTMENT: "Stock Investment",
    BANK_LOAN: "Bank Loan",
    OTHER: "Other",
    TRADING: "Trading",
    OTHER_REGION: "Other Region"
};

export const IncorpComponentKeys = {
    _COMPANY_INFO: 'CompanyInfo',
    _ADDRESS_SECRETARY: 'AddressSecretary',
    _OFFICERS: 'Officers',
    _KYC: 'Kyc',
    _UB: 'Ultimate Beneficiary'
}
/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
    JURISDICTION_OPTIONS: [
        { label: 'Hong Kong', value: 'HK' },
        { label: 'Singapore', value: 'SG' },
        { label: 'British Virgin Islands', value: 'BVI' },
        { label: 'Samoa', value: 'SAMOA' },
        { label: 'Cayman Islands', value: 'CAYMAN' },
        { label: 'Seychelles', value: 'SEY' },
        { label: 'United Kingdom', value: 'UK' },
        { label: 'United States', value: 'US' },
    ],
    STATUS_OPTIONS: [
        { label: 'Pending', value: 0 },
        { label: 'Rejected', value: 1 },
        { label: 'Approved', value: 2 }
    ],
}

/// <summary>
/// Author: Lewis
/// </summary>
export class DocumentType {
    static _STATUTORY_DOCUMENT = { value: 0, name: "Statutory Document" };
    static _OHTER = { value: 1, name: "Other" };
}

/// <summary>
/// Author: Lewis
/// </summary>
export const CrmType = {
    _ONGOING: 0,
    _COMPLETED: 1,
}

/// <summary>
/// Author: Lewis
/// </summary>
export const OngoingMatterStatus = {
    _ADMIN_QC_REVIEW: { value: 0, name: 'Administration QC review' },
    _PENDING_SIGNATURE: { value: 1, name: 'Pending signature' },
    _PENDING_PAYMENT: { value: 2, name: 'Pending payment' },
    _GOVERNMENT_FILLING: { value: 3, name: 'Filling with government' },
}

/// <summary>
/// Author: Chris
/// </summary>
export const DefaultValues = {
    _SBC_ADDRESS: {
        addressLine1: '1 Wang Kwong Road, Kowloon Bay',
        addressLine2: '10/F., Tower A, Billion Centre',
        postcode: '-',
        state: 'Kowloon',
        country: 'HK',
        deliveryAddressLine1: '1 Wang Kwong Road, Kowloon Bay',
        deliveryAddressLine2: '10/F., Tower A, Billion Centre',
        deliveryPostcode: '-',
        deliveryState: 'Kowloon',
        deliveryCountry: 'HK'
    }
}

export const SidebarType = {
    _ACCOUTANT: 1,
    _ADMIN: 2,
    _SUPERUSER: 3
}

export const RoleType = {
    _SUPERUSER: 1,
    _ACCOUNTANT: 2,
    _ADMIN: 3,
    _GUEST: 4,
}

/// <summary>
/// Author: Jia Ren GOH
/// </summary>
export const AccountantStatus = {
    _REJECTED: 3,
    _PENDING_APPROVAL: 1,
    _APPROVED: 2,
}

/// <summary>
/// Author: Lewis
/// </summary>
export class CompanyStatus {
    static _ALL = 0
    static _REJECTED = -1;
    static _REGISTERING = 1;
    static _LIVE = 2;
    static _DEREGISTERING = 3;
    static _DEREGISTERED = 4;
}

/// <summary>
/// Author: CJ(Jiann)
/// </summary>
export class CompanyStatusName {
    static _ALL = "ALL";
    static _REJECTED = "REJECTED";
    static _REGISTERING = "REGISTERING";
    static _LIVE = "LIVE";
    static _DEREGISTERING = "DEREGISTERING";
    static _DEREGISTERED = "DEREGISTERED";
}

/// <summary>
/// Author: Lewis
/// </summary>
export class CompanyProcessState {
    static _IDLE = 0;
    static _NAME_CHECK = 1000;
    static _PENDING_INFORMATION = 2000;
    static _PENDING_PAYMENT = 3000;
    static _PAYMENT_COMPLETE = 4000;
    static _PENDING_SIGNATURE = 5000;
    static _VERIFY_DOCUMENT = 6000;
    static _PROCESSING_SERVICE_REQUEST = 7000;
}

/// <summary>
/// Author: Robin
/// </summary>
export class CompanyNameCheckStatus {
    static _REJECTED = -1;
    static _PENDING = 0;
    static _APPROVED = 1;
}
export const deleteConfirm = {
    okButtonBackground: '#f64e60'
}

//
// Author: CJ(Jiann)
//
export const DateFormat = {
    _DATE_FORMAT: "YYYY-MM-DD hh:mm:ss A",
    _DATE_ONLY: "YYYY-MM-DD",
    _MONTH_DAY_ONLY: "MM-DD",
    _DISPLAY_DATE_TIME: "MMMM Do YYYY, h:mm:ss a",
    _DISPLAY_TIME_ONLY: "h:mm a",
}

/// <summary>
/// Author: Robin
/// </summary>
export const CompanyRegistrationType = {
    _INCORPORATION: 1,
    _TRANSFER_IN: 2
}

/// <summary>
/// Author: Wind
/// </summary>
export const PaymentStatus = {
    _PENDING: 0,
    _SUCCESS: 1,
    _FAILED: -1
}
export const matterType = { _INCORPORATION: 1, _ANNUAL_PACKAGE: 2, _SERVICES: 3, _MISCELLANEOUS: 4, _ANNUAL_COMPLIANCE: 5 };

export const _MONTH_OPTS = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 }
]

/// <summary>
/// Author: Robin
/// </summary>
export const AnnualComplianceStatus = {
    _PENDING: 0,
    _COMPLETED: 1
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const ResetPasswordResult = {
    _LINK_EXPIRED: "LINK_EXPIRED",
    _OTP_INCORRECT: "OTP_INCORRECT",
    _OPERATION_SUCCESS: "Operation Success",
    _INVALID_PASSWORD: "INVALID_PASSWORD",
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const CompanyDocumentStatus = {
    _ADOPT: 1,
    _PENDING: 0,
    _REJECT: -1,
}

//<summary>
//Author: Lewis
//</summary>
export const TabsViewStyleProps = {
    indicatorColor: "primary",
    textColor: "primary",
    variant: "scrollable",
    scrollButtons: "auto",
    className: "pb-3",
};

export const FinanceType = {
    _INCOME: 1,
    _EXPENSE: 2,
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const FileType = {
    _PDF: ".pdf",
    _DOC: ".doc",
    _DOCX: ".docx",
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const UrgencyId = {
    _THREE_DAYS: 1,
    _ONE_WEEK: 2,
    _TWO_WEEKS: 3,
    _ONE_MONTH: 4,
    _SIX_MONTHS: 5,
}

//<summary>
//Author: Robin
//</summary>
export const AnnualComplianceRuleType = {
    _AR: 1,
    _TR: 2,
    _AGM: 3,
    _ESA: 4
}

//<summary>
//Author: Robin
//</summary>
export const Frequency = {
    _DAILY: 1,
    _WEEKLY: 2,
    _MONTLY: 3,
    _YEARLY: 4
}

//<summary>
//Author: YJ
//</summary>
export const StripeJsKey = {
    _PUBLISHABLE_KEY: "pk_test_51NDOVnDxWnNSGLhbuk0MsDOdkNEQ9zCg7nxTdi5DKc3TgOGHFEszuzGayFEipGxutHhgoyej5abwR4yokAfNGKTk00iPLhE1rP",
    _SECRET_KEY: "sk_test_51NDOVnDxWnNSGLhbd7T6bXfLR7V7U5UGAj2UmV5acU9sPSF0Hvzqq11cycxExPhANvUXrBxf45Z7oSQ3W7QgYcW1009KFkg5L0"
}

//<summary>
//Author: YJ
//</summary>
export const DefaultCurrency = "hkd";

// hkd currency id
export const DefaultCurrencyId = 3;

//<summary>
//Author: YJ
//</summary>
export const StripeStatus = {
    _SUCCESS: "succeeded",
    _FAIL: "failed"
}

//<summary>
//Author: CJ(Jiann)
//</summary>
export const OfficerTypeString = {
    _INDIVIDUAL: "Individual",
    _CORPORATE: "Corporate"
}

//<summary>
//Author: YJ
//</summary>
export const StripeSourceStatus = {
    _CANCELED: "canceled",
    _SUCCESS: "chargeable",
    _FAILED: "failed"
}

export const NotificationPriority = {
    _URGENT: "Urgent",
    _HIGH: "High",
    _NORMAL: "Normal",
    _LOW: "Low",
    _ISREAD: "IsRead",
}

//<summary>
//Author: Sze Hua
//</summary>
export const Icon = {
    _SEARCH: 'search',
    _PROFILE: 'profile',
    _SEARCH_COMPANY: 'search-company',
    _SEARCH_JURISDICTION: 'search-jurisdiction',
    _COMPANY_STATUS: 'company-status',
    _EMAIL: 'search-email',
    _SEARCH_PHONE_NUMBER: 'search-phone-number',
    _SELECT_ACTIVITY: 'select-activity',
    _SEARCH_DOCUMENT_TYPE: 'search- document search type',
    _CALENDER: 'calender',
    _ADD_DOCUMENT: 'add-document',
    _ADD_DOCUMENT_TYPE: 'add-document-type',
}

//<summary>
//Author: Lewis
//</summary>
export const CreateOfficerStepFormRefKey = {
    _COMPANY_OFFICERS: 0,
    _COMPANY_BENEFICIARY: 1,
}

export const AttachmentStatus = {
    _REJECTED: -1,
    _PENDING: 0,
    _APPROVED: 1
}

export const AttachmentType = {
    _CERTIFICATE: 1,
    _IDENTIFICATION: 2,
}

export class PolicyObjectConstant {
    // Objects
    static role = "role";
    static backoffice = "backoffice";
    static company = "company";
    static company_namecheck = "company.namecheck";
    static company_document = "company.document";
    static company_annualCompliance = "company.annualCompliance";

    // Pages / Components
    static company_portfolio_finance = "company.portfolio.finance";
    static company_portfolio_document = "company.portfolio.document";
    static admin_namecheck_list = "admin.namecheck.list";
    static admin_production_list = "admin.production.list";
    static admin_user_management = "admin.userManagement";
    static admin_finance_list = "admin.finance.list";
    static admin_kyc_aml_result = "admin.kycAmlResult";
    static admin_annual_compliance = "admin.annualCompliance";
}

export class PolicyActionConstant {
    static grant = "grant";
    static read = "read";
    static write = "write";
    static view = "view";
}

export const TableId = {
    _ANNUAL_COMPLIANCE: "company-annual-compliance",
    _COMPANY_DOCUMENTS: "company-documents",
    _COMPANY_LETTERS: "company-letters",
    _COMPANY_MANAGEMENT: "company-management",
    _DOCUMENT_MANAGEMENT: "document-management",
    _DOCUMENT_TYPE_MANAGEMENT: "document-type-management",
    _ROLE_MANAGEMENT: "role-management",
    _SERVICE_FEE_MANAGEMENT: "service-fee-management",
    _GOVERNMENT_DEPARTMENT_MANAGEMENT: "government-department-management",
    _LETTER_TYPE_MANAGEMENT: "letter-type-management",
    _SERVICES: "services",
    _SERVICES_FEE_ITEMS: "service-fee-items",
    _SERVICES_FEE_ITEMS_ASSIGNED: "services-fee-items-assigned",
    _FINANCE_LIST: "finance-list",
    _INDIVIDUAL_MANAGEMENT: "individual-management",
    _SERVICE_REQUEST_LIST: "service-request-list",
    _COMPANY_NAME_CHECK: "company-name-check",
    _RENEWAL_LIST: "renewal-list",
    _USERS_MANAGEMENT_ACCOUNTANT: "user-management-accountant",
    _USERS_MANAGEMENT_ADMIN: "user-management-admin",
    _NOTIFICATION_LIST: "notification-list",
    _PAYMENT_HISTORY: "payment-history",
}