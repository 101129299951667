import React, { useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Language } from 'reactstrap';
import FormBuilder from "components/form/FormBuilder";
import { InputTypes, SelectField, ApiUrl, ApiKey, WebUrl, LoadingStateText, RegexValidation } from "util/Constant";
import { Loading, Report, Block } from "notiflix";
import { sortByPopularCountry, stringIsNullOrEmpty } from "util/Utility";
import { CompanyDao } from "data";
import { Notify, Confirm } from "notiflix";
import { useRecoilValue } from "recoil";
import { authCredentialState } from "recoil/Atoms";
import { useTranslation } from "react-i18next";


/// <summary>
/// Author: Robin
/// </summary>
const SubmitNameCheckForm = ({ cancelHandler, selectedFactSheet }) => {
    const formRef = useRef();
    const _history = useHistory();
    const { t } = useTranslation();
    const [reset, setReset] = useState(false);
    const _ENGLISH_COMPANY_NAME_POSTFIX = "LIMITED";
    const _CHINESE_COMPANY_NAME_POSTFIX = "有限公司";
    const authCredential = useRecoilValue(authCredentialState);
    const [hookFormMethods, setHookFormMethods] = useState({});

    const _FIELDS = [
        {
            rowOptions: { xl: 1 },
            columns: [
                {
                    label: "PROPOSED_NAME",
                    name: "englishName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    className: "text-uppercase",
                    postfix: _ENGLISH_COMPANY_NAME_POSTFIX.toUpperCase(),
                    formGroupClass: "m",
                    rules: { required: "Company Name is required" },
                },
                {
                    label: "Proposed Chinese Name (Optional)",
                    name: "chineseName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    postfix: _CHINESE_COMPANY_NAME_POSTFIX,
                    rules: {
                        required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: "Please fill in company name in Chinese." }
                    },
                    showError: true
                }
            ],
        },
    ];


    const createCompanyNameCheck = (formData) => {
        let dao = new CompanyDao();

        formData.firmId = authCredential.firmId;
        formData.jurisdictionId = selectedFactSheet?.jurisdiction?.id;
        formData.englishName = `${formData.englishName.toUpperCase()} ${_ENGLISH_COMPANY_NAME_POSTFIX}`;
        if (!stringIsNullOrEmpty(formData.chineseName)) {
            formData.chineseName += _CHINESE_COMPANY_NAME_POSTFIX;
        }
        Confirm.Show(
            t("SUBMIT_NAME_CHECK"),
            `${t("SUBMIT_NAME_CHECK")}?`,
            t("notiflix:YES"),
            t("notiflix:NO"),
            async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                let loadingTimeout = setTimeout(() => {
                    Block.Circle('#submitNameCheck', LoadingStateText._PLEASE_WAIT);
                }, 250);

                await dao.createCompanyNameCheck(formData).then((response) => {

                    clearTimeout(loadingTimeout);
                    Block.Remove('#submitNameCheck', 500);

                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        Report.Success(
                            t("notiflix:SUBMIT_NAME_CHECK_SUCCESS"),
                            t("notiflix:SUCCESS_CMP_NAME_SUBMIT_REDIRECT"),
                            t("notiflix:OKAY"),
                            () => {
                                _history.push(WebUrl._COMPANY_MANAGEMENT)
                            });
                    }
                    else if (response[ApiKey._API_STATUS_KEY] == 400) {
                        let errorMessage = "";
                        if (response[ApiKey._API_ERROR_KEY]?.EnglishName) {
                            errorMessage += `${t('notiflix:ENG_NAME_CHECK_SUBMIT_FAILED_MSG')}<br />`;
                        }
                        if (response[ApiKey._API_ERROR_KEY]?.ChineseName) {
                            errorMessage += `${t('notiflix:CN_NAME_CHECK_SUBMIT_FAILED_MSG')}<br />`;
                        }
                        console.log("ERRORMESSAGE", errorMessage);
                        Report.Warning(
                            t('notiflix:NAME_CHECK_SUBMIT_FAILED'),
                            errorMessage,
                            t('notiflix:OK')
                        );
                    }
                    else {
                        let errors = response[ApiKey._API_ERROR_KEY];
                        if (errors) {
                            let firstError = errors[0];
                            if (firstError) {
                                Report.Warning(firstError?.title, firstError?.detail);
                            }
                        }
                    }

                    clearTimeout(loadingTimeout);
                    Loading.Remove();
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            },
            () => { return });
    };

    //Submit Company Name
    return (
        <>
            <ModalBody id="submitNameCheck">
                <div className="form-short mb-4">
                    <FormBuilder
                        fields={nameCheckForm(useTranslation())}
                        onSubmit={createCompanyNameCheck}
                        formRef={formRef}
                        reset={reset}
                        resetTriggerReset={setReset}
                        setHookFormMethods={setHookFormMethods}
                    />
                </div>
            </ModalBody>
            <ModalFooter className="panel-foot panel-foot-buttons">
                <button type="button" className="btn btn-themed btn-min-width" onClick={cancelHandler}>{t('CANCEL')}</button>
                <button type="submit" className="btn btn-themed btn-min-width"
                    onClick={() => {
                        formRef.current.dispatchEvent(
                            new Event("submit", { cancelable: true })
                        );
                    }}
                >{t('SUBMIT')}</button>
            </ModalFooter>
        </>
    );
};

export const nameCheckForm = props => {
    let translate = props;
    const _ENGLISH_COMPANY_NAME_POSTFIX = "Limited";
    const _CHINESE_COMPANY_NAME_POSTFIX = "有限公司";
    const _SUBMIT_NAME_CHECK_FORM = [
        {
            rowOptions: { xl: 1 },
            columns: [
                {
                    label: translate.t("PROPOSED_NAME"),
                    name: "englishName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    className: "text-uppercase",
                    postfix: _ENGLISH_COMPANY_NAME_POSTFIX.toUpperCase(),
                    formGroupClass: "m",
                    rules: { required: "Company Name is required" },
                },
                {
                    label: `${translate.t("PROPOSED_CN_NAME")} (${translate.t("OPTIONAL")})`,
                    name: "chineseName",
                    input: InputTypes.INPUT,
                    columnOptions: { xl: 12 },
                    postfix: _CHINESE_COMPANY_NAME_POSTFIX,
                    rules: {
                        required: false, pattern: { value: RegexValidation._CHINESE_INPUT, message: "Please fill in company name in Chinese." }
                    },
                    showError: true
                }
            ],
        },
    ];
    return _SUBMIT_NAME_CHECK_FORM;
}


export default SubmitNameCheckForm;
