import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { WebUrl, BtnTypes, ApiKey, SweetAlert, DefaultCurrencyId, DefaultCurrency } from 'util/Constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authCredentialState, cartState } from 'recoil/Atoms';
import ButtonRound from '../../buttons/ButtonRound';
import EmptyShoppingCart from '../../../assets/img/user/empty-cart-1.png';
import { AccountantDao } from "data";
import Notiflix from "notiflix";
import { Loading, Report, Block, Confirm, Notify } from "notiflix";
import { getCartItemPrices } from 'util/Utility';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';


/// <summary>
/// Author: Lewis
/// Chris - Change rendering of cart items, added recoil support
/// </summary>
const ShoppingCart = (props) => {

    const { t } = useTranslation();
    const _history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [cart, setCart] = useRecoilState(cartState);
    const [cartTotalPrice, setCartTotalPrice] = useState({});
    const [cartItemPrice, setCartItemPrice] = useState([]);
    const authState = useRecoilValue(authCredentialState);

    const { currencyPreference } = useRecoilValue(authCredentialState);
    const defaultCurrencyCode = DefaultCurrency.toUpperCase();

    let accountDao = new AccountantDao();

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    /// <summary>
    /// Author: Chris
    /// Edited: Wind
    /// </summary>
    const removeCartItemHandler = (e, item) => {
        e.stopPropagation();
        Notiflix.Confirm.Show(
            t('notiflix:DELETE_CART'),
            `${t('notiflix:DO_YOU_WISH_DELETE')} (${item.serviceRequest.company.englishName}) ${t('notiflix:FROM_CART')}?`,
            t("YES"), t("NO"), function () { deleteCart(item.id); }, function () { })
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function deleteCart(id) {
        let loadingTimeout = setTimeout(() => {
            Loading.Circle('Deleting cart...');
        }, 250);

        let accountDao = new AccountantDao();
        await accountDao.deleteCart(id).then(responseJson => {

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Report.Success(
                    t(SweetAlert._OPERATION_SUCCESS),
                    t("ITEM_REMOVE_SUCCESSFULLY"),
                    t(SweetAlert._OK),
                    () => {
                        getCartList();
                    }
                );
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t('notiflix:REQUEST_FAILED'),
                    t(SweetAlert._OK),
                );
            }
            clearTimeout(loadingTimeout);
            Loading.Remove();
        })
    }

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (!isEmpty(authState)) {
            getCartList();
        }
    }, [authState]);

    /// <summary>
    /// Author: Wind
    /// </summary>
    const getCartList = async () => {

        await accountDao.getAccountantCart().then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCart(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                Report.Warning(
                    responseJson[ApiKey._API_MESSAGE_KEY],
                    responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t('notiflix:REQUEST_FAILED'),
                    t(SweetAlert._OK),
                );
            }
        })

    };

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        let total = 0;
        let quoteTotal = 0;
        let newElement = [];
        let isCurrencyPreference = (currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate);
        if (cart.length > 0) {
            cart.forEach(function (item, index) {
                let subTotal = 0;
                let quoteSubTotal = 0;
                item.cartItems.forEach(function (item) {
                    // temporary remove discount
                    //subTotal += getCartItemPrices(item.product);
                    subTotal += item.product.price;
                })
                if (isCurrencyPreference) {
                    quoteSubTotal = subTotal * currencyPreference.exchangeRate.rate
                    quoteTotal += quoteSubTotal;
                }
                newElement.push({ id: item.id, price: subTotal, ...isCurrencyPreference && { quotePrice: quoteSubTotal } });
                total += subTotal;
            });
            setCartTotalPrice({ total: total, ...isCurrencyPreference && { quoteTotal: quoteTotal } });
            setCartItemPrice(newElement)
        }
    }, [cart]);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown" tag="li" id="shopping-cart-dropdown">
            <DropdownToggle tag="a" typeof="button" style={{ cursor: "pointer" }}>
                <img alt="" src={require("../../../assets/img/icon/icon-shopping.svg")} />
                {
                    cart.length > 0 &&
                    <span className="label">{cart.length}</span>
                }
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-left dropdown-cart" right >
                {
                    cart.length > 0 ? <>
                        <div className="dropdown-item-content-wrapper y-scrollbar-2 x-scrollbar-2" style={{ maxHeight: "30vh", overflowY: "scroll" }}>
                            {
                                cart.length > 0 && cart.map((item, key) => (
                                    <DropdownItem key={key} toggle={false} onClick={() => {
                                        _history.push({ pathname: WebUrl._PAYMENT_CHECKOUT });
                                        toggle();
                                    }}>
                                        <div className="cart-item-wrapper">
                                            <div className="cart-item-text-container">
                                                <strong className="d-block">{item.serviceRequest.productMatter.name}</strong>
                                                <span className="d-block text-small">{item.serviceRequest.company.englishName} {item.serviceRequest.company.chineseName && `/ ${item.serviceRequest.company.chineseName}`}</span>
                                                <strong className="d-block text-primary"><NumberFormat decimalScale={2} fixedDecimalScale value={cartItemPrice.find(x => x.id === item.id)?.price} thousandSeparator displayType={'text'} prefix={`${defaultCurrencyCode} `} /></strong>
                                                {
                                                    currencyPreference?.id != DefaultCurrencyId && currencyPreference?.exchangeRate?.rate &&
                                                    <strong className="d-block quote-currency"><NumberFormat decimalScale={2} fixedDecimalScale value={cartItemPrice.find(x => x.id === item.id)?.quotePrice} thousandSeparator displayType={'text'} prefix={` ≈ ${currencyPreference?.code} `} /></strong>
                                                }
                                            </div>
                                            <div className="cart-item-btn-wrapper">
                                                <ButtonRound medium type={BtnTypes.DELETE} onClick={(e) => removeCartItemHandler(e, item)} title={null} className="m-l-10" />
                                            </div>
                                        </div>
                                    </DropdownItem>
                                ))
                            }
                        </div>
                        <DropdownItem toggle={false} header>
                            <div className="d-flex justify-content-between disabled">
                                <p className="f-s-14 m-0"><strong style={{ fontWeight: 'lighter' }}>{t("TOTAL")}:</strong></p>
                                <p className="f-s-14 m-0">
                                    <strong className="text-theme-1"><NumberFormat decimalScale={2} fixedDecimalScale value={cartTotalPrice?.total} thousandSeparator displayType={'text'} prefix={`   ${defaultCurrencyCode} `} /></strong>
                                    {cartTotalPrice?.quoteTotal &&
                                        <strong className="quote-currency"><NumberFormat decimalScale={2} fixedDecimalScale value={cartTotalPrice?.quoteTotal} thousandSeparator displayType={'text'} prefix={` ≈ ${currencyPreference?.code} `} /></strong>
                                    }
                                </p>
                            </div>
                            <div className="cart-dropdown-footer p-l-10 p-r-10"><Link to={WebUrl._PAYMENT_CHECKOUT} onClick={toggle} className="btn btn-block btn-themed">{t("CHECKOUT")}</Link></div>
                        </DropdownItem>
                    </> :
                        <div className="text-center p-t-20 p-b-20">
                            <img alt=""  src={EmptyShoppingCart} className="col-lg-12" />
                            <h4 className="m-t-20"> {t("SHOPPING_CART_IS_EMPTY")} </h4>
                        </div>
                }
            </DropdownMenu>
        </Dropdown>
    );
}

export default ShoppingCart;