import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ApiKey, IncorpComponentKeys, InputTypes, SweetAlert } from 'util/Constant';
import { Notify, Report } from 'notiflix';
import { CompanyDao } from "data";
import FormBuilder, { submitForm } from 'components/form/FormBuilder';
import _ from 'lodash/object';
import { IncorporationInfo } from 'recoil/Incorporation';
import { useRecoilState } from 'recoil';
import { stringIsNullOrEmpty } from 'util/Utility';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Chris
/// </summary>
const CompanyNameComponent = ({ toggleStepComplete, ...restProps }) => {
    const { t } = useTranslation();
    const [incorporationInfo, setIncorporationInfo] = useRecoilState(IncorporationInfo);
    const [reset, setReset] = useState(false);
    const _formRef = useRef();
    const _FYE_APPLICABLE_JURISDICTION = ["SG"];
    const _isFyeApplicable = _FYE_APPLICABLE_JURISDICTION.includes(incorporationInfo?.jurisdiction?.shortName);
    const _FYE_OPTIONS = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ];

    const _FYE_COLUMN =
    {
        label: 'Proposed Financial Year-End',
        name: 'proposedFinancialYearEndMonth',
        rules: { required: 'required' },
        input: InputTypes.SELECT,
        options: _FYE_OPTIONS,
        disabled: !stringIsNullOrEmpty(incorporationInfo?.proposedFinancialYearEndMonth)
    };

    const _FIELDS = [{
        rowOptions: { md: 3 },
        columns: [
            { label: 'ENGLISH_COMPANY_NAME', name: 'englishName', disabled: true },
            { label: 'CHINESE_COMPANY_NAME', name: 'chineseName', disabled: true },
            _isFyeApplicable && _FYE_COLUMN
        ]
    }];

    /// <summary>
    /// Author: Chris
    /// </summary>
    const onSubmit = async (data) => {
        let dao = new CompanyDao();
        await dao.updateProposedFyeMonth(incorporationInfo.id, { month: data.proposedFinancialYearEndMonth }).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {

                Notify.Success(t(SweetAlert._OPERATION_SUCCESS));

                setIncorporationInfo((prevState) => ({
                    ...prevState,
                    proposedFinancialYearEndMonth: data.proposedFinancialYearEndMonth
                }));

            }
            else {
                Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            }
        });
        toggleStepComplete(IncorpComponentKeys._COMPANY_INFO);
    }

    useEffect(() => {
        if (incorporationInfo?.id !== undefined) {
            setReset(true);
            if (incorporationInfo?.englishName && (!_isFyeApplicable || (_isFyeApplicable && incorporationInfo?.proposedFinancialYearEndMonth))) {
                toggleStepComplete(IncorpComponentKeys._COMPANY_INFO, true);
            };
        }
        return;
    }, [incorporationInfo]);

    return <div className="section-incorp">
        <div className="title">{t("COMPANY_INFO")}</div>
        <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={_formRef} reset={reset} resetTriggerReset={setReset} resetValues={incorporationInfo} />
        {
            _isFyeApplicable &&
            Object.keys(incorporationInfo).length > 0 &&
            !incorporationInfo.proposedFinancialYearEndMonth &&
            <div className="text-right">
                <div className="btn btn-themed btn-min-width m-t-10" onClick={() => submitForm(_formRef)}>{t("SAVE")}</div>
            </div>
        }
    </div>;
}

export default CompanyNameComponent;