import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { propertyIsUndefined } from 'util/Utility';
import ButtonRound from 'components/buttons/ButtonRound';
import { BtnTypes } from 'util/Constant';

/// <summary>
/// Author: CJ(Jiann)
/// </summary>
const ImageModal = (props) => {
    let { attachmentUrl, showImageModal, toggle, ...restProps } = props;

    return (
        <>
            {
                showImageModal &&
                <div className="brand-image-modal">
                    <div className="image-modal_wrap" style={restProps.style}>
                        <div className="image-modal-header">
                            <a onClick={() => { toggle() }}>
                                <i class="fas fa-times"></i>
                            </a>
                            <a target="_blank"
                                href={attachmentUrl} download >
                                <i class="fas fa-download"></i>
                            </a>
                        </div>
                        <div className="image-modal">
                            <img src={attachmentUrl} />
                        </div>
                    </div>
                </div>
            }

        </>

    );
};

export default ImageModal;