import { stringIsNullOrEmpty } from 'util/Utility';
import { ApiKey, Language, SessionKey, SweetAlert, WebUrl } from "util/Constant";
import { Report, Notify } from 'notiflix';
import i18n from 'i18next';

const _LOCAL_URL = 'https://localhost:44389';
const _SIT_URL = 'http://yicom.gzit.com.my';
const _PROD_URL = 'http://yicom.gzit.com.my';

const getApiUrl = () => {
    // npm run start:sit || npm run build:sit
    switch (process.env.REACT_APP_ENV) {
        case "dev":
            return _LOCAL_URL;

        case "sit":
            return _SIT_URL;

        case "prod":
            return _PROD_URL;

        default:
            // npm run start || npm run build
            return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? _LOCAL_URL : _PROD_URL;
    }
}

export const _API_URL = getApiUrl();

/// <summary>
/// Author: Chris
/// </summary>
class DataAccessObject {

    _signal = null;
    _languageCode = localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_GB;

    /// <summary>
    /// Author: Lewis
    /// </summary>
    responseErrorHandling(response) {

        let { status, url, statusText, ...restResponse } = response;
        let message = "";

        switch (response.status) {

            case 401:
                message = i18n.t("401_ERROR_MSG");
                Report.Failure(i18n.t(SweetAlert._UNAUTHORISED), message, i18n.t(SweetAlert._BACK), () => {
                    window.location = WebUrl._LOGIN;
                });
                break;

            case 403:
                message = i18n.t("403_ERROR_MSG");
                Report.Failure(i18n.t(SweetAlert._FORBIDDEN_ERROR), message, i18n.t(SweetAlert._BACK));
                break;

            case 404:
                // TODO - redirect to page not found
                message = i18n.t("404_ERROR_MSG");
                Report.Failure(i18n.t(SweetAlert._PAGE_NOT_FOUND_ERROR), message, i18n.t(SweetAlert._BACK));
                break;

            case 500:
                message = i18n.t("500_ERROR_MSG");
                Report.Failure(i18n.t(SweetAlert._INTERNAL_SERVER_ERROR), message, i18n.t(SweetAlert._BACK));
                break;

            default:
                break;
        }
    }

    async get(url, customHeaders = null, token = null, withCredential = true) {

        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        }

        if (customHeaders != null) {
            headers = customHeaders
        }

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return fetch(`${_API_URL}${url}`, {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_GET,
            headers: headers,
            signal: this._signal
        }).then((response) => {

            // error handling
            if (!response.ok) {
                this.responseErrorHandling(response);
            }

            /// success api call, return json
            return response.json();

        }).catch((error) => {
            throw error;
        });
    }

    async post(url, data = null, token = null, withCredential = true) {
        let headers = {
            "Content-Type": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body;
        if (data !== null) {
            body = JSON.stringify(data);
        }

        let options = {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_POST,
            headers: headers,
            body: body,
            signal: this._signal
        }

        return this.fetchPost(`${_API_URL}${url}`, options).then((response) => {
            // error handling
            if (!response.ok) {
                this.responseErrorHandling(response);
            }

            return response.json()
        });
    }

    async postForm(url, data = null, token = null, withCredential = true) {
        let headers = {
            'Accept': ApiKey._API_APPLICATION_JSON,
            // "Content-Type": ApiKey._API_FORM_DATA,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body = data;

        let options = {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_POST,
            // mode: "no-cors",
            headers: headers,
            body: body,
            signal: this._signal,
        }

        return this.fetchPost(`${_API_URL}${url}`, options).then((response) => {

            // error handling
            if (!response.ok) {
                this.responseErrorHandling(response);
            }

            /// success api call, return json
            return response.json();

        });
    }

    async getDownload(url, token = null, withCredential = true) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return fetch(`${_API_URL}${url}`, {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_GET,
            headers: headers,
            signal: this._signal
        })
            .then((response) => {

                // error handling
                if (!response.ok) {
                    this.responseErrorHandling(response);
                }

                /// success api call, return array buffer
                return response.arrayBuffer();

            })
            .catch((error) => {
                throw error;
            });
    }

    async postDownload(url, data = null, token = null, withCredential = true) {
        let headers = {
            "Content-Type": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body;
        if (data !== null) {
            body = JSON.stringify(data);
        }

        let options = {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_POST,
            headers: headers,
            body: body,
            signal: this._signal
        }

        return this.fetchPost(`${_API_URL}${url}`, options).then(response => {
            // error handling
            if (!response.ok) {
                this.responseErrorHandling(response);
            }

            /// success api call, return json
            return response.json();
        });
    }

    async fetchPost(url, options = {}) {
        if (!options.method) {
            options.method = ApiKey._API_POST
        }

        if (!options.signal) {
            options.signal = this._signal;
        }

        return fetch(url, options)
            .catch((error) => {
                throw error;
            });
    }

    async delete(url = null, withCredential = true) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        return fetch(url, {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_DELETE,
            headers: headers,
            signal: this._signal
        })
            .then((response) => {
                // error handling
                if (!response.ok) {
                    this.responseErrorHandling(response);
                }

                /// success api call, return json
                return response.json();

            }).catch((error) => {
                throw error;
            });
    }

    async put(url, data = null, token = null, withCredential = true) {
        let headers = {
            "Content-Type": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            "Accept-Language": this._languageCode,
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body;
        if (data !== null) {
            body = JSON.stringify(data);
        }

        let options = {
            credentials: withCredential ? "include" : "omit",
            method: ApiKey._API_PUT,
            headers: headers,
            body: body,
            signal: this._signal
        }

        return fetch(`${_API_URL}${url}`, options).then((response) => {
            // error handling
            if (!response.ok) {
                this.responseErrorHandling(response);
            }

            return response.json()
        });
    }
}

export default DataAccessObject;