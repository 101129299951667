import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Ong Sze Hua
 * DataAccessObject's fetch will return the promise.
 */
class WorkflowDao extends DataAccessObject {

    async getWorkflows(queryParams) {
        return this.get(ApiUrl._API_GET_WORKFLOWS, queryParams);
    }

    async getWorkflowsByJurisdiction(jurisdictionId) {
        return this.get(ApiUrl._API_GET_WORKFLOWS_BY_JURISDICTION.replace(":jurisdictionId", jurisdictionId));
    }

    async createWorkflow(postData) {
        var formData = createWorkflowForm(postData);
        return this.postForm(ApiUrl._API_CREATE_WORKFLOW, formData);
    }

    async getWorkflowByJurisdictionProductMatter(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_WORKFLOW_BY_JURISDICTION_PRODUCT_MATTER.replace(":jurisdictionId", jurisdictionId).replace(":productMatterId", productMatterId));
    }

    async updateJurisdictionProductMatterMapping(postData, jurisdictionId, productMatterId) {
        return this.post(ApiUrl._API_UPDATE_JURISDICTION_PRODUCT_MATTER_MAPPING.replace(":jurisdictionId", jurisdictionId).replace(":productMatterId", productMatterId), postData);
    }

    async updateWorkflow(postData) {
        var formData = createWorkflowForm(postData);
        return this.postForm(ApiUrl._API_UPDATE_WORKFLOW, formData);
    }

    async removeWorkflow(id) {
        return this.delete(ApiUrl._API_DELETE_WORKFLOW.replace(":id", id));
    }

}

export default WorkflowDao;

function createWorkflowForm(postData) {
    let formData = new FormData();
    for (let key in postData) {
        if (Array.isArray(postData[key])) {
            postData[key].forEach((obj, index) => {
                let keyList = Object.keys(obj);
                keyList.forEach((keyItem) => {
                    if (Array.isArray(obj[keyItem])) {
                        obj[keyItem].forEach((subobj, subindex) => {
                            let subkeyList = Object.keys(subobj);
                            subkeyList.forEach((subkeyItem) => {
                                if (Array.isArray(subobj[subkeyItem])) {
                                    let keyName = [key, "[", index, "]", ".", keyItem, "[", subindex, "].", subkeyItem].join("");
                                    formData.append(keyName, subobj[subkeyItem][0]);
                                }
                                else {
                                    let keyName = [key, "[", index, "]", ".", keyItem, "[", subindex, "].", subkeyItem].join("");
                                    if (subobj[subkeyItem])
                                        formData.append(keyName, subobj[subkeyItem]);
                                }
                            });
                        });
                    }
                    else {
                        let keyName = [key, "[", index, "]", ".", keyItem].join("");
                        if (obj[keyItem])
                            formData.append(keyName, obj[keyItem]);
                    }
                });
            });
        } else if (typeof postData[key] === "object") {
            for (let innerKey in postData[key]) {
                if (postData[key][innerKey])
                    formData.append(`${key}.${innerKey}`, postData[key][innerKey]);
            }
        } else {
            if (postData[key])
                formData.append(key, postData[key]);
        }
    }
    return formData;
}
