import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ApiKey, BtnTypes, SweetAlert } from 'util/Constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Confirm, Notify, Report } from 'notiflix';
import _ from 'lodash/object';
import { CompanyDao } from 'data';
import { IncorporationInfo, kycQuestionState } from 'recoil/Incorporation';
import {
    OtherRegionForm
} from './forms/KycForm';
import ButtonRound from 'components/buttons/ButtonRound';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: Chris
/// Edited: CJ(Jiann)
/// </summary>
const KycRegionOfBusiness = () => {

    let dao = new CompanyDao();
    const { t } = useTranslation();
    const incorporationInfo = useRecoilValue(IncorporationInfo);
    const [kycQuestion, setKycQuestion] = useRecoilState(kycQuestionState);

    const { register, watch } = useForm();

    const _REGION_OF_BUSINESS_KEY = {
        ASIA: 'Asia',
        EUROPE: 'Europe',
        WESTERN: 'Western',
        OTHER: 'Other',
    }
    //<summary>
    //Author: CJ(Jiann)
    //</summary>
    const RegionOfBusiness = {
        _ASIA: "China / Hong Kong / Singapore / Japan / Taiwan",
        _WESTERN: "U.S.A / Canada / Australia",
        _EUROPE: "Britain / France / Spain / Germany",
        _OTHER: "Other",
    }

    const initialState = [
        { label: `${t("CHINA")} / ${t("HONG_KONG")} / ${t("SINGAPORE")} / ${t("JAPAN")} / ${t("TAIWAN")}`, value: RegionOfBusiness._ASIA, status: false, isOpen: false, key: _REGION_OF_BUSINESS_KEY.ASIA, isOpen: false },
        { label: `${t("USA")} / ${t("CANADA")} / ${t("AUSTRALIA")}`, value: RegionOfBusiness._WESTERN, status: false, isOpen: false, key: _REGION_OF_BUSINESS_KEY.WESTERN, isOpen: false },
        { label: `${t("BRITAIN")} / ${t("FRANCE")} / ${t("SPAIN")} / ${t("GERMANY")}`, value: RegionOfBusiness._EUROPE, status: false, isOpen: false, key: _REGION_OF_BUSINESS_KEY.EUROPE, isOpen: false },
        { label: `${t("OTHER")}`, value: RegionOfBusiness._OTHER, component: OtherRegionForm, status: false, key: _REGION_OF_BUSINESS_KEY.OTHER, isOpen: false },
    ]

    const [regionOfBusiness, setRegionOfBusiness] = useState(initialState);
    const [selectedRegion, setSelectedRegion] = useState([]);

    const createOrUpdateRegionOfBusiness = async (key, data) => {

        await dao.saveKycQuestions(incorporationInfo?.id, data).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var responseData = response[ApiKey._API_DATA_KEY];
                setKycQuestion((prevState) => ({
                    ...prevState,
                    regionOfBusiness: responseData?.regionOfBusiness
                }));

                Notify.Success(t(SweetAlert._OPERATION_SUCCESS));
                toggleStatusByKey(key);

            }
            else {
                Report.Warning(
                    response[ApiKey._API_MESSAGE_KEY],
                    response[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                    t(SweetAlert._OK),
                );
            };
        });

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const submitOtherRegion = (formValue) => {

        let data = {
            regionOfBusiness: {
                region: [...selectedRegion],
                regionOthers: formValue.regionOthers,
            }
        }

        createOrUpdateRegionOfBusiness(_REGION_OF_BUSINESS_KEY.OTHER, data);

    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggleDetails = (key) => {
        setRegionOfBusiness(prevState => {
            var newState = [...prevState];
            newState.map((o, i) => {
                if (o.key === key) {
                    o.isOpen = !prevState[i].isOpen;
                }
            });
            return newState;
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggleStatusByKey = (key) => {
        setRegionOfBusiness(prevState => {
            var newState = [...prevState];
            newState.map((o, i) => {
                if (o.key === key) {
                    o.status = !prevState[i].status;
                }
            });
            return newState;
        });
    };

    const toggleOption = (key) => {

        if (key == _REGION_OF_BUSINESS_KEY.OTHER && !kycQuestion?.regionOfBusiness?.regionOthers) {
            toggleDetails(key);
            return;
        }

        let filteredItem = regionOfBusiness.filter(x => x.key === key)[0];
        let itemValue = filteredItem.value;

        // create or update region of business & exclude others region
        if (!filteredItem.status && key != _REGION_OF_BUSINESS_KEY.OTHER) {

            let data = {
                regionOfBusiness: {
                    region: [...selectedRegion, itemValue],
                    regionOthers: kycQuestion?.regionOfBusiness?.regionOthers
                }
            };

            createOrUpdateRegionOfBusiness(key, data);
        }
        // remove region of business
        else {
            Confirm.Show(
                t("DO_YOU_WISH_TO_DELETE"),
                t("REMOVE_REGION"),
                t("YES"),
                t("NO"),
                () => {

                    if (key != _REGION_OF_BUSINESS_KEY.OTHER) {

                        setSelectedRegion(prevState => {
                            var newState = [...prevState];

                            var matchedIndex = prevState.indexOf(itemValue);
                            if (matchedIndex !== -1) {

                                newState.splice(matchedIndex, 1);

                                let data = {
                                    regionOfBusiness: {
                                        region: [...newState],
                                        regionOthers: kycQuestion?.regionOfBusiness?.regionOthers
                                    }
                                }

                                createOrUpdateRegionOfBusiness(key, data);
                            }

                            return newState;
                        });
                    }
                    /// remove others region
                    else {
                        let data = {
                            regionOfBusiness: {
                                region: [...selectedRegion],
                            }
                        }
                        createOrUpdateRegionOfBusiness(key, data);
                    }
                }
            )
        }
    }

    /// <summary>
    /// Author: Lewis 
    /// populate kyc question region of business
    /// </summary>
    useEffect(() => {
        if (kycQuestion?.regionOfBusiness) {
            let regionOfBusinessArray = kycQuestion.regionOfBusiness?.region ?? [];

            setRegionOfBusiness(prevState => {

                var newState = [...prevState];

                newState.map(o => {
                    if (regionOfBusinessArray?.includes(o.value)) {
                        o.status = true;
                    }
                })

                // if other region is set, then status is true
                if (kycQuestion?.regionOfBusiness?.regionOthers) {
                    var index = newState.findIndex(x => x.key === _REGION_OF_BUSINESS_KEY.OTHER);
                    newState[index].status = true;
                }

                return newState;
            });

            setSelectedRegion(regionOfBusinessArray);

        }

    }, [kycQuestion]);

    return (
        <tr>
            <td className="p-0">
                <Row>
                    <Col xl={3} xl={3} style={{ backgroundColor: "#F3F4F6", display: 'flex' }}>
                        <p className="m-auto"><strong>{t("REGION_OF_BUSINESS")}</strong></p>
                    </Col>
                    <Col xl={9} className="p-t-10 p-b-10">
                        {
                            regionOfBusiness.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="d-flex" key={item.key}>
                                            <div class="checkbox-wrapper p-3" style={{ minWidth: "60%" }}>
                                                <div class="exp">
                                                    <div class="checkbox">
                                                        <input
                                                            id={`regionOfBusiness_${item.key}`}
                                                            type="checkbox"
                                                            onClick={() => toggleOption(item.key)}
                                                            checked={item.status}
                                                            name={item.key}
                                                            ref={register}
                                                        />
                                                        <label for={`regionOfBusiness_${item.key}`}>
                                                            <span></span>
                                                            {item.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ minWidth: "40%" }}>
                                                {
                                                    item.key == _REGION_OF_BUSINESS_KEY.OTHER && !isEmpty(kycQuestion?.regionOfBusiness?.regionOthers) &&
                                                    <ButtonRound className="m-auto" medium type={BtnTypes.FA_VIEW} onClick={() => toggleDetails(item.key)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {
                                                (item.key == _REGION_OF_BUSINESS_KEY.OTHER && regionOfBusiness.filter(o => o.key === _REGION_OF_BUSINESS_KEY.OTHER)[0].isOpen &&
                                                    React.createElement(item.component, { setState: submitOtherRegion, defaultValues: kycQuestion?.regionOfBusiness }))
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </Col>
                </Row>
            </td>
        </tr>
    );
}

export default KycRegionOfBusiness;