import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { InputTypes, ShareholderType, BtnTypes, WebUrl, Position, PositionKey, ApiKey } from 'util/Constant';
import InputHoc from 'components/form/InputHoc';
import getCountryList from 'react-select-country-list';
import { sortByPopularCountry } from "util/Utility";
import { Link, useHistory } from 'react-router-dom';
import ComplyAdvantageDao from 'data/ComplyAdvantageDao';
import OfficerSearchDao from 'data/OfficerSearchDao';
import { useDispatch } from 'react-redux';
import NavigationButton from 'components/buttons/NavigationButton';
import { Report } from 'notiflix';

/// <summary>
/// Author: Andrew
/// </summary>
const KycSearch = () => {
    const formRef = useRef();
    const history = useHistory();

    const _countries = sortByPopularCountry(getCountryList().getData());
    const { register, control, handleSubmit, errors, reset, trigger, setError, setValue } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'kyc'
    });

    /// <summary>
    /// Author: Andrew
    /// </summary>
    async function onSubmit(data, e) {
        e.preventDefault();
        //let kycDao = new ComplyAdvantageDao();

        //await kycDao.search({
        //    SearchTerm: data.kyc[0].name
        //}).then((response) => {
        //    if (response.data.hits.length > 0) {
        //        history.push({
        //            pathname: WebUrl._KYC_RESULT_LIST,
        //            state: { result: response.data }
        //        });
        //    } else {
        //        Report.Warning(
        //            'Oops!',
        //            `No results found for searched term ${data.kyc[0].name}`,
        //        );
        //    }
        //}).finally(() => {
        //    //
        //});
        let officerSearchDao = new OfficerSearchDao();
        await officerSearchDao.createOfficerSearch({
            SearchTerm: data.kyc[0].name
        }).then(jsonResponse => {
            if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                if (jsonResponse[ApiKey._API_DATA_COUNT_KEY] > 0)
                    history.push(WebUrl._KYC_RESULT_LIST, { searchId: jsonResponse[ApiKey._API_DATA_KEY], searchTerm: data.kyc[0].name })
                else {
                    Report.Warning(
                        'Oops!',
                        `No results found for searched term ${data.kyc[0].name}`,
                    );
                }
            }
        })
    }

    useEffect(() => {
        append({});
    }, []);

    return (
        <>
            <h1 className="page-header">KYC</h1>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardBody>
                            {
                                fields.map((item, index) => {
                                    return (<Row key={index}>
                                        <Col>
                                            <InputHoc name={`kyc[${index}].name`} error={errors?.kyc?.[index]?.name?.message} label="Name" ref={register({ required: 'Name is required.' })} />
                                        </Col>
                                        <Col>
                                            <InputHoc name={`kyc[${index}].reference`} label="Client References (optional)" ref={register()} />
                                        </Col>
                                        <Col>
                                            <InputHoc name={`kyc[${index}].yearOfBirth`} label="Year of Birthday (optional)" ref={register()} />
                                        </Col>
                                        <Col>
                                            <InputHoc name={`kyc[${index}].countries`} label="Country" inputType={InputTypes.SELECT} options={_countries} control={control} ref={register()} />
                                        </Col>
                                        <Col xl={1}>
                                            {
                                                index !== 0 &&
                                                <button type="button" className="btn btn-danger" style={{ marginTop: '5px' }} onClick={() => remove(index)}><i className="fas fa-trash-alt"></i></button>
                                            }
                                        </Col>
                                    </Row>)
                                })
                            }
                            {
                                fields.length < 5 &&
                                <button type="button" className="btn btn-primary mr-2 mt-2" onClick={() => append({})}>Add Search</button>
                            }
                            <button type="submit" className="btn btn-themed mt-2">Submit Search</button>
                        </CardBody>
                    </Card>
                </form>
            </div>
        </>
    );

}

export default KycSearch;