import { ApiUrl, DateFormat } from 'util/Constant';
import { createMultiPartFormBody, filterFileObjectToSubmit, isNullOrUndefined } from 'util/Utility';
import DataAccessObject from './DataAccessObject';
import _ from 'lodash/object';
import moment from "moment";

/**
 * Author : Lewis
 * DataAccessObject's fetch will return the promise.
 */
class OfficerDao extends DataAccessObject {

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async createIndividualOfficer(officerData) {
        let { postcode, state, countryId, address1, address2, dateOfBirth,
            identityAttachment, addressProofAttachment, ...rest } = officerData

        var postData = {
            address: { postcode, state, countryId, address1, address2 },
            ...rest,
        };

        var formBody = createMultiPartFormBody(postData);
        if (!isNullOrUndefined(dateOfBirth)) {
            formBody.append('dateOfBirth', moment(dateOfBirth).format(DateFormat._DATE_FORMAT));
        }
        formBody.append('addressProofAttachment', filterFileObjectToSubmit(addressProofAttachment, true) ?? addressProofAttachment?.[0]);
        formBody.append('identityAttachment', filterFileObjectToSubmit(identityAttachment, true) ?? identityAttachment?.[0]);

        return this.postForm(ApiUrl._API_CREATE_INDIVIDUAL_OFFICER, formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async createCorporateOfficer(officerData) {
        let { postcode, state, countryId, address1, address2, dateOfBirth,
            certificateAttachment, ...rest } = officerData

        var postData = {
            address: { postcode, state, countryId, address1, address2 },
            ...rest,
        };

        var formBody = createMultiPartFormBody(postData);

        if (!isNullOrUndefined(dateOfBirth)) {
            formBody.append('dateOfIncorporation', moment(dateOfBirth).format(DateFormat._DATE_FORMAT));
        }
        formBody.append('certificateAttachment', filterFileObjectToSubmit(certificateAttachment, true) ?? certificateAttachment?.[0]);

        return this.postForm(ApiUrl._API_CREATE_CORPORATE_OFFICER, formBody);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getOfficerList() {
        return this.get(ApiUrl._API_GET_OFFICERS_LIST)
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    async getOfficerById(officerId) {
        return this.get(`${ApiUrl._API_GET_OFFICER_BY_ID}/${officerId}`)
    }

    /// <summary>
    /// Author : Lewis
    /// TODO
    /// </summary>
    async updateCorporateOfficer(data) {
        let { address1, address2, state, postcode, countryId } = data;
        let { phoneNumber, englishName, englishSurname, identityNumber, dateOfBirth, placeOfBirth } = data

        var prepPostData = {
            address: {
                address1, address2, state, postcode, countryId
            },
            phoneNumber, englishName, englishSurname, identityNumber, dateOfBirth, placeOfBirth,
        }

        var postMultiPartForm = createMultiPartFormBody(prepPostData);

        return this.postForm(ApiUrl._API_UPDATE_CORPORATE_OFFICER, postMultiPartForm);
    }

    /// <summary>
    /// Author : Lewis
    /// TODO
    /// </summary>
    async updateIndividualOfficer(data) {
        var postMultiPartForm = createMultiPartFormBody(data);
        return this.postForm(ApiUrl._API_UPDATE_INDIVIDUAL_OFFICER, postMultiPartForm);
    }

    //
    //Author: CJ(Jiann)
    //
    async searchOfficer(data) {
        return this.post(ApiUrl._API_SEARCH_EXISTING_OFFICER, data);
    }

    //
    //Author: CJ(Jiann)
    //
    async getOfficerCompanyShareListById(officerId) {
        return this.get(ApiUrl._API_GET_OFFICER_COMPANY_SHARE_LIST_BY_ID.replace(':officerId', officerId));
    }

    async getOfficerCompanyDirectorListById(officerId) {
        return this.get(ApiUrl._API_GET_OFFICER_COMPANY_DIRECTOR_LIST_BY_ID.replace(':officerId', officerId));
    }

    async getOfficerCompanyListById(officerId) {
        return this.get(ApiUrl._API_GET_OFFICER_COMPANY_LIST_BY_ID.replace(':officerId', officerId));
    }
}

export default OfficerDao;