import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Page } from 'react-pdf';
import Loader from 'react-loader-spinner';
import SamplePdf from '../../assets/pdf/sample-receipt.pdf';


/// <summary>
/// Author: Lewis
/// </summary>
const ViewReceiptModal = props => {
    const { isModalOpen, setModalOpen } = props;
    const [numPages, setNumPages] = useState(null);
    const [isLoadedSuccess, setLoadedSuccess] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoadedSuccess(true);
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const toggleModal = () => {
        setModalOpen(false);
    }

    return (
        <Modal isOpen={isModalOpen} id="receiptsModalPdfViewer">
            <ModalHeader toggle={toggleModal}>Receipts</ModalHeader>

            <ModalBody>
                <Document
                    loading={
                        <div className="d-flex">
                            <Loader
                                className="m-auto"
                                type="TailSpin"
                                color="#00BFFF"
                                height={50}
                                width={50}
                            />
                        </div>}
                    file={SamplePdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                {pageNumber && (<p className="text-center">Page {pageNumber} of {numPages}</p>)}
            </ModalBody>
            <ModalFooter>
                {isLoadedSuccess && (
                    <a className="btn btn-themed" href={SamplePdf} download> Download </a>
                )}
            </ModalFooter>
        </Modal>
    )
};

export default ViewReceiptModal;