import { ApiKey } from "util/Constant";
import Notiflix from "notiflix";
import { AccountRegistrationDao } from "data";

/// <summary>
/// Author: Lewis
/// </summary>
export const isUsernameExisted = async (username) => {
  let dao = new AccountRegistrationDao();

  const isExisted = await dao.checkUsername(username).then((responseJson) => {
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      return true;
    } else {
      var errors = responseJson[ApiKey._API_ERROR_KEY];
      if (errors) {
        var firstError = errors[0];
        if (firstError) {
          Notiflix.Notify.Warning(firstError?.detail ?? firstError?.title);
        }
      } else {
        Notiflix.Notify.Warning("Some unknown error occured.");
      }

      return false;
    }
  });
  return isExisted;
};

/// <summary>
/// Author: Lewis
/// </summary>
export const isEmailExisted = async (email) => {
  let dao = new AccountRegistrationDao();

  const isExisted = await dao.checkEmail(email).then((responseJson) => {
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      return true;
    } else {
      var errors = responseJson[ApiKey._API_ERROR_KEY];
      if (errors) {
        var firstError = errors[0];
        if (firstError) {
          Notiflix.Notify.Warning(firstError?.detail ?? firstError?.title);
        }
      } else {
        Notiflix.Notify.Warning("Some unknown error occured.");
      }

      return false;
    }
  });
  return isExisted;
};

/// <summary>
/// Author: Lewis
/// </summary>
export const isPhoneNumExisted = async (phoneNumber) => {
  let dao = new AccountRegistrationDao();

  const isExisted = await dao
    .checkPhoneNum(phoneNumber)
    .then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        return true;
      } else {
        var errors = responseJson[ApiKey._API_ERROR_KEY];
        if (errors) {
          var firstError = errors[0];
          if (firstError) {
            Notiflix.Notify.Warning(firstError?.detail ?? firstError?.title);
          }
        } else {
          Notiflix.Notify.Warning("Some unknown error occured.");
        }

        return false;
      }
    });
  return isExisted;
};

/// <summary>
/// Author: Robin
/// </summary>
export const checkPasswordComplexity = async (password) => {
    let dao = new AccountRegistrationDao();

    const isValid = await dao.checkPasswordComplexity(password).then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            return true;
        } else {
            var errors = responseJson[ApiKey._API_ERROR_KEY];
            var errorHtml = "";
            errors.forEach((error) => {
                errorHtml += `<li>${error.detail}</li>`;
            });
            errorHtml = `<ul>${errorHtml}</ul>`;
            Notiflix.Report.Warning("Password complexity not met.", errorHtml ?? "", "Okay");

            return false;
        }
    });

    return isValid;
};
