import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { ApiUrl, FileType, BtnTypes, deleteConfirm, LoadingStateText, Icon, InputTypes, TableId } from 'util/Constant';
import { ApiKey } from 'util/Constant';
import { CommonDao, DocumentDao } from 'data';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import ReactTable from "components/react-table/ReactTable";
import Notiflix, { Block } from 'notiflix';
import DocumentViewer from 'components/document-viewer/DocumentViewer';
import { DocumentTemplateModal, DocumentModal, DocumentTypeModal } from './DocumentModal';
import ButtonRound from 'components/buttons/ButtonRound';
import classNames from 'classnames';
import { PageSettings } from 'config/page-settings';
import { useHistory, useLocation } from 'react-router';
import { Tab, Tabs } from '@material-ui/core';
import TabPanel from 'components/panel/TabPanel';
import { formatJurisdictionList, stringIsNullOrEmpty } from 'util/Utility';
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useRecoilValue } from 'recoil';
import { userIdSelector } from 'recoil/Atoms';

//<summary>
//Author: CJ(Jiann)
//</summary>
const DocumentManagement = (props) => {
    const { t } = useTranslation();

    const _history = useHistory();
    const _location = useLocation();
    const { isXlDevices } = useContext(PageSettings);
    const [currentTab, setCurrentTab] = useState(0);
    const _TABS = {
        DOCUMENT: { id: 0, title: t("ADD_DOCUMENT") },
        DOCUMENT_TYPE: { id: 1, title: t("ADD_DOCUMENT_TYPE") }
    };

    useEffect(() => {
        let search = _location.search;

        if (stringIsNullOrEmpty(search)) {
            setCurrentTab(0);
            return;
        }

        let params = new URLSearchParams(search);
        if (params) {
            let tab = parseInt(params.get("tab"));
            setCurrentTab(tab);
        }
    }, [_location]);

    return (<>
        <div className="panel panel-brand panel-flex" id="DocumentWizard">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot">{t("DOCUMENT_MANAGEMENT")}</h1>
                <Tabs
                    value={currentTab}
                    onChange={(e, newTab) => {
                        const path = _location.pathname;
                        let params = new URLSearchParams({ tab: newTab });

                        _history.push({
                            pathname: path,
                            search: `?${params}`
                        })
                        setCurrentTab(newTab)
                    }}
                    variant="scrollable"
                >
                    <Tab icon={<img src={require('../../assets/img/icon/add-document-gold.svg')} className="mr-2" />} classes={{ wrapper: "d-inline" }} label={_TABS.DOCUMENT.title} />
                    <Tab icon={<img src={require('../../assets/img/icon/product-name-check.svg')} className="mr-2" />} classes={{ wrapper: "d-inline" }} label={_TABS.DOCUMENT_TYPE.title} />
                </Tabs>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames(
                    'panel-body-inner-content with-br-radius',
                    {
                        'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices,
                    },
                )}>
                    <div className="d-flex flex-column h-100">
                        <TabPanel value={currentTab} index={_TABS.DOCUMENT.id} className="flex-grow-1">
                            <Document />
                        </TabPanel>
                        <TabPanel value={currentTab} index={_TABS.DOCUMENT_TYPE.id} className="flex-grow-1">
                            <AddDocumentTypes />
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

const Document = (props) => {
    const { t } = useTranslation();
    const userId = useRecoilValue(userIdSelector);

    const _filterFormRef = useRef();
    const { ref: wrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [document, setDocument] = useState({});
    const [isViewing, setIsViewing] = useState(false);
    const [file, setFile] = useState({});
    const [refreshTable, setRefreshTable] = useState(false);
    const [modal, setModal] = useState(false);
    const [templateModal, setTemplateModal] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);


    const _SEARCH_FORMS = useMemo(() => {

        const _FORM_FIELDS = [
            {
                rowOptions: { xl: 6 },
                columns: [
                    { prefix: { icon: Icon._SEARCH_DOCUMENT_TYPE }, label: t('SEARCH_TYPE'), name: 'documentTypeId', placeholder: t("SEARCH_TYPE"), input: InputTypes.SELECT, options: documentTypes, columnOptions: { xl: 3, md: 6 } },
                    { prefix: { icon: Icon._SEARCH_JURISDICTION }, label: t('SEARCH_JURISDICTION'), name: 'jurisdictionId', placeholder: t("SEARCH_JURISDICTION"), input: InputTypes.SELECT, options: jurisdictionOptions, columnOptions: { xl: 3, md: 6 } },
                    { prefix: '#', label: t('SEARCH_CODE'), name: 'code', placeholder: t("SEARCH_CODE"), input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                    { prefix: '#', label: t('SEARCH_NAME'), name: 'name', placeholder: t("SEARCH_NAME"), input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },

                ]
            }
        ];

        return _FORM_FIELDS;

    }, [jurisdictionOptions, documentTypes]);

    const _DOCUMENT_COLUMN = useMemo(() => [
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                if (row.original.name) {
                    return (row.original.name);
                } else {
                    return <> - </>
                }
            },
            isRequiredColumn: true
        },
        {
            id: "Code",
            Header: "systemConfiguration:CODE",
            accessor: "code",
            Cell: ({ row }) => {
                if (row.original.documentCode) {
                    return (row.original.documentCode);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "Jurisdiction",
            Header: "JURISDICTION",
            accessor: "jurisdiction",
            Cell: ({ row }) => {
                if (row.original.jurisdiction.name) {
                    return (row.original.jurisdiction.name);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "Type",
            Header: "systemConfiguration:TYPE",
            accessor: "type",
            Cell: ({ row }) => {
                if (row.original.documentType.name) {
                    return (row.original.documentType.name);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => {
                if (row.original.description) {
                    return (row.original.description);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "Action",
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="m-b-5 d-flex">
                        <ButtonRound className="mr-2" type={BtnTypes.FILE_UPLOAD} small onClick={() => { setTemplateModal(!templateModal); setDocument(row.original); }} />
                        {
                            row.original.templateFile &&
                            <>
                                <a href={row.original?.templateFile?.fileMeta[0]?.requestPath}
                                    className="btn-sm btn-round btn-themed mr-2" target="_blank" id="download_btn"
                                    download={row.original?.templateFile?.fileMeta[0]?.filename}>
                                    <i class="las la-file-download"></i>
                                </a>
                                <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="left" target="download_btn">{t('systemConfiguration:DOWNLOAD_TEMPLATE')}</UncontrolledTooltip>
                                <ButtonRound className="mr-2" type={BtnTypes.VIEW} small onClick={() => {
                                    if (row.original?.templateFile?.fileMeta[0].extension != FileType._PDF) {
                                        Notiflix.Notify.Warning(t("systemConfiguration:DOCX_FILE_NOT_VIEWABLE"))
                                    } else {
                                        setIsViewing(true);
                                        setViewFile(row.original?.templateFile?.fileMeta[0].requestPath);
                                    }

                                }} />

                            </>
                        }
                        <ButtonRound className="mr-2" type={BtnTypes.EDIT} small onClick={() => { setDocument(row.original); setModal(!modal); }} />
                        <ButtonRound type={BtnTypes.DELETE} small onClick={() => {
                            Notiflix.Confirm.Show(t('DELETE_CONFIRMATION'), t('ARE_YOU_SURE_TO_DELETE'), t('YES'), t('NO'),
                                function () {//Yes
                                    removeDocument(row.original.id);
                                }, '', deleteConfirm)
                        }} />
                    </div>
                </>);
            },
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], []);

    const removeDocument = async (id) => {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);

        let dao = new DocumentDao();
        await dao.removeDocumentById(id).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setRefreshTable(prevState => !prevState);
                Notiflix.Notify.Success(t("notiflix:SUCCESS_REMOVE_DOCUMENT"));
            }
        }).finally(() => Block.Remove(".notiflix-confirm-content"));
    }

    const setViewFile = async (filePathString) => {
        setFile(filePathString);
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const getDocumentList = async () => {

        let commonDao = new CommonDao();

        (async () => {
            await commonDao.getDocumentTypeList().then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    let temOpts = data.map(d => { return { label: d.name, value: d.id } });
                    setDocumentTypes(temOpts);
                }
            })
        })();

    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    useEffect(() => {
        getDocumentList();
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    return (<>
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container border-radius-none" >
                <Row className="filter-panel-body p-t-15">
                    <Col xl={8}>
                        <FormBuilder
                            fields={_SEARCH_FORMS}
                            formRef={_filterFormRef}
                            onSubmit={filterOnSubmit}
                            reset={resetFilters}
                            resetTriggerReset={setResetFilters}
                            resetValues={{}}
                        />
                    </Col>
                    <Col xl={4} className="btn-list d-flex justify-content-end">
                        <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                    </Col>
                </Row>
            </div>
            <div className="flex-grow-1" ref={wrapperRef}>
                <div style={{ height: fixedContentHeight }}>
                    <ReactTable
                        enableSearch
                        columns={_DOCUMENT_COLUMN}
                        url={ApiUrl._API_GET_DOCUMENT_LIST}
                        refreshTable={refreshTable}
                        filterFormfield={filtersForm}
                        setPagination
                        customButtons={
                            <button type="button" className="btn btn-sm pull-right btn-themed tbl-custom-brand-btn" onClick={() => { setModal(!modal); }}><i class="fas fa-plus"></i>&nbsp;&nbsp;{t("systemConfiguration:NEW_DOCUMENT")}</button>
                        }
                        tableColumnPreference={[userId, TableId._DOCUMENT_MANAGEMENT]}
                    />
                </div>
            </div>
        </div>
        <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={file} />
        <DocumentModal
            modal={modal}
            setModal={setModal}
            document={document}
            setDocument={setDocument}
            documentTypeOpts={documentTypes}
            setRefreshTable={setRefreshTable}
        />
        <DocumentTemplateModal
            document={document}
            templateModal={templateModal}
            setTemplateModal={setTemplateModal}
            setRefreshTable={setRefreshTable}
        />
    </>);

}

const AddDocumentTypes = props => {
    const { t } = useTranslation();

    const _filterFormRef = useRef();
    const { ref: wrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [documentType, setDocumentType] = useState({});
    const [refreshTable, setRefreshTable] = useState(false);
    const [modal, setModal] = useState(false);
    const [documentTypes, setDocumentTypeList] = useState([]);
    const userId = useRecoilValue(userIdSelector);

    let commonDao = new CommonDao();

    const _DOCUMENT_TYPE_COLUMN = useMemo(() => [
        {
            id: "Name",
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => {
                return (row.original.name);
            },
            isRequiredColumn: true
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => {
                return (row.original.description);
            }
        },
        {
            id: "Action",
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="m-b-5 d-flex">
                        <ButtonRound className="mr-2" type={BtnTypes.EDIT} small onClick={() => { setDocumentType(row.original); setModal(!modal); }} />
                        <ButtonRound type={BtnTypes.DELETE} small onClick={() => {
                            Notiflix.Confirm.Show(t('DELETE_CONFIRMATION'), t('ARE_YOU_SURE_TO_DELETE'), t('YES'), t('NO'),
                                function () {//Yes
                                    removeDocumentType(row.original.id);
                                }, '', deleteConfirm)
                        }} />
                    </div>
                </>);
            },
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], []);

    const removeDocumentType = async (id) => {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        let dao = new DocumentDao();
        await dao.removeDocumentTypeById(id).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setRefreshTable(prevState => !prevState);
                Notiflix.Notify.Success(t("notiflix:SUCCESS_REMOVE_DOCUMENT_TYPE"));
            }
        }).finally(() => Block.Remove(".notiflix-confirm-content"));
    }


    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const getDocumentList = async () => {

        (async () => {
            await commonDao.getDocumentTypeList().then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    let temOpts = data.map(d => { return { label: d.name, value: d.id } });
                    setDocumentTypeList(temOpts);
                }
            })
        })()
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const _SEARCH_FORMS = useMemo(() => {
        const _FORM_FIELDS = [
            {
                rowOptions: { xl: 6 },
                columns: [
                    { prefix: { icon: Icon._SEARCH_DOCUMENT_TYPE }, label: t('SEARCH_TYPE'), name: 'documentTypeId', placeholder: t("SEARCH_TYPE"), input: InputTypes.SELECT, options: documentTypes, columnOptions: { xl: 6, md: 6 } },
                    { prefix: '#', label: t('systemConfiguration:SEARCH_DESCRIPTION'), name: 'description', placeholder: t("systemConfiguration:SEARCH_DESCRIPTION"), input: InputTypes.INPUT, columnOptions: { xl: 6, md: 6 } },

                ]
            }
        ];
        return _FORM_FIELDS;
    }, [documentTypes]);

    useEffect(() => {
        getDocumentList();
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    return (<>
        <div className="d-flex flex-column h-100">
            <div className="filter-panel-container border-radius-none" >
                <Row className="filter-panel-body p-t-15">
                    <Col xl={8}>
                        <FormBuilder
                            fields={_SEARCH_FORMS}
                            formRef={_filterFormRef}
                            onSubmit={filterOnSubmit}
                            reset={resetFilters}
                            resetTriggerReset={setResetFilters}
                            resetValues={{}}
                        />
                    </Col>
                    <Col xl={4} className="btn-list d-flex justify-content-end">
                        <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                        <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                    </Col>
                </Row>
            </div>
            <div className="flex-grow-1" ref={wrapperRef}>
                <div style={{ height: fixedContentHeight }}>
                    <ReactTable
                        enableSearch
                        columns={_DOCUMENT_TYPE_COLUMN}
                        url={ApiUrl._API_GET_DOCUMENT_TYPE_LIST}
                        refreshTable={refreshTable}
                        filterFormfield={filtersForm}
                        setPagination
                        customButtons={
                            <button type="button" className="btn btn-sm pull-right btn-themed tbl-custom-brand-btn" onClick={() => { setDocumentType({}); setModal(!modal); }}>
                                <i class="fas fa-plus"></i>&nbsp;&nbsp;{t("systemConfiguration:NEW_DOCUMENT_TYPE")}
                            </button>
                        }
                        tableColumnPreference={[userId, TableId._DOCUMENT_TYPE_MANAGEMENT]}
                    />
                </div>
            </div>
        </div>
        <DocumentTypeModal
            modal={modal}
            setModal={setModal}
            documentType={documentType}
            setRefreshTable={setRefreshTable}
        />
    </>);
}

export default DocumentManagement;