import { ApiUrl, ApiKey } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Wind
/// </summary> 
class PaymentDao extends DataAccessObject {

    async getPaymentMethod() {
        return this.get(ApiUrl._API_GET_PAYMENT_METHOD);
    }

    async revertOrder(orderId) {
        return this.delete(ApiUrl._API_REVERT_ORDER.replace(":orderId", orderId));
    }

    async getPaymentSummary(paymentId) {
        return this.get(ApiUrl._API_GET_PAYMENT_SUMMARY.replace(":paymentId", paymentId));
    }

    async getPaymentHistoryList(firmId) {
        return this.get(ApiUrl._API_GET_PAYMENT_HISTORY_LIST.replace(":firmId", firmId));
    }

    async getPaymentHistoryListByDate(startDate, endDate) {
        return this.get(ApiUrl._API_GET_PAYMENT_HISTORY_LIST_BY_DATE.replace(':startDate', startDate).replace(':endDate', endDate));
    }

    async downloadPaymentReceipt(paymentId) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_PDF,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        }
        return this.get(ApiUrl._API_DOWNLOAD_PAYMENT_RECEIPT.replace(':paymentId', paymentId), headers);
    }

    async processPaymentWithAlipay(data) {
        return this.post(ApiUrl._API_PROCESS_ALIPAY_PAYMENT, data);
    }

    async handleAlipayCallback(data) {
        return this.post(ApiUrl._API_HANDLE_ALIPAY_CALLBACK, data);
    }

    async processPaymentWithCard(data) {
        return this.post(ApiUrl._API_PROCESS_CARD_PAYMENT, data);
    }

    async handleCardPaymentCallback(data) {
        return this.post(ApiUrl._API_HANDLE_CARD_PAYMENT_CALLBACK, data);
    }

    async processPaymentWithWechat(data) {
        return this.post(ApiUrl._API_PROCESS_WECHAT_PAYMENT, data);
    }

    async processWechatPaySource(sourceId) {
        return this.post(ApiUrl._API_PROCESS_WECHATPAY_SOURCE.replace(":sourceId", sourceId));
    }

    async processWechatPayCharge(chargeId) {
        return this.post(ApiUrl._API_PROCESS_WECHATPAY_CHARGE.replace(":chargeId", chargeId));
    }
}

export default PaymentDao;
