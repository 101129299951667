import React, { useState, useEffect, useRef, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { ApiKey, ApiUrl, BtnTypes, ComponentKeys, CreateOfficerStepFormRefKey, IncorpComponentKeys, LoadingStateText, OfficerRoleId, OfficerTypeID, SweetAlert } from 'util/Constant';
import 'awesome-steps/dist/style.css';
import { Confirm, Notify, Report, Block } from 'notiflix';
import { CompanyDao } from 'data';
import classnames from 'classnames';
import _ from 'lodash/object';
import { companyOfficersState, IncorporationInfo, companyOfficerProfileState } from 'recoil/Incorporation';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import ButtonRound from 'components/buttons/ButtonRound';
import NumberFormat from 'react-number-format';
import SearchOrCreateCompanyOfficerWizard from './SearchOrCreateCompanyOfficerWizard';
import { CardBody, Card, Row, Col, CardFooter } from 'reactstrap';
import { createOfficerFormState } from 'recoil/Incorporation';
import InputHoc from 'components/form/InputHoc';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

/// <summary>
/// Author: Chris
/// </summary>
const CompanyOfficersComponent = ({ index, toggleStepComplete }) => {
    const _CLASS_OF_SHARE_KEY = {
        ORDINARY: "Ordinary", PREFERRED: "Preferred"
    }
    const { t } = useTranslation();
    const stepFormFunctionRef = useRef(null);
    const [isEditable, setEditable] = useState(false);
    const incorporationInfo = useRecoilValue(IncorporationInfo);
    const [companyOfficerList, setCompanyOfficerList] = useRecoilState(companyOfficersState);
    const resetCompanyOfficerList = useResetRecoilState(companyOfficersState);
    const [refreshCompanyOfficers, setRefreshCompanyOfficers] = useState(false);
    const [hasRemainingShares, setHasRemainingShares] = useState(true);
    const [remainingSharesPerClass, setRemainingSharesPerClass] = useState({});
    const [isWizardOfficerModalOpen, setWizardOfficerModal] = useState(false);
    const [officerTypeId, setOfficerTypeId] = useState(null);
    const [officerId, setOfficerId] = useState(null);
    const [totalShareholders, setTotalShareholders] = useState(0);
    const [totalShareAllocated, setTotalShareAllocated] = useState(0);
    const resetCreateOfficerFormValue = useResetRecoilState(createOfficerFormState);

    let companyDao = new CompanyDao();

    const toggleWizardOfficerModal = () => setWizardOfficerModal(prevState => !prevState);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const checkRemainingShares = async (companyId) => {
        var totalShares = 0;
        await companyDao.getCompanyOfficerRemainingShares(companyId).then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                if (response[ApiKey._API_DATA_KEY].length > 0) {
                    response[ApiKey._API_DATA_KEY].map(item => totalShares += item.remainingShares);
                    setHasRemainingShares(!(totalShares == 0));
                    setRemainingSharesPerClass(response[ApiKey._API_DATA_KEY]);
                };
            }
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchCompanyOfficerList = async (companyId) => {
        await companyDao.getCompanyOfficerList(companyId).then(response => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                let data = response[ApiKey._API_DATA_KEY];
                let { shares, roleAssignments, ...restOfficerProps } = response[ApiKey._API_DATA_KEY];
                setCompanyOfficerList(data);

                let totalNumShareholder = 0;
                let totalSharesAmount = 0;

                if (data?.length > 0) {

                    data.map(({ shares, roleAssignments, isShareholder, ...restOfficerProps }) => {

                        if (isShareholder) {
                            totalNumShareholder += 1;
                            totalSharesAmount += shares[0].shareAllocated;
                        }

                    });
                }

                setTotalShareAllocated(totalSharesAmount);
                setTotalShareholders(totalNumShareholder);
            }
            else {
                Report.Warning(t("notiflix:WARNING")+"!", response[ApiKey._API_MESSAGE_KEY], t("notiflix:BACK"));
            }
        });
    };

    const { data: compOfficersJson, mutate: compOfficerMutate, isValidating } = useSWR(incorporationInfo?.id ? [
        ApiUrl._API_GET_COMPANY_OFFICER_LIST.replace(":companyId", incorporationInfo.id),
        ApiKey._API_GET
    ] : null);

    useEffect(() => {
        if (compOfficersJson) {
            if (compOfficersJson[ApiKey._API_SUCCESS_KEY]) {
                let data = compOfficersJson[ApiKey._API_DATA_KEY];
                let { shares, roleAssignments, ...restOfficerProps } = compOfficersJson[ApiKey._API_DATA_KEY];
                setCompanyOfficerList(data);

                let totalNumShareholder = 0;
                let totalSharesAmount = 0;

                if (data?.length > 0) {

                    data.map(({ shares, roleAssignments, isShareholder, ...restOfficerProps }) => {

                        if (isShareholder) {
                            totalNumShareholder += 1;
                            totalSharesAmount += shares[0].shareAllocated;
                        }

                    });
                }

                setTotalShareAllocated(totalSharesAmount);
                setTotalShareholders(totalNumShareholder);
            }
            else {
                Report.Warning(t("notiflix:WARNING")+"!", compOfficersJson[ApiKey._API_MESSAGE_KEY], t("notiflix:BACK"));
            }
        }
    }, [compOfficersJson]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const deleteCompanyOfficerHandler = (companyId, officerId) => {
        Confirm.Show(
            t("notiflix:CONFIRM_DELETE_COMPANY_OFFICER"),
            t("notiflix:CONFIRM_DELETE_COMPANY_OFFICER_MESSAGE"),
            t("notiflix:YES"),
            t("notiflix:NO"),
            async () => {
                await companyDao.deleteCompanyOfficer(companyId, officerId).then(responseJson => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(t("notiflix:DELETE_COMPANY_OFFICER_SUCCESS"));
                        setRefreshCompanyOfficers(prevState => !prevState);

                        // set incomplete
                        if (companyOfficerList.length == 0) {
                            toggleStepComplete(IncorpComponentKeys._OFFICERS, false);
                        }

                    }
                    else {
                        Report.Warning(
                            responseJson[ApiKey._API_MESSAGE_KEY],
                            responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                            t(SweetAlert._OK),
                        );
                    }
                });
            }
        )
    };

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (incorporationInfo.id) {
            checkRemainingShares(incorporationInfo.id);
            // fetchCompanyOfficerList(incorporationInfo.id);
            compOfficerMutate();
        };
    }, [refreshCompanyOfficers, incorporationInfo]);

    /// <summary>
    /// Author: Lewis
    /// reset recoil after unmounting component
    /// </summary>
    useEffect(() => {
        return () => { resetCompanyOfficerList(); }
    }, []);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (companyOfficerList.length > 0) {
            toggleStepComplete(IncorpComponentKeys._OFFICERS, true);
        }
        else if (companyOfficerList.length === 0) {
            toggleStepComplete(IncorpComponentKeys._OFFICERS, false);
        }
    }, [companyOfficerList.length]);

    const onCreateNewOfficer = async (officerFormValue) => {
        let postData = officerFormValue;

        Block.Circle("div.modal-content", LoadingStateText._PLEASE_WAIT);

        if (officerTypeId == OfficerTypeID._CORPORATE) {

            await companyDao.createCompanyCorporateOfficer(incorporationInfo.id, postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    stepFormFunctionRef.current.onSuccessCreateCompanyOfficer(json[ApiKey._API_DATA_KEY]);
                }
                else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }

            }).finally(() => Block.Remove("div.modal-content"));
        }
        else {

            await companyDao.createCompanyIndividualOfficer(incorporationInfo.id, postData).then(json => {
                if (json[ApiKey._API_SUCCESS_KEY]) {
                    stepFormFunctionRef.current.onSuccessCreateCompanyOfficer(json[ApiKey._API_DATA_KEY]);
                }
                else {
                    Report.Warning(
                        json[ApiKey._API_MESSAGE_KEY],
                        json[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                        t(SweetAlert._OK),
                    );
                }
            }).finally(() => Block.Remove("div.modal-content"));
        }
    }

    return <div className="section-incorp">
        <div className="panel panel-brand p-20" style={{ backgroundColor: "#fff" }}>
            <div className="title">{t("SHAREHOLDERS_AND_DIRECTORS")}</div>
            <Row>
                <Col>
                    <Card className="officer-shares-card">
                        <CardBody className="row">
                            <img class="col-xl-3 comp-officer-shares-img" src={require("../../assets/img/ui/shares-chart.svg")} />
                            <Col xl={6}>
                                <div className="officer-shares-text">
                                    <h5 className="text-gray">{t("TOTAL_SHARE")}</h5>
                                    <p><strong><NumberFormat value={totalShareAllocated} displayType={'text'} thousandSeparator={true} /></strong></p>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className="officer-shares-card">
                        <CardBody className="row">
                            <img class="col-xl-3 comp-officer-shares-img" src={require("../../assets/img/ui/shares-human.svg")} />
                            <Col xl={6}>
                                <div className="officer-shares-text">
                                    <h5 className="text-gray">{t("TOTAL_SHAREHOLDER")}</h5>
                                    <p><strong>{totalShareholders}</strong></p>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        <div className="panel panel-brand mb-0" style={{ overflow: 'hidden' }}>
            {
                companyOfficerList.length > 0 ? <>
                    <div class="table-responsive">
                        <table className="table table-officers">
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}></th>
                                    <th style={{ width: "30%" }}><strong>{t("SHAREHOLDER")}</strong></th>
                                    <th style={{ width: "25%" }}><strong>{t("DIRECTOR")}</strong></th>
                                    <th style={{ width: "15%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companyOfficerList.map((officerObject, index) => {
                                        return <tr key={index}>
                                            <td>
                                                <div className="officer-listing-name-wrapper d-flex">
                                                    <div className="p-10">
                                                        {
                                                            officerObject?.officer?.officerTypeId == OfficerTypeID._INDIVIDUAL ?
                                                                <img src={require('../../assets/img/ui/individual.svg')} /> :
                                                                <img src={require('../../assets/img/ui/corporate.svg')} />
                                                        }
                                                    </div>
                                                    <div style={{ fontWeight: 600 }}>
                                                        <p className="m-0">{officerObject?.officer?.englishName} {officerObject?.officer?.chineseName ? `(${officerObject?.officer?.chineseName})` : ""}</p>
                                                        <p className="m-0">({officerObject?.officer?.pNumber})</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="m-0">
                                                    {
                                                        officerObject?.isShareholder ?
                                                            officerObject?.shares?.map((share, index) => {
                                                                return (
                                                                    <li key={index} className={classnames({
                                                                        "text-blue": share?.classOfShareName === _CLASS_OF_SHARE_KEY.ORDINARY,
                                                                        "text-purple": share?.classOfShareName === _CLASS_OF_SHARE_KEY.PREFERRED
                                                                    })}>
                                                                        <b>{t(`${share?.classOfShareName.toUpperCase()}`)} - {share?.currencyName}</b> - <NumberFormat value={`${share.shareAllocated}`} thousandSeparator displayType={'text'} />
                                                                        {` (${Math.round(((share?.shareAllocated / share?.issuedShares) * 100 + Number.EPSILON) * 100) / 100}%)`}
                                                                    </li>
                                                                )
                                                            })
                                                            : <i className="fas fa-times-circle brand-cross"></i>
                                                    }
                                                </ul>
                                            </td>
                                            <td>
                                                {officerObject?.isDirector ? <i className="fas fa-check-circle text-green brand-tick"></i> : <i className="fas fa-times-circle brand-cross"></i>}
                                            </td>
                                            <td>
                                                <div className="btn-list-wrapper d-flex">
                                                    <ButtonRound medium type={BtnTypes.EDIT} onClick={() => {
                                                        setEditable(true);
                                                        setOfficerId(officerObject.officer.id);
                                                        setOfficerTypeId(officerObject.officer.officerTypeId);
                                                        toggleWizardOfficerModal();
                                                    }} />
                                                    <ButtonRound medium type={BtnTypes.DELETE} onClick={() => deleteCompanyOfficerHandler(incorporationInfo.id, officerObject.officer.id)} />
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="text-center">
                                        <strong>{t("ADD_PERSON")}</strong>
                                    </td>
                                    <td colSpan={3}>
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-themed-default m-r-5" onClick={() => {
                                                setEditable(false);
                                                toggleWizardOfficerModal();
                                                setOfficerTypeId(OfficerTypeID._INDIVIDUAL)
                                            }}>
                                                <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/individual.svg")} /> {t("INDIVIDUAL")}
                                            </button>
                                            <button type="button" className="btn btn-themed-default m-l-5" onClick={() => {
                                                setEditable(false);
                                                toggleWizardOfficerModal();
                                                setOfficerTypeId(OfficerTypeID._CORPORATE)
                                            }}>
                                                <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/corporate.svg")} /> {t("CORPORATE")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </> :
                    <Card>
                        <CardBody style={{ backgroundColor: "#FDF8EB" }}>
                            <div className="d-flex justify-content-center">
                                <img src={require("../../assets/img/ui/comp-officer-empty.svg")} />
                            </div>
                            <Row className="m-b-20 m-t-10">
                                <Col xl={4}></Col>
                                <Col xl={4}>
                                    <InputHoc
                                        formGroupClass={"w-100 m-0 hinter"}
                                        disabled={true}
                                        prefix={<img src={require("../../assets/img/icon/bulb.svg")} />}
                                        placeholder={t("ADD_YOUR_COMPANY_SHARES_ALLOCATION")}
                                    />
                                </Col>
                                <Col xl={4}></Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex"><p className="m-auto m-r-15"><strong>{t("ADD_NEW")}</strong></p></div>
                                <button type="button" className="btn btn-themed-default m-r-5" onClick={() => {
                                    setEditable(false);
                                    toggleWizardOfficerModal();
                                    setOfficerTypeId(OfficerTypeID._INDIVIDUAL)
                                }}>
                                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/individual.svg")} /> {t("INDIVIDUAL")}
                                </button>
                                <button type="button" className="btn btn-themed-default m-l-5" onClick={() => {
                                    setEditable(false);
                                    toggleWizardOfficerModal();
                                    setOfficerTypeId(OfficerTypeID._CORPORATE)
                                }}>
                                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/corporate.svg")} /> {t("CORPORATE")}
                                </button>
                            </div>
                        </CardFooter>
                    </Card>
            }
        </div>
        <SearchOrCreateCompanyOfficerWizard
            isOpen={isWizardOfficerModalOpen}
            toggler={toggleWizardOfficerModal}
            officerTypeId={officerTypeId}
            companyId={incorporationInfo.id}
            // refreshCompanyOfficers={fetchCompanyOfficerList}
            refreshCompanyOfficers={compOfficerMutate}
            isEdit={isEditable}
            officerId={officerId}
            onCreateNewOfficer={onCreateNewOfficer}
            ref={stepFormFunctionRef}
            parentKey={CreateOfficerStepFormRefKey._COMPANY_OFFICERS}
        />
    </div>
}

export default CompanyOfficersComponent;