import { Card, CardBody, Row, Col } from "reactstrap";
import { PageSettings } from "config/page-settings";
import React, { useEffect, useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LoadingStateText, WebUrl } from "util/Constant";
import InputHoc from "components/form/InputHoc";
import { AccountantDao } from "data";
import { Report, Block } from "notiflix";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import DataAccessObject from "data/DataAccessObject";

/// <summary>
/// Author: Lewis
/// </summary>
const ForgotPassword = (props) => {
    const _history = useHistory();
    const _context = useContext(PageSettings);
    const { control, handleSubmit } = useForm();

    let accountDao = new AccountantDao();

    const { t, i18n } = useTranslation();

    const { data: languageList } = useSWR(
        ApiUrl._API_GET_LANGUAGE_LIST,
        async (url) => {
            let dao = new DataAccessObject();
            let tempList = [];
            await dao.get(url).then(jsonResponse => {
                if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                    tempList = jsonResponse[ApiKey._API_DATA_KEY];
                }
            });
            return tempList;
        }
    );

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const onSubmit = async (formValue) => {

        Block.Circle("form#forgot-pw-form", LoadingStateText._PLEASE_WAIT);

        await accountDao.sendForgotPasswordEmail(formValue.email).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                Report.Success(
                    t('notiflix:SENT_SUCCESSFUL'),
                    `${t('notiflix:RESET_PASSWORD_EMAIL_SENT')}!`,
                    t('OKAY'),
                    () => {
                        _history.push({ pathname: WebUrl._LOGIN })
                    }
                )
            } else {
                Report.Warning(`${t('notiflix:WARNING')}!`, json[ApiKey._API_MESSAGE_KEY], t('OKAY'));
            }
            Block.Remove("form#forgot-pw-form");
        });
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        _context.setOptions('pageHeader', false);
        _context.setOptions('pageSidebar', false);
        _context.setOptions('sideBarButton', false);
        return (() => {
            _context.setOptions('pageSidebar', true);
            _context.setOptions('pageHeader', true);
            _context.setOptions('pageSidebar', true);
        });
    }, []);

    return <div id="forgot-password-page">
        <div className="container d-flex flex-column h-100">
            <Card className="panel-brand card-login">
                <CardBody className="p-5">
                    <Row>
                        <Col xl={6} xs={12} md={6} className="p-40">
                            <img className="img-responsive" src={require("../../assets/img/ui/forgt-pw.svg")} />
                        </Col>
                        <Col xl={6} xs={12} md={6} className="p-40">
                            <div className="d-flex h-100 form-wrapper">
                                <form onSubmit={handleSubmit(onSubmit)} id="forgot-pw-form">
                                    <h2 className="title title-with-dot">{t('FORGOT_PASSWORD')}</h2>
                                    <p className="m-b-30">{t('PLEASE_ENTER_YOUR')} <strong>{t('EMAIL')}</strong>{`, ${t('WE_WILL_SEND_RESET_PASSWORD_EMAIL')}.`}</p>
                                    <InputHoc label="EMAIL" control={control} name="email" rules={{ required: "Email is required" }} />
                                    <div className="d-flex justify-content-start m-t-40">
                                        <button className="btn btn-mid-long btn-themed">{t('SEND')}</button>
                                        <button className="btn btn-mid-long" onClick={() => _history.push({ pathname: WebUrl._LOGIN })}>{t('RETURN_TO_LOGIN')}</button>
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="d-flex align-items-center p-l-15 p-t-10 mb-auto">
                <span>{t('LANGUAGE')}</span>
                <div className="d-flex">
                    {languageList && (
                        languageList.map(data => (
                            <div className={"pointer-cursor countries language-" + data.code} onClick={() => { i18n.changeLanguage(data.code) }} />
                        ))
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default ForgotPassword;