import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

export const CustomPaginationFooter =
    ({
        pageIndex,
        canPreviousPage,
        pageRange,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        pageCount,
        footerClassname
    }) => {

        const { t } = useTranslation();

        /// <summary>
        /// Author: Lewis
        /// </summary>
        const pagingHandler = {
            toLastPage: () => {
                gotoPage(pageCount - 1);
            },
            toFirstPage: () => {
                gotoPage(0);
            },
            pageBackCallback: () => {
                previousPage();
            },
            pageNextCallback: () => {
                nextPage();
            },
            goToPageCallback: (clickedPageIndex) => {
                gotoPage(clickedPageIndex - 1);
            }
        }

        return <div className={classnames("rt-custom-tfoot", { [footerClassname]: footerClassname })}>
            <span className="text-left">
                {pageCount > 0 && (
                    <>
                        {
                            canPreviousPage &&
                            <button className="btn btn-sm btn-pagination skip-all left-left" onClick={() => pagingHandler.toFirstPage()} disabled={!canPreviousPage}>
                                <i className="fas fa-angle-double-left"></i>
                            </button>
                        }
                        {/* <button className="btn btn-sm" onClick={() => pagingHandler.pageBackCallback()} disabled={!canPreviousPage}>
                                                            <i className="fas fa-angle-left fas-2x"></i>
                                                        </button> */}
                        {
                            pageRange.map((pageI, index) => {
                                return (
                                    <button key={index} className={"btn btn-sm btn-pagination" + (pageIndex === (pageI - 1) ? " active" : "")} onClick={() => pagingHandler.goToPageCallback(pageI)} >
                                        {pageI}
                                    </button>
                                )
                            })
                        }
                        {/* <button className="btn btn-sm btn-pagination" onClick={() => pagingHandler.pageNextCallback()} disabled={!canNextPage}>
                                                            <i className="fas fa-angle-right fas-2x"></i>
                                                        </button> */}
                        {
                            canNextPage &&
                            <button className="btn btn-sm btn-pagination skip-all right-right" onClick={() => pagingHandler.toLastPage()} disabled={!canNextPage}>
                                <i className="fas fa-angle-double-right"></i>
                            </button>
                        }
                    </>
                )}
            </span>
            <span className="m-l-20 no-select" style={{ color: "#8695A0" }}>
                {t("PAGE")}{' '}
                <strong>
                    {pageIndex + 1} {t("OF")} {pageOptions.length > 0 ? pageOptions.length : 1}
                </strong>
            </span>
        </div>
    }