import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Robin
/// </summary> 
class RoleDao extends DataAccessObject {
    async createOrUpdateRole(data) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_ROLE, data);
    }

    async assignActionsToRoleObject(data) {
        return this.post(ApiUrl._API_ASSIGN_ACTIONS_TO_ROLE_OBJECT, data);
    }
}

export default RoleDao;
