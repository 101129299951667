import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import className from 'classnames';
import { WebUrl, ApiKey, AccountantStatus, DateFormat, LoadingStateText, InputTypes, BtnTypes, FileType, SweetAlert, AttachmentStatus, PolicyActionConstant, PolicyObjectConstant } from 'util/Constant';
import NavigationButton from 'components/buttons/NavigationButton';
import ImgNotFound from '../../assets/img/img-not-found.png';
import { Card, CardBody, Row, Col, CardHeader, Collapse, ModalBody, ModalFooter } from "reactstrap";
import AccountantDao from '../../data/AccountantDao';
import Moment from 'moment';
import Notiflix, { Block, Notify, Report } from "notiflix";
import _, { isEmpty } from 'lodash';
import InputHoc from 'components/form/InputHoc';
import { useForm } from 'react-hook-form';
import ButtonRound from 'components/buttons/ButtonRound';
import DocumentViewer from 'components/document-viewer/DocumentViewer';
import BrandModal from 'components/modals/BrandModal';
import RejectAttachmentModal from './RejectAttachmentModal';
import { useTranslation } from 'react-i18next';
import { setAccountantStatusName } from 'util/Utility';
import { submitForm } from 'components/form/FormBuilder';
import { isEmailExisted } from './forms/RegistrationUtils';
import { Can } from 'config/user-access';

const DetailMapper = () => {
    const { t } = useTranslation();
    const _CARD_KEYS = {
        _PERSONAL_DETAILS: t("PERSONAL_DETAIL"),
        _FIRM_DETAILS: t("FIRM_DETAIL"),
        _ACCOUNT_VERIFICATION: t("ACCOUNTANT_VERIFICATION"),
    }

    const _CARD_HEADER = {
        [_CARD_KEYS._PERSONAL_DETAILS]: { id: 0, title: _CARD_KEYS._PERSONAL_DETAILS, icon: require("../../assets/img/icon/edit.svg"), component: AccountantDetails, left: true },
        [_CARD_KEYS._FIRM_DETAILS]: { id: 1, title: _CARD_KEYS._FIRM_DETAILS, icon: require("../../assets/img/icon/icon-firmdetails.svg"), component: FirmDetail, left: true },
        [_CARD_KEYS._ACCOUNT_VERIFICATION]: { id: 2, title: _CARD_KEYS._ACCOUNT_VERIFICATION, icon: require("../../assets/img/icon/attachment.svg"), component: Verification, left: false },
    }

    const _location = useLocation();
    const [currentTab, setCurrentTab] = useState(0);
    const [accountantProfile, setAccountantProfile] = useState([]);

    useEffect(() => {
        getAccountantProfile(_location.state);
    }, [])

    const getAccountantProfile = async (accountantID) => {
        let dao = new AccountantDao();
        await dao.getAccountantDetails(accountantID).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                setAccountantProfile(data);
            }
            else {
                Notiflix.Notify.Failure('Unable to Load Accountant Profile');
            }
        })
    }
    return (<>
        <div className="panel panel-brand panel-flex">
            <div className="panel-body y-scrollbar-2">
                <Row>
                    <Col className="d-inline-flex mb-4">
                        <NavigationButton /><h1 className="page-header title title-with-dot ">{t("ACCOUNTANT_DETAIL")}</h1>
                    </Col>
                </Row>
                <div className="table-brand-wrapper" id="AccountantDetail">
                    <Row>
                        <Col xl={6}>
                            {
                                Object.keys(_CARD_HEADER).filter(x => _CARD_HEADER[x].left == true).map((key, index) => {
                                    return (<>
                                        <Card key={index}>
                                            <CardHeader onClick={() => { setCurrentTab(index) }} style={{ backgroundColor: "#EDEEF1" }}>
                                                <img className="mr-3" src={_CARD_HEADER[key].icon} />{_CARD_HEADER[key].title}
                                            </CardHeader>
                                            <Collapse isOpen={currentTab == index ? true : false}>
                                                <CardBody>
                                                    {
                                                        React.createElement(_CARD_HEADER[key].component, { accountantProfile: accountantProfile, setAccountantProfile: setAccountantProfile })
                                                    }
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </>)
                                })
                            }
                        </Col>
                        <Col xl={6}>
                            {
                                Object.keys(_CARD_HEADER).filter(x => _CARD_HEADER[x].left == false).map((key, index) => {
                                    return (<>
                                        <Card key={index}>
                                            <CardHeader onClick={() => { setCurrentTab(index) }} style={{ backgroundColor: "#EDEEF1" }}>
                                                <img className="mr-3" src={_CARD_HEADER[key].icon} />{_CARD_HEADER[key].title}
                                            </CardHeader>
                                            <Collapse isOpen>
                                                <CardBody>
                                                    {
                                                        React.createElement(_CARD_HEADER[key].component, { accountantProfile: accountantProfile, setAccountantProfile: setAccountantProfile, getAccountantProfile: getAccountantProfile })
                                                    }
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    </>)
                                })
                            }
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    </>)
}
/// <summary>
/// Author: Jia Ren GOH
/// </summary>
const AccountantDetails = props => {
    let { accountantProfile, setAccountantProfile } = props;
    const _history = useHistory();
    const [name, setName] = useState();
    const [isEditEmail, setIsEditEmail] = useState(false);
    const { t } = useTranslation();
    const _formRef = useRef();
    const { control, errors, handleSubmit, reset } = useForm();

    useEffect(() => {
        if (isEditEmail && accountantProfile?.email) {
            reset({ email: accountantProfile.email })
        }
    }, [isEditEmail, accountantProfile?.email]);

    const updateAccountantStatus = async (accountantID, decision) => {
        let dao = new AccountantDao();

        if (decision == AccountantStatus._APPROVED || decision == AccountantStatus._REJECTED) {
            Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
            await dao.updateAccountantStatus(accountantID, decision).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    setAccountantProfile(data);
                    Notiflix.Notify.Success(response[ApiKey._API_MESSAGE_KEY]);
                }
                else {
                    Notiflix.Notify.Failure(response[ApiKey._API_MESSAGE_KEY]);
                }
            }).finally(() => Block.Remove(".notiflix-confirm-content"));
        }
        else {
            Notiflix.Notify.Failure(SweetAlert._OPERATION_FAIL);
        }
    }

    const updateAccountantActive = async (accountantID, statusChange) => {
        let dao = new AccountantDao();

        if (statusChange != null) {
            Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
            await dao.updateAccountantActive(accountantID, statusChange).then((response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    var data = response[ApiKey._API_DATA_KEY];
                    setAccountantProfile(data);
                    Notiflix.Notify.Success(response[ApiKey._API_MESSAGE_KEY]);
                }
                else {
                    Notiflix.Notify.Failure(response[ApiKey._API_MESSAGE_KEY]);
                }
            }).finally(() => Block.Remove(".notiflix-confirm-content"));
        }
        else {
            Notiflix.Notify.Failure(SweetAlert._OPERATION_FAIL);
        }
    }

    /// <summary>
    /// Author: Jia Ren GOH
    /// </summary>
    function setBadgeColour(statusId) {
        switch (statusId) {
            case (AccountantStatus._PENDING_APPROVAL):
                return 'badge badge-warning';
                break;
            case (AccountantStatus._APPROVED):
                return 'badge badge-success';
                break;
            case (AccountantStatus._REJECTED):
                return 'badge badge-danger';
                break;
        }
    }

    /// <summary>
    /// Author: Jia Ren GOH
    /// </summary>
    function setActiveText(active) {
        switch (active) {
            case true:
                return t("ACTIVE");
                break;
            case false:
                return t("INACTIVE");
                break;
        }
    }

    const onSubmit = async data => {
        let accountantDao = new AccountantDao();
        let params = {
            ...data,
            id: accountantProfile?.id
        }
        await accountantDao.updateAccountantEmail(params).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(responseJson[ApiKey._API_MESSAGE_KEY]);
                setAccountantProfile(prev => ({ ...prev, ...data }));
            }
            else {
                Notiflix.Notify.Warning(responseJson[ApiKey._API_FIRST_ERROR_KEY]);
            }
        })
    }

    return <>
        <CardHeader className="px-0 pt-0">
            <Row className="d-flex-center">
                <Col>
                    <h3 className="font-weight-bold mt-2">{t("ACTION")}</h3>
                </Col>
                <Col>
                    {
                        accountantProfile.accountantStatusId == AccountantStatus._PENDING_APPROVAL
                            ?
                            <>
                                <button className="btn btn-themed" onClick={() => Notiflix.Confirm.Show(t('notiflix:APPROVE'), `${t('notiflix:DO_YOU_WISH_APPROVE_ACCOUNTANT')}?`, t('notiflix:APPROVE'), t('notiflix:NO'),
                                    function () {
                                        updateAccountantStatus(accountantProfile.id, AccountantStatus._APPROVED);
                                    })}
                                >{t("APPROVE")}</button>
                                <button className="btn btn-themed" onClick={() => Notiflix.Confirm.Show(t('notiflix:REJECT'), `${t('notiflix:DO_YOU_WISH_REJECT_ACCOUNTANT')}?`, t('notiflix:REJECT'), t('notiflix:NO'),
                                    function () {
                                        updateAccountantStatus(accountantProfile.id, AccountantStatus._REJECTED);
                                    })}
                                >{t("REJECT")}</button>
                            </>
                            :
                            accountantProfile.accountantStatusId == AccountantStatus._APPROVED &&
                            <>
                                <button className="btn btn-themed py-2 px-4 m-2 float-right" onClick={() => {
                                    if (accountantProfile.active) {
                                        Notiflix.Confirm.Show(`${t('notiflix:DISABLE')}${t('ACCOUNTANT')}`, `${t('notiflix:WISH_TO_DISABLE')}${t('ACCOUNTANT')}?`, t('notiflix:DISABLE'), t('notiflix:NO'),
                                            function () {
                                                updateAccountantActive(accountantProfile.id, false);
                                            });
                                    } else {
                                        Notiflix.Confirm.Show(`${t('notiflix:ENABLE')}${t('ACCOUNTANT')}`, `${t('notiflix:WISH_TO_ENABLE')}${t('ACCOUNTANT')}？`, t('notiflix:ENABLE'), t('notiflix:NO'),
                                            function () {
                                                updateAccountantActive(accountantProfile.id, true);
                                            });
                                    }
                                }}
                                >{accountantProfile.active ? t("DISABLE") : t("ENABLE")}</button>
                            </>
                    }
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <Row id="accountantDetailTable">
                <Col xl={4} style={{ alignItems: 'center' }}>
                    <span className={className("mr-2", { "text-red": errors?.email })}>{t("EMAIL")}</span>
                    {
                        accountantProfile.accountantStatusId == AccountantStatus._APPROVED &&
                        <Can I={PolicyActionConstant.view} this={PolicyObjectConstant.admin_user_management}>
                            {allowed => {
                                if (allowed) {
                                    return <ButtonRound small type={BtnTypes.EDIT} onClick={() => { setIsEditEmail(prev => !prev) }} />
                                }
                                return "";
                            }}
                        </Can>
                    }
                </Col>
                <Col xl={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {isEditEmail ? (
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={_formRef} style={{ width: '85%' }}>
                            <InputHoc
                                name='email'
                                inputType={InputTypes.INPUT}
                                rules={{
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    },
                                    validate: async value => (accountantProfile?.email === value) || (await isEmailExisted(value) || "This email address already exists")
                                }}
                                control={control}
                                error={errors?.email}
                                defaultValue={accountantProfile?.email}
                                formGroupClass={"m-0"}
                            />
                        </form>
                    ) : (<span>{accountantProfile.email}</span>)}
                    {isEditEmail && (
                        <ButtonRound small type={BtnTypes.CHECK_CIRCLE} onClick={() => { submitForm(_formRef) }} />
                    )}
                </Col>
                <Col xl={4}>{t("MOBILE_NUMBER")}</Col>
                <Col xl={6}>{accountantProfile.phoneNumber} </Col>
                <Col xl={4}>{t("REGISTRATION_DATE")}</Col>
                <Col xl={6}><span>{Moment(accountantProfile.createdDate).format(DateFormat._DATE_ONLY)}</span> </Col>
                <Col xl={4}>{t("ACTIVE")}</Col>
                <Col xl={6}>{setActiveText(accountantProfile.active)}</Col>
                <Col xl={4}>{t("STATUS")}</Col>
                <Col xl={6}><span className={setBadgeColour(accountantProfile.accountantStatusId)}>{t(setAccountantStatusName(accountantProfile.accountantStatusId))}</span></Col>
            </Row>
            {/* <table>
                <tr>
                    <th>
                        <div className="d-flex justify-between">
                            <span>{t("EMAIL")}</span>
                            <ButtonRound small type={BtnTypes.EDIT} onClick={() => { setIsEditEmail(prev => !prev) }} />
                        </div>
                    </th>
                    <td>
                        <div className="d-flex justify-between d-flex-center">
                            {isEditEmail ? (
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={_formRef} style={{ width: '85%' }}>
                                    <InputHoc
                                        name='email'
                                        inputType={InputTypes.INPUT}
                                        rules={{
                                            required: 'Email is required.',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "invalid email address"
                                            },
                                            validate: async value => await isEmailExisted(value) || "This email address already exists"
                                        }}
                                        control={control}
                                        error={errors?.email}
                                        defaultValue={null}
                                        formGroupClass={"m-0"}
                                    />
                                </form>
                            ) : (<span>{accountantProfile.email}</span>)}
                            {isEditEmail && (
                                <ButtonRound small type={BtnTypes.CHECK_CIRCLE} onClick={() => { submitForm(_formRef) }} />
                            )}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>{t("MOBILE_NUMBER")}</th>
                    <td>
                        {accountantProfile.phoneNumber}
                    </td>
                </tr>
                <tr>
                    <th>{t("REGISTRATION_DATE")}</th>
                    <td>
                        {
                            <span>{Moment(accountantProfile.createdDate).format(DateFormat._DATE_ONLY)}</span>
                        }
                    </td>
                </tr>
                <tr>
                    <th>{t("ACTIVE")}</th>
                    <td>{setActiveText(accountantProfile.active)}</td>
                </tr>
                <tr>
                    <th>{t("STATUS")}</th>
                    <td><span className={setBadgeColour(accountantProfile.accountantStatusId)}>{t(setAccountantStatusName(accountantProfile.accountantStatusId))}</span></td>
                </tr>
            </table> */}
        </CardBody>
    </>;
}

const FirmDetail = props => {
    let { accountantProfile } = props;
    const { t } = useTranslation();

    return (<>
        <table>
            <tr>
                <th>{t("FIRM")}</th>
                <td>{accountantProfile.firm && accountantProfile.firm.name}</td>
            </tr>
            <tr>
                <th >{t("PRACTITIONER")}</th>
                <td>{accountantProfile.firm && accountantProfile.firm.practitionerName}</td>
            </tr>
            <tr>
                <th>{t("EXPERTISE")}</th>
                <td>
                    <div>
                        <ul>
                            {
                                accountantProfile.firm && accountantProfile.firm.expertise.map((item, index) => {
                                    return (
                                        <li>{item.name}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </td>
            </tr>
            <tr>
                <th>{t("SERVICES")}</th>
                <td>
                    <div>
                        <ul>
                            {
                                accountantProfile.firm && accountantProfile.firm.services.map((item, index) => {
                                    return (
                                        <li>{item.name}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </td>
            </tr>
        </table>

    </>);
}

const Verification = props => {
    let { accountantProfile, getAccountantProfile } = props;
    let { certificateAttachment, identificationAttachment, pendingAttachment, id: userId } = accountantProfile;

    const [isViewing, setIsViewing] = useState();
    const [file, setFile] = useState();
    const [modal, setModal] = useState(false);
    const [rejectId, setRejectId] = useState({});
    const { t } = useTranslation();

    const accountantDao = useMemo(() => new AccountantDao());

    const _CERT_FORM = useForm();
    const _ID_FORM = useForm();

    const latestCertFile = useMemo(() => {
        if (!isEmpty(certificateAttachment)) {
            let tempLatestFile = certificateAttachment.fileMeta.find(f => f.id === certificateAttachment.lastUploadedFileMetaId);
            if (!isEmpty(tempLatestFile)) {
                if (tempLatestFile?.accountantVerifications?.verificationStatus === AttachmentStatus._REJECTED) {
                    let currentFile = certificateAttachment.fileMeta.find(f => f.id === certificateAttachment.currentVersionFileMetaId);
                    return currentFile;
                }
                return tempLatestFile;
            }
        }
        return null;
    }, [certificateAttachment]);

    const latestIdFile = useMemo(() => {
        if (!isEmpty(identificationAttachment)) {
            let tempLatestFile = identificationAttachment.fileMeta.find(f => f.id === identificationAttachment.lastUploadedFileMetaId);
            if (!isEmpty(tempLatestFile)) {
                if (tempLatestFile?.accountantVerifications?.verificationStatus === AttachmentStatus._REJECTED) {
                    let currentFile = identificationAttachment.fileMeta.find(f => f.id === identificationAttachment.currentVersionFileMetaId);
                    return currentFile;
                }
                return tempLatestFile;
            }
        }
        return null;
    }, [identificationAttachment]);

    const setViewFile = async (filePathString) => {
        setFile(filePathString);
        setIsViewing(true);
    }

    const approveAttachment = (userId, attachmentId, fileMetaId) => {
        Notiflix.Confirm.Show(t('notiflix:APPROVE_ATTACHMENT'), t('notiflix:DO_YOU_WISH_TO_APPROVE_THIS_ACCOUNTANT_ATTACHMENT') + '?', t('notiflix:APPROVE'), t('notiflix:NO'),
            async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                let postData = { attachmentId: attachmentId, fileMetaId: fileMetaId };
                await accountantDao.adminApproveAttachment(userId, postData).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        Notify.Success(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                        getAccountantProfile(userId);
                    }
                    else {
                        let errors = jsonResponse[ApiKey._API_ERROR_KEY];
                        if (errors) {
                            let firstError = errors[0];
                            if (firstError) {
                                Report.Warning(firstError?.title, firstError?.detail);
                            }
                        }
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            }
        );
    }

    const toggler = () => setModal(prev => !prev);

    useEffect(() => {
        if (!isEmpty(latestCertFile)) {
            _CERT_FORM.reset({
                certificationAttachment: [latestCertFile],
            });
        }
    }, [_CERT_FORM.reset, latestCertFile]);

    useEffect(() => {
        if (!isEmpty(latestIdFile)) {
            _ID_FORM.reset({
                identificationCards: [latestIdFile],
            });
        }
    }, [_ID_FORM.reset, latestIdFile]);

    return <>
        <div id="Verification">
            <Row>
                <Col xl={6}>
                    <Row className="table-header">
                        <InputHoc name="certificationAttachment" formGroupClass="w-100"
                            label={t("CERTIFICATE_DOCUMENT")}
                            labelClasses="d-flex justify-between h4 align-items-center"
                            // labelIcon={
                            //     !isEmpty(latestCertFile) &&
                            //     <div>
                            //         {
                            //             (latestCertFile?.extension == FileType._PDF ||
                            //                 latestCertFile?.extension == FileType._DOC ||
                            //                 latestCertFile?.extension == FileType._DOCX) &&
                            //             <Link className="brand-anchor mr-1"
                            //                 to={latestCertFile?.requestPath}
                            //                 target={"_blank"} download>
                            //                 <ButtonRound className="btn-sm btn-themed" type={BtnTypes.FILE_DOWNLOAD} />
                            //             </Link>
                            //         }
                            //         {
                            //             latestCertFile?.extension == FileType._PDF &&
                            //             <ButtonRound className="btn-sm btn-themed" type={BtnTypes.VIEW} onClick={() => setViewFile(latestCertFile?.requestPath)} />
                            //         }
                            //     </div>
                            // }
                            inputType={InputTypes.FILEUPLOAD}
                            control={_CERT_FORM.control} accept={{ image: true, pdf: true }} disabled />
                        {
                            typeof pendingAttachment?.certificate === 'boolean' && pendingAttachment?.certificate &&
                            <div className="m-auto m-b-5">
                                <ButtonRound small className="m-r-2" type={BtnTypes.CHECK_CIRCLE} title="Approve Certificate Document" onClick={() => approveAttachment(userId, certificateAttachment?.id, latestCertFile?.id)} />
                                <ButtonRound small type={BtnTypes.LA_TIMES} title="Reject Certificate Document"
                                    onClick={() => {
                                        setRejectId({ attachmentId: certificateAttachment?.id, fileMetaId: latestCertFile?.id });
                                        toggler();
                                    }} />
                            </div>
                        }
                    </Row>
                </Col>
                <Col xl={6}>
                    <Row className="table-header">
                        <InputHoc name="identificationCards" formGroupClass="w-100"
                            label={t("IDENTIFICATION_DOCUMENT")}
                            labelClasses="d-flex justify-between h4 align-items-center"
                            // labelIcon={
                            //     !isEmpty(latestIdFile) &&
                            //     <div>
                            //         {
                            //             (latestIdFile?.extension == FileType._PDF ||
                            //                 latestIdFile?.extension == FileType._DOC ||
                            //                 latestIdFile?.extension == FileType._DOCX) &&
                            //             <Link className="brand-anchor mr-1"
                            //                 to={latestIdFile?.requestPath}
                            //                 target={"_blank"} download>
                            //                 <ButtonRound className="btn-sm btn-themed" type={BtnTypes.FILE_DOWNLOAD} />
                            //             </Link>
                            //         }
                            //         {
                            //             latestIdFile?.extension == FileType._PDF &&
                            //             <ButtonRound className="btn-sm btn-themed" type={BtnTypes.VIEW} onClick={() => setViewFile(latestIdFile?.requestPath)} />
                            //         }
                            //     </div>
                            // }
                            inputType={InputTypes.FILEUPLOAD}
                            control={_ID_FORM.control} accept={{ image: true, pdf: true }} disabled />

                        {
                            typeof pendingAttachment?.identification === 'boolean' && pendingAttachment?.identification &&
                            <div className="m-auto m-b-5">
                                <ButtonRound small className="m-r-2" type={BtnTypes.CHECK_CIRCLE} title="Approve Identification Document" onClick={() => approveAttachment(userId, identificationAttachment?.id, latestIdFile?.id)} />
                                <ButtonRound small type={BtnTypes.LA_TIMES} title="Reject Identification Document"
                                    onClick={() => {
                                        setRejectId({ attachmentId: identificationAttachment?.id, fileMetaId: latestIdFile?.id });
                                        toggler();
                                    }} />
                            </div>
                        }
                    </Row>
                </Col>
            </Row>
        </div>
        <DocumentViewer isViewing={isViewing} setIsViewing={setIsViewing} file={file} />
        <RejectAttachmentModal modal={modal} toggler={toggler} userId={userId} rejectId={rejectId} setRejectId={setRejectId} getAccountantProfile={getAccountantProfile} />
    </>
}
export default DetailMapper;