import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Ong Sze Hua
/// </summary>
class JurisdictionDao extends DataAccessObject {

    async getJurisdictionById(jurisdictionId) {
        return this.get(`${ApiUrl._API_GET_JURISDICTION_BY_ID}/${jurisdictionId}`);
    }

    async getJurisdictionList() {
        return this.get(ApiUrl._API_GET_JURISDICTION_LIST);
    }

    async createOrUpdateJurisdiction(postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_JURISDICTION, postData);
    }

    async deleteJurisdiction(id) {
        return this.delete(ApiUrl._API_DELETE_JURISDICTION.replace(':id', id));
    }

    async getFactSheetListByJurisdiction(jurisdictionId) {
        return this.get(ApiUrl._API_GET_FACT_SHEET_LIST_BY_JURISDICTION.replace(":id", jurisdictionId));
    }

    async retrieveStandardShareCapitalPackage(jurisdictionId) {
        return this.get(ApiUrl._API_GET_STANDARD_SHARE_CAPITAL_PACKAGE.replace(":id", jurisdictionId));
    }

    async createOrUpdateStandardShareCapitalPackage(jurisdictionId, data) {
        return this.post(ApiUrl._API_STANDARD_SHARE_CAPITAL_PACKAGE.replace(":id", jurisdictionId), data);
    }

    async getJurisdictionAnnualComplianceList(id) {
        return this.get(ApiUrl._API_GET_JURISDICTION_ANNUAL_COMPLIANCE_LIST.replace(":id", id));
    }

    async createOrUpdateJurisdictionAnnualCompliance(postData) {
        return this.post(ApiUrl._API_CREATE_OR_UPDATE_JURISDICTION_ANNUAL_COMPLIANCE, postData);
    }

    async deleteJurisdictionAnnualCompliance(id) {
        return this.delete(ApiUrl._API_DELETE_JURISDICTION_ANNUAL_COMPLIANCE.replace(":id", id));
    }

    async getAllJurisdictionAssignedDocumentList(jurisdictionId) {
        return this.get(ApiUrl._API_GET_ALL_JURISDICTION_ASSIGNED_DOCUMENT_LIST.replace(':jurisdictionId', jurisdictionId));
    }

    async getAssignedDocumentList(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_ASSIGN_DOCUMENT_LIST.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId));
    }

    async assignDocumentList(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_ASSIGN_DOCUMENT_LIST.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }

    async getAnnualComplianceRuleList(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_ANNUAL_COMPLIANCE_RULE_LIST.replace(':jurisdictionId', jurisdictionId));
    }

    async getAnnualComplianceSystemRuleList(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_ANNUAL_COMPLIANCE_SYSTEM_RULE_LIST.replace(':jurisdictionId', jurisdictionId));
    }

    async getAnnualComplianceCustomRuleList(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_ANNUAL_COMPLIANCE_CUSTOM_RULE_LIST.replace(':jurisdictionId', jurisdictionId));
    }

    async saveAnnualComplianceCustomRule(jurisdictionId, productMatterId, rule) {
        return this.post(ApiUrl._API_SAVE_ANNUAL_COMPLIANCE_CUSTOM_RULE.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), rule);
    }

    async getAssignedAnnualComplianceRule(jurisdictionId, productMatterId) {
        return this.get(ApiUrl._API_GET_ASSIGNED_ANNUAL_COMPLIANCE_RULE.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId));
    }

    async assignAnnualComplianceRule(jurisdictionId, productMatterId, postData) {
        return this.post(ApiUrl._API_ASSIGN_ANNUAL_COMPLIANCE_RULE.replace(':jurisdictionId', jurisdictionId).replace(':productMatterId', productMatterId), postData);
    }

    async updateJurisdictionAvailability(jurisdictionId, isAvailable) {
        return this.post(ApiUrl._API_UPDATE_JURISDICTION_AVAILABILITY.replace(':id', jurisdictionId), isAvailable);
    }

    async createGovernmentDepartment(jurisdictionId, postData) {
        return this.post(ApiUrl._API_GOVERNMENT_DEPARTMENT.replace(":jurisdictionId", jurisdictionId), postData);
    }

    async updateGovernmentDepartment(jurisdictionId, governmentDepartmentId, postData) {
        return this.put(ApiUrl._API_GOVERNMENT_DEPARTMENT_BY_ID.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId), postData);
    }

    async deleteGovernmentDepartment(jurisdictionId, governmentDepartmentId) {
        return this.delete(ApiUrl._API_GOVERNMENT_DEPARTMENT_BY_ID.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId));
    }
    async getGovernmentDeparmentList(jurisdictionId) {
        return this.get(ApiUrl._API_GOVERNMENT_DEPARTMENT.replace(":jurisdictionId", jurisdictionId));
    }

    async createLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, postData) {
        return this.post(ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId), postData);
    }

    async updateLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, letterTypeId, postData) {
        return this.put(ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES_BY_ID.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId).replace(":letterTypeId", letterTypeId), postData);
    }

    async deleteLetterTypeByGovernmentDeparment(jurisdictionId, governmentDepartmentId, letterTypeId) {
        return this.delete(ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES_BY_ID.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId).replace(":letterTypeId", letterTypeId));
    }

    async getLetterTypesListByGovernmentDeparment(jurisdictionId, governmentDepartmentId) {
        return this.get(ApiUrl._API_GOVERNMENT_DEPARTMENT_LETTER_TYPES.replace(":jurisdictionId", jurisdictionId).replace(":governmentDepartmentId", governmentDepartmentId));
    }
}

export default JurisdictionDao;
