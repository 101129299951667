import React, { useMemo, useState, useRef, useEffect } from "react";
import { Card, CardBody, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import Notiflix, { Block } from "notiflix";
import InputHoc from 'components/form/InputHoc';
import BrandModal from "components/modals/BrandModal";
import { InputTypes, ApiKey, _MONTH_OPTS, Frequency, LoadingStateText, ApiUrl } from "util/Constant";
import { useForm } from "react-hook-form";
import classnames from 'classnames';
import { JurisdictionDao } from "data";
import moment from 'moment';
import useSWR from 'swr';
import { formatJurisdictionList } from "util/Utility";
import { useTranslation } from "react-i18next";

///<summary>
///Author: Robin
///</summary>
const AnnualComplianceRecurrenceRuleModal = props => {
    const { modal, setModal, rule, setRule, jurisdictionId, productMatterId, setRefreshRuleList, isEditRule } = props;
    const { t } = useTranslation();
    const _RECURRENCE_SOURCE_TYPE = {
        INCORPORATION: { label: t("INCORPORATION_DATE"), value: 1 },
        FYE: { label: t("systemConfiguration:FINANCIAL_YEAR_END"), value: 2 },
        SPECIFIC_DATE: { label: t("systemConfiguration:SPECIFIC_DATE"), value: 3 }
    };
    const [selectedRecurrenceSourceType, setSelectedRecurrenceSourceType] = useState(_RECURRENCE_SOURCE_TYPE.INCORPORATION.value);

    const _formRef = useRef();
    const { control, handleSubmit, errors, reset, watch, register, setValue } = useForm();

    const _WATCH_IS_FOLLOW_DEADLINE = watch("isFollowDeadline");
    const _WATCH_SYSTEM_DEADLINE_FREQUENCY = watch("annualComplianceRuleMeta.systemDeadlineFrequency");

    const _RECURRENCE_FREQUENCY_OPTS = {
        DAILY: { label: t("systemConfiguration:DAILY"), value: Frequency._DAILY, smallnote: t('systemConfiguration:DAY') },
        WEEKLY: { label: t("systemConfiguration:WEEKLY"), value: Frequency._WEEKLY, smallnote: t('systemConfiguration:WEEK') },
        MONTHLY: { label: t("systemConfiguration:MONTHLY"), value: Frequency._MONTLY, smallnote: t('systemConfiguration:MONTH') },
        ANNUALLY: { label: t("systemConfiguration:ANNUALLY"), value: Frequency._YEARLY, smallnote: t("systemConfiguration:YEAR") },
    };
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(_RECURRENCE_FREQUENCY_OPTS.DAILY);

    const DateOpts = {
        _DAY: { label: t('DAY'), value: 1, minNum: 1, maxNum: 365 },
        _MONTH: { label: t('MONTH'), value: 2, minNum: 1, maxNum: 12 }
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const { data: jurisdictionJson } = useSWR([ApiUrl._API_GET_JURISDICTION_LIST, ApiKey._API_GET]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const jurisdictionOptions = useMemo(() => {
        return jurisdictionJson?.[ApiKey._API_SUCCESS_KEY] ?
            formatJurisdictionList(jurisdictionJson[ApiKey._API_DATA_KEY]) : [];
    }, [jurisdictionJson]);

    ///<summary>
    ///Author: Robin
    ///</summary>
    const toggle = () => {
        setModal(prev => !prev);
        setRefreshRuleList(prevState => !prevState)
    };

    ///<summary>
    ///Author: Robin
    ///</summary>
    const onSubmit = async (formData) => {
        //let params = { ...data, annualComplianceDateExtendType: selectedRecurrenceSourceType, jurisdictionId: jurisdictionId };
        //let msg = "create";
        //if (!stringIsNullOrEmpty(rule)) {
        //    params["id"] = rule.id;
        //    msg = "update"
        //}
        //if (!params["numberOfMonthToExtend"]) {
        //    params["numberOfMonthToExtend"] = 0;
        //}
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let { isFollowDeadline, annualComplianceRuleMeta: { systemDeadlineBefore, systemDeadlineFrequency, ...restRuleMeta }, ...restFormData } = formData;
        let params = {
            ...restFormData,
        }

        if (!isFollowDeadline) {
            params.annualComplianceRuleMeta = {
                ...restRuleMeta,
                repeatType: selectedRecurrenceSourceType,
                systemDeadlineBefore: systemDeadlineBefore,
                systemDeadlineFrequency: systemDeadlineFrequency
            };
        }
        else {
            params.annualComplianceRuleMeta = {
                ...restRuleMeta,
                repeatType: selectedRecurrenceSourceType
            }
        }

        let dao = new JurisdictionDao();
        await dao.saveAnnualComplianceCustomRule(jurisdictionId, productMatterId, params).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success(t("notiflix:ANNUAL_COMPLIANCE_RULE_SAVED"));
                setRule(responseJson[ApiKey._API_DATA_KEY]);
                toggle();
            } else {
                var errors = responseJson[ApiKey._API_ERROR_KEY];
                if (errors) {
                    var firstError = errors[0];
                    if (firstError) {
                        Notiflix.Report.Warning(firstError?.title, firstError?.detail);
                    }
                }
            }
        }).finally(() => Block.Remove(".modal-content"));
    }

    ///<summary>
    ///Author: Robin
    ///</summary>
    const frequency = useMemo(() => {
        if (recurrenceFrequency) {
            return recurrenceFrequency.smallnote;
        }
    }, [recurrenceFrequency]);

    ///<summary>
    ///Author: Robin
    ///</summary>
    useEffect(() => {
        if (rule && isEditRule) {
            reset({ ...rule, isFollowDeadline: !(rule?.annualComplianceRuleMeta?.systemDeadlineFrequency || rule?.annualComplianceRuleMeta?.systemDeadlineBefore) });

            // Set the selected source
            Object.entries(_RECURRENCE_SOURCE_TYPE).map(([k, v], index) => {
                var type = v.value;
                if (v.value === rule?.annualComplianceRuleMeta?.repeatType) {
                    setSelectedRecurrenceSourceType(type);
                    return;
                }
            });
        }
        else {
            setSelectedRecurrenceSourceType(_RECURRENCE_SOURCE_TYPE.INCORPORATION.value);
            reset({});
        }
    }, [rule, isEditRule]);

    const renderSystemDeadline = () => {
        let selectedDateType = Object.values(DateOpts).find((v) => v.value === _WATCH_SYSTEM_DEADLINE_FREQUENCY);


        return (<div className={classnames("form-group", { "has-error": (errors?.systemDeadline?.systemDeadlineBefore || errors?.systemDeadline?.systemDeadlineFrequency) })}>
            <label>{`${t('BEFORE_ASSIGNED_DEADLINE')}?`}</label>
            <div style={{ paddingTop: '5px' }}>
                <Row>
                    <Col xl={6} className="pr-0">
                        <InputHoc
                            name="annualComplianceRuleMeta.systemDeadlineBefore"
                            inputType={InputTypes.NUMBER_INPUT}
                            minValue={selectedDateType?.minNum ?? 1}
                            maxValue={selectedDateType?.maxNum ?? 365}
                            rules={{ required: true }}
                            control={control}
                            defaultValue={null}
                            className={"none-border-radius-right"}
                        />
                    </Col>
                    <Col xl={6} className="pl-0">
                        <InputHoc
                            name="annualComplianceRuleMeta.systemDeadlineFrequency"
                            inputType={InputTypes.SELECT}
                            options={Object.values(DateOpts).map((v) => v)}
                            control={control}
                            rules={({ required: true })}
                            defaultValue={DateOpts._DAY.value}
                            selectClasses={"none-border-radius-left"}
                            onChange={() => { setValue("annualComplianceRuleMeta.systemDeadlineBefore", "") }}
                        />
                    </Col>
                </Row>
            </div>
        </div>);
    }

    return (<>
        <BrandModal
            modalSize="lg"
            title={(isEditRule ? t("systemConfiguration:EDIT_ANNL_COMP") : t("systemConfiguration:ADD_ANNL_COMP"))}
            isOpen={modal}
            customBody
            toggler={toggle}
            centered
            backdrop='static'
        >
            <form onSubmit={handleSubmit(onSubmit)} ref={_formRef} autoComplete='off'>
                <ModalBody>
                    <Row>
                        <Col xl={6} xs={12}>
                            <InputHoc name="id" type="hidden" control={control} formGroupClass="d-none" />
                            <InputHoc
                                inputType={InputTypes.SELECT}
                                name="jurisdictionId"
                                error={errors?.jurisdictionId}
                                label="JURISDICTION"
                                rules={{ required: 'Jurisdiction is required.' }}
                                control={control}
                                options={jurisdictionOptions}
                                defaultValue={jurisdictionId}
                                readOnly={jurisdictionId}
                            />
                            <InputHoc
                                name="annualComplianceRuleMeta.name"
                                error={errors?.annualComplianceRuleMeta?.name}
                                label="NAME"
                                rules={{ required: true }}
                                control={control}
                                defaultValue=""
                            />
                            <InputHoc
                                inputType={InputTypes.TEXTAREA}
                                name="description"
                                error={errors?.description}
                                label="DESCRIPTION"
                                rules={{ required: true }}
                                control={control}
                                defaultValue=""
                                maxRows={4}
                                style={{ height: 'auto' }}
                            />
                        </Col>
                        <Col xl={6} xs={12}>
                            <Row>
                                <Col xs='12' md='6'>
                                    <InputHoc
                                        name="annualComplianceRuleMeta.frequency"
                                        inputType={InputTypes.SELECT}
                                        options={Object.entries(_RECURRENCE_FREQUENCY_OPTS).map(([k, v], index) => v)}
                                        control={control}
                                        error={errors?.annualComplianceRuleMeta?.frequency}
                                        label={t("systemConfiguration:RECURRENCE_FREQUENCY")}
                                        rules={({ required: 'Recurrence frequency is required.' })}
                                        // defaultValue={rule?.annualComplianceRuleMeta?.frequency ?? 1}
                                        defaultValue={1}
                                        onChange={((value) =>
                                            setRecurrenceFrequency((prevState) => {
                                                var frequency = prevState;
                                                Object.entries(_RECURRENCE_FREQUENCY_OPTS).map(([k, v]) => {
                                                    if (v.value == value) {
                                                        frequency = v;
                                                    }
                                                });
                                                return frequency;
                                            })
                                        )}
                                    />
                                </Col>
                                <Col xs='12' md='6'>
                                    <InputHoc
                                        name="annualComplianceRuleMeta.every"
                                        inputType={InputTypes.NUMBER_INPUT}
                                        minValue={0}
                                        maxValue={100}
                                        rules={{ required: true }}
                                        control={control}
                                        error={errors?.annualComplianceRuleMeta?.every}
                                        label={t("systemConfiguration:EVERY")}
                                        defaultValue={null}
                                        smallnote={frequency}
                                    />
                                </Col>
                            </Row>
                            <strong>{t("FROM")}</strong>
                            <div style={{ paddingTop: '5px' }} className="d-flex-center">
                                {
                                    Object.entries(_RECURRENCE_SOURCE_TYPE).map(([k, v], index) =>
                                        <div key={index} className={classnames("item-row m-r-20", { 'active': selectedRecurrenceSourceType === v.value })} onClick={() => { setSelectedRecurrenceSourceType(v.value) }}>
                                            <Card>
                                                <CardBody style={{ gridTemplateColumns: "auto auto", padding: "6px 12px" }}>
                                                    <div className="chk-item d-flex justify-center"><span className={classnames("check-icon", { 'active': selectedRecurrenceSourceType === v.value })}><i className="fa fa-check-circle"></i></span></div>
                                                    <strong>{v.label}</strong>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )
                                }
                            </div>
                            <Row>
                                <Col xs='12'>
                                    {
                                        selectedRecurrenceSourceType === _RECURRENCE_SOURCE_TYPE.SPECIFIC_DATE.value &&
                                        <InputHoc
                                            name="annualComplianceRuleMeta.repeatStart"
                                            inputType={InputTypes.DATEPICKER}
                                            control={control}
                                            error={errors?.annualComplianceRuleMeta?.repeatStart}
                                            label="CUSTOM_DATE"
                                            rules={({ required: 'Date is required.' })}
                                            minDate={new Date(new Date().getFullYear(), 0, 1)}
                                            showYearDropdown={false}
                                            defaultValue={moment().toDate()}
                                        />
                                    }
                                </Col>
                            </Row>
                            <InputHoc
                                name="isFollowDeadline"
                                inputType={InputTypes.CHECKBOX}
                                label={`${t('FOLLOW_ASSIGNED_DEADLINE')}?`}
                                options={[{ label: "", value: true }]}
                                ref={register()}
                                control={control}
                                defaultValue={null}
                                isSwitcher
                                formGroupClass="mb-0"
                            />
                            {
                                !_WATCH_IS_FOLLOW_DEADLINE && (
                                    renderSystemDeadline()
                                )
                            }
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-themed grayscale-100" onClick={() => toggle()}>{t("CANCEL")}</button>
                    <button type="submit" className="btn btn-themed">{t("SAVE")}</button>
                </ModalFooter>
            </form>
        </BrandModal>
    </>)
}

export default AnnualComplianceRecurrenceRuleModal;
