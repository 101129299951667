import React, { useMemo, useState, useRef, useEffect, createRef, useContext } from "react";
import { Col, Row, UncontrolledTooltip, Button } from 'reactstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import Notiflix, { Block } from 'notiflix';
import { PageSettings } from 'config/page-settings';
import { Can } from 'config/user-access';
import { ApiUrl, FileType, BtnTypes, deleteConfirm, LoadingStateText, Icon, InputTypes, TableId } from 'util/Constant';
import ButtonRound from 'components/buttons/ButtonRound';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import ReactTable from "components/react-table/ReactTable";
import RoleModal from "./RoleModal";
import RoleAssignPolicyModal from "./RoleAssignPolicyModal";
import RoleUsersInRoleModal from "./RoleUsersInRoleModal";
import useSWR from 'swr';
import { PolicyActionConstant, PolicyObjectConstant } from "../../util/Constant";
import { useRecoilValue } from "recoil";
import { userIdSelector } from "recoil/Atoms";

///<summary>
///Author: Robin
///</summary>
const RoleManagement = props => {
    const { t } = useTranslation();
    const { isXlDevices } = useContext(PageSettings);

    const _filterFormRef = useRef();
    const { ref: wrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const [refreshTable, setRefreshTable] = useState(false);
    const userId = useRecoilValue(userIdSelector);

    // Modal
    const [role, setRole] = useState({});
    const [modal, setModal]= useState(false);
    const [assignPolicyModal, setAssignPolicyModal]= useState(false);
    const [usersInRoleModal, setUsersInRoleModal]= useState(false);
    const [usersInRole, setUsersInRole]= useState([]);

    const _SEARCH_FORMS = useMemo(() => {

        const _FORM_FIELDS = [
            {
                rowOptions: { xl: 6 },
                columns: [
                    { label: t('SEARCH_NAME'), name: 'name', placeholder: t("SEARCH_NAME"), input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                    { label: t('SEARCH_DESCRIPTION'), name: 'description', placeholder: t("SEARCH_DESCRIPTION"), input: InputTypes.INPUT, columnOptions: { xl: 3, md: 6 } },
                ]
            }
        ];

        return _FORM_FIELDS;

    }, []);

    const _ROLE_COLUMN = useMemo(() => [
        {
            id: "Name",
            Header: "systemConfiguration:ROLE_NAME",
            accessor: "name",
            Cell: ({ row }) => {
                if (row.original.name) {
                    return (<>
                        <div style={{width: '25vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            <span id={`role-name-ctrl-tooltip-${row.original.id}`}>{row.original.name}</span>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="bottom-right" target={`role-name-ctrl-tooltip-${row.original.id}`}>{row.original.name}</UncontrolledTooltip>
                    </>);
                } else {
                    return <> - </>
                }
            },
            isRequiredColumn: true
        },
        {
            id: "Description",
            Header: "DESCRIPTION",
            accessor: "description",
            Cell: ({ row }) => {
                if (row.original.description) {
                    return (<>
                        <div style={{width: '40vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            <span id={`role-desrc-ctrl-tooltip-${row.original.id}`}>{row.original.description}</span>
                        </div>
                        <UncontrolledTooltip innerClassName="tooltip-qcdoc" placement="bottom-right" target={`role-desrc-ctrl-tooltip-${row.original.id}`}>{row.original.description}</UncontrolledTooltip>
                    </>);
                } else {
                    return <> - </>
                }
            }
        },
        {
            id: "UsersInRole",
            Header: "systemConfiguration:USERS_IN_ROLE",
            accessor: "userCount",
            Cell: ({ row }) => {
                if (row.original.userCount) {
                    return <Button color="link" onClick={() => {
                        setUsersInRoleModal(!usersInRoleModal);
                        setUsersInRole(row.original.usersInRole);
                    }}>{row.original.userCount}</Button>
                } else {
                    return <Button color="none">0</Button>;
                }
            }
        },
        {
            id: "Action",
            Header: "ACTION",
            accessor: "action",
            Cell: ({ row }) => {
                return (<>
                    <div className="m-b-5 d-flex">
                        <ButtonRound className="mr-2" type={BtnTypes.EDIT} small onClick={() => {
                            setRole(row.original);
                            setModal(true);
                        }} />

                        <ButtonRound className="mr-2" type={BtnTypes.VIEW} small onClick={() => {
                            setRole(row.original);
                            setAssignPolicyModal(true);
                        }} />
                        
                    </div>
                </>);
            },
            disableSortBy: true,
            isRequiredColumn: true
        }
    ], []);

    const filterOnSubmit = (filtersValue) => {
        setFiltersForm(filtersValue);
    }

    const resetFiltersFormValue = () => {
        setResetFilters(!resetFilters);
        setFiltersForm({});
    };

    const fixedContentHeight = useMemo(() => {
        if (wrapperRef.current != null) {
            return wrapperRef.current.clientHeight;
        }
    }, [wrapperRef?.current]);

    return (<>
        <div className="panel panel-brand panel-flex" id="role_management">
            <div className="panel-header p-b-0">
                <h1 className="page-header title title-with-dot">{t("ROLE_MANAGEMENT")}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className={classNames(
                    'panel-body-inner-content with-br-radius',
                    {
                        'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices,
                    },
                )}>
                    <div className="d-flex flex-column h-100">
                        <div className="filter-panel-container border-radius-none" >
                            <Row className="filter-panel-body p-t-15">
                                <Col xl={8}>
                                    <FormBuilder
                                        fields={_SEARCH_FORMS}
                                        formRef={_filterFormRef}
                                        onSubmit={filterOnSubmit}
                                        reset={resetFilters}
                                        resetTriggerReset={setResetFilters}
                                        resetValues={{}}
                                    />
                                </Col>
                                <Col xl={4} className="btn-list d-flex justify-content-end">
                                    <button className="btn btn-themed btn-brand-round" onClick={() => submitForm(_filterFormRef)}><i className="las la-search" /></button>
                                    <button className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1" ref={wrapperRef}>
                            <div style={{ height: fixedContentHeight }}>
                                <ReactTable
                                    enableSearch
                                    columns={_ROLE_COLUMN}
                                    url={ApiUrl._API_GET_ROLE_LIST}
                                    refreshTable={refreshTable}
                                    filterFormfield={filtersForm}
                                    setPagination
                                    customButtons={
                                        <button type="button" className="btn btn-sm pull-right btn-themed tbl-custom-brand-btn" onClick={() => {
                                            setModal(true);
                                            setRole({});
                                        }}><i className="fas fa-plus"></i>&nbsp;&nbsp;{t("systemConfiguration:NEW_ROLE")}</button>
                                    }
                                    tableColumnPreference={[userId, TableId._ROLE_MANAGEMENT]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RoleModal
            modal={modal}
            setModal={setModal}
            role={role}
            setRole={setRole}
            setRefreshTable={setRefreshTable}
        />
        <RoleAssignPolicyModal
            modal={assignPolicyModal}
            setModal={setAssignPolicyModal}
            role={role}
            setRole={setRole}
        />
        <RoleUsersInRoleModal
            modal={usersInRoleModal}
            setModal={setUsersInRoleModal}
            usersInRole={usersInRole}
            setUsersInRole={setUsersInRole}
        />
    </>);
}

export default RoleManagement;
