import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { useTable, useFilters, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce, useRowSelect } from 'react-table';
import { useTranslation, Translation } from 'react-i18next';
import DataAccessObject from "../../data/DataAccessObject";
import { ApiKey, ApiUrl, LoadingStateText, SweetAlert } from 'util/Constant';
import { createMultiPartFormBody, queryStringUrlToObj, stringIsNullOrEmpty } from 'util/Utility';
import { Block, Report } from 'notiflix';
import _, { isEmpty } from 'lodash';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { findByDisplayValue } from '@testing-library/dom';
import { v4 as uuidv4 } from 'uuid';
import { useResizeDetector } from "react-resize-detector";
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';

const GlobalFilter = ({ preGlobalFilteredRows, setGlobalFilter, value, setValue, gotoPage }) => {
    const { t } = useTranslation();
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined); gotoPage(0); }, 200)

    return (
        <div className="form-group d-inline-block ml-4 m-t-auto m-b-auto">
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${t("FILTER")} ${count} ${t("ENTRIES")}...`}
                style={{ fontSize: '1rem' }}
                className="form-control d-inline"
            />
        </div>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const [checkboxId, setCheckboxId] = useState(uuidv4());
        const defaultRef = useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (<div className="react-table-checkbox">
            <div className="round-checkbox" onClick={(e) => {
                e.stopPropagation();
            }}>
                <input id={checkboxId} type="checkbox" ref={resolvedRef} {...rest} />
                <label for={checkboxId}></label>
                <label for={checkboxId}>{rest.label}</label>
            </div>
        </div>
        )
    }
)

/// <summary>
/// Author: Chris
/// </summary>
const ReactTable = (props) => {
    let { columns, data,
        getFilteredRecords,
        getRowProps = () => ({}),
        setCheckboxIds,
        enableSearch,
        setPagination,
        url, initialPageSize,
        filterFormfield, retrievedCallback,
        initialSearchParam,
        refreshTable, useMultipartForm, fetchMethod,
        className,
        setCustomFooters,
        enableCheckbox,
        customButtons,
        tblFooterClass,
        pageFooterClass,
        isInheritHeight,
        tableColumnPreference
    } = props;

    const { t } = useTranslation();
    const [tableBodyId, setTableBodyId] = useState(uniqueId('reactTableBody-'));
    const [fetchUrl, setFetchUrl] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const [tablePageCount, setTablePageCount] = useState(0);
    const [pageRange, setPageRange] = useState([]);
    const _PAGE_RANGE = 6;
    const _PAGINATION_OPTIONS = [5, 10, 20, 30, 40, 50];
    const _INITIAL_PAGESIZE = initialPageSize ? initialPageSize : _PAGINATION_OPTIONS[1];
    const [globalFilterValue, setGlobalFilterValue] = useState();

    const { height: theadHeight, ref: theadRef } = useResizeDetector();
    const { height: tfootHeight, ref: tfootRef } = useResizeDetector();
    const { height: wrapperHeight, ref: wrapperRef } = useResizeDetector();
    const [afterCallApi, setAfterCallApi] = useState(false);
    const [afterInitHidden, setAfterInitHidden] = useState(false);

    const tbodyHeight = useMemo(() => {
        if (theadHeight && tfootHeight && wrapperHeight) {
            return wrapperHeight - theadHeight - tfootHeight;
        }
    }, [theadHeight, tfootHeight, wrapperHeight])

    const _INIT_TABLE_PROPS = !stringIsNullOrEmpty(url) ?
        {
            columns,
            data: fetchedData,
            initialState: { pageIndex: 0, pageSize: _INITIAL_PAGESIZE },
            autoResetPage: false,
            manualPagination: true,
            pageCount: tablePageCount,
        } : {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: initialPageSize ? initialPageSize : 10, globalFilter: globalFilterValue },
            autoResetPage: false,
        }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        page,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds, hiddenColumns },
        allColumns,
        getToggleHideAllColumnsProps,
        setHiddenColumns,
        toggleHideColumn,
        visibleColumns
    } = useTable(
        { ..._INIT_TABLE_PROPS },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => {
                let checkboxCol = {};
                if (enableCheckbox) {
                    checkboxCol = {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="check-all-checkbox" />
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                        width: "5%",
                        disableSortBy: true,
                    }
                }
                return isEmpty(checkboxCol) ? [...columns] : [checkboxCol, ...columns];
            })
        }
    )

    const headerProps = (props, { column }) => [
        props,
        column.getSortByToggleProps(),
        {
            className: column.headerClassName,
            style: {
                ...column.headerStyle,
            },
            width: column.width
        },
    ];

    const cellProps = (props, { cell }) => [
        props,
        {
            style: {
                ...cell.column.style,
                ...cell.style
            },
            className: cell.column.className
        }
    ];

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdownOpen = () => setDropdownOpen(prev => !prev);
    const fixedTableHeight = useMemo(() => {
        if (setPagination && enableSearch) {
            return (wrapperHeight > 150 && !isInheritHeight) ? (wrapperHeight - (page.length > 0 ? 50 : 0) - 50) : 'inherit'
        }
        else if (setPagination) {
            return (wrapperHeight > 150 && !isInheritHeight) ? (wrapperHeight - (page.length > 0 ? 50 : 0)) : 'inherit'
        }
        else if (enableSearch) {
            return (wrapperHeight > 150 && !isInheritHeight) ? (wrapperHeight - 50) : 'inherit'
        }
    }, [setPagination, enableSearch, isInheritHeight, wrapperHeight, page.length]);

    const columnsChecked = useMemo(() => {
        return allColumns.filter(c => !c.isRequiredColumn).length === visibleColumns.filter(c => !c.isRequiredColumn).length;
    }, [allColumns, visibleColumns]);

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    React.useEffect(() => {
        if (allColumns) {
            let tempHiddenColumns = [];
            let isDefaultHiddenColumns = true;
            if (tableColumnPreference?.[0] && tableColumnPreference?.[1]) {
                let columnPreferenceValues = localStorage.getItem(tableColumnPreference);
                if (!isEmpty(columnPreferenceValues)) {
                    isDefaultHiddenColumns = false;
                    columnPreferenceValues = columnPreferenceValues.split(",");
                    allColumns.map((col, index) => {
                        if (columnPreferenceValues?.[index] === "true" && !col.isRequiredColumn) {
                            tempHiddenColumns.push(col.id);
                        }
                    });
                }
            }
            if (isDefaultHiddenColumns) {
                allColumns.map(col => {
                    if (col.isDefaultHidden === true) {
                        tempHiddenColumns.push(col.id);
                    }
                });
            }
            setHiddenColumns(tempHiddenColumns);
            !afterInitHidden && setAfterInitHidden(true);
        }
    }, [setHiddenColumns, tableColumnPreference, allColumns]);

    /// <summary>
    /// Author: Sze Hua
    /// </summary>
    React.useEffect(() => {
        if (tableColumnPreference?.[0] && tableColumnPreference?.[1] && allColumns && afterInitHidden) {
            let tempHiddenColumns = allColumns.map(col => hiddenColumns.includes(col.id));
            localStorage.setItem(tableColumnPreference, tempHiddenColumns);
        }
    }, [tableColumnPreference, hiddenColumns]);

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const getFetchUrl = useCallback((pageIndex, pageSize) => {
        var queryParams = { page: pageIndex, pageSize: pageSize, v: Date.now() }
        filterFormfield = _.pickBy(filterFormfield, (v) => v !== null && v !== undefined && !stringIsNullOrEmpty(v));
        var newUrl = `${url}?${new URLSearchParams({ ...queryParams, ...filterFormfield, ...initialSearchParam })}`;
        return newUrl;
    }, [filterFormfield]);


    /// <summary>
    /// Author: Lewis
    /// </summary>
    const pagingHandler = {
        toLastPage: () => {
            if (!stringIsNullOrEmpty(url)) {
                setFetchUrl(() => { return getFetchUrl(pageCount, pageSize) });
            }
            gotoPage(pageCount - 1);
        },
        toFirstPage: () => {
            if (!stringIsNullOrEmpty(url)) {
                setFetchUrl(() => { return getFetchUrl(1, pageSize) });
            }
            gotoPage(0);
        },
        pageBackCallback: () => {
            if (!stringIsNullOrEmpty(url)) {
                setFetchUrl(() => { return getFetchUrl(pageIndex, pageSize) });
            }
            previousPage();
        },
        pageNextCallback: () => {
            if (!stringIsNullOrEmpty(url)) {
                setFetchUrl(() => { return getFetchUrl(pageIndex + 2, pageSize) });
            }
            nextPage();
        },
        goToPageCallback: (clickedPageIndex) => {
            if (!stringIsNullOrEmpty(url)) {
                setFetchUrl(() => { return getFetchUrl(clickedPageIndex, pageSize) });
            }
            gotoPage(clickedPageIndex - 1);
        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const fetchTableData = async (url) => {

        let dao = new DataAccessObject();

        if (fetchMethod == ApiKey._API_POST && useMultipartForm) {
            var formBody = { ...queryStringUrlToObj(url), ...filterFormfield };
            let baseUrl = url.split('?')[0];

            formBody = createMultiPartFormBody(formBody);
            await dao.postForm(baseUrl, formBody).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setFetchedData(responseJson[ApiKey._API_DATA_KEY]);
                    if (responseJson?.[ApiKey._API_DATA_COUNT_KEY]) {
                        setTablePageCount(Math.ceil(responseJson[ApiKey._API_DATA_COUNT_KEY] / pageSize));
                    }

                    if (typeof (retrievedCallback) == "function") {
                        retrievedCallback(responseJson[ApiKey._API_DATA_KEY], responseJson[ApiKey._API_DATA_COUNT_KEY]);
                    }
                    return
                }
                Report.Failure(responseJson[ApiKey._API_MESSAGE_KEY], responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t("FAILED_TO_RETRIEVE"), t(SweetAlert._BACK));
            }).finally(() => Block.Remove(`tbody#${tableBodyId}`));
        }
        else if (fetchMethod == ApiKey._API_GET) {
            Block.Circle(`tbody#${tableBodyId}`, LoadingStateText._PLEASE_WAIT);
            await dao.get(url).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var data = responseJson?.[ApiKey._API_DATA_KEY] ? responseJson[ApiKey._API_DATA_KEY] : [];
                    setFetchedData(data);
                    if (responseJson?.[ApiKey._API_DATA_COUNT_KEY]) {
                        setTablePageCount(Math.ceil(responseJson[ApiKey._API_DATA_COUNT_KEY] / pageSize));
                    }

                    if (typeof (retrievedCallback) == "function") {
                        retrievedCallback(responseJson[ApiKey._API_DATA_KEY], responseJson[ApiKey._API_DATA_COUNT_KEY]);
                    }
                    return
                }
                Report.Failure(responseJson[ApiKey._API_MESSAGE_KEY], responseJson[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? t("FAILED_TO_RETRIEVE"), t(SweetAlert._BACK));
            }).finally(() => {
                Block.Remove(`tbody#${tableBodyId}`);
                if (!afterCallApi)
                    setAfterCallApi(true);
            });

        }
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    const paginationNavBtns = (pageOptionsLength, pageIndex) => {
        let start = Math.max(1, (pageIndex + 1) - Math.floor(_PAGE_RANGE / 2));
        let end = start + _PAGE_RANGE;
        if (end >= pageOptionsLength) {
            let diff = end - pageOptionsLength;
            start = Math.max(start - diff, 1);
            end = pageOptionsLength;
        }
        let pgRange = range(start, end);
        setPageRange(pgRange);
    }

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(url)) {
            paginationNavBtns(tablePageCount, pageIndex);
        }
        else {
            paginationNavBtns(pageOptions.length, pageIndex);
        }
    }, [pageIndex, tablePageCount, pageOptions]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(url) || url != undefined) {
            var queryParams = { page: 1, pageSize: pageSize }
            setFetchUrl(`${url}?` + new URLSearchParams(queryParams));
        }
    }, [url]);

    /// <summary>
    /// Author: Lewis
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(url)) {
            setFetchUrl(() => { return getFetchUrl(pageIndex + 1, pageSize) });
        }
        if (!url && data)
            setTablePageCount(Math.ceil(data.length / pageSize));
    }, [pageSize]);

    useEffect(() => {
        if (!url && data) {
            setFetchedData(data);
            setTablePageCount(Math.ceil(data.length / pageSize));
            if (!afterCallApi)
                setAfterCallApi(true);
        }
    }, [data]);

    /// <summary>
    /// Author: Lewis
    /// if url is proveded, then fetch from backend / trigger fetch data after adding new row in backend
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(fetchUrl)) {
            fetchTableData(fetchUrl);
        }
    }, [fetchUrl, refreshTable]);

    /// <summary>
    /// Author: Lewis
    /// trigger when filter form field changes
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(fetchUrl)) {
            setFetchUrl(() => `${getFetchUrl(1, pageSize)}`);
            gotoPage(0);
        }
    }, [filterFormfield]);

    useEffect(() => {
        if (typeof (setCheckboxIds) == "function" && enableCheckbox) {
            setCheckboxIds(selectedFlatRows.map(j => j.original.id));
        }
    }, [selectedRowIds, setCheckboxIds]);

    return (
        <div id="reactTable"
            className={"table-responsive " +
                classnames({ [className]: className })}
            ref={wrapperRef}
        // style={{ height: "inherit" }}
        >
            {/* DEBUG PURPOSE
            <code>
                {JSON.stringify(
                    {
                        pageRange,
                        pageIndex,
                        pageSize,
                        pageCount,
                        canNextPage,
                        canPreviousPage,
                    },
                    null,
                    2
                )}
            </code> */}
            {setPagination && enableSearch && (
                <>
                    <div className="tr-filter menu-header">
                        <div className="d-flex justify-content-between" style={{ lineHeight: "40px" }}>
                            <div className="d-flex">
                                {
                                    page.length > 0 ? (
                                        <div className="d-inline form-group m-t-auto m-b-auto">
                                            {t("SHOW")}&nbsp;
                                            <select className="form-control d-inline"
                                                value={pageSize}
                                                onChange={e => {
                                                    setPageSize(Number(e.target.value))
                                                }}
                                                style={{
                                                    width: 'auto',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {_PAGINATION_OPTIONS.map(pageSize => (
                                                    // Highlight colour is 'browser-specific'
                                                    <option key={pageSize} value={pageSize}>
                                                        {pageSize}
                                                    </option>
                                                ))}
                                            </select>
                                            &nbsp;&nbsp;{t("ENTRIES")}
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                {enableSearch && (
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        setGlobalFilter={setGlobalFilter}
                                        value={globalFilterValue}
                                        setValue={setGlobalFilterValue}
                                        gotoPage={gotoPage}
                                    />
                                )}
                            </div>
                            <div className="d-flex flex-center">
                                {customButtons && (<div>
                                    {customButtons}
                                </div>)}
                                <ButtonDropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdownOpen}
                                    className="ml-2"
                                >
                                    <DropdownToggle className="btn btn-rounded btn-themed"><i className="fa fa-ellipsis-v"></i></DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-columns" right>
                                        <div className="dropdown-item-content-wrapper y-scrollbar-2 x-scrollbar-2">
                                            <DropdownItem toggle={false} type="button">
                                                <IndeterminateCheckbox
                                                    label={t('ALL_COLUMN')}
                                                    onChange={() => {
                                                        allColumns.filter(c => !c.isRequiredColumn).map(c => toggleHideColumn(c.id, columnsChecked))
                                                    }}
                                                    value={columnsChecked}
                                                    checked={columnsChecked}
                                                />
                                            </DropdownItem>
                                            {/* <DropdownItem toggle={false}>
                                                <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} label={"All columns"} />
                                            </DropdownItem> */}
                                            <hr className="mt-1 mb-2" />
                                            {
                                                allColumns.map((c) => (
                                                    typeof (c.render('Header')) !== "object" &&
                                                    <DropdownItem toggle={false} type="button">
                                                        <IndeterminateCheckbox {...!c.isRequiredColumn ? c.getToggleHiddenProps() : { value: true, checked: true, disabled: true }} label={t(c.Header) + (c.isRequiredColumn ? "*" : "")} />
                                                    </DropdownItem>
                                                ))
                                            }
                                        </div>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="fixed-table-container-inner y-scrollbar-2 x-scrollbar-2" style={{ height: fixedTableHeight }}>
                <div className="reactTable-header"></div>
                <table {...getTableProps()} className={classNames("table table-td-valign-middle dataTable h-100")}>
                    <thead ref={theadRef}>
                        {
                            // setPagination && enableSearch &&
                            // <>
                            //     <tr className="tr-filter">
                            //         {/* col span + 1 because of visble checkbox row */}
                            //         <th
                            //             colSpan={enableCheckbox ? columns.length + 1 : columns.length}
                            //             style={{ border: 'none' }}
                            //         >
                            //             <div className="d-flex justify-content-between" style={{ lineHeight: "40px" }}>
                            //                 <div className="d-flex">
                            //                     {
                            //                         page.length > 0 ? (
                            //                             <div className="d-inline form-group m-t-auto m-b-auto">
                            //                                 {t("SHOW")}&nbsp;
                            //                                 <select className="form-control d-inline"
                            //                                     value={pageSize}
                            //                                     onChange={e => {
                            //                                         setPageSize(Number(e.target.value))
                            //                                     }}
                            //                                     style={{
                            //                                         width: 'auto',
                            //                                         fontSize: '0.9rem',
                            //                                     }}
                            //                                 >
                            //                                     {_PAGINATION_OPTIONS.map(pageSize => (
                            //                                         // Highlight colour is 'browser-specific'
                            //                                         <option key={pageSize} value={pageSize}>
                            //                                             {pageSize}
                            //                                         </option>
                            //                                     ))}
                            //                                 </select>
                            //                                 &nbsp;&nbsp;{t("ENTRIES")}
                            //                             </div>
                            //                         ) : (
                            //                             <></>
                            //                         )
                            //                     }
                            //                     {enableSearch &&
                            //                         <GlobalFilter
                            //                             preGlobalFilteredRows={preGlobalFilteredRows}
                            //                             setGlobalFilter={setGlobalFilter}
                            //                             value={globalFilterValue}
                            //                             setValue={setGlobalFilterValue}
                            //                             gotoPage={gotoPage}
                            //                         />
                            //                     }
                            //                 </div>
                            //                 {customButtons && <div>
                            //                     {customButtons}
                            //                 </div>}
                            //             </div>
                            //         </th>
                            //     </tr>
                            // </>
                        }

                        {headerGroups.map(headerGroup => (
                            <tr className="tr-column" {...headerGroup.getHeaderGroupProps()} style={{ top: enableSearch ? 53.5 : 0, top: 0 }}
                            >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(headerProps)} className="th-brand-col no-select">
                                        {typeof (column.render('Header')) !== "object" ? t(column.render('Header')) : column.render('Header')}
                                        {column.disableSortBy == true ? null :
                                            <span style={{ marginLeft: '5%' }}>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <i className="fas fa-sort-down" style={{ marginLeft: '3px' }} />
                                                        : <i className="fas fa-sort-up" style={{ marginLeft: '3px' }} />
                                                    : <i className="fas fa-sort" style={{ marginLeft: '3px' }} />
                                                }
                                            </span>
                                        }
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} id={tableBodyId} style={{ height: tbodyHeight }}>
                        {
                            rows.length > 0 ? (
                                setPagination ?
                                    page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps(getRowProps(row))}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps(cellProps)}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    }) :
                                    rows.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps(getRowProps(row))}>
                                                {row.cells.map(cell => {
                                                    return <td  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                    })
                            ) :
                                afterCallApi && (
                                    <tr>
                                        <td colSpan={enableCheckbox ? columns.length + 1 : columns.flatMap(c => c.columns ?? c).length}>
                                            <div className="d-flex flex-column">
                                                <img alt="" className="no-data-found-gph" src={require("../../assets/img/ui/graphic-nodatafound.svg")} />
                                                <p className="no-data-found-text">{t("NO_DATA_AVAILABLE")}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                        {
                            (rows.length < pageSize) &&
                            <tr style={{ height: "100%" }}>
                                <td colSpan={columns.length + (enableCheckbox ? 1 : 0)}></td>
                            </tr>
                        }
                    </tbody>
                    <tfoot className={classNames("react-tbl-footer", { [tblFooterClass]: tblFooterClass })} ref={tfootRef} style={{ backgroundColor: rows.length === 0 && "white" }}>
                        {(rows.length > 0 && setCustomFooters) &&
                            footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        column.Footer.name !== 'emptyRenderer' && (
                                            <td style={{ backgroundColor: "white" }} {...column.getFooterProps()}>{column.render('Footer')}</td>
                                        )
                                    ))}
                                </tr>
                            ))
                        }
                        {
                            // setPagination &&
                            // footerGroups.map((group, index) => (
                            //     <React.Fragment key={index}>
                            //         <tr {...group.getFooterGroupProps()}>
                            //             {
                            //                 page.length > 0 && <>
                            //                     <td colSpan={enableCheckbox ? columns.length + 1 : columns.length}>
                            //                         <span className="text-left">
                            //                             {
                            //                                 canPreviousPage &&
                            //                                 <button className="btn btn-sm btn-pagination skip-all left-left" onClick={() => pagingHandler.toFirstPage()} disabled={!canPreviousPage}>
                            //                                     <i className="fas fa-angle-double-left"></i>
                            //                                 </button>
                            //                             }
                            //                             {/* <button className="btn btn-sm" onClick={() => pagingHandler.pageBackCallback()} disabled={!canPreviousPage}>
                            //                                 <i className="fas fa-angle-left fas-2x"></i>
                            //                             </button> */}
                            //                             {
                            //                                 pageRange.map((pageI, index) => {
                            //                                     return (
                            //                                         <button key={index} className={"btn btn-sm btn-pagination" + (pageIndex === (pageI - 1) ? " active" : "")} onClick={() => pagingHandler.goToPageCallback(pageI)} >
                            //                                             {pageI}
                            //                                         </button>
                            //                                     )
                            //                                 })
                            //                             }
                            //                             {/* <button className="btn btn-sm btn-pagination" onClick={() => pagingHandler.pageNextCallback()} disabled={!canNextPage}>
                            //                                 <i className="fas fa-angle-right fas-2x"></i>
                            //                             </button> */}
                            //                             {
                            //                                 canNextPage &&
                            //                                 <button className="btn btn-sm btn-pagination skip-all right-right" onClick={() => pagingHandler.toLastPage()} disabled={!canNextPage}>
                            //                                     <i className="fas fa-angle-double-right"></i>
                            //                                 </button>
                            //                             }
                            //                         </span>
                            //                         <span className="m-l-20 no-select" style={{ color: "#8695A0" }}>
                            //                             {t("PAGE")}{' '}
                            //                             <strong>
                            //                                 {pageIndex + 1} {t("OF")} {pageOptions.length}
                            //                             </strong>
                            //                         </span>
                            //                     </td>
                            //                 </>
                            //             }
                            //         </tr>
                            //     </React.Fragment>
                            // ))
                        }
                    </tfoot>
                </table>
            </div>
            {setPagination && page.length > 0 && (
                <div className={classNames({ [pageFooterClass]: pageFooterClass })} style={{ height: 50 }}>
                    <div className="h-100 d-flex flex-align-center" style={{ padding: 8 }}>
                        <span className="text-left">
                            {
                                canPreviousPage &&
                                <button className="btn btn-sm btn-pagination skip-all left-left" onClick={() => pagingHandler.toFirstPage()} disabled={!canPreviousPage}>
                                    <i className="fas fa-angle-double-left"></i>
                                </button>
                            }
                            {/* <button className="btn btn-sm" onClick={() => pagingHandler.pageBackCallback()} disabled={!canPreviousPage}>
                                                            <i className="fas fa-angle-left fas-2x"></i>
                                                        </button> */}
                            {
                                pageRange.map((pageI, index) => {
                                    return (
                                        <button key={index} className={"btn btn-sm btn-pagination" + (pageIndex === (pageI - 1) ? " active" : "")} onClick={() => pagingHandler.goToPageCallback(pageI)} >
                                            {pageI}
                                        </button>
                                    )
                                })
                            }
                            {/* <button className="btn btn-sm btn-pagination" onClick={() => pagingHandler.pageNextCallback()} disabled={!canNextPage}>
                                                            <i className="fas fa-angle-right fas-2x"></i>
                                                        </button> */}
                            {
                                canNextPage &&
                                <button className="btn btn-sm btn-pagination skip-all right-right" onClick={() => pagingHandler.toLastPage()} disabled={!canNextPage}>
                                    <i className="fas fa-angle-double-right"></i>
                                </button>
                            }
                        </span>
                        <span className="m-l-20 no-select" style={{ color: "#8695A0" }}>
                            {t("PAGE")}{' '}
                            <strong>
                                {pageIndex + 1} {t("OF")} {pageOptions.length}
                            </strong>
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

ReactTable.defaultProps = {
    data: [],
    initialSearchParam: {},
    globalFilterable: true,
    filterFormfield: {},
    enableSearch: false,
    setPagination: false,
    setCustomFooters: false,
    refreshTable: false,
    useMultipartForm: false,
    retrievedCallback: () => { return },
    fetchMethod: ApiKey._API_GET,
    url: null,
    enableCheckbox: false,
    tblFooterClass: "",
}

ReactTable.propTypes = {
    data: PropTypes.array,
    initialSearchParam: PropTypes.object,
    globalFilterable: PropTypes.bool,
    filterFormfield: PropTypes.object,
    enableSearch: PropTypes.bool,
    setPagination: PropTypes.bool,
    setCustomFooters: PropTypes.bool,
    refreshTable: PropTypes.bool,
    useMultipartForm: PropTypes.bool,
    retrievedCallback: PropTypes.func,
    fetchMethod: PropTypes.string,
    url: PropTypes.string,
    enableCheckbox: PropTypes.bool,
    tblFooterClass: PropTypes.string,
}


export default ReactTable;