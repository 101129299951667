import React, { forwardRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { generateRandString } from 'util/Utility';
import Input from './Input';

/// <summary>
/// Author: Chris
/// </summary>
const Radio = forwardRef((props, ref) => {
    const {
        options,
        name,
        className,
        defaultValue,
        inline,
        radioLabelClasses,
        ...otherProps
    } = props;
    const radioClasses = classnames('radio radio-css m-b-10',
        className, { 'radio-inline': inline }
    );
    const [checkboxValues, setCheckboxValues] = useState([]);

    /// <summary>
    /// Author: Chris
    /// </summary>
    const handleOnChange = (index) => {
        let newCheckboxValues = options.reduce((finalItem, item, optIndex) => {
            let tempArr = finalItem;

            tempArr.push(optIndex == index);

            return tempArr;
        }, []);

        setCheckboxValues([...newCheckboxValues]);
    };

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        setCheckboxValues(options.reduce((finalItem, item) => {
            let tempArr = finalItem;
            tempArr.push(item.value == defaultValue);
            return tempArr;
        }, []));
    }, [defaultValue]);

    return <>
        {
            options.map((item, key) => {
                let id = `${name}-${generateRandString()}`;

                return (
                    <div key={key} className={radioClasses}>
                        <input ref={ref} id={id} name={name} value={item.value} checked={checkboxValues[key]} onChange={() => handleOnChange(key)} {...otherProps} />
                        <label htmlFor={id} className={radioLabelClasses}>{item.label}</label>
                    </div>
                );
            })
        }
    </>;
});

Radio.defaultProps = {
    className: '',
    type: 'radio'
}

export default Radio;