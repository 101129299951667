import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';
import { PermissionControl } from 'util/Constant';
import _, { isEmpty } from 'lodash';
import { PermissionPolicyDao } from 'data';

/// <summary>
/// Author: Lewis : custom hook for user access control & policies
/// </summary>
export const useUserAccessControl = () => {

    const defineRulesFor = (authCredential) => {
        const { can, cannot, rules } = new AbilityBuilder(Ability);

        if (!isEmpty(authCredential)) {
            let { permissions, role, ...rest } = authCredential;

            if (role?.casbinName === "role_superuser") {
                // Manage and all is wildcard for CASL
                can('manage', 'all');
            } else {
                if (!isEmpty(permissions)) {
                    permissions.forEach((item, index) => {
                        can(item.action, item.object)
                    })
                };
            }
        }
        return rules;
    }

    /// <summary>
    /// Author: Lewis
    /// Update by Robin
    /// </summary>
    const buildAbilityFor = (authCredential) => {
        return new Ability(defineRulesFor(authCredential));
    }

    return { buildAbilityFor }
}


