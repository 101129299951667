import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/// <summary>
/// Author: Chris
/// </summary>
class AdminDao extends DataAccessObject {

    async getAdminList() {
        return this.get(ApiUrl._API_GET_ADMIN_LIST);
    }

    async createOrUpdateAdmin(formData) {
        const { id } = formData;
        if (id) {
            return this.put(ApiUrl._API_UPDATE_ADMIN.replace(":adminId", id), formData);
        }
        else {
            return this.post(ApiUrl._API_CREATE_OR_UPDATE_ADMIN, formData);
        }
    }

    async getAdminById(userGuid) {
        return this.get(ApiUrl._API_CREATE_OR_UPDATE_ADMIN + `/${userGuid}`)
    }

}

export default AdminDao;
