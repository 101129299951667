import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import Notiflix, { Block, Confirm, Notify, Report } from "notiflix";
import { InputTypes, SelectField, WebUrl, deleteConfirm, ApiKey, BtnTypes, LoadingStateText, SweetAlert } from "util/Constant";
import { getCountryFlagIconByJurisdictionName, getFullUrl } from 'util/Utility';
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import { useHistory } from "react-router";
import { JurisdictionDao, CurrencyDao } from "data";
import { common } from "@material-ui/core/colors";
import { stringIsNullOrEmpty } from "util/Utility.js";
import ButtonRound from "components/buttons/ButtonRound";
import BrandModal from 'components/modals/BrandModal';
import { useTranslation } from 'react-i18next';
import { currenciesState } from 'recoil/Atoms.js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

///<summary>
///Author: Ong Sze Hua
///</summary>
const JurisdictionManagement = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const [modal, setModal] = useState(false);
    const [jurisdictionList, setJurisdictionList] = useState([]);
    // const currencyOpts = useRecoilValue(currenciesState);
    const [jurisdiction, setJurisdiction] = useState();
    const [reset, setReset] = useState(false);
    const formRef = useRef();

    let jurisdictionDao = new JurisdictionDao();

    const _FIELDS = useMemo(() => [
        {
            rowOptions: { xl: 1 },
            columns: [
                { label: t('systemConfiguration:FULL_NAME'), name: "name", input: InputTypes.INPUT, rules: { required: 'Full Name is required' } },
                { label: t('systemConfiguration:ABBREVIATION_NAME'), name: "shortName", input: InputTypes.INPUT, rules: { required: 'Abbreviation Name is required' } },
                // { label: t('CURRENCY'), name: "currencyId", input: InputTypes.SELECT, options: currencyOpts, rules: { required: 'Currency is required' } },
            ]
        }
    ], []);

    const _COLUMN = useMemo(() => [
        {
            id: "Full Name",
            Header: 'systemConfiguration:FULL_NAME',
            accessor: "name"
        },
        {
            id: "Abbreviation Name",
            Header: 'systemConfiguration:ABBREVIATION_NAME',
            accessor: "shortName",
            Cell: ({ row }) => {
                return <div className="d-flex">
                    <div className={`countries country-flag-${row.original.shortName.toLowerCase()}`}></div>
                    <div className="m-auto m-l-5">
                        <span>{row.original.shortName}</span>
                    </div>
                </div>
            }
        },
        // {
        //     id: "Currency Code",
        //     Header: 'systemConfiguration:CURRENCY_CODE',
        //     accessor: el => el?.currency?.code,
        // },

        {
            Header: "AVAILABILITY",
            accessor: el => el.isAvailable ?? false,
            Cell: ({ row, value }) => {
                return <div class="switcher">
                    <input type="checkbox" id={"chk" + row.original.id} checked={value}
                        onChange={() => {
                            if (value) {
                                updateJurisdictionAvailability(row.original.id, row.original.name, false);
                            }
                            else {
                                updateJurisdictionAvailability(row.original.id, row.original.name, true);
                            }
                        }
                        } />
                    <label for={"chk" + row.original.id}></label>
                </div>
            }
            ,
            disableFilters: true,
        },
        {
            id: "Action",
            Header: "ACTION",
            Cell: ({ row }) => (<>
                <div className="btn-list-wrapper d-flex">
                    <ButtonRound medium title={t('SETTING')} type={BtnTypes.GEAR_COG} className="btn-themed" onClick={() => { _history.push(getFullUrl(WebUrl._JURISDICTION_WIZARD, [{ "jurisdictionId": row.original.id }])) }} />
                    <ButtonRound medium type={BtnTypes.EDIT} onClick={() => { toggle(); setJurisdiction(row.original) }} />
                    {/* <ButtonRound medium type={BtnTypes.DELETE} onClick={() => {
                        Notiflix.Confirm.Show(t('DELETE_CONFIRMATION'), t('ARE_YOU_SURE_TO_DELETE'), t('YES'), t('NO'),
                            function () {//Yes
                                deleteJurisdiction(row.original.id)
                            }, '', deleteConfirm)
                    }} /> */}
                </div>
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [])

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const toggle = () => { setModal(!modal); setJurisdiction(null) };

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    const onSubmit = (data) => {
        Block.Circle(".modal-content", LoadingStateText._PLEASE_WAIT);
        let msg = "";
        if (!stringIsNullOrEmpty(jurisdiction)) {
            data["id"] = jurisdiction.id;
            msg = t("notiflix:SUCCESS_UPDATE_JURISDICTION");
        }
        else {
            msg = t("notiflix:SUCCESS_CREATE_JURISDICTION");
        }

        (async () => {
            await jurisdictionDao.createOrUpdateJurisdiction(data).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Notify.Success(msg);
                    getJurisdictionList();
                }
                toggle();
            }).finally(() => Block.Remove(".modal-content"));
        })()
    }

    function deleteJurisdiction(id) {
        Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
        (async () => {
            await jurisdictionDao.deleteJurisdiction(id).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Notify.Success(t('notiflix:SUCCESS_REMOVE_JURISDICTION'));
                    getJurisdictionList();
                }
            }).finally(() => Block.Remove(".notiflix-confirm-content"));
        })()
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        if (jurisdiction) {
            setReset(true);
        }
    }, [jurisdiction]);

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    function getJurisdictionList() {
        Block.Circle("#jurisdictionList", LoadingStateText._PLEASE_WAIT);
        (async () => {
            await jurisdictionDao.getJurisdictionList().then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Block.Remove("#jurisdictionList");
                    setJurisdictionList(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
        })()
    }

    ///<summary>
    ///Author: Ong Sze Hua
    ///</summary>
    useEffect(() => {
        getJurisdictionList();
    }, []);

    const updateJurisdictionAvailability = (id, name, isAvailable) => {
        let confirmTitle = isAvailable ? t("notiflix:ENABLE_CONFIRMATION") : t("notiflix:DISABLE_CONFIRMATION");
        let confirmMsg = (isAvailable ? t("notiflix:WISH_TO_ENABLE") : t("notiflix:WISH_TO_DISABLE")) + ` ${name}?`;
        Confirm.Show(
            confirmTitle,
            confirmMsg,
            t("CONFIRM"),
            t("CANCEL"),
            async () => {
                Block.Circle(".notiflix-confirm-content", LoadingStateText._PLEASE_WAIT);
                await jurisdictionDao.updateJurisdictionAvailability(id, isAvailable).then(jsonResponse => {
                    if (jsonResponse[ApiKey._API_SUCCESS_KEY]) {
                        setJurisdictionList(prev => {
                            let result = [...prev];
                            let index = result.findIndex(p => p.id == id);
                            result[index].isAvailable = isAvailable;
                            return result;
                        })
                        Notify.Success(jsonResponse[ApiKey._API_MESSAGE_KEY]);
                    }
                    else {
                        Report.Warning(
                            jsonResponse[ApiKey._API_MESSAGE_KEY],
                            jsonResponse[ApiKey._API_FIRST_ERROR_KEY]?.detail ?? "Request failed.",
                            t(SweetAlert._OK),
                        );
                    }
                }).finally(() => Block.Remove(".notiflix-confirm-content"));
            }, '', !isAvailable && deleteConfirm
        )
    }

    return (<>
        <div className="panel panel-brand panel-flex">
            <div className="panel-header p-b-0 d-flex justify-content-between">
                <h1 className="page-header title title-with-dot">{t('JURISDICTION_MANAGEMENT')}</h1>
            </div>
            <div className="panel-body y-scrollbar-2 h-100">
                <div className="panel-body-inner-content with-br-radius h-auto">
                    <div className="d-flex flex-column">
                        <div className="filter-panel-container">
                            <Row className="filter-panel-body p-15">
                                <Col xl={12}>
                                    <button type="button" className="btn btn-min-width btn-themed" onClick={toggle}><i className="las la-plus mr-1"></i>{t('systemConfiguration:ADD_JURISDICTION')}</button>
                                </Col>
                            </Row>
                        </div>
                        <div className="flex-grow-1">
                            <ReactTable columns={_COLUMN} data={jurisdictionList} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BrandModal modalSize="md" isOpen={modal} title={jurisdiction ? t('systemConfiguration:EDIT_JURISDICTION') : t('systemConfiguration:ADD_JURISDICTION')} customBody toggler={toggle} centered>
            <ModalBody>
                <FormBuilder fields={_FIELDS} onSubmit={onSubmit} formRef={formRef} reset={reset} resetTriggerReset={setReset} resetValues={jurisdiction} />
                <div className="country country-flag-hk"></div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <button type="button" className="btn btn-themed grayscale-100 btn-min-width" onClick={toggle}>{t('CANCEL')}</button>
                <button type="submit" className="btn btn-themed btn-min-width" onClick={() => { submitForm(formRef) }}>{t('SAVE')}</button>
            </ModalFooter>
        </BrandModal>
    </>)
}

export default JurisdictionManagement;