import BrandModal from 'components/modals/BrandModal';
import React, { useState, useMemo, useEffect, useImperativeHandle } from 'react';
import { Col, ModalBody, ModalFooter, Row, Card, CardBody } from 'reactstrap';
import StepZilla from "react-stepzilla";

// forms
import CreateOfficerForm from './forms/CreateOfficerForm';
import SearchOfficerForm from './forms/SearchOfficerForm';
import DisplayPersonDetailsForm from './forms/DisplayPersonDetailsForm';
import { stringIsNullOrEmpty } from 'util/Utility';
import ReactTable from 'components/react-table/ReactTable';
import ButtonRound from 'components/buttons/ButtonRound';
import { ApiKey, BtnTypes, CreateOfficerStepFormRefKey, OfficerTypeID } from 'util/Constant';
import { useRecoilState } from 'recoil';
import { ubChildrenNodeListState } from 'recoil/Incorporation';
import { useFieldArray, useForm } from 'react-hook-form';
import { CompanyDao } from 'data';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

///<summary>
///Author: Lewis
///</summary>
const SearchOrCreateCompanyOfficerWizard = React.forwardRef((props, ref) => {

    let {
        isOpen,
        toggler,
        companyId,
        officerTypeId,
        setOfficerTypeId,
        retrieveBeneficiaryTree,
        refreshCompanyOfficers,
        isEdit,
        officerId,
        onAddBeneficiaryOfficer,
        onCreateNewOfficer,
        parentKey,
        beneficiaryId,
        deleteNode,
    } = props

    const { t } = useTranslation();
    const [fetchOfficerId, setFetchOfficerId] = useState(null);
    const [searchResultList, setSearchResultList] = useState();
    const [modalSize, setModalSize] = useState('lg');

    const _wizardFormArray = useMemo(() => {

        return [
            {
                name: 'Search Officer', component:
                    <SearchOfficerForm
                        index={0}
                        companyId={companyId}
                        modalToggler={toggler}
                        officerTypeId={officerTypeId}
                        setFetchOfficerId={setFetchOfficerId}
                        setSearchResultList={setSearchResultList}
                        parentKey={parentKey}
                        beneficiaryId={beneficiaryId}
                    />
            },
            {
                name: 'No Result Searched', component:
                    <NoResultSearch
                        index={1}
                        companyId={companyId}
                        modalToggler={toggler}
                        officerTypeId={officerTypeId}
                        parentKey={parentKey}
                    />
            },
            {
                name: 'Company Officer', component:
                    <CreateOfficerForm
                        index={2}
                        companyId={companyId}
                        modalToggler={toggler}
                        officerTypeId={officerTypeId}
                        setFetchOfficerId={setFetchOfficerId}
                        retrieveBeneficiaryTree={retrieveBeneficiaryTree}
                        refreshCompanyOfficers={refreshCompanyOfficers}
                        onCreateNewOfficer={onCreateNewOfficer}
                        ref={ref}
                        parentKey={parentKey}
                    />
            },
            {
                name: 'Company Officer', component:
                    <DisplayPersonDetailsForm
                        index={3}
                        companyId={companyId}
                        modalToggler={toggler}
                        officerTypeId={officerTypeId}
                        fetchOfficerId={fetchOfficerId}
                        retrieveBeneficiaryTree={retrieveBeneficiaryTree}
                        refreshCompanyOfficers={refreshCompanyOfficers}
                        isEdit={isEdit}
                        ref={ref}
                        parentKey={parentKey}
                        beneficiaryId={beneficiaryId}
                        onAddBeneficiaryOfficer={onAddBeneficiaryOfficer}
                    />
            },
            {
                name: 'Company Officer Results',
                component:
                    <OfficersResultList
                        index={4}
                        searchResultList={searchResultList}
                        setFetchOfficerId={setFetchOfficerId}
                        parentKey={parentKey}
                    />
            },
            {
                name: 'Company Beneficiary Listing',
                component: <CompanyBeneficiaryListingForm
                    index={5}
                    modalToggler={toggler}
                    companyId={companyId}
                    setOfficerTypeId={setOfficerTypeId}
                    retrieveBeneficiaryTree={retrieveBeneficiaryTree}
                    parentKey={parentKey}
                    beneficiaryId={beneficiaryId}
                    deleteNode={deleteNode}
                    ref={ref}
                />
            }
        ]
    }, [
        ref,
        isEdit,
        companyId,
        parentKey,
        deleteNode,
        beneficiaryId,
        officerTypeId,
        fetchOfficerId,
        setOfficerTypeId,
        searchResultList,
        setFetchOfficerId,
        onCreateNewOfficer,
        setSearchResultList,
        refreshCompanyOfficers,
        retrieveBeneficiaryTree,
        onAddBeneficiaryOfficer,
    ]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(officerId)) {
            setFetchOfficerId(officerId);
        }

    }, [officerId]);

    return (
        <>
            <BrandModal
                isOpen={isOpen}
                toggler={toggler}
                customBody={true}
                title={t("SEARCH_OR_CREATE_OURS_COMPANY_OFFICERS")}
                className="officer-brand-modal"
                modalSize={modalSize}
            >
                <ModalBody className="p-0">
                    <StepZilla
                        className="step-zilla step-zilla-brand w-100"
                        preventEnterSubmission={true}
                        showNavigation={false}
                        steps={_wizardFormArray}
                        showSteps={false}
                        stepsNavigation={false}
                        nextButtonCls='btn btn-prev pull-right'
                        backButtonCls='btn btn-next pull-left'
                        startAtStep={isEdit ? 3 : 0}
                        onStepChange={(step) => {
                            if (step == 2) {
                                setModalSize('xl');
                                return;
                            }
                            setModalSize('lg');
                        }}
                    />
                </ModalBody>
            </BrandModal>
        </>
    )
});


///<summary>
///Author: Lewis
///</summary>
const NoResultSearch = (props) => {

    let {
        jumpToStep,
        modalToggler,
        officerTypeId
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <div className="officer-no-result-img-wrapper d-flex justify-content-center">
                <img className="m-auto" src={require("../../assets/img/ui/search-not-found.svg")} />
            </div>
            <div className="w-100 text-center">
                <p className="text-gray">{t("THERE_IS_NO_RESULT_FOR_YOUR_SEARCH")}</p>
                <p><strong>{t("DO_YOU_WANT_TO_ADD_NEW")} {officerTypeId == OfficerTypeID._CORPORATE ? "PC#" : "P#"}</strong></p>
            </div>
            <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-center">
                <button type="button" className="btn btn-themed grayscale-100 btn-mid-long" onClick={modalToggler}>{t("CANCEL")}</button>
                <button type="button" className="btn btn-themed btn-mid-long" onClick={() => jumpToStep(2)}>{t("YES")}</button>
            </ModalFooter>
        </>
    )
}

///<summary>
///Author: Lewis
///</summary>
const CompanyBeneficiaryListingForm = React.forwardRef((props, ref) => {

    let {
        companyId,
        beneficiaryId,
        modalToggler,
        deleteNode,
        jumpToStep,
        setOfficerTypeId,
    } = props;

    let companyDao = new CompanyDao();
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue } = useForm();
    const _FIELDS_ARRAY_KEY = "companyBeneficiary"
    const [childrenNodes, setChildrenNodes] = useRecoilState(ubChildrenNodeListState);
    const { append, remove, fields } = useFieldArray({ control, name: _FIELDS_ARRAY_KEY });

    const { totalCorporate, totalIndividual, totalSharePercentage } = useMemo(() => {

        let totalSharePercentage = 0;
        let totalCorporate = 0;
        let totalIndividual = 0

        if (childrenNodes.length > 0) {

            childrenNodes.map(item => {

                let { companyBeneficiaryShares, officer, ...restItemProps } = item;

                if (companyBeneficiaryShares.length > 0) {
                    companyBeneficiaryShares.map(s => { totalSharePercentage += s.sharePercentage });
                }

                if (officer.officerTypeId === OfficerTypeID._CORPORATE) {
                    totalCorporate += 1;
                }
                else {
                    totalIndividual += 1;
                }

            });

        }

        return { totalSharePercentage, totalCorporate, totalIndividual }

    }, [childrenNodes]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const onSubmit = (formValue) => { };

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const removeHandler = (beneficiaryId, name) => {
        if (typeof (deleteNode) == "function") {
            deleteNode(beneficiaryId, name);
        }
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const fetchChildrenNodes = async (companyId, beneficiaryId) => {
        await companyDao.getUbChildrenNodeList(companyId, beneficiaryId).then(json => {
            if (json[ApiKey._API_SUCCESS_KEY]) {
                setChildrenNodes(json[ApiKey._API_DATA_KEY]);
            }
        });
    }

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (childrenNodes.length > 0) {

            let valueToSet = childrenNodes.map(nodeItem => {
                let { id, officer, ubo, companyBeneficiaryShares, ...restOfficerProps } = nodeItem;
                let { englishName, chineseName, pNumber } = officer;
                let sharePercentage = 0;

                if (companyBeneficiaryShares.length > 0) {
                    sharePercentage = companyBeneficiaryShares[0].sharePercentage;
                }

                return { id, englishName, chineseName, pNumber, sharePercentage, ubo }
            });

            reset({ [_FIELDS_ARRAY_KEY]: valueToSet });
        }

    }, [childrenNodes]);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    useEffect(() => {

        if (!stringIsNullOrEmpty(beneficiaryId) && !stringIsNullOrEmpty(companyId)) {
            fetchChildrenNodes(companyId, beneficiaryId);
        }

    }, [beneficiaryId, companyId]);

    return <>
        <Row className="m-0 m-b-10">
            <Col style={{ paddingRight: 3 }}>
                <Card className="officer-shares-card">
                    <CardBody className="row p-5">
                        <img class="col-xl-3 comp-officer-shares-img" src={require("../../assets/img/ui/shares-chart.svg")} />
                        <Col xl={6}>
                            <div className="officer-shares-text">
                                <h5 className="text-gray">{t("TOTAL_SHARE_PERCENTAGE")}</h5>
                                <p><strong>{totalSharePercentage}%</strong></p>
                            </div>
                        </Col>
                    </CardBody>
                </Card>
            </Col>
            <Col style={{ paddingLeft: 3 }}>
                <Card className="officer-shares-card">
                    <CardBody className="row p-5">
                        <img class="col-xl-3 comp-officer-shares-img" src={require("../../assets/img/ui/shares-human.svg")} />
                        <Col xl={8} className="d-flex justify-content-around">
                            <div className="officer-shares-text">
                                <h5 className="text-gray">{t("TOTAL_CORPORATE_OFFICER")}</h5>
                                <p><strong>{totalCorporate}</strong></p>
                            </div>
                            <div className="officer-shares-text">
                                <h5 className="text-gray">{t("TOTAL_INDIVIDUAL_OFFICER")}</h5>
                                <p><strong>{totalIndividual}</strong></p>
                            </div>
                        </Col>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="company-beneficiary-ul y-scrollbar-2">
                {fields.map((b, i) => {
                    return (
                        <li key={i} className="li-item">
                            <Row className="m-0 p-10">
                                <Col xl={6}>
                                    <div className="comp-beneficiary-details">
                                        <p><strong>{b.englishName} {b.chineseName && `(${b.chineseName})`} ({b.pNumber})</strong></p>
                                        <p className="shares-text"><strong>Ordinary HKD - {(b.sharePercentage)}%</strong></p>
                                    </div>
                                </Col>
                                <Col xl={6} className="d-flex justify-content-end">
                                    <button className="btn btn-mid-long btn-themed m-t-auto m-b-auto" onClick={() => removeHandler(b.id, b.englishName)}>{t("REMOVE")}</button>
                                </Col>
                            </Row>
                        </li>
                    )
                })}
            </ul>
        </form>
        <ModalFooter className="panel-foot panel-foot-buttons d-flex justify-content-between">
            <div>
                <strong><span className="m-r-10">{t("ADD_ANOTHER")}</span></strong>
                <button type="button" className="btn btn-themed-default m-r-5" onClick={() => {

                    if (typeof (setOfficerTypeId) == "function") {
                        jumpToStep(0);
                        setOfficerTypeId(OfficerTypeID._INDIVIDUAL);
                    }

                }}>
                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/individual.svg")} />{t("INDIVIDUAL")}
                </button>
                <button type="button" className="btn btn-themed-default m-l-5" onClick={() => {

                    if (typeof (setOfficerTypeId) == "function") {
                        jumpToStep(0);
                        setOfficerTypeId(OfficerTypeID._CORPORATE);
                    }

                }}>
                    <img className="step-incomplete m-r-5" src={require("../../assets/img/ui/corporate.svg")} />{t("CORPORATE")}
                </button>
            </div>
            <div>
                <button type="button" className="btn btn-themed btn-mid-long" onClick={modalToggler}>{t("DONE")}</button>
            </div>
        </ModalFooter>
    </>
});

///<summary>
///Author: Lewis
///</summary>
const OfficersResultList = (props) => {

    let {
        jumpToStep,
        searchResultList: officerList,
        setFetchOfficerId
    } = props;

    const { t } = useTranslation();
    const { ref: _tblWrapperRef } = useResizeDetector();

    const _COLUMNS = useMemo(() => [
        {
            Header: "NAME", accessor: (el) => {
                return <div>
                    <div className="m-b-5 nowrap">
                        <span className="badge badge-purple">EN</span>
                        <span style={{ paddingLeft: '3%' }}>{el.englishName}</span>
                    </div>
                    {
                        !stringIsNullOrEmpty(el?.chineseName) && <>
                            <div className="nowrap">
                                <span className="badge badge-light-green">CN</span>
                                <span style={{ paddingLeft: '3%' }}>{el.chineseName}</span>
                            </div>
                        </>
                    }
                </div>
            },
        },
        {
            Header: 'P#',
            accessor: 'pNumber'
        },
        {
            Header: 'EMAIL',
            accessor: 'email',
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original?.email ? <span>{row.original.email}</span> : <span>-</span>}
                    </>
                )
            }
        },
        {
            Header: 'PHONE_NUMBER',
            accessor: 'phoneNumber',
            Cell: ({ row }) => {
                return (
                    <>
                        {row.original?.phoneNumber ? <span>{row.original.phoneNumber}</span> : <span>-</span>}
                    </>
                )
            }
        },
        {
            Header: 'ACTION',
            Cell: ({ row }) => {
                return (
                    <div className="btn-list-wrapper">
                        <ButtonRound type={BtnTypes.ADD} medium onClick={() => {
                            setFetchOfficerId(row.original.id);
                            // jump to role assignment form
                            jumpToStep(3);
                        }} />
                    </div>
                )
            },
            disableFilters: true,
            disableSortBy: true,
        }
    ], []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const tblHeight = useMemo(() => {
        if (_tblWrapperRef.current != null) {
            return _tblWrapperRef.current.clientHeight;
        }
    }, [_tblWrapperRef?.current]);

    return (
        <>
            <div className="d-flex flex-column h-100" ref={_tblWrapperRef}>
                <div style={{ height: tblHeight }}>
                    <ReactTable
                        columns={_COLUMNS}
                        data={officerList}
                        setPagination
                        enableSearch
                        initialPageSize={5}
                    />
                </div>
            </div>
            <ModalFooter className="panel-foot panel-foot-buttons justify-content-end">
                <button className="btn btn-themed btn-min-width" onClick={() => jumpToStep(0)}>{t("BACK")}</button>
            </ModalFooter>
        </>
    )
}

export default SearchOrCreateCompanyOfficerWizard;