import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { ReactTablev2 as ReactTable } from 'components/react-table';
import { ApiUrl, DateFormat, RoleType, InputTypes, Icon } from "util/Constant";
import FormBuilder, { submitForm } from "components/form/FormBuilder";
import moment from 'moment';
import { useResizeDetector } from "react-resize-detector";
import classnames from 'classnames';
import { PageSettings } from "config/page-settings";
import { useForm } from 'react-hook-form';
import InputHoc from 'components/form/InputHoc';
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

/// <summary>
/// Author: Lewis
/// </summary>
const ActivityLogs = props => {
    const { userOpts, userType } = props;
    const _tableRef = useRef();
    const { t } = useTranslation();
    const { isXlDevices } = useContext(PageSettings);
    const { ref: _tableWrapperRef } = useResizeDetector();
    const [resetFilters, setResetFilters] = useState(false);
    const [filtersForm, setFiltersForm] = useState({});
    const { register, control, handleSubmit, errors, reset, watch, setValue } = useForm();
    const today = useMemo(() => moment().toDate(), []);

    ///<summary>
    ///Author: Lewis
    ///</summary>
    const filterOnSubmit = (filtersValue) => {
        var startDate = moment(filtersValue.startLogDate).format(DateFormat._DATE_ONLY);
        var endDate = moment(filtersValue.endLogDate).format(DateFormat._DATE_ONLY);
        let tempObj = {
            ...filtersValue,
            startLogDate: startDate,
            endLogDate: endDate,
        }
        setFiltersForm(tempObj);
    }

    /// <summary>
    /// Author : Lewis
    /// </summary>
    const resetFiltersFormValue = () => {

        if (isEmpty(filtersForm)) {
            _tableRef.current.reFetch();
            return;
        }

        setResetFilters(!resetFilters);
        reset();
        setFiltersForm({});
    };

    const tableHeight = useMemo(() => {

        if (_tableWrapperRef.current != null) {
            return _tableWrapperRef.current.clientHeight;
        }

    }, [_tableWrapperRef?.current]);

    return (
        <div className={classnames('panel-body-inner-content with-br-radius', { 'content-overflow-y-scrollable y-scrollbar-2 x-scrollbar-2': !isXlDevices })}>
            <div className="d-flex flex-column h-100">
                <div className="filter-panel-container border-radius-none" >
                    <form onSubmit={handleSubmit(filterOnSubmit)} autoComplete='off'>
                        <Row className="filter-panel-body p-t-15">
                            <Col xl={8}>
                                <Row xl={4}>
                                    {
                                        userType == RoleType._SUPERUSER &&
                                        <Col>
                                            <InputHoc prefix={{ icon: Icon._PROFILE }} label={t("SEARCH") + t("USER")} name='name' placeholder={t("SEARCH") + t("USER")} inputType={InputTypes.SELECT} options={userOpts} control={control} />
                                        </Col>
                                    }
                                    <Col>
                                        <InputHoc prefix="#" label='Search Keyword' name='keyword' placeholder={t("SEARCH") + t("KEYWORD")} inputType={InputTypes.INPUT} control={control} />
                                    </Col>
                                    <Col>
                                        <InputHoc prefix={{ icon: Icon._CALENDER }} label='Start Date' name='startLogDate' inputType={InputTypes.DATEPICKER} control={control}
                                            onChange={(value) => { moment(value).isAfter(watch('endLogDate')) && setValue('endLogDate', value) }} defaultValue={today} />
                                    </Col>
                                    <Col>
                                        <InputHoc prefix={{ icon: Icon._CALENDER }} label='End Date' name='endLogDate' inputType={InputTypes.DATEPICKER} control={control}
                                            minDate={watch("startLogDate") ?? today} defaultValue={today} />
                                    </Col>
                                </Row>
                                {/* {<FormBuilder fields={ActivityLogForms(userOpts, userType)} formRef={_ref} onSubmit={filterOnSubmit}
                                reset={resetFilters} resetTriggerReset={setResetFilters} resetValues={{}} />} */}
                            </Col>
                            <Col xl={4} className="btn-list d-flex justify-content-end">
                                <button type="submit" className="btn btn-themed btn-brand-round" /*onClick={() => submitForm(_ref)}*/><i className="las la-search" /></button>
                                <button type="button" className="btn btn-themed btn-brand-round grayscale-100" onClick={() => resetFiltersFormValue()}><i className="las la-redo-alt" /></button>
                            </Col>
                        </Row>
                    </form>
                </div>
                <div className="flex-grow-1" ref={_tableWrapperRef}>
                    <div style={{ height: tableHeight }}>
                        <ReactTable
                            ref={_tableRef}
                            tableMinHeight={tableHeight}
                            columns={props.columns}
                            url={ApiUrl._API_GET_USER_ACTIVITY_LOGS}
                            filterFormfield={filtersForm}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ActivityLogs;